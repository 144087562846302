import { writable } from 'svelte/store';

const responsesReducer = writable([]);

const responsesStore = {
  subscribe: responsesReducer.subscribe,
  addResponses: (formId, responses) => {
    const newResponses = {
      [formId]: responses,
    };
    responsesReducer.update(responses => {
      return [...responses, newResponses];
    });
  },
};

export default responsesStore;
