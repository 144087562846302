<script>
  import FileUpload from './FileUpload.svelte';
  import { _ } from 'svelte-i18n';
  import {
    ImageEditCallback,
    ImageEditSquare,
    ImageEditURL,
  } from '../../lib/store';
  import { showModal } from '../../actions/modalController';
  import Button from '../UI/Button.svelte';
  import Icon from '../UI/Icon.svelte';
  export let entity = null;
  const uploadIcon = res => {
    ImageEditURL.set(res.data.url);
    ImageEditSquare.set(true);
    ImageEditCallback.set(res => {
      entity.iconImageURL = res.data.url;
    });
    showModal('#imageEditor');
  };
</script>

<div class="uk-text-center">
  <img
    alt="icon"
    src={entity.iconImageURL || '/images/no_photo.jpg'}
    style="height:80px;width:80px;"
    class="uk-border-rounded uk-margin-small-bottom"
  /><br />
  {#if entity.iconImageURL}
    <span>
      <Button
        style="text"
        onclick={() => {
          entity.iconImageURL = '';
        }}
      >
        <Icon icon="trash" />
      </Button>
    </span>
  {:else}
    <span>
      <FileUpload small fileType="image" onUploadComplete={uploadIcon}
        >{$_('Upload')}</FileUpload
      >
    </span>
  {/if}
</div>
