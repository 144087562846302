<script lang="ts">
  import Cookies from 'js-cookie';
  import { onMount } from 'svelte';
  import { _ } from 'svelte-i18n';
  import { navigate } from 'svelte-routing';
  import dialog from '../../lib/helpers/dialog';
  import {
    PersonaDefault,
    PersonaInterface,
  } from '../../lib/interfaces/Persona';
  import { ioGet } from '../../lib/realtime';
  import { School, User } from '../../lib/store';
  import Button from '../UI/Button.svelte';
  import Lead from '../UI/Lead.svelte';
  import ProfileEdit from './ProfileEdit.svelte';
  export let loggedIn;
  let persona: PersonaInterface = PersonaDefault;
  let role = null;
  let school = null;
  export let roleID: string = '';
  export let schoolSlug: string = '';
  onMount(async () => {
    if (!loggedIn) {
      Cookies.set('backURL', location.href);
      navigate('/login');
      return;
    }
    console.log(roleID, schoolSlug);
    school = await ioGet('getSchool', {
      slug: schoolSlug,
    });
    console.log(school);
    role = school.roles.find(
      r => r.id == roleID && r.allowRegistrationsByUsers,
    );
  });
  const save = async () => {
    if (!persona.nickname || !persona.avatarURL) {
      dialog($_('PleaseFillOutRequiredField'));
      return;
    }

    const isStudentRole =
      $School.roles.find(schoolRole => schoolRole.id === role.id)?.category ===
      'subscriber';

    let res = await ioGet('addUserRole', {
      ...persona,
      userEmail: $User.email,
      roleId: role.id,
      schoolId: school._id,
      isStudent: isStudentRole,
    });
    console.log(res);
    if (res.result == 'already has role') {
      dialog($_('accounts.AlreadyHasTheSameRole'));
      return;
    }
    if (res.result == 'success') {
      location.href = '/';
    }
  };
</script>

<section class="uk-section uk-light">
  <h1>{$_('RegisterToSchool')}</h1>
  {#if role}
    <Lead>
      {role.title}
    </Lead>
    <ProfileEdit {school} {persona} {role} />
    <Button fullwidth onclick={save}>
      {$_('Register')}
    </Button>
  {:else}
    {$_(
      'You have to ask the school manager to create an account in this school.',
    )}
  {/if}
</section>
