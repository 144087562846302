<script lang="ts">
  import { DateTime } from 'luxon';
  import { _ } from 'svelte-i18n';
  import { navigate } from 'svelte-routing';
  import { thumbnailCloudURL, thumbnailOKList } from '../../lib/defaultValues';
  import copy from '../../lib/helpers/copy';
  import { defineIfUndefined } from '../../lib/helpers/defineIfUndefined';
  import dialog from '../../lib/helpers/dialog';
  import myPrompt from '../../lib/helpers/myPrompt';
  import previewViewers from '../../lib/helpers/previewViewers';
  import switchSort from '../../lib/helpers/switchSort';
  import {
    AssetDefault,
    AssetInterface,
  } from '../../lib/interfaces/AssetInterface';
  import {
    PersonaDefault,
    PersonaInterface,
  } from '../../lib/interfaces/Persona';
  import { ioGet } from '../../lib/realtime';
  import { School, SchoolRole, TargetFolder } from '../../lib/store';
  import { FileStorageClient } from '../../v2/clients/fileStorageClient';
  import FileBrowserUpload from '../../v2/components/fileBrowser/FileBrowserUpload.svelte';
  import { RoleCategory } from '../../v2/models/types';
  import Button from '../UI/Button.svelte';
  import Input from '../UI/Input.svelte';
  import Lead from '../UI/Lead.svelte';
  import FilterInput from './FilterInput.svelte';
  import ViewersBlock from './ViewersBlock.svelte';
  let folderID: string = '';
  let breadcrumb = [];
  let folderContents: Array<AssetInterface> = [];
  let currentFolder = null;
  let unfilteredFolderContents: Array<AssetInterface> = [];

  let fileStorageClient = new FileStorageClient();

  TargetFolder.subscribe(async id => {
    breadcrumb = [];
    folderID = id ? id : $School._id;

    folderContents = await ioGet('listFolderContents', {
      folderID: folderID,
      schoolID: $School._id,
    });

    unfilteredFolderContents = folderContents;
    currentFolder = await ioGet('getFolderDetail', {
      _id: folderID,
    });

    await getFolder(folderID);

    breadcrumb = [$School, ...breadcrumb];
  });

  const getFolder = async (id: string) => {
    let folder = await ioGet('getFolderDetail', {
      _id: id,
    });

    if (folder) {
      breadcrumb = [folder, ...breadcrumb];
      if (folder.folderID) {
        await getFolder(folder.folderID);
      }
    }
  };

  const createFolder = () => {
    myPrompt($_('event.GiveItAName'), async (title: string) => {
      if (!title) return;
      let def = copy(AssetDefault);
      let res = await ioGet('createFolder', {
        data: {
          ...def,
          folderID: folderID,
          schoolID: $School._id,
          title: title,
          fileType: 'folder',
        },
      });
      folderContents = [...folderContents, res];
      unfilteredFolderContents = [...unfilteredFolderContents, res];
    });
  };

  const navigateToFolder = (id: string) => {
    navigate(`/${$School.slug}/files/${id}`);
    TargetFolder.set(id);
  };

  const saveFolder = async (folder: AssetInterface) => {
    folder.startDate = DateTime.now().toISO();
    let resViewers = await previewViewers(folder);
    if (!resViewers) return;
    await ioGet('updateFolder', {
      _id: folder._id,
      data: folder,
    });
    dialog($_('Saved'));
  };

  const removeFolder = async (id: string, key?: string) => {
    const res: {
      acknowledged: boolean;
      deletedCount: number;
    } = await ioGet('removeFolder', {
      _id: id,
    });

    if (res.acknowledged && key) {
      await fileStorageClient.deleteFileByKey(key);
    }

    folderContents.splice(
      folderContents.findIndex(content => content._id == id),
      1,
    );
    unfilteredFolderContents.splice(
      unfilteredFolderContents.findIndex(content => content._id == id),
      1,
    );
    folderContents = folderContents;
  };

  const fileBrowserUploadCallback = async (
    response: Response,
    key: string,
    file: File,
  ): Promise<void> => {
    if (!key) return;
    const defaultValues = copy(AssetDefault);

    const mongoResponse = await ioGet('createFolder', {
      data: {
        ...defaultValues,
        folderID: folderID,
        title: file.name,
        fileType: file.type,
        fileSize: file.size,
        key: key,
      },
    });

    folderContents = [...folderContents, mongoResponse];
    unfilteredFolderContents = [...unfilteredFolderContents, mongoResponse];
  };

  const downloadContentFromKey = async (key: string): Promise<void> => {
    fileStorageClient.downloadSingleFileLocally(key);
  };

  const filteredContents = (array: any[]): void => {
    folderContents = array;
  };
  let schoolRole: PersonaInterface | null = {
    ...PersonaDefault,
    schoolID: $School?._id,
    selected: false,
  };
  SchoolRole.subscribe(obj => {
    if (obj) {
      obj.customFieldValues = defineIfUndefined(obj.customFieldValues, {});
      schoolRole = obj;
    }
  });
  const role = $School.roles.find(role => role.id == $SchoolRole.roleID);
</script>

<ul class="uk-breadcrumb">
  {#each breadcrumb as bread}
    <li>
      <a href={'#'} on:click={() => navigateToFolder(bread._id)}>
        {bread.title}
      </a>
    </li>
  {/each}
</ul>
{#if currentFolder}
  <Lead>
    {currentFolder.title}
  </Lead>
  {#if currentFolder.filterByRole}
    <div>
      {#each $School.roles.filter(schoolRole => currentFolder.filterRoles.indexOf(schoolRole.id) > -1) as role}
        <div>
          {role.title}
        </div>
      {/each}
    </div>
  {/if}
{/if}
<FilterInput
  returnArray={res => filteredContents(res)}
  unfilteredArray={unfilteredFolderContents}
/>
<table class="uk-table uk-table-divider uk-table-small">
  <thead>
    <tr>
      <th>
        <a
          href={'#'}
          on:click={() => {
            folderContents = switchSort(folderContents, 'title');
          }}
        >
          {$_('file.Filename')}
        </a>
      </th>
      <th>
        <a
          href={'#'}
          on:click={() => {
            folderContents = switchSort(folderContents, 'updatedAt');
          }}
        >
          {$_('file.UpdatedAt')}
        </a></th
      >

      <th />
    </tr>
  </thead>
  <tbody>
    {#each folderContents as content}
      {#if content.fileType == 'folder'}
        <tr>
          <td class="uk-flex">
            <div class="uk-width-expand">
              <a
                href={'#'}
                on:click={() => {
                  navigateToFolder(content._id);
                }}
              >
                <i class="fa-duotone fa-folder-open" />
                {content.title}
              </a>
            </div>
            <div class="uk-margin-left">
              <ViewersBlock entity={content} />
            </div>
          </td>
          <td>
            {DateTime.fromISO(content.updatedAt).toLocaleString(
              DateTime.DATETIME_SHORT,
            )}
          </td>
          {#if role.category !== RoleCategory.Student}
            <td>
              <a href={'#'}>
                <i class="fa-solid fa-ellipsis-vertical" />
              </a>
              <div
                uk-dropdown="mode:click"
                class="uk-card uk-width-large uk-border-rounded uk-background-secondary"
              >
                <Input
                  bind:value={content.title}
                  label={$_('file.FolderTitle')}
                />
                <!-- <ViewersCheckboxes
                  bind:entity={content}
                  componentCategory="Files"
                  /> -->
                <Button
                  small
                  style={'primary'}
                  onclick={() => {
                    saveFolder(content);
                  }}>{$_('Save')}</Button
                >
                <Button
                  small
                  style="danger"
                  onclick={() => removeFolder(content._id)}
                >
                  <i class="fa-duotone fa-trash" />
                  {$_('Delete')}
                </Button>
              </div>
            </td>
          {/if}
        </tr>
      {:else}
        <tr>
          <td>
            {#if thumbnailOKList.indexOf(content.url.split('.').pop()) > -1}
              <img
                src={`${thumbnailCloudURL + content.url}`}
                class="uk-border-rounded"
                alt="thumbnail"
                style="width:60px;height:60px;"
              />
            {/if}
            <a
              href={content.url ? content.url : '#'}
              target="_blank"
              on:click={event => {
                event.preventDefault();
                if (content.url) return;
                downloadContentFromKey(content.key);
              }}
            >
              {content.title}
            </a>
          </td>
          <td>
            {DateTime.fromISO(content.updatedAt).toLocaleString(
              DateTime.DATETIME_SHORT,
            )}
          </td>
          {#if role.category !== RoleCategory.Student}
            <td>
              <Button style="link" onclick={() => {}}>
                <i class="fa-solid fa-ellipsis-vertical fa-fw" />
              </Button>
              <div
                uk-dropdown="mode:click"
                class="uk-card uk-width-large uk-border-rounded uk-background-secondary"
              >
                <Input bind:value={content.title} label={$_('file.Filename')} />
                <Button
                  small
                  style={'primary'}
                  onclick={() => {
                    saveFolder(content);
                  }}>{$_('Save')}</Button
                >
                <Button
                  small
                  style="danger"
                  onclick={() => removeFolder(content._id, content.key)}
                >
                  <i class="fa-duotone fa-trash" />
                  {$_('Delete')}
                </Button>
              </div>
            </td>
          {/if}
        </tr>
      {/if}
    {/each}
  </tbody>
</table>
{#if role.category !== RoleCategory.Student}
  <Button onclick={createFolder}>{$_('file.CreateFolder')}</Button>
  {#if folderID && $School}
    <FileBrowserUpload
      fileUploadCallback={fileBrowserUploadCallback}
      schoolId={$School._id}
      folderId={folderID}>{$_('Upload')}</FileBrowserUpload
    >
  {/if}
{/if}
