<script>
  import { _ } from 'svelte-i18n';

  import Button from './UI/Button.svelte';
  import { paginate, PaginationNav } from 'svelte-paginate';
  import Icon from './UI/Icon.svelte';

  export let pageSize = 8;

  export let currentPage;
  export let totalItems;
</script>

<PaginationNav
  {totalItems}
  {pageSize}
  {currentPage}
  limit="2"
  showStepOptions={true}
  on:setPage={e => (currentPage = e.detail.page)}
  let:value={pageNumber}
>
  <span slot="number" class="uk-margin-small-right uk-margin-small-left">
    <Button style="link">
      {#if currentPage == pageNumber}
        <span class="uk-text-bold">
          {pageNumber}
        </span>
      {:else}
        {pageNumber}
      {/if}
    </Button>
  </span>
  <span slot="ellipsis" class="uk-margin-small-right uk-margin-small-left">
    <Icon style="solid" icon="ellipsis" />
  </span>
  <span slot="prev" class="uk-margin-small-right uk-margin-small-left">
    <Button style="link">
      <Icon icon="chevron-left" />
    </Button>
  </span>
  <span slot="next" class="uk-margin-small-right uk-margin-small-left">
    <Button style="link">
      <Icon icon="chevron-right" />
    </Button>
  </span>
</PaginationNav>
