<script lang="ts">
  import { onMount } from 'svelte';
  import { _ } from 'svelte-i18n';
  import { ENDPOINT } from '../../lib/config';
  import copy from '../../lib/helpers/copy';
  import dialog from '../../lib/helpers/dialog';
  import { ioGet } from '../../lib/realtime';
  import { School } from '../../lib/store';
  import { UUID } from '../../lib/uuid';
  import { assignmentsStore } from '../../state/stores/assignmentsStore';
  import { submissionsStore } from '../../state/stores/submissionsStore';
  import { FileStorageClient } from '../../v2/clients/fileStorageClient';
  import Button from '../UI/Button.svelte';
  import Icon from '../UI/Icon.svelte';
  import Input from '../UI/Input.svelte';
  import PersonaBlock from './PersonaBlock.svelte';
  import SubmissionRow from './SubmissionRow.svelte';
  import SummaryFieldHeading from './SummaryFieldHeading.svelte';
  export let group;
  export let interval;
  export let category;
  export let saveCategory;
  let school;
  let thisInterval;
  let summaryFieldEditMode = false;
  let bulkUploadMode: boolean = false;

  const fileStorageClient = new FileStorageClient();

  School.subscribe(obj => {
    if (obj) {
      thisInterval = copy(obj.intervals.find(int => int.id === interval.id));
    }
    school = obj;
  });
  let students = [];

  onMount(async () => {
    let res = await ioGet('getRegisteredSubscribersV2', {
      id: group.id,
    });
    students = res.map(student => {
      return student.summaryFieldValues
        ? student
        : {
            ...student,
            summaryFieldValues: {},
          };
    });
  });
  let assignments = [];
  let submissions = [];
  submissionsStore.subscribe(array => {
    submissions = submissionsStore.list();
  });
  assignmentsStore.subscribe(obj => {
    assignments = assignmentsStore.list();
  });
  let selectedAssignment;
  let bulkDownloadFilename: string = 'download';
  let selectedCustomField;
  const bulkDownload = async () => {
    if (!selectedAssignment) {
      dialog($_('assignments.SelectAssignment'));
      return;
    }

    const files: {
      url: string;
      fileName: string;
    }[] = [];

    const selectedSubmissions = submissions.filter(
      s => s.selected && s.assignmentID === selectedAssignment,
    );
    await Promise.all(
      selectedSubmissions.map(async submission => {
        if (submission.customFieldValues[selectedCustomField]?.url) {
          files.push({
            url: submission.customFieldValues[selectedCustomField].url.replace(
              ENDPOINT + '/',
              'public/',
            ),
            fileName: submission.customFieldValues[selectedCustomField].title,
          });
        }
        if (submission.customFieldValues[selectedCustomField]?.key) {
          files.push({
            url: await fileStorageClient.getDownloadUrl(
              submission.customFieldValues[selectedCustomField].key,
            ),
            fileName: submission.customFieldValues[selectedCustomField].title,
          });
        }
      }),
    );

    if (!files.length) {
      dialog($_('assignments.NoFileToDownload'));
      return;
    }

    fileStorageClient.downloadFilesToZipLocally(files, bulkDownloadFilename);
  };
  let checkAll: boolean = false;
</script>

<div style="overflow-x:auto">
  <table
    class="uk-table uk-table-divider uk-table-justify uk-table-small uk-table-middle"
  >
    <thead>
      <tr>
        <th />
        {#each category.submissionSummaryFields || [] as summaryField, i}
          <th class="uk-text-center">
            <SummaryFieldHeading
              bind:summaryField
              bind:summaryFields={category.submissionSummaryFields}
              sourceCustomFields={category.submissionCustomFields}
              save={saveCategory}
              {i}
            />
          </th>
        {/each}
        <th>
          <div uk-grid>
            <div>
              <Button
                style="link"
                onclick={() => {
                  if (!category.submissionSummaryFields)
                    category.submissionSummaryFields = [];
                  category.submissionSummaryFields = [
                    ...category.submissionSummaryFields,
                    {
                      id: UUID(),
                      target: '',
                      calculation: '',
                      value: '',
                    },
                  ];
                  summaryFieldEditMode = true;
                }}
              >
                <i class="fa-duotone fa-plus" />
              </Button>
            </div>
            <div class="">
              <div class="uk-margin">
                <Button
                  onclick={() => {
                    bulkUploadMode = !bulkUploadMode;
                    selectedAssignment = '';
                  }}
                  style={bulkUploadMode ? 'primary' : 'default'}
                >
                  {$_('assignments.BulkDownload')}
                </Button>
              </div>
              {#if bulkUploadMode}
                <div class="uk-card uk-card-default uk-padding-small">
                  <div uk-grid class="uk-grid-small">
                    <div>
                      <select class="uk-select" bind:value={selectedAssignment}>
                        {#each assignments as assignment}
                          <option value={assignment.id}
                            >{assignment.title}</option
                          >
                        {/each}
                      </select>
                    </div>
                    <div>
                      <Button
                        style="link"
                        onclick={() => {
                          checkAll = !checkAll;
                          submissions = submissions.map(s => {
                            if (s.assignmentID === selectedAssignment) {
                              s.selected = checkAll;
                            }
                            return s;
                          });
                          submissionsStore.set(submissions);
                        }}
                      >
                        <Icon icon="square-check" />
                      </Button>
                    </div>
                    <div>
                      <select
                        class="uk-select"
                        bind:value={selectedCustomField}
                      >
                        {#each category.submissionCustomFields.filter(f => f.type === 'file') as field}
                          <option value={field.id}>{field.title}</option>
                        {/each}
                      </select>
                    </div>
                    <div>
                      <Input
                        bind:value={bulkDownloadFilename}
                        label={$_('Filename')}
                        withUnit=".zip"
                      />
                    </div>
                    <div>
                      <Button onclick={bulkDownload}>
                        {$_('assignments.DownloadFiles')}
                      </Button>
                    </div>
                  </div>
                </div>
              {/if}
            </div>
          </div>
        </th>
      </tr>
    </thead>
    <tbody>
      {#each students as subscriber}
        {#if subscriber && group}
          <tr>
            <td>
              <PersonaBlock
                withChat
                small
                withRole={false}
                schoolRoleID={subscriber.id}
              />
            </td>
            {#key category.submissionSummaryFields}
              <SubmissionRow
                assignmentCategory={category}
                {interval}
                {group}
                {subscriber}
                showCheckbox={bulkUploadMode}
                {selectedAssignment}
              />
            {/key}
          </tr>
        {/if}
      {/each}
    </tbody>
  </table>
</div>
