<script>
  import validatePassword from '../lib/helpers/validatePassword';
  import { ioFetch } from '../lib/realtime';

  import EnterCard from './blocks/EnterCard.svelte';
  import Input from './UI/Input.svelte';
  import Button from './UI/Button.svelte';
  import { _ } from 'svelte-i18n';
  import { User, ComeBackURL } from '../lib/store';
  import { navigate } from 'svelte-routing';
  import dialog from '../lib/helpers/dialog';
  let comeBackURL;
  ComeBackURL.subscribe(val => {
    comeBackURL = val;
  });
  let user;
  User.subscribe(obj => {
    user = obj;
  });

  const setPassword = async () => {
    if (password1 != password2) {
      dialog($_('login.PasswordsDontMatch'));
      return;
    }
    let res = validatePassword(password1);
    console.log(res);
    if (res.length) {
      let alertMessage = '';
      res.forEach(err => {
        alertMessage += $_(err.message) + '<br />';
        return;
      });
      dialog(alertMessage);
      return;
    }
    //okay
    // Store hash in your password DB.
    let userRes = await ioFetch('setUserPassword', {
      _id: user._id,
      data: {
        password: password1,
      },
    });
    console.log(userRes);
    if (comeBackURL.indexOf('set-password') > 0) {
      navigate('/');
    } else {
      navigate(comeBackURL);
    }
  };
  let password1;
  let password2;
</script>

<svelte:head>
  <title>{$_('login.SetPassword')} - AVOS</title>
</svelte:head>
<EnterCard>
  <span slot="title">{$_('login.SetPassword')}</span>
  <div slot="body">
    <Input
      type="password"
      bind:value={password1}
      label={$_('login.Password')}
    />
    <Input
      type="password"
      bind:value={password2}
      label={$_('login.PasswordConfirm')}
    />
    <Button fullwidth small onclick={setPassword}
      >{$_('login.SetPassword')}</Button
    >
  </div>
</EnterCard>
