AFRAME.registerComponent('slave-neck-to-look-control', {
  schema: {
    enabled: { type: 'boolean' },
  },
  init: function () {
    this.sync = false;
    this.head = null;
    //this.rig = rig
    this.el.addEventListener('model-loaded', evt => {
      let model = evt.detail.model;
      // console.log('model',model)
      if (model.children.length) {
        if (model.children[0].name == 'Armature') {
          this.neckFactor = -0.3;
        } else {
          model.traverse(object => {
            if (object.name == 'Head' || object.name == 'mixamorig_Head') {
              this.headBone = object;
            }
            if (object.name == 'Neck' || object.name == 'mixamorig_Neck') {
              this.neckBone = object;
            }
          });
          this.neckFactor = -1;
        }
        this.sync = true;
        this.rig = this.el.parentNode.parentNode;
      }
    });
  },
  tick: function () {
    if (this.sync && typeof this.rig != 'undefined' && this.data.enabled) {
      let rigXRotation = this.rig.object3D.rotation.x;
      this.headBone.rotation.x = this.neckFactor * rigXRotation;
    }
  },
});
