<script lang="ts">
  //npm moules
  import 'aframe';
  import 'aframe-extras';
  import 'aframe-look-at-component';
  import 'aframe-htmlembed-component';
  import 'aframe-gltf-part-component';
  import 'aframe-simple-sun-sky';

  import { onMount } from 'svelte';
  import Cookies from 'js-cookie';

  //actions
  import '../../actions/roomItems';

  //aframe components
  import '../../aframeComponents/transformControls';
  import '../../aframeComponents/updateRepeatedly';
  import '../../aframeComponents/transformDashboardControls';
  import '../../aframeComponents/clickToSeat';
  import '../../aframeComponents/offsetHeadBone';
  import '../../aframeComponents/showGumball';
  import '../../aframeComponents/showDashboardGumball';
  import '../../aframeComponents/slaveNeckToLookControl';
  import '../../aframeComponents/slaveNeckToHands';
  import '../../aframeComponents/floatAround';
  import '../../aframeComponents/spawnToRandomDesk';
  import '../../aframeComponents/broadcastMe';
  import '../../aframeComponents/scaleToFitDashboard';
  import '../../aframeComponents/hideHands';
  import '../../aframeComponents/showEmoteSelection';
  import '../../aframeComponents/myVolumeMeter';
  import '../../aframeComponents/fadeMyVoice';
  import '../../aframeComponents/showFriendMenu';
  import '../../aframeComponents/htmlembedBackplate';
  import '../../aframeComponents/htmlEmbed';
  import '../../aframeComponents/dashboardHandle';
  import '../../aframeComponents/disableLookWhileDragging';
  import '../../aframeComponents/loadingWhileModelLoad';
  import '../../aframeComponents/onClick';
  import '../../aframeComponents/myTouchControls';

  //components
  import UploadDialog from '../Modals/UploadDialog.svelte';

  //blocks
  import Me from './Me.svelte';
  import Gumball from './Gumball.svelte';
  import DashboardGumball from './DashboardGumball.svelte';
  import SidePanel from '../blocks/SidePanel.svelte';
  import FriendMenu from './FriendMenu.svelte';
  //libs
  import { io, ioGet } from '../../lib/realtime.js';
  import {
    School,
    User,
    Room,
    RoomPermissions,
    HEIGHT,
    gumballTarget,
    RIG,
    SchoolRole,
    IsMutable,
    flyMode,
  } from '../../lib/store.js';
  import broadcaseMeResponse from '../../lib/broadcastMeResponse';
  //classes
  import { HOST } from '../../lib/config.js';
  import ChatPanel from '../blocks/ChatPanel.svelte';
  import Button from '../UI/Button.svelte';
  import BottomMenu from '../blocks/BottomMenu.svelte';
  import EmoteMenu from '../blocks/EmoteMenu.svelte';
  import { _ } from 'svelte-i18n';
  import appendAvatarAsset from '../../lib/helpers/appendAvatarAsset.js';
  import PersonaBlock from '../blocks/PersonaBlock.svelte';
  import Sky from './Sky.svelte';
  import Modal from '../UI/Modal.svelte';
  import Icon from '../UI/Icon.svelte';
  import { roomElementsStore } from '../../state/stores/roomElementsStore';
  import myConfirm from '../../lib/helpers/myConfirm';
  import Dropdown from '../UI/Dropdown.svelte';
  import { Notification } from '../../actions/notification';
  import { personasStore } from '../../state/stores/personasStore';
  import { showModal } from '../../actions/modalController';
  import { thumbnailCloudURL } from '../../lib/defaultValues';
  import ParticipantsList from '../blocks/ParticipantsList.svelte';

  let chatPanelHidden = true;

  let uploadFileType;

  let participantsListHidden: boolean = true;
  let transportPointsListHidden: boolean = true;
  let roomElements = [];

  let isMutable: boolean;

  IsMutable.subscribe(value => {
    isMutable = value;
  });
  $: usersInRoom = roomElements.filter(item => {
    return item.type == 'user';
  });
  roomElementsStore.subscribe(array => {
    roomElements = roomElementsStore.list();
  });
  $: transportPoints = roomElements.filter(item => {
    return item.fileType == 'transportPoint';
  });
  //const { connect } = Twilio.Video;
  $: rig = document.getElementById($SchoolRole.id);
  onMount(async () => {
    io.on('broadcaseMeResponse', data => {
      broadcaseMeResponse(data);
    });
    io.on('leaveRoom', friendID => {
      let friendEl = document.getElementById(friendID);
      if (friendEl && friendEl.parentNode) {
        friendEl.parentNode.removeChild(friendEl);
      }
      roomElementsStore.delete(friendID);
    });
    //testing edit
    io.on('updateNicknameAndAvatar', async data => {
      console.log('friend updated his nickname and avatar');
      let res = await ioGet('getPersona', {
        schoolRoleID: data.schoolRoleID,
      });
      roomElementsStore.add(res);
      appendAvatarAsset(res.id, res.avatarURL);
      document.getElementById('myNickname_' + res.id).innerHTML = res.nickname;
      document
        .getElementById('myAvatar_' + res.id)
        .removeAttribute('gltf-model');
      document
        .getElementById('myAvatar_' + res.id)
        .setAttribute('gltf-model', '#avatarFile_' + res.id);
    });
  });
  const disableFly = () => {
    if (!$flyMode) {
      setTimeout(function () {
        rig.setAttribute('movement-controls', { fly: false });
      }, 500);
    }
    rig.removeEventListener('moving', disableFly);
  };
  io.on('forceTransport', data => {
    rig.setAttribute('movement-controls', { fly: true });
    rig.setAttribute('position', data.position);
    rig.addEventListener('moving', disableFly);
    Notification($_('YouAreTransported'));
  });
  //in 3 seconds, second student appears
  //let lastIntersection = {}
</script>

{#if $Room && $SchoolRole}
  <BottomMenu
    {chatPanelHidden}
    bind:fileType={uploadFileType}
    toggleChatPanelHidden={() => {
      chatPanelHidden = !chatPanelHidden;
    }}
  />
  <div
    class="full-scene"
    style="
    height: calc(100vh - 80px);
    height: calc(100dvh - 80px);
    "
  >
    <a-scene
      embedded
      loading-screen="enabled: false"
      renderer="
        antialias: auto;
        sortObjects: true;
        colorManagement: true;
        precision:mediump;
        maxCanvasWidth: 1080;
        maxCanvasHeight: 1080;
        "
      raycaster="objects: .rayTarget"
      cursor="rayOrigin: mouse;"
      id="scene"
      vr-mode-ui="enterVRButton: #myEnterVRButton; enterARButton: #myEnterARButton"
    >
      <a-assets>
        <video
          id="background360Video"
          crossorigin="anonymous"
          autoplay
          loop="true"
          src={$Room.backgroundType == 'video' || $Room?.use360VideoBackground
            ? $Room.sky360URL
            : ''}
        >
          <track kind="captions" />
        </video>
      </a-assets>
      <Me />
      <a-entity id="spawnPoint" position="-0.5 0 8.8" />
      {#if $Room.environment.id == 1 || typeof $Room.environment.url == 'undefined'}
        <a-entity
          loading-while-model-load
          gltf-model="url({HOST}/models/all_wo_doors.glb)"
          scale="1 1 1"
          class="walkarea"
          position="0 0 0"
          rotation="0 0 0"
        />
        <a-entity
          gltf-model="url({HOST}/models/navmesh.glb)"
          scale="1 1 1"
          class="walkarea"
          position="0 0 0"
          rotation="0 0 0"
          nav-mesh
          visible="false"
        />
      {:else}
        {#if $Room.environment.url}
          <a-entity
            loading-while-model-load
            gltf-model="url({$Room.environment.url})"
            scale="1 1 1"
            class="walkarea"
            position="0 0 0"
            rotation="0 0 0"
          />
        {:else}
          <a-entity
            loading-while-model-load
            gltf-model="url({HOST}/models/blankModel.glb)"
            scale="1 1 1"
            visible="false"
            class="walkarea"
            position="0 0 0"
            rotation="0 0 0"
          />
        {/if}
        {#if $Room.environment.navMeshURL}
          <a-entity
            gltf-model="url({$Room.environment.navMeshURL})"
            scale="1 1 1"
            class="walkarea"
            position="0 0 0"
            rotation="0 0 0"
            nav-mesh
            visible="false"
          />
        {/if}
      {/if}
      {#if $Room.backgroundType == 'sun'}
        <Sky />
      {:else if $Room.backgroundType == 'video' || $Room?.use360VideoBackground}
        <a-videosphere src="#background360Video" radius="5000" />
      {:else}
        <a-sky
          src={$Room?.skyURL
            ? thumbnailCloudURL + $Room.skyURL + '&size=2000&mode=orig'
            : '/models/pixta_40499328_L.jpg'}
        />
      {/if}
      <Gumball />
      <DashboardGumball />
      <FriendMenu />
      <a-entity oculus-touch-controls="hand: left" thumbstick-logging />
      <a-entity oculus-touch-controls="hand: right" thumbstick-logging />
    </a-scene>
    <div
      class="uk-position-top-right uk-margin-small-top uk-margin-small-right"
      style="z-index:1;"
    >
      <div class="uk-grid-small" uk-grid>
        <div>
          <img
            alt="icon"
            src={$School.iconImageURL || '/images/no_photo.jpg'}
            class="uk-border-rounded"
            style="width:30px;height:30px;"
          />
          <span class="uk-visible@s">
            {$School.title}
          </span>
        </div>
        <div>
          <img
            alt="icon"
            src={$Room.iconImageURL || '/images/no_photo.jpg'}
            class="uk-border-rounded"
            style="width:30px;height:30px;"
          />
          <span class="uk-visible@s">
            {$Room.title}
          </span>
        </div>

        {#if transportPoints.length && $Room.permissions.find(permissionSet => permissionSet.id == $SchoolRole.roleID).roomPermissionPresets.canTransport}
          <div>
            <Button
              style="link"
              onclick={() => {
                transportPointsListHidden = !transportPointsListHidden;
              }}
            >
              <Icon icon="transporter-1" />
              {$_('menu.Transport')}
            </Button>
          </div>
        {/if}
        <div>
          <Button
            style="link"
            onclick={() => {
              participantsListHidden = !participantsListHidden;
            }}
          >
            <i class="fa-duotone fa-users fa-lg" />
          </Button>
        </div>
        <div>
          <Button style="link" id="myEnterVRButton" onclick={() => {}}>
            <i class="fa-solid fa-vr-cardboard" />
            <span class="uk-visible@s">
              {$_('scene.EnterVRMode')}
            </span>
          </Button>
        </div>
      </div>
    </div>
  </div>
  <div class="uk-position-top-right uk-margin-large uk-margin-small-right">
    <div
      id="myWebcamPreview"
      class="uk-width-small uk-height-small uk-border-rounded uk-box-shadow-large uk-overflow-hidden"
      style="display:none;"
    >
      <div class="cameraInUseDot" style="color: greenyellow;">
        <i class="fa-solid fa-circle" />
        {$_('scene.CameraInUse')}
        <span uk-spinner id="webcamPreviewSpinner" hidden />
      </div>
    </div>
    <ParticipantsList
      {transportPoints}
      {participantsListHidden}
      {usersInRoom}
    />
    <div
      id="transportPointsList"
      class="uk-margin dark-translucent-background uk-light uk-border-rounded"
      style:padding="10px"
      hidden={transportPointsListHidden}
    >
      {#if transportPoints.length}
        <div class="uk-text-meta">
          {$_('room.TransportPoints')}
        </div>
        <ul class="uk-list uk-list-small uk-margin-large-right">
          {#each transportPoints as transportPoint}
            <li class="uk-animation-slide-right-medium">
              <a
                on:click={() => {
                  console.log(transportPoint.position);
                  myConfirm($_('AreYouSureToTransport'), () => {
                    rig.setAttribute(
                      'position',
                      document
                        .getElementById(transportPoint.uid)
                        .getAttribute('position'),
                    );
                  });
                }}
                href={'#'}
              >
                {transportPoint.extra.title}
              </a>
            </li>
          {/each}
        </ul>
      {/if}
    </div>
  </div>
  <ChatPanel hidden={chatPanelHidden} />
  <SidePanel />

  <Modal id="uploadDialog" title={uploadFileType}>
    <div slot="body">
      {#key uploadFileType}
        <UploadDialog fileType={uploadFileType} />
      {/key}
    </div>
  </Modal>
  <EmoteMenu />
{/if}
