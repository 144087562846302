<script>
  import { _ } from 'svelte-i18n';
  import { School, SchoolRole, TargetAssignment } from '../../lib/store';

  import dialog from '../../lib/helpers/dialog';
  import { ioGet } from '../../lib/realtime';
  import CustomFieldEditOrDisplay from '../blocks/CustomFieldComponents/CustomFieldEditOrDisplay.svelte';
  import Button from '../UI/Button.svelte';
  import Input from '../UI/Input.svelte';
  import Modal from '../UI/Modal.svelte';
  let category;
  let school;
  School.subscribe(obj => {
    if (obj && $TargetAssignment) {
      //category = obj.assignmentCategories.find(category=>category.id==$TargetAssignment.category.id)
    }
    school = obj;
  });
  TargetAssignment.subscribe(obj => {
    if (obj && typeof obj.caterogy != 'undefined') {
      category = obj.category;
      //category = school.assignmentCategories.find(category=>category.id==obj.category.id)
    }
  });
  const save = async () => {
    await ioGet('updateAssignment', {
      _id: $TargetAssignment._id,
      data: $TargetAssignment,
    });
    dialog($_('Saved'));
  };
</script>

<Modal withFooter id="assignmentEdit" title={$TargetAssignment?.title}>
  <div slot="body">
    {#if $TargetAssignment}
      <div class="uk-margin">
        <div class="uk-text-meta">
          {$_('Title')}
        </div>
        <Input bind:value={$TargetAssignment.title} />
      </div>
      {#each $TargetAssignment.category?.customFields || [] as field, i}
        <div class="uk-margin">
          <CustomFieldEditOrDisplay
            videoWatcher={$SchoolRole.id}
            fields={$TargetAssignment.category?.customFields}
            {field}
            bind:value={$TargetAssignment.customFieldValues[field.id]}
          />
        </div>
      {/each}
    {/if}
  </div>
  <div slot="footer">
    <Button onclick={save}>{$_('Save')}</Button>
  </div>
</Modal>
