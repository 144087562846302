import { ioGet } from '../lib/realtime';
import { School, SchoolRole } from '../lib/store';
let school = null;
School.subscribe(obj => {
  school = obj;
});
let schoolRole;
SchoolRole.subscribe(obj => {
  schoolRole = obj;
});
let list;
const articleController = {
  list: async () => {
    list = await ioGet('listArticles', {
      schoolID: school._id,
      schoolRoleID: schoolRole.id,
    });
    return list;
  },
};
export default articleController;
