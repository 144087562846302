<script>
  import CategoryCheckboxes from '../blocks/CategoryCheckboxes.svelte';

  import { TargetPersonas, School } from '../../lib/store';
  import { ioGet } from '../../lib/realtime';
  import { _ } from 'svelte-i18n';
  import Button from '../UI/Button.svelte';
  import PersonaBlock from '../blocks/PersonaBlock.svelte';
  import Switch from '../UI/Switch.svelte';
  import copy from '../../lib/helpers/copy';
  import { defineIfUndefined } from '../../lib/helpers/defineIfUndefined';
  import { Notification } from '../../actions/notification';
  export let targetPersonas = null;
  let registrations = [];
  let mount;
  let intervals = [];
  School.subscribe(obj => {
    if (obj) {
      intervals = copy(obj.intervals);
    }
  });

  const save = () => {
    let allRegistrations = [];
    intervals.forEach(interval => {
      console.log(interval.active);
      if (!interval.active) return;
      interval.programCategories
        .filter(category => category.useToGroupUser)
        .forEach(category => {
          category.groups.forEach(group => {
            allRegistrations.push(group.id);
            group.children.forEach(child => {
              allRegistrations.push(child.id);
            });
          });
        });
    });
    if (!allRegistrations.length) return;
    let promises = [];
    targetPersonas.forEach(persona => {
      console.log(persona.registrations, registrations, allRegistrations);
      allRegistrations.forEach(deletingRegistration => {
        persona.registrations = defineIfUndefined(persona.registrations, []);
        let i = persona.registrations.findIndex(registration => {
          if (registration == null) return false;
          return registration.programID == deletingRegistration;
        });
        if (i > -1) {
          persona.registrations.splice(i, 1);
        }
      });
      console.log('deleted', persona.registrations);
      persona.registrations = [
        ...persona.registrations,
        ...registrations.map(registration => {
          return { programID: registration };
        }),
      ];
      promises.push(
        ioGet('updatePersona', {
          schoolRoleID: persona.id,
          data: {
            registrations: persona.registrations,
          },
        }),
      );
    });
    Promise.all(promises).then(results => {
      Notification($_('Saved'));
    });
  };
</script>

{#if targetPersonas && $School}
  {#each targetPersonas as targetPersona}
    {#key targetPersona.id}
      <PersonaBlock withRole={false} small schoolRoleID={targetPersona.id} />
    {/key}
  {/each}
  <ul uk-tab>
    {#each intervals as interval}
      <li>
        <a href={'#'}>
          <span hidden={!interval.active}>
            <i class="fa-duotone fa-square-check fa-fw" />
          </span>
          {interval.title}
        </a>
      </li>
    {/each}
  </ul>
  <ul class="uk-switcher">
    {#each intervals as interval}
      <li>
        <Switch bind:value={interval.active} />

        {#if interval.programCategories && interval.active}
          <div class="uk-margin-left">
            {#each interval.programCategories.filter(category => category.useToGroupUser) as category}
              {category.title}
              {#each category.groups as group}
                <div>
                  <label>
                    <input
                      type="checkbox"
                      class="uk-checkbox"
                      value={group.id}
                      bind:group={registrations}
                      name="registrations"
                      on:change={evt => {
                        console.log(evt);
                        let el = evt.target.parentNode.nextElementSibling;
                        console.log(el);
                        if (el) {
                          let checkboxes =
                            el.querySelectorAll('[type=checkbox]');
                          console.log(checkboxes);
                          checkboxes.forEach(checkbox => {
                            if (evt.target.checked) {
                              checkbox.disabled = true;
                              if (registrations.indexOf(checkbox.value) == -1) {
                                registrations.push(checkbox.value);
                              }
                            } else {
                              checkbox.disabled = false;
                            }
                          });
                          registrations = registrations;
                          console.log(registrations);
                        }
                      }}
                    />
                    {group.title}
                  </label>
                  {#if group.children.length > 0}
                    <div class="uk-margin-left">
                      <ul class="uk-subnav">
                        {#each group.children as child}
                          <li>
                            <label>
                              <input
                                type="checkbox"
                                class="uk-checkbox"
                                value={child.id}
                                bind:group={registrations}
                                name="registrations"
                              />
                              {child.title}
                            </label>
                          </li>
                        {/each}
                      </ul>
                    </div>
                  {/if}
                </div>
              {/each}
            {/each}
          </div>
        {/if}
      </li>
    {/each}
  </ul>
  <Button onclick={save}>{$_('Save')}</Button>
{/if}
