<script lang="ts">
  import {
    TargetAssignment,
    TargetCategory,
    TargetGroup,
    TargetInterval,
    TargetSubmission,
  } from '../../lib/store';
  import CustomFieldDisplay from './CustomFieldComponents/CustomFieldDisplay.svelte';

  import { onMount } from 'svelte';
  import { ringSize } from '../../lib/defaultValues';
  import { defineIfUndefined } from '../../lib/helpers/defineIfUndefined';
  import { getVideosFromAssignment } from '../../lib/helpers/getVideosFromAssignment';
  import type { IntervalInterface } from '../../lib/interfaces/Interval';
  import Checkbox from '../UI/Checkbox.svelte';
  import VideoProgressRing from './VideoProgressRing.svelte';

  export let assignment;
  export let category;
  export let group;
  export let subscriber;
  export let calculateSummary;
  export let interval: IntervalInterface = null;
  export let submission;
  export let showCheckbox: boolean = false;
  export let selectedAssignment: string = '';
  onMount(async () => {
    /*
    submission = await ioGet("getSubmission", {
      assignmentID: assignment.id,
      subscriberID: subscriber.id,
    });
    */
    if (typeof submission != 'undefined') {
      category.submissionCustomFields.forEach(field => {
        submission.customFieldValues[field.id] = defineIfUndefined(
          submission.customFieldValues[field.id],
          {
            value: '',
          },
        );
      });
    }
  });
  const openEditSubmissionModal = sub => {
    TargetSubmission.set(sub);
    TargetAssignment.set(assignment);
    TargetCategory.set(category);
    TargetInterval.set(interval);
    TargetGroup.set(group);
    UIkit.modal('#submissionEdit').show();
  };
  $: videos = getVideosFromAssignment(assignment, category);
</script>

{#if submission}
  {#if selectedAssignment == assignment.id}
    <Checkbox bind:checked={submission.selected} />
  {/if}
  <div
    on:click={() => openEditSubmissionModal(submission)}
    class="uk-text-center uk-position-relative"
    style:cursor="pointer"
  >
    {assignment.title}
    {#if showCheckbox}
      <span />
    {/if}
    {#if category}
      {@const field =
        category.submissionCustomFields.find(field => field.showOnList) ||
        false}
      <div class="uk-position-relative">
        {#if submission && videos.length}
          <div class="uk-position-top">
            <VideoProgressRing
              width={ringSize}
              {videos}
              videoProgress={submission.videoProgress}
            />
          </div>
        {/if}
        <div class="">
          {#if submission && field}
            <CustomFieldDisplay
              parentFields={category.customFields}
              parentValues={assignment.customFieldValues}
              fields={category.submissionCustomFields}
              values={submission.customFieldValues}
              {field}
              bind:value={submission.customFieldValues[field.id]}
            />
          {/if}
        </div>
      </div>
    {/if}
  </div>
{/if}
