let ringHeight = 0.2;
let ringRadius = 0.6;
let dashboardClose = 0.6;
let dashboardOut = -0.1;

export let ringSize = 60;
let panSpeed = 2;
let thumbnailCloudURL =
  'https://thumbnails.cloud/v1/jpg?token=AzZcrtTaQT6ud5Yfh&url=';
let thumbnailOKList = [
  'jpg',
  'jpeg',
  'png',
  'webp',
  'gif',
  'avif',
  'bmp',
  'pdf',
  'eps',
  'ai',
  'epdf',
  'epi',
  'tiff',
  'tif',
  'fax',
  'ico',
  'mat',
  'ps',
  'psd',
  'svg',
  'ttf',
  'mpeg',
  'm4v',
  'mp4',
  'mov',
  'wmv',
  'flv',
  'doc',
  'docx',
  'md',
  'html',
  'htm',
  'bib',
  'tex',
  'latex',
  'xml',
  'wmf',
  'emf',
  'sgv',
  'odf',
  'ppm',
  'ras',
  'pct',
  'svm',
  'xpm',
  'pbm',
  'txt',
  'text',
  'rtf',
  'otf',
  'otd',
  'csv',
  'epub',
  'bibtex',
  'pages',
  'numbers',
  'key',
  'sketch',
  'heif',
  'heic',
];
let voiceFadeMaxDistance = 10;
let darkBG = '#334';
let heightUI = 60;
let accountCategories = [
  {
    slug: 'admin',
    title: 'Admin',
  },
  {
    slug: 'manager',
    title: 'Manager',
  },
  {
    slug: 'subscriber',
    title: 'Subscriber',
  },
  {
    slug: 'guest',
    title: 'Guest',
  },
];

let faces = [
  {
    icon: 'fa-duotone fa-face-smile fa-2x',
    label: 'smile',
  },
  {
    icon: 'fa-duotone fa-face-meh fa-2x',
    label: 'normal',
  },
  {
    icon: 'fa-duotone fa-face-frown-slight fa-2x',
    label: 'frown',
  },
  {
    icon: 'fa-duotone fa-face-smile-relaxed fa-2x',
    label: 'relaxed',
  },
  {
    icon: 'fa-duotone fa-face-angry fa-2x',
    label: 'angry',
  },
];
let menuActions = [
  {
    label: 'clap',
    icon: 'fa-duotone fa-hands-clapping fa-2x',
  },
  {
    label: 'raise hand',
    icon: 'fa-duotone fa-hand fa-2x',
  },
  {
    label: 'thumb up',
    icon: 'fa-duotone fa-thumbs-up fa-2x',
  },
  {
    label: 'waving',
    icon: 'fa-duotone fa-hand-wave fa-2x',
  },
  {
    label: 'whatever',
    icon: 'fa-duotone fa-circle-question fa-2x',
  },
  {
    label: 'laugh',
    icon: 'fa-duotone fa-face-laugh fa-2x',
  },
  {
    label: 'Nooooo',
    icon: 'fa-duotone fa-thumbs-down fa-2x',
  },
];
let itemTypes = [
  {
    //label will be i18ned
    icon: 'fa-duotone fa-cube fa-fw fa-2x fa-fw',
    fileType: 'gltf-model',
    label: 'AddModel',
  },
  {
    icon: 'fa-duotone fa-image fa-fw fa-2x fa-fw',
    fileType: 'image',
    label: 'AddImage',
  },
  {
    icon: 'fa-duotone fa-note fa-fw fa-2x fa-fw',
    fileType: 'postIt',
    label: 'AddPostIt',
  },
  {
    icon: 'fa-duotone fa-music fa-fw fa-2x fa-fw',
    fileType: 'audio',
    label: 'AddAudio',
  },
  {
    icon: 'fa-duotone fa-video fa-fw fa-2x fa-fw',
    fileType: 'video',
    label: 'AddVideo',
  },
  {
    icon: 'fa-duotone fa-panorama fa-fw fa-2x fa-fw',
    fileType: '360image',
    label: 'Add360Image',
  },
  {
    icon: 'fa-duotone fa-globe fa-fw fa-2x fa-fw',
    fileType: '360video',
    label: 'Add360Video',
  },
  {
    icon: 'fa-duotone fa-file fa-fw fa-2x fa-fw',
    fileType: 'file',
    label: 'AddOtherFile',
  },
];
export {
  heightUI,
  ringHeight,
  ringRadius,
  dashboardClose,
  dashboardOut,
  darkBG,
  voiceFadeMaxDistance,
  menuActions,
  itemTypes,
  faces,
  panSpeed,
  accountCategories,
  thumbnailCloudURL,
  thumbnailOKList,
};
