import { Room, SchoolRole, User } from '../lib/store.js';
import loadHands from '../lib/helpers/loadHands.js';
import { HOST } from '../lib/config.js';
import appendAvatarAsset from '../lib/helpers/appendAvatarAsset.js';
import { setLookRiggerHeight } from '../lib/helpers/aframeHelpers/setLookRiggerHeight.js';

let user;
User.subscribe(obj => {
  user = obj;
});
let schoolRole;
SchoolRole.subscribe(obj => {
  if (obj?.id != schoolRole?.id && document.getElementById('myAvatarPreview')) {
    console.log('schoolRole updated');
    appendAvatarAsset(obj.id, obj.avatarURL);
    document
      .getElementById('myAvatarPreview')
      .setAttribute('gltf-model', '#avatarFile_' + obj.id);
  }
  schoolRole = obj;
});
let room = null;
Room.subscribe(obj => {
  room = obj;
});

class MyAvatar {
  constructor() {
    appendAvatarAsset(schoolRole.id, schoolRole.avatarURL);

    if (!schoolRole.position || schoolRole.roomID != room._id) {
      schoolRole.position = { x: 0, y: 0, z: 0 };
      schoolRole.rotation = { x: 0, y: 0, z: 0 };
    }
    if (schoolRole.height) {
      setLookRiggerHeight(schoolRole.height);
    }
    if (schoolRole.position && schoolRole.position.y < 30) {
      document
        .getElementById(schoolRole.id)
        .setAttribute('position', schoolRole.position);
    } else {
      //somehow it is tooooo high
      document
        .getElementById(schoolRole.id)
        .setAttribute(
          'position',
          schoolRole.position.x + ' 0 ' + schoolRole.position.z,
        );
    }
    // document.getElementById("look-rigger").setAttribute('rotation',"0 "+schoolRole.rotation.y+" 0")

    document.getElementById('myNickname').innerHTML = schoolRole.nickname;

    document
      .getElementById('myAvatar')
      .setAttribute('gltf-model', '#avatarFile_' + schoolRole.id);
    document
      .getElementById('myAvatarPreview')
      .setAttribute('gltf-model', '#avatarFile_' + schoolRole.id);

    document.getElementById('myAvatarPivot').appendChild(loadHands());

    document
      .getElementById('myDashboard')
      .setAttribute('float-around', { targetEl: this.el });

    document.getElementById(schoolRole.id).setAttribute('broadcast-me', {
      enabled: true,
    });
  }
}
class FriendAvatar {
  constructor(user) {
    this.el = document.createElement('a-entity');
    this.el.id = user.id;
    this.el.setAttribute('fade-my-voice', { enabled: true });
    this.el.setAttribute('show-friend-menu', { enabled: true });
    this.el.setAttribute('socketID', user.socketID);

    this.el.classList.add('user');
    this.el.classList.add('rayTarget');

    this.childGLTF = document.createElement('a-entity');
    let myAvatarEyes = document.createElement('a-entity');
    myAvatarEyes.setAttribute('position', '0 0 0');
    myAvatarEyes.id = 'myAvatarEyes_' + user.id;
    //    this.el.appendChild(this.childGLTF)
    let gltfAsset = document.createElement('a-asset-item');
    gltfAsset.id = 'avatarFile_' + user.id;
    gltfAsset.setAttribute('src', user.avatarURL);
    document.getElementsByTagName('a-assets')[0].appendChild(gltfAsset);
    this.childGLTF.setAttribute('gltf-model', '#avatarFile_' + user.id);
    this.childGLTF.id = 'myAvatar_' + user.id;
    this.childGLTF.setAttribute('position', '0 -0.65 0');
    this.childGLTF.setAttribute('rotation', '0 180 0');
    this.childGLTF.setAttribute('hide-hands', { enabled: true });
    this.childGLTF.classList.add('rayTarget');

    this.avatarPivot = document.createElement('a-entity');
    this.avatarPivot.id = 'myAvatarPivot_' + user.id;
    this.avatarPivot.setAttribute('offset-head-bone', { enabled: true });
    //this.avatarPivot.setAttribute('slave-neck-to-hands', 'targetId', user.id);
    this.avatarPivot.appendChild(myAvatarEyes);

    this.avatarPivot.appendChild(loadHands(user.id));

    let childNickname = document.createElement('a-entity');
    childNickname.id = 'myNickname_' + user.id;
    childNickname.innerHTML =
      "<div id='myNickname_" +
      user.id +
      "' style='color:white;width:500px;text-align:center'>" +
      user.nickname +
      '</div>';
    this.avatarPivot.appendChild(this.childGLTF);
    this.el.appendChild(this.avatarPivot);
    childNickname.setAttribute('htmlembed', {
      ppu: 500,
    });
    childNickname.setAttribute('look-at', '#myCamera');
    childNickname.setAttribute('position', '0 0.3 0');
    childNickname.setAttribute('rotation', '0 180 0');
    this.avatarPivot.appendChild(childNickname);
    this.childGLTF.id = 'myAvatar_' + user.id;
  }
  append(scene) {
    scene.appendChild(this.el);
  }
}
export { MyAvatar, FriendAvatar };
