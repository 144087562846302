import { getSource } from '../actions/mediaItemController';
import resetFocus from '../lib/helpers/resetFocus';
import { focusFriend } from '../lib/store';
import { showAFRAME } from '../lib/visibilityAFRAME';
let focus;
focusFriend.subscribe(el => {
  focus = el?.id;
});
AFRAME.registerComponent('show-friend-menu', {
  schema: {
    enabled: { default: true },
  },
  init: function () {
    this.friendMenu = null;
    this.el.addEventListener('click', () => {
      console.log('friend clicked');
      resetFocus();
      this.friendMenu = document.getElementById('friendMenu');
      focusFriend.set(this.el);
      getSource(this.el);
      showAFRAME(this.friendMenu);
    });
  },
  tick: function () {
    if (this.friendMenu && focus == this.el.id) {
      this.friendMenu.setAttribute(
        'position',
        this.el.getAttribute('position'),
      );
    }
  },
});
