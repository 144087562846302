<script lang="ts">
  import { _ } from 'svelte-i18n';
  import { hideModal, showModal } from '../../actions/modalController';
  import dialog from '../../lib/helpers/dialog';
  import type { PersonaInterface } from '../../lib/interfaces/Persona';
  import type { RoleInterface } from '../../lib/interfaces/Role';
  import { ioGet } from '../../lib/realtime';
  import {
    ImageEditCallback,
    ImageEditSquare,
    ImageEditURL,
    School,
    SchoolRole,
  } from '../../lib/store';
  import CustomFieldEditOrDisplay from '../blocks/CustomFieldComponents/CustomFieldEditOrDisplay.svelte';
  import FileUpload from '../blocks/FileUpload.svelte';
  import PersonaBlock from '../blocks/PersonaBlock.svelte';
  import Button from '../UI/Button.svelte';
  import Input from '../UI/Input.svelte';
  import Modal from '../UI/Modal.svelte';
  let mount;
  export let persona = null;
  export let role: RoleInterface;
  export let onChange: (persona: PersonaInterface) => void;
  const save = async () => {
    let res = await ioGet('updatePersona', {
      schoolRoleID: persona.id,
      data: persona,
    });
    dialog($_('Saved'));
    hideModal('#accountDetail');
  };
</script>

{#if persona && persona.customFieldValues}
  <Modal id="accountDetail" withFooter title={persona.nickname}>
    <div slot="body">
      {#key persona.id}
        <PersonaBlock schoolRoleID={persona.id} />
        {#if $School.roles.find(r => r.id == $SchoolRole.roleID).school.account.canEdit}
          <FileUpload
            fileType="image"
            small
            onUploadComplete={res => {
              ImageEditURL.set(res.data.url);
              ImageEditSquare.set(true);
              ImageEditCallback.set(res => {
                persona.profileImageURL = res.data.url;
                save();
              });
              showModal('#imageEditor');
            }}>{$_('Upload')}</FileUpload
          >
        {/if}
      {/key}
      <Input type="text" label={$_('Nickname')} bind:value={persona.nickname} />
      {#each $School.customFields[role.category] || [] as field}
        <div class="uk-margin">
          <CustomFieldEditOrDisplay
            fields={$School.customFields[role.category]}
            {field}
            bind:value={persona.customFieldValues[field.id]}
            bind:values={persona.customFieldValues}
          />
        </div>
      {/each}
    </div>
    <div slot="footer">
      <Button onclick={save}>{$_('Save')}</Button>
    </div>
  </Modal>
{/if}
