import {
  Room,
  User,
  noiseThreshold,
  myVolumeRaw,
  micOnMute,
  captureScreenActive,
  webcamActive,
  SchoolRole,
  megaphoneActive,
  AudioDeviceID,
  VideoStream,
} from '../lib/store.js';
import { get, writable } from 'svelte/store';

import {
  moveScreenCaptureToDashboard,
  moveScreenCaptureToRoom,
  appendScreenCapture,
  getScreenData,
} from './roomVideoItems';
import { io, ioGet } from '../lib/realtime.js';
import pollAudioLevel from '../lib/pollAudioLevel';
import { updateItem } from './itemController.js';
import dialog from '../lib/helpers/dialog';
import { _ } from 'svelte-i18n';
import { Notification } from './notification.js';
import { UUID } from '../lib/uuid.js';
import { personasStore } from '../state/stores/personasStore.js';

const MEGAPHONE_VOLUME_LEVEL = 1;

let videoStream;
VideoStream.subscribe(obj => {
  videoStream = obj;
});

let participantAudio = {};

let videoMe;
let theRoom;
let thisNoiseThreshold;
let schoolRole;
SchoolRole.subscribe(obj => {
  schoolRole = obj;
});
let user;
User.subscribe(obj => {
  user = obj;
});
let thisRoom;
Room.subscribe(obj => {
  thisRoom = obj;
});
noiseThreshold.subscribe(val => {
  thisNoiseThreshold = val;
});
let myMicOnMute = false;
micOnMute.subscribe(bool => {
  myMicOnMute = bool;
});

async function subscribed(track, participant) {
  if (
    track.kind == 'video' &&
    track.isEnabled &&
    track.name.indexOf(participant.identity) > 0
  ) {
    console.log('subscribed video', track.name, participant.identity);
    if (track.name.indexOf('myWebcam') === 0) {
      var elementId = 'webcamOf_' + participant.identity;

      let newVideo = track?.attach(); //creates a video element in the DOM

      if (document.getElementById(elementId)) return;
      newVideo.id = elementId;
      document.querySelector('a-assets').appendChild(newVideo);
      let newVideoEl = document.createElement('a-video');
      newVideoEl.id = elementId + '_entity';
      newVideoEl.setAttribute('src', '#' + elementId);
      if (track.name.indexOf('myWebcam') === 0) {
        newVideoEl.setAttribute('width', 0.35);
        newVideoEl.setAttribute('height', 0.35);
        newVideoEl.setAttribute('position', '0 0 -0.16');
        newVideoEl.setAttribute('rotation', '0 180 0');
        document.getElementById(participant.identity).appendChild(newVideoEl);
      }
    } else if (track.name.indexOf('myScreenshare_') === 0) {
      appendScreenCapture(track, participant.identity);
    }
  }
  if (track.kind == 'audio') {
    if (track.name.indexOf('myAudioScreenshare_') === 0) {
      //this is for screen
      let soundEl = track.attach();
      document.querySelector('a-assets').appendChild(soundEl);
      soundEl.id = track.name + '_element';
      soundEl.setAttribute('preload', 'auto');
    } else {
      appendAudioToFriend(track, participant);

      track.on('enabled', () => {
        appendAudioToFriend(track, participant);
      });
      track.on('audio disabled', () => {
        console.log('disabled', track);
      });
    }
  }
}
function appendAudioToFriend(track, participant) {
  participantAudio[participant.identity] = {
    track,
  };

  let newAudio = document.createElement('audio');

  let elementId = 'voiceOf_' + participant.identity;
  let existingEl = document.getElementById(elementId);
  if (existingEl) return;
  newAudio.id = elementId;
  newAudio.setAttribute('preload', 'none');

  if (!document.getElementById(participant.identity)) {
    setTimeout(function () {
      //try again in a few seconds
      appendAudioToFriend(track, participant);
    }, 2000);
  } else {
    document.querySelector('a-assets').appendChild(newAudio);
    document
      .getElementById(participant.identity)
      .setAttribute('sourceID', elementId);
    document
      .getElementById(participant.identity)
      .setAttribute('sourceType', 'friend');
  }
}

function stopScreenCapture() {
  io.emit('deleteScreen');
  videoMe.audioTracks.forEach(publication => {
    console.log('audioPublication', publication);
    if (publication.track.name.indexOf('myAudioScreenshare') == 0) {
      //for the host
      publication.track.stop();
      publication.unpublish();
    }
  });

  videoMe.videoTracks.forEach(publication => {
    if (publication.track.name.indexOf('myScreenshare') == 0) {
      publication.track.stop();
      publication.unpublish();
      deleteScreenCapture(schoolRole.id, publication.track);
    }
  });
  captureScreenActive.set(false);
}

async function startScreenCapture() {
  if (!videoMe) {
    await reConnect();
  }
  const stream = await navigator.mediaDevices.getDisplayMedia({
    video: {
      frameRate: 15,
    },
    audio: true,
  });
  console.log(stream.getTracks());
  let uuid = UUID();

  if (stream.getTracks().length > 1) {
    //with audio
    const audioTrack = new Twilio.Video.LocalAudioTrack(stream.getTracks()[0], {
      //name: "myScreenshare_" + schoolRole.id,
      name: 'myAudioScreenshare_' + schoolRole.id + uuid,
    });
    console.log({ audioTrack });
    videoMe.publishTrack(audioTrack);
    videoMe.audioTracks.forEach(publication => {
      if (publication.track.name.indexOf('myAudioScreenshare') > -1) {
        publication.track.enable();
      }
    });
    /*
    let soundEl = audioTrack.attach();
    document.querySelector("a-assets").appendChild(soundEl);
    soundEl.id = audioTrack.name + "_element";
    soundEl.setAttribute("preload", "auto");
    */
    var screenTrack = new Twilio.Video.LocalVideoTrack(stream.getTracks()[1], {
      name: 'myScreenshare_' + schoolRole.id + uuid,
    });
  } else {
    //without audio
    var screenTrack = new Twilio.Video.LocalVideoTrack(stream.getTracks()[0], {
      name: 'myScreenshare_' + schoolRole.id + uuid,
    });
  }

  console.log({ screenTrack });
  videoMe.publishTrack(screenTrack);
  videoMe.videoTracks.forEach(publication => {
    publication.track.enable();
  });

  let newVideoEl = await appendScreenCapture(screenTrack, schoolRole.id); //for me
  let position = document
    .getElementById('myCamera')
    .object3D.localToWorld(new THREE.Vector3(0, 0, -2));
  newVideoEl.setAttribute('position', position);
  let y = document.getElementById('look-rigger').getAttribute('rotation').y;
  newVideoEl.setAttribute('rotation', '0 ' + y + ' 0');
  updateItem(newVideoEl);
  captureScreenActive.set(true);
}
const startWebcam = async () => {
  let preview = document.getElementById('myWebcamPreview');
  document.getElementById('webcamPreviewSpinner').removeAttribute('hidden');
  preview.style = 'display:block';
  let stream = videoStream;
  const webcamTrack = new Twilio.Video.LocalVideoTrack(stream.getTracks()[0], {
    name: 'myWebcam_' + schoolRole.id,
  });
  videoMe.publishTrack(webcamTrack);
  videoMe.videoTracks.forEach(publication => {
    publication.track.enable();
    console.log('enabled', publication.track);
  });

  preview.appendChild(webcamTrack.attach());
  webcamActive.set(true);
  document.getElementById('webcamPreviewSpinner').setAttribute('hidden', true);
};
function stopWebcam() {
  videoMe.videoTracks.forEach(publication => {
    //publication.track.stop();
    publication.unpublish();
  });
  let preview = document.getElementById('myWebcamPreview');
  for (let i = 0; i < preview.children.length; i++) {
    let el = preview.children.item(i);
    if (el.tagName == 'VIDEO') el.remove();
  }
  preview.style = 'display:none';
  webcamActive.set(false);
}
async function normalAudio() {
  if (!videoMe) {
    await reConnect();
  }
  videoMe.audioTracks.forEach(publication => {
    publication.track.enable();
    console.log('enabled', publication.track);
  });
  micOnMute.set(false);
  megaphoneActive.set(false);
}

io.on('forceMute', data => {
  console.log('received force mute', data);
  muteAudio();
});
async function muteAudio() {
  if (!videoMe) await reConnect();
  videoMe.audioTracks.forEach(publication => {
    if (publication.track.name.indexOf('myAudioScreenshare') === 0) {
      console.log('this is screenaudio. lets return');
      return;
    }
    publication.track.disable();
    console.log('disabled', publication.track);
  });
  micOnMute.set(true);
  megaphoneActive.set(false);
}

function deleteScreenCapture(identity, track) {
  let assetEl = document.getElementsByName('screenOf_' + identity)[0];
  console.log(identity);
  console.log({ assetEl });
  if (assetEl) {
    assetEl.parentNode.removeChild(assetEl);
  }
  let assetAudioEl = document.getElementById(track.name + '_entity');
  if (assetAudioEl) {
    assetAudioEl.parentNode.removeChild(assetAudioEl);
  }
  let entityEl = document.getElementById('screenOf_' + identity + '_entity');
  if (entityEl) {
    if (entityEl.parentNode.id.indexOf('panel_') === 0) {
      let offsetter = entityEl.parentNode.parentNode;
      offsetter.parentNode.removeChild(offsetter);
    } else {
      entityEl.parentNode.removeChild(entityEl);
    }
  }
}
function unsubscribed(track, participant) {
  if (track.kind == 'video' && track.name.indexOf(participant.identity) > 0) {
    if (track.name.indexOf('myWebcam_') === 0) {
      let assetEl = document.getElementById('webcamOf_' + participant.identity);
      if (assetEl) {
        assetEl.parentNode.removeChild(assetEl);
      }
      let entityEl = document.getElementById(
        'webcamOf_' + participant.identity + '_entity',
      );
      if (entityEl) {
        entityEl.parentNode.removeChild(entityEl);
      }
    } else {
      console.log({ track });
      deleteScreenCapture(participant.identity, track);
    }
  }
  if (track.kind == 'audio') {
    let assetEl = document.getElementById('voiceOf_' + participant.identity);
    if (assetEl) {
      //assetEl.parentNode.removeChild(assetEl)
    }
  }
}

function subscriptionFailed(error, publication) {
  console.log(
    'Failed to subscribe to RemoteTrack ${publication.trackSid}:',
    error,
  );
}

async function listenToSubscriptionEvents(participant) {
  if (!videoMe) {
    await reConnect();
    //dialog("Microphone is disabled");
    //    return;
  }

  //room.on("trackSubscribed", (track) => {
  participant.on('trackSubscribed', track => {
    subscribed(track, participant);
  });
  //room.on("trackUnsubscribed", (track) => {
  participant.on('trackUnsubscribed', track => {
    unsubscribed(track, participant);
  });
}

const videoController = {
  init: async (token, deviceId = null) => {
    let devices = await navigator.mediaDevices.enumerateDevices();
    const audioInput = devices.find(device => {
      if (deviceId) {
        return device.deviceId === deviceId;
      } else {
        return device.kind === 'audioinput';
      }
    });
    AudioDeviceID.set(audioInput.deviceId);
    let localTracks = await Twilio.Video.createLocalTracks({
      audio: {
        deviceId: audioInput.deviceId,
        noiseSuppression: true,
      },
      video: false,
    });
    console.log('myLocalTracks', localTracks);
    Twilio.Video.connect(token, {
      name: thisRoom._id,
      tracks: localTracks,
    }).then(
      room => {
        console.log('twilio connection succeed', room);
        theRoom = room;
        // videoController = room
        room.on('disconnected', (room, error) => {
          console.log(room.state, 'disconnected');
          //reConnect()
          if (error?.code === 20104) {
            console.log(
              'Signaling reconnection failed due to expired AccessToken!',
            );
          } else if (error?.code === 53000) {
            console.log('Signaling reconnection attempts exhausted!');
          } else if (error?.code === 53002) {
            console.log('Signaling reconnection took too long!');
          }
        });
        room.on('participantConnected', participant => {
          console.log('participant Connected Event', participant);
          participant.tracks.forEach(publication => {
            publication.on('subscriptionFailed', subscriptionFailed);
          });
          listenToSubscriptionEvents(participant);
        });
        videoMe = room.localParticipant;
        muteAudio();

        videoMe.audioTracks.forEach(publication => {
          pollAudioLevel(publication.track, level => {
            if (level >= 0) {
              myVolumeRaw.set(level);
              if (level < thisNoiseThreshold) level = 0;
              level = level - thisNoiseThreshold;
              if (level < 0) {
                level = 0;
              }
              level = level * 1.5;
              SchoolRole.update(persona => {
                persona.currentVolume = level;
                return persona;
              });
              //myVolume.set(level);

              //io.emit('myVolumeChanged', level)
            }
          });
        });
        room.participants.forEach(participant => {
          console.log(
            `Participant "${participant.identity}" is connected to the Room`,
          );
          participant.tracks.forEach(publication => {
            publication.on('subscriptionFailed', subscriptionFailed);
          });
          listenToSubscriptionEvents(participant);
        });

        room.on('participantDisconnected', p => {
          console.log(
            `Participant "${p.identity}" has disconnected from the Room`,
          );
          delete participantAudio[p.identity];

          /*
          if (!myMicOnMute) {
            videoMe.audioTracks.forEach((publication) => {
              console.log("refreshing my mic");
              publication.track.disable();
              publication.track.enable();
            });
          }*/
        });

        //Notification(get(_)("YourAudioIsReady"));
        RoomReady.set(true);
      },
      error => {
        console.log('Unable to connect to Room: ', error);
        Notification(
          get(_)(
            'FailedToConnectToAudioTryingAgainIfThisDialogKeepsShowingUpMultipleTimesPleaseReloadThePage',
          ),
        );
        //let's try again later
        setTimeout(async () => {
          reConnect();
        }, 1000);
      },
    );
  },

  disconnect: () => {
    if (!videoMe) return;
    videoMe.audioTracks.forEach(publication => {
      publication.track.disable();
      console.log('disabled', publication.track);
    });
    theRoom.disconnect();
  },
};
io.on('moveScreenCaptureToDashboard', data => {
  console.log({ data });
  moveScreenCaptureToDashboard(document.getElementById(data.uid));
});

io.on('moveScreenCaptureToRoom', data => {
  console.log('moveScreenCaptureToRoom', data);
  let el = document.getElementById(data.uid);
  moveScreenCaptureToRoom(el);
});

const reConnect = async () => {
  let videoTokenRes = await ioGet('getVideoToken');
  console.log('video token res ', videoTokenRes);
  videoController.init(videoTokenRes.token);
};

io.on('audioOnDistance', data => {
  /**
   * Finding the own event emitter
   */
  if (data.me !== schoolRole?.id) return;
  if (!theRoom) return;

  theRoom.participants.forEach(participant => {
    /**
     * Finding event that belongs to particular participant. To determine volume level, megaphone mode etc.
     */
    if (participant.identity !== data.participantId) return;
    participant.tracks.forEach(publication => {
      if (!data.megaphone) {
        /**
         * Participant without megaphone. Attaching track if still not attached
         */
        if (
          !participantAudio[data.participantId].soundEl &&
          publication.track
        ) {
          const elements = publication.track.detach();
          elements.forEach(el => el.remove());

          participantAudio[data.participantId].soundEl =
            publication.track.attach();
        } else {
          /**
           * Optimized for particpants with the same volume level not set many times
           */

          if (
            participantAudio[data.participantId].soundEl.volume !== data.level
          ) {
            participantAudio[data.participantId].soundEl.volume = data.level;
          }
        }
      } else {
        /**
         * Participant with megaphone always the same level of volume
         * Optimized no to set it many times
         */
        if (
          participantAudio[data.participantId].soundEl.volume !==
          MEGAPHONE_VOLUME_LEVEL
        ) {
          participantAudio[data.participantId].soundEl.volume =
            MEGAPHONE_VOLUME_LEVEL;
        }
      }
      /**
       * Streaming info for ui
       */
      updatePersonasStore(data);
    });
  });
});

const updatePersonasStore = data => {
  /**
   * Optimized. Checking whether this status already matched
   */
  const persona = personasStore.get(data.participantId);
  if (!persona) return;
  if (!data.megaphone) {
    /**
     * Participant without megaphone
     */
    if (data.level) {
      if (persona.isMutable && persona.isNear) return;
      personasStore.update(data.participantId, {
        isMutable: true,
        isNear: true,
      });
    } else {
      if (persona.isMutable && !persona.isNear) return;
      personasStore.update(data.participantId, {
        isMutable: true,
        isNear: false,
      });
    }
  } else {
    /**
     * Participant with megaphone
     */
    if (data.level) {
      if (!persona.isMutable && persona.isNear) return;
      personasStore.update(data.participantId, {
        isMutable: false,
        isNear: true,
      });
    } else {
      if (!persona.isMutable && !persona.isNear) return;
      personasStore.update(data.participantId, {
        isMutable: false,
        isNear: false,
      });
    }
  }
};

const RoomReady = writable(false);
export {
  videoController,
  RoomReady,
  startScreenCapture,
  stopScreenCapture,
  startWebcam,
  stopWebcam,
  muteAudio,
  normalAudio,
  moveScreenCaptureToDashboard,
  moveScreenCaptureToRoom,
};
