import { _ } from 'svelte-i18n';
import { get } from 'svelte/store';
import myConfirm from './myConfirm';
const areYouSure = (title, callback) => {
  myConfirm(
    get(_)('AreYouSure', {
      values: {
        title: title,
      },
    }),
    callback,
  );
};
export default areYouSure;
