import { getSource } from '../../actions/mediaItemController';
import { resetDashboardGumball, resetGumball } from '../resizeGumball';
import {
  gumballTargetType,
  gumballTarget,
  dashboardGumballTarget,
} from '../store';
import { showAFRAME } from '../visibilityAFRAME';
import getBoundingBox from './getBoundingBox';
import resetFocus from './resetFocus';

const initGumball = (component, isDashboard = false) => {
  if (component.data.sourceType == 'gltf-model') {
    component.el.addEventListener('model-loaded', () => {
      var boxSize = getBoundingBox(component.el.object3D);
      component.el.object3D.traverse(node => {
        if (node.isMesh) {
          node.frustumCulled = false;
        }
      });
      if (isDashboard) {
        var clickCatcher = document.createElement('a-entity');
        let scale = 0.2 / component.el.getAttribute('scale').x;
        clickCatcher.setAttribute('scale', scale + ' ' + scale + ' ' + scale);
        clickCatcher.setAttribute('look-at', '#myCamera');
        let offset = scale / 2;
        clickCatcher.setAttribute('position', '0 ' + offset + ' 0');
        clickCatcher.setAttribute('visible', true);
        let backPlate = document.createElement('a-plane');
        backPlate.setAttribute('color', 'lightblue');
        backPlate.setAttribute('material', 'opacity:0.2');
        backPlate.setAttribute('position', '0 0 -0.5');
        clickCatcher.appendChild(backPlate);
      } else {
        var clickCatcher = document.createElement('a-sphere');
        clickCatcher.setAttribute('radius', boxSize / 4);
        clickCatcher.setAttribute('phi-length', 180);
        clickCatcher.setAttribute('rotation', '-90 0 0');
        clickCatcher.setAttribute('visible', false);
      }
      clickCatcher.setAttribute('material', 'wireframe:true;side:double');
      clickCatcher.id = 'clickCatcherOf_' + component.el.id;
      component.el.appendChild(clickCatcher);
    });
  }
  component.el.addEventListener('click', evt => {
    if (
      component.data.sourceType == 'audio' ||
      component.data.sourceType == 'video' ||
      component.data.sourceType == '360video'
    ) {
      getSource(component.el);
    }
    gumballTargetType.set(component.data.sourceType);
    if (isDashboard) {
      dashboardGumballTarget.set(component.el);
    } else {
      gumballTarget.set(component.el);
    }

    resetFocus();
    showAFRAME(component.gumball);

    // get three.js object from aframe entity
    if (isDashboard) {
      resetDashboardGumball(component.el);
    } else {
      resetGumball(component.el);
    }
  });
};
export default initGumball;
