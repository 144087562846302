<script lang="ts">
  import PersonaBlock from './PersonaBlock.svelte';
  import ExamBox from './ExamBox.svelte';
  import Button from '../UI/Button.svelte';
  import CustomFieldDisplay from './CustomFieldComponents/CustomFieldDisplay.svelte';
  import { onMount } from 'svelte';
  import { _ } from 'svelte-i18n';
  import { ioGet } from '../../lib/realtime';
  import {
    TargetGrade,
    TargetGroup,
    TargetInterval,
    TargetProgramCategory,
    School,
    SchoolRole,
  } from '../../lib/store';
  import Icon from '../UI/Icon.svelte';
  import GroupBlock from './GroupBlock.svelte';
  export let interval;
  export let subscriber;
  export let withExams: boolean = true;
  onMount(async () => {
    subscriber.grades = {};
    interval.programCategories.forEach(programCategory => {
      if (programCategory.useToGroupGrades) {
        programCategory.groups.forEach(async group => {
          if (
            subscriber.registrations.some(
              registration => registration.programID == group.id,
            )
          ) {
            let grade = await ioGet('getGrade', {
              subscriberID: subscriber.id,
              programID: group.id,
            });
            subscriber.grades[group.id] = grade;
          }
          group.children.forEach(async child => {
            if (
              subscriber.registrations.some(
                registration => registration.programID == child.id,
              )
            ) {
              let grade = await ioGet('getGrade', {
                subscriberID: subscriber.id,
                programID: child.id,
              });
              subscriber.grades[child.id] = grade;
            }
          });
        });
      }
    });
  });
  const openGradeEditModal = (grade, group, programCategory) => {
    TargetGrade.set(grade);
    TargetGroup.set(group);
    TargetInterval.set(interval);
    TargetProgramCategory.set(programCategory);
    UIkit.modal('#gradeEdit').show();
  };
</script>

{#if subscriber.grades}
  {@const customFieldsToShow = interval.customFields['grade'].filter(
    field => field.showOnList && field.permissions[$SchoolRole.roleID].canRead,
  )}
  <div style="overflow-x:auto">
    <table
      class="uk-table uk-table-divider uk-table-justify uk-table-small uk-table-middle"
    >
      <thead>
        <tr>
          <th />
          {#if withExams}
            <th>
              {$_('grades.Exams')}
            </th>
          {/if}

          {#each customFieldsToShow || [] as field, i}
            <th class="uk-text-center">
              {field.title}
            </th>
          {/each}
        </tr>
      </thead>
      <tbody>
        {#each interval.programCategories.filter(category => category.useToGroupAssignment) as programCategory}
          <tr hidden>
            <th>
              {programCategory.title}
            </th>
          </tr>
          {#each programCategory.groups as group}
            {@const grade = subscriber.grades[group.id] || {
              customFieldValues: {},
            }}

            {#if group.children.length}
              {@const groupChildren = group.children.filter(child =>
                subscriber.registrations.some(
                  registration => registration.programID == child.id,
                ),
              )}
              {#if groupChildren.length}
                <tr>
                  <th>
                    <div class="uk-margin-small-left">
                      {group.title}
                    </div>
                  </th>
                </tr>
                {#each groupChildren as child}
                  {@const grade = subscriber.grades[child.id] || {
                    customFieldValues: {},
                  }}
                  <tr>
                    <td>
                      <div class="uk-margin-left">
                        <GroupBlock small groupID={child.id} />
                      </div>
                    </td>
                    {#if withExams}
                      <td>
                        <div class="uk-flex">
                          {#each grade.exams || [] as exam, i}
                            <ExamBox readonly {grade} bind:exam {i} />
                          {/each}
                        </div>
                      </td>
                    {/if}

                    {#each customFieldsToShow || [] as field}
                      <td
                        class="uk-text-center"
                        style="min-width:150px;padding-right:0px;"
                      >
                        <CustomFieldDisplay
                          {field}
                          value={grade.customFieldValues[field.id]}
                          fields={interval.customFields['grade']}
                          values={grade.customFieldValues}
                          parentFields={[
                            ...programCategory.customFields,
                            ...programCategory.childCustomFields,
                          ]}
                          parentValues={child.customFieldValues}
                        />
                      </td>
                    {/each}
                    <td style="min-width:40px;">
                      {#if $School.roles.find(role => role.id == $SchoolRole.roleID).school.grade.canEdit}
                        <Button
                          style="link"
                          onclick={() =>
                            openGradeEditModal(grade, child, programCategory)}
                        >
                          <Icon icon="edit" size="large" />
                        </Button>
                      {:else}
                        <Button
                          style="link"
                          onclick={() =>
                            openGradeEditModal(grade, child, programCategory)}
                        >
                          <Icon icon="circle-info" size="large" />
                        </Button>
                      {/if}
                    </td>
                  </tr>
                {/each}
              {/if}
            {:else if subscriber.registrations.some(registration => registration.programID == group.id)}
              <tr>
                <td>
                  <div class="uk-margin-small-left">
                    <GroupBlock small groupID={group.id} />
                  </div>
                </td>
                {#if withExams}
                  <td>
                    <div class="uk-flex">
                      {#each grade.exams || [] as exam, i}
                        <ExamBox readonly {grade} bind:exam {i} />
                      {/each}
                    </div>
                  </td>
                {/if}

                {#each customFieldsToShow || [] as field}
                  <td
                    class="uk-text-center"
                    style="min-width:150px;padding-right:0px;"
                  >
                    <CustomFieldDisplay
                      {field}
                      value={grade.customFieldValues[field.id]}
                      fields={interval.customFields['grade']}
                      values={grade.customFieldValues}
                      parentFields={[
                        ...programCategory.customFields,
                        ...programCategory.childCustomFields,
                      ]}
                      parentValues={group.customFieldValues}
                    />
                  </td>
                {/each}
                <td style="min-width:40px;">
                  {#if $School.roles.find(role => role.id == $SchoolRole.roleID).school.grade.canEdit}
                    <Button
                      style="link"
                      onclick={() =>
                        openGradeEditModal(grade, group, programCategory)}
                    >
                      <Icon icon="edit" size="large" />
                    </Button>
                  {:else}
                    <Button
                      style="link"
                      onclick={() =>
                        openGradeEditModal(grade, group, programCategory)}
                    >
                      <Icon icon="circle-info" size="large" />
                    </Button>
                  {/if}
                </td>
              </tr>
            {/if}
          {/each}
        {/each}
      </tbody>
    </table>
  </div>
{/if}
