<script lang="ts">
  import { onMount } from 'svelte';
  import sortByKey from '../../lib/helpers/sortByKey';
  import { ioGet } from '../../lib/realtime';
  import { UUID } from '../../lib/uuid';
  import { School, SchoolRole } from '../../lib/store';
  import AttendanceCreditRow from './AttendanceCreditRow.svelte';
  import Button from '../UI/Button.svelte';
  import AttendanceCredit from './AttendanceCredit.svelte';
  import SummaryFieldHeading from './SummaryFieldHeading.svelte';
  import GroupBlock from './GroupBlock.svelte';
  export let view;
  export let saveCategory = () => {};
  export let interval;
  export let subscriber;
  let events = [];
  onMount(async () => {
    console.log('view', view);
    events = await ioGet('getAttendanceCredits', {
      categoryLabel: view.categoryLabel,
      categoryLabels: view.categoryLabels,
      schoolRoleID: subscriber.id,
      intervalID: interval.id,
    });
    events = sortByKey(events, {
      key: 'start',
      direction: 'asc',
    });
    console.log(events);
  });
  let summaryFieldEditMode = false;
</script>

<table
  class="uk-table uk-table-divider uk-table-small uk-table-justify uk-table-middle"
>
  <thead>
    <th />
    {#each view.customFields || [] as summaryField, i}
      <th class="uk-text-center">
        <SummaryFieldHeading
          bind:summaryField
          bind:summaryFields={view.customFields}
          sourceCustomFields={$School.customFields.attendance}
          save={saveCategory}
          {i}
        />
      </th>
    {/each}
    <th>
      {#if $School.roles.find(role => role.id == $SchoolRole.roleID).school.canEdit}
        <Button
          style="link"
          onclick={() => {
            if (typeof view.customFields == 'undefined') view.customFields = [];
            view.customFields = [
              ...view.customFields,
              {
                id: UUID(),
                target: '',
                calculation: '',
                value: '',
              },
            ];
            summaryFieldEditMode = true;
          }}
        >
          <i class="fa-duotone fa-plus" />
        </Button>
      {/if}
    </th>
  </thead>
  <tbody>
    {#each interval.programCategories.filter(category => category.useToGroupAssignment && category.useAttendanceCredit) as programCategory}
      <tr hidden>
        <th>
          {programCategory.title}
        </th>
      </tr>
      {#each programCategory.groups as group}
        {#if group.children.length}
          {@const groupChildren = group.children.filter(child =>
            subscriber.registrations.some(
              registration => registration.programID == child.id,
            ),
          )}
          {#if groupChildren.length}
            <tr>
              <th>
                <div class="">
                  {group.title}
                </div>
              </th>
            </tr>
            {#each groupChildren as child}
              <tr>
                <td>
                  <div class="uk-margin-small-left">
                    <GroupBlock small groupID={child.id} />
                  </div>
                </td>
                {#if events.length}
                  {#key view}
                    <AttendanceCreditRow
                      events={events.filter(event =>
                        event.linkedPrograms.some(
                          program => program == child.id,
                        ),
                      )}
                      {subscriber}
                      {view}
                      group={child}
                    />
                  {/key}
                {/if}
              </tr>
            {/each}
          {/if}
        {:else if subscriber.registrations.some(registration => registration.programID == group.id)}
          <tr>
            <td>
              <div class="">
                <GroupBlock small groupID={group.id} />
              </div>
            </td>
            {#if events.length}
              {#key view}
                <AttendanceCreditRow
                  events={events.filter(event =>
                    event.linkedPrograms.some(program => program == group.id),
                  )}
                  {subscriber}
                  {view}
                  {group}
                />
              {/key}
            {/if}
          </tr>
        {/if}
      {/each}
    {/each}
  </tbody>
</table>
