<script lang="ts">
  import Input from '../UI/Input.svelte';
  import CustomFieldInput from './CustomFieldComponents/CustomFieldInput.svelte';
  import ArrayNav from './ArrayNav.svelte';
  import arraySwap from '../../lib/helpers/arraySwap';
  import areYouSure from '../../lib/helpers/areYouSure';
  import { Route } from 'svelte-routing';
  import EntityThumbnail from './EntityThumbnail.svelte';

  export let customFields;
  export let arr;
  export let row;
  export let i: number;
  const moveUp = (i: number) => {
    arr = arraySwap(arr, i, i - 1);
  };
  const moveDown = (i: number) => {
    arr = arraySwap(arr, i, i + 1);
  };
  const remove = (i: number) => {
    areYouSure(row.title, () => {
      arr.splice(i, 1);
      arr = arr;
    });
  };
</script>

<tr>
  <td>
    <EntityThumbnail bind:entity={row} />
  </td>
  <td style="min-width:150px;padding-right:0px;">
    <Input bind:value={row.title} />
  </td>
  {#each customFields as field}
    <td style="min-width:150px;padding-right:0px;">
      <CustomFieldInput {field} bind:value={row.customFieldValues[field.id]} />
    </td>
  {/each}
  <td>
    <ArrayNav {moveUp} {moveDown} {remove} {i} arrayLength={arr.length} />
  </td>
</tr>
