<script>
  import { User, SchoolRole } from '../../lib/store';
  import UI3D from './UI3D.svelte';
  import {
    dashboardClose,
    dashboardOut,
    ringHeight,
    ringRadius,
  } from '../../lib/defaultValues';
  import Button from '../UI/Button.svelte';
</script>

{#if $SchoolRole}
  <a-entity
    id={$SchoolRole?.id}
    class="user"
    my-touch-controls
    movement-controls="
    speed:0.2;
    constrainToNavMesh:true;
    camera:[look-controls];"
    ><!-- rig -->

    <a-entity
      position="0 1.6 0"
      look-controls="reverseTouchDrag:true"
      id="look-rigger"
    >
      <a-entity
        position="0 0 0"
        id="myCamera"
        class="myCamera"
        camera="active:true"
      >
        <UI3D id="UI3D" />
      </a-entity>

      <a-entity offset-head-bone id="myAvatarPivot" position="0 0 0"
        ><!-- avatar pivot-->
        <a-entity
          id="myAvatar"
          position="0 -0.65 0.3"
          rotation="0 180 0"
          hide-hands
          animation-mixer
          visible="false"
          slave-neck-to-hands="targetId:{$SchoolRole?.id};useFace:false"
        />
        <a-entity id="myAvatarEyes" position="0 0 0" />
        <a-entity htmlEmbed="ppu:500" look-at="#myCamera" position="0 0.2 0">
          <div
            style="color:white;width:500px;text-align:center"
            id="myNickname"
          />
        </a-entity>
        <a-cylinder
          class="rayTarget"
          material="opacity:0.3"
          radius={ringRadius * 1.2}
          open-ended="true"
          side="double"
          height="0.1"
          color="#000"
          id="myDashboard"
          position="0 0 {dashboardOut}"
          rotation="0 230 0"
          float-around
          disable-look-while-dragging
        />
        <a-entity dashboard-handle id="myDashboardHandle">
          <a-circle
            position="-0.06 0 0"
            radius="0.05"
            width="150"
            material="flatShading:true"
            class="rayTarget"
            on-click="action:zoomDashboardOut"
            src="/images/icons/zoomOut.png"
          />
          <a-circle
            position="0.06 0 0"
            radius="0.05"
            width="150"
            material="flatShading:true"
            class="rayTarget"
            on-click="action:zoomDashboardIn"
            src="/images/icons/zoomIn.png"
          />
        </a-entity>
      </a-entity>
    </a-entity>
  </a-entity>
{/if}
