<script>
  import Input from '../UI/Input.svelte';

  import { onMount } from 'svelte';
  import { _ } from 'svelte-i18n';
  import { School, SchoolRole } from '../../lib/store';
  import { UUID } from '../../lib/uuid';
  import Button from '../UI/Button.svelte';
  import Checkbox from '../UI/Checkbox.svelte';
  import Dropdown from '../UI/Dropdown.svelte';
  import Lead from '../UI/Lead.svelte';
  import AttendanceBlock from './AttendanceBlock.svelte';
  import CustomFieldEditOrDisplay from './CustomFieldComponents/CustomFieldEditOrDisplay.svelte';
  import EntityThumbnail from './EntityThumbnail.svelte';
  import HourNameSelect from './HourNameSelect.svelte';
  import TinyTextarea from './TinyTextarea.svelte';
  export let recurring = false;
  export let schoolEvent = null;
  export let presetEditMode = false;
  let eventSchool = null;
  let interval = null;
  let chatrooms = [];
  console.log(schoolEvent);
  onMount(() => {
    if (typeof schoolEvent.customFieldValues == 'undefined') {
      schoolEvent.customFieldValues = {};
    }
    interval =
      $School.intervals[
        $School.intervals.findIndex(interval => {
          if (!$School.features.useIntervals) return true;
          if (
            interval.startOn <= schoolEvent.startDate &&
            interval.endOn >= schoolEvent.startDate
          ) {
            return true;
          }
        })
      ];
    if (interval) {
      chatrooms = [];
      interval.programCategories.forEach(programCategory => {
        if (programCategory.useChatroom) {
          programCategory.groups.forEach(group => {
            chatrooms.push({
              id: group.id,
              title: group.title,
            });
            group.children.forEach(child => {
              chatrooms.push({
                id: child.id,
                title: child.title,
              });
            });
          });
        }
      });
    }
    console.log('chatrooms', chatrooms);
  });
  const removeAttendanceSetting = i => {
    schoolEvent.attendanceSettings.splice(i, 1);
    schoolEvent = schoolEvent;
  };
</script>

{#if typeof schoolEvent != 'undefined' && schoolEvent && typeof schoolEvent.filterCategories != 'undefined' && $School && $SchoolRole}
  <div uk-margin class="uk-margin">
    <div uk-grid>
      {#if !presetEditMode}
        <div>
          <Input
            label={$_('event.EventCategory')}
            bind:value={schoolEvent.categoryLabel}
          />
        </div>
      {/if}

      <div>
        <Input
          label={$_('event.LabelColor')}
          type="color"
          bind:value={schoolEvent.backgroundColor}
        />
      </div>
    </div>
    <hr />
    <div>
      <div uk-grid class="uk-grid-small">
        <div>
          <EntityThumbnail bind:entity={schoolEvent} />
        </div>
        <div>
          <Input
            label={$_('event.EventTitle')}
            type="text"
            bind:value={schoolEvent.title}
          />
        </div>
      </div>
    </div>
    <hr />
    <div uk-grid>
      <div>
        <Lead>{$_('event.Schedule')}</Lead>
        {#if recurring}
          <div>
            <Checkbox
              bind:checked={schoolEvent.recurring}
              onchange={evt => {
                if (
                  schoolEvent.recurring &&
                  (typeof schoolEvent.rrule == 'undefined' ||
                    schoolEvent.rrule == null)
                ) {
                  schoolEvent.rrule = {
                    byweekday: [],
                    freq: 'weekly',
                  };
                }
              }}>{$_('event.Recurring')}</Checkbox
            >
          </div>
        {/if}

        <ul class="uk-subnav">
          <li>
            <Checkbox bind:checked={schoolEvent.allDay}
              >{$_('event.AllDay')}</Checkbox
            >
          </li>
          {#if !schoolEvent.recurring}
            <li>
              <Checkbox
                bind:checked={schoolEvent.multipleDays}
                onchange={evt => {
                  console.log(evt.target);
                  if (evt.target.checked) {
                    schoolEvent.useCustomTime = true;
                  } else {
                  }
                }}>{$_('event.MultipleDays')}</Checkbox
              >
            </li>
          {/if}
          {#if !schoolEvent.allDay}
            <li hidden={!$School.features?.useHourNames}>
              <Checkbox
                bind:checked={schoolEvent.useCustomTime}
                bind:disabled={schoolEvent.multipleDays}
                >{$_('event.UseCustomTime')}</Checkbox
              >
            </li>
          {/if}
        </ul>
        {#if schoolEvent.recurring && schoolEvent.rrule}
          <div>
            <Input
              type="date"
              label={$_('event.RecurringStart')}
              bind:value={schoolEvent.startDate}
            />
          </div>
          <div>
            <Input
              type="date"
              label={$_('event.RecurringEnd')}
              bind:value={schoolEvent.rrule.until}
            />
          </div>
          <ul class="uk-subnav">
            {#each ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'] as day, i}<!--0 == RRule.MO-->
              <li>
                <label>
                  <input
                    type="checkbox"
                    class="uk-checkbox"
                    bind:group={schoolEvent.rrule.byweekday}
                    name="daysOfWeek"
                    value={i}
                  />{$_(day)}
                </label>
              </li>
            {/each}
          </ul>
          {#if !schoolEvent.allDay}
            {#if schoolEvent.useCustomTime}
              <Input
                type="time"
                bind:value={schoolEvent.startAt}
                label={$_('event.StartTime')}
              />
              <Input
                type="time"
                bind:value={schoolEvent.endAt}
                label={$_('event.EndTime')}
              />
            {:else}
              <HourNameSelect bind:schoolEvent />
            {/if}
          {/if}
        {:else}
          <div>
            {#if !presetEditMode}
              <Input
                label={schoolEvent.multipleDays
                  ? $_('event.StartDate')
                  : $_('event.Date')}
                type="date"
                bind:value={schoolEvent.startDate}
              />
            {/if}
            {#if !schoolEvent.allDay}
              {#if schoolEvent.useCustomTime}
                <Input
                  label={$_('event.StartTime')}
                  type="time"
                  bind:value={schoolEvent.startAt}
                />
              {:else}
                <HourNameSelect bind:schoolEvent />
              {/if}
            {/if}
          </div>
          <div>
            {#if schoolEvent.multipleDays}
              {#if !presetEditMode}
                <Input
                  label={$_('event.EndDate')}
                  type="date"
                  bind:value={schoolEvent.endDate}
                  onchange={() => {
                    if (schoolEvent.endDate < schoolEvent.startDate)
                      schoolEvent.endDate = schoolEvent.startDate;
                  }}
                />
              {/if}
            {/if}
            {#if !schoolEvent.allDay}
              {#if schoolEvent.useCustomTime}
                <Input
                  label={$_('event.EndTime')}
                  type="time"
                  bind:value={schoolEvent.endAt}
                />
              {/if}
            {/if}
          </div>
        {/if}
      </div>
      <div>
        <!-- <Lead>{$_('event.Viewers')}</Lead>
        <ViewersCheckboxes
          bind:entity={schoolEvent}
          componentCategory="Event"
        /> -->
      </div>
      <div>
        <!--<Lead>{$_('event.VirtualClassroom')}</Lead>
        <ClassroomSelect bind:value={schoolEvent.roomID} />-->
        <Lead>{$_('event.Chatroom')}</Lead>
        {#key schoolEvent.linkedPrograms}
          <select class="uk-select" bind:value={schoolEvent.chatroom}>
            <option value="">{$_('Undefined')}</option>
            {#each $School.chatrooms as chatroom}
              <option value={chatroom.id}>{chatroom.title}</option>
            {/each}
            {#each chatrooms as chatroom}
              {#if schoolEvent.linkedPrograms.indexOf(chatroom.id) > -1}
                <option value={chatroom.id}>{chatroom.title}</option>
              {/if}
            {/each}
          </select>
        {/key}
      </div>
    </div>

    <TinyTextarea bind:value={schoolEvent.description} />
    {#if $School.features.useAttendances}
      <Lead>{$_('event.Attendance')}</Lead>
      {#if $School.roles.find(role => role.id == $SchoolRole.roleID).school.attendance?.canCreate}
        <Button style="text">{$_('event.TakeAttendanceFor')}</Button>
        <Dropdown>
          {#each $School.roles.filter(role => role.category != 'guest') as role}
            <li>
              <a
                href={'#'}
                on:click={() => {
                  schoolEvent.attendanceSettings.push({
                    id: UUID(),
                    roleID: role.id,
                    roleTitle: role.title,
                    attendance: [],
                  });
                  schoolEvent = schoolEvent;
                }}
              >
                {role.title}
              </a>
            </li>
          {/each}
        </Dropdown>
      {/if}
      <div>
        {#each schoolEvent.attendanceSettings || [] as setting, i}
          <AttendanceBlock
            {removeAttendanceSetting}
            {schoolEvent}
            bind:setting
            {i}
          />
        {/each}
      </div>
      {#if schoolEvent.attendanceSettings?.length || 0 > 0}
        <hr />
        <div>
          <div>
            <Checkbox bind:checked={schoolEvent.useDeadlineDate}
              >{$_('event.UseDateForAttendanceDeadline')}</Checkbox
            >
          </div>
          {#if schoolEvent.useDeadlineDate}
            <div class="uk-margin-small-left">
              <Input
                type="date"
                label={$_('event.AttendanceDeadline')}
                bind:value={schoolEvent.attendanceDeadline}
              />
            </div>
          {:else}
            <div class="uk-margin-small-left uk-margin">
              <div uk-grid class="uk-grid-small">
                <div>
                  <Input
                    type="number"
                    bind:value={schoolEvent.attendanceDeadlineDayCount}
                  />
                </div>
                <div class="uk-flex uk-flex-middle">
                  {$_('event.DaysBeforeTheDate')}
                </div>
              </div>
            </div>
          {/if}
          {#if schoolEvent.initialAttendanceStatus != 'attending'}
            <div>
              <Checkbox
                label={$_('event.LimitAttendance')}
                bind:checked={schoolEvent.limitAttendance}
              />
            </div>
          {/if}
          {#if !schoolEvent.autoAttendance && schoolEvent.limitAttendance}
            <div class="uk-margin-small-left">
              <Input
                label={$_('event.AttendanceLimitNumber')}
                type="number"
                bind:value={schoolEvent.attendanceLimitNumber}
              />
            </div>
          {/if}
        </div>
      {/if}
    {/if}
  </div>
  <hr />
  <ul class="uk-list uk-loist-small">
    {#each $School.customFields?.event || [] as field}
      {#if schoolEvent.customFieldValues}
        <li>
          <CustomFieldEditOrDisplay
            {field}
            bind:value={schoolEvent.customFieldValues[field.id]}
            fields={$School.customFields.event}
            values={schoolEvent.customFieldValues}
          />
        </li>
      {/if}
    {/each}
  </ul>
{/if}
