<script lang="ts">
  import { afterUpdate, beforeUpdate, onMount } from 'svelte';
  import { updateDashboardScale } from '../../actions/itemController';
  import { SchoolRole } from '../../lib/store';
  import { ioGet } from '../../lib/realtime';
  import { showAFRAME } from '../../lib/visibilityAFRAME';
  import Button from '../UI/Button.svelte';
  import Modal from '../UI/Modal.svelte';
  import { CometChat } from '@cometchat-pro/chat';
  import { _ } from 'svelte-i18n';
  import Lead from '../UI/Lead.svelte';
  import Icon from '../UI/Icon.svelte';
  import CustomFieldDisplay from './CustomFieldComponents/CustomFieldDisplay.svelte';
  import type { CustomFieldInterface } from '../../lib/interfaces/CustomField';

  export let groupID;
  export let withChat: Boolean = false;
  export let withCustomFields: boolean = true;
  export let customFields: Array<CustomFieldInterface> = [];
  let hasChat = false;
  export let small = false;
  let school = null;
  export let group = null;
  onMount(async () => {
    group = await ioGet('getGroup', {
      groupID: groupID,
    });
    if (!group) {
      groupID = '';
      return;
    }
    if (typeof group.title == 'undefined') {
      groupID = '';
      return;
    }
    hasChat = group.hasChat;
  });
</script>

{#if group}
  <div class="uk-grid-small" uk-grid>
    <div style:min-width={small ? '40px' : '80px'}>
      <img
        alt="icon"
        src={group.iconImageURL || '/images/no_photo.jpg'}
        style:height={small ? '40px' : '80px'}
        style:width={small ? '40px' : '80px'}
        class="uk-border-rounded uk-margin-small-bottom uk-box-shadow-small"
      />
    </div>

    <div class="uk-visible@s">
      {#if small}
        {group.title}
      {:else}
        <div>
          <Lead>
            {group.title}
          </Lead>
        </div>
      {/if}
      {#if withCustomFields}
        <ul
          class="uk-subnav uk-subnav-divider uk-margin-remove-top uk-margin-remove-bottom uk-text-small"
        >
          {#each customFields.filter(field => field.showOnBadge) as field}
            <li>
              <CustomFieldDisplay
                {field}
                fields={customFields}
                value={group.customFieldValues[field.id]}
                values={group.customFieldValues}
              />
            </li>
          {/each}
        </ul>
      {/if}
    </div>
    <div>
      {#if withChat && hasChat}
        <div class="uk-visible@m">
          <Button
            onclick={() => {
              CometChatWidget.chatWithGroup(group.id);
              UIkit.modal('#chatModal').show();
            }}>{$_('chatrooms.Enter')}</Button
          >
        </div>
        <div class="uk-hidden@m">
          <Button
            style="text"
            onclick={() => {
              CometChatWidget.chatWithGroup(group.id);
              UIkit.modal('#chatModal').show();
            }}
          >
            <Icon icon="comments" />
          </Button>
        </div>
      {/if}
    </div>
  </div>
{/if}
