import 'svelte-i18n';
import 'svelte/store';
import { hideModal, showModal } from '../../actions/modalController';
const myConfirm = (message, callback) => {
    showModal('#confirmModal');
    document.getElementById('confirmMessage').innerHTML = message;
    const cancelAction = () => {
        hideModal('#confirmModal');
        document.getElementById('confirmOK').removeEventListener('click', okAction);
    };
    const okAction = () => {
        callback();
        hideModal('#confirmModal');
        document
            .getElementById('confirmCancel')
            .removeEventListener('click', cancelAction);
    };
    document
        .getElementById('confirmCancel')
        .addEventListener('click', cancelAction, { once: true });
    document
        .getElementById('confirmOK')
        .addEventListener('click', okAction, { once: true });
};
export default myConfirm;
