<script lang="ts">
  import { afterUpdate, getContext, onMount } from 'svelte';
  import { ioGet } from '../../lib/realtime';
  import ExamBox from './ExamBox.svelte';
  import { _ } from 'svelte-i18n';
  import PersonaBlock from './PersonaBlock.svelte';
  import CustomFieldThead from './CustomFieldComponents/CustomFieldThead.svelte';
  import CustomFieldTbody from './CustomFieldComponents/CustomFieldTbody.svelte';
  import Input from '../UI/Input.svelte';
  import copy from '../../lib/helpers/copy';
  import {
    School,
    TargetProgramCategory,
    TargetGrade,
    TargetGroup,
    TargetInterval,
    SchoolRole,
  } from '../../lib/store';
  import Intervals from './Intervals.svelte';
  import areYouSure from '../../lib/helpers/areYouSure';
  import arraySwap from '../../lib/helpers/arraySwap';
  import dialog from '../../lib/helpers/dialog';
  import Button from '../UI/Button.svelte';
  import Console from '../Console.svelte';
  import { extname } from 'path';
  import { GreaterDepth } from 'three';
  import { saveIntervalCustomFields } from '../../lib/helpers/saveInterval';
  import CustomFieldInput from './CustomFieldComponents/CustomFieldInput.svelte';
  import CustomFieldEditOrDisplay from './CustomFieldComponents/CustomFieldEditOrDisplay.svelte';
  import GradeEdit from '../Modals/GradeEdit.svelte';
  import Icon from '../UI/Icon.svelte';
  import Dropdown from '../UI/Dropdown.svelte';
  import { personasStore } from '../../state/stores/personasStore';
  export let group;
  export let interval;
  export let programCategory;
  let school;
  let thisInterval;
  School.subscribe(obj => {
    if (obj) {
      thisInterval = copy(obj.intervals.find(int => int.id == interval.id));
    }
    school = obj;
  });
  let students = [];
  const save = async (withDialog = true) => {
    console.log(school);
    school.intervals = school.intervals.map(interval => {
      if (interval.id == thisInterval.id) {
        return thisInterval;
      }
      return interval;
    });
    await ioGet('updateSchool', {
      _id: $School._id,
      data: school,
    });
    School.set(school);
    if (withDialog) {
      dialog($_('Saved'));
    }
  };
  const saveGrades = async () => {
    let promises = [];
    students.forEach(student => {
      console.log(student.grade);
      promises.push(
        ioGet('updateGrade', {
          _id: student.grade._id,
          data: student.grade,
        }),
      );
    });
    Promise.all(promises).then(results => {
      results.forEach((res, i) => {});
      dialog($_('Saved'));
    });
    save(false);
  };
  onMount(async () => {
    console.log('searching registration for ', group.title);
    students = await ioGet('getRegisteredSubscribers', {
      id: group.id,
    });
    console.log(students);
    let promises = [];
    students.forEach(async student => {
      if (!student) return;
      promises.push(
        ioGet('getGrade', {
          subscriberID: student.id,
          programID: group.id,
        }),
      );
    });
    Promise.all(promises).then(results => {
      results.forEach((grade, i) => {
        students[i].grade = grade;
      });
    });
  });
  const moveUp = (i: number) => {
    thisInterval.customFields['grade'] = arraySwap(
      thisInterval.customFields['grade'],
      i,
      i - 1,
    );
  };
  const moveDown = (i: number) => {
    thisInterval.customFields['grade'] = arraySwap(
      thisInterval.customFields['grade'],
      i,
      i + 1,
    );
  };
  const remove = (i: number) => {
    areYouSure(thisInterval.customFields['grade'][i].title, () => {
      thisInterval.customFields['grade'].splice(i, 1);
      thisInterval.customFields['grade'] = thisInterval.customFields['grade'];
    });
  };
  const openGradeEditModal = grade => {
    TargetGrade.set(grade);
    TargetGroup.set(group);
    TargetInterval.set(interval);
    TargetProgramCategory.set(programCategory);
    UIkit.modal('#gradeEdit').show();
  };

  const addExamForAll = () => {};
  let newExam = {
    title: '',
    date: '',
  };
  let newGrade = {
    exams: [],
  };
</script>

<div style="overflow-x:auto">
  <table
    class="uk-table uk-table-divider uk-table-justify uk-table-small
    uk-table-middle"
  >
    <thead>
      <tr>
        <th />
        <th>
          {$_('grades.Exams')}
          <Button onclick={() => {}} style="text">
            <Icon icon="plus" size="large" />
            {$_('grades.AddExamForAll')}
          </Button>
          <Dropdown>
            <ExamBox
              withTrash={false}
              bind:grade={newGrade}
              bind:exam={newExam}
              i={0}
            />
            <Button
              onclick={() => {
                students = students.map(student => {
                  student.grade.exams = [
                    ...(student.grade.exams || []),
                    copy(newExam),
                  ];
                  return student;
                });
              }}
            >
              {$_('Save')}
            </Button>
          </Dropdown>
        </th>

        {#each thisInterval.customFields['grade'].filter(field => field.showOnList) || [] as field, i}
          <th class="uk-text-center">{field.title}</th>
        {/each}
      </tr>
    </thead>
    <tbody>
      {#each students as student}
        {#if student}
          <tr>
            <td>
              <PersonaBlock small withRole={false} schoolRoleID={student.id} />
            </td>
            {#if student.grade}
              <td>
                <div class="uk-flex">
                  {#each student.grade.exams || [] as exam, i}
                    <ExamBox bind:grade={student.grade} bind:exam {i} />
                  {/each}
                  <div style="min-width:40px">
                    <Button
                      small
                      style="link"
                      onclick={() => {
                        student.grade.exams = [
                          ...(student.grade.exams || []),
                          { date: '', score: '' },
                        ];
                      }}
                    >
                      <i class="fa-duotone fa-plus fa-fw" />
                    </Button>
                  </div>
                </div>
              </td>

              {#each thisInterval.customFields['grade'].filter(field => field.showOnList) || [] as field}
                <td
                  class="uk-text-center"
                  style="min-width:150px;padding-right:0px;"
                >
                  <CustomFieldEditOrDisplay
                    withLabel={false}
                    {field}
                    bind:value={student.grade.customFieldValues[field.id]}
                    fields={thisInterval.customFields['grade']}
                    values={student.grade.customFieldValues}
                    parentFields={[
                      ...programCategory.customFields,
                      ...programCategory.childCustomFields,
                    ]}
                    parentValues={group.customFieldValues}
                  />
                </td>
              {/each}
              <td style="min-width:40px;">
                {#if $School.roles.find(role => role.id == $SchoolRole.roleID).school.grade.canEdit}
                  <Button
                    style="link"
                    onclick={() => openGradeEditModal(student.grade)}
                  >
                    <Icon icon="edit" size="large" />
                  </Button>
                {:else}
                  <Button
                    style="link"
                    onclick={() => openGradeEditModal(student.grade)}
                  >
                    <Icon icon="circle-info" size="large" />
                  </Button>
                {/if}
              </td>
            {/if}
          </tr>
        {/if}
      {/each}
    </tbody>
  </table>
</div>
<Button onclick={saveGrades}>{$_('Save')}</Button>
