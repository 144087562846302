<script lang="ts">
  import { onMount } from 'svelte';
  import { assign } from 'svelte/internal';

  import { calculateSummaryEachRow } from '../../lib/helpers/calculateSummaryEachRow';
  import { defineIfUndefined } from '../../lib/helpers/defineIfUndefined';
  import { ioGet } from '../../lib/realtime';
  import { assignmentsStore } from '../../state/stores/assignmentsStore';
  import { submissionsStore } from '../../state/stores/submissionsStore';

  import Submission from './Submission.svelte';
  export let assignmentCategory;
  export let group;
  export let interval;
  export let subscriber;
  export let showCheckbox: boolean = false;
  export let selectedAssignment: string = '';
  let assignments = [];
  let submissions = [];
  let row = { summaryFieldValues: {} };
  const loadSubmissions = async () => {
    let promises = [];
    assignments.forEach(assignment => {
      promises.push(
        ioGet('getSubmission', {
          subscriberID: subscriber.id,
          assignmentID: assignment.id,
        }),
      );
    });
    return Promise.all(promises).then(async results => {
      let items = results.map(res => {
        res.customFieldValues = defineIfUndefined(res.customFieldValues, {});
        return res;
      });
      submissionsStore.addMany(items);
    });
  };
  const calculateSummary = async () => {
    row = await calculateSummaryEachRow(
      group,
      assignments,
      assignmentCategory,
      subscriber,
      row,
      submissions,
    );
  };
  submissionsStore.subscribe(async array => {
    submissions = submissionsStore
      .list()
      .filter(
        submission =>
          submission.subscriberID == subscriber.id &&
          assignments
            .map(assignment => assignment.id)
            .indexOf(submission.assignmentID) > -1,
      );
    await calculateSummary();
  });

  assignmentsStore.subscribe(async array => {
    if (assignmentsStore.list().length) {
      assignments = assignmentsStore
        .list()
        .filter(assignment => assignment.group == group.id);
      await loadSubmissions();
      await calculateSummary();
    }
  });
</script>

{#each assignmentCategory.submissionSummaryFields || [] as summaryField}
  <td class="uk-text-center">
    {#if row}
      {#if row.summaryFieldValues}
        {#if row.summaryFieldValues[summaryField.id]}
          {row.summaryFieldValues[summaryField.id].value}
        {/if}
      {/if}
    {/if}
  </td>
{/each}
<td>
  <div style="max-height:200px;overflow:auto">
    <div uk-grid class="uk-grid-collapse">
      {#each assignments as assignment, i}
        {@const submission = submissions.find(
          submission =>
            submission.subscriberID == subscriber.id &&
            submission.assignmentID == assignment.id,
        )}
        {#if submission && group}
          <div class="uk-margin-small-right">
            <Submission
              {selectedAssignment}
              {showCheckbox}
              {calculateSummary}
              {submission}
              {subscriber}
              {interval}
              {assignment}
              {group}
              category={assignmentCategory}
            />
          </div>
        {/if}
      {/each}
    </div>
  </div>
</td>
