<script>
  import Cookies from 'js-cookie';
  import { _ } from 'svelte-i18n';
  import { HOST } from '../lib/config';
  import dialog from '../lib/helpers/dialog';
  import validateSlug from '../lib/helpers/validateSlug';
  import schoolPreset from '../lib/presets/school';
  import { ioGet } from '../lib/realtime';
  import { School } from '../lib/store';
  import EnterCard from './blocks/EnterCard.svelte';
  import EntityThumbnail from './blocks/EntityThumbnail.svelte';
  import LinkToDocumentation from './blocks/LinkToDocumentation.svelte';
  import SlugInput from './blocks/SlugInput.svelte';
  import EditRoles from './EditRoles.svelte';
  import Button from './UI/Button.svelte';
  import Input from './UI/Input.svelte';
  import Lead from './UI/Lead.svelte';
  let school = schoolPreset;

  const createSchool = async () => {
    let res = await validateSlug(school.slug);
    console.log(res);
    if (res.status != 'success') {
      dialog($_(`slug.${res.message}`));
      return false;
    }
    let data = await ioGet('createSchool', {
      data: school,
    });
    console.log(data);
    if (data) {
      School.set(data.school);
      Cookies.set('role', data.roleID);
      location.href = '/' + data.school.slug + '/create-room';
    } else {
      alert('failed');
    }
  };
</script>

<svelte:head>
  <title>{$_('createSchool.CreateNewSchool')} - AVOS</title>
</svelte:head>
<EnterCard>
  <span slot="title">{$_('createSchool.CreateNewSchool')}</span>
  <span slot="description"
    >{$_('createSchool.PleaseProvideSomeInformation')}</span
  >
  <div slot="body">
    <Lead>{$_('createSchool.BasicInfo')}</Lead>
    <div class="uk-margin">
      <div uk-grid class="uk-grid-small">
        <div>
          <EntityThumbnail bind:entity={school} />
        </div>
        <div>
          <Input
            required
            bind:value={school.title}
            label={$_('schoolSetting.SchoolName')}
          />
        </div>
      </div>
    </div>
    <div class="uk-margin">
      <SlugInput
        beforeURL={HOST}
        label={$_('schoolSetting.SchoolURL')}
        bind:value={school.slug}
      />
    </div>
    <Lead>{$_('createSchool.UserRoles')}</Lead>
    <EditRoles short bind:school />
    <div class="uk-margin">
      <Button fullwidth onclick={createSchool}
        >{$_('permissions.CreateSchool')}</Button
      >
    </div>
  </div>
</EnterCard>
