const appendToDashboard = entity => {
  entity.setAttribute('position', '0 0 0');
  entity.classList.add('rayTarget');

  if (entity.getAttribute('sourceType') != 'gltf-model') {
    entity.setAttribute('rotation', '0 180 0');
  }
  entity.setAttribute('scale-to-fit-dashboard', { enabled: true });
  let offsetter = document.createElement('a-entity');
  offsetter.id = 'offsetter_' + entity.id;
  let panel = document.createElement('a-entity');
  panel.appendChild(entity);
  panel.id = 'panel_' + entity.id;
  offsetter.appendChild(panel);
  document.getElementById('myDashboard').appendChild(offsetter);
};
export default appendToDashboard;
