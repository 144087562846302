import { ioGet } from '../realtime';
import Cookies from 'js-cookie';
import { HOST } from '../config';
const logout = async () => {
    await ioGet('logout', {
        login: Cookies.get('login'),
    });
    Cookies.remove('login');
    location.href = HOST;
};
export default logout;
