<script lang="ts">
  import { _ } from 'svelte-i18n';
  import { Notification } from '../actions/notification';
  import areYouSure from '../lib/helpers/areYouSure';
  import arraySwap from '../lib/helpers/arraySwap';
  import { defineIfUndefined } from '../lib/helpers/defineIfUndefined';
  import type { ChatroomInterface } from '../lib/interfaces/ChatroomInterface';
  import { ChatroomDefault } from '../lib/interfaces/ChatroomInterface';
  import { ioGet } from '../lib/realtime';
  import { School, SchoolRole } from '../lib/store';
  import { UUID } from '../lib/uuid';
  import ArrayNav from './blocks/ArrayNav.svelte';
  import EntityThumbnail from './blocks/EntityThumbnail.svelte';
  import IntervalsTab from './blocks/IntervalsTab.svelte';
  import Button from './UI/Button.svelte';
  import Input from './UI/Input.svelte';
  import Lead from './UI/Lead.svelte';
  import { removeGroup } from '../lib/helpers/chatLogin';

  let chatroomsPerInterval: Array<ChatroomInterface> = [];
  let chatrooms: Array<ChatroomInterface> = [];
  const initCometChatGroup = chatroom => {
    const group = new CometChatWidget.CometChat.Group(
      chatroom.id,
      chatroom.title,
      'public',
    );
    group.setIcon(chatroom.iconImageURL);
    CometChatWidget.createOrUpdateGroup(group).then(group => {
      // Proceed with launch
    });
  };
  School.subscribe(obj => {
    if (obj) {
      obj.chatrooms = defineIfUndefined(obj.chatrooms, []);
      obj.chatrooms.forEach(chatroom => {
        initCometChatGroup(chatroom);
      });
      obj.intervals.forEach(interval => {
        interval.programCategories.forEach(programCategory => {
          if (programCategory.useChatroom) {
            programCategory.groups.forEach(group => {
              if (
                $SchoolRole.registrations.some(
                  registration => registration.programID == group.id,
                )
              ) {
                let chatroom = {
                  ...ChatroomDefault,
                  id: group.id,
                  iconImageURL: group.iconImageURL,
                  title: group.title + '-' + interval.title,
                };
                chatroomsPerInterval.push(chatroom);
                initCometChatGroup(chatroom);
              }

              group.children.forEach(child => {
                if (
                  $SchoolRole.registrations.some(
                    registration => registration.programID == child.id,
                  )
                ) {
                  let chatroom = {
                    ...ChatroomDefault,
                    id: child.id,
                    iconImageURL: child.iconImageURL,
                    title: child.title + '-' + interval.title,
                  };
                  chatroomsPerInterval.push(chatroom);
                  initCometChatGroup(chatroom);
                }
              });
            });
          }
        });
      });
      chatrooms = obj.chatrooms;
    }
  });
  const addChatroom = () => {
    let chatroom = {
      ...ChatroomDefault,
      id: UUID(),
    };
    chatrooms = [...chatrooms, chatroom];
    initCometChatGroup(chatroom);
  };
  const remove = i => {
    areYouSure(chatrooms[i].title, async () => {
      await removeGroup(chatrooms[i].id);
      chatrooms.splice(i, 1);
      chatrooms = chatrooms;
      $School.chatrooms = chatrooms;
      Notification($_('Saved'));
    });
  };
  const moveUp = i => {
    chatrooms = arraySwap(chatrooms, i, i - 1);
  };
  const moveDown = i => {
    chatrooms = arraySwap(chatrooms, i, i + 1);
  };
  const saveChatroom = async () => {
    let res = await ioGet('updateSchool', {
      _id: $School._id,
      data: {
        chatrooms: chatrooms,
      },
    });
    $School.chatrooms = chatrooms;
    Notification($_('Saved'));
  };
</script>

<div class="uk-margin uk-width-expand">
  <Lead>
    {$_('Chatrooms')}
  </Lead>
  <ul class="uk-list uk-list-divider">
    {#each chatrooms as chatroom, i}
      <li>
        <div uk-grid class="uk-grid-small uk-child-width-auto">
          <div>
            <EntityThumbnail bind:entity={chatroom} />
          </div>
          <div>
            <Input type="text" bind:value={chatroom.title} />
          </div>
          <div>
            <ArrayNav
              {remove}
              {moveUp}
              {moveDown}
              {i}
              arrayLength={chatrooms.length}
            />
          </div>
          <div class="uk-text-right">
            <Button
              onclick={() => {
                CometChatWidget.chatWithGroup(chatroom.id);
                UIkit.modal('#chatModal').show();
              }}>{$_('chatrooms.Enter')}</Button
            >
          </div>
        </div>
      </li>
    {/each}
  </ul>
  <Button onclick={addChatroom}>{$_('Add')}</Button>
  <Button onclick={saveChatroom}>{$_('Save')}</Button>
</div>
<div class="uk-margin uk-width-expand">
  <Lead>
    {$_('chatrooms.ChatroomPerInterval')}
  </Lead>
  <IntervalsTab intervals={$School.intervals}>
    <ul slot="content" class="uk-switcher">
      {#each $School.intervals as interval}
        <li>
          <ul class="uk-list uk-list-divider">
            {#each chatroomsPerInterval as chatroom}
              <li>
                <div uk-grid class="uk-grid-small  uk-child-width-auto">
                  <div>
                    <img
                      alt="icon"
                      src={chatroom.iconImageURL || '/images/no_photo.jpg'}
                      style="height:80px;width:80px;"
                      class="uk-border-rounded uk-margin-small-bottom"
                    />
                  </div>
                  <div>
                    {chatroom.title}
                  </div>
                  <div class="uk-text-right">
                    <Button
                      onclick={() => {
                        CometChatWidget.chatWithGroup(chatroom.id);
                        UIkit.modal('#chatModal').show();
                      }}>{$_('chatrooms.Enter')}</Button
                    >
                  </div>
                </div>
              </li>
            {/each}
          </ul>
        </li>
      {/each}
    </ul>
  </IntervalsTab>
</div>
