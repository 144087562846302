<script lang="ts">
  import { _ } from 'svelte-i18n';
  import { School, SchoolRole } from '../../lib/store';
  import Dropdown from '../UI/Dropdown.svelte';
  export let label;
  export let affectPreset: (categoryID: string) => void;
  import Button from '../UI/Button.svelte';
  export let createCategory;
  export let entity;
</script>

<Button>{$_(label + '.SelectCategory')}</Button>
<Dropdown>
  {#each $School.eventCategories as category}
    <li value={category.id}>
      <a
        href={'#'}
        on:click={() => {
          affectPreset(category.id);
        }}
      >
        {category[label + 'Presets'].categoryLabel}
      </a>
    </li>
  {/each}
</Dropdown>
{#if $School.roles.find(schoolRole => schoolRole.id == $SchoolRole.roleID)?.school.master.canCreate}
  <Button onclick={() => createCategory(entity, label)}
    >{$_('CreateCategory')}</Button
  >
{/if}
<hr />
