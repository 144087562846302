<script lang="ts">
  import { onMount } from 'svelte';

  import sortByKey from '../../lib/helpers/sortByKey';
  import { ioGet } from '../../lib/realtime';

  import { School, SchoolRole, TargetEvent } from '../../lib/store';
  import Button from '../UI/Button.svelte';

  import Input from '../UI/Input.svelte';

  import Lead from '../UI/Lead.svelte';
  import { _ } from 'svelte-i18n';
  import HourNameSelect from './HourNameSelect.svelte';
  import CustomFields from '../CustomFields.svelte';
  import CustomFieldDisplay from './CustomFieldComponents/CustomFieldDisplay.svelte';
  import CustomFieldEditOrDisplay from './CustomFieldComponents/CustomFieldEditOrDisplay.svelte';
  import { createEvent, updateEvent } from '../../lib/helpers/updateEvent';
  import { EventDefault } from '../../lib/interfaces/EventInterface';
  import { DateTime, Interval, Settings } from 'luxon';
  import { now } from 'svelte/internal';
  import CategoryLabel from './CategoryLabel.svelte';
  import { affectPresetToEvent } from '../../lib/helpers/affectPresetToEvent';
  import Modal from '../UI/Modal.svelte';
  import EventEdit from './EventEdit.svelte';
  import areYouSure from '../../lib/helpers/areYouSure';
  import { calculateAttendanceSummaryEachRow } from '../../lib/helpers/calculateSummaryEachRow';
  import {
    eventCustomFields,
    eventCustomFieldValues,
  } from '../../lib/helpers/eventCustomFields';
  import MetaText from '../UI/MetaText.svelte';
  import { hideModal, showModal } from '../../actions/modalController';
  export let view;
  export let subscriber;
  export let interval;
  let eventToCreate = null;
  let events = [];
  onMount(async () => {
    events = await ioGet('getAttendanceCredits', {
      categoryLabels: view.categoryLabels,
      schoolRoleID: subscriber.id,
      intervalID: interval.id,
    });
    events = sortByKey(events, {
      key: 'start',
      direction: 'desc',
    });
    console.log(events);
  });
  const deleteEvent = async () => {
    areYouSure(eventToCreate.title, async () => {
      let res = await ioGet('deleteEvent', {
        _id: eventToCreate._id,
      });
      console.log(res);
      let i = events.findIndex(
        schoolEvent => schoolEvent._id == eventToCreate._id,
      );
      events.splice(i, 1);
      events = events;
      hideModal('#editEventList');
    });
  };
  const openEventEdit = schoolEvent => {
    eventToCreate = schoolEvent;
    showModal('#editEventAttendance');
  };
  const save = async () => {
    eventToCreate = await updateEvent(eventToCreate);
    let i = events.findIndex(
      schoolEvent => schoolEvent._id == eventToCreate._id,
    );
    events[i] = eventToCreate;
    events = sortByKey(events, {
      key: 'start',
      direction: 'desc',
    });
    hideModal('#editEventList');
  };
</script>

<div class="uk-overflow-auto">
  <table
    class="uk-table uk-table-divider uk-table-small uk-table-responsive uk-table-justify uk-table-middle"
  >
    <tbody>
      {#each events as schoolEvent}
        {@const attendance = schoolEvent.attendanceSettings
          .find(setting => setting.roleID == subscriber.roleID)
          .attendances.find(
            attendance => attendance.schoolRoleID == subscriber.id,
          )}
        <tr>
          <td>
            {DateTime.fromISO(schoolEvent.start).toLocaleString()}
          </td>
          <td>
            {schoolEvent.title}
          </td>
          {#each $School.customFields.attendance.filter(f => f.showOnList) as field}
            <td class="uk-text-right">
              <a href={'#'} on:click={openEventEdit(schoolEvent)}>
                <CustomFieldDisplay
                  value={attendance.customFieldValues[field.id]}
                  values={attendance.customFieldValues}
                  {field}
                  fields={$School.customFields.attendance}
                  parentFields={eventCustomFields($School)}
                  parentValues={eventCustomFieldValues(schoolEvent)}
                />
              </a>
            </td>
          {/each}
        </tr>
      {/each}
    </tbody>
  </table>
</div>
<Modal
  bgClose={false}
  withFooter
  id="editEventAttendance"
  size="container"
  title={$_('event.EditEvent')}
>
  <div slot="body">
    {#if eventToCreate}
      <EventEdit bind:schoolEvent={eventToCreate} />
    {/if}
  </div>
  <div slot="footer">
    <Button
      onclick={() => {
        hideModal('#editEventAttendance');
      }}>{$_('Cancel')}</Button
    >

    <Button onclick={save}>{$_('Save')}</Button>
    {#if $School.roles.find(schoolRole => schoolRole.id == $SchoolRole.roleID)?.school.event?.canDelete}
      <Button style="danger" onclick={deleteEvent}>{$_('Delete')}</Button>
    {/if}
  </div>
</Modal>
