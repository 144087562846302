<script>
  import { _ } from 'svelte-i18n';
  import { School } from '../../lib/store';
  import Button from '../UI/Button.svelte';
  export let attend;
  export let ask = false;
  export let unattend;
  export let value = '';
  export let onchange = () => {};
  export let disabledAttend = false;
  export let disabledAsk = false;
  export let disabledUnattend = false;
  export let disabledActual = false;
  export let actual = false;
  export let asking = true;
</script>

<div class="uk-button-group">
  {#if asking}
    {#if attend}
      <Button
        disabled={disabledAttend}
        style={value == 'attending' ? 'primary' : 'default'}
        onclick={() => {
          value = 'attending';
          onchange();
        }}
      >
        {$_('event.Attending')}
      </Button>
    {/if}
    {#if ask}
      <Button
        disabled={disabledAsk}
        style={value == 'ask' ? 'primary' : 'default'}
        onclick={() => {
          value = 'ask';
          onchange();
        }}
      >
        {$_('event.Ask')}
      </Button>
    {/if}
    {#if unattend}
      <Button
        disabled={disabledUnattend}
        style={value == 'unattending' ? 'primary' : 'default'}
        onclick={() => {
          value = 'unattending';
          onchange();
        }}
      >
        {$_('event.Unattending')}
      </Button>
    {/if}
  {/if}
</div>
