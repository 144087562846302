const sortByKey = (array, options) => {
  let sorted = [];
  sorted = array.sort((a, b) => {
    if (typeof a[options.key] == 'string') {
      return a[options.key].localeCompare(b[options.key]);
    } else {
      return a[options.key] - b[options.key];
    }
  });
  if (options.direction == 'desc') {
    sorted.reverse();
  }
  return sorted;
};
export default sortByKey;
