<script lang="ts">
  import { ioGet } from '../../lib/realtime';

  import { SchoolRole } from '../../lib/store';

  export let favorites = [];
  export let save;
</script>

{#if !$SchoolRole}
  <span class="uk-text-danger">
    <span class="uk-text-danger">
      <i class="fa-solid fa-heart fa-lg" />
    </span>
  </span>
  {favorites?.length || '0'}
{:else if favorites?.indexOf($SchoolRole.id) > -1}
  <a
    class=" uk-link-text uk-text-danger"
    on:click={() => {
      favorites.splice(favorites.indexOf($SchoolRole.id), 1);
      favorites = favorites;
      save();
    }}
  >
    <span class="uk-text-danger">
      <i class="fa-solid fa-heart fa-lg" />
    </span>
  </a>
  {favorites?.length || '0'}
{:else}
  <a
    class=" uk-link-text"
    on:click={() => {
      favorites = [...favorites, $SchoolRole.id];
      save();
    }}
  >
    <i class="fa-solid fa-heart fa-lg" />
  </a>
  {favorites?.length || '0'}
{/if}
