import positionRingItems from '../actions/positionRingItems';
import getBoundingBox from '../lib/helpers/getBoundingBox';
import { ringHeight, ringRadius } from '../lib/defaultValues';
import scaleDashboardItem from '../lib/helpers/scaleDashboardItem';

AFRAME.registerComponent('scale-to-fit-dashboard', {
  schema: {
    enabled: { type: 'boolean' },
  },
  init: function () {
    let ringLength = ringRadius * 2 * 3.14;
    if (this.el.getAttribute('sourceType') == 'gltf-model') {
      this.el.addEventListener('model-loaded', () => {
        scaleDashboardItem(this.el);
        positionRingItems();
      });
    }
    if (this.el.tagName == 'A-IMAGE') {
      this.el.setAttribute(
        'scale',
        ringHeight + ' ' + ringHeight + ' ' + ringHeight,
      );
      if (this.el.getAttribute('sourceType') == 'audio') {
        var itemWidth =
          document
            .getElementById(this.el.getAttribute('sourceId').replace('#', ''))
            .getAttribute('aspectRatio') * ringHeight;
      } else {
        var itemWidth =
          document
            .getElementById(this.el.getAttribute('src').replace('#', ''))
            .getAttribute('aspectRatio') * ringHeight;
      }
      var itemWidthAngle = (itemWidth / ringLength) * 360;
      this.el.setAttribute('itemWidthAngle', itemWidthAngle);
      positionRingItems();
    }
    if (this.el.tagName == 'A-VIDEO') {
      this.el.setAttribute(
        'scale',
        ringHeight + ' ' + ringHeight + ' ' + ringHeight,
      );
      let itemWidth =
        (this.el.getAttribute('width') / this.el.getAttribute('height')) *
        ringHeight;
      var itemWidthAngle = (itemWidth / ringLength) * 360;
      this.el.setAttribute('itemWidthAngle', itemWidthAngle);
      positionRingItems();
    }
    if (this.el.getAttribute('html-embed')) {
      this.el.setAttribute(
        'scale',
        ringHeight + ' ' + ringHeight + ' ' + ringHeight,
      );
      let itemWidth = ringHeight;
      var itemWidthAngle = (itemWidth / ringLength) * 360;
      this.el.setAttribute('itemWidthAngle', itemWidthAngle);
      positionRingItems();
    }
  },
});
