<script>
  import CustomFieldEditRow from '../blocks/CustomFieldComponents/CustomFieldEditRow.svelte';
  import Button from '../UI/Button.svelte';
  import Modal from '../UI/Modal.svelte';
  import { _ } from 'svelte-i18n';
  import {
    AskIfShowOnList,
    SaveCustomField,
    ShowShowOnBadge,
    TargetCustomField,
    TargetFields,
  } from '../../lib/store';
  export let saveCustomField;
  export let field;
  export let fields;
  export let askIfShowOnList = false;
  export let showShowOnBadge = false;
</script>

<Button
  style="link"
  onclick={() => {
    TargetCustomField.set(field);
    TargetFields.set(fields);
    AskIfShowOnList.set(askIfShowOnList);
    ShowShowOnBadge.set(showShowOnBadge);
    SaveCustomField.set(saveCustomField);
    UIkit.modal('#customFieldEdit').show();
  }}
>
  <i class="fa-duotone fa-edit fa-fw" />
</Button>
