<script lang="ts">
  import Button from './UI/Button.svelte';
  import Checkbox from './UI/Checkbox.svelte';
  import Input from './UI/Input.svelte';

  import { Room, School, SchoolRole } from '../lib/store';
  import { ioGet } from '../lib/realtime';
  import CreateSchool from './CreateSchool.svelte';
  import Lead from './UI/Lead.svelte';
  import FileUpload from './blocks/FileUpload.svelte';
  import { HOST } from '../lib/config';
  import backgroundImages from '../lib/presets/backgroundImages';
  import background360Videos from '../lib/presets/background360Videos';
  import Section from './UI/Section.svelte';
  import { _ } from 'svelte-i18n';
  import { navigate } from 'svelte-routing';
  import dialog from '../lib/helpers/dialog';
  import { onMount } from 'svelte';
  import setSchoolAndRoom from '../lib/helpers/setSchoolAndRoom';
  import validateSlug from '../lib/helpers/validateSlug';
  import RoomSettingBlock from './blocks/RoomSettingBlock.svelte';
  import areYouSure from '../lib/helpers/areYouSure';
  import Div from './UI/Div.svelte';
  import { roomStore } from '../state/stores/roomStore';
  import { writable } from 'svelte/store';
  let presetBGImages = backgroundImages;
  let preset360BGVideos = background360Videos;
  let school = null;
  let room = null;
  export let slug;
  export let roomSlug;
  Room.subscribe(obj => {
    if (obj) {
      if (typeof obj.environment == 'undefined') {
        obj.environment = {};
      }
      if (typeof obj.invitations == 'undefined') {
        obj.invitations = [];
      }
      room = obj;
    }
  });
  School.subscribe(obj => {
    school = obj;
  });
  onMount(async () => {
    await setSchoolAndRoom(slug, roomSlug);
  });
  const saveRoom = async (noTransfer = false) => {
    console.log('saving Room setting', room);
    if (!room.title) {
      dialog($_('roomSetting.EmptyRoomTitle'));
      return;
    }
    let res = await validate();
    if (!res) return;
    roomStore.update(room);
    dialog($_('Saved'));
    Room.set(room);
    if (noTransfer) return;
    navigate('/' + school.slug + '/select-room');
  };
  const validate = async () => {
    let res = await validateSlug(room.slug);
    console.log(res);
    if (res.status == 'success') {
      //okay
      return true;
    } else {
      dialog($_(`slug.${res.message}`));
      return false;
    }
  };
  const deleteRoom = async () => {
    areYouSure($Room.title, async () => {
      let res = await ioGet('deleteRoom', {
        _id: $Room._id,
      });
      console.log(res);
      navigate('/' + school.slug + '/select-room');
    });
  };
</script>

{#if school && room}
  <div>
    <Section>
      <div>
        <div class="uk-container">
          <ul class="uk-breadcrumb">
            <li>
              <a href={'#'} on:click={() => navigate('/' + school.slug + '/#')}>
                {school?.title}
              </a>
            </li>
            <li>
              <a
                href={'#'}
                on:click={() => navigate('/' + school.slug + '/select-room')}
              >
                {$_('enterRoom.Rooms')}
              </a>
            </li>
            <li>
              <span>
                {room?.title}
              </span>
            </li>
          </ul>
          <RoomSettingBlock
            triggerSave={() => saveRoom('noTransfer')}
            bind:room
          />
          <Div>
            <Button fullwidth onclick={saveRoom}>{$_('Save')}</Button>
          </Div>
          {#if $SchoolRole.roomPermissionPresets.rooms.canDelete}
            <div class="uk-margin">
              <Button
                small
                style="danger"
                onclick={() => {
                  deleteRoom(room._id);
                }}><i class="fa-duotone fa-trash" /> {$_('Delete')}</Button
              >
            </div>
          {/if}
        </div>
      </div>
    </Section>
  </div>
{/if}
