<script>
  import {
    startScreenCapture,
    stopScreenCapture,
    startWebcam,
    stopWebcam,
    videoController,
  } from '../../actions/videoController.js';
  import { HOST } from '../../lib/config.js';
  import { navigate } from 'svelte-routing';
  import { io, ioFetch } from '../../lib/realtime.js';
  import {
    noiseThreshold,
    captureScreenActive,
    webcamActive,
    Room,
    SchoolRole,
    RoomPermissions,
    School,
    FooterVisible,
    AdjustTime,
  } from '../../lib/store';
  import Cookies from 'js-cookie';
  import Button from '../UI/Button.svelte';
  import Offcanvas from '../UI/Offcanvas.svelte';
  import selectLocale from '../../lib/helpers/selectLocale';
  import { _ } from 'svelte-i18n';
  import Input from '../UI/Input.svelte';
  import Modal from '../UI/Modal.svelte';
  import PersonaBlock from './PersonaBlock.svelte';
  import LanguageSwitch from './LanguageSwitch.svelte';
  import RoleSelectOption from './RoleSelectOption.svelte';
  import dialog from '../../lib/helpers/dialog.js';

  let room = null;
  Room.subscribe(obj => {
    room = obj;
  });

  let roomPermissions;
  RoomPermissions.subscribe(array => {
    roomPermissions = array;
  });
  let volume = 0;
  let threshold = 0;
  let ifWebcamActive = false;
  let ifCaptureScreenActive;
  captureScreenActive.subscribe(val => {
    ifCaptureScreenActive = val;
  });
  webcamActive.subscribe(val => {
    ifWebcamActive = val;
    volume = val;
  });
  noiseThreshold.subscribe(val => {
    threshold = val;
  });
  const updateNoiseThreshold = () => {
    noiseThreshold.set(threshold);
  };

  const updatePassword = async () => {
    ioFetch('updateRoom', {
      _id: $Room._id,
      data: {
        usePassword: $Room.usePassword,
        password: $Room.password,
      },
    });
    UIkit.notification({
      message: 'Updated',
      status: 'success',
      pos: 'top-center',
    });
  };
  const getKickedOut = () => {
    dialog($_('chat.YouAreKickedOut'));
    disconnect();
  };
  const disconnect = () => {
    videoController.disconnect();
    io.emit('leaveRoom', $Room._id);
    console.log($SchoolRole.isGuest);
    FooterVisible.set(true);

    if ($SchoolRole.isGuest == true || $SchoolRole.schoolID != $School._id) {
      navigate('/');
    } else {
      navigate('enter-room');
    }
  };
  io.on('kickOut', data => {
    getKickedOut();
  });
</script>

<Offcanvas right id="side-panel">
  <div uk-margin>
    <PersonaBlock schoolRoleID={$SchoolRole.id} />
    <Button
      onclick={() => {
        UIkit.modal('#editNicknameAndAvatar').show();
      }}
    >
      {$_('menu.ChangeNicknameAndAvatar')}
    </Button>
    {#if !$SchoolRole.isGuest}
      {#if $School.roles.some(role => role.roomPermissionPresets.rooms.canEdit == true && role.id == $SchoolRole.roleID)}
        <Button onclick={() => UIkit.modal('#roomSettingModal').show()}>
          {$_('roomSetting.RoomSetting')}
        </Button>
      {/if}
    {/if}
    <ul class="uk-nav">
      <li class="uk-nav-divider" />
      <li class="uk-nav-heading">{$_('menu.Language')}</li>
      <li>
        <LanguageSwitch />
      </li>
    </ul>
    <div id="myEnterVRButton" />
    <div id="myEnterARButton" />
    <div class="uk-margin">
      <Button onclick={disconnect} small fullwidth style="danger">
        {$_('menu.LeaveRoom')}
      </Button>
    </div>
  </div>
</Offcanvas>
