<script lang="ts">
  import { hideModal } from '../../actions/modalController';
  import { capitalize } from '../../lib/helpers/capitalize';
  import { School, SchoolRole } from '../../lib/store';
  import Button from './Button.svelte';
  import Icon from './Icon.svelte';
  import Lead from './Lead.svelte';

  export let id;
  export let withHeader = false;
  export let withFooter = false;
  export let title = '';
  export let size = '';
  export let darkMode = true;
  export let stack = false;
  export let container = true;
  export let bgClose: boolean = true;
  export let open: boolean = false;
  $: fontIsDark = $School
    ? !$School[
        'fontIsDarkFor' +
          capitalize(
            $School?.roles.find(r => r.id == $SchoolRole?.roleID)?.category,
          )
      ]
    : false;
</script>

<div
  uk-modal="{bgClose ? 'bg-close:true;' : 'bg-close:false;'}{stack
    ? 'stack:true;'
    : ''}{container === false ? 'container:false;' : ''}"
  {id}
  class:uk-modal-container={size == 'container'}
  class:uk-modal-large={size == 'large'}
  class:uk-modal-small={size == 'small'}
  class:uk-open={open}
>
  <div class="uk-modal-dialog">
    {#if withHeader || title}
      <div class:uk-light={fontIsDark} class="uk-modal-header ">
        <div class="uk-position-top-right uk-padding-small">
          <Button
            onclick={() => {
              hideModal('#' + id);
            }}
            style="text"
          >
            <Icon icon="times" size="large" />
          </Button>
        </div>
        <Lead>
          {title}
        </Lead>
      </div>
    {/if}
    <div
      class:uk-light={fontIsDark}
      class="uk-modal-body  uk-position-relative"
      uk-overflow-auto
    >
      <slot name="body" />
    </div>
    {#if withFooter}
      <div class="uk-modal-footer" class:uk-light={fontIsDark}>
        <slot name="footer" />
      </div>
    {/if}
  </div>
</div>
