export const showModal = search => {
  try {
    UIkit.modal(search).show();
  } catch (e) {
    console.log({ e, search });
  }
};
export const hideModal = search => {
  UIkit.modal(search).hide();
};
