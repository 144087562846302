const getBoundingBox = (model, mode = null) => {
  switch (mode) {
    case 'width-height':
      var boxSize = {
        width: 0,
        height: 0,
        abs: 0,
      };
      let offset = model.localToWorld(new THREE.Vector3());
      model.traverse(node => {
        if (node.isMesh) {
          var box = new THREE.Box3().setFromObject(node);

          let sizes = box.getSize(new THREE.Vector3());

          boxSize.width = Math.max(sizes.x, sizes.z, boxSize.width);
          if (boxSize.height < sizes.y) {
            boxSize.height = sizes.y;
            boxSize.center = box.getCenter();
            boxSize.minY = box.min.y;
            boxSize.maxY = box.max.y;
          }
        }
      });
      if (Math.abs(boxSize.maxY) > Math.abs(boxSize.minY)) {
        var abs = Math.abs(boxSize.maxY);
      } else {
        var abs = Math.abs(boxSize.minY);
      }
      boxSize.abs = abs;
      break;
    default:
      var boxSize = 0;
      model.traverse(node => {
        if (node.isMesh) {
          var box = new THREE.Box3().setFromObject(node);
          boxSize = Math.max(
            boxSize,
            box.getSize(new THREE.Vector3()).length(),
          );
        }
      });
  }
  return boxSize;
};
export default getBoundingBox;
