<script lang="ts">
  import { DateTime } from 'luxon';

  import Checkbox from '../../UI/Checkbox.svelte';
  import nl2br from 'nl2br';
  import ColorRing from '../ColorRing.svelte';
  import { onMount } from 'svelte';
  import PrimitiveDisplay from './PrimitiveDisplay.svelte';
  import {
    afterUpdate,
    getContext,
    select_options,
    setContext,
  } from 'svelte/internal';
  import { findConditionalValueMatch } from '../../../lib/helpers/findConditionalValueMatch';
  import { customFieldTypes } from '../../../lib/presets/customFieldTypes';
  import { TargetAssignment, TargetCategory } from '../../../lib/store';
  import { calculate } from '../../../lib/helpers/calculateCustomFieldValues';
  import { previewMath } from '../../../lib/helpers/decodeMath';
  import copy from '../../../lib/helpers/copy';
  import { defineIfUndefined } from '../../../lib/helpers/defineIfUndefined';
  export let field;
  export let fields = [];
  export let value: any;
  export let values = {};
  export let parentValues = {};
  export let parentFields = [];
  export let videoWatcher: string = '';
  let fieldForDisplay = null;
  let valueForDisplay = {
    value: '',
  };
  setContext('field', field);

  afterUpdate(() => {
    var list = calculate(
      field,
      fields,
      value,
      values,
      parentFields,
      parentValues,
    );

    fieldForDisplay = list[0];
    valueForDisplay = list[1];
    valueForDisplay = defineIfUndefined(valueForDisplay, { value: '' });
    if (fieldForDisplay && valueForDisplay.value) {
      if (
        fieldForDisplay.type == 'richText' &&
        valueForDisplay.value.indexOf('[math]') > -1
      ) {
        previewMath();
      }
    }
  });
</script>

{#if fieldForDisplay && (valueForDisplay != null || fieldForDisplay.type == 'button')}
  <PrimitiveDisplay
    {videoWatcher}
    {fields}
    bind:values
    {parentFields}
    {parentValues}
    field={fieldForDisplay}
    value={valueForDisplay}
  />
{/if}
