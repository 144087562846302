<script lang="ts">
  import axios from 'axios';
  import { _ } from 'svelte-i18n';
  import { SERVER_URL } from '../../lib/config';

  export let onUploadComplete: (x: string) => void;
  let files: FileList;

  const uploadFile = async (
    event: MouseEvent & {
      currentTarget: EventTarget & HTMLButtonElement;
    },
    callback: (x: string) => void,
  ) => {
    event.preventDefault();
    if (!files.length) return;

    const file = files[0];
    const formData = new FormData();
    formData.append('file', file, file.name);

    const response = await axios.post<string>(
      SERVER_URL + 'api/v2/account/registration/csv',
      formData,
    );
    await callback(response.data);
    location.reload();
  };
</script>

<label class="upload uk-margin-small" for="upload">
  {$_('accounts.UploadCSVFile')}
  <input
    type="file"
    id="upload"
    class="uk-input"
    bind:files
    accept="text/csv"
  />
</label>
<button
  class="uk-button uk-button-default"
  on:click={event => uploadFile(event, onUploadComplete)}
>
  {$_('accounts.SendCsv')}
</button>

<style>
  label.upload {
    display: inline-block;
  }

  label.upload input {
    border: none;
    padding: 6px 12px;
    cursor: pointer;
  }
</style>
