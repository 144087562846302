<script lang="ts">
  import { defineIfUndefined } from '../../../lib/helpers/defineIfUndefined';
  import { getVideosFromAssignment } from '../../../lib/helpers/getVideosFromAssignment';
  import {
    TargetAssignment,
    TargetCategory,
    TargetProgramCategory,
    TargetSubmission,
  } from '../../../lib/store';
  import { submissionsStore } from '../../../state/stores/submissionsStore';
  import Lead from '../../UI/Lead.svelte';
  import VideoProgressRing from '../VideoProgressRing.svelte';
  export let mode: string = 'list';
  let submissions = [];

  submissionsStore.subscribe(array => {
    submissions = submissionsStore.list();
  });

  $: console.log({ videos });
  $: videos = getVideosFromAssignment($TargetAssignment, $TargetCategory);
  $: submission = submissions.find(
    submission => submission._id == $TargetSubmission._id,
  );
  export let width: number = 100;
</script>

{#if videos.length}
  <div uk-grid class="uk-grid-small">
    <div>
      <VideoProgressRing
        {width}
        {videos}
        videoProgress={submission.videoProgress}
      />
    </div>
    <div>
      <ul class="uk-list">
        {#each videos as video}
          {#if submission.videoProgress}
            <li>
              <div uk-grid class=" uk-width-expand">
                <div class="uk-width-expand">
                  {video.title || ''}
                </div>
                <div>
                  <Lead>
                    {submission.videoProgress[video.videoId] || 0}%
                  </Lead>
                </div>
              </div>
            </li>
          {/if}
        {/each}
      </ul>
    </div>
  </div>
{/if}

<style>
  .wrapper {
    /*margin-top: 1rem;*/
    height: 0.2rem;
    border-radius: 0.1rem;
    overflow: hidden;
    display: flex;
  }

  .progress-bar {
    flex: 1;
    height: 0.2rem;
    background-color: #e0e0e0;
    /*box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.2);*/
  }

  .progress-bar-fill {
    display: block;
    height: 0.2rem;
    background-color: lightseagreen;
    /*transition: width 500ms ease-in-out;*/
  }
</style>
