const setValue = (head, key, val) => {
  var index = head.morphTargetDictionary[key];
  head.morphTargetInfluences[index] = val;
};
let neckBone;
export const moveFace = (
  head,
  neck,
  blendShapes,
  rotation = null,
  useFace = false,
) => {
  if (head && rotation) {
    neck.rotation.x = rotation.pitch * -1.2;
    neck.rotation.y = rotation.yaw;
    neck.rotation.z = rotation.roll * -1;
    if (!useFace) return;
    Object.keys(blendShapes).forEach(key => {
      let resKey = key.replace('_L', 'Left').replace('_R', 'Right');
      setValue(head, resKey, blendShapes[key]);
    });
  }
};
