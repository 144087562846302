import { School, User, SchoolRole, Room, RoomPermissions } from '../store';
let school;
School.subscribe(obj => {
    school = obj;
});
let user;
User.subscribe(obj => {
    user = obj;
});
let room;
Room.subscribe(obj => {
    room = obj;
});
let roomPermissions;
RoomPermissions.subscribe(array => {
    roomPermissions = array;
});
let schoolRole;
SchoolRole.subscribe(obj => {
    schoolRole = obj;
});
const setRoomPermissions = (asGuest = false) => {
    if (asGuest) {
        var permissions = room.permissions.find((role) => role.category == 'guest');
    }
    else {
        var permissions = room.permissions.find((role) => role.id == schoolRole.roleID);
    }
    if (permissions) {
        RoomPermissions.set(permissions.roomPermissionPresets);
    }
};
export default setRoomPermissions;
