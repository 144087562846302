<script lang="ts">
  import CustomFieldOptionButton from './CustomFieldOptionButton.svelte';
  import CustomFieldSelectOptions from './CustomFieldSelectOptions.svelte';
  import TargetFieldSelect from './TargetFieldSelect.svelte';
  import Checkbox from '../../UI/Checkbox.svelte';
  import Button from '../../UI/Button.svelte';
  import { _ } from 'svelte-i18n';
  import Input from '../../UI/Input.svelte';
  import { getContext, select_options } from 'svelte/internal';
  import CustomFieldInput from './CustomFieldInput.svelte';
  import { customFieldTypes } from '../../../lib/presets/customFieldTypes';
  import {
    CustomFieldDefault,
    CustomFieldInterface,
    CustomFieldOptionsInterface,
  } from '../../../lib/interfaces/CustomField';
  import { UUID } from '../../../lib/uuid';
  export let field;
  export let fields: Array<CustomFieldInterface>;
  let parentFields: boolean | Array<CustomFieldInterface> =
    getContext('parentFields');
  import CustomFieldOptions from './CustomFieldOptions.svelte';
  import ArrayNav from '../ArrayNav.svelte';
  import { School } from '../../../lib/store';
  import ArrayFieldRows from './ArrayFieldRows.svelte';
  import copy from '../../../lib/helpers/copy';
  export let type: string;
  export let options: CustomFieldOptionsInterface;
  export let save: () => void;
</script>

{#if type == 'number'}
  <div uk-grid class="uk-grid">
    <div>
      <Input
        type="number"
        bind:value={options.min}
        label={$_('customFields.Min')}
      />
    </div>
    <div>
      <Input
        type="number"
        bind:value={options.max}
        label={$_('customFields.Max')}
      />
    </div>
    <div>
      <Input
        type="number"
        bind:value={options.step}
        label={$_('customFields.Step')}
      />
    </div>
  </div>
{:else if type == 'array'}
  <ul class="uk-list uk-list-small uk-list-divider">
    <ArrayFieldRows {save} bind:options />
  </ul>
  <Button
    onclick={async () => {
      console.log(options.rows);
      options.rows = [
        ...options.rows,
        {
          ...CustomFieldDefault,
          id: UUID(),
        },
      ];
      console.log(options.rows);
    }}>{$_('Add')}</Button
  >
{:else if type == 'inherit'}
  <TargetFieldSelect bind:value={options.target} fields={parentFields} />
{:else if type == 'reference'}
  {$_('customFields.OriginalField')}
  <TargetFieldSelect
    bind:value={options.ringColorTarget}
    fields={fields.filter(f => f.id != field.id)}
  />
{:else if type == 'conditionalColorRing'}
  {$_('customFields.RingColor')}
  <TargetFieldSelect
    bind:value={options.ringColorTarget}
    fields={fields.filter(f => f.type == 'color' || f.options.type == 'color')}
  />
  <Checkbox bind:checked={options.showAboveTheRing}>
    {$_('customFields.AboveTheRing')}
  </Checkbox>
  {#if options.showAboveTheRing}
    <TargetFieldSelect bind:value={options.aboveTheRingTarget} {fields} />
  {/if}
  {$_('customFields.InsideTheRing')}
  <TargetFieldSelect bind:value={options.insideTheRingTarget} {fields} />
  <select class="uk-select" bind:value={options.insideTheRingSize}>
    <option value="large">{$_('customFields.Large')}</option>
    <option value="medium">{$_('customFields.Medium')}</option>
    <option value="small">{$_('customFields.Small')}</option>
  </select>
  <Checkbox bind:checked={options.showBelowTheRing}>
    {$_('customFields.BelowTheRing')}
  </Checkbox>
  {#if options.showBelowTheRing}
    <TargetFieldSelect bind:value={options.belowTheRingTarget} {fields} />
  {/if}
{:else if type == 'conditionalTime'}
  {$_('customFields.FormatOfField')}
  <select class="uk-select" bind:value={options.type}>
    {#each customFieldTypes.filter(type => type.isPrimitive || type.slug == 'reference') as type}
      <option value={type.slug}>{$_('customFields.' + type.title)}</option>
    {/each}
  </select>
  <table class="uk-table uk-table-small uk-table-divider uk-table-justify">
    <tbody>
      {#each options.rows as row, i}
        <tr>
          <td>
            {#if i != 0}
              else
            {/if}
            {#if i == 0 || i != options.rows.length - 1}
              if
            {/if}
          </td>
          <td>
            {#if i == 0 || i != options.rows.length - 1}
              <select class="uk-select" bind:value={row.beforeOrAfter}>
                <option value="isBefore"
                  >{$_('customFields.NowIsBefore')}</option
                >
                <option value="sameAs"
                  >{$_('customFields.NowIsTheSameAs')}</option
                >
                <option value="isAfter">{$_('customFields.NowIsAfter')}</option>
              </select>
            {/if}
          </td>
          <td>
            {#if i != options.rows.length - 1}
              <TargetFieldSelect
                bind:value={row.compareTo}
                fields={fields.filter(
                  f =>
                    f.id != field.id &&
                    (f.type == 'date' ||
                      f.type == 'datetime-local' ||
                      f.type == 'endOfMonth' ||
                      f.type == 'inherit' ||
                      f.options.type == 'date' ||
                      f.options.type == 'endOfMonth' ||
                      f.options.type == 'datetime-local' ||
                      f.type == 'autoCalculation' ||
                      (f.type == 'conditionalValue' &&
                        f.options.type == 'reference') ||
                      (f.type == 'conditionalValue' &&
                        f.options.type == 'reference')),
                )}
              />
            {/if}
          </td>
          <td>
            {#if i == 0 || i != options.rows.length - 1}
              then
            {/if}
          </td>
          <td>
            {#if options.type == 'reference'}
              <TargetFieldSelect
                bind:value={row.target}
                fields={fields.filter(f => f.id != field.id)}
              />
            {:else}
              <CustomFieldInput field={options} bind:value={row.value} />
            {/if}
          </td>
          <td style="min-with:40px">
            <Button
              style="link"
              onclick={() => {
                options.rows.splice(i, 1);
                options.rows = options.rows;
              }}
            >
              <i class="fa-duotone fa-trash" />
            </Button>
          </td>
        </tr>
      {/each}
    </tbody>
  </table>
  <Button
    small
    onclick={() => {
      options.rows = [
        ...options.rows,
        {
          compareTo: '',
          compareToNow: false,
        },
      ];
    }}>{$_('customFields.Add')}</Button
  >
{:else if type == 'conditionalValue'}
  {$_('customFields.OriginalField')}
  <TargetFieldSelect bind:value={options.target} {fields} />
  {$_('customFields.FormatOfField')}
  <select class="uk-select" bind:value={options.type}>
    {#each customFieldTypes.filter(type => type.isPrimitive || type.slug == 'reference') as type}
      <option value={type.slug}>{$_('customFields.' + type.title)}</option>
    {/each}
  </select>
  <table class="uk-table uk-table-small uk-table-divider uk-table-justify">
    <thead>
      <tr>
        {#if fields.find(fDef => fDef.id == options.target)?.type == 'number'}
          <th> <i class="fa-solid fa-less-than-equal fa-fw" /></th>
          <th> <i class="fa-solid fa-less-than fa-fw" /></th>
        {:else}
          <th> <i class="fa-solid fa-equals fa-fw" /></th>
        {/if}
        <th>{$_('customFields.Value')}</th>
        <th style="min-width:40px;" />
      </tr>
    </thead>
    <tbody>
      {#each options.rows || [] as row, i}
        {@const tempField = fields.find(fDef => fDef.id == options.target)}
        <tr>
          {#if fields.find(fDef => fDef.id == options.target)?.type == 'number'}
            <td>
              <CustomFieldInput field={tempField} bind:value={row.min} />
            </td>
            <td>
              <CustomFieldInput field={tempField} bind:value={row.max} />
            </td>
          {:else}
            <td>
              <CustomFieldInput field={tempField} bind:value={row.equal} />
            </td>
          {/if}
          <td>
            {#if options.type == 'reference'}
              <TargetFieldSelect
                bind:value={row.target}
                fields={fields.filter(f => f.id != field.id)}
              />
            {:else}
              <CustomFieldInput field={options} bind:value={row.value} />
            {/if}
          </td>
          <td>
            <Button
              style="link"
              onclick={() => {
                options.rows.splice(i, 1);
                options.rows = options.rows;
              }}
            >
              <i class="fa-duotone fa-trash" />
            </Button>
          </td>
        </tr>
      {/each}
    </tbody>
  </table>

  <Button
    small
    onclick={() => {
      options.rows = [
        ...options.rows,
        {
          min: '',
          max: '',
          value: '',
        },
      ];
    }}>{$_('customFields.Add')}</Button
  >
{:else if type == 'autoCalculation'}
  {$_('customFields.OriginalField')}
  <TargetFieldSelect bind:value={options.target} {fields} />
  <select class="uk-select" bind:value={options.calculation}>
    <option value="plus">{$_('customFields.Plus')}</option>
    <option value="minus">{$_('customFields.Minus')}</option>
    <option value="multiply">{$_('customFields.Multiply')}</option>
  </select>
  <Input type="number" bind:value={options.value} />
  <select class="uk-select" bind:value={options.unit}>
    <option value="">{$_('customFields.Number')}</option>
    <option value="minutes">{$_('customFields.Minutes')}</option>
    <option value="hours">{$_('customFields.Hours')}</option>
    <option value="days">{$_('customFields.Days')}</option>
    <option value="weeks">{$_('customFields.Weeks')}</option>
    <option value="months">{$_('customFields.Months')}</option>
  </select>
{:else if type == 'button'}
  {$_('customFields.ByClickingSet')}
  <TargetFieldSelect
    bind:value={options.target}
    fields={fields.filter(f => f.id != field.id)}
  />
  {$_('customFields.ToThisValue')}
  <CustomFieldInput
    field={fields.find(f => f.id == options.target)}
    bind:value={options.value}
  />
{:else if type == 'select' || type == 'selectMultiple'}
  <CustomFieldSelectOptions bind:values={options.values} />
{/if}
