<script lang="ts">
  export let value: boolean = false;
  export let disabled: boolean = false;
  export let onChange: () => void = () => {};
  const toggle = () => {
    if (!disabled) {
      value = !value;
    }
    onChange();
  };
</script>

{#if value}
  <a href={'#'} on:click={toggle}>
    <i class="fa-duotone fa-toggle-on fa-2x" />
  </a>
{:else}
  <a href={'#'} on:click={toggle}>
    <i class="fa-duotone fa-toggle-off fa-2x" />
  </a>
{/if}
