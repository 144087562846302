<script lang="ts">
  import { SubscriptionPlans } from '../../lib/interfaces/SubscriptionPlan';
  import { School } from '../../lib/store';
  import Lead from '../UI/Lead.svelte';
  import ChangeSubscriptionOrEmail from './changeSubscriptionOrEmail.svelte';
  import Cookies from 'js-cookie';
  export let setSubscriptionPlan;
  import { _ } from 'svelte-i18n';
</script>

{#if Cookies.get('locale') == 'ja'}
  <table
    class="uk-table uk-table-divider uk-text-center uk-table-justify uk-table-middle"
  >
    <thead>
      <tr>
        <td class="fixed" style="min-width:150px;" />
        <td
          ><Lead>Openwater</Lead><span class="font-s">小規模スクール向け</span>
        </td>
        <td
          ><Lead>Essential</Lead><span class="font-s">中規模スクール向け</span>
        </td>
        <td
          ><Lead>Basic</Lead>
          <span class="font-s">大規模<br />オンラインスクール向け</span>
        </td>
        <td
          ><Lead>Platinum</Lead><span class="font-s"
            >大学、専門学校、<br />
            通信制高校向け</span
          >
        </td>
      </tr>
      <tr>
        <td />
        {#each SubscriptionPlans as plan}
          <td>
            {#if $School.subscriptionPlan.id == plan.id}
              {$_('schoolSetting.CurrentPlan')}
            {:else}
              <ChangeSubscriptionOrEmail {plan} {setSubscriptionPlan} />
            {/if}
          </td>
        {/each}
      </tr>
    </thead>
    <tfoot>
      <tr>
        <td />
        {#each SubscriptionPlans as plan}
          <td>
            {#if $School.subscriptionPlan.id == plan.id}
              {$_('schoolSetting.CurrentPlan')}
            {:else}
              <ChangeSubscriptionOrEmail {plan} {setSubscriptionPlan} />
            {/if}
          </td>
        {/each}
      </tr>
    </tfoot>
    <tbody>
      <tr>
        <td class="no-border fixed" />
        <td class="info">バーチャル教室のみ<br />教室数に制限あり</td>
        <td class="info">バーチャル教室のみ</td>
        <td class="info">豊富な教務ツール</td>
        <td class="info">教務ツール＋事務・管理ツール</td>
      </tr>
      <tr>
        <td class="no-border fixed" />
        <td class="info">
          ~50アカウントまで<br />
          <span class="free">無料</span></td
        >
        <td class="info"
          >~100アカウント<br />
          10,000円/月（年払い)<br /><br />
          100~アカウント<br />
          100円/月/アカウント<br /><br />初期費用は別途お見積もり</td
        >
        <td class="info"
          >~100アカウント<br />
          38,000円/月（年払い)<br /><br />
          100~アカウント<br />
          380円/月/アカウント<br /><br />初期費用は別途お見積もり</td
        >
        <td class="info"
          >~100アカウント<br />
          60,000~80,000円/月（年払い)<br /><br />
          100~アカウント<br />
          600~800円/月/アカウント<br /><br />
          初期費用は別途お見積もり</td
        >
      </tr>
      <tr>
        <td class="border-r-top fixed">バー チャル教室 </td>
        <td
          ><img
            src="https://aoike-ep.com/openwater/wp-content/uploads/2022/03/check-1.svg"
            alt="チェック"
            width="30px"
          /></td
        >
        <td
          ><img
            src="https://aoike-ep.com/openwater/wp-content/uploads/2022/03/check-1.svg"
            alt="チェック"
            width="30px"
          /></td
        >
        <td
          ><img
            src="https://aoike-ep.com/openwater/wp-content/uploads/2022/03/check-1.svg"
            alt="チェック"
            width="30px"
          /></td
        >
        <td
          ><img
            src="https://aoike-ep.com/openwater/wp-content/uploads/2022/03/check-1.svg"
            alt="チェック"
            width="30px"
          /></td
        >
      </tr>
      <tr>
        <td class="fixed">テキストチャット</td>
        <td
          ><img
            src="https://aoike-ep.com/openwater/wp-content/uploads/2022/03/check-1.svg"
            alt="チェック"
            width="30px"
          /></td
        >
        <td
          ><img
            src="https://aoike-ep.com/openwater/wp-content/uploads/2022/03/check-1.svg"
            alt="チェック"
            width="30px"
          /></td
        >
        <td
          ><img
            src="https://aoike-ep.com/openwater/wp-content/uploads/2022/03/check-1.svg"
            alt="チェック"
            width="30px"
          /></td
        >
        <td
          ><img
            src="https://aoike-ep.com/openwater/wp-content/uploads/2022/03/check-1.svg"
            alt="チェック"
            width="30px"
          /></td
        >
      </tr>
      <tr>
        <td class="fixed">オンライン授業<br />（ビデオ 通話）</td>
        <td
          ><img
            src="https://aoike-ep.com/openwater/wp-content/uploads/2022/03/check-1.svg"
            alt="チェック"
            width="30px"
          /></td
        >
        <td
          ><img
            src="https://aoike-ep.com/openwater/wp-content/uploads/2022/03/check-1.svg"
            alt="チェック"
            width="30px"
          /></td
        >
        <td
          ><img
            src="https://aoike-ep.com/openwater/wp-content/uploads/2022/03/check-1.svg"
            alt="チェック"
            width="30px"
          /></td
        >
        <td
          ><img
            src="https://aoike-ep.com/openwater/wp-content/uploads/2022/03/check-1.svg"
            alt="チェック"
            width="30px"
          /></td
        >
      </tr>
      <tr>
        <td class="fixed"
          >生徒アカウントの管理<br />
          一斉メール</td
        >
        <td
          ><img
            src="https://aoike-ep.com/openwater/wp-content/uploads/2022/03/check-1.svg"
            alt="チェック"
            width="30px"
          /></td
        >
        <td
          ><img
            src="https://aoike-ep.com/openwater/wp-content/uploads/2022/03/check-1.svg"
            alt="チェック"
            width="30px"
          /></td
        >
        <td
          ><img
            src="https://aoike-ep.com/openwater/wp-content/uploads/2022/03/check-1.svg"
            alt="チェック"
            width="30px"
          /></td
        >
        <td
          ><img
            src="https://aoike-ep.com/openwater/wp-content/uploads/2022/03/check-1.svg"
            alt="チェック"
            width="30px"
          /></td
        >
      </tr>
      <tr hidden>
        <td class="fixed">独自ドメイン</td>
        <td>-</td>
        <td
          ><img
            src="https://aoike-ep.com/openwater/wp-content/uploads/2022/03/check-1.svg"
            alt="チェック"
            width="30px"
          /></td
        >
        <td
          ><img
            src="https://aoike-ep.com/openwater/wp-content/uploads/2022/03/check-1.svg"
            alt="チェック"
            width="30px"
          /></td
        >
        <td
          ><img
            src="https://aoike-ep.com/openwater/wp-content/uploads/2022/03/check-1.svg"
            alt="チェック"
            width="30px"
          /></td
        >
      </tr>
      <tr>
        <td class="fixed"
          >レポート管理・提出機能<br />
          オンデマンド動画</td
        >
        <td>-</td>
        <td>-</td>
        <td
          ><img
            src="https://aoike-ep.com/openwater/wp-content/uploads/2022/03/check-1.svg"
            alt="チェック"
            width="30px"
          /></td
        >
        <td
          ><img
            src="https://aoike-ep.com/openwater/wp-content/uploads/2022/03/check-1.svg"
            alt="チェック"
            width="30px"
          /></td
        >
      </tr>
      <tr>
        <td class="fixed">レポート進捗管理</td>
        <td>-</td>
        <td>-</td>
        <td
          ><img
            src="https://aoike-ep.com/openwater/wp-content/uploads/2022/03/check-1.svg"
            alt="チェック"
            width="30px"
          /></td
        >
        <td
          ><img
            src="https://aoike-ep.com/openwater/wp-content/uploads/2022/03/check-1.svg"
            alt="チェック"
            width="30px"
          /></td
        >
      </tr>
      <tr>
        <td class="fixed">証明書のオンライン申請<br />（EC機能）</td>
        <td>-</td>
        <td>-</td>
        <td
          ><img
            src="https://aoike-ep.com/openwater/wp-content/uploads/2022/03/check-1.svg"
            alt="チェック"
            width="30px"
          /></td
        >
        <td
          ><img
            src="https://aoike-ep.com/openwater/wp-content/uploads/2022/03/check-1.svg"
            alt="チェック"
            width="30px"
          /></td
        >
      </tr>
      <tr>
        <td class="fixed">カスタム機能の追加</td>
        <td>-</td>
        <td>-</td>
        <td
          ><img
            src="https://aoike-ep.com/openwater/wp-content/uploads/2022/03/check-1.svg"
            alt="チェック"
            width="30px"
          /></td
        >
        <td
          ><img
            src="https://aoike-ep.com/openwater/wp-content/uploads/2022/03/check-1.svg"
            alt="チェック"
            width="30px"
          /></td
        >
      </tr>
      <tr>
        <td class="fixed"
          >帳票出力機能<br />
          事務・管理機能<br />(2023年 度より)</td
        >
        <td>-</td>
        <td>-</td>
        <td>-</td>
        <td rowspan="5" style="background-color: grey;"
          ><img
            src="https://aoike-ep.com/openwater/wp-content/uploads/2022/03/check-1.svg"
            alt="チェック"
            width="30px"
          /><br /><span style="font-size:10px;"
            >※必要な機能を選んでカスタマイズ</span
          ></td
        >
      </tr>
      <tr>
        <td class="fixed">単位認定試験・成績管理</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
      </tr>
      <tr>
        <td class="fixed"
          >チャットによるサポート
          <br />(24時間以内対応)</td
        >
        <td>-</td>
        <td>-</td>
        <td>-</td>
      </tr>
      <tr>
        <td class="fixed">スクーリング管理</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
      </tr>
      <tr>
        <td class="border-r-btm fixed">特別活動・<br />保護者アカウ ント</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
      </tr>
    </tbody>
  </table>
{:else}
  <table
    class="uk-table uk-table-divider uk-text-center uk-table-justify uk-table-middle"
  >
    <thead>
      <tr>
        <td class="fixed" />
        <td
          ><Lead>Openwater</Lead><span class="font-s"
            >For Small scale<br />community schools</span
          ></td
        >
        <td
          ><Lead>Essential</Lead><span class="font-s"
            >For mid scale<br />schools
          </span></td
        >
        <td
          ><Lead>Basic</Lead>
          <span class="font-s"
            >For technical colleges<br />&amp; Culture schools
          </span></td
        >
        <td
          ><Lead>Platinum</Lead><span class="font-s"
            >For universities,<br />high schools</span
          ></td
        >
      </tr>
      <tr>
        <td />
        {#each SubscriptionPlans as plan}
          <td>
            {#if $School.subscriptionPlan.id == plan.id}
              {$_('schoolSetting.CurrentPlan')}
            {:else}
              <ChangeSubscriptionOrEmail {plan} {setSubscriptionPlan} />
            {/if}
          </td>
        {/each}
      </tr>
    </thead>
    <tfoot>
      <tr>
        <td />
        {#each SubscriptionPlans as plan}
          <td>
            {#if $School.subscriptionPlan.id == plan.id}
              {$_('schoolSetting.CurrentPlan')}
            {:else}
              <ChangeSubscriptionOrEmail {plan} {setSubscriptionPlan} />
            {/if}
          </td>
        {/each}
      </tr>
    </tfoot>
    <tbody>
      <tr>
        <td class="no-border fixed" />
        <td class="info"
          >Virtual Classroom<br />
          with up to<br />50 simultaneous<br />participants</td
        >
        <td class="info">Virtual Classroom<br />with unlimited participants</td>
        <td class="info">Virtual Classroom<br />+ Standard LMS </td>
        <td class="info"
          >Virtual Classroom<br />
          + LMS with management tools</td
        >
      </tr>
      <tr>
        <td class="no-border fixed" />
        <td class="info">Free<br />up to 50 accounts</td>
        <td class="info"
          >~ 100 account <br />＄100 USD / month<br />(annual payment) <br /><br
          />100 ~ account <br />＄1 USD / month / account
        </td>
        <td class="info"
          >~ 100 account <br />＄38 USD / month<br />(annual payment) <br /><br
          />100 ~ account <br />＄3.80 USD / month / account <br /><br />initial
          cost is<br />estimated separately</td
        >
        <td class="info"
          >~ 100 account <br />＄600 - 800 USD<br />/ month<br />(annual
          payment) <br /><br />100 ~ account <br />＄6 - 8 USD /<br />month /
          account <br /><br />Cost for initial installment to be<br />
          estimated/charged separately</td
        >
      </tr>
      <tr>
        <td class="border-r-top fixed">Virtual classroom</td>
        <td
          ><img
            src="https://aoike-ep.com/openwater/wp-content/uploads/2022/03/check-1.svg"
            alt="check"
            width="30px"
          /></td
        >
        <td
          ><img
            src="https://aoike-ep.com/openwater/wp-content/uploads/2022/03/check-1.svg"
            alt="check"
            width="30px"
          /></td
        >
        <td
          ><img
            src="https://aoike-ep.com/openwater/wp-content/uploads/2022/03/check-1.svg"
            alt="check"
            width="30px"
          /></td
        >
        <td
          ><img
            src="https://aoike-ep.com/openwater/wp-content/uploads/2022/03/check-1.svg"
            alt="check"
            width="30px"
          /></td
        >
      </tr>
      <tr>
        <td class="fixed">Text chat</td>
        <td
          ><img
            src="https://aoike-ep.com/openwater/wp-content/uploads/2022/03/check-1.svg"
            alt="check"
            width="30px"
          /></td
        >
        <td
          ><img
            src="https://aoike-ep.com/openwater/wp-content/uploads/2022/03/check-1.svg"
            alt="check"
            width="30px"
          /></td
        >
        <td
          ><img
            src="https://aoike-ep.com/openwater/wp-content/uploads/2022/03/check-1.svg"
            alt="check"
            width="30px"
          /></td
        >
        <td
          ><img
            src="https://aoike-ep.com/openwater/wp-content/uploads/2022/03/check-1.svg"
            alt="check"
            width="30px"
          /></td
        >
      </tr>
      <tr>
        <td class="fixed">Online Lessons<br />with video call</td>
        <td
          ><img
            src="https://aoike-ep.com/openwater/wp-content/uploads/2022/03/check-1.svg"
            alt="check"
            width="30px"
          /></td
        >
        <td
          ><img
            src="https://aoike-ep.com/openwater/wp-content/uploads/2022/03/check-1.svg"
            alt="check"
            width="30px"
          /></td
        >
        <td
          ><img
            src="https://aoike-ep.com/openwater/wp-content/uploads/2022/03/check-1.svg"
            alt="check"
            width="30px"
          /></td
        >
        <td
          ><img
            src="https://aoike-ep.com/openwater/wp-content/uploads/2022/03/check-1.svg"
            alt="check"
            width="30px"
          /></td
        >
      </tr>
      <tr>
        <td class="fixed">Student account management,<br />mass email</td>
        <td
          ><img
            src="https://aoike-ep.com/openwater/wp-content/uploads/2022/03/check-1.svg"
            alt="check"
            width="30px"
          /></td
        >
        <td
          ><img
            src="https://aoike-ep.com/openwater/wp-content/uploads/2022/03/check-1.svg"
            alt="check"
            width="30px"
          /></td
        >
        <td
          ><img
            src="https://aoike-ep.com/openwater/wp-content/uploads/2022/03/check-1.svg"
            alt="check"
            width="30px"
          /></td
        >
        <td
          ><img
            src="https://aoike-ep.com/openwater/wp-content/uploads/2022/03/check-1.svg"
            alt="check"
            width="30px"
          /></td
        >
      </tr>
      <tr hidden>
        <td class="fixed">Own domain</td>
        <td>―</td>
        <td
          ><img
            src="https://aoike-ep.com/openwater/wp-content/uploads/2022/03/check-1.svg"
            alt="check"
            width="30px"
          /></td
        >
        <td
          ><img
            src="https://aoike-ep.com/openwater/wp-content/uploads/2022/03/check-1.svg"
            alt="check"
            width="30px"
          /></td
        >
        <td
          ><img
            src="https://aoike-ep.com/openwater/wp-content/uploads/2022/03/check-1.svg"
            alt="check"
            width="30px"
          /></td
        >
      </tr>
      <tr>
        <td class="fixed">Assignment with<br />On-demand videos</td>
        <td>―</td>
        <td>―</td>
        <td
          ><img
            src="https://aoike-ep.com/openwater/wp-content/uploads/2022/03/check-1.svg"
            alt="check"
            width="30px"
          /></td
        >
        <td
          ><img
            src="https://aoike-ep.com/openwater/wp-content/uploads/2022/03/check-1.svg"
            alt="check"
            width="30px"
          /></td
        >
      </tr>
      <tr>
        <td class="fixed">Submission and<br />grading management</td>
        <td>―</td>
        <td>―</td>
        <td
          ><img
            src="https://aoike-ep.com/openwater/wp-content/uploads/2022/03/check-1.svg"
            alt="check"
            width="30px"
          /></td
        >
        <td
          ><img
            src="https://aoike-ep.com/openwater/wp-content/uploads/2022/03/check-1.svg"
            alt="check"
            width="30px"
          /></td
        >
      </tr>
      <tr>
        <td class="fixed"
          >Online Payment system<br />for administrative works<br />
          (i.e. producing diploma)</td
        >
        <td>―</td>
        <td>―</td>
        <td
          ><img
            src="https://aoike-ep.com/openwater/wp-content/uploads/2022/03/check-1.svg"
            alt="check"
            width="30px"
          /></td
        >
        <td
          ><img
            src="https://aoike-ep.com/openwater/wp-content/uploads/2022/03/check-1.svg"
            alt="check"
            width="30px"
          /></td
        >
      </tr>
      <tr>
        <td class="fixed">A custom features</td>
        <td>―</td>
        <td>―</td>
        <td
          ><img
            src="https://aoike-ep.com/openwater/wp-content/uploads/2022/03/check-1.svg"
            alt="check"
            width="30px"
          /></td
        >
        <td
          ><img
            src="https://aoike-ep.com/openwater/wp-content/uploads/2022/03/check-1.svg"
            alt="check"
            width="30px"
          /></td
        >
      </tr>
      <tr>
        <td class="fixed">Paperwork exports<br />(avaiblable from 2023) </td>
        <td>―</td>
        <td>―</td>
        <td>―</td>
        <td rowspan="5"
          ><img
            src="https://aoike-ep.com/openwater/wp-content/uploads/2022/03/check-1.svg"
            alt="check"
            width="30px"
          /><br /><span style="font-size:10px;"
            >* Select and customize<br />the required functions</span
          ></td
        >
      </tr>
      <tr>
        <td class="fixed">Exams <br />&amp; Grades management</td>
        <td>―</td>
        <td>―</td>
        <td>―</td>
      </tr>
      <tr>
        <td class="fixed"
          >Customer support<br />via chat<br />(within 24 hours)
        </td>
        <td>―</td>
        <td>―</td>
        <td>―</td>
      </tr>
      <tr>
        <td class="fixed">Detailed Attendance report</td>
        <td>―</td>
        <td>―</td>
        <td>―</td>
      </tr>
      <tr>
        <td class="border-r-btm fixed"
          >Special types of event activities<br />(i.e graduation ceremony)</td
        >
        <td>―</td>
        <td>―</td>
        <td>―</td>
      </tr>
    </tbody>
  </table>
{/if}
