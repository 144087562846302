import { DateTime } from 'luxon';
import { School } from '../store';
import dialog from './dialog';
import { _ } from 'svelte-i18n';
import { get } from 'svelte/store';

let school = null;
School.subscribe(obj => {
  school = obj;
});

const fixEventDate = eventToCreate => {
  if (eventToCreate.recurring) {
    if (!DateTime.fromISO(eventToCreate.startDate).isValid) {
      dialog(get(_)('InvalidStartDate'));
      return false;
    }
    if (!DateTime.fromISO(eventToCreate.rrule.until).isValid) {
      dialog(get(_)('InvalidStartDate'));
      return false;
    }
    eventToCreate.startRecur = eventToCreate.startDate;
    eventToCreate.endRecur = eventToCreate.rrule.until;
    if (eventToCreate.allDay) {
      eventToCreate.startAt = '00:00:00';
      eventToCreate.endAt = '00:00:00';
    } else {
      if (eventToCreate.useCustomTime) {
        if (eventToCreate.startTime >= eventToCreate.endTime) {
          if (!DateTime.fromISO(eventToCreate.startDate).isValid) {
            dialog(get(_)('InvalidStartTime'));
            return false;
          }

          eventToCreate.endTime = DateTime.fromISO(eventToCreate.startTime)
            .plus({
              hours: 1,
            })
            .toFormat('H:mm:00');
        }
      } else {
        if (eventToCreate.hourName) {
          eventToCreate.startTime = school.hourNames.find(
            hourName => hourName.title == eventToCreate.hourName,
          ).startAt;
          eventToCreate.endTime = school.hourNames.find(
            hourName => hourName.title == eventToCreate.hourName,
          ).endAt;
        } else {
          //missing information -> fall top default
          dialog(get(_)('event.HourNameUnset'));
          return false;
        }
      }
    }
    eventToCreate.rrule.dtstart = DateTime.fromISO(
      eventToCreate.startDate + 'T' + eventToCreate.startTime,
    ).toISO();
    let duration = DateTime.fromISO(eventToCreate.endTime)
      .diff(DateTime.fromISO(eventToCreate.startTime))
      .toObject();
    console.log(duration);
    eventToCreate.duration = duration;

    eventToCreate.start = null;
    eventToCreate.end = null;
  } else {
    eventToCreate.rrule = null;
    eventToCreate.startTime = null;
    eventToCreate.endTime = null;
    eventToCreate.startRecur = null;
    eventToCreate.endRecur = null;

    if (!DateTime.fromISO(eventToCreate.startDate).isValid) {
      dialog(get(_)('InvalidStartDate'));
      return false;
    }
    if (eventToCreate.allDay) {
      eventToCreate.startAt = '00:00:00';
      eventToCreate.endAt = '00:00:00';
      if (eventToCreate.multipleDays) {
        //end date as is
        if (!DateTime.fromISO(eventToCreate.endDate).isValid) {
          dialog(get(_)('InvalidEndDate'));
          return false;
        }
      } else {
        eventToCreate.endDate = eventToCreate.startDate;
      }
      eventToCreate.end = DateTime.fromISO(
        eventToCreate.endDate + 'T' + eventToCreate.endAt,
      )
        .plus({ days: 1 })
        .toISO();
    } else {
      if (eventToCreate.multipleDays) {
        //as is
        if (!DateTime.fromISO(eventToCreate.endDate).isValid) {
          dialog(get(_)('InvalidEndDate'));
          return false;
        }
      } else {
        eventToCreate.endDate = eventToCreate.startDate;
        if (eventToCreate.useCustomTime) {
          if (eventToCreate.startAt >= eventToCreate.endAt)
            eventToCreate.endAt = DateTime.fromISO(eventToCreate.startAt)
              .plus({
                hours: 1,
              })
              .toFormat('H:mm:00');
        } else {
          if (eventToCreate.hourName) {
            eventToCreate.startAt = school.hourNames.find(
              hourName => hourName.title == eventToCreate.hourName,
            ).startAt;
            eventToCreate.endAt = school.hourNames.find(
              hourName => hourName.title == eventToCreate.hourName,
            ).endAt;
          } else {
            //missing information -> makeit all day
            dialog(get(_)('event.HourNameUnset'));
            return false;
          }
        }
      }
      eventToCreate.end = DateTime.fromISO(
        eventToCreate.endDate + 'T' + eventToCreate.endAt,
      ).toISO();
    }
    eventToCreate.start = DateTime.fromISO(
      eventToCreate.startDate + 'T' + eventToCreate.startAt,
    ).toISO();
  }
  return eventToCreate;
};
export default fixEventDate;
