<script>
  import { SchoolRole } from '../../lib/store';
  import { _ } from 'svelte-i18n';
  import { ioGet } from '../../lib/realtime';
  export let value = null;
  let rooms = [];
  SchoolRole.subscribe(async obj => {
    if (!obj) return;

    let roomsRes = await ioGet('findRooms', {
      query: {
        schoolID: obj.schoolID,
      },
    });
    console.log(roomsRes);
    if (roomsRes.length) {
      rooms = roomsRes.filter(room => {
        if (obj.category == 'admin') return true;
        let res = room.permissions.find(permission => {
          //console.log(permission, obj)
          if (permission.id == obj.roleID && permission.canEnter) {
            return true;
          }
        });
        return res;
      });
      //console.log(rooms)
    }
  });
</script>

<select class="uk-select" bind:value>
  <option>{$_('Undefined')}</option>
  {#each rooms as room}
    <option value={room._id}>{room.title}</option>
  {/each}
</select>
