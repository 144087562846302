<script lang="ts">
  import { _ } from 'svelte-i18n';
  import areYouSure from '../../lib/helpers/areYouSure';
  import arraySwap from '../../lib/helpers/arraySwap';
  import { AssignmentDefault } from '../../lib/interfaces/Assignments';
  import { CustomFieldDefault } from '../../lib/interfaces/CustomField';
  import {
    School,
    TargetAssignment,
    TargetAssignmentSave,
  } from '../../lib/store';
  import { UUID } from '../../lib/uuid';
  import Button from '../UI/Button.svelte';
  import Input from '../UI/Input.svelte';
  import Modal from '../UI/Modal.svelte';
  import ArrayNav from './ArrayNav.svelte';
  import AssignmentCategories from './AssignmentCategories.svelte';
  import CustomFieldDisplay from './CustomFieldComponents/CustomFieldDisplay.svelte';
  import type { AssignmentCategoryInterface } from '../../lib/interfaces/AssignmentCategory';
  import { showModal } from '../../actions/modalController';
  import { ioGet } from '../../lib/realtime';
  import dialog from '../../lib/helpers/dialog';
  import { assignmentsStore } from '../../state/stores/assignmentsStore';
  import { assign } from 'svelte/internal';
  import { Notification } from '../../actions/notification';
  export let group;
  let assignments = [];
  assignmentsStore.subscribe(obj => {
    assignments = assignmentsStore.list();
  });
  export let category: AssignmentCategoryInterface;
  const save = () => {
    let promises = [];

    assignments.forEach((assignment, i) => {
      promises.push(
        ioGet('updateAssignment', {
          _id: assignment._id,
          data: {
            ...assignment,
            weight: i,
          },
        }),
      );
    });
    Promise.all(promises).then(res => {
      Notification($_('Saved'));
    });
  };
  const add = async () => {
    let newAssignment = {
      ...AssignmentDefault,
      id: UUID(),
      group: group.id,
      assignmentCategoryID: category.id,
      weight: assignments.length,
    };
    let res = await ioGet('createAssignment', { data: newAssignment });
    //assignments.push(res);
    Notification($_('Saved'));
    //assignments = assignments;
  };
  const moveUp = (i: number) => {
    assignments = arraySwap(assignments, i, i - 1);
    console.log(assignments);
  };
  const moveDown = (i: number) => {
    assignments = arraySwap(assignments, i, i + 1);
  };
  const remove = (i: number) => {
    areYouSure(assignments[i].title, async () => {
      let res = await ioGet('deleteAssignment', {
        _id: assignments[i]._id,
      });
      Notification($_('Deleted'));

      //assignments.splice(i, 1);
      //assignments = assignments;
    });
  };
</script>

{#if assignments}
  <table
    class="uk-table uk-table-divider uk-table-small uk-table-justify uk-table-middle"
  >
    <thead>
      <tr>
        <th>{$_('Title')}</th>
        {#each category.customFields.filter(filter => filter.showOnList) as field, i}
          <th>
            {#if field.type != 'color' && field.type != 'image'}
              {field.title}
            {/if}
          </th>
        {/each}
        <th />
      </tr>
    </thead>
    <tbody>
      {#each assignments as assignment, i}
        <tr>
          <td>
            <Input bind:value={assignment.title} />
          </td>
          {#each category.customFields.filter(filter => filter.showOnList) as field, i}
            <td>
              <CustomFieldDisplay
                {field}
                bind:value={assignment.customFieldValues[field.id]}
              />
            </td>
          {/each}

          <td class="uk-text-right">
            <Button
              style="link"
              onclick={() => {
                assignment.category = category;
                TargetAssignment.set(assignment);
                setTimeout(function () {
                  showModal('#assignmentEdit');
                }, 500);
              }}
            >
              <i class="fa-duotone fa-edit fa-fw" />
            </Button>
            <button class="uk-button uk-button-link">
              <i class="fa-duotone fa-ellipsis-vertical fa-fw" />
            </button>
            <div
              uk-dropdown="mode:click"
              class="uk-background-secondary uk-border-rounded uk-light"
            >
              <ArrayNav
                {moveUp}
                {moveDown}
                {remove}
                {i}
                arrayLength={assignments.length}
              />
            </div>
          </td>
        </tr>
      {/each}
    </tbody>
  </table>
{/if}
<Button onclick={add}>{$_('assignments.Add')}</Button>
<Button onclick={save}>{$_('Save')}</Button>
