<script>
  import UUID from '../../lib/helpers/uuid';

  export let visible = false;
  export let id = UUID();
  export let right = false;
</script>

<div
  uk-offcanvas="{right == true ? 'flip: true;' : ''} overlay:true;"
  class={visible ? 'uk-open' : ''}
  {id}
>
  <div class="uk-offcanvas-bar">
    <slot />
  </div>
</div>
