<script>
  import AColorPicker from 'a-color-picker';
  import { onMount } from 'svelte';
  import { ioGet } from '../../lib/realtime';
  import { School } from '../../lib/store';
  let picker;
  export let value;
  let school;
  onMount(() => {
    AColorPicker.from(picker)
      .on('change', (picker, color) => {
        value = color;
      })
      .on('coloradd', async (picker, color) => {
        school.colorPresets = [...school.colorPresets, color];
        let res = await ioGet('updateSchool', {
          _id: $School._id,
          data: {
            colorPresets: school.colorPresets,
          },
        });
      });
  });
  School.subscribe(obj => {
    if (obj) {
      if (typeof obj.colorPresets == 'undefined') obj.colorPresets = ['green'];
      school = obj;
    }
  });
</script>

<div
  bind:this={picker}
  class="picker"
  acp-color="#EFE9E7"
  acp-show-rgb="no"
  acp-show-hsl="no"
  acp-show-hex="no"
  acp-show-alpha="no"
  acp-palette={$School.colorPresets.join('|')}
  acp-palette-editable
/>
