<script>
  import { onMount } from 'svelte';
  import { DateTime } from 'luxon';
  import { ioGet, io } from '../../lib/realtime';
  import { Room, SchoolRole } from '../../lib/store';
  import Offcanvas from '../UI/Offcanvas.svelte';
  import ChatMessage from './ChatMessage.svelte';
  import Button from '../UI/Button.svelte';
  import FloatingPanel from './FloatingPanel.svelte';
  import { heightUI } from '../../lib/defaultValues.js';
  import { _ } from 'svelte-i18n';
  import { Notification } from '../../actions/notification';
  export let hidden = true;
  let updateMessage;
  let room = null;
  let schoolRole = null;
  SchoolRole.subscribe(obj => {
    schoolRole = obj;
  });

  const scrollToBottom = () => {
    if (document.getElementById('chatMessages')) {
      document.getElementById('chatMessages').scrollTop =
        document.getElementById('chatMessages').scrollHeight;
    }
  };
  const refreshMessages = async () => {
    let res = await ioGet('getMessages', {
      roomID: room._id,
    });
    if (res) {
      messages = res.reverse();
    }
    messages.forEach(async (message, i) => {
      message = await getMessageSenderDetail(message);
      messages[i] = message;
    });
    setTimeout(function () {
      scrollToBottom();
    }, 1000);
  };
  const getMessageSenderDetail = async message => {
    let res = await ioGet('getPersona', {
      schoolRoleID: message.sender,
    });
    if (res) {
      message.nickname = res.nickname;
      message.profileImageURL = res.profileImageURL;
    }
    return message;
  };
  Room.subscribe(obj => {
    room = obj;
    if (obj) {
      refreshMessages();
    }
  });

  let messages = [];
  let messageBody = '';
  const sendMessage = async () => {
    let res = await ioGet('sendMessage', {
      body: messageBody,
    });
    messageBody = '';
  };
  io.on('receivedNewMessage', async res => {
    console.log('received new message', res);
    let message = await getMessageSenderDetail(res);

    messages.push(res);
    messages = messages;
    setTimeout(function () {
      scrollToBottom();
    }, 500);
    if (message.sender != schoolRole?.id) {
      //not me!
      if (message.category == 'information') {
        var html =
          `
      <img src="${message.profileImageURL}" class="uk-border-circle" style="width:28px;height:28px" /> ` +
          $_(`chat.${message.body}`);
      } else {
        var html =
          `
      <img src="${message.profileImageURL}" class="uk-border-circle" style="width:28px;height:28px" />  ` +
          $_(`chat.${message.body}`);
      }
      Notification(html);
    }
  });
</script>

<div id="chatPanel" class="uk-light chatPanel" {hidden}>
  <FloatingPanel>
    <div slot="title">{$_('Chat')}</div>
    <ul slot="messages" class="uk-list uk-padding-small">
      {#each messages as message, index}
        <li>
          <ChatMessage
            bind:update={updateMessage}
            {message}
            {index}
            previousDate={index > 0
              ? DateTime.fromISO(messages[index - 1].createdAt).toFormat('D')
              : ''}
            date={DateTime.fromISO(message.createdAt).toFormat('D')}
          />
        </li>
      {/each}
    </ul>
    <div slot="footer" class="uk-grid-collapse" uk-grid>
      <div class="uk-width-expand" style="padding:2px">
        <textarea
          class="uk-textarea"
          bind:value={messageBody}
          placeholder="Message..."
        />
      </div>
      <div style="padding:2px">
        <div class="uk-margin-small-top">
          <Button style="text" onclick={sendMessage}
            ><i class="fa-duotone fa-paper-plane fa-2x" /></Button
          >
        </div>
      </div>
    </div>
  </FloatingPanel>
</div>

<style>
</style>
