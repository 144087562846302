<script lang="ts">
  import type { ArticleInterface } from '../../lib/interfaces/Articles';
  import { _ } from 'svelte-i18n';
  import ArticleHeart from './ArticleHeart.svelte';
  import Lead from '../UI/Lead.svelte';
  import PublicFrom from './PublicFrom.svelte';
  import ViewersBlock from './ViewersBlock.svelte';
  import Button from '../UI/Button.svelte';
  import { School, SchoolRole } from '../../lib/store';
  import { DateTime } from 'luxon';
  import { stripTags } from '../../lib/helpers/stripTags';
  import Icon from '../UI/Icon.svelte';
  export let articles: Array<ArticleInterface> = [];
  export let openArticleDetail: (article: ArticleInterface) => void;
  export let saveArticle: (article: ArticleInterface) => void;
  export let edit: (articleID: string) => void;
  export let noEdit: boolean = false;
</script>

<ul
  class="uk-list uk-list-divider"
  style="background-color:rgba(255,255,255,0.1)"
>
  {#each articles as article}
    <li class="uk-padding-small">
      <div class="uk-margin">
        {#if $School}
          {#if article.isPublic && article.schoolID != $School._id}
            <PublicFrom schoolID={article.schoolID} />
          {:else}
            <ViewersBlock entity={article} />
          {/if}
        {:else}
          <PublicFrom schoolID={article.schoolID} />
        {/if}
      </div>
      <div uk-grid class="uk-grid-small">
        <div class="uk-width-expand uk-text-left">
          {#if article.categoryLabel}
            <div
              class="uk-label"
              style="background-color:{article.backgroundColor};color:rgba(255,255,255,0.8)"
            >
              {article.categoryLabel}
            </div>
          {/if}
          <div>
            {DateTime.fromISO(article.publishAt).toLocaleString()}
          </div>
          <Lead>
            <a on:click={() => openArticleDetail(article)}>
              {article.title}
            </a>
          </Lead>
          <div class="uk-text-truncate">
            {@html stripTags(article.description)}
          </div>
          <div class="uk-grid-small" uk-grid>
            <div>
              <ArticleHeart
                save={() => saveArticle(article)}
                bind:favorites={article.favorites}
              />
            </div>
            <div>
              {$_('Comments')}
              (
              {article.comments?.length || 0}
              )
            </div>
          </div>
        </div>
        <div>
          <a on:click={() => openArticleDetail(article)}>
            <div class="uk-hidden@s">
              <img
                alt="icon"
                src={article.iconImageURL || '/images/no_photo.jpg'}
                style="height:40px;width:40px;"
                class="uk-border-rounded uk-box-shadow-small"
              />
            </div>
            <div class="uk-visible@s">
              <img
                alt="icon"
                src={article.iconImageURL || '/images/no_photo.jpg'}
                style="height:80px;width:80px;"
                class="uk-border-rounded uk-box-shadow-small"
              />
            </div>
          </a>
        </div>
        {#if !noEdit}
          {#if $School.roles.find(role => role.id == $SchoolRole.roleID).school.article.canEdit && !(article.isPublic && article.schoolID != $School._id)}
            <div class="uk-visible@s">
              <Button onclick={() => edit(article._id)}>{$_('Edit')}</Button>
            </div>
            <div class="uk-hidden@s">
              <Button style="text" onclick={() => edit(article._id)}>
                <Icon icon="edit" size="large" />
              </Button>
            </div>
          {/if}
        {/if}
      </div>
    </li>
  {/each}
</ul>
