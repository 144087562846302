<script>
  import { onMount } from 'svelte';
  import { ioGet } from '../lib/realtime';
  import {
    School,
    SchoolRole,
    TargetPersona,
    TargetPersonas,
  } from '../lib/store';
  import VerticalTab from './UI/VerticalTab.svelte';
  import { _ } from 'svelte-i18n';

  import { defineIfUndefined } from '../lib/helpers/defineIfUndefined';
  import { navigate, Route, Router } from 'svelte-routing';
  import AccountTable from './blocks/AccountTable.svelte';
  import { personasStore } from '../state/stores/personasStore';
  let schoolRole = {
    registrations: [],
  };
  SchoolRole.subscribe(obj => {
    if (obj) {
      obj.registrations = defineIfUndefined(obj.registrations, []);
      obj.customFieldValues = defineIfUndefined(obj.customFieldValues, {});
      schoolRole = obj;
    }
  });
  let school;
  onMount(async () => {
    list();
  });
  const list = async school => {
    if (!$School) return;
    let userRolesRes = await ioGet('findUserRolesBySchool', {
      schoolID: $School._id,
    });

    userRolesRes.forEach(userRole => {
      personasStore.add(userRole);
    });
  };

  let focus = location.href.split('/').pop().replace('#', '');
  const nav = id => {
    navigate(id);
    focus = id;
  };
</script>

<VerticalTab>
  <ul slot="menu" class="uk-tab-left" uk-tab>
    {#each $School.roles.filter(role => role.category != 'guest') as role}
      <li class:uk-active={focus == role.id}>
        <a href={'#'} on:click={nav(role.id)}>
          {role.title}
        </a>
      </li>
    {/each}
  </ul>
  <div slot="content">
    <Router>
      {#each $School.roles.filter(role => role.category != 'guest') as role}
        <Route path={role.id}>
          <AccountTable {role} />
        </Route>
      {/each}
    </Router>
  </div>
</VerticalTab>
