<script lang="ts">
  import FileUpload from '../FileUpload.svelte';
  import { _ } from 'svelte-i18n';
  import {
    ImageEditCallback,
    ImageEditSquare,
    ImageEditURL,
  } from '../../../lib/store';
  export let value = null;
  export let setFilename: (title: string) => void;
  export let original = false;
  const uploadIcon = res => {
    ImageEditURL.set(res.data.url);
    ImageEditSquare.set(!original);
    ImageEditCallback.set(res2 => {
      value = res2.data.url;
      console.log('returned data', res.data);
      setFilename(res.data.filename);
    });
    UIkit.modal('#imageEditor').show();
  };
</script>

<div class="uk-text-center">
  <img
    alt="icon"
    src={value || '/images/no_photo.jpg'}
    style={original ? '' : 'height:80px;width:80px;'}
    class="uk-border-rounded uk-margin-small-bottom uk-box-shadow-small"
  />
  <br />
  <FileUpload small fileType="image" onUploadComplete={uploadIcon}>
    {$_('Upload')}
  </FileUpload>
</div>
