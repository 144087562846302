<script>
  import { onMount } from 'svelte';
  import { ioFetch, ioGet } from '../../lib/realtime';

  export let returnFilePath;

  onMount(() => {
    var el = document.getElementById('skfb-widget');
    var skfbWidget = new SketchfabImporter(el, {
      onModelSelected: async function (result) {
        //Do something with the result
        console.log(result);
        let path = await ioGet('loadExternalFile', {
          url: result.download.glb.url,
        });
        returnFilePath(path);
      },
    });
  });
</script>

<div
  id="skfb-widget"
  class="uk-height-large uk-border-rounded uk-overflow-hidden"
/>
