<script lang="ts">
  import Button from '../UI/Button.svelte';
  import Navbar from '../UI/Navbar.svelte';
  import { _ } from 'svelte-i18n';
  export let arrayLength: number = 0;
  export let i: number = 0;
  export let moveUp: Boolean | ((i: number) => void) = false;
  export let moveDown: Boolean | ((i: number) => void) = false;
  export let remove: Boolean | ((i: number) => void) = false;
  export let sideway: boolean = false;
</script>

<Navbar>
  <div slot="right">
    <ul class="uk-subnav uk-margin-remove uk-text-right">
      {#if arrayLength != 1}
        {#if i != 0}
          {#if moveUp !== false}
            <li>
              <Button style="text" small onclick={() => moveUp(i)}>
                {#if sideway}
                  <i class="fa-duotone fa-left" />
                  {$_('arrayNav.MoveLeft')}
                {:else}
                  <i class="fa-duotone fa-up" />
                  {$_('arrayNav.MoveUp')}
                {/if}
              </Button>
            </li>
          {/if}
        {/if}
        {#if i != arrayLength - 1}
          {#if moveDown !== false}
            <li>
              <Button style="text" small onclick={() => moveDown(i)}>
                {#if sideway}
                  <i class="fa-duotone fa-right" />
                  {$_('arrayNav.MoveRight')}
                {:else}
                  <i class="fa-duotone fa-down" />
                  {$_('arrayNav.MoveDown')}
                {/if}
              </Button>
            </li>
          {/if}
        {/if}
      {/if}
      {#if remove !== false}
        <li>
          <Button style="text" small onclick={() => remove(i)}>
            <i class="fa-duotone fa-trash" />

            {$_('arrayNav.Remove')}
          </Button>
        </li>
      {/if}
    </ul>
  </div>
</Navbar>
