import { dashboardGumballTarget, gumballTargetType } from '../lib/store.js';
import { resetDashboardGumball } from '../lib/resizeGumball';
import { hideAFRAME, showAFRAME } from '../lib/visibilityAFRAME.js';
import resetFocus from '../lib/helpers/resetFocus.js';
import { getSource } from '../actions/mediaItemController';
import gumballSchema from '../lib/helpers/gumballSchema.js';
import initGumball from '../lib/helpers/initGumball.js';

let target = null;

dashboardGumballTarget.subscribe(val => {
  target = val;
});
AFRAME.registerComponent('show-dashboard-gumball', {
  schema: gumballSchema,
  init: function () {
    this.gumball = document.getElementById('dashboardGumball');
    initGumball(this, 'isDashbord');
  },
  tick: function () {
    if (target?.id == this.el.id) {
      resetDashboardGumball(this.el);
      //document.getElementById('dashboardGumball').setAttribute('look-at',"#myAvatarEyes")
    }
  },
  remove: function () {
    resetFocus();
  },
});
