<script lang="ts">
  import dialog from '../../lib/helpers/dialog';

  import { ioGet } from '../../lib/realtime';
  import { _ } from 'svelte-i18n';
  import { School, SchoolRole, TargetProgramCategory } from '../../lib/store';
  import VerticalTab from '../UI/VerticalTab.svelte';
  import AssignmentsTable from './AssignmentsTable.svelte';
  import AssignmentDetailContent from './AssignmentDetailContent.svelte';
  import { navigate, Route, Router } from 'svelte-routing';
  import { groupIntersectingEntries } from '@fullcalendar/core';
  import { onMount, setContext } from 'svelte';
  import copy from '../../lib/helpers/copy';
  import { children } from 'svelte/internal';
  import { defineIfUndefined } from '../../lib/helpers/defineIfUndefined';
  let school = null;
  let mainTab;
  let focus;
  let focusGroup = null;
  School.subscribe(obj => {
    if (obj) {
      if (typeof obj.assignmentCategories == 'undefined')
        obj.assignmentCategories = [];
      obj.assignmentCategories = obj.assignmentCategories.map(category => {
        category.submissionCustomFields = defineIfUndefined(
          category.submissionCustomFields,
          [],
        );
        return category;
      });
      obj.intervals = obj.intervals.map(interval => {
        if (typeof interval.customFields == 'undefined') {
          interval.customFields = {
            grade: [],
          };
        }
        if (typeof interval.customFields.grade == 'undefined') {
          interval.customFields.grade = [];
        }

        interval.programCategories = interval.programCategories.map(
          category => {
            category.groups = category.groups.map(group => {
              if (
                typeof group.assignments == 'undefined' ||
                Array.isArray(group.assignments)
              ) {
                group.assignments = {};
                obj.assignmentCategories.forEach(assignmentCategory => {
                  group.assignments[assignmentCategory.id] = [];
                });
              }
              group.children = group.children.map(child => {
                if (
                  typeof child.assignments == 'undefined' ||
                  Array.isArray(child.assignments)
                ) {
                  child.assignments = {};
                  obj.assignmentCategories.forEach(assignmentCategory => {
                    child.assignments[assignmentCategory.id] = [];
                  });
                }
                return child;
              });
              return group;
            });
            return category;
          },
        );
        return interval;
      });
      school = obj;
      if (!focusGroup) {
        school.intervals.forEach(interval => {
          if (location.href.indexOf(interval.id) > 0) focus = interval.id;
          if (focusGroup) return;
          interval.programCategories.forEach(programCategory => {
            if (focusGroup) return;
            programCategory.groups.forEach(group => {
              group.children.forEach(child => {
                if (focusGroup) return;
                if (location.href.indexOf(child.id) > 0) focusGroup = child.id;
              });
              if (focusGroup) return;
              if (location.href.indexOf(group.id) > 0) focusGroup = group.id;
            });
          });
        });
      }
    }
  });
  const saveSchool = async () => {
    await ioGet('updateSchool', {
      _id: $School._id,
      data: school,
    });
    School.set(school);
    dialog($_('Saved'));
  };
  onMount(() => {
    if (!school.features.useIntervals) {
      navInterval(school.intervals[0]);
    }
    if (!focus) {
      navInterval(school.intervals[0]);
    }
    if (!focusGroup) {
      console.log('focus group not set');
    }
  });
  const navInterval = interval => {
    if (!interval) return;
    navigate(`/${school.slug}/assignments/${interval.id}/`);
    focus = interval.id;
  };
</script>

{#if school && $SchoolRole}
  <ul hidden={!school.features.useIntervals} class="uk-tab" bind:this={mainTab}>
    {#each school.intervals as interval}
      <li class:uk-active={focus == interval.id}>
        <a href={'#'} on:click={() => navInterval(interval)}>
          {interval.title}
        </a>
      </li>
    {/each}
  </ul>
  <div>
    <Router>
      <Route path=":intervalID/*" let:params>
        {#each school.intervals.filter(interval => interval.id == params.intervalID) as interval}
          {@const programCategories = interval.programCategories.filter(
            category =>
              category.useToGroupAssignment || category.useToGroupGrades,
          )}
          <VerticalTab>
            <ul slot="menu" class="uk-tab uk-tab-left">
              {#key $SchoolRole.registrations}
                {#each programCategories as programCategory}
                  {#if programCategory.groups.length}
                    <li class="uk-nav-header">
                      {programCategory.title}
                    </li>
                  {/if}
                  {#each programCategory.groups as group}
                    {#if group.children.length}
                      {@const groupChildren = group.children.filter(child =>
                        $SchoolRole.registrations.some(
                          registration => registration.programID == child.id,
                        ),
                      )}
                      {#if groupChildren.length}
                        <li>
                          {group.title}
                        </li>
                        {#each groupChildren as child}
                          <li class:uk-active={focusGroup == child.id}>
                            <a
                              href={'#'}
                              on:click={() => {
                                navigate(
                                  `/${school.slug}/assignments/${interval.id}/${group.id}/${child.id}/`,
                                );
                                focusGroup = child.id;
                              }}
                              class="uk-margin-left"
                            >
                              <img
                                alt="icon"
                                src={child.iconImageURL ||
                                  '/images/no_photo.jpg'}
                                style="height:40px;width:40px;"
                                class="uk-border-rounded"
                              />
                              {child.title}
                            </a>
                          </li>
                        {/each}
                      {/if}
                    {:else if $SchoolRole.registrations.some(registration => registration.programID == group.id)}
                      <li class:uk-active={focusGroup == group.id}>
                        <a
                          href={'#'}
                          on:click={() => {
                            navigate(
                              `/${school.slug}/assignments/${interval.id}/${group.id}/`,
                            );
                            focusGroup = group.id;
                          }}
                        >
                          <img
                            alt="icon"
                            src={group.iconImageURL || '/images/no_photo.jpg'}
                            style="height:40px;width:40px;"
                            class="uk-border-rounded"
                          />
                          {group.title}
                        </a>
                      </li>
                    {/if}
                  {/each}
                {/each}
              {/key}
            </ul>
            <div slot="content">
              <Router>
                <Route path=":groupID/*" let:params>
                  {#each programCategories as programCategory}
                    {#each programCategory.groups.filter(group => group.id == params.groupID) as group}
                      {#if group.children.length == 0}
                        {#key group.id}
                          <AssignmentDetailContent
                            bind:interval
                            {programCategory}
                            bind:school
                            bind:group
                            save={saveSchool}
                            isChild={false}
                          />
                        {/key}
                      {:else}
                        <Router>
                          <Route path=":childID/*" let:params>
                            {#each group.children.filter(child => child.id == params.childID) as child}
                              {#key child.id}
                                <AssignmentDetailContent
                                  bind:interval
                                  {programCategory}
                                  bind:school
                                  bind:group={child}
                                  save={saveSchool}
                                  isChild={true}
                                />
                              {/key}
                            {/each}
                          </Route>
                        </Router>
                      {/if}
                    {/each}
                  {/each}
                </Route>
              </Router>
            </div>
          </VerticalTab>
        {/each}
      </Route>
    </Router>
  </div>
{/if}
