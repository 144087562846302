<script>
  import { darkBG } from '../../lib/defaultValues';

  import Lead from '../UI/Lead.svelte';
  import PoweredBy from './PoweredBy.svelte';
</script>

<div
  class="uk-card dark uk-card-body uk-border-rounded uk-light"
  style="background-color:{darkBG};"
>
  <slot name="breadcrumb" />
  <article class="uk-article">
    <h1 class="uk-article-title">
      <slot name="title" />
    </h1>
    <p class="uk-article-meta" />
    <Lead>
      <slot name="lead" />
    </Lead>
    <p>
      <slot name="description" />
    </p>
    <slot name="body" />
  </article>
</div>
