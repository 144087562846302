<script>
  import { _ } from 'svelte-i18n';
  export let unfilteredArray;
  export let returnArray;

  let keyword;
  const filter = () => {
    let res = unfilteredArray.filter(item => {
      if (!keyword) return true;
      return JSON.stringify(item).indexOf(keyword) > -1;
    });
    returnArray(res);
  };
</script>

<div class="uk-inline">
  <span class="uk-form-icon">
    <i class="fa-duotone fa-magnifying-glass" />
  </span>
  <input
    class="uk-input"
    bind:value={keyword}
    on:input={filter}
    placeholder={$_('Filter')}
  />
</div>
