<script>
  import { _ } from 'svelte-i18n';
  import { TargetArticle, School, Articles, SchoolRole } from '../../lib/store';

  import EventDetail from '../blocks/EventDetail.svelte';
  import EventEdit from '../blocks/EventEdit.svelte';
  import Button from '../UI/Button.svelte';
  import Modal from '../UI/Modal.svelte';
  import { DateTime } from 'luxon';
  import { ioGet } from '../../lib/realtime';
  import { UUID } from '../../lib/uuid';
  import fixEventDate from '../../lib/helpers/fixEventDate';
  import dialog from '../../lib/helpers/dialog';
  import CalendarController from '../../actions/calendarController';
  import ArticleEdit from '../blocks/ArticleEdit.svelte';
  import ArticleDetail from '../blocks/ArticleDetail.svelte';
  import articleController from '../../actions/articleController';
  import Article from '../UI/Article.svelte';
  import Dropdown from '../UI/Dropdown.svelte';
  import areYouSure from '../../lib/helpers/areYouSure';
  import previewViewers from '../../lib/helpers/previewViewers';
  import createCategory from '../../lib/helpers/createCategory';
  import { hideModal } from '../../actions/modalController';
  import { defineIfUndefined } from '../../lib/helpers/defineIfUndefined';
  import LinkToDocumentation from '../blocks/LinkToDocumentation.svelte';
  let article = null;
  TargetArticle.subscribe(data => {
    if (data) {
      data.customFieldValues = defineIfUndefined(data.customFieldValues, {});
      $School.customFields['article'].forEach(field => {
        data.customFieldValues[field.id] = defineIfUndefined(
          data.customFieldValues[field.id],
          {},
        );
      });
      article = data;
    }
  });
  const updateArticle = async () => {
    console.log(article);
    article.publishAt = DateTime.fromISO(
      article.startDate + 'T' + article.publishTime + ':00',
    ).toISO();

    let viewerRes = await previewViewers(article);
    if (!viewerRes) return;
    let res = await ioGet('updateArticle', {
      _id: article._id,
      data: article,
    });
    console.log(res);
    hideModal('#editArticle');
    let articles = await articleController.list();
    Articles.set(articles);
  };
  const deleteArticle = async () => {
    areYouSure(article.title, async () => {
      let res = await ioGet('deleteArticle', {
        _id: article._id,
      });
      console.log(res);
      hideModal('#editArticle');
      let articles = await articleController.list();
      Articles.set(articles);
    });
  };
  const createArticle = async () => {
    console.log(article);
    article.publishAt = DateTime.fromISO(
      article.startDate + 'T' + article.publishTime + ':00',
    ).toISO();
    article.schoolID = $School._id;

    let viewerRes = await previewViewers(article);
    console.log(viewerRes);
    if (!viewerRes) return;
    var result = await ioGet('createArticle', {
      data: article,
    });
    console.log(result);
    hideModal('#createArticle');
    let articles = await articleController.list();
    Articles.set(articles);
  };

  const affectPreset = id => {
    let category = $School.articleCategories.find(
      category => category.id == id,
    );
    Object.keys(category.articlePresets).forEach(key => {
      if (key != 'id' && key != '_id' && key != 'startDate') {
        if (key == 'customFieldValues') {
          Object.keys(category.articlePresets.customFieldValues).forEach(
            fieldID => {
              article.customFieldValues[fieldID] =
                category.articlePresets.customFieldValues[fieldID];
            },
          );
        } else {
          article[key] = category.articlePresets[key];
        }
      }
    });
  };
</script>

{#if $School && $SchoolRole}
  <Modal
    withFooter
    id="articleDetail"
    size="container"
    title={$_('article.ArticleDetail')}
  >
    <div slot="body">
      <ArticleDetail bind:article />
    </div>
    <div slot="footer">
      <Button onclick={updateArticle}>{$_('Save')}</Button>
    </div>
  </Modal>
  <Modal
    bgClose={false}
    withFooter
    id="createArticle"
    size="container"
    title={$_('article.CreateArticle')}
  >
    <div slot="body">

      <a class="uk-button uk-button-default" href={'#'}
        >{$_('event.SelectCategory')}</a
      >
      <Dropdown>
        {#each $School.articleCategories || [] as category}
          <li value={category.id}>
            <a
              href={'#'}
              on:click={() => {
                affectPreset(category.id);
              }}
            >
              {category.articlePresets.categoryLabel}
            </a>
          </li>
        {/each}
      </Dropdown>
      {#if $School.roles.find(schoolRole => schoolRole.id == $SchoolRole.roleID)?.school.master.canCreate}
        <Button onclick={() => createCategory(article, 'article')}
          >{$_('CreateCategory')}</Button
        >
      {/if}
      <hr />
      {#if article}
        <ArticleEdit bind:article />
      {/if}
    </div>
    <div slot="footer">
      <Button
        onclick={() => {
          hideModal('#createArticle');
        }}>{$_('Cancel')}</Button
      >
      <Button onclick={createArticle}>{$_('Create')}</Button>
    </div>
  </Modal>
  <Modal
    bgClose={false}
    withFooter
    id="editArticle"
    size="container"
    title={$_('article.EditArticle')}
  >
    <div slot="body">
      <a class="uk-button uk-button-default" href={'#'}
        >{$_('event.SelectCategory')}</a
      >
      <Dropdown>
        {#each $School.articleCategories || [] as category}
          <li value={category.id}>
            <a
              href={'#'}
              on:click={() => {
                affectPreset(category.id);
              }}
            >
              {category.articlePresets.categoryLabel}
            </a>
          </li>
        {/each}
      </Dropdown>
      {#if $School.roles.find(schoolRole => schoolRole.id == $SchoolRole.roleID)?.school.master.canCreate}
        <Button onclick={() => createCategory(article, 'article')}
          >{$_('CreateCategory')}</Button
        >
      {/if}
      <hr />
      {#if article}
        <ArticleEdit bind:article />
      {/if}
    </div>
    <div slot="footer">
      <Button
        onclick={() => {
          hideModal('#editArticle');
        }}>{$_('Cancel')}</Button
      >

      <Button onclick={updateArticle}>{$_('Save')}</Button>
      {#if $School.roles.find(schoolRole => schoolRole.id == $SchoolRole.roleID)?.school.article?.canDelete}
        <Button style="danger" onclick={deleteArticle}>{$_('Delete')}</Button>
      {/if}
    </div>
  </Modal>
{/if}
