<script>
  import { onMount } from 'svelte';
  import {
    calculateAttendanceSummaryEachRow,
    calculateSummaryEachRow,
  } from '../../lib/helpers/calculateSummaryEachRow';
  import { defineIfUndefined } from '../../lib/helpers/defineIfUndefined';
  import AssignmentCategoryCustomFields from './AssignmentCategoryCustomFields.svelte';

  import AttendanceCredit from './AttendanceCredit.svelte';
  export let subscriber;
  export let view;
  export let group;
  export let events;
  const calculate = () => {
    group.summaryFieldValues = defineIfUndefined(group.summaryFieldValues, {});
    group = calculateAttendanceSummaryEachRow(events, view, subscriber, group);
    console.log('summary field value', group.summaryFieldValues);
  };
  onMount(async () => {
    calculate();
  });
</script>

{#each view.customFields || [] as summaryField}
  <td class="uk-text-center">
    {#if group.summaryFieldValues}
      {#if group.summaryFieldValues[summaryField.id]}
        {group.summaryFieldValues[summaryField.id].value}
      {/if}
    {/if}
  </td>
{/each}
<td>
  <div class="uk-flex">
    {#each events as schoolEvent, i}
      {@const attendance = schoolEvent.attendanceSettings
        .find(setting => setting.roleID == subscriber.roleID)
        .attendances.find(
          attendance => attendance.schoolRoleID == subscriber.id,
        )}
      {#if attendance}
        <div class="uk-margin-small-right">
          {#key schoolEvent}
            <AttendanceCredit {attendance} {subscriber} {schoolEvent} />
          {/key}
        </div>
      {/if}
    {/each}
  </div>
</td>
