<script>
  import {
    User,
    micOnMute,
    myCurrentFace,
    emotePanelVisible,
    noiseThreshold,
    megaphoneActive,
    SchoolRole,
  } from '../../lib/store';

  import {
    videoController,
    muteAudio,
    normalAudio,
  } from '../../actions/videoController.js';
  import { Scene } from 'three';
  import assignAnimation from '../../lib/helpers/assignAnimation';
  import resetFocus from '../../lib/helpers/resetFocus';
  import { hideAFRAME, showAFRAME } from '../../lib/visibilityAFRAME';
  import { dashboardClose, dashboardOut } from '../../lib/defaultValues';

  let dashboardVisible = true;
  let dashboardZoomed = false;
  let y = 0;
  let user;
  let thisEmotePanelVisible = false;
  User.subscribe(id => {
    user = id;
  });
  let schoolRole;
  SchoolRole.subscribe(obj => {
    schoolRole = obj;
  });
  const toggleDashboard = () => {
    let dashboard = document.getElementById('myDashboard');
    dashboardVisible = !dashboardVisible;
    if (dashboardVisible) {
      dashboard.object3D.position.y = y;
      resetFocus();
      showAFRAME(dashboard);
    } else {
      hideAFRAME(dashboard);
      y = dashboard.object3D.position.y;
      resetFocus();
    }
  };

  let mute = false;
  let emotePanel;

  micOnMute.subscribe(bool => {
    mute = bool;
  });
  let threshold;
  noiseThreshold.subscribe(val => {
    threshold = val;
  });
  emotePanelVisible.subscribe(bool => {
    emotePanel = bool;
  });

  export let id;
</script>

<a-entity {id} position="0 -0.15 -0.25" rotation="0 0 0">
  <a-entity
    id="myAvatarPreview"
    position="0 -0.085 0"
    scale="-0.16 0.16 0.16"
    rotation="330 0 0"
    hide-hands
    animation-mixer
    slave-neck-to-hands="targetId:{$SchoolRole?.id};useFace:true;"
  />
</a-entity>
