<script lang="ts">
  import { onMount } from 'svelte';

  import Modal from '../UI/Modal.svelte';
  import Button from '../UI/Button.svelte';
  import { hideModal, showModal } from '../../actions/modalController';
  export let loadRPMAvatar: (url: string) => void;

  // const subdomain = 'aoikevos'; // Replace with your custom subdomain
  window.addEventListener('message', subscribe);

  function subscribe(event) {
    const json = parse(event);
    if (json) {
      if (json.source !== 'readyplayerme') {
        return;
      }
    }
    // Get avatar GLB URL
    if (
      event.origin.indexOf(subdomain + '.readyplayer.me') > 0 &&
      event.data.indexOf('.glb') > 0
    ) {
      loadRPMAvatar(event.data);
      hideModal('#createAvatar');
      //document.getElementById('avatarUrl').innerHTML = "Avatar URL: " + json.data.url;
      //document.getElementById('frame').hidden = true;
    }
  }
  // onMount(() => {
  //   const frame = document.getElementById('readyPlayerMeIFrame');
  //   frame.src =
  //     'https://' + subdomain + '.readyplayer.me/avatar?frameApi&theme=Dark';

  //   document.addEventListener('message', subscribe);
  // });
  function parse(event) {
    try {
      return JSON.parse(event.data);
    } catch (error) {
      return null;
    }
  }
  function displayIframe() {
    showModal('#createAvatar');
  }
</script>

<Button small style="text" onclick={displayIframe}><slot /></Button>

<div uk-modal="stack:true" id="createAvatar" class="uk-modal-container">
  Avatar Editor Not Available
  <!-- <iframe
    title="create avatar"
    id="readyPlayerMeIFrame"
    class="frame"
    allow="camera *; microphone *"
  /> -->
</div>

<style>
  .frame {
    max-width: 1080px;
    width: 100%;
    height: 800px;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
      Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    padding: 20px;
    font-size: 14px;
    border: none;
  }
</style>
