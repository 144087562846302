<script>
  export let entity = null;
</script>

{#if entity}
  <span
    class="uk-label"
    style="background-color:{entity.backgroundColor};color:rgba(255,255,255,0.8)"
  >
    {entity.categoryLabel}
  </span>
{/if}
