<script>
  import Editor from '@tinymce/tinymce-svelte';
  import Cookies from 'js-cookie';
  import { beforeUpdate, onMount } from 'svelte';
  import { _ } from 'svelte-i18n';
  import { ENDPOINT } from '../../lib/config';
  import { defineIfUndefined } from '../../lib/helpers/defineIfUndefined';
  import upload from '../../lib/helpers/upload';
  import UUID from '../../lib/helpers/uuid';
  import { previewValue } from '../../lib/store';
  import VideoFileUpload from './VideoFileUpload.svelte';
  export let value = '';
  let id = '';
  onMount(() => {
    id = UUID();
  });
  beforeUpdate(() => {
    value = defineIfUndefined(value, ' ');
    if (value == '') {
      value = ' ';
    }
  });
  const updatePreview = () => {
    MathJax.typeset(document.querySelectorAll('.mathPreview'));
  };
  const uploadFromTinyTextarea = (blobInfo, progress) =>
    new Promise((resolve, reject) => {
      //Loading.show()
      const fd = new FormData();
      fd.append('myFile', blobInfo.blob(), blobInfo.filename());
      upload(
        fd,
        res => {
          console.log(res);
          resolve(res.data.url);
        },
        reject,
      );
    });
  let conf = {
    plugins: 'image media link table',
    toolbar:
      'undo redo | styles | bold italic underline backcolor forecolor fontsize | link image media videoUpload | alignleft aligncenter alignright table |previewMath mathCheatSheet',
    menubar: false,
    image_uploadtab: true,
    images_file_types: 'jpg,png',
    images_upload_url: ENDPOINT + '/upload',
    images_upload_credentials: true,
    object_resizing: true,
    images_upload_handler: uploadFromTinyTextarea,
    content_style:
      'body{background-color: #444;color:rgba(255,255,255,0.8);padding:5px;}',
    language: Cookies.get('locale') == 'ja' ? 'ja' : null,
    image_caption: true,
    file_picker_types: 'media',
    skin: 'oxide-dark',
    statusbar: true,
    branding: false,
    elementpath: false,
    resize: true,
    setup: editor => {
      editor.ui.registry.addButton('videoUpload', {
        text: $_('UploadVideo'),
        onAction: async () => {
          document.getElementById(id).click();
        },
      });
      editor.ui.registry.addButton('previewMath', {
        text: $_('math.Preview'),
        onAction: async () => {
          previewValue.set(value);
          setTimeout(function () {
            UIkit.modal('#mathPreviewModal').show();
          }, 100);
        },
      });
      editor.ui.registry.addButton('mathCheatSheet', {
        text: $_('math.CheatSheet'),
        onAction: async () => {
          UIkit.modal('#mathCheatSheet').show();
        },
      });
    },
  };
</script>

<div hidden>
  <VideoFileUpload
    {id}
    onUploadComplete={data => {
      console.log(data);
      value = value + data.assets.iframe;
    }}
  />
</div>

<Editor
  class="mathpreview"
  bind:value
  scriptSrc="/tinymce/tinymce.min.js"
  {conf}
/>
<div />
