<script>
  import { DateTime } from 'luxon';
  import { _ } from 'svelte-i18n';
  import areYouSure from '../../lib/helpers/areYouSure';
  import arraySwap from '../../lib/helpers/arraySwap';
  import { defineIfUndefined } from '../../lib/helpers/defineIfUndefined';
  import dialog from '../../lib/helpers/dialog';
  import { ioGet } from '../../lib/realtime';
  import { School } from '../../lib/store';
  import { UUID } from '../../lib/uuid';
  import Button from '../UI/Button.svelte';
  import Checkbox from '../UI/Checkbox.svelte';
  import Input from '../UI/Input.svelte';
  import Lead from '../UI/Lead.svelte';
  import ArrayNav from './ArrayNav.svelte';
  import LinkToDocumentation from './LinkToDocumentation.svelte';
  let school;
  const DEFAULT_REGISTRATION_START = 2; // WEEKS
  const DEFAULT_REGISTRATION_END = 0; // WEEKS

  School.subscribe(obj => {
    if (obj) {
      if (obj.intervals && obj.intervals.length) {
        obj.intervals = obj.intervals.map(interval => {
          if (interval.startOn) {
            interval.startOn = DateTime.fromISO(interval.startOn).toISODate();
          }
          if (interval.endOn) {
            interval.endOn = DateTime.fromISO(interval.endOn).toISODate();
          }
          if (interval.registrationStartsAt) {
            interval.registrationStartsAt = DateTime.fromISO(
              interval.registrationStartsAt,
            ).toISODate();
          }
          if (interval.registrationEndsAt) {
            interval.registrationEndsAt = DateTime.fromISO(
              interval.registrationEndsAt,
            ).toISODate();
          }
          if (!interval.registrationStartsAt && interval.startOn) {
            interval.registrationStartsAt = DateTime.fromISO(interval.startOn)
              .minus({ weeks: DEFAULT_REGISTRATION_START })
              .toISODate();
          }
          if (!interval.registrationEndsAt && interval.endOn) {
            interval.registrationEndsAt = DateTime.fromISO(interval.endOn)
              .minus({ weeks: DEFAULT_REGISTRATION_END })
              .toISODate();
          }

          interval.programCategories = interval.programCategories.map(
            programCategory => {
              programCategory.id = defineIfUndefined(
                programCategory.id,
                UUID(),
              );
              return programCategory;
            },
          );
          return interval;
        });
      }
      school = obj;
    }
  });

  const addCategory = intervalID => {
    categoryOperation(intervalID, null, interval => {
      interval.programCategories.push({
        id: UUID(),
        Title: '',
        groups: [],
        useToGroupAdmin: false,
        useToGroupManager: false,
        useToGroupSubscriber: true,
        useToGroupGuest: false,
        useToGroupEvent: true,
        useToGroupAssignment: false,
      });
      return interval;
    });
  };

  const removeCategory = (intervalID, i) => {
    categoryOperation(intervalID, i, (interval, i) => {
      interval.programCategories.splice(i, 1);
      return interval;
    });
  };
  const moveUpCategory = (intervalID, i) => {
    categoryOperation(intervalID, i, (interval, i) => {
      interval.programCategories = arraySwap(
        interval.programCategories,
        i,
        i - 1,
      );
      return interval;
    });
  };
  const moveDownCategory = (intervalID, i) => {
    categoryOperation(intervalID, i, (interval, i) => {
      interval.programCategories = arraySwap(
        interval.programCategories,
        i,
        i + 1,
      );
      return interval;
    });
  };
  const categoryOperation = (intervalID, i, callback) => {
    school.intervals = school.intervals.map(interval => {
      if (interval.id == intervalID) {
        interval = callback(interval, i);
      }
      return interval;
    });
  };
  const addGroup = (intervalID, categoryID) => {
    groupOperation(intervalID, categoryID, null, category => {
      let newGroup = {};
      newGroup.id = UUID();
      newGroup.title = '';
      newGroup.children = [];
      newGroup.useToGroupAdmin = category.useToGroupAdmin;
      newGroup.useToGroupAdmin = category.useToGroupManager;
      newGroup.useToGroupAdmin = category.useToGroupSubscriber;
      newGroup.useToGroupAdmin = category.useToGroupGuest;
      newGroup.useToGroupAdmin = category.useToGroupEvent;
      newGroup.useToGroupAdmin = category.useToGroupAssignment;
      category.groups.push(newGroup);
      return category;
    });
  };
  const removeGroup = (intervalID, categoryID, i) => {
    groupOperation(intervalID, categoryID, i, (category, i) => {
      category.groups.splice(i, 1);
      return category;
    });
  };
  const moveUpGroup = (intervalID, categoryID, i) => {
    groupOperation(intervalID, categoryID, i, (category, i) => {
      category.groups = arraySwap(category.groups, i, i - 1);
      return category;
    });
  };
  const moveDownGroup = (intervalID, categoryID, i) => {
    groupOperation(intervalID, categoryID, i, (category, i) => {
      category.groups = arraySwap(category.groups, i, i + 1);
      return category;
    });
  };

  const groupOperation = (intervalID, categoryID, i, callback) => {
    school.intervals = school.intervals.map(interval => {
      if (interval.id == intervalID) {
        interval.programCategories = interval.programCategories.map(
          category => {
            if (category.id == categoryID) {
              category = callback(category, i);
            }
            return category;
          },
        );
      }
      return interval;
    });
  };
  const addChild = (intervalID, categoryID, groupID) => {
    childOperation(intervalID, categoryID, groupID, null, group => {
      let newChild = {};
      newChild.id = UUID();
      newChild.title = '';
      group.children.push(newChild);
      return group;
    });
  };
  const childOperation = (intervalID, categoryID, groupID, i, callback) => {
    school.intervals = school.intervals.map(interval => {
      if (interval.id == intervalID) {
        interval.programCategories = interval.programCategories.map(
          category => {
            if (category.id == categoryID) {
              category.groups = category.groups.map(group => {
                if (group.id == groupID) {
                  group = callback(group, i);
                }
                return group;
              });
            }
            return category;
          },
        );
      }
      return interval;
    });
  };
  const removeChild = (intervalID, categoryID, groupID, i) => {
    childOperation(intervalID, categoryID, groupID, i, (group, i) => {
      group.children.splice(i, 1);
      return group;
    });
  };
  const moveUpChild = (intervalID, categoryID, groupID, i) => {
    childOperation(intervalID, categoryID, groupID, i, (group, i) => {
      group.children = arraySwap(group.children, i, i - 1);
      return group;
    });
  };
  const moveDownChild = (intervalID, categoryID, groupID, i) => {
    childOperation(intervalID, categoryID, groupID, i, (group, i) => {
      group.children = arraySwap(group.children, i, i + 1);
      return group;
    });
  };

  const saveInterval = async id => {
    await ioGet('updateSchoolInterval', {
      _id: school._id,
      id: id,
      data: school.intervals.find(interval => interval.id == id),
    });
    dialog($_('Saved'));
  };
  const copyInterval = async id => {
    let res = await ioGet('duplicateSchoolInterval', {
      _id: school._id,
      id: id,
    });
    school.intervals.push(res);
    School.set(school);
  };
  const removeInterval = async interval => {
    areYouSure(interval.title, async () => {
      let res = await ioGet('deleteSchoolInterval', {
        _id: school._id,
        id: interval.id,
      });
      school.intervals = res;
    });
  };
</script>

<div>
  <ul
    class="uk-tab-left"
    uk-tab="animation: uk-animation-fade"
    hidden={!$School.features.useIntervals}
  >
    {#each school.intervals as interval}
      <li>
        <a href={'#'}>
          {interval.title}
        </a>
      </li>
    {/each}
  </ul>
  <ul class="uk-switcher">
    {#each school.intervals as interval}
      <li uk-margin>
        <div hidden={!$School.features.useIntervals}>
          <Input
            required
            label={$_('schoolSetting.IntervalTitle')}
            bind:value={interval.title}
          />
          <Input
            required
            type="date"
            label={$_('schoolSetting.StartOn')}
            bind:value={interval.startOn}
          />
          <Input
            required
            type="date"
            label={$_('schoolSetting.EndOn')}
            bind:value={interval.endOn}
          />
          <Input
            required
            type="date"
            label={$_('schoolSetting.registrationStartsAt')}
            bind:value={interval.registrationStartsAt}
          />
          <Input
            required
            type="date"
            label={$_('schoolSetting.registrationEndsAt')}
            bind:value={interval.registrationEndsAt}
          />
          <hr />
        </div>
        <div uk-margin>
          <Lead>
            {$_('schoolSetting.ProgramCategories')}
          </Lead>

          <ul uk-accordion>
            {#each interval.programCategories || [] as category, i}
              <li>
                <a class="uk-accordion-title uk-flex" href={'#'}>
                  {category.title}
                </a>
                <div class="uk-accordion-content">
                  <div class="uk-flex">
                    <Input
                      placeholder={$_('schoolSetting.CategoryTitle')}
                      bind:value={category.title}
                    />

                    <ArrayNav
                      arrayLength={interval.programCategories.length}
                      {i}
                      remove={() => removeCategory(interval.id, i)}
                      moveUp={() => moveUpCategory(interval.id, i)}
                      moveDown={() => moveDownCategory(interval.id, i)}
                    />
                  </div>
                  <div class="uk-margin-medium-left uk-margin">
                    <div uk-grid>
                      <div class="uk-width-expand">
                        <ul class="uk-subnav">
                          <li>
                            <Checkbox bind:checked={category.useToGroupUser}
                              >{$_('schoolSetting.GroupUser')}</Checkbox
                            >
                          </li>
                        </ul>
                        <ul class="uk-subnav">
                          <li>
                            <Checkbox
                              bind:checked={category.useToGroupAssignment}
                              >{$_('schoolSetting.GroupAssignment')}</Checkbox
                            >
                          </li>
                          <li>
                            <Checkbox
                              bind:checked={category.useAttendanceCredit}
                              >{$_(
                                'schoolSetting.UseAttendanceCredit',
                              )}</Checkbox
                            >
                          </li>
                          <li>
                            <Checkbox bind:checked={category.useToGroupGrades}
                              >{$_('schoolSetting.GroupGrades')}</Checkbox
                            >
                          </li>
                          <li>
                            <Checkbox bind:checked={category.useChatroom}
                              >{$_('schoolSetting.UseChatroom')}</Checkbox
                            >
                          </li>
                          <li>
                            <Checkbox
                              bind:checked={category.allowDirechChatInAssignment}
                              >{$_(
                                'schoolSetting.AllowDirectChatInAssignment',
                              )}</Checkbox
                            >
                          </li>
                        </ul>
                      </div>
                    </div>

                    <ul class="uk-list uk-list-divider">
                      {#each category.groups as group, i}
                        <li>
                          <div class="uk-flex">
                            <Input type="text" bind:value={group.title} />
                            <ArrayNav
                              arrayLength={category.groups.length}
                              {i}
                              remove={() =>
                                removeGroup(interval.id, category.id, i)}
                              moveUp={() =>
                                moveUpGroup(interval.id, category.id, i)}
                              moveDown={() =>
                                moveDownGroup(interval.id, category.id, i)}
                            />
                          </div>
                          <div class="uk-margin-large-left uk-margin">
                            {#if group.children.length}
                              <div>
                                <ul class="uk-list uk-list-divider">
                                  {#each group.children as child, i}
                                    <li class="uk-flex">
                                      <Input
                                        type="text"
                                        bind:value={child.title}
                                      />
                                      <ArrayNav
                                        arrayLength={group.children.length}
                                        {i}
                                        remove={() =>
                                          removeChild(
                                            interval.id,
                                            category.id,
                                            group.id,
                                            i,
                                          )}
                                        moveUp={() =>
                                          moveUpChild(
                                            interval.id,
                                            category.id,
                                            group.id,
                                            i,
                                          )}
                                        moveDown={() =>
                                          moveDownChild(
                                            interval.id,
                                            category.id,
                                            group.id,
                                            i,
                                          )}
                                      />
                                    </li>
                                  {/each}
                                </ul>
                              </div>
                            {/if}
                            <Button
                              small
                              onclick={() => {
                                addChild(interval.id, category.id, group.id);
                              }}>{$_('schoolSetting.AddChild')}</Button
                            >
                          </div>
                        </li>
                      {/each}
                    </ul>
                    <Button
                      small
                      onclick={() => {
                        addGroup(interval.id, category.id);
                      }}>{$_('schoolSetting.AddGroup')}</Button
                    >
                  </div>
                </div>
              </li>
            {/each}
          </ul>
          <Button
            small
            onclick={() => {
              addCategory(interval.id);
            }}>{$_('schoolSetting.AddCategory')}</Button
          >
        </div>
        {#if $School.features.useIntervals}
          <div>
            <Button onclick={() => copyInterval(interval.id)}
              >{$_('schoolSetting.CopyThisInterval')}</Button
            >
            <Button style="danger" onclick={() => removeInterval(interval)}
              >{$_('schoolSetting.RemoveThisInterval')}</Button
            >
          </div>
        {/if}
        <div>
          <Button fullwidth onclick={() => saveInterval(interval.id)}
            >{$_('schoolSetting.SaveThisInterval')}</Button
          >
        </div>
      </li>
    {/each}
  </ul>
</div>
