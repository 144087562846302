const hideHands = obj => {
  obj.traverse(mesh => {
    if (mesh.name == 'RightHand' || mesh.name == 'LeftHand') {
      mesh.scale.x = 0;
      mesh.scale.y = 0;
      mesh.scale.z = 0;
      mesh.visible = false;
    }
  });
};
export default hideHands;
