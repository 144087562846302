const consoleMenu = [
  {
    slug: 'articles',
    component: 'Article',
    label: 'Articles',
    requiredPermission: 'school.article.canRead',
    icon: 'fad fa-newspaper fa-fw',
    requiredFeature: '',
    forRoleCategories: [],
  },
  // {
  //   slug: 'select-room',
  //   component: 'SelectRoom',
  //   label: 'Rooms',
  //   requiredPermission: '',
  //   icon: 'fad fa-school fa-fw',
  //   requiredFeature: 'useVirtualClassrooms',
  //   forRoleCategories: [],
  // },
  {
    slug: 'assignments',
    component: 'AssignmentDetails',
    label: 'Assignments',
    requiredPermission: '',
    icon: 'fad fa-book-open fa-fw',
    requiredFeature: 'useAssignments',
    forRoleCategories: ['admin', 'manager'],
  },
  {
    slug: 'subscribers',
    component: 'Subscribers',
    label: 'Subscribers',
    requiredPermission: '',
    icon: 'fad fa-users fa-fw',
    requiredFeature: 'useAssignments',
    forRoleCategories: ['admin', 'manager'],
  },
  {
    slug: 'my-reports',
    component: 'MyReports',
    label: 'MyReports',
    requiredPermission: '',
    icon: 'fad fa-book-open fa-fw',
    requiredFeature: 'useAssignments',
    forRoleCategories: ['subscriber'],
  },
  {
    slug: 'my-grades',
    component: 'MyGrades',
    label: 'MyGrades',
    requiredPermission: '',
    icon: 'fa-duotone fa-chart-column fa-fw',
    requiredFeature: 'useAssignments',
    forRoleCategories: ['subscriber'],
  },
  {
    slug: 'classmates',
    component: 'Classmates',
    label: 'Classmates',
    requiredPermission: '',
    icon: 'fa-duotone fa-users fa-fw',
    requiredFeature: 'useClassmates',
    forRoleCategories: ['subscriber'],
  },
  {
    slug: 'events',
    component: 'Events',
    label: 'Events',
    requiredPermission: 'school.event.canRead',
    icon: 'fad fa-calendar fa-fw',
    requiredFeature: 'useEvents',
    forRoleCategories: [],
  },
  {
    slug: 'files',
    component: 'Files',
    label: 'Files',
    requiredPermission: '',
    icon: 'fa-duotone fa-folder-open fa-fw',
    requiredFeature: '',
    forRoleCategories: [],
  },
  {
    slug: 'setting',
    component: 'SchoolSetting',
    label: 'GeneralSetting',
    requiredPermission: 'school.canEdit',
    icon: 'fad fa-database fa-fw',
    requiredFeature: '',
    forRoleCategories: ['admin', 'manager'],
  },
];
export default consoleMenu;
