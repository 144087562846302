const showAFRAME = el => {
  //el.setAttribute('animation','property:scale; from; 0 0 0; to: 1 1 1; dur:500; loop: 0;')
  el.setAttribute('visible', true);
  el.setAttribute('scale', el.getAttribute('original-scale'));
  //el.addEventListener('animationcomplete',onShowAnimationComplete)
};
const hideAFRAME = el => {
  if (el) {
    if (!el.getAttribute('original-scale')) {
      el.setAttribute(
        'original-scale',
        el.getAttribute('scale').x +
          ' ' +
          el.getAttribute('scale').y +
          ' ' +
          el.getAttribute('scale').z,
      );
    }
    el.setAttribute('visible', false);
    el.setAttribute('scale', '0 0 0');
  }
  //el.setAttribute('animation','property:scale; from; 1 1 1; to: 0 0 0; dur:500; loop: 0;')
  //el.addEventListener('animationcomplete',onHideAnimationComplete)
};

export { showAFRAME, hideAFRAME };
