import { get } from 'svelte/store';
import { _ } from 'svelte-i18n';
const timeZones = [
  {
    title: get(_)('timezone.Japan'),
    value: '-0900',
  },
  {
    title: get(_)('timezone.Germany'),
    value: '-0200',
  },
];
export default timeZones;
