import axios from 'axios';
import { saveAs } from 'file-saver';
import { default as JSZip } from 'jszip';
import { SERVER_URL } from '../../lib/config';
import { getCreateDownloadLink } from '../hooks/hooks';
export class FileStorageClient {
    constructor() {
        this.uploadUserFile = async (schoolId, userId, files) => {
            const requestUrl = new URL(`${SERVER_URL}api/v2/file-storage/user-data`);
            if (!files.length) {
                console.log(`No file passed`);
                return;
            }
            if (files.length > 1) {
                console.log(`Only pass one file at a time`);
                return;
            }
            const { response, key } = await this.processUserFileUpload(requestUrl, schoolId, userId, files[0]);
            return { response, key };
        };
        this.uploadSchoolFile = async (schoolId, folderId, files) => {
            const requestUrl = new URL(`${SERVER_URL}api/v2/file-storage/school-data`);
            if (!files.length) {
                console.log(`No file passed`);
                return;
            }
            if (files.length > 1) {
                console.log(`Only pass one file at a time`);
                return;
            }
            const { response, key } = await this.processSchoolFileUpload(requestUrl, schoolId, folderId, files[0]);
            return { response, key };
        };
        this.getDownloadUrl = async (key) => {
            const requestUrl = new URL(`${SERVER_URL}api/v2/file-storage/file`);
            requestUrl.searchParams.append('downloadKey', key);
            const response = await axios
                .get(requestUrl.toString())
                .then(res => res.data);
            return response;
        };
        this.downloadSingleFileLocally = async (key) => {
            const requestUrl = new URL(`${SERVER_URL}api/v2/file-storage/file`);
            requestUrl.searchParams.append('downloadKey', key);
            const response = await axios
                .get(requestUrl.toString())
                .then(res => res.data);
            getCreateDownloadLink(response);
        };
        this.downloadFilesToZipLocally = async (fileList, zipName) => {
            const jsZip = new JSZip();
            const files = fileList.map(fileData => this.downloadBlobToZip(fileData.fileName, fileData.url, jsZip));
            await Promise.all(files)
                .then(() => {
                jsZip
                    .generateAsync({ type: 'blob' })
                    .then(blob => saveAs(blob, zipName + '.zip'));
            })
                .catch((err) => err instanceof Error ? console.error(err.message) : console.error(err));
        };
        this.deleteFileByKey = async (key) => {
            const requestUrl = new URL(`${SERVER_URL}api/v2/file-storage/file`);
            requestUrl.searchParams.append('deleteKey', key);
            const response = await axios
                .delete(requestUrl.toString())
                .then(res => res.data);
            return response;
        };
        this.listUserFiles = async (schoolId, userId, getDownloadLinks) => {
            const requestUrl = new URL(`${SERVER_URL}api/v2/file-storage/user-data`);
            requestUrl.searchParams.append('schoolId', schoolId);
            requestUrl.searchParams.append('userId', userId);
            getDownloadLinks
                ? requestUrl.searchParams.append('downloadLinks', getDownloadLinks.toString())
                : requestUrl;
            const response = await axios
                .get(requestUrl.toString())
                .then(res => res.data);
            return response;
        };
        this.listFolderFiles = async (schoolId, folderId, getDownloadLinks) => {
            const requestUrl = new URL(`${SERVER_URL}api/v2/file-storage/user-data`);
            requestUrl.searchParams.append('schoolId', schoolId);
            requestUrl.searchParams.append('folderId', folderId);
            getDownloadLinks
                ? requestUrl.searchParams.append('downloadLinks', getDownloadLinks.toString())
                : requestUrl;
            const response = await axios
                .get(requestUrl.toString())
                .then(res => res.data);
            return response;
        };
        this.listByKeys = async (keys, getDownloadLinks) => {
            const requestUrl = new URL(`${SERVER_URL}api/v2/file-storage/files/bulk`);
            const response = await axios
                .post(requestUrl.toString(), {
                keys: keys,
                getDownloadLinks: getDownloadLinks,
            })
                .then(res => res.data);
            return response;
        };
        this.processUserFileUpload = async (requestUrl, schoolId, userId, file) => {
            const { url, fields, key } = await axios
                .post(requestUrl.toString(), {
                schoolId: schoolId,
                userId: userId,
                fileName: file.name,
                fileType: file.type,
            })
                .then(res => res.data);
            const response = await this.uploadToBucket(url, fields, file);
            return { response, key };
        };
        this.processSchoolFileUpload = async (requestUrl, schoolId, folderId, file) => {
            const { url, fields, key } = await axios
                .post(requestUrl.toString(), {
                schoolId: schoolId,
                folderId: folderId,
                fileName: file.name,
                fileType: file.type,
            })
                .then(res => res.data);
            const response = await this.uploadToBucket(url, fields, file);
            return { response, key };
        };
        this.uploadToBucket = async (url, fields, file) => {
            const formData = new FormData();
            Object.keys(fields).forEach(fieldKey => {
                formData.append(fieldKey, fields[fieldKey]);
            });
            formData.append('file', file);
            const response = await fetch(url, {
                method: 'POST',
                mode: 'no-cors',
                body: formData,
            });
            return response;
        };
        this.downloadBlobToZip = async (fileName, url, jsZip) => {
            return await axios
                .get(url, { responseType: 'blob' })
                .then(res => jsZip.file(fileName, res.data));
        };
    }
}
