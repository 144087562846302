import { resetDashboardGumball } from '../lib/resizeGumball.js';
import { dashboardGumballTarget, mousePos, RIG } from '../lib/store.js';
import { updateItem } from '../actions/itemController';

let target = null;
let rig;
let mouseDown = false;
let mouseDownPos = {};
let defaultOpacity = 0.3;
RIG.subscribe(el => {
  rig = el;
});

dashboardGumballTarget.subscribe(el => {
  target = el;
});

let thisPos;
mousePos.subscribe(pos => {
  thisPos = pos;
});

AFRAME.registerComponent('transform-dashboard-controls', {
  schema: {
    prop: {
      type: 'string',
    },
  },
  init: function () {
    let targetUid = this.el.id;
    let offsetter = document.getElementById('offsetter_' + targetUid);
    this.el.setAttribute('material', 'opacity:' + defaultOpacity);
    this.el.addEventListener('mouseenter', () => {
      this.el.setAttribute('material', {
        opacity: 1,
      });
    });
    this.el.addEventListener('mouseleave', () => {
      this.el.setAttribute('material', {
        opacity: defaultOpacity,
      });
    });
    this.el.addEventListener('mouseown', evt => {
      //let rig = document.getElementById(uid)
      mouseDown = true;
      //console.log(evt.detail)
      mouseDownPos = { x: thisPos.x, y: thisPos.y };
      console.log('mouse is down', rig);
    });
    this.el.addEventListener('mouseup', evt => {
      //let rig = document.getElementById(uid)
      //console.log(evt.detail)
      mouseDown = false;
      this.el.setAttribute('material', {
        opacity: defaultOpacity,
      });
      let gumball = document.getElementById('gumball');
      if (target) {
        resetDashboardGumball(target);
        gumball.setAttribute('position', target.getAttribute('position'));
      }
    });
  },
  tick: function () {
    //console.log(mouseDown)
    if (mouseDown) {
      console.log('mouse data', this);
      let state = target.getAttribute(this.data.prop);
      if (state == 'dashboard-x') {
        var distance = mouseDownPos.x - thisPos.x;
      }
      if (state == 'dashboard-y') {
        var distance = mouseDownPos.y - thisPos.y;
      }
      //console.log('camDistance',camDistance)
      //console.log(distance)
      if (distance > 10 || distance < -10) {
        if (state == 'dashboard-x') {
          var affectedValue = state + 0.008;
          offsetter.setAttribute('rotation', '0 ' + affectedValue + ' 0');
        }
        if (state == 'dashboard-y') {
          var affectedValue = state + 0.008;
          offsetter.setAttribute('position', '0 ' + affectedValue + ' 0');
          mouseDownPos = thisPos.x;
        }
        mouseDownPos = thisPos;
        updateItem(target);
      }
    }
  },
});
