import { RIG, SchoolRole, ConstructionMode } from './store';
import { showAFRAME, hideAFRAME } from './visibilityAFRAME';
let schoolRole;
SchoolRole.subscribe(obj => {
  schoolRole = obj;
});
let constructionMode;
ConstructionMode.subscribe(bool => {
  constructionMode = bool;
});
let rig;
RIG.subscribe(el => {
  rig = el;
});
const resetGumball = el => {
  let gumball = document.getElementById('gumball');
  let gizmo = document.getElementById('gizmo');
  let object = el.getObject3D('mesh');
  let clickCatcher = document.getElementById('clickCatcherOf_' + el.id);

  //test

  // compute bounding box
  let scale = 1;
  let scale2 = 1;
  if (typeof object != 'undefined') {
    var bbox = new THREE.Box3().setFromObject(object);
    var xDiff = bbox.max.x - bbox.min.x;
    var yDiff = bbox.max.y - bbox.min.y;
    var zDiff = bbox.max.z - bbox.min.z;
    if (xDiff >= yDiff && xDiff >= zDiff) {
      scale = xDiff * 1.1;
    } else if (yDiff >= xDiff && yDiff >= zDiff) {
      scale = yDiff * 1.1;
    } else if (zDiff >= xDiff && zDiff >= yDiff) {
      scale = zDiff * 1.1;
    }
    if (clickCatcher) {
      let model = clickCatcher.getObject3D('mesh');

      var bbox = new THREE.Box3().setFromObject(model);
      var xDiff = bbox.max.x - bbox.min.x;
      var yDiff = bbox.max.y - bbox.min.y;
      var zDiff = bbox.max.z - bbox.min.z;
      if (xDiff >= yDiff && xDiff >= zDiff) {
        scale2 = xDiff * 1.1;
      } else if (yDiff >= xDiff && yDiff >= zDiff) {
        scale2 = yDiff * 1.1;
      } else if (zDiff >= xDiff && zDiff >= yDiff) {
        scale2 = zDiff * 1.1;
      }
      scale2 = scale2;
      scale = Math.max(scale, scale2);
    }
  }
  //let's set the scale different way
  let distance = el.object3D.position.distanceTo(
    document.getElementById(schoolRole.id).object3D.position,
  );
  scale = Math.max(1, 0.6 * distance);

  gumball.setAttribute('scale', scale + ' ' + scale + ' ' + scale);
  gumball.setAttribute('position', el.getAttribute('position'));
  gumball.setAttribute('visible', true);
  if (constructionMode) {
    showAFRAME(gizmo);
  } else {
    hideAFRAME(gizmo);
  }
  //  document.getElementById('x-rotation-torus').object3D.rotation.x = el.object3D.rotation.x
  document.getElementById('x-rotation-torus').object3D.rotation.y =
    el.object3D.rotation.y + 1.57;
  document.getElementById('z-rotation-torus').object3D.rotation.y =
    el.object3D.rotation.y;
  document.getElementById('z-rotation-torus').object3D.rotation.x =
    el.object3D.rotation.x;
};
const resetDashboardGumball = el => {
  let gumball = document.getElementById('dashboardGumball');
  gumball.setAttribute(
    'position',
    el.parentNode.object3D.localToWorld(new THREE.Vector3()),
  );
  //gumball.setAttribute('scale', '0.2 0.2 0.2')
};
export { resetGumball, resetDashboardGumball };
