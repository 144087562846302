import axios from 'axios';
import { ENDPOINT } from '../config';

export const downloadFileAs = value => {
  console.log(value);
  let fileURL = 'public/' + value.url.replace(ENDPOINT + '/', '');
  console.log(fileURL);
  axios({
    url: ENDPOINT + '/api/download',
    responseType: 'blob',
    method: 'post',
    data: {
      fileURL: fileURL,
      filename: value.title,
    },
  }).then(res => {
    console.log(res);
    const url = window.URL.createObjectURL(res.data);
    const link = document.createElement('a');
    link.href = url;
    console.log(url);
    link.setAttribute('download', value.title);
    document.body.appendChild(link);
    link.click();
    //delete an a element afterword
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  });
};
export const downloadFilesAs = data => {
  axios({
    url: ENDPOINT + '/api/archive-files',
    responseType: 'blob',
    method: 'post',
    data: data,
  }).then(res => {
    console.log(res);
    const url = window.URL.createObjectURL(res.data);
    const link = document.createElement('a');
    link.href = url;
    console.log(url);
    link.setAttribute('download', data.outputFilename);
    document.body.appendChild(link);
    link.click();
    //delete an a element afterword
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  });
};
