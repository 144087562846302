<script lang="ts">
  export let fullwidth: boolean = false;
  export let color: string = null;
  export let style: string = 'default';
  export let small: boolean = false;
  export let rounded: boolean = false;
  export let onclick: () => void = () => {};
  export let disabled: boolean = false;
  export let id: string = null;
  export let hidden: boolean = false;
  export let customButtonUrl: string = null;
  export let margin: 'small' | 'medium' | 'large' | '' = '';
  if (color) {
    style = color;
  }
</script>

{#if customButtonUrl}
  <a on:click={onclick} href={'#'}>
    <img src={customButtonUrl} alt="" style="max-height:60px" />
  </a>
{:else}
  <button
    {id}
    {disabled}
    class="uk-button"
    class:uk-border-rounded={rounded}
    class:uk-width-expand={fullwidth}
    class:uk-button-primary={style == 'primary'}
    class:uk-button-default={style == 'default'}
    class:uk-button-secondary={style == 'secondary'}
    class:uk-button-danger={style == 'danger'}
    class:uk-button-text={style == 'text'}
    class:uk-button-link={style == 'link'}
    class:uk-button-small={style == 'small'}
    class:uk-button-disabled={disabled}
    class:uk-margin-small={margin === 'small'}
    class:uk-margin-medium={margin === 'medium'}
    class:uk-margin-large={margin === 'large'}
    on:click={onclick}
    {hidden}
  >
    <span>
      <slot />
    </span>
  </button>
{/if}
