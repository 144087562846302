import hideHands from '../lib/helpers/hideHands';

AFRAME.registerComponent('hide-hands', {
  schema: {
    enabled: { type: 'boolean' },
  },
  init: function () {
    this.el.addEventListener('model-loaded', () => {
      hideHands(this.el.object3D);
    });
  },
});
