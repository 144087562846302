<script>
  import { gumballTarget } from '../../lib/store.js';
  import GumballMenu from './GumballMenu.svelte';
  import ScalingTriangle from './ScalingTriangle.svelte';

  let target;
  gumballTarget.subscribe(el => {
    target = el;
  });
</script>

<a-entity id="gumball">
  <a-entity id="gumballMenu" look-at="#myCamera">
    <GumballMenu position="room" />
  </a-entity>
  <a-entity id="gizmo">
    <a-box
      color="red"
      width="1"
      class="rayTarget"
      height="0.05"
      depth="0.05"
      disable-look-while-dragging
      transform-controls="prop:position; axis:x; position:room"
      rotation="0 0 0"
      material="opacity:0.5;side:double;"
    />
    <a-box
      class="rayTarget"
      color="green"
      width="1"
      height="0.05"
      depth="0.05"
      disable-look-while-dragging
      transform-controls="prop:position; axis:y; position:room"
      rotation="0 0 90"
      material="opacity:0.5;side:double;"
    />
    <a-box
      class="rayTarget"
      color="blue"
      width="1"
      height="0.05"
      depth="0.05"
      disable-look-while-dragging
      transform-controls="prop:position; axis:z; position:room"
      rotation="0 90 0"
      material="opacity:0.5;side:double;"
    />
    <a-torus
      class="rayTarget"
      id="y-rotation-torus"
      color="green"
      segments-radial="4"
      radius-tubular="0.0125"
      rotation="90 0 0"
      disable-look-while-dragging
      transform-controls="prop:rotation; axis:y; position:room"
      radius="0.5"
      material="opacity:0.5;side:double;"
    />
    <a-torus
      class="rayTarget"
      id="x-rotation-torus"
      color="red"
      segments-raial="4"
      radius-tubular="0.0125"
      rotation="0 90 0"
      disable-look-while-dragging
      transform-controls="prop:rotation; axis:x; position:room"
      radius="0.5"
      material="opacity:0.5;side:double;"
    />

    <a-torus
      class="rayTarget"
      id="z-rotation-torus"
      color="blue"
      segments-radial="4"
      radius-tubular="0.0125"
      rotation="0 0 90"
      disable-look-while-dragging
      transform-controls="prop:rotation; axis:z; position:room"
      radius="0.5"
      material="opacity:0.5;side:double;"
    />
    <a-entity rotation="0 0 45">
      <ScalingTriangle />
    </a-entity>
    <a-entity rotation="0 0 -45">
      <ScalingTriangle />
    </a-entity>
    <a-entity rotation="0 0 135">
      <ScalingTriangle />
    </a-entity>

    <a-entity rotation="0 0 -135">
      <ScalingTriangle />
    </a-entity>
    <a-entity rotation="0 90 45">
      <ScalingTriangle />
    </a-entity>
    <a-entity rotation="0 90 -45">
      <ScalingTriangle />
    </a-entity>
    <a-entity rotation="0 90 135">
      <ScalingTriangle />
    </a-entity>
    <a-entity rotation="0 90 -135">
      <ScalingTriangle />
    </a-entity>
    <a-entity rotation="90 0 45">
      <ScalingTriangle />
    </a-entity>
    <a-entity rotation="90 0 135">
      <ScalingTriangle />
    </a-entity>
    <a-entity rotation="90 0 -45">
      <ScalingTriangle />
    </a-entity>
    <a-entity rotation="90 0 -135">
      <ScalingTriangle />
    </a-entity>
  </a-entity>
</a-entity>
