<script>
  import { _ } from 'svelte-i18n';

  import VerticalTab from './UI/VerticalTab.svelte';

  import { onMount } from 'svelte';
  import { navigate, Route, Router } from 'svelte-routing';
  import dialog from '../lib/helpers/dialog';
  import { ioGet } from '../lib/realtime';
  import { School, SchoolRole } from '../lib/store';
  import Accounts from './Accounts.svelte';
  import ArticleCategories from './blocks/ArticleCategories.svelte';
  import EventCategories from './blocks/EventCategories.svelte';
  import Intervals from './blocks/Intervals.svelte';
  import ProductCategories from './blocks/ProductCategories.svelte';
  import ProgramDetails from './blocks/ProgramDetails.svelte';
  import SchoolBasicSettings from './blocks/SchoolBasicSettings.svelte';
  import Chatrooms from './Chatrooms.svelte';
  import CustomFields from './CustomFields.svelte';
  import EditRoles from './EditRoles.svelte';
  import Migration from './Migration.svelte';
  import Button from './UI/Button.svelte';

  let school = null;
  School.subscribe(obj => {
    if (obj) {
      obj.roles = obj.roles.map(role => {
        if (typeof role.school.product == 'undefined') {
          role.school.product = {
            canRead: true,
            canCreate: false,
            canEdit: false,
            canDelete: false,
          };
        }
        return role;
      });
    }
    school = obj;
  });
  const saveSchoolRoles = async () => {
    console.log(school.roles);
    await ioGet('updateSchoolRoles', {
      schoolID: school._id,
      roles: school.roles,
    });
    dialog($_('Saved'));
    School.set(school);
  };
  let focus = location.href
    .split($School.slug + '/setting/')
    .pop()
    .split('/')
    .shift()
    .replace('#', '');
  onMount(() => {
    if (!focus) {
      nav('basic');
    }
  });
  const nav = slug => {
    navigate(`/${$School.slug}/setting/${slug}`);
    focus = slug;
  };
</script>

<svelte:head>
  <title>{$_('GeneralSetting')} | {$School?.title} - AVOS</title>
</svelte:head>
{#if $SchoolRole}
  <VerticalTab>
    <ul
      slot="menu"
      class="uk-tab uk-tab-left"
      uk-tab="connect:#schoolSettingContent; animation: uk-animation-fade"
    >
      <li class:uk-active={focus == 'basic'}>
        <a href={'#'} on:click={() => nav('basic')}>
          {$_('schoolSetting.BasicSettings')}
        </a>
      </li>
      <li class:uk-active={focus == 'article'}>
        <a href={'#'} on:click={() => nav('article')}>
          {$_('schoolSetting.ArticleCategories')}
        </a>
      </li>
      <li class:uk-active={focus == 'event'}>
        <a href={'#'} on:click={() => nav('event')}>
          {$_('schoolSetting.EventCategories')}
        </a>
      </li>
      <li class:uk-active={focus == 'product'}>
        <a href={'#'} on:click={() => nav('product')}>
          {$_('schoolSetting.ProductCategories')}
        </a>
      </li>
      <li class:uk-active={focus == 'intervals'}>
        <a href={'#'} on:click={() => nav('intervals')}>
          {$_('schoolSetting.Intervals')}
        </a>
      </li>
      <li class:uk-active={focus == 'program'}>
        <a href={'#'} class="uk-margin-left" on:click={() => nav('program')}>
          {$_('schoolSetting.ProgramDetail')}
        </a>
      </li>

      {#if $School.roles.find(role => role.id == $SchoolRole.roleID).canChangePermissions}
        <li class:uk-active={focus == 'roles'}>
          <a href={'#'} on:click={() => nav('roles')}>
            {$_('EditRoles')}
          </a>
        </li>
      {/if}
      {#if $School.roles.find(role => role.id == $SchoolRole.roleID).school.account?.canRead}
        <li class:uk-active={focus == 'accounts'}>
          <a href={'#'} on:click={() => nav('accounts/')}>
            {$_('Accounts')}
          </a>
        </li>
      {/if}
      {#if $School.roles.find(role => role.id == $SchoolRole.roleID).school.master?.canRead}
        <li class:uk-active={focus == 'customfields'}>
          <a href={'#'} on:click={() => nav('customfields/')}>
            {$_('CustomFields')}
          </a>
        </li>
      {/if}
      <li class:uk-active={focus == 'chatrooms'}>
        <a href={'#'} on:click={() => nav('chatrooms/')}>
          {$_('Chatrooms')}
        </a>
      </li>
      <li class:uk-active={focus == 'migration'}>
        <a href={'#'} on:click={() => nav('migration/')}>
          {$_('Migration')}
        </a>
      </li>
    </ul>
    <div slot="content">
      <Router>
        <Route path="basic" component={SchoolBasicSettings} />
        <Route path="article" component={ArticleCategories} />
        <Route path="event" component={EventCategories} />
        <Route path="product" component={ProductCategories} />
        <Route path="intervals" component={Intervals} />
        <Route path="program" component={ProgramDetails} />
        <Route path="roles">
          <EditRoles bind:school />
          <div class="uk-margin">
            <Button fullwidth onclick={saveSchoolRoles}>{$_('Save')}</Button>
          </div>
        </Route>
        <Route path="accounts/*" component={Accounts} />
        <Route path="customfields/*" component={CustomFields} />
        <Route path="chatrooms/*" component={Chatrooms} />
        <Route path="migration/*" component={Migration} />
      </Router>
    </div>
  </VerticalTab>
{/if}
