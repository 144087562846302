<script lang="ts">
  import versionHistory from '../../lib/versionHistory';
</script>

<ul class="uk-list uk-list-divider">
  {#each versionHistory as history}
    <li>
      <strong>
        {history.version}
      </strong>
      -
      {history.description}
      <span class="uk-text-meta">
        {history.date}
      </span>
      <ul class="uk-list uk-list-small uk-margin-left">
        {#each history.updates as update, i}
          <li>
            {update.description}
            <span class="uk-text-meta">
              {update.date}
            </span>
          </li>
        {/each}
      </ul>
    </li>
  {/each}
</ul>
