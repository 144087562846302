<script lang="ts">
  import { afterUpdate, getContext, onMount } from 'svelte';
  import { ioGet } from '../../lib/realtime';
  import { _ } from 'svelte-i18n';
  import PersonaBlock from './PersonaBlock.svelte';
  import CustomFieldThead from './CustomFieldComponents/CustomFieldThead.svelte';
  import CustomFieldTbody from './CustomFieldComponents/CustomFieldTbody.svelte';
  import Input from '../UI/Input.svelte';
  import copy from '../../lib/helpers/copy';
  import {
    School,
    TargetGrade,
    TargetGroup,
    TargetInterval,
  } from '../../lib/store';
  import Intervals from './Intervals.svelte';
  import areYouSure from '../../lib/helpers/areYouSure';
  import arraySwap from '../../lib/helpers/arraySwap';
  import dialog from '../../lib/helpers/dialog';
  import Button from '../UI/Button.svelte';
  import Console from '../Console.svelte';
  import { extname } from 'path';
  import { FileLoader, GreaterDepth } from 'three';
  import { saveIntervalCustomFields } from '../../lib/helpers/saveInterval';
  import CustomFieldInput from './CustomFieldComponents/CustomFieldInput.svelte';
  import Submission from './Submission.svelte';
  import { bind } from 'svelte/internal';
  import { UUID } from '../../lib/uuid';
  import {
    calculate,
    calculateSummaryFieldValue,
  } from '../../lib/helpers/calculateCustomFieldValues';
  import AttendanceCredit from './AttendanceCredit.svelte';
  import sortByKey from '../../lib/helpers/sortByKey';
  import { Settings } from 'luxon';
  import SummaryFieldHeading from './SummaryFieldHeading.svelte';
  import { Notification } from '../../actions/notification';
  export let group;
  export let interval;
  export let view;
  export let saveView;
  let school;
  let events = [];
  let thisInterval;
  let summaryFieldEditMode = false;

  School.subscribe(obj => {
    if (obj) {
      thisInterval = copy(obj.intervals.find(int => int.id == interval.id));
    }
    school = obj;
  });
  let subscribers = [];
  const saveCategory = async () => {
    let i = school.intervals.findIndex(i => i.id == interval.id);
    school.intervals[i] = interval;
    let res = ioGet('updateSchool', {
      _id: $School._id,
      data: school,
    });
    School.set(school);
    Notification($_('Saved'));
  };
  onMount(async () => {
    let res = await ioGet('getRegisteredSubscribers', {
      id: group.id,
    });
    res.map(async subscriber => {
      if (typeof subscriber.summaryFieldValues == 'undefined')
        subscriber.summaryFieldValues = {};
    });
    subscribers = res;
    events = await ioGet('getAttendanceCredits', {
      categoryLabel: view.categoryLabel,
      categoryLabels: view.categoryLabels,
      groupID: group.id,
    });
    events = sortByKey(events, {
      key: 'start',
      direction: 'asc',
    });
    calculateSummary();
  });
  const calculateSummary = () => {
    subscribers.forEach((subscriber, i) => {
      let attendances = [];
      events.forEach(schoolEvent => {
        schoolEvent.attendanceSettings.forEach(setting => {
          if (setting.roleID == subscriber.roleID) {
            setting.attendances.forEach(attendance => {
              if (attendance.schoolRoleID == subscriber.id) {
                if (typeof attendance.customFieldValues == 'undefined')
                  attendance.customFieldValues = {};
                attendances.push({
                  schoolEvent: schoolEvent,
                  attendance: attendance,
                });
              }
            });
          }
        });
      });
      if (typeof view.customFields == 'undefined') view.customFields = [];
      view.customFields = view.customFields.map(summaryField => {
        console.log(attendances);
        subscriber.summaryFieldValues[summaryField.id] =
          calculateSummaryFieldValue(
            summaryField,
            school.customFields.attendance,
            school.customFields.event,
            attendances.map(atte => atte.schoolEvent.customFieldValues),
            attendances.map(atte => atte.attendance),
          );
        return summaryField;
      });
      subscribers[i] = subscriber;
    });
  };
</script>

<div style="overflow-x:auto">
  <table
    class="uk-table uk-table-divider uk-table-justify uk-table-small uk-table-middle"
  >
    <thead>
      <tr>
        <th />
        {#each view.customFields || [] as summaryField, i}
          <th class="uk-text-center">
            <SummaryFieldHeading
              bind:summaryField
              bind:summaryFields={view.customFields}
              sourceCustomFields={school.customFields.attendance}
              save={async () => {
                await saveView();
                calculateSummary();
              }}
              {i}
            />
          </th>
        {/each}
        <th>
          <Button
            style="link"
            onclick={() => {
              if (typeof view.customFields == 'undefined')
                view.customFields = [];
              view.customFields = [
                ...view.customFields,
                {
                  id: UUID(),
                  target: '',
                  calculation: '',
                  value: '',
                },
              ];
              summaryFieldEditMode = true;
            }}
          >
            <i class="fa-duotone fa-plus" />
          </Button>
        </th>
      </tr>
    </thead>
    <tbody>
      {#each subscribers as subscriber}
        {#if subscriber}
          <tr>
            <td>
              <PersonaBlock
                withChat
                small
                withRole={false}
                schoolRoleID={subscriber.id}
              />
            </td>
            {#each view.customFields || [] as summaryField}
              <td class="uk-text-center">
                {#if subscriber.summaryFieldValues[summaryField.id]}
                  {subscriber.summaryFieldValues[summaryField.id].value}
                {/if}
              </td>
            {/each}
            <td>
              <div class="uk-flex">
                {#each events.filter( event => event.attendanceSettings.some(setting => setting.roleID == subscriber.roleID), ) as schoolEvent, i}
                  {@const attendance = schoolEvent.attendanceSettings
                    .find(setting => setting.roleID == subscriber.roleID)
                    .attendances.find(
                      attendance => attendance.schoolRoleID == subscriber.id,
                    )}
                  {#if attendance}
                    <div class="uk-margin-small-right">
                      {#key schoolEvent}
                        <AttendanceCredit
                          {attendance}
                          {subscriber}
                          {schoolEvent}
                        />
                      {/key}
                    </div>
                  {/if}
                {/each}
              </div>
            </td>
          </tr>
        {/if}
      {/each}
    </tbody>
  </table>
</div>
