<script lang="ts">
  import { _ } from 'svelte-i18n';
  import { ioGet } from '../../lib/realtime';
  import {
    School,
    TargetAssignment,
    TargetCategory,
    TargetGroup,
    TargetPersona,
    TargetProgramCategory,
    TargetSubmission,
  } from '../../lib/store';
  import CustomFieldDisplay from '../blocks/CustomFieldComponents/CustomFieldDisplay.svelte';
  import CustomFieldEditOrDisplay from '../blocks/CustomFieldComponents/CustomFieldEditOrDisplay.svelte';
  import GroupBlock from '../blocks/GroupBlock.svelte';
  import PersonaBlock from '../blocks/PersonaBlock.svelte';
  import Button from '../UI/Button.svelte';
  import MetaText from '../UI/MetaText.svelte';

  import { hideModal } from '../../actions/modalController';
  import { Notification } from '../../actions/notification';
  import { assignmentsStore } from '../../state/stores/assignmentsStore';
  import { submissionsStore } from '../../state/stores/submissionsStore';
  import Modal from '../UI/Modal.svelte';
  let assignment = null;
  let category = null;
  let group = null;
  let submission = null;

  TargetSubmission.subscribe(async obj => {
    if (!obj) return;
    submission = submissionsStore.get(obj._id);
    let persona = await ioGet('getPersona', {
      schoolRoleID: submission.subscriberID,
    });
    TargetPersona.set(persona);
  });
  submissionsStore.subscribe(array => {
    if (!$TargetSubmission) return;
    submission = submissionsStore.get($TargetSubmission._id);
  });
  TargetAssignment.subscribe(obj => {
    assignment = obj;
  });
  assignmentsStore.subscribe(array => {
    if (!$TargetAssignment) return;
    assignment = assignmentsStore.get($TargetAssignment._id);
  });
  TargetCategory.subscribe(obj => {
    category = obj;
  });
  let managers = [];
  TargetGroup.subscribe(async obj => {
    if (!obj) return;
    managers = await ioGet('listManagersForGroup', {
      groupID: $TargetGroup.id,
      schoolID: $School._id,
    });
  });

  const save = async () => {
    let res = await ioGet('updateSubmission', {
      _id: submission._id,
      data: submission,
    });
    //TargetSubmission.set(submission)
    //School.set($School); // reset the view
    hideModal('#submissionEdit');
    Notification($_('Saved'));
  };
</script>

<Modal id="submissionEdit" size="container" title={assignment?.title}>
  <div slot="body" uk-margin>
    {#if assignment && submission && category.customFields}
      <div
        uk-grid
        class="uk-grid-divider uk-grid-small uk-child-width-expand@s"
      >
        <div>
          <div uk-grid>
            <div class="uk-width-expand">
              <GroupBlock small bind:group groupID={$TargetGroup.id} />
            </div>
          </div>
          {#if managers.length}
            <div>
              <MetaText>
                {$_('RegisteredManagers')}
              </MetaText>
            </div>
            <ul class="uk-subnav">
              {#each managers as manager}
                <li>
                  {#key $TargetGroup}
                    <PersonaBlock
                      withRole={false}
                      withChat={$TargetProgramCategory?.allowDirechChatInAssignment}
                      small
                      schoolRoleID={manager.id}
                    />
                  {/key}
                </li>
              {/each}
            </ul>
          {/if}
          {#each category.customFields as field}
            <div class="uk-margin">
              <MetaText>{field.title}</MetaText>
              {#key submission.subscriberID}
                <CustomFieldDisplay
                  videoWatcher={submission.subscriberID}
                  values={assignment.customFieldValues}
                  fields={category.customFields}
                  {field}
                  value={assignment.customFieldValues[field.id]}
                />
              {/key}
            </div>
          {/each}
        </div>
        <div>
          <div uk-grid>
            <div class="uk-width-expand">
              {#key submission.subscriberID}
                <PersonaBlock
                  small
                  withChat
                  withRole={false}
                  schoolRoleID={submission.subscriberID}
                />
              {/key}
            </div>
            <div>
              {#each category.submissionCustomFields.filter(field => field.showOnList) as field}
                <CustomFieldEditOrDisplay
                  parentFields={category.customFields}
                  parentValues={assignment.customFieldValues}
                  withLabel={false}
                  fields={category.submissionCustomFields}
                  values={submission.customFieldValues}
                  {field}
                  bind:value={submission.customFieldValues[field.id]}
                />
              {/each}
            </div>
          </div>
          {#each category.submissionCustomFields.filter(field => !field.showOnList) as field}
            <div class="uk-margin">
              <CustomFieldEditOrDisplay
                parentFields={category.customFields}
                parentValues={assignment.customFieldValues}
                bind:values={submission.customFieldValues}
                fields={category.submissionCustomFields}
                {field}
                bind:value={submission.customFieldValues[field.id]}
              />
            </div>
          {/each}
          <Button fullwidth onclick={save}>{$_('Save')}</Button>
        </div>
      </div>
    {/if}
  </div>
</Modal>
