import { showModal } from '../actions/modalController';
import { listItems } from '../actions/roomItems';
import { getScreenData } from '../actions/roomVideoItems';
import { MyAvatar } from '../Classes/User';
import Loading from '../lib/helpers/loading';
import resetFocus from '../lib/helpers/resetFocus';
import toggleDashboard from '../lib/helpers/toggleDashboard';
import { io, ioGet } from '../lib/realtime';
import { SchoolRole, Room } from '../lib/store';
import { roomElementsStore } from '../state/stores/roomElementsStore';
let room = null;
Room.subscribe(obj => {
  room = obj;
});
let schoolRole = null;
SchoolRole.subscribe(obj => {
  if (obj) {
    schoolRole = obj;
  }
});
io.on('connect', () => {
  if (location.href.indexOf('scene') > 0 && schoolRole) {
    console.log('reconnected');
    let reconnection = document.getElementById(schoolRole.id) ? true : false;
    loadMe(reconnection);
    Loading.hide();
  }
});
export const loadMe = async (reconnection = false) => {
  let meRes = await ioGet('loadMe', {
    schoolID: schoolRole.schoolID,
    schoolRoleID: schoolRole.id,
    roomID: room._id,
    isGuest: schoolRole.isGuest,
    reconnection: reconnection,
  });
  resetFocus();
  roomElementsStore.add(schoolRole);

  console.log('loadMemeResponse', meRes);
  new MyAvatar(meRes);
};
export const loadItems = async () => {
  let itemsRes = await ioGet('listItems', {
    query: {
      roomID: room._id,
    },
  });
  if (itemsRes) {
    listItems(itemsRes);
  }
  document.getElementById('ascene-cover').setAttribute('hidden', true);
};
const whenDone = async () => {
  Loading.show();
  await loadMe();

  //RIG.set(document.getElementById(schoolRole.id))
  Loading.hide();
};
AFRAME.registerComponent('loading-while-model-load', {
  init: async function () {
    if (room.environment.url) {
      this.el.addEventListener('model-loaded', async function () {
        await whenDone();
      });
    } else {
      await whenDone();
    }
    toggleDashboard();
    showModal('#initialSettingModal');
    document
      .getElementById('initialSettingModal')
      .addEventListener('hide', loadItems, { once: true });
  },
});
