import { ioGet } from '../realtime';
import dialog from './dialog';
import { _ } from 'svelte-i18n';
import { get } from 'svelte/store';
const previewViewers = async testEvent => {
  let viewerRes = await ioGet('previewViewers', testEvent);
  console.log(viewerRes);
  if (viewerRes.viewerCount == 0) {
    dialog(get(_)('event.NoOneCanSeeThis'));
    return false;
  }
  if (viewerRes.ifICanSeeThis == 0) {
    dialog(get(_)('event.YouCantSeeThis'));
    return false;
  }
  return true;
};
export default previewViewers;
