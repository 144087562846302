<script>
  import { onMount } from 'svelte';
  import { ioGet } from '../lib/realtime';
  import Cookies from 'js-cookie';

  import EnterCard from './blocks/EnterCard.svelte';
  import Button from './UI/Button.svelte';
  import { _ } from 'svelte-i18n';
  import LanguageSwitch from './blocks/LanguageSwitch.svelte';
  import dialog from '../lib/helpers/dialog';

  export let token;
  let email = '';
  onMount(async () => {
    let userRes = await ioGet('findUserByToken', {
      token: token,
    });
    if (userRes) {
      email = userRes.email;
    } else {
      dialog($_('WrongURL'));
      location.href = '/';
    }
  });
  const activate = async () => {
    let res = await ioGet('activateUser', {
      token: token,
    });
    if (res.result == 'success') {
      //yay!
      Cookies.set('login', res.login);
      location.href = '/';
    } else {
      alert('something is not okay');
    }
  };
</script>

<svelte:head>
  <title>{$_('accounts.ActivateMyAccount')} - AVOS</title>
</svelte:head>
<EnterCard>
  <div slot="body">
    {$_('accounts.YourEmailIs')} : {email}
    <p>{$_('accounts.PleaseClickBelowToProceed')}</p>
    <Button fullwidth onclick={activate}
      >{$_('accounts.ActivateMyAccount')}</Button
    >
    <LanguageSwitch />
  </div>
</EnterCard>
