<script>
  import Input from '../UI/Input.svelte';

  import { _ } from 'svelte-i18n';
  import Cookies from 'js-cookie';
  import Checkbox from '../UI/Checkbox.svelte';
  import { School } from '../../lib/store';
  import { onMount } from 'svelte/internal';
  let school = {
    hourNames: [],
    programTitle: '',
  };
  import Lead from '../UI/Lead.svelte';
  import TinyTextarea from './TinyTextarea.svelte';
  import ViewersCheckboxes from './ViewersCheckboxes.svelte';
  import ClassroomSelect from './ClassroomSelect.svelte';
  import EntityThumbnail from './EntityThumbnail.svelte';
  import CustomFieldEditOrDisplay from './CustomFieldComponents/CustomFieldEditOrDisplay.svelte';
  import { defineIfUndefined } from '../../lib/helpers/defineIfUndefined';
  export let presetEditMode = false;
  export let article;
  onMount(() => {
    console.log(article);
    article.customFieldValues = defineIfUndefined(
      article.customFieldValues,
      {},
    );
  });
</script>

{#if article}
  <div uk-margin class="uk-margin">
    <div uk-grid>
      {#if !presetEditMode}
        <div>
          <Input
            label={$_('article.ArticleCategory')}
            bind:value={article.categoryLabel}
          />
        </div>
      {/if}

      <div>
        <Input
          label={$_('event.LabelColor')}
          type="color"
          bind:value={article.backgroundColor}
        />
      </div>
    </div>
    <hr />
    <div uk-grid>
      <div>
        <EntityThumbnail bind:entity={article} />
      </div>
      <div>
        <Input
          label={$_('article.ArticleTitle')}
          type="text"
          bind:value={article.title}
        />
      </div>
    </div>
    <hr />
    <div uk-grid>
      {#if !presetEditMode}
        <div>
          <Lead>{$_('event.Schedule')}</Lead>
          <div>
            <Input
              label={$_('article.PublishDate')}
              type="date"
              bind:value={article.startDate}
            />
            <Input
              label={$_('article.PublishTime')}
              type="time"
              bind:value={article.publishTime}
            />
          </div>
        </div>
      {/if}
      <!-- <div>
        <Lead>{$_('event.Viewers')}</Lead>
        <ViewersCheckboxes bind:entity={article} componentCategory="Article" />
      </div> -->
      <div>
        <Lead>{$_('event.VirtualClassroom')}</Lead>
        <ClassroomSelect bind:value={article.roomID} />
      </div>
    </div>

    <TinyTextarea bind:value={article.description} />
    {#each $School.customFields?.article || [] as field}
      {#if article.customFieldValues}
        <CustomFieldEditOrDisplay
          {field}
          bind:value={article.customFieldValues[field.id]}
        />
      {/if}
    {/each}
  </div>
  <hr />
{/if}
