<script>
  import { muteAudio, normalAudio } from '../../actions/videoController.js';
  import { ioGet } from '../../lib/realtime.js';
  import { _ } from 'svelte-i18n';
  import {
    megaphoneActive,
    micOnMute,
    AudioDeviceID,
    myVolumeRaw,
    noiseThreshold,
    captureScreenActive,
    webcamActive,
    AudioInputs,
    IsMutable,
  } from '../../lib/store.js';
  import Button from '../UI/Button.svelte';
  import BottomMenuNav from './BottomMenuNav.svelte';
  import changeAudioDevice from '../../lib/helpers/changeAudioDevice.js';
  import Icon from '../UI/Icon.svelte';
  import MicIconWithLevel from './MicIconWithLevel.svelte';
  let ifWebcamActive;
  webcamActive.subscribe(bool => {
    ifWebcamActive = bool;
  });
  let ifCaptureScreenActive;
  captureScreenActive.subscribe(val => {
    ifCaptureScreenActive = val;
  });

  const megaphoneAudio = () => {
    normalAudio();
    megaphoneActive.set(true);
  };
  let isMutable;
  IsMutable.subscribe(value => {
    isMutable = value;
  });

  let megaphone;
  megaphoneActive.subscribe(bool => {
    megaphone = bool;
  });
  let muted;
  micOnMute.subscribe(bool => {
    muted = bool;
  });
  let audioInputs = [];
  navigator.mediaDevices.enumerateDevices().then(devices => {
    audioInputs = devices.filter(device => device.kind === 'audioinput');
    const audioInput = devices.find(device => device.kind === 'audioinput');
    audioInputs = audioInputs.map(input => {
      input.inUse = input.deviceId == audioDeviceID;
      return input;
    });
    AudioInputs.set(audioInputs);
  });
  let audioDeviceID = '';
  AudioDeviceID.subscribe(id => {
    audioDeviceID = id;
  });
  const increaseThreshold = () => {
    threshold += 1;
    noiseThreshold.set(threshold);
  };
  const decreaseThreshold = () => {
    if (threshold > 0) {
      threshold -= 1;
      noiseThreshold.set(threshold);
    }
  };
  let volume;
  myVolumeRaw.subscribe(val => {
    volume = val;
  });
  let threshold;
  noiseThreshold.subscribe(val => {
    threshold = val;
  });
</script>

<a href={'#'}>
  {#if muted}
    <BottomMenuNav>
      <span slot="icon" style="color:red">
        <Icon icon="microphone-slash" size="2x" />
      </span>
      <div slot="subtitle">
        {$_('menu.OnMute')}
      </div>
    </BottomMenuNav>
  {:else if !$IsMutable}
    <BottomMenuNav>
      <span slot="icon" style="color:yellow">
        <i class="fa-duotone fa-megaphone fa-2x" />
      </span>
      <div slot="subtitle">
        {$_('menu.Megaphone')}
      </div>
    </BottomMenuNav>
  {:else}
    <div class="uk-text-center">
      <MicIconWithLevel {volume} size="2x" />
      <div class="uk-navbar-subtitle uk-visible@s">
        {$_('menu.Audio')}
      </div>
    </div>
  {/if}
</a>
<div
  class="uk-navbar-dropdown dark-translucent-background uk-light uk-border-rounded uk-width-medium"
  uk-dropdown="pos: top-center;mode:click"
  id="bottomMenuAudioDropdown"
>
  <ul class="uk-nav uk-navbar-dropdown-nav ">
    <li class="uk-nav-heading">
      {$_('menu.MouthOpenThreshold')}
    </li>
    <li>
      <div uk-grid class="uk-grid-small">
        <div>
          <Button style="text" onclick={decreaseThreshold}
            ><i class="fa-solid fa-minus" /></Button
          >
        </div>
        <div class="uk-width-expand uk-flex uk-flex-middle">
          <div class="uk-position-relative uk-width-expand">
            <div
              id="myVoumeMeter"
              style="
                            background-color:lightgreen;
                            height:6px;
                            width:{volume * 10}%;"
              class="uk-position-top"
            />
            <div
              id="myThreshold"
              style="
                            background-color:yellow;
                            height:6px;
                            opacity:0.5;
                            width:{threshold * 10}%;"
              class="uk-position-top"
            />
          </div>
        </div>
        <div>
          <Button style="text" onclick={increaseThreshold}
            ><i class="fa-solid fa-plus" /></Button
          >
        </div>
      </div>
    </li>
    <li>
      <a
        id="is-mutable"
        href={'#'}
        on:click={() => {
          IsMutable.update(current => (current = !current));
        }}
      >
        <Icon icon="megaphone" size="2x" />
        {$_('menu.Megaphone')}
      </a>
    </li>
    <li>
      <a
        href={'#'}
        on:click={() => {
          normalAudio();
          UIkit.dropdown('#bottomMenuAudioDropdown').hide(0);
        }}
      >
        <Icon icon="microphone" size="2x" />
        {$_('menu.AudioON')}
      </a>
    </li>
    <li>
      <a
        href={'#'}
        on:click={() => {
          muteAudio();
          UIkit.dropdown('#bottomMenuAudioDropdown').hide(0);
        }}
      >
        <Icon icon="microphone-slash" size="2x" />

        {$_('menu.Mute')}
      </a>
    </li>

    <li hidden>
      <a
        href={'#'}
        on:click={() => {
          megaphoneAudio();
          UIkit.dropdown('#bottomMenuAudioDropdown').hide(0);
        }}
      >
        <i class="fa-duotone fa-megaphone fa-2x fa-fw" />
        {$_('menu.Megaphone')}
      </a>
    </li>
    {#if audioInputs.length}
      <li class="uk-nav-divider" />
      <Button
        fullwidth
        onclick={() => {
          changeAudioDevice(audioInputs[0].deviceId);
        }}>{$_('menu.ResetAudio')}</Button
      >
      <li class="uk-nav-divider" />
      <li class="uk-bav-heading">{$_('menu.AudioDevices')}</li>
      {#each audioInputs as input}
        <li>
          <a href={'#'} on:click={() => changeAudioDevice(input.deviceId)}>
            <span class="uk-margin-small-left">
              {#if input.inUse}
                <span>
                  <i class="fa-solid fa-check fa-lg" />
                </span>
              {/if}
              {input.label}
            </span>
          </a>
        </li>
      {/each}
    {/if}
  </ul>
</div>
