<script>
  import { HOST } from '../../lib/config';
  import dialog from '../../lib/helpers/dialog';
  import validateSlug from '../../lib/helpers/validateSlug';
  import { _ } from 'svelte-i18n';
  export let label;
  export let value;
  export let beforeURL;
  const validate = async () => {
    let res = await validateSlug(value);
    console.log(res);
    if (res.status == 'success') {
      //okay
      document.getElementById('SlugInputOK').removeAttribute('hidden');
      document.getElementById('SlugInputNG').setAttribute('hidden', true);
      return true;
    } else {
      dialog($_('slug.' + res.message));
      document.getElementById('SlugInputOK').setAttribute('hidden', true);
      document.getElementById('SlugInputNG').removeAttribute('hidden');
      return false;
    }
  };
</script>

<label>
  {label}
</label>
<div class="uk-grid-small" uk-grid>
  <div class="uk-flex uk-flex-middle">
    {beforeURL}/
  </div>
  <div>
    <input
      required
      class="uk-input"
      type="text"
      bind:value
      on:change={() => validate()}
    />
  </div>
  <div class="uk-flex uk-flex-middle">
    <span class="uk-text-success" id="SlugInputOK" hidden
      ><i class="fa-duotone fa-check" /></span
    >
    <span class="uk-text-danger" id="SlugInputNG" hidden
      ><i class="fa-duotone fa-times" /></span
    >
  </div>
</div>
<div class="uk-text-meta">
  {$_('SlugCanOnlyContainLowercaseAlphabetAndNumbers')}
</div>
