<script>
  import { HOST, IS_TEST } from '../../lib/config';

  import areYouSure from '../../lib/helpers/areYouSure';

  import { ioGet } from '../../lib/realtime';
  import Cookies from 'js-cookie';
  import { School, SchoolRole } from '../../lib/store';

  import Button from '../UI/Button.svelte';
  import Input from '../UI/Input.svelte';
  import SlugInput from './SlugInput.svelte';
  import { _ } from 'svelte-i18n';
  import Switch from '../UI/Switch.svelte';
  import validateSlug from '../../lib/helpers/validateSlug';
  import dialog from '../../lib/helpers/dialog';
  import EntityThumbnail from './EntityThumbnail.svelte';
  import copy from '../../lib/helpers/copy';
  import Lead from '../UI/Lead.svelte';
  import HourNameSelect from './HourNameSelect.svelte';
  import ArrayNav from './ArrayNav.svelte';
  import arraySwap from '../../lib/helpers/arraySwap';
  import AssignmentCategories from './AssignmentCategories.svelte';
  import { UUID } from '../../lib/uuid';
  import { AssignmentCategoryDefault } from '../../lib/interfaces/AssignmentCategory';
  import { defineIfUndefined } from '../../lib/helpers/defineIfUndefined';
  import { SubscriptionPlans } from '../../lib/interfaces/SubscriptionPlan';
  import features from '../../lib/interfaces/Features';
  import Modal from '../UI/Modal.svelte';
  import SchoolPages from '../SchoolPages.svelte';
  import ChangeSubscriptionOrEmail from './changeSubscriptionOrEmail.svelte';
  import Div from '../UI/Div.svelte';
  import consoleMenu from '../../lib/presets/consoleMenu';
  import { capitalize } from '../../lib/helpers/capitalize';
  import PriceTables from './PriceTables.svelte';
  import MetaText from '../UI/MetaText.svelte';
  import Scene from '../Scene.svelte';
  import LinkToDocumentation from './LinkToDocumentation.svelte';
  let school = null;
  School.subscribe(obj => {
    if (obj) {
      obj.features.useVirtualClassrooms = defineIfUndefined(
        obj.features.useVirtualClassrooms,
        true,
      );
      obj.customLanguages = defineIfUndefined(obj.customLanguages, {});
      obj.consoleLabels = defineIfUndefined(obj.consoleLabels, {});
      obj.subscriptionPlan = defineIfUndefined(
        obj.subscriptionPlan,
        SubscriptionPlans[0],
      );
      if (typeof obj.features == 'undefined') obj.features = {};
      if (typeof obj.assignmentCategories == 'undefined')
        obj.assignmentCategories = [
          {
            ...AssignmentCategoryDefault,
            id: UUID(),
          },
        ];
      school = copy(obj);
    }
  });
  const deleteSchool = async () => {
    areYouSure($School.title, async () => {
      let res = await ioGet('deleteSchool', {
        _id: $School._id,
      });
      location.href = HOST;
    });
  };
  const validate = async () => {
    let res = await validateSlug(school.slug);
    console.log(res);
    return res;
    if (res.status == 'success') {
      return true;
    } else {
      return false;
    }
  };

  const save = async () => {
    let res = await validate();
    if (res.status == 'success') {
      await ioGet('updateSchool', {
        _id: school._id,
        data: school,
      });
      dialog($_('Saved'));
      location.reload();
    } else {
      dialog($_(`slug.${res.message}`));
    }
  };
  const hourNameMoveUp = i => {
    school.hourNames = arraySwap(school.hourNames, i, i - 1);
  };
  const hourNameMoveDown = i => {
    school.hourNames = arraySwap(school.hourNames, i, i + 1);
  };
  const hourNameRemove = i => {
    school.hourNames.splice(i, 1);
    school.hourNames = school.hourNames;
  };
  const hourNameAdd = () => {
    school.hourNames = [
      ...school.hourNames,
      {
        title: '',
        startAt: '',
        endAt: '',
      },
    ];
  };
  const updateBackgroundColor = () => {};
  const addAssignmentCategory = () => {
    if (typeof school.assignmentCategories == 'undefined') {
      school.assignmentCategories = [];
    }
    school.assignmentCategories = [
      ...school.assignmentCategories,
      {
        id: UUID(),
        title: '',
        customFields: [],
      },
    ];
  };
  const setSubscriptionPlan = plan => {
    school.subscriptionPlan = plan;
    save();
  };
</script>

{#if school}
  <div uk-margin>
    <div>
      <div uk-grid class="uk-grid-small">
        <div>
          {#if $School.roles.find(role => role.id == $SchoolRole.roleID).school.canEdit}
            <EntityThumbnail bind:entity={school} />
          {:else if school.iconImageURL}
            <img
              alt="thumbnail"
              src={school.iconImageURL}
              class="uk-border-rounded"
              style="height:120px;width:120px"
            />
          {/if}
        </div>
        <div>
          {#if $School.roles.find(role => role.id == $SchoolRole.roleID).school.canEdit}
            <Input
              required
              label={$_('schoolSetting.SchoolName')}
              bind:value={school.title}
            />
          {:else}
            <div class="uk-text-meta">{$_('schoolSetting.SchoolName')}</div>

            {school.title}
          {/if}
        </div>
      </div>
    </div>
    <Div>
      <Lead>
        {$_('schoolSetting.BackgroundColors')}
      </Lead>
      <LinkToDocumentation
        url="https://www.notion.so/avosdocs/General-Setting-Background-color-of-the-site-colorblindness-0e5f47591ce745c696c2d5929ac8995e"
        label={$_('Help')}
        position="right"
      />
      <div uk-grid class="uk-grid-small">
        {#each ['admin', 'manager', 'subscriber', 'guest'] as role}
          <div>
            {$_('schoolSetting.' + capitalize(role))}
            {#if role == 'admin'}
              <Input
                type="color"
                label={$_('schoolSetting.BackgroundColorFrame')}
                bind:value={$School.backgroundColorFrame}
                on:change={updateBackgroundColor}
              />
              <Input
                type="color"
                label={$_('schoolSetting.BackgroundColorContent')}
                bind:value={$School.backgroundColorContent}
                on:change={updateBackgroundColor}
              />
            {:else}
              <Input
                type="color"
                label={$_('schoolSetting.BackgroundColorFrame')}
                bind:value={$School['backgroundColorFrame' + capitalize(role)]}
                on:change={updateBackgroundColor}
              />
              <Input
                type="color"
                label={$_('schoolSetting.BackgroundColorContent')}
                bind:value={$School[
                  'backgroundColorContent' + capitalize(role)
                ]}
                on:change={updateBackgroundColor}
              />
            {/if}
            <div class="uk-flex uk-flex-middle">
              <div>
                {$_('schoolSetting.FontIsDark')}
              </div>
              <div class="uk-margin-small-left">
                <Switch
                  bind:value={$School['fontIsDarkFor' + capitalize(role)]}
                />
              </div>
            </div>
          </div>
        {/each}
      </div>
    </Div>
    <hr />
    <div>
      <div class="uk-margin">
        <Lead>{$_('schoolSetting.CurrentPlan')}</Lead>
        <div class="uk-label" style="background-color:green">
          <Lead>
            {$School.subscriptionPlan?.title}
          </Lead>
        </div>
        <div>
          <Button
            style="link"
            onclick={() => {
              UIkit.modal('#changeSubscriptionPlan').show();
            }}
          >
            {$_('schoolSetting.ChangeSubscriptionPlan')}
          </Button>
        </div>
        <Modal id="changeSubscriptionPlan" size="container">
          <div slot="body">
            <PriceTables {setSubscriptionPlan} />
          </div>
        </Modal>
      </div>
      {$_('schoolSetting.Features')}
      <ul class="uk-list uk-list-small uk-column-1-2@s">
        {#each features as feature}
          {#if feature.requiredPlanGrade <= school.subscriptionPlan.id}
            <li>
              <div uk-grid class="uk-width-expand">
                <div class="uk-width-expand">
                  {$_(`feature.${feature.title}`)}
                </div>
                <div>
                  <Switch
                    bind:value={school.features[feature.title]}
                    disabled={!$School.roles.find(
                      role => role.id == $SchoolRole.roleID,
                    ).school.canEdit}
                  />
                </div>
              </div>
            </li>
          {:else}
            <li hidden>
              <Switch
                bind:value={school.features[feature.title]}
                disabled={!$School.roles.find(
                  role => role.id == $SchoolRole.roleID,
                ).school.canEdit}
              />
            </li>
          {/if}
        {/each}
      </ul>
    </div>
    {#if school.features.useAssignments}
      <hr />
      <div>
        <Lead>{$_('AssignmentCategories')}</Lead>
        <AssignmentCategories
          bind:assignmentCategories={school.assignmentCategories}
        />
        <Button small onclick={addAssignmentCategory}
          >{$_('schoolSetting.AddAssignmentCategory')}</Button
        >
      </div>
    {/if}
    {#if school.features.useHourNames}
      <hr />
      <LinkToDocumentation
        url="https://www.notion.so/General-Setting-Timetable-settings-d8c7c774fc9d436581fd1d0f49376005"
        label={$_('Help')}
        position="right"
      />

      <div>
        <Lead>{$_('HourNames')}</Lead>
        <ul class="uk-list uk-list-divider">
          {#each school.hourNames as hourName, i}
            <li>
              <div class="uk-grid-small uk-child-width-1-4" uk-grid>
                <div>
                  <Input
                    medium
                    label={$_('HourName')}
                    bind:value={hourName.title}
                    type="text"
                  />
                </div>
                <div>
                  <Input
                    medium
                    label={$_('event.StartTime')}
                    bind:value={hourName.startAt}
                    type="time"
                  />
                </div>
                <div>
                  <Input
                    medium
                    label={$_('event.EndTime')}
                    bind:value={hourName.endAt}
                    type="time"
                  />
                </div>
                <div>
                  <ArrayNav
                    arrayLength={school.hourNames.length}
                    {i}
                    moveUp={hourNameMoveUp}
                    moveDown={hourNameMoveDown}
                    remove={hourNameRemove}
                  />
                </div>
              </div>
            </li>
          {/each}
        </ul>
        <Button small onclick={hourNameAdd}
          >{$_('schoolSetting.AddHourName')}</Button
        >
      </div>
    {/if}
    <hr />
    <Div>
      <Lead>
        {$_('schoolSetting.ConsoleMenuTitles')}
      </Lead>
      <table
        class="uk-table uk-table-justify uk-table-small uk-table-middle uk-table-divider"
      >
        {#each consoleMenu as menu}
          <tr>
            <td>
              <div>
                {$_(menu.label)}
              </div>
              <ul class="uk-subnav uk-margin-remove-top">
                {#if menu.forRoleCategories.length}
                  <li>
                    <MetaText>
                      {$_('schoolSetting.TargetRole')}
                    </MetaText>
                  </li>
                {/if}
                {#each menu.forRoleCategories as category}
                  <li>
                    <MetaText>
                      {$_('customFields.' + capitalize(category))}
                    </MetaText>
                  </li>
                {/each}
              </ul>
            </td>
            <td>
              <Input bind:value={school.consoleLabels[menu.label]} />
            </td>
          </tr>
        {/each}
      </table>
    </Div>
    <hr />
    <Div>
      <Lead>{$_('schoolSetting.CustomLanguages')}</Lead>
      <table
        class="uk-table uk-table-justify uk-table-small uk-table-middle uk-table-divider"
      >
        {#each ['Submissions', 'Manage'] as menu}
          <tr>
            <td>
              <div>
                {$_(menu)}
              </div>
            </td>
            <td>
              <Input bind:value={$School.customLanguages[menu]} />
            </td>
          </tr>
        {/each}
      </table>
    </Div>
    <hr />
    <div>
      {#if $School.roles.find(role => role.id == $SchoolRole.roleID).school.canEdit}
        <div class="uk-child-width-expand@s" uk-grid>
          <div>
            <Lead>
              {$_('schoolSetting.SchoolURL')}
            </Lead>
          </div>
          <div>
            <LinkToDocumentation
              url="https://www.notion.so/avosdocs/General-Setting-Specify-slug-URL-slug-URL-18b0a8e635544fe2916887f51da961e3"
              label={$_('Help')}
              position="right"
            />
          </div>
        </div>
        <SlugInput beforeURL={HOST} bind:value={school.slug} />
      {:else}
        <div class="uk-text-meta">{$_('schoolSetting.SchoolURL')}</div>

        {HOST}/{school.slug}
      {/if}
    </div>
    <hr />
    {#if $School.roles.find(role => role.id == $SchoolRole.roleID).school.canEdit}
      <div>
        <Button fullwidth onclick={save}>{$_('Save')}</Button>
      </div>
      {#if $School.roles.find(role => role.id == $SchoolRole.roleID).school.canDelete}
        <div>
          <Button style="danger" onclick={deleteSchool}
            ><i class="fa-duotone fa-trash" /> {$_('Delete')}</Button
          >
        </div>
      {/if}
    {/if}
  </div>
{/if}
