import { writable } from 'svelte/store';

const formsReducer = writable([]);

const formsStore = {
  subscribe: formsReducer.subscribe,
  addForm: form => {
    const newForm = {
      ...form,
    };
    formsReducer.update(forms => {
      return [...forms, newForm];
    });
  },
};

export default formsStore;
