<script>
  import { _ } from 'svelte-i18n';
  import Button from '../UI/Button.svelte';
  import Modal from '../UI/Modal.svelte';
</script>

<Modal stack withFooter id="confirmModal">
  <div slot="body">
    <div id="confirmMessage" />
  </div>
  <div slot="footer">
    <Button id="confirmCancel">{$_('Cancel')}</Button>
    <Button id="confirmOK">{$_('OK')}</Button>
  </div>
</Modal>
