<script>
  import { Interval } from 'luxon';
  import { onMount } from 'svelte';
  import { _ } from 'svelte-i18n';
  import { navigate, Route, Router } from 'svelte-routing';
  import { ioGet } from '../lib/realtime';
  import { School, SchoolRole } from '../lib/store';
  import EventAttendanceList from './blocks/EventAttendanceList.svelte';
  import IntervalsTab from './blocks/IntervalsTab.svelte';
  import MyReportTable from './blocks/MyReportTable.svelte';
  import NavList from './blocks/NavList.svelte';
  import SubscriberAssignmentTable from './blocks/SubscriberAssignmentTable.svelte';
  import SubscriberAttendanceCreditTable from './blocks/SubscriberAttendanceCreditTable.svelte';
  import SubscriberGrades from './blocks/SubscriberGrades.svelte';
  import SubscriberTable from './blocks/SubscriberTable.svelte';
  import ViewSettingDropdown from './blocks/ViewSettingDropdown.svelte';

  import VerticalTab from './UI/VerticalTab.svelte';
  let subscribers = [];
  let breadcrumb = '/' + $School.slug + '/my-grades/';
  let focus = location.href
    .split('my-grades/')
    .pop()
    .split('/')
    .shift()
    .replace('#', '');
  let focus2 = location.href
    .split('my-grades/')
    .pop()
    .split('/')[1]
    ?.replace('#', '');
  const nav = slug => {
    navigate('/' + $School.slug + '/my-grades/' + slug + '/');
    focus = slug;
  };
  onMount(() => {
    if (!focus) {
      nav($School.intervals[0].id);
    }
  });
</script>

<ul class="uk-tab" hidden={!$School.features.useIntervals}>
  {#each $School.intervals as interval}
    <NavList bind:focus slug={interval.id} {breadcrumb}>
      {interval.title}
    </NavList>
  {/each}
</ul>
<div>
  <Router>
    {#each $School.intervals as interval}
      <Route path={interval.id + '/*'}>
        <SubscriberGrades
          withExams={false}
          {interval}
          subscriber={$SchoolRole}
        />
      </Route>
    {/each}
  </Router>
</div>
