<script lang="ts">
  import { onMount } from 'svelte';
  import { _ } from 'svelte-i18n';
  import { navigate } from 'svelte-routing';
  import { ioFetch, ioGet } from '../lib/realtime';
  import { School, SchoolRole, TargetArticle, User } from '../lib/store';

  import SchoolStats from './blocks/SchoolStats.svelte';
  import EnterCard from './blocks/EnterCard.svelte';
  import Article from './UI/Article.svelte';
  import Button from './UI/Button.svelte';
  import Lead from './UI/Lead.svelte';
  import Loading from '../lib/helpers/loading';
  import enterSchoolAs from '../lib/helpers/enterSchoolAs';
  import Articles from './Articles.svelte';
  import TopNav from './blocks/TopNav.svelte';
  import Section from './UI/Section.svelte';
  import PersonaBlock from './blocks/PersonaBlock.svelte';
  import Icon from './UI/Icon.svelte';
  import ArticlesList from './blocks/ArticlesList.svelte';
  import type { ArticleInterface } from '../lib/interfaces/Articles';
  import Cookies from 'js-cookie';
  import {
    HOST,
    INFORMATION_CATEGORY_TITLES,
    MASTER_SCHOOL_ID,
    UPDATE_CATEGORY_TITLES,
  } from '../lib/config';
  import CategoryLabel from './blocks/CategoryLabel.svelte';
  import Modal from './UI/Modal.svelte';
  import { previewMath } from '../lib/helpers/decodeMath';
  import { showModal } from '../actions/modalController';
  import ArticleDetail from './blocks/ArticleDetail.svelte';
  import TwitterEmbed from './blocks/TwitterEmbed.svelte';
  import Div from './UI/Div.svelte';
  import { get } from 'svelte/store';
  import RoomButtonForGuest from './blocks/RoomButtonForGuest.svelte';
  import MetaText from './UI/MetaText.svelte';
  let user;
  let schools = [];

  let articles: Array<ArticleInterface> = [];

  User.subscribe(obj => {
    user = obj;
  });
  onMount(async () => {
    School.set(null);
    SchoolRole.set(null);

    articles = await ioGet('listUpdateArticles', {
      schoolID: MASTER_SCHOOL_ID,
    });
    if (user.roles) {
      schools = await ioFetch('findSchoolsByIds', {
        ids: user.roles.map(role => {
          return role.schoolID;
        }),
      });
    }
  });
  let targetArticle = null;
  const openArticleDetail = article => {
    targetArticle = article;
    //previewMath()
    //navigate(article._id)

    showModal('#articleTopDetail');
  };
  const saveArticle = () => {};
</script>

<div
  class="bg"
  style:background-image={`url(${HOST}/images/bg_avos_02.jpg)`}
  style:background-size={'cover'}
>
  <Section forTop>
    <TopNav forTop />
    <div class="uk-container uk-text-center  uk-margin-large">
      <h1>{$_('MainTitle')}</h1>
      <div class="uk-margin">
        <Lead>
          {$_(
            schools.length > 1
              ? 'top.YourSchools.prural'
              : 'top.YourSchools.single',
          )}
        </Lead>
        {#if schools.length > 0}
          <div class="uk-margin">
            {$_('top.SchoolsYouCreated')}
          </div>
        {/if}

        <ul class="uk-list ">
          {#each schools as school}
            <li class="uk-margin-bottom uk-text-left">
              <div
                style="border:solid 1px white;
              background-color:rgba(255,255,255,0.1)"
              >
                <div
                  class="uk-padding-small"
                  style="border-bottom:1px solid white;"
                >
                  <div uk-grid class="uk-grid-small">
                    {#if school.iconImageURL}
                      <div>
                        <img
                          alt="thumbnail"
                          src={school.iconImageURL}
                          class="uk-border-rounded"
                          style="height:80px;width:80px"
                        />
                      </div>
                    {/if}
                    <div class="uk-flex uk-flex-middle">
                      <div>
                        <Lead>
                          {school.title}
                        </Lead>
                        <MetaText>
                          <SchoolStats id={school._id} />
                        </MetaText>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="uk-padding-small uk-padding-remove-bottom">
                  {$_('top.YourPersonas')}
                </div>
                <div uk-grid class="uk-grid-small uk-child-width-auto">
                  {#each user.roles.filter(role => role.schoolID == school._id && school.roles.find(schoolRole => schoolRole.id == role.roleID).category != 'guest') as role}
                    <div>
                      <Button
                        style="text"
                        onclick={() => {
                          enterSchoolAs(role.id);
                        }}
                      >
                        <div class="uk-padding uk-text-left">
                          <PersonaBlock
                            withOnlineStatus={false}
                            small
                            schoolRoleID={role.id}
                          />
                        </div>
                      </Button>
                    </div>
                  {/each}
                </div>
              </div>
              <div>
                <SchoolStats onlyDate id={school._id} />
              </div>
            </li>
          {/each}
        </ul>

        {#if schools.length == 0}{$_('top.ByClicking')}{/if}
      </div>
      <button
        class="uk-button uk-button-default uk-width-expand uk-padding-small"
        style="background-color:rgba(255,255,255,0.2)"
        on:click={() => {
          location.href = '/create-school';
        }}
      >
        <div>
          <Lead>
            <Icon icon="plus" size="large" />
            {$_('top.CreateNewSchool')}
          </Lead>
        </div>
      </button>
      {#if typeof Cookies.get('guestVisits') != 'undefined'}
        {@const guestVisits = JSON.parse(Cookies.get('guestVisits'))}
        <Div large>
          <h1>Rooms you visited as a guest</h1>
          <div uk-grid class="uk-grid-small uk-child-width-1-2">
            {#each Object.keys(guestVisits) as roomID}
              {@const visit = guestVisits[roomID]}
              {#key visit}
                <RoomButtonForGuest {visit} {roomID} />
              {/key}
            {/each}
          </div>
        </Div>
      {/if}
      <div class="uk-margin-large">
        <div class="uk-text-center uk-margin-large">
          <h1>UPDATES FROM AVOS</h1>
          <h4 class="uk-margin-remove-top">AVOS更新情報</h4>
        </div>
        <div uk-grid class="uk-width-expand  uk-margin-large">
          <div class="uk-text-center uk-width-3-5@m">
            <h1>News</h1>
            <h4 class="uk-margin-remove-top">お知らせ一覧</h4>
            <div>
              <ul uk-tab style="margin-bottom: 0px;">
                <li>
                  <a href={'#'}>AVOSからのお知らせ</a>
                </li>
                <li>
                  <a href={'#'}>アップデート情報</a>
                </li>
              </ul>
              <ul class="uk-switcher uk-overflow-auto" style="max-height:768px">
                <li>
                  <ArticlesList
                    edit={() => {}}
                    noEdit
                    {saveArticle}
                    {openArticleDetail}
                    articles={articles.filter(
                      article =>
                        INFORMATION_CATEGORY_TITLES.indexOf(
                          article.categoryLabel,
                        ) > -1,
                    )}
                  />
                </li>
                <li>
                  <ArticlesList
                    edit={() => {}}
                    noEdit
                    {saveArticle}
                    {openArticleDetail}
                    articles={articles.filter(
                      article =>
                        UPDATE_CATEGORY_TITLES.indexOf(article.categoryLabel) >
                        -1,
                    )}
                  />
                </li>
              </ul>
            </div>
          </div>
          <div class="uk-text-center uk-width-2-5@m">
            <h1>Twitter</h1>
            <h4 class="uk-margin-remove-top">最新ツイート</h4>
            <TwitterEmbed />
          </div>
        </div>
      </div>
    </div>
  </Section>
</div>

<Modal id="articleTopDetail" size="container" title={targetArticle?.title}>
  <div slot="body">
    {#if targetArticle}
      {#key targetArticle.id}
        <ArticleDetail noPersona bind:article={targetArticle} />
      {/key}
    {/if}
  </div>
</Modal>
