<script>
  import { ioFetch, ioGet } from '../lib/realtime';
  import Cookies from 'js-cookie';
  import {
    User,
    School,
    Room,
    SchoolRole,
    RoomPermissions,
    FooterVisible,
  } from '../lib/store';
  import { Router, Route, navigate } from 'svelte-routing';
  import { onMount } from 'svelte';
  import FileUpload from './blocks/FileUpload.svelte';
  import Lead from './UI/Lead.svelte';
  import Button from './UI/Button.svelte';
  import Input from './UI/Input.svelte';
  import Section from './UI/Section.svelte';
  import AvatarPreview from './blocks/AvatarPreview.svelte';
  import CreateAvatar from './blocks/CreateAvatar.svelte';
  import Modal from './UI/Modal.svelte';
  import PresetAvatars from './Modals/presetAvatars.svelte';
  import Loading from '../lib/helpers/loading';
  import { _ } from 'svelte-i18n';
  import upload from '../lib/helpers/upload';
  import setSchoolAndRoom from '../lib/helpers/setSchoolAndRoom';
  import PersonaBlock from './blocks/PersonaBlock.svelte';
  import { showModal } from '../actions/modalController';
  import { HOST } from '../lib/config';
  import LinkToDocumentation from './blocks/LinkToDocumentation.svelte';

  export let invitationCode = null;
  Cookies.set('invitationCode', invitationCode);
  export let roomSlug;
  export let slug;
  let invitationCodeInput;
  let rooms = [];
  let nickname = '';
  let avatarURL = '';
  let school = null;

  let user = null;
  User.subscribe(obj => {
    user = obj;
  });
  School.subscribe(obj => {
    school = obj;
  });
  let room = null;
  Room.subscribe(obj => {
    if (obj) {
      console.log(obj);
      room = obj;
    }
  });
  let roomPermissions = [];
  RoomPermissions.subscribe(array => {
    roomPermissions = array;
  });

  onMount(async () => {
    console.log(slug, roomSlug);
    await setSchoolAndRoom(slug, roomSlug);
  });

  const enter = async () => {
    Loading.show();
    FooterVisible.set(false);
    setTimeout(function () {
      navigate('/' + school.slug + '/' + room.slug + '/scene');
    }, 1000);
  };
  let mount = null;
  SchoolRole.subscribe(obj => {
    if (obj && mount) {
      mount.do();
    }
  });
</script>

<svelte:head>
  <title>{$Room?.title} - AVOS</title>
</svelte:head>
{#if $SchoolRole && $Room}
  <Section>
    <div class="uk-container" uk-margin>
      <div>
        <ul class="uk-breadcrumb">
          <li>
            <a
              href={'#'}
              on:click={() => {
                Room.set(null);
                navigate('/' + school.slug + '/#');
              }}
            >
              {school?.title}
            </a>
          </li>
          <li>
            <a
              href={'#'}
              on:click={() => {
                Room.set(null);
                navigate('/' + school.slug + '/select-room');
              }}
            >
              {$_('enterRoom.Rooms')}
            </a>
          </li>
          <li>
            <span>
              {room?.title}
            </span>
          </li>
        </ul>
      </div>
      {#if room.participants?.length}
        <div class="uk-text-meta">
          {$_('selectRoom.CurrentlyInTheRoom')}
        </div>
        <div class="uk-grid-small" uk-grid>
          {#each room.participants as participant}
            <div>
              <PersonaBlock small schoolRoleID={participant.identity} />
            </div>
          {/each}
        </div>
        <hr />
      {/if}
      <div>
        <PersonaBlock schoolRoleID={$SchoolRole.id} />
        {#if $SchoolRole.bio}
          <Lead>{$_('Biography')}</Lead>
          <p>
            {@html $SchoolRole.bio}
          </p>
        {/if}
        <Lead>{$_('Avatar')}</Lead>
        <div style="width:200px;" class="uk-margin">
          {#if $SchoolRole.avatarURL}
            <AvatarPreview
              bind:mount
              showGrid={false}
              hideAvatarHands
              circle
              id="enterRoomAvatarPreview"
              url={$SchoolRole.avatarURL}
            />
          {/if}
        </div>
        <Button fullwidth onclick={() => showModal('#editNicknameAndAvatar')}
          >{$_('menu.ChangeNicknameAndAvatar')}</Button
        >
        <hr />
        <Button fullwidth onclick={enter}>
          {$_('enterRoom.EnterRoom')}
        </Button>
        {#if $School.roles.some(role => role.roomPermissionPresets.rooms.canEdit == true && role.id == $SchoolRole.roleID)}
          <div class="uk-margin">
            <Button
              fullwidth
              onclick={() => {
                navigate('setting');
              }}>{$_('roomSetting.RoomSetting')}</Button
            >
          </div>
        {/if}
      </div>
    </div>
  </Section>
{:else}
  <a href={HOST}> Move to Top </a>
{/if}
