import { HOST } from '../config';

let background360Videos = [
  {
    id: 1,
    url: HOST + '/videos/1083484648-sd.mov',
    thumbnail: '/videos/1.jpg',
  },
  {
    id: 2,
    url: HOST + '/videos/1084143145-sd.mov',
    thumbnail: '/videos/2.jpg',
  },
  {
    id: 3,
    url: HOST + '/videos/fireworksHDRI.mp4',
    thumbnail: '/videos/3.jpg',
  },
];
export default background360Videos;
