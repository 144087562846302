<script>
  import { onDestroy, onMount } from 'svelte';

  import { navigate, Route, Router } from 'svelte-routing';
  import dialog from '../lib/helpers/dialog';
  import logout from '../lib/helpers/logout';
  import setRoomPermissions from '../lib/helpers/setRoomPermissions';
  import setSchoolRole from '../lib/helpers/setSchoolRole';
  import { ioGet } from '../lib/realtime';
  import { Room, School, SchoolRole, User } from '../lib/store';
  import AScene from './aframeBlocks/AScene.svelte';
  import EventDetail from './blocks/EventDetail.svelte';
  import TopNav from './blocks/TopNav.svelte';
  import Chat from './Chat.svelte';
  import Console from './Console.svelte';
  import RoomPages from './EnterRoom.svelte';
  import SchoolPages from './SchoolPages.svelte';
  import Button from './UI/Button.svelte';
  import Modal from './UI/Modal.svelte';
  import Navbar from './UI/Navbar.svelte';
  import Section from './UI/Section.svelte';
  export let slug;
  import { _ } from 'svelte-i18n';
  import { defineIfUndefined } from '../lib/helpers/defineIfUndefined';
  import schoolPreset from '../lib/presets/school';

  let user = null;
  User.subscribe(obj => {
    user = obj;
  });
  let school = null;
  School.subscribe(obj => {
    if (obj) {
      obj.features.useVirtualClassrooms = defineIfUndefined(
        obj.features.useVirtualClassrooms,
        true,
      );

      obj.customFields = defineIfUndefined(
        obj.customFields,
        schoolPreset.customFields,
      );
      obj.chatrooms = defineIfUndefined(obj.chatrooms, []);
      school = obj;
    }
  });
  let schoolRole = null;
  SchoolRole.subscribe(obj => {
    if (obj) {
      let match = $School.roles.find(role => role.id == obj.roleID);
      if (match && match.category == 'guest') {
        logout();
      }
    }
    schoolRole = obj;
  });
  let room = null;
  Room.subscribe(obj => {
    room = obj;
  });
  onMount(async () => {
    if (slug == 'register') {
      navigate('/');
      return;
    }
    if ($School) {
      let match = $School.roles.find(role => role.id == $SchoolRole.roleID);
      if (!match) {
        //outsider
        navigate('/');
      }
      if (match && match.category == 'guest') {
        logout();
      }
    }

    if (slug == 'login') {
      navigate('/');
      return;
    }
    if (!user) {
      dialog('NotLoggedIn');
      location.href = '/login';
      return;
    }
    var roomRes = await ioGet('getRoom', {
      slug: slug,
    });
    if (roomRes) {
      Room.set(roomRes);
      let schoolRes = await ioGet('getSchool', {
        _id: roomRes.schoolID,
      });
      if (schoolRes) {
        School.set(schoolRes);
        await setSchoolRole();
      } else {
        dialog($_('WrongURL'));
        navigate('/');

        return;
      }
      setRoomPermissions();
    } else {
      Room.set(null);
      room = null;
      let schoolRes = await ioGet('getSchool', {
        slug: slug,
      });
      if (schoolRes) {
        School.set(schoolRes);
        await setSchoolRole();
      } else {
        dialog($_('WrongURL'));
        navigate('/');

        return;
      }
    }
  });
</script>

<svelte:head>
  <title>{school?.title} - AVOS</title>
</svelte:head>
{#if school}
  <Section>
    <Router>
      <Route path="event/:eventID" component={EventDetail} />

      <Route path="*">
        <TopNav />

        <SchoolPages schoolID={school._id} {slug} />
      </Route>
    </Router>
  </Section>
{/if}
