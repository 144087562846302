<script>
  export let label = '';
  export let checked = false;
  export let required = false;
  export let disabled = false;
  export let onchange = () => {};
</script>

<label>
  <input
    type="checkbox"
    class="uk-checkbox"
    {required}
    {disabled}
    bind:checked
    on:change={onchange}
  />
  {label}
  <slot />
</label>
