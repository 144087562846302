import '../uuid';
const CustomFieldDefault = {
    title: '',
    category: '',
    permissions: {},
    options: {
        values: [],
        rows: [],
        target: '',
    },
    showOnList: false,
    showOnBadge: false,
    defaultValue: '',
    type: 'text',
};
export { CustomFieldDefault };
