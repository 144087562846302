const features = [
    {
        title: 'useEvents',
        requiredPlanGrade: 1,
        default: true,
    },
    // {
    //   title: 'useVirtualClassrooms',
    //   requiredPlanGrade: 1,
    //   default: true,
    // },
    {
        title: 'useClassmates',
        requiredPlanGrade: 1,
        default: true,
    },
    {
        title: 'usePublicEvents',
        requiredPlanGrade: 1,
        default: true,
    },
    {
        title: 'useAttendances',
        requiredPlanGrade: 3,
        default: false,
    },
    {
        title: 'usePublicArticles',
        requiredPlanGrade: 1,
        default: true,
    },
    {
        title: 'useIntervals',
        requiredPlanGrade: 3,
        default: false,
    },
    {
        title: 'useAssignments',
        requiredPlanGrade: 3,
        default: false,
    },
    {
        title: 'useGrades',
        requiredPlanGrade: 4,
        default: false,
    },
    {
        title: 'useHourNames',
        requiredPlanGrade: 1,
        default: true,
    },
    {
        title: 'useParentAccounts',
        requiredPlanGrade: 4,
        default: false,
    },
    {
        title: 'useEC',
        requiredPlanGrade: 3,
        default: false,
    },
    //useDashboard:false
];
export default features;
