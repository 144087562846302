<script>
  import { ioFetch, ioGet, io } from '../lib/realtime';
  import EnterCard from './blocks/EnterCard.svelte';
  import Button from './UI/Button.svelte';
  import Cookies from 'js-cookie';
  import EmailValidator from 'email-validator';
  import Input from './UI/Input.svelte';
  import { onMount } from 'svelte';
  import { navigate } from 'svelte-routing';
  import { ComeBackURL, LoggedIn } from '../lib/store';
  import { getLocaleFromNavigator, _ } from 'svelte-i18n';
  import dialog from '../lib/helpers/dialog';
  import Loading from '../lib/helpers/loading';
  let mode = 'forgot-password';

  let comeBackURL;
  ComeBackURL.subscribe(url => {
    comeBackURL = url;
  });
  LoggedIn.subscribe(bool => {
    if (bool) {
      navigate('/');
    }
  });

  let email = '';
  const sendVerificationCode = async () => {
    Loading.show();
    console.log('sending verification');
    if (!EmailValidator.validate(email)) {
      //email not OK
      dialog($_('login.NotAValidEmail'));
      return;
    }
    let res = await ioGet('sendVerificationCode', {
      email: email,
      locale: Cookies.get('locale') || getLocaleFromNavigator(),
    });
    Loading.hide();
    dialog($_('login.CheckYourEmail'));
    mode = 'verify';
  };
  let code = '';
  const verifyCode = async () => {
    let res = await ioGet('verifyCode', {
      email: email,
      code: code,
      locale: Cookies.get('locale') || getLocaleFromNavigator(),
    });
    console.log(res);
    if (res.result == 'ng') {
      alert('wrong code');
      return;
    } else if (res.result == 'not registered') {
      dialog($_('login.YouAreNotRegistered'));
      return;
    }
    Cookies.set('login', res.login);

    location.href = '/';
  };
</script>

<svelte:head>
  <title>{$_('login.ForgotPassword')} - AVOS</title>
</svelte:head>
{#if mode == 'forgot-password'}
  <EnterCard>
    <span slot="title">{$_('login.ForgotPassword')}</span>
    <p slot="description">{$_('login.PleaseEnterYourEmail')}</p>
    <div slot="body">
      <Input type="email" bind:value={email} />
      <Button fullwidth onclick={sendVerificationCode}>{$_('Send')}</Button>
      <div class="uk-margin">
        <Button
          style="text"
          small
          onclick={() => {
            navigate('/login');
          }}>{$_('login.GoToLogin')}</Button
        >
      </div>
    </div>
  </EnterCard>
{:else}
  <EnterCard>
    <span slot="title">{$_('login.VerifyCode')}</span>
    <div slot="body">
      <Input type="text" bind:value={code} />
      <Button onclick={verifyCode} fullwidth>{$_('login.VerifyCode')}</Button>
      <Button
        style="text"
        onclick={() => {
          mode = 'forgot-password';
          navigate('/forgot-password');
        }}>{$_('login.SendCodeAgain')}</Button
      >
    </div>
  </EnterCard>
{/if}
