<script>
  import RoomSettingBlock from '../blocks/RoomSettingBlock.svelte';
  import Modal from '../UI/Modal.svelte';
  import { Room } from '../../lib/store';
  import { _ } from 'svelte-i18n';
  import Button from '../UI/Button.svelte';
  import { ioGet } from '../../lib/realtime';
  import dialog from '../../lib/helpers/dialog';
  import { roomStore } from '../../state/stores/roomStore';
  import { Notification } from '../../actions/notification';
  let room = null;
  Room.subscribe(obj => {
    if (obj) {
      if (typeof obj.environment == 'undefined') {
        obj.environment = {};
      }
      room = obj;
    }
  });
  const saveRoom = async () => {
    roomStore.update(room);
    Notification($_('Saved'));
    //location.reload();
  };
</script>

<Modal id="roomSettingModal" title={room?.title}>
  <div slot="body">
    <RoomSettingBlock
      small
      triggerSave={() => saveRoom('noTransfer')}
      bind:room
    />
    <Button fullwidth onclick={saveRoom}>{$_('Save')}</Button>
  </div>
</Modal>
