import { DateTime } from 'luxon';
import { SchoolRole } from '../store';
let schoolRole;
SchoolRole.subscribe(obj => {
  schoolRole = obj;
});
const prepareEventForCalendar = schoolEvent => {
  schoolEvent.id = schoolEvent._id;
  if (!schoolEvent.allDay) {
    if (!schoolEvent.useCustomTime) {
      schoolEvent.editable = false;
    }

    if (schoolEvent.useCustomTime) {
      schoolEvent.title =
        DateTime.fromISO(schoolEvent.start).toFormat('H:mm') +
        ' ' +
        schoolEvent.title;
    } else {
      // schoolEvent.display = 'block'
      schoolEvent.title = '[' + schoolEvent.hourName + '] ' + schoolEvent.title;
    }
  }
  let setting = schoolEvent.attendanceSettings.find(
    setting => setting.roleID == schoolRole.roleID,
  );
  if (setting) {
    let attendance = setting.attendances.find(
      attendance => attendance.schoolRoleID == schoolRole.id,
    );
    if (attendance) {
      if (attendance.status == 'attending' || setting.autoAttendance) {
        schoolEvent.title += '✅';
      }
      if (attendance.status == 'unattending') {
        schoolEvent.title += '🚷';
        console.log('unattending', schoolEvent);
      }
    }
  }
  return schoolEvent;
};
export default prepareEventForCalendar;
