<script>
  import { navigate } from 'svelte-routing';
  import { _ } from 'svelte-i18n';
  import { SchoolRole, School, Room } from '../lib/store';
  import Article from './UI/Article.svelte';
  import Button from './UI/Button.svelte';
  import { onMount } from 'svelte';
  import { ioGet } from '../lib/realtime';
  import nl2br from 'nl2br';
  import PersonaBlock from './blocks/PersonaBlock.svelte';

  let schoolRole;
  SchoolRole.subscribe(obj => {
    schoolRole = obj;
  });
  let school = null;
  School.subscribe(async obj => {
    school = obj;
    if (!obj) return;
    let roomsRes = await ioGet('findRooms', {
      query: {
        schoolID: obj._id,
      },
    });
    if (roomsRes.length) {
      rooms = roomsRes.filter(room => {
        if (!schoolRole) return;
        if (schoolRole.category == 'admin') return true;
        let res = room.permissions.find(permission => {
          if (
            permission.id == schoolRole.roleID &&
            permission.roomPermissionPresets.canEnter
          ) {
            return true;
          }
        });
        return res;
      });
    }
  });
  let rooms = [];
  onMount(async () => {});
</script>

<svelte:head>
  <title>{$_('Rooms')} | {$School?.title} - AVOS</title>
</svelte:head>
<div uk-margin>
  {#if rooms.length}
    <ul class="uk-list uk-list-divider">
      {#each rooms as room}
        <li class="uk-position-relative">
          <Article titleSize="h3">
            <div slot="thumbnail">
              {#if room.iconImageURL}
                <img
                  alt="thumbnail"
                  src={room.iconImageURL}
                  class="uk-border-rounded"
                  style="height:120px;width:120px"
                />
              {/if}
            </div>

            <span slot="title">
              {room.title}
            </span>
            <div slot="body">
              {#if room.requirePassword}
                <i class="fa-duotone fa-lock" />{$_(
                  'selectRoom.PasswordRequired',
                )}
              {/if}
              {#if room.description}
                <p>{@html nl2br(room.description)}</p>
              {/if}
              {#if room.participants.length}
                <div class="uk-text-meta">
                  {$_('selectRoom.CurrentlyInTheRoom')}
                </div>
                <div class="uk-grid-small" uk-grid>
                  {#each room.participants as participant}
                    <div>
                      <PersonaBlock small schoolRoleID={participant.identity} />
                    </div>
                  {/each}
                </div>
              {/if}
              <div uk-grid class="uk-grid-small">
                <div />

                <div>
                  <Button
                    onclick={() => {
                      Room.set(room);
                      navigate(
                        '/' + school.slug + '/' + room.slug + '/enter-room',
                      );
                    }}
                    ><i class="fa-duotone fa-person-to-portal" />
                    {$_('selectRoom.EnterRoom')}</Button
                  >
                </div>
                {#if $School.roles.some(role => role.roomPermissionPresets.rooms.canEdit == true && role.id == $SchoolRole.roleID)}
                  <div>
                    <Button
                      onclick={() => {
                        Room.set(room);
                        navigate(
                          '/' + school.slug + '/' + room.slug + '/setting',
                        );
                      }}
                      ><i class="fa-duotone fa-gear" />
                      {$_('roomSetting.RoomSetting')}</Button
                    >
                  </div>
                {/if}
              </div>
            </div>
          </Article>
        </li>
      {/each}
    </ul>
  {/if}
  {#if schoolRole}
    {#if schoolRole.roomPermissionPresets.rooms.canCreate}
      <Button
        fullwidth
        onclick={() => {
          navigate('/' + $School.slug + '/create-room');
        }}>{$_('CreateNewClassroom')}</Button
      >
    {/if}
  {/if}
</div>
