<script lang="ts">
  import axios from 'axios';
  import { onMount } from 'svelte';
  import { navigate, Route, Router } from 'svelte-routing';
  import { SERVER_URL } from '../lib/config';
  import type { Registration } from '../lib/models/registration';
  import { RegistrationStatus } from '../lib/models/registration';
  import { School, User } from '../lib/store';
  import MyReportTable from './blocks/MyReportTable.svelte';
  import NavList from './blocks/NavList.svelte';
  let registrations: Registration[] = [];
  let registeredIntervalIds: string[] = [];
  let subscribers = [];
  let breadcrumb = '/' + $School.slug + '/my-reports/';
  let focus = location.href
    .split('my-reports/')
    .pop()
    .split('/')
    .shift()
    .replace('#', '');
  let focus2 = location.href
    .split('my-reports/')
    .pop()
    .split('/')[1]
    ?.replace('#', '');
  const nav = slug => {
    navigate('/' + $School.slug + '/my-reports/' + slug + '/');
    focus = slug;
  };

  onMount(async () => {
    const allRegistrations: Registration[] = await userListRegistrations(
      $User._id,
    );
    registrations = allRegistrations.filter(
      registration => registration.status === RegistrationStatus.CourseStarted,
    );
    registeredIntervalIds = registrations.flatMap(
      registration => registration.intervalId,
    );

    if (!focus) {
      if (!registeredIntervalIds.length) return;
      nav(registeredIntervalIds[0]);
    }
  });

  const userListRegistrations = async (userId: string): Promise<any> => {
    return axios
      .get<any>(`${SERVER_URL}api/v2/users/${userId}/registrations`)
      .then(res => res.data);
  };

  const canViewInterval = (
    intervalId: string,
    viewableIntervalList: string[],
  ): boolean => {
    if (!viewableIntervalList.length) return false;
    return viewableIntervalList.some(
      viewabledInterval => viewabledInterval === intervalId,
    );
  };
</script>

<ul class="uk-tab" hidden={!$School.features.useIntervals}>
  {#each $School.intervals as interval}
    {#if canViewInterval(interval.id, registeredIntervalIds)}
      <NavList bind:focus slug={interval.id} {breadcrumb}>
        {interval.title}
      </NavList>
    {/if}
  {/each}
</ul>
<div>
  <Router>
    {#each $School.intervals as interval}
      {#if canViewInterval(interval.id, registeredIntervalIds)}
        <Route path={interval.id + '/*'}>
          <MyReportTable {interval} />
        </Route>
      {/if}
    {/each}
  </Router>
</div>
