<script lang="ts">
  import { DateTime } from 'luxon';
  import { onMount } from 'svelte';
  import { ioGet } from '../../lib/realtime';
  import Button from '../UI/Button.svelte';
  import Lead from '../UI/Lead.svelte';
  import MetaText from '../UI/MetaText.svelte';

  export let roomID;
  export let visit;
  let school = null;
  let room = null;
  onMount(async () => {
    console.log(visit);
    room = await ioGet('getRoom', {
      _id: roomID,
    });
    school = await ioGet('getSchool', {
      _id: room.schoolID,
    });
  });
</script>

{#if room && school}
  <div class="uk-text-left">
    <div style="border:1px solid white;">
      <Button
        fullwidth
        style="text"
        onclick={() => {
          location.href = visit.url;
        }}
      >
        <div class="uk-padding uk-text-left">
          <div uk-grid class="uk-grid-small">
            <div>
              <img
                alt={'#'}
                src={room.iconImageURL}
                style="height:80px;hight:80px"
                class="uk-border-rounded"
              />
            </div>
            <div class="uk-flex uk-flex-middle">
              <div>
                <Lead>
                  {room.title}
                </Lead>
                <MetaText>
                  @
                  {school.title}
                </MetaText>
              </div>
            </div>
          </div>
        </div>
      </Button>
    </div>
    Last Visited:
    {DateTime.fromISO(visit.dateTime).toLocaleString(DateTime.DATETIME_SHORT)}
  </div>
{/if}
