<script>
  import { _ } from 'svelte-i18n';
  import Button from '../UI/Button.svelte';
  import Input from '../UI/Input.svelte';
  import Modal from '../UI/Modal.svelte';
  let value = '';
</script>

<Modal stack withFooter id="promptModal">
  <div slot="body">
    <div id="promptMessage" />
    <Input bind:value id="promptInput" />
  </div>
  <div slot="footer">
    <Button id="promptCancel">{$_('Cancel')}</Button>
    <Button id="promptOK">{$_('OK')}</Button>
  </div>
</Modal>
