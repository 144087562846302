<script lang="ts">
  import { ioGet } from '../lib/realtime';
  import queryString from 'query-string';
  import {
    User,
    SchoolRole,
    Room,
    School,
    myVolumeRaw,
    AudioDeviceID,
    AudioInputs,
  } from '../lib/store';
  import AScene from './aframeBlocks/AScene.svelte';
  import { UUID } from '../lib/uuid';
  import dialog from '../lib/helpers/dialog';
  import { navigate } from 'svelte-routing';
  import setSchoolAndRoom from '../lib/helpers/setSchoolAndRoom';
  import { _ } from 'svelte-i18n';
  import {
    muteAudio,
    normalAudio,
    RoomReady,
    videoController,
  } from '../actions/videoController';
  import Cookies from 'js-cookie';
  import { onMount, setContext } from 'svelte';
  import Loading from '../lib/helpers/loading';
  import Avatars from '../lib/presets/avatars';
  import { HOST } from '../lib/config';
  import myPrompt from '../lib/helpers/myPrompt';
  import { DateTime } from 'luxon';
  import { avatarPredictions } from '../lib/helpers/predictions';
  import Modal from './UI/Modal.svelte';
  import Switch from './UI/Switch.svelte';
  import { showModal } from '../actions/modalController';
  import { updateFacialCapture } from '../lib/helpers/updateFacialCapture';
  import { CometChat } from '@cometchat-pro/chat';
  import { createWhiteBoardAndDocumentURLForRoom } from '../lib/helpers/createWhiteBoardAndDocumentURLForRoom';
  import { Notification } from '../actions/notification';
  export let slug = '';
  export let roomSlug = '';
  let micOn = false;
  let roomReady = false;
  let asGuest = false;
  const markGuestVisit = roomID => {
    let guestVisits = {};
    if (typeof Cookies.get('guestVisits') != 'undefined') {
      guestVisits = JSON.parse(Cookies.get('guestVisits'));
    }
    guestVisits[roomID] = {
      roomID: roomID,
      dateTime: DateTime.now().toISO(),
      url: location.href,
    };
    Cookies.set('guestVisits', JSON.stringify(guestVisits));
  };
  const updateMic = () => {
    micOn = !micOn;
    SchoolRole.update(role => {
      role.micOn = micOn;
      return role;
    });
    if (micOn) {
      normalAudio();
    } else {
      muteAudio();
    }
  };

  const wrongURL = (back: string | boolean = false) => {
    dialog($_('WrongURL'));
    if (back) {
      history.back();
    } else {
      navigate('/');
      Loading.hide();
    }
  };
  onMount(async () => {
    SchoolRole.update(role => {
      if (role) {
        role.micOn = false;
      }
      return role;
    });
    document
      .getElementById('initialSettingModal')
      .addEventListener('hide', async () => {
        let res = await ioGet('updatePersona', {
          schoolRoleID: $SchoolRole.id,
          data: {
            micOn: $SchoolRole.micOn,
            dashboardVisible: $SchoolRole.dashboardVisible,
            useFacialCapture: $SchoolRole.useFacialCapture,
          },
        });
      });
    avatarPredictions()
      .then(() => console.log('Avatar predictions started'))
      .catch(err => {
        console.log('Avatar predictions failed to start', err);
        Notification('Camera access not granted.');
      });

    Loading.show();
    let params = queryString.parse(location.search);
    if (!$User) {
      if (params.invitation) {
        let roomRes = await ioGet('getRoom', {
          slug: roomSlug,
        });
        if (roomRes) {
          let invitation = roomRes.invitations.find(
            invitation => invitation.id == params.invitation,
          );
          if (invitation) {
            let schoolRes = await ioGet('getSchool', {
              slug: slug,
            });
            if (schoolRes) {
              let avatar = Avatars[Math.floor(Math.random() * Avatars.length)];

              let res = await ioGet('grantGuestAccess', {
                schoolID: schoolRes._id,
                roleID: invitation.roleID,
                nickname: 'Guest',
                avatarURL: avatar.url,
                profileImageURL: HOST + '/' + avatar.thumbnail,
              });
              Cookies.set('login', res.login);
              Cookies.set('role', res.user.roles[0].id);
              User.set(res.user);
              markGuestVisit(roomRes._id);
            } else {
              wrongURL();
              return;
            }
          } else {
            wrongURL();
            return;
          }
        } else {
          wrongURL();
          return;
        }
      } else {
        alert('not logged in');
        location.href = '/login';
        return;
      }
    } else {
      //you may be logged in but maybe with an invitation that means your role would be different.
      if (params.invitation) {
        let roomRes = await ioGet('getRoom', {
          slug: roomSlug,
        });
        if (roomRes) {
          let invitation = roomRes.invitations.find(
            invitation => invitation.id == params.invitation,
          );
          if (invitation) {
            markGuestVisit(roomRes._id);
            asGuest = true;
          } else {
            wrongURL('back');
            return;
          }
        } else {
          wrongURL('back');
          return;
        }
      } else {
      }
    }
    await setSchoolAndRoom(slug, roomSlug, asGuest);
    setTimeout(async function () {
      if ($Room.requirePassword) {
        myPrompt($_('EnterPasswordToEnter'), async function (value) {
          if (!value) {
            history.back();
            return;
          }
          if (value == $Room.password) {
            await openRoom();
          } else {
            dialog($_('WrongPassword'));
            history.back();
          }
        });
      } else {
        await openRoom();
      }
    }, 100);
  });
  const openRoom = async () => {
    Loading.show($_('ConnectingToAudio'));
    let videoTokenRes = await ioGet('getVideoToken');
    try {
      await videoController.init(videoTokenRes.token);
    } catch (err) {
      console.log(err);
      dialog(
        'Camera and Microphone access needed. some of the features will not work.',
      );
    }
    //cometchat group
    let group = new CometChatWidget.CometChat.Group(
      $Room._id,
      $Room.title,
      'public',
    );
    group.setIcon($Room.iconImageURL || 'https://avos.ac/images/no_photo.jpg');
    group = await CometChatWidget.createOrUpdateGroup(group);
    if (!group.hasJoined) {
      try {
        await CometChat.joinGroup(group.guid, CometChat.GROUP_TYPE.PUBLIC);
      } catch (err) {
        console.log(err);
      }
    }
    createWhiteBoardAndDocumentURLForRoom();
    //muteAudio();
  };
  RoomReady.subscribe(bool => {
    if (bool) {
      roomReady = true;
      Loading.show($_('LoadingModelsPleaseBePatient'));
    }
  });
  let audioInputs = [];
  navigator.mediaDevices.enumerateDevices().then(devices => {
    audioInputs = devices.filter(device => device.kind === 'audioinput');
    audioInputs = audioInputs.map(input => {
      input.inUse = input.deviceId == $AudioDeviceID;
      return input;
    });
    AudioInputs.set(audioInputs);
  });
</script>

<svelte:head>
  <title>{$Room?.title} | {$School?.title} - AVOS</title>
</svelte:head>
{#if roomReady}
  <AScene />
{/if}
<div
  id="ascene-cover"
  class="uk-position-top"
  style="width:100vw;height:100vh;top:0px;background-color:garkgrey"
>
  <div class="uk-position-center">
    <img
      src="/images/008.png"
      alt=""
      class="uk-width-large uk-border-rounded"
      style="opacity:0.5"
    />
  </div>
</div>
<Modal id="initialSettingModal" title={$_('menu.MicAndCameraSetting')}>
  <div slot="body">
    {#if $SchoolRole}
      <div uk-grid>
        <div class="uk-width-expand">
          {$_('menu.Audio')}
        </div>
        <div>
          <Switch value={micOn} onChange={() => updateMic()} />
        </div>
      </div>
      {#if $SchoolRole.micOn}
        <div uk-grid>
          <div>
            {$_('menu.MicLevel')}
          </div>
          <div class="uk-width-expand uk-flex u-flex-middle">
            <div
              id="myVoumeMeterOnModal"
              style="
            background-color:lightgreen;
            height:16px;
            width:{$myVolumeRaw * 10}%;"
            />
          </div>
        </div>
      {/if}
      <div uk-grid>
        <div class="uk-width-expand">
          {$_('menu.FacialCapture')}
        </div>
        <div>
          <Switch
            value={$SchoolRole.useFacialCapture}
            onChange={updateFacialCapture}
          />
        </div>
      </div>
      <p class="uk-text-meta uk-margin-remove-top">
        {$_('message.HowToSwitchFacialCapture')}
      </p>
    {/if}
  </div>
</Modal>
