import { writable, readable } from 'svelte/store';
import { tweened } from 'svelte/motion';
import 'js-cookie';
import { HOST } from './config';
export const ItemsLoaded = writable(0);
export const ItemCounts = writable(0);
export const User = writable(null);
export const School = writable(null);
export const Room = writable(null);
export const RoomPermissions = writable(null);
export const SchoolRole = writable(null);
export const ConstructionMode = writable(false);
export const ComeBackURL = writable(HOST);
export const LoggedIn = writable(null);
export const gumballTarget = writable(null);
export const dashboardGumballTarget = writable(null);
export const HEIGHT = writable(0);
export const RIG = writable({});
export const noiseThreshold = tweened(1);
export const micOnMute = writable(false);
export const transformLock = writable(null);
export const myVolume = writable(0);
export const myVolumeRaw = writable(0);
export const captureScreenActive = writable(false);
export const webcamActive = writable(false);
export const myCurrentFace = writable('normal');
export const emotePanelVisible = writable(false);
export const mediaVolume = writable(1);
export const megaphoneActive = writable(false);
export const ROLE = writable(null);
export const focusFriend = writable(null);
export const gumballTargetType = writable('');
export const AudioDeviceID = writable('');
export const PointerLockEnabled = writable(false);
export const TargetPersona = writable(null);
export const DialogMessage = writable('');
export const CalendarInstance = writable(null);
export const TargetArticle = writable(null);
export const TargetProduct = writable(null);
export const Articles = writable([]);
export const Products = writable([]);
export const AudioInputs = writable([]);
export const LoadingMessage = writable('');
export const EventToCreate = writable(null);
export const ImageEditURL = writable('');
export const ImageEditCallback = writable((res) => { });
export const ImageEditSquare = writable(false);
export const TargetFolder = writable('');
export const AdjustTime = writable(0);
export const FooterVisible = writable(location.href.indexOf('/scene') == -1);
export const TargetCustomField = writable(null);
export const AskIfShowOnList = writable(false);
export const ShowShowOnBadge = writable(false);
export const SaveCustomField = writable(() => { });
export const TargetAssignment = writable(null);
export const TargetAssignmentSave = writable(null);
export const TargetGrade = writable(null);
export const TargetInterval = writable(null);
export const TargetGroup = writable(null);
export const TargetFields = writable([]);
export const TargetSubmission = writable(null);
export const TargetCategory = writable(null);
export const TargetProgramCategory = writable(null);
export const TargetPersonas = writable(null);
export const ChatUnreadCount = writable(0);
export const previewValue = writable('');
export const flyMode = writable(false);
export const SchoolPrograms = writable([
    {
        title: 'Math101',
    },
    {
        title: 'Art101',
    },
]);
export const VideoStream = writable(null);
export const TargetEvent = writable(null);
//sound 'god mode' - everyone can hear you
export const IsMutable = writable(true);
export const mousePos = readable({ x: 0, y: 0 }, function start(set) {
    window.onpointermove = evt => {
        evt = evt || window.evt; // IE対応
        set({ x: evt.clientX, y: evt.clientY });
    };
    return function stop() {
        //do nothing
    };
});
