const versionHistory = [
  {
    id: 12,
    version: '0.1.11',
    date: '2022-08-31',
    description: 'Preset Models.',
    updates: [],
  },
  {
    id: 11,
    version: '0.1.10',
    date: '2022-08-24',
    description: 'Math equasions, subscription Plans.',
    updates: [],
  },
  {
    id: 10,
    version: '0.1.9',
    date: '2022-08-20',
    description: 'Subscribers (students) views.',
    updates: [
      {
        description:
          'Students report, schooling, special activities, exams, additional exams, grades, all completed. Plus, you can add/remove any views for any event categories, exams, special activities, any thing. And each of them come with custom fields AND custom summary fields.',
        date: '2022-08-20',
      },
    ],
  },
  {
    id: 9,
    version: '0.1.8',
    date: '2022-08-17',
    description: 'Attendance Credit (Schooling) feature.',
    updates: [
      {
        description:
          'Custom Fields for attendance data. Atendance credit and attendance status are now customizable and optional for all schools.',
        date: '2022-08-16',
      },
      {
        description:
          'Exams list feature. Any type of events can be displayed in either list view or sort-by-student view. And these views are fully customizable with custom summary fields. Attendance counting feature is fully accomplished with this update.',
        date: '2022-08-17',
      },
      {
        description: 'Bulk registration edit. Sort/filter by custom field.',
        date: '2022-08-18',
      },
    ],
  },
  {
    id: 8,
    version: '0.1.7',
    date: '2022-08-10',
    description: 'Custom Fields support for all.',
    updates: [
      {
        description:
          'Completed customfield support for all elements. including the relationship between fields.',
        date: '2022-08-10',
      },
    ],
  },
  {
    id: 7,
    version: '0.1.6',
    date: '2022-08-09',
    description: 'Submissions management.',
    updates: [
      {
        description: 'Submissions Management.',
        date: '2022-08-09',
      },
      {
        description: 'Grade Management.',
        date: '2022-08-09',
      },
    ],
  },
  {
    id: 6,
    version: '0.1.5',
    date: '2022-08-04',
    description: 'Assignment management.',
    updates: [
      {
        description: 'Assignment Management.',
        date: '2022-08-04',
      },
    ],
  },
  {
    id: 5,
    version: '0.1.4',
    date: '2022-08-03',
    description: 'iCal export, custom fields, CSV upload',
    updates: [
      {
        description: 'Export event to iCal file support.',
        date: '2022-08-03',
      },
      {
        description: 'Custom Fields for users.',
        date: '2022-08-03',
      },
      {
        description: 'CSV upload of user accounts.',
        date: '2022-08-03',
      },
    ],
  },
  {
    id: 4,
    version: '0.1.3',
    date: '2022-08-01',
    description: 'EC feature as a experimental feature. Bug Fixes.',
    updates: [
      {
        description:
          "Added 'Sun' for vertual classroom environment background.",
        date: '2022-08-01',
      },
      {
        description: "Added 'Blank' scene.",
        date: '2022-08-01',
      },
      {
        description: 'Enabled room setting from within the room.',
        date: '2022-08-01',
      },
    ],
  },
  {
    id: 3,
    version: '0.1.2',
    date: '2022-07-26',
    description: 'EC feature as a experimental feature. Bug Fixes.',
    updates: [
      {
        description: 'EC feature',
        date: '2022-07-26',
      },
    ],
  },
  {
    id: 2,
    version: '0.1.1',
    date: '2022-07-24',
    description:
      'Last minute updates before whole things being tested by beta testers.',
    updates: [
      {
        description: 'Avatar height support.',
        date: '2022-07-25',
      },
      {
        description: 'UI background color customization support.',
        date: '2022-07-25',
      },
      {
        description:
          'Google Analytics installed, and correct page titles for all pages.',
        date: '2022-07-24',
      },
      {
        description: 'File Browsers and thumbnailing.',
        date: '2022-07-24',
      },
      {
        description:
          "Recurring event's bug fixes. Start time and end time not correctly calculated.",
        date: '2022-07-24',
      },
    ],
  },
  {
    id: 1,
    version: '0.1.0',
    date: '2022-07-22',
    description: 'Beginning of the version history',
    updates: [
      {
        description: 'Created version history',
        date: '2022-07-22',
      },
    ],
  },
];
export default versionHistory;
