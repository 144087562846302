<script>
  import { onMount } from 'svelte';
  import { ioGet } from '../../lib/realtime';

  export let schoolID;
  let school = null;
  onMount(async () => {
    school = await ioGet('getSchool', {
      _id: schoolID,
    });
  });
</script>

{#if school}
  <div class="uk-flex uk-flex-middle">
    {#if school.iconImageURL}
      <div class="uk-margin-small-right">
        <img
          alt="icon"
          src={school.iconImageURL}
          style="height:40px;width:40px;"
          class="uk-border-rounded"
        />
      </div>
    {/if}
    <div>
      {school?.title}
    </div>
  </div>
{/if}
