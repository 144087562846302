<div class="floatingPanel">
  <div class="uk-padding-small">
    <slot name="title" />
  </div>
  <div class="overflow" id="chatMessages">
    <slot name="messages" />
  </div>
  <div class="footer">
    <slot name="footer" />
  </div>
</div>

<style>
  .floatingPanel {
    height: calc(100vh - 250px);
    height: calc(100dvh - 150px);
    position: absolute;
    top: 50px;
    right: 10px;
    max-width: calc(100vw - 110px);
    width: 400px;
    border-radius: 6px;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .overflow {
    overflow-y: auto;
    height: calc(100vh - 370px);
    height: calc(100dvh - 270px);
  }

  .footer {
    bottom: 0px;
  }
</style>
