<script lang="ts">
  export let message = null;
  export let date = '';
  export let previousDate = '';
  export let index: number;
  import { _ } from 'svelte-i18n';
  import { DateTime } from 'luxon';
  import { onMount } from 'svelte';
  import { ioGet } from '../../lib/realtime';
</script>

{#if date != previousDate}
  <div class="uk-text-meat uk-text-center">
    <hr />
    {DateTime.fromISO(message.createdAt).toFormat('D')}
  </div>
{/if}
{#if message?.category == 'information'}
  <div class="uk-text-center uk-text-italic uk-text-small">
    <img
      alt="avatar"
      src={message.profileImageURL}
      class="uk-border-circle informationIcon"
    />
    {message.nickname}
    {$_(`chat.${message.body}`)}
    {DateTime.fromISO(message.createdAt).toFormat('H:mm')}
  </div>
{/if}
{#if message?.category == 'message'}
  <div class="uk-grid-small uk-width-expand" uk-grid>
    <div>
      <img
        alt="avatar"
        src={message.profileImageURL}
        class="uk-border-circle messageIcon"
      />
    </div>
    <div class="uk-width-expand">
      <div class="message">
        <div class="messageSender uk-text-small">
          {message.nickname}
        </div>
        <div class="messageBody">
          {message.body}
        </div>
        <div class="messageDate uk-text-meta uk-text-right uk-text-italic">
          {DateTime.fromISO(message.createdAt).toFormat('H:mm')}
        </div>
      </div>
    </div>
  </div>
{/if}

<style>
  .messageIcon {
    width: 50px;
    height: 50px;
  }

  .informationIcon {
    width: 28px;
    height: 28px;
  }

  .messageSender {
  }

  .messageBody {
  }

  .messageDate {
  }
</style>
