import { writable } from 'svelte/store';
import { io, ioGet } from '../../lib/realtime';
import { Room } from '../../lib/store';

let room = null;
Room.subscribe(obj => {
  if (obj) {
    room = obj;
  }
});
export const roomStore = {
  update: async data => {
    ioGet('updateRoom', {
      _id: data._id,
      data: data,
    });
  },
  get: () => {
    return room;
  },
};

io.on('roomUpdated', res => {
  console.log({ res });
  if (res.data._id == room._id) {
    console.log('roomUpdated called', { data: res.data });
    Room.update(r => {
      r = {
        ...r,
        ...res.data,
      };
      console.log(r);
      return r;
    });
    if (
      (location.href.indexOf('scene') > 0 &&
        res.data.backgroundType == 'video') ||
      res.data.use360VideoBackground
    ) {
      // in the room. so update the background asset
      document
        .getElementById('background360Video')
        .setAttribute('src', res.data.sky360URL);
    }
  }
});
