import { moveFace } from '../lib/helpers/moveFace';
import moveMouth from '../lib/helpers/moveMouth';
import { myCurrentFace, SchoolRole, User } from '../lib/store';
let user;
User.subscribe(id => {
  user = id;
});
let schoolRole;
SchoolRole.subscribe(obj => {
  schoolRole = obj;
});
let currentFace;
myCurrentFace.subscribe(face => {
  currentFace = face;
});
AFRAME.registerComponent('slave-neck-to-hands', {
  schema: {
    enabled: { type: 'boolean' },
    targetId: { type: 'string' },
    useFace: { type: 'boolean' },
  },
  init: function () {
    this.sync = false;
    //this.rig = rig
    this.el.addEventListener('model-loaded', evt => {
      let model = evt.detail.model;
      if (model.children.length) {
        model.traverse(object => {
          if (object.name == 'Wolf3D_Avatar') {
            this.head = object;
          }
          if (object.name == 'Head' || object.name == 'mixamorig_Head') {
            this.headBone = object;
          }
          if (object.name == 'Neck' || object.name == 'mixamorig_Neck') {
            this.neck = object;
          }
        });
        this.neckFactor = -1;

        this.sync = true;
        if (this.data.targetId == schoolRole.id) {
          this.hands = document.getElementById('myHands');
        } else {
          this.hands = document.getElementById('myHands_' + this.data.targetId);
        }
        // console.log(this.sync)
      }
    });
    let interval = setInterval(() => {
      if (
        this.data.targetId == schoolRole.id &&
        typeof this.headBone != 'undefined' &&
        schoolRole.useFacialCapture
      ) {
        moveFace(
          this.head,
          this.headBone,
          schoolRole.faceShape,
          schoolRole.faceRotation,
          this.data.useFace,
        );
      } else {
        moveMouth(this.head, schoolRole.currentVolume, schoolRole.currentFace);
        if (this.sync && this.hands) {
          this.hands.object3D.traverse(object => {
            if (
              (object.name == 'mixamorig_Head' ||
                object.name == 'mixamorigHead') &&
              typeof this.headBone != 'undefined'
            ) {
              this.headBone.rotation.x = object.rotation.x;
              this.headBone.rotation.y = object.rotation.y;
              this.headBone.rotation.z = object.rotation.z;
            }
            if (
              (object.name == 'mixamorig_Neck' ||
                object.name == 'mixamorigNeck') &&
              typeof this.neckBone != 'undefined'
            ) {
              this.neckBone.rotation.x = object.rotation.x;
              this.neckBone.rotation.y = object.rotation.y;
              this.neckBone.rotation.z = object.rotation.z;
            }
          });
          //      this.headBone.rotation = this.hands.object3D.
        }
      }
    }, 80);
  },
  tick: function () {},
});
