import { SchoolRole } from '../store';
import { avatarPredictions, stopAvatarPredictions } from './predictions';
let schoolRole;
SchoolRole.subscribe(obj => {
  schoolRole = obj;
});

export const updateFacialCapture = () => {
  let now = !schoolRole.useFacialCapture;
  SchoolRole.update(persona => {
    persona.useFacialCapture = now;
    return persona;
  });
  if (now) {
    avatarPredictions();
  } else {
    stopAvatarPredictions();
  }
};
