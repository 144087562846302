import { _ } from 'svelte-i18n';
import { UUID } from '../uuid';
import { defaultRoles } from './roles';
import { get } from 'svelte/store';
import { DateTime } from 'luxon';
import features from '../interfaces/Features';
let defaultFeatures = {};
features.forEach(feature => {
    defaultFeatures[feature.title] = feature.default;
});
const schoolPreset = {
    v1ID: '',
    roles: defaultRoles,
    slug: UUID().replace(/-/g, ''),
    backgroundColorContent: '#17435e',
    backgroundColorFrame: '#002038',
    eventCategories: [],
    articleCategories: [],
    productCategories: [],
    assignmentCategories: [],
    attendanceCreditViews: [],
    eventViews: [],
    subscriberEventViews: [],
    formOutputTemplates: [],
    subscriptionPlan: {
        id: 1,
        title: 'Openwater',
    },
    chatrooms: [],
    customFields: {
        admin: [],
        manager: [],
        subscriber: [],
        article: [],
        event: [],
        product: [],
        attendanceCreditSummary: [],
        attendance: [],
    },
    features: defaultFeatures,
    hourNames: [
        {
            title: get(_)('preset.FirstHour'),
            startAt: '08:30:00',
            endAt: '09:20:00',
        },
        {
            title: get(_)('preset.SecondHour'),
            startAt: '09:30:00',
            endAt: '10:20:00',
        },
        {
            title: get(_)('preset.ThirdHour'),
            startAt: '10:30:00',
            endAt: '11:20:00',
        },
    ],
    intervals: [
        {
            id: UUID(),
            title: get(_)('preset.DefaultTerm'),
            startOn: DateTime.now().toString(),
            endOn: DateTime.now()
                .plus({
                years: 1,
            })
                .toString(),
            customFields: {
                grade: [],
            },
            programCategories: [
                {
                    id: UUID(),
                    slug: 'plan',
                    title: get(_)('preset.Plan'),
                    useToGroupUser: true,
                    useToGroupAdmin: false,
                    useToGroupManager: false,
                    useToGroupSubscriber: true,
                    useToGroupGuest: false,
                    useToGroupEvent: true,
                    useToGroupArticle: true,
                    useToGroupAssignment: false,
                    useToGroupGrades: false,
                    groups: [
                        {
                            id: UUID(),
                            title: get(_)('preset.DefaultProgramPlanProgram'),
                            children: [
                                {
                                    id: UUID(),
                                    title: get(_)('preset.DefaultProgramPlanChildren'),
                                },
                            ],
                        },
                    ],
                },
                {
                    id: UUID(),
                    slug: 'class',
                    title: get(_)('preset.Class'),
                    useToGroupUser: true,
                    useToGroupAdmin: false,
                    useToGroupManager: false,
                    useToGroupSubscriber: true,
                    useToGroupGuest: false,
                    useToGroupEvent: true,
                    useToGroupAssignment: false,
                    useToGroupGrades: false,
                    groups: [
                        {
                            id: UUID(),
                            title: get(_)('preset.DefaultProgramClassProgram'),
                            children: [],
                        },
                        {
                            id: UUID(),
                            title: get(_)('preset.DefaultProgramClassProgram2'),
                            children: [],
                        },
                    ],
                },
                {
                    id: UUID(),
                    slug: 'subject',
                    title: get(_)('preset.Subject'),
                    useToGroupUser: true,
                    useToGroupAdmin: false,
                    useToGroupManager: true,
                    useToGroupSubscriber: true,
                    useToGroupGuest: false,
                    useToGroupEvent: true,
                    useToGroupArticle: true,
                    useToGroupAssignment: true,
                    useToGroupGrades: true,
                    groups: [
                        {
                            id: UUID(),
                            title: get(_)('preset.DefaultProgramSubjectProgram'),
                            children: [],
                        },
                        {
                            id: UUID(),
                            title: get(_)('preset.DefaultProgramSubjectProgram2'),
                            children: [],
                        },
                    ],
                },
            ],
        },
    ],
};
export default schoolPreset;
