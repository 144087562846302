import { ringHeight, ringRadius } from '../lib/defaultValues';
export default function () {
  let currentFloor = 0;
  let currentAngle = 0;
  let ringLength = ringRadius * 2 * 3.14;
  for (
    let i = 0;
    i < document.getElementById('myDashboard').children.length;
    i++
  ) {
    let itemEl = document
      .getElementById('myDashboard')
      .children.item(i)
      .children.item(0)
      .children.item(0);
    // if(!itemEl) continue
    let itemWidthAngle = itemEl.getAttribute('itemWidthAngle');
    if (!itemWidthAngle) {
      break;
    }

    //console.log(itemWidthAngle)
    let el = document.getElementById('offsetter_' + itemEl.id);
    let targetAngle = currentAngle + itemWidthAngle / 2 - 0.5;
    let posY = currentFloor * ringHeight * 1.1;
    el.children[0].setAttribute('position', ' 0 0 ' + ringRadius);
    el.setAttribute(
      'animation__rotation',
      'property: rotation; to: 0 ' +
        -1 * targetAngle +
        ' 0; loop: false; dur: 500',
    );
    el.setAttribute(
      'animation__position',
      'property: position; to: 0 ' + posY + ' 0;  loop: false; dur: 500',
    );
    currentAngle = targetAngle + itemWidthAngle / 2 - 0.5 + 3;
    //currentAngle = parseFloat(currentAngle) + parseFloat(itemWidthAngle)
    if (currentAngle > 270) {
      currentFloor++;
      currentAngle = 0;
    }
  }
}
