import { TargetEvent } from '../store';
import { ioGet } from '../realtime';
import { DateTime } from 'luxon';

const openEventDetail = async (id, date = null) => {
  let res = await ioGet('getEvent', {
    _id: id,
  });
  console.log(date);
  if (res.recurring) {
    res.clickedDate = date;
  }

  TargetEvent.set(res);

  UIkit.modal('#eventDetail').show();
};
export const openEventEdit = async (id, date = null) => {
  let res = await ioGet('getEvent', {
    _id: id,
  });
  console.log(date);
  if (res.recurring) {
    res.clickedDate = date;
  }

  TargetEvent.set(res);

  UIkit.modal('#editEvent').show();
};
export default openEventDetail;
