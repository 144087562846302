<script>
  import { dashboardGumballTarget } from '../../lib/store.js';
  import { onMount } from 'svelte';
  import GumballMenu from '../aframeBlocks/GumballMenu.svelte';

  let target;
  let ringVisible = false;
  dashboardGumballTarget.subscribe(el => {
    target = el;
    //console.log(target)
    if (target) {
      if (target.getAttribute('sourceType') == 'gltf-model') {
        ringVisible = true;
      } else {
        ringVisible = false;
      }
    }
  });
</script>

<a-entity
  id="dashboardGumball"
  scale="0.3 0.3 0.3"
  original-scale="0.3 0.3 0.3"
>
  <a-entity id="dashboardGumballMenu" look-at="#myCamera">
    <GumballMenu />
  </a-entity>
  {#if ringVisible}
    <a-torus
      id="y-rotation-torus-dashboard"
      color="green"
      segments-radial="4"
      radius-tubular="0.025"
      rotation="90 0 0"
      position="0 -0.6 0"
      disable-look-while-dragging
      transform-controls="prop:rotation; axis:y; position:dashboard"
      radius="0.5"
      material="opacity:0.5;side:double;"
    />
  {/if}
</a-entity>
