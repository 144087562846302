<script lang="ts">
  import { onMount } from 'svelte';
  import { ioGet } from '../../lib/realtime';
  import { School, TargetArticle, SchoolRole } from '../../lib/store';
  import { decodeMath, previewMath } from '../../lib/helpers/decodeMath';
  import { _ } from 'svelte-i18n';
  import Lead from '../UI/Lead.svelte';
  import { DateTime } from 'luxon';

  import Button from '../UI/Button.svelte';
  import ViewersBlock from './ViewersBlock.svelte';
  import RoomButton from './RoomButton.svelte';
  import CategoryLabel from './CategoryLabel.svelte';
  import PublicFrom from './PublicFrom.svelte';
  import CustomFieldEditOrDisplay from './CustomFieldComponents/CustomFieldEditOrDisplay.svelte';
  import type { ArticleInterface } from '../../lib/interfaces/Articles';
  import { showModal } from '../../actions/modalController';
  import { defineIfUndefined } from '../../lib/helpers/defineIfUndefined';
  import Comment from './Comment.svelte';
  import { UUID } from '../../lib/uuid';
  import dialog from '../../lib/helpers/dialog';
  import MetaText from '../UI/MetaText.svelte';
  import ArticleHeart from './ArticleHeart.svelte';
  import CustomFieldDisplay from './CustomFieldComponents/CustomFieldDisplay.svelte';
  export let article: ArticleInterface;
  export let noPersona: boolean = false;
  let articleSchool = null;
  let newCommentBody = '';
  onMount(async () => {
    if (!article) return;
    articleSchool = await ioGet('getSchool', {
      _id: article.schoolID,
    });
    console.log('articleSchool', articleSchool);
    article.comments = defineIfUndefined(article.comments, []);
    newCommentBody = '';
  });

  const editArticle = () => {
    TargetArticle.set(article);
    console.log(article);
    showModal('#editArticle');
  };
  const sendNewComment = async () => {
    let comment = {
      id: UUID(),
      personaID: $SchoolRole.id,
      body: newCommentBody,
      createdAt: DateTime.now().toISO(),
    };
    article.comments = [...article.comments, comment];
    let res = await ioGet('updateArticle', {
      _id: article._id,
      data: {
        comments: article.comments,
      },
    });
    newCommentBody = '';
  };
  const saveHeart = async () => {
    let res = await ioGet('updateArticle', {
      _id: article._id,
      data: {
        favorites: article.favorites,
      },
    });
  };
</script>

{#if article && (($School && $SchoolRole) || noPersona)}
  <div uk-grid class="uk-grid-divider uk-grid-small uk-width-expand">
    <div class="">
      <div uk-margin>
        <div class="uk-position-relative">
          <div uk-grid>
            {#if article.iconImageURL}
              <div>
                <img
                  alt="icon"
                  src={article.iconImageURL || '/images/no_photo.jpg'}
                  style="height:80px;width:80px;"
                  class="uk-border-rounded"
                />
              </div>
            {/if}
            <div>
              <Lead>
                {article.title}
              </Lead>
              {#if noPersona}
                <PublicFrom schoolID={article.schoolID} />
              {:else if article.isPublic && article.schoolID != $School._id}
                <PublicFrom schoolID={article.schoolID} />
              {/if}
            </div>
          </div>
          {#if article.categoryLabel && article.categoryLabel != 'undefined'}
            <CategoryLabel entity={article} />
          {/if}
          {#if !noPersona}
            {#if $School.roles.find(role => role.id == $SchoolRole.roleID)?.school.article.canEdit && !(article.isPublic && article.schoolID != $School._id)}
              <div class="uk-position-top-right">
                <Button onclick={editArticle}>{$_('Edit')}</Button>
              </div>
            {/if}
          {/if}
        </div>
        <hr />

        <div uk-grid>
          <div class="uk-position-relative uk-width-expand">
            <dl class="uk-description-list">
              <dt>
                <span class="">
                  {$_('event.Date')}
                </span>
              </dt>
              <dd>
                {DateTime.fromISO(article.publishAt).toLocaleString(
                  DateTime.DATETIME_SHORT,
                )}
              </dd>
            </dl>
          </div>
          {#if article.roomID && article.roomID != 'undefined'}
            <div>
              <dl class="uk-description-list">
                <dt>
                  <span class="">
                    {$_('Room')}
                  </span>
                </dt>
                <dd>
                  {#if noPersona}
                    <RoomButton
                      roomID={article.roomID}
                      asGuest={article.isPublic}
                    />
                  {:else}
                    <RoomButton
                      roomID={article.roomID}
                      asGuest={article.isPublic &&
                        article.schoolID != $SchoolRole.schoolID}
                    />
                  {/if}
                </dd>
              </dl>
            </div>
          {/if}
        </div>
        {#if !noPersona}
          <hr />
          {#if article.filterCategories.length > 0 && !(article.isPublic && article.schoolID != $SchoolRole.schoolID)}
            <Lead>{$_('event.Viewers')}</Lead>
            <ViewersBlock entity={article} />
            <hr />
          {/if}
        {/if}
        <div class="mathPreview">
          {@html decodeMath(article.description)}
        </div>
        {#if articleSchool}
          {#each articleSchool.customFields?.article || [] as field}
            {#if noPersona}
              <CustomFieldDisplay
                {field}
                bind:value={article.customFieldValues[field.id]}
                fields={articleSchool.customFields?.article}
                values={article.customFieldValues}
              />
            {:else}
              <CustomFieldEditOrDisplay
                fieldSchool={articleSchool}
                {field}
                bind:value={article.customFieldValues[field.id]}
                fields={articleSchool.customFields?.article}
                values={article.customFieldValues}
              />
            {/if}
          {/each}
        {/if}
      </div>
    </div>
    <div>
      <ArticleHeart save={saveHeart} bind:favorites={article.favorites} />
      <MetaText>
        {$_('Comments')}
        (
        {article.comments?.length || '0'}
        )
      </MetaText>
      <ul class="uk-list uk-list-divider">
        {#each article.comments || [] as comment}
          <li>
            <Comment {comment} />
          </li>
        {/each}
      </ul>
      {#if !noPersona}
        <div class="uk-margin">
          <textarea class="uk-textarea" bind:value={newCommentBody} />
          <Button onclick={sendNewComment}>{$_('Send')}</Button>
        </div>
      {/if}
    </div>
  </div>
{/if}
