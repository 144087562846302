<script>
  import { _ } from 'svelte-i18n';
  import { TargetEvent, School, SchoolRole } from '../../lib/store';

  import EventDetail from '../blocks/EventDetail.svelte';
  import EventEdit from '../blocks/EventEdit.svelte';
  import Button from '../UI/Button.svelte';
  import Modal from '../UI/Modal.svelte';
  import { DateTime } from 'luxon';
  import { ioGet } from '../../lib/realtime';
  import { UUID } from '../../lib/uuid';
  import fixEventDate from '../../lib/helpers/fixEventDate';
  import dialog from '../../lib/helpers/dialog';
  import CalendarController from '../../actions/calendarController';
  import { MeshBasicMaterial, Uint8ClampedAttribute } from 'three';
  import Dropdown from '../UI/Dropdown.svelte';
  import RoleSelectOption from '../blocks/RoleSelectOption.svelte';
  import areYouSure from '../../lib/helpers/areYouSure';
  import previewViewers from '../../lib/helpers/previewViewers';
  import copy from '../../lib/helpers/copy';
  import createCategory from '../../lib/helpers/createCategory';
  import { updateEvent } from '../../lib/helpers/updateEvent';
  import { affectPresetToEvent } from '../../lib/helpers/affectPresetToEvent';
  import { hideModal } from '../../actions/modalController';
  import LinkToDocumentation from '../blocks/LinkToDocumentation.svelte';
  let eventToCreate = null;
  TargetEvent.subscribe(obj => {
    if (obj) {
      if (typeof obj.customFieldValues == 'undefined') {
        obj.customFieldValues = {};
      }
      if (typeof obj.filterCategories == 'undefined') obj.filterCategories = [];
      if (obj.useDeadlineDate) {
        obj.canAttend = obj.attendanceDeadline >= DateTime.now().toISODate();
        obj.actualAttendanceDeadline = obj.attendanceDeadline;
      } else if (obj.attendanceDeadlineDayCount) {
        obj.actualAttendanceDeadline = DateTime.fromISO(obj.startDate)
          .minus({
            days: obj.attendanceDeadlineDayCount,
          })
          .toISODate();
      } else {
        obj.actualAttendanceDeadline = obj.startDate;
      }
      console.log(obj.actualAttendanceDeadline);
      obj.canAttend =
        obj.actualAttendanceDeadline >= DateTime.now().toISODate();
      if (typeof obj.attendances == 'undefined') obj.attendances = [];
      obj.attendanceCount = 0;
      if (typeof obj.attendanceSettings == 'undefined')
        obj.attendanceSettings = [];
      obj.attendanceSettings.forEach(setting => {
        if (typeof setting.attendances == 'undefined') setting.attendances = [];
        setting.attendances = setting.attendances.map(attendance => {
          if (typeof attendance.customFieldValues == 'undefined')
            attendance.customFieldValues = {};
          return attendance;
        });
      });

      eventToCreate = obj;
    }
  });
  const deleteEvent = async () => {
    areYouSure(eventToCreate.title, async () => {
      let res = await ioGet('deleteEvent', {
        _id: eventToCreate._id,
      });
      console.log(res);
      hideModal('#editEvent');
      CalendarController.deleteEvent(eventToCreate);
    });
  };
  const createEvent = async () => {
    console.log(eventToCreate);

    eventToCreate.schoolID = $School._id;
    if (eventToCreate.recurring) {
      let firstDate = eventToCreate.startDate;
      let rrule = eventToCreate.rrule;
      let data = [];
      let dates = [];
      for (let i = 0; i < 365; i++) {
        let today = DateTime.fromISO(firstDate).plus({
          days: i,
        });
        if (today.toISODate() > rrule.until) {
          break;
        }
        if (rrule.byweekday.indexOf(today.toFormat('E') - 1) > -1) {
          dates.push(today.toISODate());
        }
      }
      for await (let date of dates) {
        let newEvent = copy(eventToCreate);
        newEvent.startDate = date;
        newEvent.recurring = false;
        let res = fixEventDate(newEvent);
        if (!res) return;
        newEvent = res;
        console.log(newEvent);
        let viewerRes = await previewViewers(newEvent);
        if (!viewerRes) break;
        let createdEvent = await ioGet('createEvent', {
          data: newEvent,
        });
        CalendarController.addEvent(createdEvent);
      }
    } else {
      let res = fixEventDate(eventToCreate);
      if (!res) return;
      eventToCreate = res;
      let viewerRes = await previewViewers(eventToCreate);
      if (!viewerRes) return;
      var createdEvent = await ioGet('createEvent', {
        data: eventToCreate,
      });
      CalendarController.addEvent(createdEvent);
    }
    hideModal('#createEvent');
  };
  const affectPreset = id => {
    let category = $School.eventCategories.find(category => category.id == id);
    eventToCreate = affectPresetToEvent(category, eventToCreate);
  };
</script>

{#if $School && $SchoolRole}
  <Modal
    id="eventDetail"
    withFooter
    size="container"
    title={$_('event.EventDetail')}
  >
    <div slot="body">
      <EventDetail bind:schoolEvent={eventToCreate} />
    </div>
    <div slot="footer">
      <Button onclick={() => updateEvent(eventToCreate)}>{$_('Save')}</Button>
    </div>
  </Modal>
  <Modal
    bgClose={false}
    withFooter
    id="createEvent"
    size="container"
    title={$_('event.CreateEvent')}
  >
    <div slot="body">
      <a class="uk-button uk-button-default" href={'#'}
        >{$_('event.SelectCategory')}</a
      >

      <Dropdown>
        {#each $School.eventCategories as category}
          <li value={category.id}>
            <a
              href={'#'}
              on:click={() => {
                affectPreset(category.id);
              }}
            >
              {category.eventPresets.categoryLabel}
            </a>
          </li>
        {/each}
      </Dropdown>
      {#if $School.roles.find(schoolRole => schoolRole.id == $SchoolRole.roleID)?.school.master.canCreate}
        <Button onclick={() => createCategory(eventToCreate, 'event')}
          >{$_('CreateCategory')}</Button
        >
      {/if}
      <hr />
      {#if eventToCreate}
        <EventEdit recurring={true} bind:schoolEvent={eventToCreate} />
      {/if}
    </div>
    <div slot="footer">
      <Button
        onclick={() => {
          hideModal('#createEvent');
        }}>{$_('Cancel')}</Button
      >
      <Button onclick={createEvent}>{$_('Create')}</Button>
    </div>
  </Modal>
  <Modal
    bgClose={false}
    withFooter
    id="editEvent"
    size="container"
    title={$_('event.EditEvent')}
  >
    <div slot="body">
      <a class="uk-button uk-button-default" href={'#'}
        >{$_('event.SelectCategory')}</a
      >
      <Dropdown>
        {#each $School.eventCategories as category}
          <li value={category.id}>
            <a
              href={'#'}
              on:click={() => {
                affectPreset(category.id);
              }}
            >
              {category.eventPresets.categoryLabel}
            </a>
          </li>
        {/each}
      </Dropdown>
      {#if $School.roles.find(schoolRole => schoolRole.id == $SchoolRole.roleID)?.school.master.canCreate}
        <Button onclick={() => createCategory(eventToCreate, 'event')}
          >{$_('CreateCategory')}</Button
        >
      {/if}
      <hr />
      {#if eventToCreate}
        <EventEdit bind:schoolEvent={eventToCreate} />
      {/if}
    </div>
    <div slot="footer">
      <Button
        onclick={() => {
          hideModal('#editEvent');
        }}>{$_('Cancel')}</Button
      >

      <Button onclick={() => updateEvent(eventToCreate)}>{$_('Save')}</Button>
      {#if $School.roles.find(schoolRole => schoolRole.id == $SchoolRole.roleID)?.school.event?.canDelete}
        <Button style="danger" onclick={deleteEvent}>{$_('Delete')}</Button>
      {/if}
    </div>
  </Modal>
{/if}
