import { fetchFormResponses } from '../../state/action-creators/formActionCreator';
import responsesStore from '../../state/stores/responsesStore';
import { School, TargetPersona } from '../store';
import dialog from './dialog';
import Loading from './loading';
import { _ } from 'svelte-i18n';
import { get } from 'svelte/store';
let school = null;
School.subscribe(obj => {
  school = obj;
});
let responses;
responsesStore.subscribe(obj => {
  responses = obj;
});
let targetPersona;
TargetPersona.subscribe(obj => {
  targetPersona = obj;
});
export const getFormScore = async (field, parentFields, parentValues) => {
  Loading.show();
  let parentField = parentFields.find(f => f.id == field.options.target);
  let res = await fetchFormResponses(
    parentValues[parentField.id].formData.formId,
  );
  let studentID =
    targetPersona.customFieldValues[
      parentValues[parentField.id].customFieldForStudentID
    ]?.value;
  Loading.hide();
  if (studentID) {
    let response = res
      .filter(response => response.studentId == studentID)
      .pop();
    return parseFloat(response.answer.split('/').shift().trim());
  } else {
    dialog(get(_)('FormNotSet'));
    return 0;
  }
};
