<script lang="ts">
  import Button from '../../UI/Button.svelte';
  import { _ } from 'svelte-i18n';
  import arraySwap from '../../../lib/helpers/arraySwap';
  import { UUID } from '../../../lib/uuid';
  import CustomFieldEditRow from './CustomFieldEditRow.svelte';
  import areYouSure from '../../../lib/helpers/areYouSure';
  import { CustomFieldDefault } from '../../../lib/interfaces/CustomField';
  import type { CustomFieldInterface } from '../../../lib/interfaces/CustomField';
  import { School } from '../../../lib/store';
  import { setContext } from 'svelte';
  import { FileLoader } from 'three';
  import copy from '../../../lib/helpers/copy';
  export let save: () => void;
  export let category: string;
  export let customFields;
  export let parentFields: boolean | Array<CustomFieldInterface> = false;
  export let showShowOnBadge: boolean = false;
  export let withGuest: boolean = false;
  setContext('parentFields', parentFields);
  const createNewField = () => {
    console.log(CustomFieldDefault);
    let permissions = {};
    $School.roles.forEach(role => {
      permissions[role.id] = {
        canRead: true,
        canEdit: false,
      };
    });
    console.log(customFields);
    customFields = [
      ...customFields,
      copy({
        ...CustomFieldDefault,
        id: UUID(),
        category: category,
        permissions: permissions,
      }),
    ];
    //save()
  };
  const moveUp = (i: number) => {
    customFields = arraySwap(customFields, i, i - 1);
    console.log(customFields);
  };
  const moveDown = (i: number) => {
    customFields = arraySwap(customFields, i, i + 1);
  };
  const remove = (i: number) => {
    areYouSure(customFields[i].title, () => {
      customFields.splice(i, 1);
      customFields = customFields;
    });
  };
</script>

<ul class="uk-list uk-list-divider uk-list-small">
  {#each customFields || [] as field, i}
    <li>
      <CustomFieldEditRow
        {save}
        {withGuest}
        {showShowOnBadge}
        fields={customFields}
        bind:field
        {moveUp}
        {moveDown}
        {remove}
        {i}
        length={customFields.length}
      />
    </li>
  {/each}
</ul>
<Button onclick={createNewField}>{$_('customFields.CreateNewField')}</Button>
