<script>
  import { onMount } from 'svelte';
  import { ioGet } from '../../lib/realtime';
  import { School, TargetProduct, SchoolRole } from '../../lib/store';
  import { _ } from 'svelte-i18n';
  import Lead from '../UI/Lead.svelte';
  import { DateTime } from 'luxon';

  import Button from '../UI/Button.svelte';
  import AttendanceButtonGroup from './AttendanceButtonGroup.svelte';
  let attendanceStatus = '';
  let product;
  import PersonaBlock from './PersonaBlock.svelte';
  import Input from '../UI/Input.svelte';
  import RoleSelectOption from './RoleSelectOption.svelte';
  import ViewersBlock from './ViewersBlock.svelte';
  import RoomButton from './RoomButton.svelte';
  import CategoryLabel from './CategoryLabel.svelte';
  import PublicFrom from './PublicFrom.svelte';
  import CustomFieldEditOrDisplay from './CustomFieldComponents/CustomFieldEditOrDisplay.svelte';
  import { defineIfUndefined } from '../../lib/helpers/defineIfUndefined';
  import { decodeMath, previewMath } from '../../lib/helpers/decodeMath';

  TargetProduct.subscribe(obj => {
    if (obj) {
      obj.prices = defineIfUndefined(obj.prices, []);
      if (typeof obj.customFieldValues == 'undefined') {
        obj.customFieldValues = {};
      }
      product = obj;
      previewMath();
    }
  });
  const editProduct = () => {
    TargetProduct.set(product);
    console.log(product);
    UIkit.modal('#editProduct').show();
  };
</script>

{#if product && $School && $SchoolRole}
  <div uk-margin>
    <div class="uk-position-relative">
      <div uk-grid>
        {#if product.iconImageURL}
          <div>
            <img
              alt="icon"
              src={product.iconImageURL || '/images/no_photo.jpg'}
              style="height:80px;width:80px;"
              class="uk-border-rounded"
            />
          </div>
        {/if}
        <div>
          <Lead>
            {product.title}
          </Lead>
          {#if product.isPublic && product.schoolID != $School._id}
            <PublicFrom schoolID={product.schoolID} />
          {/if}
        </div>
      </div>
      {#if product.categoryLabel && product.categoryLabel != 'undefined'}
        <CategoryLabel entity={product} />
      {/if}
      {#if $School.roles.find(role => role.id == $SchoolRole.roleID)?.school.product?.canEdit && !(product.isPublic && product.schoolID != $School._id)}
        <div class="uk-position-top-right">
          <Button onclick={editProduct}>{$_('Edit')}</Button>
        </div>
      {/if}
    </div>
    <hr />
    {$_('product.Prices')}
    <ul class="uk-list">
      {#each product.prices as price}
        <li>
          <div uk-grid>
            <div>
              {price.price}
            </div>
            <div>
              {price.currency}
            </div>
          </div>
        </li>
      {/each}
    </ul>
    <hr />

    <div uk-grid>
      <div class="uk-position-relative uk-width-expand">
        <dl class="uk-description-list">
          <dt>
            <span class="uk-light">
              {$_('event.Date')}
            </span>
          </dt>
          <dd>
            {DateTime.fromISO(product.startDate).toLocaleString(
              DateTime.DATETIME_SHORT,
            )}
          </dd>
        </dl>
      </div>
    </div>
    <hr />
    {#if product.filterCategories.length > 0 && !(product.isPublic && product.schoolID != $SchoolRole.schoolID)}
      <Lead>{$_('event.Viewers')}</Lead>
      <ViewersBlock entity={product} />
      <hr />
    {/if}
    {#if product.description}
      <div class="mathPreview">
        {@html decodeMath(product.description)}
      </div>
    {/if}
    {#each $School.customFields?.product || [] as field}
      <CustomFieldEditOrDisplay
        {field}
        bind:value={product.customFieldValues[field.id]}
      />
    {/each}
  </div>
{/if}
