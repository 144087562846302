import { get } from 'svelte/store';
import { _ } from 'svelte-i18n';
import { HOST } from '../config';
const Environments = [
  {
    id: 0,
    url: '',
    scale: 1,
    title: get(_)('roomSetting.BlackEnvironment'),
    navMeshURL: '',
    previewDistance: 35,
  },
  {
    id: 1,
    url: HOST + '/models/all_wo_doors.glb',
    scale: 1,
    title: get(_)('roomSetting.DefaultSchool'),
    navMeshURL: HOST + '/models/navmesh.glb',
    previewDistance: 35,
  },
  {
    id: 2,
    url: HOST + '/models/10x10ground.glb',
    scale: 1,
    title: get(_)('roomSetting.10x10Ground'),
    navMeshURL: HOST + '/models/10x10ground.glb',
    previewDistance: 10,
  },
];
export default Environments;
