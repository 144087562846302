import { _ } from 'svelte-i18n';
import { writable } from 'svelte/store';
import { io } from '../../lib/realtime';
import { UUID } from '../../lib/uuid';

const roomElementsReducer = writable([]);
const roomAssetsReducer = writable([]);

let roomElements;
roomElementsReducer.subscribe(array => {
  roomElements = array;
});
let roomAssets;
roomAssetsReducer.subscribe(array => {
  roomAssets = array;
});

export const roomAssetsStore = {
  subscribe: roomAssetsReducer.subscribe,
  add: function (item) {
    if (typeof item == 'undefined') return;
    const newItem = {
      ...item,
    };
    if (!newItem.id) {
      newItem.id = newItem.uid || newItem._id || UUID();
    }
    if (!this.get(newItem.id)) {
      roomAssetsReducer.update(items => {
        return [...items, newItem];
      });
    }
  },

  set: function (items) {
    roomAssetsReducer.set(items);
  },
  get: id => {
    return roomAssets.find(item => item.id == id);
  },
  list: function () {
    return roomAssets;
  },
  delete: function (id) {
    this.set(roomAssets.filter(item => item.id != id));
  },
  clear: () => {
    roomAssetsReducer.set([]);
  },
};
const addAssetProperties = item => {
  if (item.assetID) {
    let asset = roomAssetsStore.get(item.assetID);
    item = {
      ...item,
      fileType: asset.fileType,
      url: asset.url,
    };
  }
  return item;
};

export const roomElementsStore = {
  roomID: '',
  subscribe: roomElementsReducer.subscribe,
  add: function (item) {
    if (typeof item == 'undefined') return;
    let newItem = {
      ...item,
    };
    if (!newItem.id) {
      newItem.id = newItem._id || newItem.uid || UUID();
    }
    newItem = addAssetProperties(newItem);

    if (!this.get(newItem.id)) {
      roomElementsReducer.update(items => {
        return [...items, newItem];
      });
    } else {
      //exists =>update
      roomElementsReducer.update(items => {
        items = items.map(i => {
          if (i.id == item.id) {
            i = {
              ...i,
              ...item,
            };
          }
          return i;
        });
        return items;
      });
    }
  },

  set: function (items) {
    roomElementsReducer.set(items);
  },
  get: id => {
    return roomElements.find(item => item.id == id);
  },
  list: function () {
    return roomElements;
  },
  delete: function (id) {
    roomElementsReducer.update(items => {
      items = items.filter(item => item.id != id);
      return items;
    });
  },
  clear: () => {
    roomElementsReducer.set([]);
  },
};

io.on('roomElementDeleted', res => {
  roomElementsReducer.set(
    roomElements.filter(item => item._id != res.data._id),
  );
});
io.on('roomElementAdded', res => {
  if (
    res.data.group == roomElementsStore.groupID &&
    res.data.assignmentCategoryID == roomElementsStore.assignmentCategoryID
  ) {
    //Notification(get(_)("AdditionDetected", { values: { category: get(_)("roomElements"), title: res.data.title } }));
    roomElementsStore.add(res.data);
  }
});
io.on('roomElementUpdated', res => {
  console.log({ roomElementUpdated: res });
  let updated = false;
  res.data.id = res.data.id || res.data._id || res.data.uid;
  roomElements = roomElements.map(existingItem => {
    if (existingItem.id == res.data.id) {
      updated = true;
      //Notification(get(_)("UpdateDetected", { values: { category: get(_)("roomElements"), title: res.data.title } }));
      return addAssetProperties({
        ...existingItem,
        ...res.data,
      });
    } else {
      return existingItem;
    }
  });
  if (updated) {
    roomElementsReducer.set(roomElements);
  }
});
