import Cookies from 'js-cookie';
import { ioGet } from '../realtime';
import { User } from '../store';
let user;
User.subscribe(obj => {
  user = obj;
});
const enterSchoolAs = async id => {
  await ioGet('activateRole', {
    id: id,
  });
  Cookies.set('role', id);
  let roleRes = user.roles.find(role => role.id == id);
  let school = await ioGet('getSchool', {
    _id: roleRes.schoolID,
  });
  location.href = '/' + school.slug + '/';
};

export default enterSchoolAs;
