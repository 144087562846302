import { ioGet } from '../realtime';
import { School } from '../store';
import { calculateSummaryFieldValue } from './calculateCustomFieldValues';
import { defineIfUndefined } from './defineIfUndefined';
let school;
School.subscribe(obj => {
  school = obj;
});
export const calculateSummaryEachRow = async (
  group,
  assignments,
  assignmentCategory,
  subscriber,
  row,
  submissions,
) => {
  assignmentCategory.submissionSummaryFields = defineIfUndefined(
    assignmentCategory.submissionSummaryFields,
    [],
  );
  if (assignmentCategory.submissionCustomFields.length) {
    assignmentCategory.submissionSummaryFields.forEach(summaryField => {
      row.summaryFieldValues[summaryField.id] = calculateSummaryFieldValue(
        summaryField,
        assignmentCategory.submissionCustomFields,
        assignmentCategory.customFields,
        group.customFieldValues,
        submissions,
      );
    });
    return row;
  } else {
    return;
  }
};
export const calculateAttendanceSummaryEachRow = (
  events,
  view,
  subscriber,
  row,
) => {
  let attendances = [];
  events.forEach(schoolEvent => {
    schoolEvent.attendanceSettings.forEach(setting => {
      if (setting.roleID == subscriber.roleID) {
        setting.attendances.forEach(attendance => {
          if (attendance.schoolRoleID == subscriber.id) {
            if (typeof attendance.customFieldValues == 'undefined')
              attendance.customFieldValues = {};
            attendances.push({
              schoolEvent: schoolEvent,
              attendance: attendance,
            });
          }
        });
      }
    });
  });
  if (typeof view.customFields == 'undefined') view.customFields = [];
  view.customFields.forEach(summaryField => {
    row.summaryFieldValues[summaryField.id] = calculateSummaryFieldValue(
      summaryField,
      school.customFields.attendance,
      school.customFields.event,
      attendances.map(atte => atte.schoolEvent.customFieldValues),
      attendances.map(atte => atte.attendance),
    );
  });
  return row;
};
