<script>
  import { _ } from 'svelte-i18n';
  import { TargetProduct, School, Products, SchoolRole } from '../../lib/store';

  import EventDetail from '../blocks/EventDetail.svelte';
  import EventEdit from '../blocks/EventEdit.svelte';
  import Button from '../UI/Button.svelte';
  import Modal from '../UI/Modal.svelte';
  import { DateTime } from 'luxon';
  import { ioGet } from '../../lib/realtime';
  import { UUID } from '../../lib/uuid';
  import fixEventDate from '../../lib/helpers/fixEventDate';
  import dialog from '../../lib/helpers/dialog';
  import CalendarController from '../../actions/calendarController';
  import ProductEdit from '../blocks/ProductEdit.svelte';
  import ProductDetail from '../blocks/ProductDetail.svelte';
  import ProductController from '../../actions/productController';
  import Dropdown from '../UI/Dropdown.svelte';
  import areYouSure from '../../lib/helpers/areYouSure';
  import previewViewers from '../../lib/helpers/previewViewers';
  import createCategory from '../../lib/helpers/createCategory';
  import { hideModal } from '../../actions/modalController';
  let product = null;
  TargetProduct.subscribe(data => {
    if (data) {
      if (typeof data.customFieldValues == 'undefined') {
        data.customFieldValues = {};
      }
      product = data;
    }
  });
  const updateProduct = async () => {
    console.log(product);

    let viewerRes = await previewViewers(product);
    if (!viewerRes) return;
    let res = await ioGet('updateProduct', {
      _id: product._id,
      data: product,
    });
    console.log(res);
    hideModal('#editProduct');
    let products = await ProductController.list();
    Products.set(products);
  };
  const deleteProduct = async () => {
    areYouSure(product.title, async () => {
      let res = await ioGet('deleteProduct', {
        _id: product._id,
      });
      console.log(res);
      hideModal('#editProduct');
      let products = await ProductController.list();
      Products.set(products);
    });
  };
  const createProduct = async () => {
    console.log(product);
    product.schoolID = $School._id;

    let viewerRes = await previewViewers(product);
    console.log(viewerRes);
    if (!viewerRes) return;
    var result = await ioGet('createProduct', {
      data: product,
    });
    console.log(result);
    hideModal('#createProduct');
    let products = await ProductController.list();
    Products.set(products);
  };

  const affectPreset = id => {
    let category = $School.productCategories.find(
      category => category.id == id,
    );
    console.log('affecting Cagtegory', category);
    Object.keys(category.productPresets).forEach(key => {
      if (key != 'id' && key != '_id' && key != 'startDate') {
        product[key] = category.productPresets[key];
      }
    });
  };
</script>

{#if $School && $SchoolRole}
  <Modal
    id="productDetail"
    size="container"
    title={$_('product.productDetail')}
  >
    <div slot="body">
      <ProductDetail />
    </div>
  </Modal>
  <Modal
    bgClose={false}
    withFooter
    id="createProduct"
    size="container"
    title={$_('product.CreateProduct')}
  >
    <div slot="body">
      <a class="uk-button uk-button-default" href={'#'}
        >{$_('event.SelectCategory')}</a
      >
      <Dropdown>
        {#each $School.productCategories || [] as category}
          <li value={category.id}>
            <a
              href={'#'}
              on:click={() => {
                affectPreset(category.id);
              }}
            >
              {category.productPresets.categoryLabel}
            </a>
          </li>
        {/each}
      </Dropdown>
      {#if $School.roles.find(schoolRole => schoolRole.id == $SchoolRole.roleID)?.school.master.canCreate}
        <Button onclick={() => createCategory(product, 'product')}
          >{$_('CreateCategory')}</Button
        >
      {/if}
      <hr />
      {#if product}
        <ProductEdit bind:product />
      {/if}
    </div>
    <div slot="footer">
      <Button
        onclick={() => {
          hideModal('#createProduct');
        }}>{$_('Cancel')}</Button
      >
      <Button onclick={createProduct}>{$_('Create')}</Button>
    </div>
  </Modal>
  <Modal
    bgClose={false}
    withFooter
    id="editProduct"
    size="container"
    title={$_('product.EditProduct')}
  >
    <div slot="body">
      <a class="uk-button uk-button-default" href={'#'}
        >{$_('event.SelectCategory')}</a
      >
      <Dropdown>
        {#each $School.productCategories || [] as category}
          <li value={category.id}>
            <a
              href={'#'}
              on:click={() => {
                affectPreset(category.id);
              }}
            >
              {category.productPresets.categoryLabel}
            </a>
          </li>
        {/each}
      </Dropdown>
      {#if $School.roles.find(schoolRole => schoolRole.id == $SchoolRole.roleID)?.school.master.canCreate}
        <Button onclick={() => createCategory(product, 'product')}
          >{$_('CreateCategory')}</Button
        >
      {/if}
      <hr />
      {#if product}
        <ProductEdit bind:product />
      {/if}
    </div>
    <div slot="footer">
      <Button
        onclick={() => {
          hideModal('#editProduct');
        }}>{$_('Cancel')}</Button
      >

      <Button onclick={updateProduct}>{$_('Save')}</Button>
      {#if $School.roles.find(schoolRole => schoolRole.id == $SchoolRole.roleID)?.school.product?.canDelete}
        <Button style="danger" onclick={deleteProduct}>{$_('Delete')}</Button>
      {/if}
    </div>
  </Modal>
{/if}
