<script>
  import { _ } from 'svelte-i18n';
  import { navigate, Route, Router } from 'svelte-routing';
  import { capitalize } from '../lib/helpers/capitalize';
  import getPropByDotNotation from '../lib/helpers/getPropByDotNotation';
  import consoleMenu from '../lib/presets/consoleMenu';
  import { School, SchoolRole } from '../lib/store';
  import Articles from './Articles.svelte';
  import AssignmentDetails from './blocks/AssignmentDetails.svelte';
  import Events from './Events.svelte';
  import Files from './Files.svelte';
  import MyGrades from './MyGrades.svelte';
  import MyReports from './MyReports.svelte';
  import SchoolSetting from './SchoolSetting.svelte';
  import SelectRoom from './SelectRoom.svelte';
  import Subscribers from './Subscribers.svelte';

  let focus = location.href
    .split($School.slug + '/')
    .pop()
    .split('/')
    .shift()
    .replace('#', '');
  let schoolRole;
  SchoolRole.subscribe(obj => {
    schoolRole = obj;
  });
</script>

<svelte:head>
  {#if $School['fontIsDarkFor' + capitalize($School?.roles.find(r => r.id == $SchoolRole?.roleID)?.category)]}
    <style>
      .uk-input,
      .uk-modal-dialog,
      .uk-select,
      .uk-textarea,
      .uk-modal-footer,
      .uk-modal-footer > .uk-button-default,
      .uk-modal-header,
      .uk-notification-message,
      .uk-notification-message {
        background-color: #ddd !important;
        color: rgba(0, 0, 0, 0.8) !important;
      }
      .uk-select,
      .uk-button-default,
      .uk-input,
      .uk-textarea {
        border: 1px solid rgba(0, 0, 0, 0.5);
      }
      .uk-hr,
      hr {
        border-top: 1px solid rgba(0, 0, 0, 0.5);
      }
      .uk-modal-header {
        border-bottom: solid 1px rgba(0, 0, 0, 0.5);
      }
      .uk-modal-footer {
        border-top: solid 1px rgba(0, 0, 0, 0.5);
      }
      .uk-grid-divider > :not(.uk-first-column)::before {
        border-left: 1px solid rgba(0, 0, 0, 0.5);
      }
      .uk-tab > * > a {
        color: #666;
      }
      .uk-tab > .uk-active > a {
        color: #222;
      }
    </style>
  {/if}
</svelte:head>

{#if $SchoolRole}
  <div uk-grid class="uk-grid-collapse">
    <div style="max-width:100px">
      <ul class="uk-tab uk-tab-left uk-margin-top">
        {#each consoleMenu as menu}
          {#if schoolRole}
            {#if getPropByDotNotation(schoolRole, menu.requiredPermission) && (menu.requiredFeature == '' || $School.features[menu.requiredFeature]) && (menu.forRoleCategories.length == 0 || menu.forRoleCategories.indexOf($SchoolRole.category) > -1)}
              <li class:uk-active={focus == menu.slug} class="uk-margin-small">
                <a
                  style="display:block;text-align:center"
                  href={'#'}
                  on:click={() => {
                    if (
                      window.location.pathname.includes( `/${$School.slug}/${menu.slug}/` )
                    )
                      return;
                    navigate(`/${$School.slug}/${menu.slug}/`);
                    focus = menu.slug;
                  }}
                >
                  <i class="{menu.icon} fa-fw fa-2x" />

                  <br />
                  {#if $School.consoleLabels}
                    <span class="uk-text-xsmall uk-visible@s"
                      >{$_(
                        $School.consoleLabels[menu.label] || menu.label,
                      )}</span
                    >
                  {:else}
                    <span class="uk-text-xsmall uk-visible@s"
                      >{$_(menu.label)}</span
                    >
                  {/if}
                </a>
              </li>
            {/if}
          {/if}
        {/each}
      </ul>
    </div>
    <div class="uk-width-expand">
      <div
        class="uk-padding-small"
        style:background-color={$School[
          'backgroundColorContent' +
            capitalize(
              $School?.roles.find(r => r.id == $SchoolRole?.roleID)?.category,
            )
        ] ||
          $School.backgroundColorContent ||
          '#3b3b3b'}
        style:border-top-left-radius="6px"
        uk-height-viewport="offset-top:true"
      >
        <Router>
          <Route path="" component={Articles} />
          <Route path="select-room/" component={SelectRoom} />
          <Route path="assignments/*" component={AssignmentDetails} />

          <Route path="articles/*" component={Articles} />
          <Route path="setting/*" component={SchoolSetting} />

          <Route path="events/" component={Events} />
          <Route path="files/*">
            <Files />
          </Route>
          <Route path="subscribers/*" component={Subscribers} />
          <Route path="my-reports/*" component={MyReports} />
          <Route path="my-grades/*" component={MyGrades} />
        </Router>
      </div>
    </div>
  </div>
{/if}

<style>
  input {
    background-color: white;
  }
</style>
