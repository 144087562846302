import prepareEventForCalendar from '../lib/helpers/prepareEventForCalendar';
import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import jaLocale from '@fullcalendar/core/locales/ja';
import Cookies from 'js-cookie';
import openEventDetail from '../lib/helpers/openEventDetail';
import { DateTime } from 'luxon';

import { TargetEvent } from '../lib/store';
import { ioGet } from '../lib/realtime';
import fixEventDate from '../lib/helpers/fixEventDate';
import { School, SchoolRole } from '../lib/store';
import { EventDefault } from '../lib/interfaces/EventInterface';
import { showModal } from './modalController';
let school = null;
let schoolRole = null;
School.subscribe(obj => {
  school = obj;
});
SchoolRole.subscribe(obj => {
  schoolRole = obj;
});

let calendar;
const dragAndResize = async info => {
  console.log(info);
  let schoolEvent = await ioGet('getEvent', {
    _id: info.event.id,
  });
  schoolEvent.startAt = DateTime.fromJSDate(info.event.start).toFormat(
    'HH:mm:00',
  );
  schoolEvent.startTime = DateTime.fromJSDate(info.event.start).toFormat(
    'HH:mm:00',
  );
  schoolEvent.startDate = DateTime.fromJSDate(info.event.start).toISODate();
  if (info.event.end) {
    schoolEvent.endAt = DateTime.fromJSDate(info.event.end).toFormat(
      'HH:mm:00',
    );
    schoolEvent.endTime = DateTime.fromJSDate(info.event.end).toFormat(
      'HH:mm:00',
    );
    if (schoolEvent.allDay) {
      schoolEvent.endDate = DateTime.fromJSDate(info.event.end).toISODate();
    } else {
      if (!schoolEvent.multipleDays) {
        schoolEvent.endDate = DateTime.fromJSDate(info.event.end)
          .minus({ days: 1 })
          .toISODate();
      } else {
        schoolEvent.endDate = DateTime.fromJSDate(info.event.end).toISODate();
      }
    }
  }
  console.log(schoolEvent);
  schoolEvent = fixEventDate(schoolEvent);
  await ioGet('updateEvent', {
    _id: schoolEvent._id,
    data: schoolEvent,
  });
};

const CalendarController = {
  ini: (el, events) => {
    calendar = new Calendar(el, {
      plugins: [dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin],
      now: DateTime.now().toISODate(),
      locales: [jaLocale],
      locale: Cookies.get('locale') == 'ja' ? 'ja' : 'en',
      initialView: 'dayGridMonth',
      headerToolbar: {
        left: 'prev,next today',
        center: 'title',
        right: 'dayGridMonth,timeGridWeek,listWeek',
      },
      displayEventTime: false,
      editable: true,
      events: events,
      eventResize: info => {
        dragAndResize(info);
      },
      eventDrop: async function (info) {
        dragAndResize(info);
      },
      eventClick: function (info) {
        console.log(info);
        openEventDetail(info.event.id, info.event.start);
      },
      dateClick: function (info) {
        if (
          school.roles.find(role => role.id == schoolRole.roleID).school.event
            .canCreate
        ) {
          console.log(info);
          let obj = {
            ...EventDefault,
            startDate: info.dateStr,
            useCustomTime:
              school.features?.useHourNames === false ? true : false,
            startAt: DateTime.now().toFormat('HH:mm:00'),
            endAt: DateTime.now()
              .plus({
                hours: 1,
              })
              .toFormat('HH:mm:00'),
          };

          TargetEvent.set(obj);
          setTimeout(function () {
            showModal('#createEvent');
          }, 500);
        }
      },
    });
    calendar.render();
  },
  updateEvent: schoolEvent => {
    7;
    if (!calendar) return;
    let event = calendar.getEventById(schoolEvent._id);
    schoolEvent = prepareEventForCalendar(schoolEvent);
    event.setDates(schoolEvent.start, schoolEvent.end, {
      allDay: schoolEvent.allDay,
    });
    event.setProp('title', schoolEvent.title);
  },
  addEvent: schoolEvent => {
    if (!calendar) return;
    calendar.addEvent(prepareEventForCalendar(schoolEvent));
  },
  deleteEvent: schoolEvent => {
    let event = calendar.getEventById(schoolEvent._id);
    event.remove();
  },
};
export default CalendarController;
