import Cookies from 'js-cookie';
import { School, User, SchoolRole } from '../store';
import { ioGet } from '../realtime';
import { defineIfUndefined } from './defineIfUndefined';
import { DateTime } from 'luxon';
let school = null;
School.subscribe(obj => {
    school = obj;
});
let user = null;
User.subscribe(obj => {
    user = obj;
});
let schoolRole = null;
const setSchoolRole = async (asGuest = false) => {
    if (asGuest) {
        var matchedRole = school.roles.find((role) => role.category == 'guest');
        schoolRole = await ioGet('getPersona', {
            schoolRoleID: Cookies.get('role'),
        });
        schoolRole.canChangePermissions = matchedRole.canChangePermissions;
        schoolRole.school = matchedRole.school;
        schoolRole.roomPermissionPresets = matchedRole.roomPermissionPresets;
        SchoolRole.set(schoolRole);
        console.log('SchoolRole set as guest', schoolRole);
    }
    else {
        var matchedRole = null;
        schoolRole = user.roles.find(userRole => {
            let match = false;
            school.roles.forEach(role => {
                if (userRole.roleID == role.id && userRole.id == Cookies.get('role')) {
                    match = true;
                    matchedRole = role;
                }
            });
            return match;
        });
        if (matchedRole) {
            schoolRole.title = matchedRole.title;
            schoolRole.category = matchedRole.category;
            schoolRole.canChangePermissions = matchedRole.canChangePermissions;
            schoolRole.school = matchedRole.school;
            schoolRole.roomPermissionPresets = matchedRole.roomPermissionPresets;
            //schoolRole.registrations = matchedRole.registrations || []
            schoolRole.registrations = defineIfUndefined(schoolRole.registrations, []);
            console.log('SchoolRole set');
            let now = DateTime.now().toISO();
            await ioGet('updatePersona', {
                schoolRoleID: schoolRole.id,
                data: {
                    lastLogin: now,
                },
            });
            SchoolRole.set(schoolRole);
            schoolRole.lastLogin = now;
        }
        else {
            console.log('SchoolRole not set');
        }
    }
    if (schoolRole) {
        await ioGet('setSchoolRole', {
            id: schoolRole.id,
        });
        return true;
    }
    else {
        return false;
    }
};
export default setSchoolRole;
