<script>
  import { startWebcam, stopWebcam } from '../../actions/videoController';
  import { _ } from 'svelte-i18n';
  import { faces, menuActions } from '../../lib/defaultValues';
  import assignAnimation from '../../lib/helpers/assignAnimation';
  import {
    myCurrentFace,
    RoomPermissions,
    Room,
    SchoolRole,
    webcamActive,
    flyMode,
  } from '../../lib/store';

  import Button from '../UI/Button.svelte';
  import BottomMenuNav from './BottomMenuNav.svelte';
  import switchCamera from '../../lib/helpers/switchCamera';
  import toggleDashboard from '../../lib/helpers/toggleDashboard';
  import Div from '../UI/Div.svelte';
  import { updateFacialCapture } from '../../lib/helpers/updateFacialCapture';
  import Icon from '../UI/Icon.svelte';
  import MetaText from '../UI/MetaText.svelte';
  import { writable } from 'svelte/store';
  let ifWebcamActive = false;
  webcamActive.subscribe(val => {
    ifWebcamActive = val;
  });
  const changeFace = face => {
    SchoolRole.update(persona => {
      persona.currentFace = face;
      return persona;
    });
  };
</script>

<div
  class="uk-position-center-left dark-translucent-background uk-light uk-border-rounded uk-text-center"
  style="
    padding-right: 0.5em;
    padding-left: 0.5em;
    padding-top: 2em;
    max-height: calc(100vh - 320px);
    max-height: calc(100dvh - 320px);
    overflow: auto;
    margin-top:-120px
    "
>
  <div class="uk-margin">
    <Button
      fullwidth
      style="link"
      onclick={() => {
        updateFacialCapture();
      }}
    >
      <span style:color={$SchoolRole.useFacialCapture ? 'lightgreen' : ''}>
        <Icon size="2x" icon="face-raised-eyebrow" />
      </span>
    </Button>
    <MetaText xsmall>Facial<br />Capture</MetaText>
  </div>
  <hr />
  {#if !$SchoolRole.useFacialCapture}
    {#each faces as face}
      <div class="uk-margin">
        <Button
          fullwidth
          style="link"
          onclick={() => {
            changeFace(face.label);
          }}
        >
          <i class={face.icon} />
        </Button>
      </div>
    {/each}
    <hr />
  {/if}

  {#each menuActions as action}
    <div class="uk-margin">
      <Button
        fullwidth
        style="link"
        onclick={() => {
          assignAnimation(
            document.getElementById('myHands'),
            action.label,
            'idle',
          );
        }}
      >
        <i class={action.icon} />
      </Button>
    </div>
  {/each}
  <hr />
  {#if !ifWebcamActive}
    <div class="uk-margin" id="startWebcamButton">
      <Button fullwidth onclick={startWebcam} style="link">
        <i class="fa-duotone fa-camera-web fa-2x" />
      </Button>
    </div>
  {:else}
    <div class="uk-margin">
      <Button onclick={stopWebcam} fullwidth style="link">
        <span style="color:lightgreen">
          <i class="fa-duotone fa-camera-web fa-2x" />
        </span>
      </Button>
      <MetaText xsmall>Web<br />Camera</MetaText>
    </div>
  {/if}

  <hr />
  <div class="uk-margin">
    <Button
      style="link"
      fullwidth
      onclick={() => {
        switchCamera();
      }}
      small
    >
      <i class="fa-duotone fa-camera-cctv fa-2x" />
    </Button>
    <MetaText xsmall>Camera<br />Position</MetaText>
  </div>
  {#if $Room.permissions.find(permission => permission.id == $SchoolRole.roleID)?.roomPermissionPresets.canFly}
    <hr />
    <div class="uk-margin">
      <Button
        fullwidth
        style="link"
        onclick={() => {
          flyMode.update(now => !now);
          document
            .getElementById($SchoolRole.id)
            .setAttribute('movement-controls', {
              constrainToNavMesh: !$flyMode,
            });
          document
            .getElementById($SchoolRole.id)
            .setAttribute('movement-controls', { fly: $flyMode });
        }}
      >
        <span style:color={$flyMode ? 'lightgreen' : ''}>
          <Icon icon="dove" size="2x" />
        </span>
      </Button>
      <MetaText xsmall>Fly<br />Mode</MetaText>
    </div>
  {/if}
</div>
