<script>
  import { HOST } from '../../lib/config';
</script>

<section
  class="uk-section uk-background-cover uk-dark uk-animation uk-animation-fade"
>
  <div class="uk-container-xsmall uk-margin-auto">
    <slot />
  </div>
</section>
