import { emotePanelVisible } from '../store';
import { hideAFRAME } from '../visibilityAFRAME';
const resetFocus = () => {
  hideAFRAME(document.getElementById('gumball'));
  hideAFRAME(document.getElementById('dashboardGumball'));
  hideAFRAME(document.getElementById('friendMenu'));
  emotePanelVisible.set(false);
  //  hideAFRAME(document.getElementById('myEmotePanel'))
};
export default resetFocus;
