import CalendarController from '../../actions/calendarController';
import { ioGet } from '../realtime';
import fixEventDate from './fixEventDate';
import previewViewers from './previewViewers';
import { _ } from 'svelte-i18n';
import { get } from 'svelte/store';
import { School } from '../store';
import { DateTime } from 'luxon';
import copy from './copy';
import { hideModal } from '../../actions/modalController';
import { Notification } from '../../actions/notification';
let school;
School.subscribe(obj => {
  school = obj;
});
export const updateEvent = async eventToCreate => {
  let res1 = fixEventDate(eventToCreate);
  if (!res1) return;
  eventToCreate = res1;
  let viewerRes = await previewViewers(eventToCreate);
  if (!viewerRes) return;
  console.log(viewerRes);
  let res = await ioGet('updateEvent', {
    _id: eventToCreate._id,
    data: eventToCreate,
  });
  console.log(res);
  hideModal('#editEvent');
  CalendarController.updateEvent(eventToCreate);
  Notification(get(_)('Saved'));
  return eventToCreate;
};
export const createEvent = async eventToCreate => {
  console.log(eventToCreate);

  eventToCreate.schoolID = school._id;
  if (eventToCreate.recurring) {
    let firstDate = eventToCreate.startDate;
    let rrule = eventToCreate.rrule;
    let data = [];
    let dates = [];
    for (let i = 0; i < 365; i++) {
      let today = DateTime.fromISO(firstDate).plus({
        days: i,
      });
      if (today.toISODate() > rrule.until) {
        break;
      }
      if (rrule.byweekday.indexOf(today.toFormat('E') - 1) > -1) {
        dates.push(today.toISODate());
      }
    }
    for await (let date of dates) {
      let newEvent = copy(eventToCreate);
      newEvent.startDate = date;
      newEvent.recurring = false;
      let res = fixEventDate(newEvent);
      if (!res) return;
      newEvent = res;
      console.log(newEvent);
      let viewerRes = await previewViewers(newEvent);
      if (!viewerRes) break;
      let createdEvent = await ioGet('createEvent', {
        data: newEvent,
      });
      CalendarController.addEvent(createdEvent);
    }
  } else {
    let res = fixEventDate(eventToCreate);
    if (!res) return;
    eventToCreate = res;
    let viewerRes = await previewViewers(eventToCreate);
    if (!viewerRes) return;
    var createdEvent = await ioGet('createEvent', {
      data: eventToCreate,
    });
    CalendarController.addEvent(createdEvent);
  }
  hideModal('#createEvent');
  return createdEvent;
};
