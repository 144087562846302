import { hideAFRAME, showAFRAME } from '../visibilityAFRAME';
import resetFocus from './resetFocus';
let dashboardVisible = true;

let y = 0;
const toggleDashboard = () => {
  let dashboard = document.getElementById('myDashboard');
  let dashboardHandle = document.getElementById('myDashboardHandle');
  dashboardVisible = !dashboardVisible;
  if (dashboardVisible) {
    dashboard.object3D.position.y = y;
    resetFocus();
    showAFRAME(dashboard);
    showAFRAME(dashboardHandle);
  } else {
    hideAFRAME(dashboard);
    hideAFRAME(dashboardHandle);
    y = dashboard.object3D.position.y;
    resetFocus();
  }
  return dashboardVisible;
};
export default toggleDashboard;
