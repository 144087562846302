<script lang="ts">
  import { join } from 'path';

  import { _ } from 'svelte-i18n';
  import { UUID } from '../../lib/uuid';

  import Button from '../UI/Button.svelte';
  import Checkbox from '../UI/Checkbox.svelte';
  import Dropdown from '../UI/Dropdown.svelte';
  export let saveView: () => void;
  export let school;
  export let eventViews;
  export let styles = [];
  let categoryLabels = [];
</script>

<Button style="link" onclick={() => {}}>
  <i class="fa-duotone fa-plus fa-fw" />
</Button>
<Dropdown>
  <ul class="uk-list uk-list-small">
    {#each school.eventCategories as eventCategory}
      <li>
        <label>
          <input
            type="checkbox"
            class="uk-checkbox uk-border-rounded"
            bind:group={categoryLabels}
            name={'categoryLabel'}
            value={eventCategory.title}
          />
          {eventCategory?.eventPresets.categoryLabel}
        </label>
      </li>
    {/each}
  </ul>
  {#each styles as style}
    <div class="uk-margin">
      <li class="uk-nav-heading">
        {$_('view.By' + style)}
      </li>
      <Button
        onclick={() => {
          if (typeof eventViews == 'undefined') eventViews = [];
          eventViews.push({
            id: UUID(),
            categoryLabels: categoryLabels,
            title: school.eventCategories
              .filter(category => categoryLabels.indexOf(category.title) > -1)
              .map(category => category.title)
              .join(', '),
            style: 'by' + style,
            customFields: [],
          });
          saveView();
        }}
      >
        {$_('Create')}
      </Button>
    </div>
  {/each}
</Dropdown>
