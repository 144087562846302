<script>
  import { UUID } from '../../lib/uuid';
  import { School } from '../../lib/store';

  import VerticalTab from '../UI/VerticalTab.svelte';
  import EventEdit from './EventEdit.svelte';
  import { onMount } from 'svelte';
  import Button from '../UI/Button.svelte';
  import { _ } from 'svelte-i18n';
  import { ioGet } from '../../lib/realtime';
  import Navbar from '../UI/Navbar.svelte';
  import arraySwap from '../../lib/helpers/arraySwap';
  import ArrayNav from './ArrayNav.svelte';
  import Input from '../UI/Input.svelte';
  import { Notification } from '../../actions/notification';
  let tabId = UUID();
  let school = null;
  School.subscribe(obj => {
    school = obj;
  });
  const savePresets = async schoolEvent => {
    let res = await ioGet('updateSchool', {
      _id: school._id,
      data: {
        eventCategories: school.eventCategories,
      },
    });
    School.set(school);
    Notification($_('Saved'));
  };
  const moveUp = async i => {
    school.eventCategories = arraySwap(school.eventCategories, i, i - 1);
    let res = await ioGet('updateSchool', {
      _id: school._id,
      data: school,
    });
    School.set(school);
    Notification($_('Saved'));
    UIkit.tab('#schoolSettingEventCategories').show(i - 1);
  };
  const moveDown = async i => {
    school.eventCategories = arraySwap(school.eventCategories, i, i + 1);
    let res = await ioGet('updateSchool', {
      _id: school._id,
      data: school,
    });
    School.set(school);
    Notification($_('Saved'));
    UIkit.tab('#schoolSettingEventCategories').show(i + 1);
  };
  const remove = async i => {
    school.eventCategories.splice(i, 1);
    let res = await ioGet('updateSchool', {
      _id: school._id,
      data: school,
    });
    School.set(school);
    Notification($_('Saved'));
    UIkit.tab('#schoolSettingEventCategories').show(i);
  };
</script>

{#if school}
  <VerticalTab>
    <ul
      slot="menu"
      class="uk-tab-left"
      id="schoolSettingEventCategories"
      uk-tab="connect:#schoolSettingEventCategoriesContent; animation: uk-animation-fade"
    >
      {#each school.eventCategories || [] as category}
        <li>
          <a href={'#'}>
            {category.eventPresets.categoryLabel}
          </a>
        </li>
      {/each}
    </ul>
    <ul
      slot="content"
      id="schoolSettingEventCategoriesContent"
      class="uk-switcher"
    >
      {#each school.eventCategories?.map(category => category.eventPresets) || [] as schoolEvent, i}
        <li>
          <Input type="text" bind:value={schoolEvent.categoryLabel} />
          <ArrayNav
            arrayLength={school.eventCategories.length}
            {i}
            {moveUp}
            {moveDown}
            {remove}
          />
          {#if schoolEvent}
            <EventEdit {schoolEvent} presetEditMode="true" />
          {/if}
          <Button onclick={() => savePresets(schoolEvent)}>{$_('Save')}</Button>
        </li>
      {/each}
    </ul>
  </VerticalTab>
{/if}
