/**
 * Touch-to-move-forward controls for mobile.
 */
module.exports = AFRAME.registerComponent('my-touch-controls', {
  schema: {
    enabled: { default: true },
    reverseEnabled: { default: true },
  },

  init: function () {
    this.dVelocity = new THREE.Vector3();
    this.bindMethods();
    this.direction = 0;
  },

  play: function () {
    this.addEventListeners();
  },

  pause: function () {
    this.removeEventListeners();
    this.dVelocity.set(0, 0, 0);
  },

  remove: function () {
    this.pause();
  },

  addEventListeners: function () {
    const sceneEl = this.el.sceneEl;
    const canvasEl = sceneEl.canvas;

    document
      .getElementById('navUp')
      .addEventListener('touchstart', this.onTouchStart);
    document
      .getElementById('navUp')
      .addEventListener('mouseenter', this.onTouchStart);
    document
      .getElementById('navDown')
      .addEventListener('touchstart', this.onTouchStartBack);
    document
      .getElementById('navDown')
      .addEventListener('mouseenter', this.onTouchStartBack);
    document
      .getElementById('navUp')
      .addEventListener('touchend', this.onTouchEnd);
    document
      .getElementById('navUp')
      .addEventListener('mouseleave', this.onTouchEnd);
    document
      .getElementById('navDown')
      .addEventListener('touchend', this.onTouchEnd);
    document
      .getElementById('navDown')
      .addEventListener('mouseleave', this.onTouchEnd);
    if (!canvasEl) {
      sceneEl.addEventListener(
        'render-target-loaded',
        this.addEventListeners.bind(this),
      );
      return;
    }
    //canvasEl.addEventListener('touchstart', this.onTouchStart);
    //    canvasEl.addEventListener('touchend', this.onTouchEnd);
  },

  removeEventListeners: function () {
    const canvasEl = this.el.sceneEl && this.el.sceneEl.canvas;
    if (!canvasEl) {
      return;
    }

    canvasEl.removeEventListener('touchstart', this.onTouchStart);
    canvasEl.removeEventListener('touchend', this.onTouchEnd);
  },

  isVelocityActive: function () {
    return this.data.enabled && !!this.direction;
  },

  getVelocityDelta: function () {
    this.dVelocity.z = this.direction;
    return this.dVelocity.clone();
  },

  bindMethods: function () {
    this.onTouchStartBack = this.onTouchStartBack.bind(this);
    this.onTouchStart = this.onTouchStart.bind(this);
    this.onTouchEnd = this.onTouchEnd.bind(this);
  },

  onTouchStart: function (e) {
    e.preventDefault();
    this.direction = -1;
    /*
    if (this.data.reverseEnabled && e.touches.length === 2) {
      this.direction = 1;
    }
    */
  },
  onTouchStartBack: function (e) {
    e.preventDefault();

    this.direction = 1;
  },

  onTouchEnd: function (e) {
    e.preventDefault();
    this.direction = 0;
  },
});
