import { FriendAvatar } from '../Classes/User';
import { SchoolRole, User } from './store';
import assignAnimation from './helpers/assignAnimation';
import moveMouth from './helpers/moveMouth';
import { moveFace } from './helpers/moveFace';
import { roomElementsStore } from '../state/stores/roomElementsStore';
import { personasStore } from '../state/stores/personasStore';
let user;
User.subscribe(obj => {
  user = obj;
});
let schoolRole;
SchoolRole.subscribe(obj => {
  schoolRole = obj;
});

const broadcaseMeResponse = data => {
  if (!document.querySelector('a-scene')) return; //the scene no longer exists
  var friendEl = document.getElementById(data.id);
  //console.log({ action: `broadcastMe fron ${data.nickname} received`, data });
  roomElementsStore.add({
    ...data,
    type: 'user',
  });
  if (!friendEl) {
    if (data.id == schoolRole.id) {
      return;
    }
    console.log('friend data', data);
    let friendAvatar = new FriendAvatar(data);
    friendAvatar.append(scene);
    var friendEl = friendAvatar.el;
    friendEl.setAttribute('fade-my-voice', { enabled: true });
  } else {
    //console.log('friend exists')
  }
  if (!data.height) data.height = 1.6;
  friendEl.setAttribute('position', {
    x: data.position.x,
    y: parseFloat(data.position.y) + data.height,
    z: data.position.z,
  });
  friendEl.setAttribute('rotation', {
    x: data.rotation.x,
    y: data.rotation.y,
    z: data.rotation.z,
  });
  //pm2 restartpersonasStore.add(data);
  //console.log("friendData", data);

  friendEl.setAttribute('isMutable', data.isMutable);

  //console.log(data)
  let model = document.getElementById('myAvatar_' + data.id)?.object3D;
  if (!model) return;
  if (model.children.length) {
    var head;
    var neck;
    model.traverse(object => {
      if (object.name == 'Wolf3D_Avatar') {
        head = object;
      }
      if (object.name == 'Head') {
        neck = object;
      }
    });
    if (data.useFacialCapture) {
      moveFace(head, neck, data.faceShape, data.faceRotation, true);
    } else {
      moveMouth(head, data.volume, data.face);
    }
  }
  let friendAvatarModelEl = document.getElementById('myAvatar_' + data.id);
  if (friendAvatarModelEl.children[0]) {
    let radian = data.rotation.x * (Math.PI / 180);
    friendAvatarModelEl.children[0].children[0].children[0].children[0].children[0].children[0].rotation.x =
      radian;
  }
  if (typeof data.action != 'undefined' && data.action) {
    let friendHands = document.getElementById('myHands_' + data.id);
    if (
      friendHands &&
      typeof friendHands.getAttribute('animation-mixer') != 'undefined'
    ) {
      if (friendHands.getAttribute('animation-mixer').clip != data.action) {
        assignAnimation(
          friendHands,
          data.action,
          data.action == 'walk' ? null : 'idle',
        );
      }
    }
  }
};
export default broadcaseMeResponse;
