<script lang="ts">
  import { _ } from 'svelte-i18n';
  import { FileLoader } from 'three';
  import dialog from '../../lib/helpers/dialog';
  import { ioGet } from '../../lib/realtime';
  import { School } from '../../lib/store';
  import { UUID } from '../../lib/uuid';
  import Button from '../UI/Button.svelte';
  import Input from '../UI/Input.svelte';
  import Modal from '../UI/Modal.svelte';
  import IntervalsTab from './IntervalsTab.svelte';
  import ProgramDetailRow from './ProgramDetailRow.svelte';
  import ProgramDetailTheadRow from './ProgramDetailTheadRow.svelte';

  let school = null;
  School.subscribe(obj => {
    if (obj) {
      obj.intervals = obj.intervals.map(interval => {
        interval.programCategories = interval.programCategories.map(
          category => {
            if (typeof category.customFields == 'undefined') {
              category.customFields = [];
            }
            if (typeof category.childCustomFields == 'undefined') {
              category.childCustomFields = [];
            }
            category.groups = category.groups.map(group => {
              if (typeof group.customFieldValues == 'undefined') {
                group.customFieldValues = {};
              }
              group.children = group.children.map(child => {
                if (typeof child.customFieldValues == 'undefined') {
                  child.customFieldValues = {};
                }
                return child;
              });
              return group;
            });
            return category;
          },
        );
        return interval;
      });
      school = obj;
    }
  });
  const saveCustomField = async () => {
    let res = await ioGet('updateSchool', {
      _id: $School._id,
      data: school,
    });
    School.set(school);
    dialog($_('Saved'));
  };
  const deleteChildCustomField = i => {};
  const createNewField = () => {};
</script>

<IntervalsTab
  menu={$School.features.useIntervals}
  intervals={$School.intervals}
>
  <ul slot="content" class="uk-switcher">
    {#each school.intervals as interval}
      <li>
        <ul uk-tab="animation:uk-animation-fade">
          {#each interval.programCategories as category}
            <li>
              <a href={'#'}>
                {category.title}
              </a>
            </li>
          {/each}
        </ul>
        <ul class="uk-switcher">
          {#each interval.programCategories as category}
            <li style="overflow-x:auto">
              <div class="uk-margin">
                <table
                  class="uk-table uk-table-small uk-table-divider uk-table-middle uk-table-justify uk-table-responsive"
                >
                  <thead>
                    <ProgramDetailTheadRow
                      {saveCustomField}
                      bind:customFields={category.customFields}
                    />
                  </thead>
                  <tbody>
                    {#each category.groups as group, i}
                      <ProgramDetailRow
                        customFields={category.customFields}
                        bind:row={group}
                        {i}
                        bind:arr={category.groups}
                      />
                    {/each}
                  </tbody>
                </table>
                <Button
                  onclick={() => {
                    category.groups = [
                      ...category.groups,
                      {
                        id: UUID(),
                        title: '',
                        children: [],
                        customFields: [],
                        customFieldValues: {},
                      },
                    ];
                  }}>{$_('schoolSetting.AddGroup')}</Button
                >
              </div>
              <div class="uk-margin">
                <ul uk-tab="animation:uk-animation-fade">
                  {#each category.groups as group}
                    <li>
                      <a href={'#'}>
                        {group.title}
                      </a>
                    </li>
                  {/each}
                </ul>
                <ul class="uk-switcher">
                  {#each category.groups as group}
                    <li style="overflow-x:auto">
                      <table
                        class="uk-table uk-table-small uk-table-divider uk-table-middle uk-table-justify uk-table-responsive"
                      >
                        <thead>
                          <ProgramDetailTheadRow
                            {saveCustomField}
                            bind:customFields={category.childCustomFields}
                          />
                        </thead>
                        <tbody>
                          {#each group.children as child, i}
                            <ProgramDetailRow
                              customFields={category.childCustomFields}
                              bind:row={child}
                              {i}
                              bind:arr={group.children}
                            />
                          {/each}
                        </tbody>
                      </table>
                      <Button
                        onclick={() => {
                          group.children = [
                            ...group.children,
                            {
                              id: UUID(),
                              title: '',
                              customFieldValues: {},
                            },
                          ];
                        }}>{$_('schoolSetting.AddChild')}</Button
                      >
                    </li>
                  {/each}
                </ul>
              </div>
            </li>
          {/each}
        </ul>
      </li>
    {/each}
  </ul>
</IntervalsTab>

<div class="uk-margin">
  <Button onclick={saveCustomField}>{$_('Save')}</Button>
</div>
