<script lang="ts">
  import { afterUpdate, getContext, onMount } from 'svelte';
  import { UUID } from '../../lib/uuid';
  import { PlayerSdk } from '@api.video/player-sdk';
  import VideoProgressBar from './CustomFieldComponents/VideoProgressBar.svelte';
  import { SchoolRole, TargetAssignment } from '../../lib/store';
  import axios from 'axios';
  import { ENDPOINT, SERVER_URL } from '../../lib/config';
  import { io, ioGet } from '../../lib/realtime';
  export let thumbnailURL;
  export let videoId;
  export let userId: string = '';
  export let title;
  let watchHistory = [];
  let progress = 0;
  let totalDuration = 0;
  let id = UUID().replace(/-/g, '');
  onMount(async () => {
    const response = await axios.get(SERVER_URL + 'api/video-sessions', {
      params: {
        userId,
        videoId,
      },
    });

    watchHistory = response.data.watchHistory;
    totalDuration = response.data.duration;
    progress = response.data.progress;
  });

  //Please use this values to work with progress bar and watch history
  let field = getContext('field');
  let videoPlayer;
  afterUpdate(() => {
    try {
      videoPlayer = new PlayerSdk('#myPlayer' + id, {
        id: videoId,
        metadata: {
          userId: $SchoolRole.id,
        },
      });
      videoPlayer.hideControls();
      videoPlayer.showControls([
        'play',
        'seekBackward',
        'seekForward',
        //"playbackRate",
        'volume',
        'fullscreen',
        'subtitles',
        'chapters',
        'pictureInPicture',
        'progressBar',
        'chromecast',
        //"download",
        'more',
      ]);
      videoPlayer.addEventListener('timeupdate', onTimeUpdate);
    } catch (error) {
      console.log(error);
    }
  });
  const onTimeUpdate = async event => {
    const watched = Math.round(event.currentTime);
    if (watchHistory.includes(watched)) {
      return;
    }

    watchHistory.push(watched);
    const response = await axios.post(SERVER_URL + 'api/video-sessions', {
      userId: $SchoolRole.id,
      videoId,
      watchHistory,
    });
    if ($SchoolRole.id == userId) {
      watchHistory = response.data.watchHistory;
      progress = Math.min(100, response.data.progress);
      io.emit('updateWatchHistory', {
        videoId,
        userId,
        progress,
        assignmentID: $TargetAssignment.id,
      });
    }
  };
</script>

<div class="uk-position-relative">
  <img src={thumbnailURL} alt="" style="opacity:0" />
  <!--{@html value.assets.iframe} -->
  <div class="uk-position-top uk-width-expand" style="height:100%">
    <iframe
      id={`myPlayer${id}`}
      src={`https://embed.api.video/vod/${videoId}?metadata[userId]=${$SchoolRole.id}`}
      {title}
      width="100%"
      height="100%"
      frameborder="0"
      scrolling="no"
      allowfullscreen={true}
    />
  </div>
</div>
{#if userId}
  {#key progress}
    <VideoProgressBar {progress} {watchHistory} {totalDuration} />
  {/key}
{/if}
