<script lang="ts">
  import { Route, Router } from 'svelte-routing';
  import { _ } from 'svelte-i18n';
  import { School, SchoolRole } from '../../lib/store';
  import EventAttendanceList from './EventAttendanceList.svelte';
  import NavList from './NavList.svelte';
  import SubscriberAssignmentTable from './SubscriberAssignmentTable.svelte';
  import SubscriberAttendanceCreditTable from './SubscriberAttendanceCreditTable.svelte';
  import SubscriberGrades from './SubscriberGrades.svelte';
  export let interval;
  let focus = location.href
    .split(interval.id + '/')
    .pop()
    .split('/')
    .shift()
    .replace('#', '');

  let breadcrumb = '/' + $School.slug + '/my-reports/' + interval.id + '/';
</script>

<div uk-grid class="uk-grid-small">
  <div>
    <ul class="uk-tab uk-tab-left">
      {#each $School.assignmentCategories as assignmentCategory}
        <NavList bind:focus slug={assignmentCategory.id} {breadcrumb}>
          {assignmentCategory.title}
        </NavList>
      {/each}
      {#each $School.subscriberEventViews || [] as view}
        <NavList bind:focus slug={view.id} {breadcrumb}>{view.title}</NavList>
      {/each}
      <NavList bind:focus slug="grades" {breadcrumb}>
        {$_('ExamScores')}
      </NavList>
    </ul>
  </div>
  <div class="uk-width-expand@m">
    {#if $SchoolRole}
      <Router>
        {#each $School.assignmentCategories as assignmentCategory}
          <Route path={assignmentCategory.id}>
            <SubscriberAssignmentTable
              subscriber={$SchoolRole}
              {interval}
              {assignmentCategory}
            />
          </Route>
        {/each}
        {#each $School.subscriberEventViews || [] as view, i}
          <Route path={view.id + '/*'}>
            {#if view.style == 'byProgram'}
              <SubscriberAttendanceCreditTable
                {view}
                {interval}
                subscriber={$SchoolRole}
              />
            {/if}
            {#if view.style == 'byAttendance'}
              <EventAttendanceList {interval} subscriber={$SchoolRole} {view} />
            {/if}
          </Route>
        {/each}
        <Route path="grades">
          <SubscriberGrades {interval} subscriber={$SchoolRole} />
        </Route>
      </Router>
    {/if}
  </div>
</div>
