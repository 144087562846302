<script>
  import {
    AskIfShowOnList,
    ShowShowOnBadge,
    SaveCustomField,
    TargetCustomField,
    TargetFields,
  } from '../../lib/store';
  import CustomFieldEditRow from '../blocks/CustomFieldComponents/CustomFieldEditRow.svelte';
  import Button from '../UI/Button.svelte';
  import Modal from '../UI/Modal.svelte';
  import { _ } from 'svelte-i18n';
</script>

<Modal size="container" stack withFooter id="customFieldEdit">
  <div slot="body">
    {#if $TargetCustomField}
      <CustomFieldEditRow
        fields={$TargetFields}
        showShowOnBadge={$ShowShowOnBadge}
        {$AskIfShowOnList}
        bind:field={$TargetCustomField}
        moveUp={false}
        moveDown={false}
        remove={false}
        i="0"
        length={1}
      />
    {/if}
  </div>
  <div slot="footer">
    <Button onclick={$SaveCustomField}>{$_('Save')}</Button>
  </div>
</Modal>
