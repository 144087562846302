<script lang="ts">
  import Button from '../UI/Button.svelte';
  import Dropdown from '../UI/Dropdown.svelte';
  import ArrayNav from './ArrayNav.svelte';
  import Input from '../UI/Input.svelte';
  import MetaText from '../UI/MetaText.svelte';
  import { _ } from 'svelte-i18n';
  import arraySwap from '../../lib/helpers/arraySwap';
  import { School } from '../../lib/store';
  import areYouSure from '../../lib/helpers/areYouSure';
  import { ioGet } from '../../lib/realtime';
  import { Notification } from '../../actions/notification';
  export let views = [];
  export let view;
  export let categories = [];
  export let i;
  export let styles = [];
  const saveView = async () => {
    let res = await ioGet('updateSchool', {
      _id: $School._id,
      data: {
        eventViews: views,
      },
    });
    Notification($_('Saved'));
  };
  const moveUp = (i: number) => {
    views = arraySwap(views, i, i - 1);
    saveView();
  };
  const moveDown = (i: number) => {
    saveView();
    views = arraySwap(views, i, i + 1);
  };
  const remove = (i: number) => {
    areYouSure(views[i].title, () => {
      views.splice(i, 1);
      views = views;
      saveView();
    });
  };
</script>

<Button style="link" onclick={() => {}}>
  <i class="fa-duotone fa-edit fa-fw" />
  {$_('view.ViewSetting')}
</Button>
<Dropdown>
  <ArrayNav
    sideway
    {moveUp}
    {moveDown}
    {remove}
    {i}
    arrayLength={views.length}
  />
  <div class="uk-margin">
    <Input bind:value={view.title} label={$_('Title')} />
  </div>
  <div class="uk-margin">
    <MetaText>{$_('event.EventCategory')}</MetaText>
    <ul class="uk-list uk-list-collapse uk-text-left">
      {#each categories as eventCategory}
        <li>
          <label>
            <input
              type="checkbox"
              class="uk-checkbox uk-border-rounded"
              bind:group={view.categoryLabels}
              name="categoryLabel"
              value={eventCategory.eventPresets.categoryLabel}
            />
            {eventCategory.eventPresets.categoryLabel}
          </label>
        </li>
      {/each}
    </ul>
  </div>
  <div class="uk-margin">
    <MetaText>{$_('event.Style')}</MetaText>
    <select class="uk-select" bind:value={view.style}>
      {#each styles as style}
        <option value={'by' + style}>{$_('view.By' + style)}</option>
      {/each}
    </select>
  </div>
  <Button onclick={saveView}>{$_('Save')}</Button>
</Dropdown>
