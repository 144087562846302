<script lang="ts">
  import { _ } from 'svelte-i18n';
  import { HOST } from '../../lib/config';
  import { thumbnailCloudURL } from '../../lib/defaultValues';
  import dialog from '../../lib/helpers/dialog';
  import myConfirm from '../../lib/helpers/myConfirm';
  import UUID from '../../lib/helpers/uuid';
  import background360Videos from '../../lib/presets/background360Videos';
  import backgroundImages from '../../lib/presets/backgroundImages';
  import Environments from '../../lib/presets/environments';
  import timeZones from '../../lib/presets/timeZones';
  import { School } from '../../lib/store';
  import Button from '../UI/Button.svelte';
  import Checkbox from '../UI/Checkbox.svelte';
  import Div from '../UI/Div.svelte';
  import Input from '../UI/Input.svelte';
  import Lead from '../UI/Lead.svelte';
  import ArrayNav from './ArrayNav.svelte';
  import AvatarPreview from './AvatarPreview.svelte';
  import EntityThumbnail from './EntityThumbnail.svelte';
  import FileUpload from './FileUpload.svelte';
  import LinkToDocumentation from './LinkToDocumentation.svelte';
  import SlugInput from './SlugInput.svelte';
  export let room = null;
  export let forNew = false;
  export let small: Boolean = false;

  export let triggerSave: () => void = () => {};
  let school = null;
  School.subscribe(obj => {
    school = obj;
  });
  const backgroundFileUploaded = res => {
    console.log(res);
    if (res.data.filePath) {
      room.skyURL = res.data.url;
    }
  };
  const background360FileUploaded = res => {
    console.log(res);
    if (res.data.filePath) {
      room.sky360URL = res.data.url;
    }
  };

  const selectBacgkround = url => {
    console.log({ url });
    room.skyURL = url;
  };
  const select360Bacgkround = url => {
    room.sky360URL = url;
  };
  const toggleVideo = () => {
    room.use360VideoBackground = !room.use360VideoBackground;
    room = room;
  };

  const removeInvitationURL = (i: number) => {
    room.invitations.splice(i, 1);
    room.invitations = room.invitations;
  };
  const createInvitation = () => {
    let guestRole = $School.roles.find(role => role.category == 'guest');
    if (!guestRole) {
      dialog($_('NoGuestAccountSetUp'));
      return false;
    }
    room.invitations.push({
      id: UUID(),
      roleID: $School.roles.find(role => role.category == 'guest').id,
      roleTitle: $School.roles.find(role => role.category == 'guest').title,
    });
    room.invitations = room.invitations;
    myConfirm($_('DoYouWantToSaveChangesNow'), triggerSave);
  };
  let mountEnvironmentModel = null;
  const updateEnvironmentModelPreview = () => {
    setTimeout(function () {
      room.environment = Environments.find(
        environment => environment.id == room.environmentID,
      );
      if (mountEnvironmentModel) {
        setTimeout(function () {
          mountEnvironmentModel.do();
        }, 500);
      }
    }, 500);
  };
</script>

{#if room && school}

  {#if !small}
    <div uk-grid class="uk-grid-small">
      <div>
        <EntityThumbnail bind:entity={room} />
      </div>
      <div>
        <Input required label={$_('RoomName')} bind:value={room.title} />
      </div>
    </div>
    <div class="uk-margin">

      <SlugInput
        beforeURL={HOST + '/' + school.slug}
        label={$_('roomSetting.RoomURL')}
        bind:value={room.slug}
      />
    </div>
    <div class="uk-margin">
      <label
        >{$_('Description')}
        <textarea class="uk-textarea" bind:value={room.description} />
      </label>
    </div>
    <div class="uk-margin">
      <Checkbox
        bind:checked={room.requirePassword}
        label={$_('RequirePasswordToEnter')}
      />
    </div>
    {#if room.requirePassword}
      <Input required label={$_('Password')} bind:value={room.password} />
    {/if}
  {/if}
  <div class="uk-margin">
    <Input
      type="number"
      bind:value={room.maxVoiceDistance}
      label={$_('roomSetting.MaxVoiceDistance')}
      withUnit="m"
    />
  </div>
  {#if !forNew}
    <hr />
    <div>
      <Lead>{$_('roomSetting.InvitationURLs')}</Lead>
    </div>
    {#each room.invitations || [] as invitation, i}
      {@const url =
        HOST +
        '/' +
        school.slug +
        '/' +
        room.slug +
        '/scene?invitation=' +
        invitation.id}
      <Div>
        <div uk-grid class="uk-grid-small">
          <div>
            <div
              style="background-color:white"
              class="uk-padding-small uk-border-rounded"
            >
              <img
                src={'https://api.qrserver.com/v1/create-qr-code/?data=' +
                  encodeURI(url) +
                  '&size=200x200'}
                alt="qrcode"
              />
            </div>
          </div>
          <div class="uk-width-expand@m uk-flex uk-flex-middle">
            <div class="uk-width-expand">
              <Input value={url} readonly copiable={true} />
              <ArrayNav
                arrayLength={room.invitations.length}
                {i}
                remove={removeInvitationURL}
              />
            </div>
          </div>
        </div>
      </Div>
    {/each}
    <div>
      <Button onclick={createInvitation}
        >{$_('roomSetting.CreateNewInvitationURL')}</Button
      >
    </div>
  {/if}
  <hr />
  <Lead>{$_('roomSetting.EnvironmentModel')}</Lead>
  {#if room.environment?.url}
    <AvatarPreview
      distance={room.environment?.previewDistance ||
        Environments[0].previewDistance}
      id="roomPreview"
      url={room.environment?.url || Environments[0].url}
      navMeshURL={room.environment?.navMeshURL || Environments[0].navMeshURL}
      bind:mount={mountEnvironmentModel}
    />
  {/if}
  <select
    bind:value={room.environmentID}
    class="uk-select"
    on:change={evt => {
      updateEnvironmentModelPreview();
    }}
  >
    {#each Environments as environment}
      <option value={environment.id}>{environment.title}</option>
    {/each}
  </select>
  <hr />
  <div>
    <Lead>{$_('roomSetting.360Background')}</Lead>
  </div>

  <div class="uk-margin">
    <div class="uk-button-group">
      <Button
        style={room.backgroundType == 'sun' ? 'primary' : 'default'}
        onclick={() => {
          room.backgroundType = 'sun';
        }}>{$_('Sun')}</Button
      >
      <Button
        style={room.backgroundType == 'image' ? 'primary' : 'default'}
        onclick={() => {
          room.backgroundType = 'image';
        }}>{$_('Image')}</Button
      >
      <Button
        style={room.backgroundType == 'video' || room.use360VideoBackground
          ? 'primary'
          : 'default'}
        onclick={() => {
          room.backgroundType = 'video';
        }}>{$_('Video')}</Button
      >
    </div>
  </div>

  {#if room.backgroundType == 'sun'}
    <div hidden>
      <p>
        {@html $_('roomSetting.SunDescription')}
      </p>
      <div class="uk-text-meta">
        {$_('roomSetting.Timezone')}
      </div>
      <select class="uk-select" bind:value={room.timezone}>
        {#each timeZones as timezone}
          <option value={timezone.value}>
            {timezone.title}
          </option>
        {/each}
      </select>
    </div>
  {:else if room.use360VideoBackground || room.backgroundType == 'video'}
    <div>
      <p>
        {@html $_('roomSetting.BackgroundDescription')}
      </p>
      <div class="uk-width-medium">
        {#if room.sky360URL}
          <video
            alt=""
            src={room.sky360URL}
            muted={true}
            autoplay
            loop="true"
            class="uk-border-rounded uk-width-medium"
            controls
          />
        {/if}
        <div>
          <FileUpload
            fullwidth
            onUploadComplete={background360FileUploaded}
            fileType="video">{$_('Upload')}</FileUpload
          >
        </div>
      </div>
      <div>
        or<br />
        {$_('roomSetting.SelectFromPresetBackgrounds')}
      </div>
      <div uk-grid class="uk-grid-small">
        {#each background360Videos as video}
          <div class="uk-width-small">
            <img
              alt=""
              src={video.thumbnail}
              class="uk-border-rounded uk-width-small"
            />
            <Button
              fullwidth
              small
              onclick={() => {
                select360Bacgkround(video.url);
              }}>{$_('UseThis')}</Button
            >
          </div>
        {/each}
      </div>
    </div>
  {:else}
    <div>
      <p>
        {@html $_('roomSetting.BackgroundDescription')}
      </p>
      <div class="uk-width-medium">
        {#if room.skyURL}
          <img
            alt=""
            src={thumbnailCloudURL + room.skyURL + '&size=2000&mode=orig'}
            class="uk-border-rounded"
          />
        {/if}
        <div>
          <FileUpload
            fullwidth
            onUploadComplete={backgroundFileUploaded}
            fileType="image">{$_('Upload')}</FileUpload
          >
        </div>
      </div>
      <div>
        or<br />
        {$_('roomSetting.SelectFromPresetBackgrounds')}
      </div>
      <div uk-grid class="uk-grid-small">
        {#each backgroundImages as image}
          <div class="uk-width-small">
            <img
              alt=""
              src={image.url}
              class="uk-border-rounded uk-width-small"
            />
            <div>
              <Button
                fullwidth
                small
                onclick={() => {
                  selectBacgkround(image.url);
                }}>{$_('UseThis')}</Button
              >
            </div>
          </div>
        {/each}
      </div>
    </div>
  {/if}

  <hr />
  <div>
    <Lead>{$_('RoomPermissions')}</Lead>
  </div>

  {#if room}
    <ul class="uk-list uk-list-decimal uk-list-divider">
      {#each room.permissions.filter( permission => school.roles.some(schoolRole => schoolRole.id == permission.id), ) as permission}
        <li>
          <Lead>
            {school.roles.find(role => role.id == permission.id)?.title}
          </Lead>
          <Checkbox
            label={$_('permissions.CanEnter')}
            bind:checked={permission.roomPermissionPresets.canEnter}
          />
          <Checkbox
            label={$_('permissions.CanTransport')}
            bind:checked={permission.roomPermissionPresets.canTransport}
          />
          <Checkbox
            label={$_('permissions.CanFly')}
            bind:checked={permission.roomPermissionPresets.canFly}
          />
          {#if permission.roomPermissionPresets.canEnter}
            <ul class="uk-subnav">
              <li>
                <Checkbox
                  label={$_('permissions.CreateItem')}
                  bind:checked={permission.roomPermissionPresets.items
                    .canCreate}
                />
              </li>
              <li>
                <Checkbox
                  label={$_('permissions.EditItem')}
                  bind:checked={permission.roomPermissionPresets.items.canEdit}
                />
              </li>
              <li>
                <Checkbox
                  label={$_('permissions.DownloadItem')}
                  bind:checked={permission.roomPermissionPresets.items
                    .canDownload}
                />
              </li>
              <li>
                <Checkbox
                  label={$_('permissions.DeleteItem')}
                  bind:checked={permission.roomPermissionPresets.items
                    .canDelete}
                />
              </li>
              <li>
                <Checkbox
                  label={$_('permissions.MoveRotateScaleItem')}
                  bind:checked={permission.roomPermissionPresets.items.canMove}
                />
              </li>
              <li>
                <Checkbox
                  label={$_('permissions.MoveItemInOutOfDashboard')}
                  bind:checked={permission.roomPermissionPresets.items
                    .canMoveStorage}
                />
              </li>
            </ul>
            <ul class="uk-subnav">
              <li>
                <Checkbox
                  label={$_('permissions.CreateCaptureScreen')}
                  bind:checked={permission.roomPermissionPresets
                    .canCreateCaptureScreen}
                />
              </li>
            </ul>
            <ul class="uk-subnav">
              <li>
                <Checkbox
                  label={$_('permissions.MuteOthers')}
                  bind:checked={permission.roomPermissionPresets.friends
                    .canMute}
                />
              </li>
              <li>
                <Checkbox
                  label={$_('permissions.KickOutOthers')}
                  bind:checked={permission.roomPermissionPresets.friends
                    .canKickOut}
                />
              </li>
              <li>
                <Checkbox
                  label={$_('permissions.TransportOthers')}
                  bind:checked={permission.roomPermissionPresets.friends
                    .canTransport}
                />
              </li>
            </ul>
          {/if}
        </li>
      {/each}
    </ul>
  {/if}
{/if}
