<script lang="ts">
  import { onMount } from 'svelte';

  import areYouSure from '../../../lib/helpers/areYouSure';

  import arraySwap from '../../../lib/helpers/arraySwap';
  import ArrayFieldEditRow from './ArrayFieldEditRow.svelte';

  import CustomFieldEditRow from './CustomFieldEditRow.svelte';

  export let options;
  export let save: () => void;

  const moveUp = (i: number) => {
    options.rows = arraySwap(options.rows, i, i - 1);
    console.log(options.rows);
  };
  const moveDown = (i: number) => {
    options.rows = arraySwap(options.rows, i, i + 1);
  };
  const remove = (i: number) => {
    areYouSure(options.rows[i].title, () => {
      options.rows.splice(i, 1);
      options = options;
    });
  };
</script>

{#each options?.rows || [] as row, i}
  <li>
    <ArrayFieldEditRow
      {save}
      bind:field={row}
      fields={options.rows}
      {moveUp}
      {moveDown}
      {remove}
      {i}
      length={options.rows.length}
    />
  </li>
{/each}
