export const getVideosFromAssignment = (assignment, category) => {
  let videos = [];
  let fieldIDs = [];
  category.customFields
    .filter(f => f.type == 'video')
    .forEach(field => {
      fieldIDs.push(field.id);
    });
  category.customFields
    .filter(f => f.type == 'array')
    .forEach(field => {
      field.options.rows
        .filter(r => r.type == 'video')
        .forEach(row => {
          fieldIDs.push(row.id);
        });
    });
  Object.keys(assignment.customFieldValues).forEach(key => {
    if (fieldIDs.includes(key)) {
      videos.push(assignment.customFieldValues[key]);
    } else if (assignment.customFieldValues[key].rows?.length) {
      assignment.customFieldValues[key].rows.forEach(row => {
        if (typeof row.customFieldValues != 'undefined') {
          Object.keys(row.customFieldValues).forEach(key2 => {
            if (fieldIDs.includes(key2)) {
              videos.push(row.customFieldValues[key2]);
            }
          });
        }
      });
    }
  });
  return videos;
};
