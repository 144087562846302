<script lang="ts">
  import { IS_TEST } from '../../lib/config';
  import Button from '../UI/Button.svelte';
  import { _ } from 'svelte-i18n';

  export let setSubscriptionPlan;
  export let plan;
</script>

{#if IS_TEST}
  <Button onclick={() => setSubscriptionPlan(plan)}
    >{$_('schoolSetting.SelectThisPlan')}</Button
  >
{:else}
  <Button
    onclick={() => {
      location.href = 'mailto:aoike-vos@aoike.ac.jp';
    }}>{$_('schoolSetting.Inquiry')}</Button
  >
{/if}
