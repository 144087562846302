<script lang="ts">
  import { DateTime } from 'luxon';
  import { onMount } from 'svelte';
  import { _ } from 'svelte-i18n';
  import { hideModal, showModal } from '../../actions/modalController';
  import { affectPresetToEvent } from '../../lib/helpers/affectPresetToEvent';
  import areYouSure from '../../lib/helpers/areYouSure';
  import sortByKey from '../../lib/helpers/sortByKey';
  import { createEvent, updateEvent } from '../../lib/helpers/updateEvent';
  import { EventDefault } from '../../lib/interfaces/EventInterface';
  import { ioGet } from '../../lib/realtime';
  import { School, SchoolRole } from '../../lib/store';
  import Button from '../UI/Button.svelte';
  import Icon from '../UI/Icon.svelte';
  import Input from '../UI/Input.svelte';
  import Lead from '../UI/Lead.svelte';
  import Modal from '../UI/Modal.svelte';
  import CustomFieldEditOrDisplay from './CustomFieldComponents/CustomFieldEditOrDisplay.svelte';
  import EventEdit from './EventEdit.svelte';
  import HourNameSelect from './HourNameSelect.svelte';
  import SelectCategory from './selectCategory.svelte';

  export let view;
  export let group;

  let eventToCreate = null;
  let events = [];

  onMount(async () => {
    events = await ioGet('getAttendanceCredits', {
      categoryLabel: view.categoryLabel,
      categoryLabels: view.categoryLabels,
      groupID: group.id,
    });
    events = sortByKey(events, {
      key: 'start',
      direction: 'desc',
    });
    console.log(events);
  });
  const deleteEvent = async () => {
    areYouSure(eventToCreate.title, async () => {
      let res = await ioGet('deleteEvent', {
        _id: eventToCreate._id,
      });
      console.log(res);
      let i = events.findIndex(
        schoolEvent => schoolEvent._id === eventToCreate._id,
      );
      events.splice(i, 1);
      events = events;
      hideModal('#editEventList');
    });
  };
  const openEventEdit = schoolEvent => {
    eventToCreate = schoolEvent;
    showModal('#editEventList');
  };
  const save = async () => {
    eventToCreate = await updateEvent(eventToCreate);
    let i = events.findIndex(
      schoolEvent => schoolEvent._id === eventToCreate._id,
    );
    events[i] = eventToCreate;
    events = sortByKey(events, {
      key: 'start',
      direction: 'desc',
    });
    hideModal('#editEventList');
  };
  const create = async () => {
    eventToCreate.linkedPrograms = group.id;
    eventToCreate.categoryLabel = view.categoryLabels;
    let createdEvent = await createEvent(eventToCreate);
    if (!createdEvent) return;
    console.log('event created', createdEvent);
    events.push(createdEvent);
    events = sortByKey(events, {
      key: 'start',
      direction: 'desc',
    });
    hideModal('#createEventList');
  };
  const affectPreset = id => {
    let category = $School.eventCategories.find(category => category.id === id);
    eventToCreate = affectPresetToEvent(category, eventToCreate);
  };
</script>

<div class="uk-overflow-auto">
  <Button
    onclick={() => {
      let obj = {
        ...EventDefault,
        startDate: DateTime.now().toISODate(),
        useCustomTime: $School.features?.useHourNames === false ? true : false,
        startAt: DateTime.now().toFormat('HH:mm:00'),
        endAt: DateTime.now()
          .plus({
            hours: 1,
          })
          .toFormat('HH:mm:00'),
      };
      console.log({ view, eventCategories: $School.eventCategories });
      let category = $School.eventCategories.find(
        category =>
          category.title === view.categoryLabel ||
          view.categoryLabels.indexOf(category.title) > -1,
      );
      if (category) {
        obj = affectPresetToEvent(category, obj);
      }
      eventToCreate = obj;
      showModal('#createEventList');
    }}
  >
    {$_('event.CreateEvent')}
  </Button>

  <table
    class="uk-table uk-table-divider uk-table-small uk-table-responsive uk-table-justify uk-table-middle"
  >
    <tbody>
      {#each events as schoolEvent}
        <tr>
          <td>
            <Lead>
              {DateTime.fromISO(schoolEvent.startDate).toFormat('M/d')}
            </Lead>
          </td>
          <td>
            <div class="uk-width-expand">
              {#if schoolEvent.categoryLabel}
                <div
                  class="uk-label"
                  style="background-color:{schoolEvent.backgroundColor};color:rgba(255,255,255,0.8)"
                >
                  {schoolEvent.categoryLabel}
                </div>
              {/if}
              <Lead>
                {schoolEvent.title}
              </Lead>
            </div>
            <Input type="date" bind:value={schoolEvent.startDate} />
          </td>
          <td>
            <div>
              {#if !schoolEvent.allDay}
                {#if schoolEvent.useCustomTime}
                  <Input
                    label={$_('event.StartTime')}
                    type="time"
                    bind:value={schoolEvent.startAt}
                  />
                {:else}
                  <HourNameSelect bind:schoolEvent />
                {/if}
              {/if}
            </div>
            <div>
              {#if schoolEvent.multipleDays}
                <Input
                  label={$_('event.EndDate')}
                  type="date"
                  bind:value={schoolEvent.endDate}
                  onchange={() => {
                    if (schoolEvent.endDate < schoolEvent.startDate)
                      schoolEvent.endDate = schoolEvent.startDate;
                  }}
                />
              {/if}
              {#if !schoolEvent.allDay}
                {#if schoolEvent.useCustomTime}
                  <Input
                    label={$_('event.EndTime')}
                    type="time"
                    bind:value={schoolEvent.endAt}
                  />
                {/if}
              {/if}
            </div>
          </td>
          {#each $School.customFields.event as field}
            <td>
              <CustomFieldEditOrDisplay
                {field}
                fields={$School.customFields.event}
                bind:value={schoolEvent.customFieldValues[field.id]}
                values={schoolEvent.customFieldValues}
              />
            </td>
          {/each}
          <td style="min-width:40px;">
            {#if $School.roles.find(role => role.id === $SchoolRole.roleID).school.event.canEdit}
              <div>
                <Button style="link" onclick={() => updateEvent(schoolEvent)}>
                  <Icon icon="save" size="large" />
                </Button>
                <Button style="link" onclick={() => openEventEdit(schoolEvent)}>
                  <Icon icon="edit" size="large" />
                </Button>
              </div>
            {/if}
          </td>
        </tr>
      {/each}
    </tbody>
  </table>
</div>
<Modal
  bgClose={false}
  withFooter
  id="editEventList"
  size="container"
  title={$_('event.EditEvent')}
>
  <div slot="body">
    {#if eventToCreate}
      <EventEdit bind:schoolEvent={eventToCreate} />
    {/if}
  </div>
  <div slot="footer">
    <Button
      onclick={() => {
        hideModal('#editEventList');
      }}>{$_('Cancel')}</Button
    >

    <Button onclick={save}>{$_('Save')}</Button>
    {#if $School.roles.find(schoolRole => schoolRole.id === $SchoolRole.roleID)?.school.event?.canDelete}
      <Button style="danger" onclick={deleteEvent}>{$_('Delete')}</Button>
    {/if}
  </div>
</Modal>
<Modal
  bgClose={false}
  withFooter
  id="createEventList"
  size="container"
  title={$_('event.CreateEvent')}
>
  <div slot="body">
    {#if eventToCreate}
      <SelectCategory entity={eventToCreate} label="event" {affectPreset} />
      <EventEdit recurring={true} bind:schoolEvent={eventToCreate} />
    {/if}
  </div>
  <div slot="footer">
    <Button
      onclick={() => {
        hideModal('#createEventList');
      }}>{$_('Cancel')}</Button
    >
    <Button onclick={create}>{$_('Create')}</Button>
  </div>
</Modal>
