<script lang="ts">
  import { assign } from 'svelte/internal';

  import arraySwap from '../../lib/helpers/arraySwap';
  import { _ } from 'svelte-i18n';

  import Input from '../UI/Input.svelte';
  import ArrayNav from './ArrayNav.svelte';
  import areYouSure from '../../lib/helpers/areYouSure';
  import CustomFieldEditRow from './CustomFieldComponents/CustomFieldEditRow.svelte';
  import AssignmentCategoryCustomFields from './AssignmentCategoryCustomFields.svelte';
  export let assignmentCategories = [];
  const moveUp = (i: number) => {
    assignmentCategories = arraySwap(assignmentCategories, i, i - 1);
  };
  const moveDown = (i: number) => {
    assignmentCategories = arraySwap(assignmentCategories, i, i + 1);
  };
  const remove = (i: number) => {
    areYouSure(assignmentCategories[i].title, () => {
      assignmentCategories.splice(i, 1);
      assignmentCategories = assignmentCategories;
    });
  };
</script>

<ul class="uk-list uk-list-divider">
  {#each assignmentCategories as category, i}
    <li>
      <div class="uk-grid-small uk-child-width-1-4" uk-grid>
        <div>
          <Input
            medium
            label={$_('schoolSetting.CategoryTitle')}
            bind:value={category.title}
            type="text"
          />
        </div>
        <div>
          <ArrayNav
            arrayLength={assignmentCategories.length}
            {i}
            {moveUp}
            {moveDown}
            {remove}
          />
        </div>
      </div>
    </li>
  {/each}
</ul>
