<script lang="ts">
  import { _ } from 'svelte-i18n';
  import { Router, Link, Route } from 'svelte-routing';
  import Scene from './components/Scene.svelte';
  import { HOST, IS_TEST } from './lib/config';
  import PanelLayout from './components/blocks/PanelLayout.svelte';
  import Login from './components/Login.svelte';
  import { onMount } from 'svelte';
  import checkLogin from './lib/helpers/checkLogin';
  import SetPassword from './components/SetPassword.svelte';
  import SideMenu from './components/blocks/SideMenu.svelte';
  import Register from './components/Register.svelte';
  import ForgotPassword from './components/ForgotPassword.svelte';
  import ResetPassword from './components/ResetPassword.svelte';
  import { EventToCreate, LoggedIn, DialogMessage } from './lib/store';
  import LoadingSpinner from './components/blocks/LoadingSpinner.svelte';
  import Loading from './lib/helpers/loading';
  import AccountActivation from './components/AccountActivation.svelte';
  import SchoolComponent from './components/School.svelte';
  import Top from './components/Top.svelte';
  import RoomSetting from './components/RoomSetting.svelte';
  import EnterRoom from './components/EnterRoom.svelte';
  import CreateSchool from './components/CreateSchool.svelte';
  import TopNav from './components/blocks/TopNav.svelte';
  import Modal from './components/UI/Modal.svelte';
  import EventEdit from './components/blocks/EventEdit.svelte';
  import Button from './components/UI/Button.svelte';
  import { School } from './lib/store';
  import { ioGet } from './lib/realtime';
  import { DateTime, Duration } from 'luxon';
  import EventDetail from './components/blocks/EventDetail.svelte';
  import EventModals from './components/Modals/EventModals.svelte';
  import ImageEditor from './components/Modals/ImageEditor.svelte';
  import EditRegistrations from './components/Modals/EditRegistrations.svelte';
  import { parseClassNames } from '@fullcalendar/core';
  import ArticleModals from './components/Modals/ArticleModals.svelte';
  export let url = '';
  import channelIO from './lib/helpers/channelio';
  import Modals from './components/blocks/Modals.svelte';
  import Footer from './components/blocks/Footer.svelte';
  import FlexibleChatroom from './components/FlexibleChatroom.svelte';
  import RegisterToSchool from './components/blocks/RegisterToSchool.svelte';
  import VideosList from './pages/VideosList.svelte';
  import VideoPage from './pages/components/VideoPage.svelte';
  import { roomElementsStore } from './state/stores/roomElementsStore';

  let loggedIn = null;
  LoggedIn.subscribe(bool => {
    loggedIn = bool;
  });
  channelIO.boot({
    pluginKey: '801e3fbc-9f1b-4691-af90-e28eda8f46d4',
    hideChannelButtonOnBoot: true,
    customLauncherSelector: '#channelIOLauncher',
  });
  let loginRes;
  onMount(async () => {
    Loading.show();
    loginRes = await checkLogin();
    if (loginRes.user?.isGuest) {
      loggedIn = false;
      Loading.hide();
      LoggedIn.set(false);

      return;
    }
    loggedIn = loginRes.status == 'approved';
    Loading.hide();
  });
</script>

<Modals />
<svelte:head>
  {#if !IS_TEST}
    <!-- Global site tag (gtag.js) - Google Analytics -->
    <script
      async
      src="https://www.googletagmanager.com/gtag/js?id=G-T9XNVCTNWN"
    >
    </script>
    <script>
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        dataLayer.push(arguments);
      }
      gtag('js', new Date());
      gtag('config', 'UA-235332053-1');
      gtag('config', 'G-T9XNVCTNWN');
    </script>
  {/if}
</svelte:head>
<Router>
  <Route path="/flex-chatroom/:id" let:params>
    <FlexibleChatroom {loggedIn} roomID={params.id} />
  </Route>
  <Route path="/:slug/:roomSlug/scene/*" let:params>
    {#if loggedIn !== null}
      <Scene slug={params.slug} roomSlug={params.roomSlug} />
    {/if}
  </Route>
  {#if loggedIn == null}
    <div />
  {:else if loggedIn == true}
    <Route path="register/:schoolSlug/:roleID" let:params>
      <TopNav />

      <div class="uk-container">
        <RegisterToSchool
          {loggedIn}
          schoolSlug={params.schoolSlug}
          roleID={params.roleID}
        />
      </div>
    </Route>
    <Route path="/reset-password">
      <PanelLayout>
        <ResetPassword />
      </PanelLayout>
    </Route>
    <Route path="/set-password">
      <PanelLayout>
        <SetPassword />
      </PanelLayout>
    </Route>
    <Route path="/:slug/:roomSlug/enter-room" let:params>
      <TopNav />

      <EnterRoom slug={params.slug} roomSlug={params.roomSlug} />
    </Route>
    <Route path="/:slug/:roomSlug/setting" let:params>
      <TopNav />
      <RoomSetting slug={params.slug} roomSlug={params.roomSlug} />
    </Route>

    <Route path="/">
      <Top />
    </Route>
    <Route path="/:slug/*" let:params>
      <SchoolComponent slug={params.slug} />
    </Route>
    <Route path="/create-school">
      <PanelLayout>
        <CreateSchool />
      </PanelLayout>
    </Route>
    <SideMenu />
  {:else}
    <div
      class="bg"
      style:background-image={`url(${HOST}/images/bg_avos_02.jpg)`}
      style:background-size={'cover'}
    >
      <Route path="register/:schoolSlug/:roleID" let:params>
        <TopNav />

        <div class="uk-container">
          <RegisterToSchool
            {loggedIn}
            schoolSlug={params.schoolSlug}
            roleID={params.roleID}
          />
        </div>
      </Route>
      <Route path="/">
        <PanelLayout>
          <Login />
        </PanelLayout>
      </Route>
      <Route path="/login">
        <PanelLayout>
          <Login />
        </PanelLayout>
      </Route>
      <Route path="/register">
        <PanelLayout>
          <Register />
        </PanelLayout>
      </Route>
      <Route path="/forgot-password">
        <PanelLayout>
          <ForgotPassword />
        </PanelLayout>
      </Route>
      <Route path="*">
        <PanelLayout>
          <Login />
        </PanelLayout>
      </Route>
    </div>
  {/if}
  <Route path="/activation/:token" let:params>
    <PanelLayout>
      <AccountActivation token={params.token} />
    </PanelLayout>
  </Route>
</Router>
<Footer />

<LoadingSpinner />

<style>
</style>
