/* global AFRAME */

if (typeof AFRAME === 'undefined') {
  throw new Error(
    'Component attempted to register before AFRAME was available.',
  );
}

/**
 * Example component for A-Frame.
 */
AFRAME.registerComponent('offset-head-bone', {
  schema: {
    enabled: { type: 'boolean' },
  },
  init: function () {
    let model = this.el.object3D;
    this.rig = document.getElementById('look-rigger');
  },
  play: function () {},
  tick: function () {
    let parentXRotation = this.rig.object3D.rotation.x;
    this.el.object3D.rotation.x = -1 * parentXRotation;
  },
});
