AFRAME.registerComponent('disable-look-while-dragging', {
  schema: {
    enabled: { type: 'boolean', default: true },
    delay: { type: 'number', default: 0 },
  },
  init: function () {
    this.el.addEventListener('mousedown', evt => {
      this.rig = document.getElementById('look-rigger');
      this.rig.setAttribute('look-controls', 'enabled', false);
      this.rig.parentNode.setAttribute('touch-controls', 'enabled', false);
    });
    this.el.addEventListener('mouseleave', evt => {
      if (this.data.delay) {
        setTimeout(() => {
          this.unfocus(this);
        }, this.data.delay);
      } else {
        this.unfocus(this);
      }
    });
    this.el.addEventListener('mouseup', evt => {
      this.unfocus(this);
    });
  },
  unfocus: function () {
    if (typeof this.rig == 'undefined') return;
    setTimeout(() => {
      this.rig.setAttribute('look-controls', 'enabled', true);
      this.rig.parentNode.setAttribute('touch-controls', 'enabled', true);
    }, 1000);
  },
});
