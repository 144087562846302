// import { AUPredictor } from '@quarkworks-inc/avatar-webkit';
import { Notification } from '../../actions/notification';
// import { HALLWAY_API_TOKEN } from '../config';
import { School, SchoolRole, VideoStream } from '../store';
import dialog from './dialog';

let schoolRole = null;
SchoolRole.subscribe(obj => {
  schoolRole = obj;
});
let predictor;
let stream;
export const getVideoStream = async () => {
  // try {
  //   let videoStream = await navigator.mediaDevices.getUserMedia({
  //     video: {
  //       width: { ideal: 640 },
  //       height: { ideal: 640 },
  //       facingMode: 'user',
  //       frameRate: 12,
  //       resizeMode: 'crop-and-scale',
  //     },
  //   });
  //   return videoStream;
  // } catch (err) {
  //   Notification('Camera access not granted.');

  //   return false;
  // }
  return false;
};

export const avatarPredictions = async () => {
  // if (!stream) {
  //   stream = await getVideoStream();
  // }
  // if (!stream) return;
  // VideoStream.set(stream);

  // predictor = new AUPredictor({
  //   apiToken: HALLWAY_API_TOKEN,
  // });

  // predictor.onPredict = results => {
  //   //console.log(results);
  //   if (schoolRole) {
  //     SchoolRole.update(schoolRole => {
  //       schoolRole.faceShape = results.blendShapes;
  //       schoolRole.faceRotation = results.rotation;
  //       return schoolRole;
  //     });
  //   }
  // };

  // try {
  //   let prediction = predictor.start({
  //     stream: stream ? stream : {},
  //   });
  //   return prediction;
  // } catch (err) {
  //   console.log(err);
  //   Notification('Camera access not granted.');
  // }
  return false;
};
export const stopAvatarPredictions = async () => {
  // console.log(predictor);
  // if (!predictor) return;
  // predictor.stop();
  return false;
};
