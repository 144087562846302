<script>
  import { School } from '../../lib/store';
  import { _ } from 'svelte-i18n';

  export let entity = null;
</script>

<div uk-grid>
  {#each $School.intervals[$School.intervals.findIndex(interval => {
      if (!$School.features.useIntervals || (interval.startOn <= entity.startDate && interval.endOn >= entity.startDate)) {
        return true;
      }
    })]?.programCategories || [] as category}
    {#if entity.filterCategories?.indexOf(category.id) > -1}
      <div>
        <dl class="uk-description-list">
          <dt>
            <span class="uk-light">
              {category.title}
            </span>
          </dt>
          <dd>
            {#each category.groups as group}
              {#if entity.linkedPrograms.indexOf(group.id) > -1}
                <div>
                  {#if group.children.length > 0}
                    All
                  {/if}
                  {group.title}
                </div>
              {:else if group.children.length > 0}
                {#each group.children as child}
                  {#if entity.linkedPrograms.indexOf(child.id) > -1}
                    <div>
                      {group.title} - {child.title}
                    </div>
                  {/if}
                {/each}
              {/if}
            {/each}
          </dd>
        </dl>
      </div>
    {/if}
  {/each}
  {#if entity.filterByRole}
    <div>
      <dl class="uk-description-list">
        <dt>
          <span class="uk-light">
            {$_('event.Roles')}
          </span>
        </dt>
        <dd>
          {#each $School.roles.filter(schoolRole => entity.filterRoles.indexOf(schoolRole.id) > -1) as role}
            <div>
              {role.title}
            </div>
          {/each}
        </dd>
      </dl>
    </div>
  {/if}
</div>
