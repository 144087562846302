<script lang="ts">
  import { navigate } from 'svelte-routing';

  import { ChatUnreadCount, School, SchoolRole } from '../../lib/store';
  import { _ } from 'svelte-i18n';
  import { showAFRAME } from '../../lib/visibilityAFRAME';
  import Button from '../UI/Button.svelte';
  import Modal from '../UI/Modal.svelte';
  import Navbar from '../UI/Navbar.svelte';
  import { capitalize } from '../../lib/helpers/capitalize';
  export let forTop: Boolean = false;

  const launchCometChat = () => {
    UIkit.modal('#chatModal').show();
  };
  const getFontIsLight = (school, schoolRole) => {
    if (forTop) return true;
    if (!school) return true; //school can be null
    return !school[
      'fontIsDarkFor' +
        capitalize(
          school?.roles.find(r => r.id == schoolRole?.roleID)?.category,
        )
    ];
  };
  const getBackgroundColor = (school, schoolRole) => {
    if (forTop) return '';
    if (!school) return ''; //school can be null
    return (
      school[
        'backgroundColorFrame' +
          capitalize(
            school?.roles.find(r => r.id == schoolRole?.roleID)?.category,
          )
      ] ||
      school?.backgroundColorFrame ||
      '#202020'
    );
  };
  $: fontIsLight = getFontIsLight($School, $SchoolRole);
  $: backgroundColor = getBackgroundColor($School, $SchoolRole);
</script>

<div
  class:uk-light={fontIsLight}
  class="nav uk-padding-small"
  style:background-color={backgroundColor}
>
  <Navbar>
    <div slot="left">
      <ul class="uk-subnav uk-margin-remove-bottom">
        <li>
          <a href="/" class="uk-button uk-button-link">
            <img
              alt="AVOS"
              src="https://api.x-vos.com/images/logos/vos_icon_circle_blue_36x36.png"
              class=""
            />
          </a>
        </li>
        {#if !forTop}
          <li>
            <a
              on:click={() => navigate('/' + $School?.slug + '/')}
              href={'#'}
              class="uk-button uk-button-link"
            >
              <strong>
                {$School?.title}
              </strong>
            </a>
          </li>
        {/if}
      </ul>
    </div>
    <div slot="right" class="uk-flex uk-flex-middle">
      <ul class="uk-subnav uk-margin-remove-bottom">
        <li>
          <a
            target="_blank"
            href="https://k2z6n.channel.io"
            class="uk-link-text"
          >
            <i class="fa-duotone fa-megaphone fa-lg fa-fw" />
            <span class=" uk-visible@s">
              {$_('Support')}
            </span>
          </a>
        </li>
        {#if !forTop}
          <li class="chatButton uk-position-relative">
            <Button style="link" onclick={launchCometChat}>
              {#if $ChatUnreadCount > 0}
                <div
                  class="uk-position-top-right"
                  style="margin-top:-10px;margin-right:-10px"
                >
                  <span class="uk-badge">{$ChatUnreadCount}</span>
                </div>
              {/if}
              <i class="fa-duotone fa-comments fa-lg fa-fw" />

              <span class=" uk-visible@s">
                {$_('Chat')}
              </span>
            </Button>
          </li>
        {/if}
        <li>
          <Button
            style="link"
            onclick={() => {
              UIkit.offcanvas('#side-menu').toggle();
            }}
          >
            {#if forTop}
              {$_('Menu')}
            {:else}
              <div uk-grid class="uk-grid-small">
                <div class="uk-flex uk-flex-middle uk-visible@s">
                  {$SchoolRole?.nickname || ''}
                </div>
                <div class="uk-flex uk-flex-middle">
                  <img
                    src={$SchoolRole?.profileImageURL || '/images/no_photo.jpg'}
                    style="height:30px;width:30px;"
                    class="uk-border-circle uk-box-shadow-small"
                    alt="profile"
                  />
                </div>
              </div>
            {/if}
          </Button>
        </li>
      </ul>
      <div />
    </div>
  </Navbar>
</div>
