<script>
  import selectLocale from '../../lib/helpers/selectLocale';
  import Button from '../UI/Button.svelte';
  import Cookies from 'js-cookie';
</script>

<div class="uk-button-group">
  <Button
    small
    style={Cookies.get('locale') == 'en' ? 'primary' : 'default'}
    onclick={() => {
      selectLocale('en');
    }}>English</Button
  >
  <Button
    small
    style={Cookies.get('locale') == 'ja' ? 'primary' : 'default'}
    onclick={() => {
      selectLocale('ja');
    }}>Japanese</Button
  >
</div>
