<script lang="ts">
  import Button from './UI/Button.svelte';
  import Checkbox from './UI/Checkbox.svelte';
  import Input from './UI/Input.svelte';
  import { defaultRoles, newRole } from '../lib/presets/roles';
  import { School } from '../lib/store';
  import { ioGet } from '../lib/realtime';
  import { navigate } from 'svelte-routing';
  import { _ } from 'svelte-i18n';
  import dialog from '../lib/helpers/dialog';
  import { accountCategories } from '../lib/defaultValues';
  import ArrayNav from './blocks/ArrayNav.svelte';
  import arraySwap from '../lib/helpers/arraySwap';
  import CategoryCheckboxes from './blocks/CategoryCheckboxes.svelte';
  import { UUID } from '../lib/uuid';
  import { capitalize } from '../lib/helpers/capitalize';
  import LinkToDocumentation from './blocks/LinkToDocumentation.svelte';
  let schoolRoleTypes = Object.keys(defaultRoles[0].school);
  schoolRoleTypes = schoolRoleTypes.reduce((accumulator, type) => {
    if (type != 'canEdit' && type != 'canDelete') {
      accumulator.push({
        title: capitalize(type),
        slug: type,
      });
    }
    return accumulator;
  }, []);

  export let school = null;
  export let short: Boolean = false;
  const addRole = async () => {
    //const newLine = JSON.parse(JSON.stringify(newRole))
    school.roles = [
      ...school.roles,
      {
        ...newRole,
        id: UUID(),
      },
    ];
  };

  const remove = (i: number) => {
    if (i == 0) {
      dialog($_('editRole.YouCantRemoveTheTopRole'));
      return;
    }
    school.roles.splice(i, 1);
    school.roles = school.roles;
  };
  const moveUp = (i: number) => {
    if (i == 1) {
      dialog($_('editRole.YouCantMoveTheTopRole'));
      return;
    }
    school.roles = arraySwap(school.roles, i, i - 1);
  };
  const moveDown = (i: number) => {
    if (i == 0) {
      dialog($_('editRole.YouCantMoveTheTopRole'));
      return;
    }
    school.roles = arraySwap(school.roles, i, i + 1);
  };
</script>

{#if school}
  <ul class="uk-list uk-list-decimal uk-list-divider">
    {#each school.roles as role, i}
      <li>
        {#if role.category == 'guest'}
          {$_('permissions.ThereMustBeOneGuestAccount')}
        {/if}
        {#if i == 0}
          {$_('permissions.ThisRoleIsAdmin')}
        {/if}
        <Input
          label={$_('editRole.RoleName')}
          bind:value={role.title}
          required
        />
        {#if role.category == 'guest'}
          <ArrayNav
            {i}
            remove={false}
            moveUp={() => moveUp(i)}
            moveDown={() => moveDown(i)}
            arrayLength={school.roles.length}
          />
        {:else}
          <ArrayNav
            {i}
            remove={() => remove(i)}
            moveUp={() => moveUp(i)}
            moveDown={() => moveDown(i)}
            arrayLength={school.roles.length}
          />
        {/if}
        {#if role.category != 'guest' && i != 0}
          <ul class="uk-subnav">
            {#each accountCategories.filter(category => category.slug != 'guest') as category}
              <li>
                <label>
                  <input
                    type="radio"
                    class="uk-radio"
                    name="roleCategoryFor{role.id}"
                    bind:group={role.category}
                    value={category.slug}
                  />
                  {$_('permissions.Is' + category.title)}
                </label>
              </li>
            {/each}
          </ul>
        {/if}
        {#if role.category != 'guest'}
          <ul class="uk-subnav">
            <li>
              <Checkbox
                label={$_('permissions.ChangePermissions')}
                bind:checked={role.canChangePermissions}
                disabled={i == 0}
              />
              {#if i == 0}
                <div class="uk-text-meta">
                  {$_('permissions.YouCantDisableThisForTheTopRole')}
                </div>
              {/if}
            </li>
            <li>
              <Checkbox
                label={$_('permissions.EditSchoolSettings')}
                bind:checked={role.school.canEdit}
              />
            </li>
            <li>
              <Checkbox
                label={$_('permissions.DeleteSchool')}
                bind:checked={role.school.canDelete}
              />
            </li>
          </ul>
          <ul class="uk-subnav">
            <li>
              <Checkbox
                label={$_('permissions.CreateAccount')}
                bind:checked={role.school.account.canCreate}
              />
            </li>
            <li>
              <Checkbox
                label={$_('permissions.DeleteAccount')}
                bind:checked={role.school.account.canDelete}
              />
            </li>
          </ul>
          {$_('Rooms')}
          <ul class="uk-subnav">
            <li>
              <Checkbox
                label={$_('permissions.CreateRoom')}
                bind:checked={role.roomPermissionPresets.rooms.canCreate}
              />
            </li>
            <li>
              <Checkbox
                label={$_('permissions.EditRoom')}
                bind:checked={role.roomPermissionPresets.rooms.canEdit}
              />
            </li>
            <li>
              <Checkbox
                label={$_('permissions.DeleteRoom')}
                bind:checked={role.roomPermissionPresets.rooms.canDelete}
              />
            </li>
          </ul>
          <hr />
        {/if}
        {#if !short}
          {$_('editRole.InTheRoom')}
          <span class="uk-text-meta">
            {$_('editRole.InTheRoomDescription')}
          </span>
          <ul class="uk-subnav">
            <li>
              <Checkbox
                label={$_('permissions.CreateItem')}
                bind:checked={role.roomPermissionPresets.items.canCreate}
              />
            </li>
            <li>
              <Checkbox
                label={$_('permissions.EditItem')}
                bind:checked={role.roomPermissionPresets.items.canEdit}
              />
            </li>
            <li>
              <Checkbox
                label={$_('permissions.DeleteItem')}
                bind:checked={role.roomPermissionPresets.items.canDelete}
              />
            </li>
            <li>
              <Checkbox
                label={$_('permissions.MoveRotateScaleItem')}
                bind:checked={role.roomPermissionPresets.items.canMove}
              />
            </li>
            <li>
              <Checkbox
                label={$_('permissions.MoveItemInOutOfDashboard')}
                bind:checked={role.roomPermissionPresets.items.canMoveStorage}
              />
            </li>
          </ul>

          <ul class="uk-subnav">
            <li>
              <Checkbox
                label={$_('permissions.MuteOthers')}
                bind:checked={role.roomPermissionPresets.friends.canMute}
              />
            </li>
            <li>
              <Checkbox
                label={$_('permissions.KickOutOthers')}
                bind:checked={role.roomPermissionPresets.friends.canKickOut}
              />
            </li>
            <li>
              <Checkbox
                label={$_('permissions.TransportOthers')}
                bind:checked={role.roomPermissionPresets.friends.canTransport}
              />
            </li>
          </ul>
          {#if role.category != 'guest'}
            <hr />
            {#each schoolRoleTypes as schoolRoleType}
              {$_('editRole.' + schoolRoleType.title)}
              <ul class="uk-subnav">
                <li>
                  <Checkbox
                    label={$_('permissions.CanRead')}
                    bind:checked={role.school[schoolRoleType.slug].canRead}
                  />
                </li>
                {#if role.category != 'guest'}
                  <li>
                    <Checkbox
                      label={$_('permissions.CanCreate')}
                      bind:checked={role.school[schoolRoleType.slug].canCreate}
                    />
                  </li>
                  <li>
                    <Checkbox
                      label={$_('permissions.CanEdit')}
                      bind:checked={role.school[schoolRoleType.slug].canEdit}
                    />
                  </li>
                  <li>
                    <Checkbox
                      label={$_('permissions.CanDelete')}
                      bind:checked={role.school[schoolRoleType.slug].canDelete}
                    />
                  </li>
                {/if}
              </ul>
            {/each}
          {/if}
        {/if}
      </li>
    {/each}
  </ul>
  <div class="uk-child-width-expand@s" uk-grid>
    <div>
      <Button small onclick={addRole}>{$_('editRole.AddRole')}</Button>
    </div>

  </div>
{/if}
