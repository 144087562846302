<script lang="ts">
  import {
    startScreenCapture,
    stopScreenCapture,
    startWebcam,
    stopWebcam,
    videoController,
    muteAudio,
    normalAudio,
  } from '../../actions/videoController.js';
  import assignAnimation from '../../lib/helpers/assignAnimation.js';
  import { _ } from 'svelte-i18n';
  import { ioGet } from '../../lib/realtime.js';
  import {
    Room,
    RoomPermissions,
    captureScreenActive,
    webcamActive,
    megaphoneActive,
    micOnMute,
    AudioDeviceID,
    myCurrentFace,
    myVolumeRaw,
    noiseThreshold,
    User,
    SchoolRole,
    ImageEditURL,
    ConstructionMode,
    PointerLockEnabled,
    ImageEditCallback,
    ImageEditSquare,
    ItemCounts,
    ItemsLoaded,
  } from '../../lib/store';
  import BottomMenuNav from './BottomMenuNav.svelte';
  import Button from '../UI/Button.svelte';
  import AudioMenu from './AudioMenu.svelte';
  import EmoteMenu from './EmoteMenu.svelte';
  import { dashboardOut, dashboardClose } from '../../lib/defaultValues.js';
  import { hideAFRAME, showAFRAME } from '../../lib/visibilityAFRAME.js';
  import resetFocus from '../../lib/helpers/resetFocus.js';
  import { menuActions, faces, itemTypes } from '../../lib/defaultValues';
  import Cookies from 'js-cookie';
  import toggleDashboard from '../../lib/helpers/toggleDashboard.js';
  import upload from '../../lib/helpers/upload.js';
  import { createItem } from '../../actions/itemController.js';
  import { showModal } from '../../actions/modalController.js';
  import { getContext } from 'svelte';
  import myConfirm from '../../lib/helpers/myConfirm.js';
  import Icon from '../UI/Icon.svelte';
  import myPrompt from '../../lib/helpers/myPrompt.js';
  import { UUID } from '../../lib/uuid.js';
  import { writable } from 'svelte/store';
  import { roomElementsStore } from '../../state/stores/roomElementsStore.js';
  export let toggleChatPanelHidden;
  export let chatPanelHidden;
  let roomPermissions;
  RoomPermissions.subscribe(obj => {
    roomPermissions = obj;
  });
  let user;
  User.subscribe(obj => {
    user = obj;
  });
  let schoolRole;
  SchoolRole.subscribe(obj => {
    schoolRole = obj;
  });
  let loadingBarHidden: boolean = false;
  let ifCaptureScreenActive;
  captureScreenActive.subscribe(val => {
    ifCaptureScreenActive = val;
  });
  document.onkeydown = function (evt) {
    if (evt.key == 'Escape') {
      resetFocus();
    }
  };
  ItemsLoaded.subscribe(num => {
    if (!$ItemCounts || $ItemCounts == $ItemsLoaded) {
      loadingBarHidden = true;
      return;
    }
    loadingBarHidden = false;
  });
  export let fileType: string = '';
  let itemCountInDashboard = 0;
  roomElementsStore.subscribe(array => {
    let screenShareInDashboardCount = Array.from(
      document.getElementById('myDashboard')?.children || [],
    ).filter(el => {
      if (el.id.indexOf('screenOf') > -1) {
        return true;
      }
    }).length;
    itemCountInDashboard =
      array.filter(item => {
        if (item.storedAt == 'dashboard') {
          return true;
        }
        return false;
      }).length + screenShareInDashboardCount;
  });
</script>

<div class="uk-position-bottom" style="z-index:1">
  <div class="uk-position-relative uk-width-expand">
    <div class="uk-position-top uk-width-expand" hidden={loadingBarHidden}>
      <div
        style="height:4px;background-color:lightblue;"
        style:width={($ItemCounts ? (100 * $ItemsLoaded) / $ItemCounts : 0) +
          '%'}
      />
    </div>
    <div
      class="uk-position-bottom-left uk-margin uk-margin-small-left uk-light dark-translucent-background uk-box-shadow-medium"
      style="height:160px;width:80px;border-radius:40px;"
    >
      <div class="uk-position-center">
        <div
          id="navUp"
          style="width:80px;height:60px;"
          class="uk-flex uk-flex-bottom uk-flex-center"
        >
          <BottomMenuNav>
            <span slot="icon" class="unselectable">
              <i class="fa-duotone fa-chevron-up fa-2x" />
            </span>
          </BottomMenuNav>
        </div>
        <hr class="unselectable" />
        <div id="navDown" style="width:80px;height:60px;">
          <BottomMenuNav>
            <span slot="icon" class="unselectable">
              <i class="fa-duotone fa-chevron-down fa-2x" />
            </span>
          </BottomMenuNav>
        </div>
      </div>
    </div>
    {#if roomPermissions?.items.canCreate && chatPanelHidden}
      <div
        class="uk-position-bottom-right uk-margin uk-margin-small-right uk-light dark-translucent-background uk-border-circle uk-box-shadow-medium"
        style="height:80px;width:80px"
      >
        <a href={'#'} class="uk-position-center">
          <BottomMenuNav>
            <span slot="icon">
              <i class="fa-duotone fa-plus fa-2x" />
            </span>
          </BottomMenuNav>
        </a>
        <div
          class="uk-navbar-dropdown uk-light uk-border-rounded uk-width-medium dark-translucent-background"
          uk-dropdown="pos: top-right;mode:click"
          id="bottomMenuControlDropdown"
          style="max-height: 90vh;overflow: auto"
        >
          <ul class="uk-nav uk-navbar-dropdown-nav ">
            <li class="uk-nav-heading">
              {$_('menu.AddItems')}
            </li>
            {#each itemTypes as type}
              <li>
                <a
                  href={'#'}
                  on:click={() => {
                    fileType = type.fileType;
                    showModal('#uploadDialog');
                    UIkit.dropdown('#bottomMenuControlDropdown').hide(0);
                  }}
                >
                  <i class={type.icon} />
                  {$_('menu.' + type.label)}</a
                >
              </li>
            {/each}
            {#if roomPermissions?.items.canCreate}
              <li>
                <a
                  href={'#'}
                  on:click={() => {
                    myPrompt(
                      $_('menu.CreatingTransportPointAtCurrentPosition'),
                      title => {
                        let position = document
                          .getElementById($SchoolRole.id)
                          .getAttribute('position');
                        createItem(
                          'room',
                          '/models/transportPoint.glb',
                          'transportPoint',
                          { title },
                          position,
                        );
                      },
                    );
                  }}
                >
                  <Icon size="2x" icon="transporter-1" />
                  {$_('menu.AddTransportPoint')}
                </a>
              </li>
            {/if}
            {#if roomPermissions?.canCreateCaptureScreen}
              {#if !ifCaptureScreenActive}
                <li>
                  <a
                    href={'#'}
                    on:click={() => {
                      startScreenCapture();
                      UIkit.dropdown('#bottomMenuControlDropdown').hide(0);
                    }}
                  >
                    <i class="fa-duotone fa-display fa-fw fa-2x" />
                    {$_('menu.ShareScreen')}
                  </a>
                </li>
              {:else}
                <li>
                  <a
                    href={'#'}
                    on:click={() => {
                      stopScreenCapture();
                      UIkit.dropdown('#bottomMenuControlDropdown').hide(0);
                    }}
                  >
                    <i class="fa-duotone fa-display-slash fa-fw fa-2x" />
                    {$_('menu.StopShareScreen')}
                  </a>
                </li>
              {/if}
            {/if}
            <li class="uk-nav-divider" />
            <li>
              <a
                href={'#'}
                on:click={() => {
                  myPrompt('Set your nickname in English', nickname => {
                    window.open(
                      $Room.whiteBoardURL + '&username=' + { nickname },
                      '_blank',
                    );
                  });
                }}
              >
                <Icon icon="chalkboard" size="2x" />
                {$_('menu.SharedWhiteBoard')}
              </a>
            </li>
            <li>
              <a href={$Room.documentURL} target="_blank">
                <Icon icon="file-lines" size="2x" />
                {$_('menu.SharedDocument')}
              </a>
            </li>
            <li class="uk-nav-divider" />
            <li>
              <a
                href={'#'}
                on:click={() => {
                  ImageEditURL.set('/images/blank.png');
                  ImageEditSquare.set(false);
                  ImageEditCallback.set(res => {
                    createItem('room', res.data.url, 'image', {
                      fileType: 'image',
                      initialScale: {
                        x: 1,
                        y: 1,
                        z: 1,
                      },
                    });
                  });
                  showModal('#imageEditor');
                }}
              >
                <i class="fa-duotone fa-text fa-fw fa-2x fa-fw" />
                {$_('menu.AddText')}</a
              >
            </li>
            <li class="uk-nav-divider" />
            <li>
              <a
                href={'#'}
                on:click={() => {
                  let canvas = document
                    .querySelector('a-scene')
                    .components.screenshot.getCanvas('perspective');
                  console.log(canvas);
                  canvas.toBlob(
                    function (blob) {
                      var formData = new FormData(); //this will submit as a "multipart/form-data" request
                      blob.name = 'something.jpg';
                      formData.append('myFile', blob); //"image_name" is what the server will call the blob
                      console.log(blob);
                      upload(formData, async res => {
                        console.log('upload complete', res);
                        ImageEditURL.set(res.data.url);
                        ImageEditSquare.set(false);

                        ImageEditCallback.set(res => {
                          createItem('room', res.data.url, 'image', {
                            fileType: 'image',
                            initialScale: {
                              x: 1,
                              y: 1,
                              z: 1,
                            },
                          });
                        });
                        showModal('#imageEditor');
                      }); //upload the "formData", not the "blob"
                    },
                    'image/jpeg',
                    0.8,
                  );
                }}
              >
                <i class="fa-solid fa-camera-viewfinder fa-fw fa-2x" />
                {$_('menu.Snapshot')}
              </a>
            </li>
          </ul>
        </div>
      </div>
    {/if}
  </div>
  <nav
    class="uk-navbar-container uk-navbar-transparent uk-light dark-translucent-background"
    uk-navbar="mode:click"
    on:click={resetFocus}
  >
    <div class="uk-navbar-left">
      <ul class="uk-navbar-nav">
        <li>
          <AudioMenu />
        </li>
        <li class="uk-position-relative">
          {#if itemCountInDashboard}
            <div class="uk-position-top-right uk-padding-small">
              <span class="uk-badge"> {itemCountInDashboard} </span>
            </div>
          {/if}
          <a
            href={'#'}
            on:click={() => {
              let bool = toggleDashboard();
              SchoolRole.set({
                ...$SchoolRole,
                dashboardVisible: bool,
              });
            }}
          >
            <BottomMenuNav>
              <span
                slot="icon"
                style={$SchoolRole.dashboardVisible ? 'color:lightgreen' : ''}
              >
                <i class="fa-duotone fa-grid-horizontal fa-2x" />
              </span>
              <div slot="subtitle">
                {$_('menu.Dashboard')}
              </div>
            </BottomMenuNav>
          </a>
        </li>
      </ul>
    </div>
    <div class="uk-navbar-center">
      <ul class="uk-navbar-nav">
        {#if roomPermissions?.items.canEdit}
          <li>
            <a
              href={'#'}
              class={$ConstructionMode ? 'uk-text-warning' : ''}
              on:click={ConstructionMode.set(!$ConstructionMode)}
            >
              <BottomMenuNav>
                <span slot="icon">
                  <i class="fa-duotone fa-screwdriver-wrench fa-2x" />
                </span>
                <div slot="subtitle">
                  {$_('menu.ConstructionMode')}
                </div>
              </BottomMenuNav>
            </a>
          </li>
        {/if}
      </ul>
    </div>
    <div class="uk-navbar-right">
      <ul class="uk-navbar-nav">
        <li>
          <a href={'#'} on:click={toggleChatPanelHidden}>
            <BottomMenuNav>
              <span slot="icon">
                <i class="fa-duotone fa-comments fa-2x" />
              </span>
              <div slot="subtitle">
                {$_('menu.Chat')}
              </div>
            </BottomMenuNav>
          </a>
        </li>
        <li>
          <a
            href={'#'}
            on:click={() => {
              UIkit.offcanvas('#side-panel').toggle();
            }}
          >
            <BottomMenuNav>
              <span slot="icon">
                <i class="fa-solid fa-ellipsis-vertical fa-2x" />
              </span>
              <div slot="subtitle">
                {$_('menu.Other')}
              </div>
            </BottomMenuNav>
          </a>
        </li>
      </ul>
    </div>
  </nav>
</div>

<style>
  a {
    -webkit-touch-callout: none !important;
    -webkit-user-select: none !important;
  }

  .directionCircle {
    height: 120px;
    width: 120px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.4);
  }

  .directionBar {
    height: 60px;
    width: 60px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.4);
    margin-bottom: 50px;
    margin-left: 30px;
  }

  .notch {
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
  }

  .notch-left {
    border-top-right-radius: 15px;
  }
  .unselectable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
</style>
