<script lang="ts">
  import { isArraysEqual } from '@fullcalendar/core';
  import { afterUpdate } from 'svelte';
  import type { CustomFieldInterface } from '../../../lib/interfaces/CustomField';
  import { CustomFieldDefault } from '../../../lib/interfaces/CustomField';
  import { UUID } from '../../../lib/uuid';

  import CustomFieldEditButtonModal from '../../Modals/CustomFieldEditButtonModal.svelte';
  import Button from '../../UI/Button.svelte';
  import ArrayNav from '../ArrayNav.svelte';
  export let saveCustomField: () => void;
  export let moveUp: (i: number) => void;
  export let moveDown: (i: number) => void;
  export let remove: (i: number) => void;
  export let withAdd: boolean = false;
  export let categoryForNewField: string = '';
  export let fields: Array<CustomFieldInterface> = [];
</script>

{#each fields || [] as field, i}
  <th>
    {field.title}
    <CustomFieldEditButtonModal
      {fields}
      {saveCustomField}
      {field}
      showShowOnBadge={true}
    />

    <button class="uk-button uk-button-link">
      <i class="fa-duotone fa-ellipsis-vertical fa-fw" />
    </button>
    <div
      uk-dropdown="mode:click"
      class="uk-background-secondary uk-border-rounded uk-light"
    >
      <ArrayNav
        sideway={true}
        {moveUp}
        {moveDown}
        {remove}
        {i}
        arrayLength={fields.length}
      />
    </div>
  </th>
{/each}
{#if withAdd}
  <th style="min-width: 50px;">
    <Button
      style="link"
      onclick={() => {
        fields = [
          ...fields,
          {
            ...CustomFieldDefault,
            id: UUID(),
            category: categoryForNewField,
          },
        ];
      }}
    >
      <i class="fa-duotone fa-plus fa-fw" />
    </Button>
  </th>
{/if}
