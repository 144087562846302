import { gumballTarget, gumballTargetType } from '../lib/store.js';
import { resetGumball } from '../lib/resizeGumball';
import { hideAFRAME, showAFRAME } from '../lib/visibilityAFRAME.js';
import resetFocus from '../lib/helpers/resetFocus.js';
import gumballSchema from '../lib/helpers/gumballSchema.js';
import { getSource } from '../actions/mediaItemController';
import getBoundingBox from '../lib/helpers/getBoundingBox';
import initGumball from '../lib/helpers/initGumball.js';

AFRAME.registerComponent('show-gumball', {
  schema: gumballSchema,
  init: function () {
    this.gumball = document.getElementById('gumball');
    initGumball(this);
    //this.editor.scene.add(this.transformControls);
  },
  remove: function () {
    resetFocus();
  },
});
