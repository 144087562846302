<script lang="ts">
  import { DateTime } from 'luxon';
  import nl2br from 'nl2br';
  import { _ } from 'svelte-i18n';
  import { hideModal, showModal } from '../../../actions/modalController';
  import { HOST } from '../../../lib/config';
  import { thumbnailCloudURL } from '../../../lib/defaultValues';
  import { decodeMath } from '../../../lib/helpers/decodeMath';
  import { downloadFileAs } from '../../../lib/helpers/downloadFileAs';
  import { getFormScore } from '../../../lib/helpers/getFormScore';
  import type { CustomFieldInterface } from '../../../lib/interfaces/CustomField';
  import { FileStorageClient } from '../../../v2/clients/fileStorageClient';
  import Button from '../../UI/Button.svelte';
  import Checkbox from '../../UI/Checkbox.svelte';
  import Lead from '../../UI/Lead.svelte';
  import Modal from '../../UI/Modal.svelte';
  import ColorRing from '../ColorRing.svelte';
  import VideoPlayer from '../VideoPlayer.svelte';
  import CustomFieldDisplay from './CustomFieldDisplay.svelte';
  import VideoProgress from './VideoProgress.svelte';
  export let field;
  export let value: any;
  export let values = {};
  export let fields: Array<CustomFieldInterface> = [];
  export let parentFields;
  export let parentValues;
  export let videoWatcher: string;
  export let disabled: boolean = false;
  let fileStorageClient = new FileStorageClient();

  if (
    field.click_once &&
    values[field.options.target].value == field.options.value.value
  )
    disabled = true;
  const actionButtonDo = () => {
    if (disabled) return;
    values[field.options.target] = field.options.value;
    values = values;
  };

  const downloadFileLocally = async (key: string): Promise<void> => {
    await fileStorageClient.downloadSingleFileLocally(key);
  };
</script>

{#if field}
  {#if field.type == 'text'}
    <span class="uk-text-nowrap">
      {value.value || ''}
    </span>
  {:else if field.type == 'videoProgress'}
    <VideoProgress />
  {:else if field.type == 'select'}
    <span class="uk-text-nowrap">
      {value.value || ''}
    </span>
  {:else if field.type == 'number' && value != '' && typeof value != 'undefined'}
    {new Intl.NumberFormat().format(value.value) || ''}
  {:else if (field.type == 'date' || field.type == 'endOfMonth') && value.value && value.value != '0000-00-00'}
    {DateTime.fromISO(value.value).toLocaleString()}
  {:else if field.type == 'datetime-local' && value.value && value.value != '0000-00-00'}
    {DateTime.fromISO(value.value).toLocaleString(DateTime.DATETIME_SHORT)}
  {:else if field.type == 'time' && value.value && value.value != '00:00:00'}
    {DateTime.fromISO(value.value).toLocaleString(DateTime.TIME_SIMPLE)}
  {:else if field.type == 'color'}
    <div style:color={value.value}>
      <i class="fa-solid fa-circle fa-lg" />
    </div>
  {:else if field.type == 'button'}
    {@const modalId = 'warning-modal_' + field.id}
    <Modal id={modalId} withFooter title={'Warning'} stack>
      <div slot="body">
        <h3>Are you sure that you wanna submit?</h3>
      </div>
      <div slot="footer">
        <Button
          onclick={() => {
            hideModal('#' + modalId);
            actionButtonDo();
          }}>{$_('Save')}</Button
        >
      </div>
    </Modal>
    <Button
      {disabled}
      fullwidth
      onclick={() => {
        if (field.click_once && field.click_once == true && disabled == false) {
          showModal('#' + modalId);
        } else {
          actionButtonDo();
        }
      }}>{field.title}Button</Button
    >
  {:else if field.type == 'selectMultiple'}
    <ul class="uk-subnav">
      {#each value.values || [] as val}
        <li>
          {val}
        </li>
      {/each}
    </ul>
  {:else if field.type == 'multiLineText'}
    {@html nl2br(value.value || '')}
  {:else if field.type == 'richText'}
    {#if value}
      <div class="mathPreview">
        {@html decodeMath(value.value)}
      </div>
    {/if}
  {:else if field.type == 'video' && typeof value.assets != 'undefined'}
    {#if value.assets.iframe}
      {#key value.videoId}
        {#key videoWatcher}
          <VideoPlayer
            thumbnailURL={value.assets.thumbnail}
            title={value.title}
            videoId={value.videoId}
            userId={videoWatcher}
          />
        {/key}
      {/key}
      <div>
        {value.title}
      </div>
    {/if}
  {:else if field.type == 'image'}
    <a
      href={'#'}
      on:click={() => {
        downloadFileAs(value);
      }}
    >
      <img
        alt="icon"
        src={value.url || '/images/no_photo.jpg'}
        class="uk-border-rounded uk-margin-small-bottom  uk-box-shadow-small"
      />
      <div>
        {value.title}
      </div>
    </a>
  {:else if field.type == 'checkbox'}
    <Checkbox bind:checked={value.value} disabled />
  {:else if field.type == 'conditionalColorRing'}
    <ColorRing {value} {values} {field} {fields} />
  {:else if field.type == 'array'}
    <ul class="uk-list uk-list-small uk-margin-remove-top">
      {#each value?.rows || [] as row, i}
        <li>
          <div
            class="uk-padding-small uk-border-rounded uk-background-secondary uk-light"
          >
            {#each field.options.rows as rowField, i}
              <CustomFieldDisplay
                {videoWatcher}
                fields={field.options.rows}
                field={rowField}
                bind:value={row.customFieldValues[rowField.id]}
                values={row.customFieldValues}
              />
            {/each}
          </div>
        </li>
      {/each}
    </ul>
  {:else if field.type == 'googleFormLink' && value.url != '' && typeof value.url != 'undefined'}
    <a class="uk-button uk-button-default" href={value.url} target="_blank">
      <i class="fa-brands fa-google" />

      {$_('googleForm.GoToAssignment')}
    </a>
  {:else if field.type == 'googleFormResponseScore' && value.url != ''}
    <div class="uk-text-center">
      <Lead>
        {new Intl.NumberFormat().format(value.value) || ''}
      </Lead>
      <Button
        onclick={async () => {
          value.value = await getFormScore(field, parentFields, parentValues);
        }}
      >
        <i class="fa-brands fa-google" />
        {$_('googleForm.UpdateFormScore')}</Button
      >
    </div>
  {:else if field.type == 'file'}
    {#if value.url}
      <div class="uk-flex">
        <div>
          <a
            href={'#'}
            on:click={() => {
              downloadFileAs(value);
            }}
          >
            {#if HOST.indexOf('localhost') == -1}
              <img src={`${thumbnailCloudURL}${value.url}`} alt="" />
            {:else}
              <img src={`${value.url || value}`} alt="" />
            {/if}
            <div class="uk-flex uk-flex-between">
              <div>
                {value.title || value}
              </div>
            </div>
          </a>
          {#if value.timestamp}
            <div>
              {$_('customFields.UploadedAt')}:
              {value.timestamp &&
                DateTime.fromISO(value.timestamp).toLocaleString(
                  DateTime.DATETIME_SHORT,
                )}
            </div>
          {/if}
        </div>
      </div>
    {/if}
    {#if value.key}
      <div class="uk-flex">
        <div>
          <a
            href={'#'}
            on:click={() => {
              downloadFileLocally(value.key);
            }}
          >
            <div class="uk-flex uk-flex-between">
              <div>
                {value.title || value}
              </div>
            </div>
          </a>
          {#if value.timestamp}
            <div>
              {$_('customFields.UploadedAt')}:
              {value.timestamp &&
                DateTime.fromISO(value.timestamp).toLocaleString(
                  DateTime.DATETIME_SHORT,
                )}
            </div>
          {/if}
        </div>
      </div>
    {/if}
  {/if}
{/if}
