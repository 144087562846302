let findAvailableDesk = () => {
  let desks = document.querySelectorAll('.desk');
  let users = document.querySelectorAll('.user');
  // console.log(desks)
  if (desks.length) {
    for (let desk of desks) {
      if (users.length) {
        let someoneClose = false;
        for (let user of users) {
          var userPos = new THREE.Vector3(
            user.object3D.position.x,
            0,
            user.object3D.position.z,
          );
          var deskPos = new THREE.Vector3(
            desk.object3D.position.x,
            0,
            desk.object3D.position.z,
          );
          let distance = deskPos.distanceTo(userPos);
          if (distance < 1) {
            someoneClose = true;
            console.log('this guy is close', userPos);
            break;
          }
        }
        if (someoneClose) {
          continue;
        }
        return desk;
      }
      return desk;
    }
  }
  return false;
};

AFRAME.registerComponent('spawn-to-random-desk', {
  init: function () {
    //console.log('spawn-to-random-desk')
    let target_desk = findAvailableDesk();
    if (target_desk) {
      var spawnPoint = {
        x: target_desk.getAttribute('position').x,
        y: 1.25,
        z: target_desk.getAttribute('position').z + 0.3,
      };
    } else {
      //no desk available...
      spawnPoint = {
        x: -6,
        y: 1.25,
        z: 0,
      };
    }
    this.el.setAttribute('position', spawnPoint);
  },
});
