const setValue = (head, key, val) => {
  var index = head.morphTargetDictionary[key];
  head.morphTargetInfluences[index] = val;
};

const moveMouth = (head, level, face = 'normal') => {
  if (head) {
    level = level * 0.1;

    // console.log(head.morphTargetDictionary)
    setValue(head, 'jawOpen', level);
    setValue(head, 'mouthOpen', level);
    switch (face) {
      case 'normal':
        setValue(head, 'eyesClosed', 0);
        setValue(head, 'mouthSmile', 0);
        setValue(head, 'mouthFrownLeft', 0);
        setValue(head, 'mouthFrownRight', 0);
        setValue(head, 'browDownLeft', 0);
        setValue(head, 'browDownRight', 0);
        break;
      case 'smile':
        setValue(head, 'eyesClosed', 0);
        setValue(head, 'mouthSmile', 0.7);
        setValue(head, 'mouthFrownLeft', 0);
        setValue(head, 'mouthFrownRight', 0);
        setValue(head, 'browDownLeft', 0);
        setValue(head, 'browDownRight', 0);
        break;
      case 'frown':
        setValue(head, 'mouthSmile', 0);
        setValue(head, 'eyesClosed', 0);
        setValue(head, 'mouthFrownLeft', 1);
        setValue(head, 'mouthFrownRight', 1);
        setValue(head, 'browDownLeft', 0);
        setValue(head, 'browDownRight', 0);
        break;
      case 'angry':
        setValue(head, 'mouthSmile', 0);
        setValue(head, 'eyesClosed', 0);
        setValue(head, 'browDownLeft', 1);
        setValue(head, 'browDownRight', 1);
        setValue(head, 'mouthFrownLeft', 0);
        setValue(head, 'mouthFrownRight', 0);
        break;
      case 'relaxed':
        setValue(head, 'browDownLeft', 0);
        setValue(head, 'browDownRight', 0);
        setValue(head, 'mouthSmile', 0.7);
        setValue(head, 'eyesClosed', 1);
        setValue(head, 'mouthFrownLeft', 0);
        setValue(head, 'mouthFrownRight', 0);
        break;
    }
  }
};
export default moveMouth;
