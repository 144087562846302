<script>
  import { ioGet } from '../../lib/realtime';
  import Cookies from 'js-cookie';

  import Offcanvas from '../UI/Offcanvas.svelte';
  import { EXPERIMENTAL_FEATURE, HOST, IS_TEST } from '../../lib/config';

  import { User, SchoolRole, School } from '../../lib/store';
  import sortByKey from '../../lib/helpers/sortByKey';
  import RoleSelectOption from './RoleSelectOption.svelte';
  import { _ } from 'svelte-i18n';
  import Button from '../UI/Button.svelte';
  import selectLocale from '../../lib/helpers/selectLocale';
  import PersonaBlock from './PersonaBlock.svelte';
  import enterSchoolAs from '../../lib/helpers/enterSchoolAs';
  import Input from '../UI/Input.svelte';
  import LanguageSwitch from './LanguageSwitch.svelte';
  import logout from '../../lib/helpers/logout';
  import { personasStore } from '../../state/stores/personasStore';
  export let room = null;
  let school = null;
  School.subscribe(obj => {
    school = obj;
  });
  let sortedRoles = [];
  User.subscribe(obj => {
    if (obj.roles) {
      let promises = [];
      obj.roles.forEach(role => {
        let promise = ioGet('getSchool', {
          _id: role.schoolID,
        });
        promises.push(promise);
      });
      Promise.all(promises).then(values => {
        values.forEach((school, i) => {
          if (school === null) {
            return;
          }
          obj.roles[i].school = school;
        });
        sortedRoles = sortByKey(obj.roles, {
          key: 'schoolID',
          direction: 'asc',
        });
      });
    }
  });
  let schoolRole;
  SchoolRole.subscribe(obj => {
    schoolRole = obj;
  });
</script>

<Offcanvas right id="side-menu">
  {#if school}
    {#if schoolRole}
      <PersonaBlock schoolRoleID={$SchoolRole.id} />
      <Button
        fullwidth
        onclick={() => {
          UIkit.modal('#editNicknameAndAvatar').show();
        }}>{$_('menu.ChangeNicknameAndAvatar')}</Button
      >
    {/if}
    <hr />
  {/if}
  <ul class="uk-nav uk-nav-default">
    <li class="uk-nav-header">{$_('menu.AccountMenu')}</li>
    <li>
      <a href={'#'} on:click={logout}>{$_('Logout')}</a>
    </li>
    <li>
      <a
        href="/reset-password"
        on:click={() => {
          UIkit.offcanvas('#side-menu').hide();
        }}>{$_('ChangePassword')}</a
      >
    </li>
    {#if sortedRoles.length > 1}
      <li class="uk-nav-divider" />
      <li class="uk-nav-header">{$_('menu.SwitchSchool')}</li>
      <li>
        <a href={'#'} uk-toggle="target:#sideMenuPersonaList">
          {$_('menu.SwitchSchool')}
        </a>
        <div id="sideMenuPersonaList" hidden>
          <ul class="uk-list">
            {#each sortedRoles.filter(role => typeof role.school != 'undefined') as role}
              <li>
                <a
                  href={'#'}
                  on:click={() => {
                    enterSchoolAs(role.id);
                    UIkit.offcanvas('#side-menu').hide();
                  }}
                  class="color:grey"
                >
                  <PersonaBlock small schoolRoleID={role.id} />
                </a>
              </li>
            {/each}
          </ul>
        </div>
      </li>
    {/if}
    <li class="uk-nav-divider" />
    <li class="uk-nav-heading">
      {$_('menu.Language')}
    </li>
    <li>
      <LanguageSwitch />
    </li>
    {#if IS_TEST}
      <li>
        <a
          href={'#'}
          on:click={() => {
            UIkit.modal('#versionHistory').show();
          }}
        >
          {$_('VersionHistory')}
        </a>
      </li>
    {/if}
  </ul>
</Offcanvas>
