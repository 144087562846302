<script>
  import CategoryCheckboxes from '../blocks/CategoryCheckboxes.svelte';

  import { TargetPersona, School } from '../../lib/store';
  import { ioGet } from '../../lib/realtime';
  import { _ } from 'svelte-i18n';
  import Button from '../UI/Button.svelte';
  import PersonaBlock from '../blocks/PersonaBlock.svelte';
  import { onMount } from 'svelte';
  import { hideModal } from '../../actions/modalController';
  import { Notification } from '../../actions/notification';
  export let targetPersona = null;
  export let registrations = [];
  const save = async () => {
    await ioGet('updatePersona', {
      schoolRoleID: targetPersona.id,
      data: {
        registrations: registrations.map(registration => {
          return { programID: registration };
        }),
      },
    });

    Notification($_('Saved'));
    hideModal('#editRegistrations');
  };
</script>

{#if targetPersona && $School}
  {#key targetPersona.id}
    <PersonaBlock
      withRole={false}
      withCustomFields={true}
      schoolRoleID={targetPersona.id}
    />
  {/key}
  <ul uk-tab>
    {#each $School.intervals as interval}
      <li>
        <a href={'#'}>
          {interval.title}
        </a>
      </li>
    {/each}
  </ul>
  <ul class="uk-switcher">
    {#each $School.intervals as interval}
      <li>
        {#if typeof targetPersona.registrations != 'undefined' && interval.programCategories}
          <div class="uk-margin-left">
            {#each interval.programCategories.filter(category => category.useToGroupUser) as category}
              {category.title}
              {#each category.groups as group}
                <div>
                  <label>
                    <input
                      type="checkbox"
                      class="uk-checkbox"
                      value={group.id}
                      bind:group={registrations}
                      name="registrations"
                      on:change={evt => {
                        console.log(evt);
                        let el = evt.target.parentNode.nextElementSibling;
                        console.log(el);
                        if (el) {
                          let checkboxes =
                            el.querySelectorAll('[type=checkbox]');
                          console.log(checkboxes);
                          checkboxes.forEach(checkbox => {
                            if (evt.target.checked) {
                              checkbox.disabled = true;
                              if (registrations.indexOf(checkbox.value) == -1) {
                                registrations.push(checkbox.value);
                              }
                            } else {
                              checkbox.disabled = false;
                            }
                          });
                          registrations = registrations;
                          console.log(registrations);
                        }
                      }}
                    />
                    {group.title}
                  </label>
                  {#if group.children.length > 0}
                    <div class="uk-margin-left">
                      <ul class="uk-subnav">
                        {#each group.children as child}
                          <li>
                            <label>
                              <input
                                type="checkbox"
                                class="uk-checkbox"
                                value={child.id}
                                bind:group={registrations}
                                name="registrations"
                              />
                              {child.title}
                            </label>
                          </li>
                        {/each}
                      </ul>
                    </div>
                  {/if}
                </div>
              {/each}
            {/each}
          </div>
        {/if}
      </li>
    {/each}
  </ul>
  <Button onclick={save}>{$_('Save')}</Button>
{/if}
