import { User, SchoolRole, megaphoneActive, Room } from '../lib/store';
import { io } from '../lib/realtime.js';
let room = null;
let voiceFadeMaxDistance = 10;
Room.subscribe(obj => {
  room = obj;
  voiceFadeMaxDistance = parseInt(obj?.maxVoiceDistance) || 10;
  console.log({ voiceFadeMaxDistance });
});
let user;
User.subscribe(obj => {
  user = obj;
});
let schoolRole;
SchoolRole.subscribe(obj => {
  schoolRole = obj;
});
let ifMegaphoneActive;
megaphoneActive.subscribe(bool => {
  ifMegaphoneActive = bool;
});

AFRAME.registerComponent('fade-my-voice', {
  schema: {
    enabled: { default: true, type: 'boolean' },
  },
  init: function () {},
  tick: function () {
    if (
      this.data.enabled &&
      document.getElementById(schoolRole.id) &&
      document.getElementById('voiceOf_' + this.el.id)
    ) {
      if (!ifMegaphoneActive) {
        let distance = this.el.object3D.position.distanceTo(
          document.getElementById(schoolRole.id).object3D.position,
        );
        var level =
          Math.max(0, 2 + voiceFadeMaxDistance - distance) /
          voiceFadeMaxDistance;
      } else {
        var level = 1;
      }
      if (this.el.getAttribute('ampLevel') != null) {
        level = level * this.el.getAttribute('ampLevel');
      }
      level = Math.max(Math.min(level, 1), 0);

      const isMutable = this.el.getAttribute('isMutable');

      if (isMutable === 'false') {
        io.emit('audioOnDistance', {
          me: schoolRole.id,
          participantId: this.el.id,
          megaphone: true,
          level,
        });
      } else {
        io.emit('audioOnDistance', {
          me: schoolRole.id,
          participantId: this.el.id,
          megaphone: false,
          level,
        });
      }
    }
  },
});
