<script lang="ts">
  import { createItem } from '../../actions/itemController';
  import AvatarPreview from '../blocks/AvatarPreview.svelte';

  import { _ } from 'svelte-i18n';
  import { hideModal, showModal } from '../../actions/modalController';
  import { thumbnailCloudURL } from '../../lib/defaultValues';
  import { PresetModels } from '../../lib/presets/presetModels';
  import thumbnailNGList from '../../lib/presets/thumbnailNGList';
  import {
    ImageEditCallback,
    ImageEditSquare,
    ImageEditURL,
  } from '../../lib/store';
  import FileUpload from '../blocks/FileUpload.svelte';
  import SketchFabImporter from '../blocks/SketchFabImporter.svelte';
  import VideoFileUpload from '../blocks/VideoFileUpload.svelte';
  import Button from '../UI/Button.svelte';
  let id = 'uploadDialog';
  let url = '';
  let videoId = '';
  let video = null;
  let body = '';
  let textColor = '#ffffff';
  let backgroundColor: string = '#333333';
  let scale: number = 1;
  let thumbnail: string = '';
  export let fileType: string = '';
  $: modalTitle = getModalTitle(fileType);
  let AvatarPreviewMount;
  const getModalTitle = (fileType: string) => {
    switch (fileType) {
      case 'gltf-model':
        return $_('upload.Upload3DModel');
        break;
      case 'audio':
        return $_('upload.UploadAudio');
        break;
      case 'video':
        return $_('upload.UploadVideo');
        break;
      case 'image':
        return $_('upload.UploadImage');
        break;
      case '360video':
        return $_('upload.Upload360Video');
        break;
      case '360image':
        return $_('upload.Upload360Image');
        break;
      case 'postIt':
        url = '/images/postIt.png';
        fileType = 'image';
        editImage();
        return $_('upload.UploadImage');
        break;
      default:
        return $_('upload.UploadAFile');
    }
  };

  const addItemFor = async (position: string) => {
    createItem(position, url, fileType, {
      fileType: fileType,
      videoId: videoId,
      video: video,
      thumbnail: thumbnail,
      textColor: textColor,
      backgroundColor: backgroundColor,
      initialScale: {
        x:
          fileType == '360image' || fileType == '360video' ? scale * -1 : scale,
        y: scale,
        z: scale,
      },
    });
    url = '';
    body = '';
    videoId = '';
    thumbnail = '';
    hideModal('#' + id);
  };

  const onUploadComplete = res => {
    UIkit.notification({
      message: $_('upload.UploadCompleted'),
      status: 'primary',
      pos: 'top-center',
      timeout: 5000,
    });
    url = res.data.url;
  };
  const onVideoUploadComplete = res => {
    UIkit.notification({
      message: $_('upload.UploadCompleted'),
      status: 'primary',
      pos: 'top-center',
      timeout: 5000,
    });
    console.log(res);
    url = res.assets.mp4;
    videoId = res.videoId;
    video = res;
  };
  const sketchFabPathReturned = path => {
    url = path;
  };
  const cancelUpload = () => {
    url = '';
  };
  const editImage = () => {
    console.log({ url });
    if (fileType == 'file' || fileType == 'audio') {
      if (thumbnailNGList.indexOf(url.split('.').pop()) > -1) {
        ImageEditURL.set(`/images/${fileType}.png`);
      } else {
        ImageEditURL.set(
          url.indexOf('localhost') > -1 ? url : thumbnailCloudURL + url,
        );
      }
    } else {
      ImageEditURL.set(url);
    }
    ImageEditSquare.set(false);
    ImageEditCallback.set(res => {
      if (fileType == 'file' || fileType == 'audio') {
        thumbnail = res.data.url;
      } else {
        url = res.data.url;
      }
    });
    showModal('#imageEditor');
  };
</script>

{#if (fileType == 'image' || fileType == '360image') && url != ''}
  <img src={url} class="uk-width-medium" alt="image" />
  <Button onclick={editImage}>{$_('Edit')}</Button>
{:else if (fileType == 'file' || fileType == 'audio') && url != ''}
  {#if thumbnailNGList.indexOf(url.split('.').pop()) > -1}
    {#if thumbnail}
      <img src={thumbnail} class="uk-width-medium" alt="image" />
    {:else}
      <img
        src={`/images/${fileType}.png`}
        class="uk-width-medium"
        alt="image"
      />
    {/if}
    <Button onclick={editImage}>{$_('EditThumbnail')}</Button>
  {:else}
    {#if thumbnail}
      <img src={thumbnail} class="uk-width-medium" alt="image" />
    {:else}
      <img src={thumbnailCloudURL + url} class="uk-width-medium" alt="image" />
    {/if}
    <Button onclick={editImage}>{$_('Edit')}</Button>
  {/if}
{:else if (fileType == 'video' || fileType == '360video') && url != ''}
  <video src={url} class="uk-width-medium" id="uploadVideoPreview" />
{:else if fileType == 'audio' && url != ''}
  <audio src={url} class="uk-width-medium" />
{:else if fileType == 'gltf-model' && url != ''}
  <div class="uk-margin">
    <AvatarPreview
      id="addModelPreview"
      {url}
      width={400}
      height={400}
      distance={1.5}
      {scale}
      bind:mount={AvatarPreviewMount}
    />
  </div>
  <select
    class="uk-select"
    bind:value={scale}
    on:change={() => {
      AvatarPreviewMount.do();
    }}
  >
    <option value={1}>Original Size (1/1)</option>
    <option value={0.001}>mm to meter (1/1000)</option>
    <option value={0.01}>cm to meter (1/100)</option>
    <option value={0.0254}>inch to meter (1/39.37)</option>
    <option value={0.305}>foot to meter (1/3.28)</option>
    <option value={0.02}>1/50</option>
    <option value={0.1}>1/10</option>
    <option value={0.2}>1/5</option>
    <option value={0.5}>1/2</option>
  </select>
  <p>
    {$_('upload.PleaseMakeSureYourModelIsntTooBig')}
  </p>
{/if}

<div class="actions center">
  {#if url == '' && videoId == '' && body == ''}
    {#if fileType != 'text'}
      {#if fileType == 'video'}
        <VideoFileUpload onUploadComplete={onVideoUploadComplete}
          >{$_('Upload')}</VideoFileUpload
        >
      {:else}
        <FileUpload {fileType} {onUploadComplete}>{$_('Upload')}</FileUpload>
      {/if}
      {#if fileType == 'gltf-model'}
        <hr class="uk-margin" />
        <p>{$_('upload.YouCanAlsoDownload')}</p>
        <ul uk-tab>
          <li>
            <a href={'#'}> SketchFab </a>
          </li>
          <li>
            <a href={'#'}>
              {$_('presetModels.PresetModels')}
            </a>
          </li>
        </ul>
        <ul class="uk-switcher">
          <li>
            <div>
              <SketchFabImporter returnFilePath={sketchFabPathReturned} />
            </div>
          </li>
          <li>
            <div>
              <div uk-grid class="uk-grid-small">
                {#each PresetModels as model}
                  <div>
                    <AvatarPreview
                      id={'presetModel' + model.id}
                      url={model.url}
                      distance={1.5}
                    />
                    <Button
                      onclick={() => {
                        url = model.url;
                      }}>{$_('presetModels.ChooseThis')}</Button
                    >
                  </div>
                {/each}
              </div>
            </div>
          </li>
        </ul>
      {/if}
    {/if}
  {:else}
    <div class="uk-margin">
      <Button onclick={() => addItemFor('room')}>{$_('scene.ToRoom')}</Button>
      {#if fileType != '360image' && fileType != '360video'}
        <Button onclick={() => addItemFor('dashboard')}
          >{$_('scene.ToDashboard')}</Button
        >
      {/if}
      <Button onclick={cancelUpload}>{$_('Cancel')}</Button>
    </div>
  {/if}
</div>
