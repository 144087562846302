import Cookies from 'js-cookie';
import { ioFetch } from '../realtime';
import { navigate } from 'svelte-routing';
import { ComeBackURL, LoggedIn, User } from '../store';
const checkLogin = async () => {
  if (Cookies.get('login')) {
    let loginRes = await ioFetch('checkLogin', Cookies.get('login'));
    if (loginRes.status == 'approved') {
      //valid login code
      User.set(loginRes.user);
      LoggedIn.set(true);
      if (!loginRes.user.password && !loginRes.user.isGuest) {
        ComeBackURL.set(location.href);
        navigate('/set-password');
      }
      //navigate('/')
    } else {
      //invali login code
      Cookies.remove('login');
      return;
    }
    return loginRes;
  } else {
    return {
      status: 'ng',
    };
  }
};
export default checkLogin;
