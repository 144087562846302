<script lang="ts">
  import { onMount } from 'svelte';
  import { navigate, Route, Router } from 'svelte-routing';
  import { ioGet } from '../../lib/realtime';
  import { School } from '../../lib/store';
  import VerticalTab from '../UI/VerticalTab.svelte';
  import AssignmentCategoryCustomFields from './AssignmentCategoryCustomFields.svelte';
  import NavList from './NavList.svelte';
  import PersonaBlock from './PersonaBlock.svelte';
  import { _ } from 'svelte-i18n';
  import EventViewCreateMenu from './EventViewCreateMenu.svelte';
  import { defineIfUndefined } from '../../lib/helpers/defineIfUndefined';
  import Button from '../UI/Button.svelte';
  import Dropdown from '../UI/Dropdown.svelte';
  import ArrayNav from './ArrayNav.svelte';
  import Input from '../UI/Input.svelte';
  import MetaText from '../UI/MetaText.svelte';
  import arraySwap from '../../lib/helpers/arraySwap';
  import areYouSure from '../../lib/helpers/areYouSure';
  import EventAttendanceList from './EventAttendanceList.svelte';
  import SubscriberAttendanceCreditTable from './SubscriberAttendanceCreditTable.svelte';
  import SubscriberAssignmentTable from './SubscriberAssignmentTable.svelte';
  import SubscriberGrades from './SubscriberGrades.svelte';
  import ViewSettingDropdown from './ViewSettingDropdown.svelte';
  import { Notification } from '../../actions/notification';
  import { DateTime } from 'luxon';
  import { personasStore } from '../../state/stores/personasStore';
  export let breadcrumb = '';
  export let interval;
  let focus = location.href
    .split(interval.id + '/')
    .pop()
    .split('/')
    .shift()

    .replace('#', '');
  let focus2 = '';
  if (focus) {
    focus2 = location.href
      .split(focus + '/')
      .pop()
      .split('/')
      .shift()
      .replace('#', '');
  } else {
    focus2 = '';
  }
  let subscribers = [];
  let school;
  let styles = ['Program', 'Attendance'];
  School.subscribe(obj => {
    if (obj) {
      obj.subscriberEventViews = defineIfUndefined(
        obj.subscriberEventViews,
        [],
      );
      school = obj;
    }
  });
  const saveCategory = async () => {
    let res = await ioGet('updateSchool', {
      _id: school._id,
      data: {
        assignmentCategories: school.assignmentCategories,
        subscriberEventViews: school.subscriberEventViews,
      },
    });
    Notification($_('Saved'));
    School.set(school);
  };
  const saveView = async () => {
    let res = await ioGet('updateSchool', {
      _id: school._id,
      data: {
        subscriberEventViews: school.subscriberEventViews,
      },
    });
    Notification($_('Saved'));
    School.set(school);
  };
  onMount(async () => {
    let res = await ioGet('listSubscribers', {
      intervalID: interval.id,
    });
    if (res == 'notLoggedinYet') {
    } else {
      subscribers = res;
      if (subscribers.length == 0) {
        Notification('no subscriber in this interval');
        return;
      }
      if (focus) {
        //navigate(focus);
      } else {
        //navigate(subscribers[0].id);
        focus = subscribers[0].id;
      }
      if (!focus2) {
        focus2 = $School.assignmentCategories[0].id;
      }
      navigate(breadcrumb + focus + '/' + $School.assignmentCategories[0].id);
    }
  });
  const moveUp = (i: number) => {
    school.subscriberEventViews = arraySwap(
      school.subscriberEventViews,
      i,
      i - 1,
    );
    saveView();
  };
  const moveDown = (i: number) => {
    saveView();
    school.subscriberEventViews = arraySwap(
      school.subscriberEventViews,
      i,
      i + 1,
    );
  };
  const remove = (i: number) => {
    areYouSure(school.subscriberEventViews[i].title, () => {
      school.subscriberEventViews.splice(i, 1);
      school.subscriberEventViews = school.subscriberEventViews;
      saveView();
    });
  };
</script>

<VerticalTab>
  <ul slot="menu" class="uk-tab uk-tab-left">
    {#each subscribers as subscriber}
      <NavList
        bind:focus
        slug={subscriber.id + '/' + focus2}
        breadcrumb={'/' + $School.slug + '/subscribers/' + interval.id + '/'}
      >
        <PersonaBlock
          schoolRoleID={subscriber.id}
          small
          withRole={false}
          withCustomFields
          withChat={false}
        />
      </NavList>
    {/each}
  </ul>
  <div slot="content">
    <Router>
      {#each subscribers as subscriber}
        {@const breadcrumb =
          '/' +
          $School.slug +
          '/subscribers/' +
          interval.id +
          '/' +
          subscriber.id +
          '/'}
        <Route path={subscriber.id + '/*'}>
          <PersonaBlock
            bind:schoolRoleID={subscriber.id}
            withRole={false}
            withCustomFields
            withChat
          />
          {#if subscriber.lastLogin}
            {$_('LastLoginAt')}
            :
            {DateTime.fromISO(subscriber.lastLogin).toLocaleString(
              DateTime.DATETIME_SHORT,
            )}
          {/if}
          <ul class="uk-tab">
            {#each $School.assignmentCategories as assignmentCategory}
              <NavList
                bind:focus={focus2}
                slug={assignmentCategory.id}
                {breadcrumb}
              >
                {assignmentCategory.title}
              </NavList>
            {/each}
            {#each $School.subscriberEventViews || [] as view}
              <NavList bind:focus={focus2} slug={view.id} {breadcrumb}>
                {view.title}
              </NavList>
            {/each}
            <NavList bind:focus={focus2} slug="grades" {breadcrumb}>
              {$_('Grades')}
            </NavList>
            <li>
              <EventViewCreateMenu
                {styles}
                bind:eventViews={school.subscriberEventViews}
                {school}
                {saveView}
              />
            </li>
          </ul>
          <div>
            <Router>
              {#each $School.assignmentCategories as assignmentCategory}
                <Route path={assignmentCategory.id}>
                  <SubscriberAssignmentTable
                    {saveCategory}
                    {subscriber}
                    {interval}
                    {assignmentCategory}
                  />
                </Route>
              {/each}
              {#each school.subscriberEventViews || [] as view, i}
                <Route path={view.id + '/*'}>
                  <div class="uk-text-right uk-width-expand">
                    <ViewSettingDropdown
                      {styles}
                      bind:views={school.subscriberEventViews}
                      bind:view
                      categories={school.eventCategories}
                      {i}
                    />
                  </div>
                  {#if view.style == 'byProgram'}
                    <SubscriberAttendanceCreditTable
                      {view}
                      {saveCategory}
                      {interval}
                      {subscriber}
                    />
                  {/if}
                  {#if view.style == 'byAttendance'}
                    <EventAttendanceList {interval} {subscriber} {view} />
                  {/if}
                </Route>
              {/each}
              <Route path="grades">
                <SubscriberGrades {interval} {subscriber} />
              </Route>
            </Router>
          </div>
        </Route>
      {/each}
    </Router>
  </div>
</VerticalTab>
