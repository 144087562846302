import getCurrentVolume from '../lib/helpers/getCurrentVolume';

import { noiseThreshold } from '../lib/store';

AFRAME.registerComponent('my-volume-meter', {
  tick: function () {
    let volume = getCurrentVolume();
    this.el.setAttribute('width', volume);
    this.el.object3D.position.x = (this.el.object3D.scale.x * volume) / 2;
  },
});
