import { get } from 'svelte/store';
import { _ } from 'svelte-i18n';
import { DateTime } from 'luxon';
export const eventCustomFields = school => {
  return [
    {
      id: 1,
      title: get(_)('Date'),
      slug: 'date',
      type: 'date',
      isPrimitive: true,
    },
    {
      id: 2,
      title: get(_)('Title'),
      slug: 'title',
      type: 'text',
      isPrimitive: true,
    },
    ...school.customFields.event,
  ];
};
export const eventCustomFieldValues = schoolEvent => {
  return {
    1: DateTime.fromISO(schoolEvent.start).toISODate(),
    2: schoolEvent.title,
    ...schoolEvent.customFieldValues,
  };
};
