<script lang="ts">
  import Input from '../UI/Input.svelte';
  import Button from '../UI/Button.svelte';
  import { _ } from 'svelte-i18n';
  import MetaText from '../UI/MetaText.svelte';
  import { DateTime } from 'luxon';
  export let grade;
  export let exam;
  export let i;
  export let readonly: boolean = false;
  export let withTrash: boolean = true;
</script>

<div
  uk-margin
  class="uk-text-small uk-margin-small-right uk-margin-remove-top
  uk-margin-remove-bottom uk-position-relative"
>
  <div>
    {#if readonly}
      {exam.title}
    {:else}
      <Input
        small
        type="text"
        bind:value={exam.title}
        label={$_('grades.ExamTitle')}
      />
    {/if}
  </div>
  <div>
    {#if readonly}
      {DateTime.fromISO(exam.date).toLocaleString()}
    {:else}
      <Input
        small
        type="date"
        bind:value={exam.date}
        label={$_('grades.ExamDate')}
      />
    {/if}
  </div>
  <div>
    {#if readonly}
      <MetaText>{$_('grades.ExamScore')}</MetaText>
      {exam.score}
    {:else}
      <Input
        small
        type="number"
        bind:value={exam.score}
        label={$_('grades.ExamScore')}
      />
    {/if}
  </div>
  <div class="uk-position-top-right" hidden={readonly}>
    {#if withTrash}
      <Button
        style="link"
        small
        onclick={() => {
          grade.exams.splice(i, 1);
          grade = grade;
        }}
      >
        <i class="fa-duotone fa-trash fa-fw" />
      </Button>
    {/if}
  </div>
</div>
