<script>
  export let titleSize = 'h1';
</script>

<article class="uk-article uk-position-relative">
  <div uk-grid class="uk-grid-small">
    <div>
      <slot name="thumbnail" />
    </div>
    <div>
      {#if titleSize == 'h1'}
        <h1><slot name="title" /></h1>
      {:else if titleSize == 'h2'}
        <h2><slot name="title" /></h2>
      {:else if titleSize == 'h3'}
        <h3><slot name="title" /></h3>
      {/if}
      <p class="uk-article-meta"><slot name="meta" /></p>

      <p class="uk-text-lead"><slot name="lead" /></p>

      <slot name="body" />
      <slot name="footer" />
    </div>
  </div>
</article>
