<script>
  import upload from '../../lib/helpers/upload';
  import UUID from '../../lib/helpers/uuid';
  import Button from '../UI/Button.svelte';
  export let style = 'default';
  export let fileType = '';
  export let onUploadComplete;
  export let customButtonUrl = '';
  export let disabled = false;
  export let fullwidth = false;
  export let small = false;
  let id = UUID();

  const uploadFile = (evt, callback) => {
    evt.preventDefault();
    let fd = new FormData();
    fd.append('myFile', evt.target.files[0]);
    fd.append('myFileName', evt.target.files[0].name);
    upload(fd, callback);
  };
</script>

<input
  {id}
  type="file"
  on:change={evt => {
    uploadFile(evt, onUploadComplete);
  }}
  hidden
  accept="
{fileType == 'gltf-model' ? '.glb' : ''}
{fileType == 'image' ? 'image/*' : ''}
{fileType == 'audio' ? 'audio/*' : ''}
{fileType == 'video' ? 'video/*' : ''}
{fileType == 'csv' ? 'text/csv' : ''}
{fileType == '' ? '*' : ''}"
/>
<Button
  {fullwidth}
  {small}
  {style}
  {disabled}
  {customButtonUrl}
  onclick={evt => {
    document.getElementById(id).click();
    //    evt.target.previousElementSibling.click();
  }}><slot /></Button
>
