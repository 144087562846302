import { _ } from 'svelte-i18n';
import { writable } from 'svelte/store';
import { PersonaDefault } from '../../lib/interfaces/Persona';
import { io } from '../../lib/realtime';
import { SchoolRole } from '../../lib/store';
let schoolRole = null;
SchoolRole.subscribe(obj => {
  schoolRole = obj;
});
const personasReducer = writable([]);

let personas;
personasReducer.subscribe(array => {
  personas = array;
});

export const personasStore = {
  subscribe: personasReducer.subscribe,
  add: function (item) {
    const newItem = {
      ...PersonaDefault,
      ...item,
    };
    if (!newItem.customFieldValues) {
      newItem.customFieldValues = {};
    }
    if (!this.get(item.id)) {
      personasReducer.update(items => {
        return [...items, newItem];
      });
    }
  },
  set: function (items) {
    personasReducer.set(items);
  },
  get: id => {
    return personas.find(item => item.id == id) || null;
  },
  list: function () {
    return personas;
  },
  clear: () => {
    personasReducer.set([]);
  },

  update: (id, data) => {
    personasReducer.update(personas => {
      personas = personas.map(persona => {
        if (persona.id === id) {
          persona.isMutable = data.isMutable;
          persona.isNear = data.isNear;
        }
        return persona;
      });

      return personas;
    });
  },
};
io.on('personaDeleted', res => {
  console.log(
    'deleting',
    personas.findIndex(p => p.id == res.data.roleID),
  );
  personas.splice(
    personas.findIndex(p => p.id == res.data.roleID),
    1,
  );
  personasStore.set(personas);
});
io.on('personaAdded', res => {
  console.log('personaAdded', res);
  if (res.data.schoolID == schoolRole.schoolID) {
    //same school
    personasStore.add(res.data);
  }
});
io.on('personaUpdated', res => {
  let updated = false;
  personas = personas.map(existingItem => {
    if (existingItem.id == res.data.id) {
      updated = true;
      if (res.data.id == schoolRole?.id) {
        SchoolRole.set(res.data);
      }
      //Notification(get(_)("UpdateDetected", { values: { category: get(_)("personas"), title: res.data.title } }));
      return res.data;
    } else {
      return existingItem;
    }
  });
  if (updated) {
    personasReducer.set(personas);
  }
});
