<script>
  import axios from 'axios';
  import { onMount } from 'svelte';
  import { SERVER_URL } from '../../../lib/config';

  //We need to pass userId here
  export let progress;
  export let watchHistory;
  export let totalDuration;
  let filledAreas = [];
  let currentProgress = 0;

  $: if (progress) {
    const filled = [];
    for (let i = 0; i <= totalDuration; i++) {
      const isFilled = watchHistory.indexOf(i) > -1;
      /*
      const isFilled = progress.watchedHistory.some((watched) => {
        return watched.startTime < i && i <= watched.endTime;
      });
      */
      filled.push(isFilled);
    }

    // progress.duration = 50
    // currentProgress = ?
    // watched = 10
    // currentProgress = 10 * 100 / 50 = 20% (and round)

    //const watched = filled.reduce((acc, curr) => acc + curr, 0);
    //currentProgress = Math.round((watched * 100) / progress.duration);
    filledAreas = [...filled];
  }
</script>

<div class="wrapper">
  {#each filledAreas as filledArea}
    <div class="progress-bar">
      <span
        class="progress-bar-fill"
        style={`width: ${filledArea ? 100 : 0}%;`}
      />
    </div>
  {/each}
</div>

<style>
  .wrapper {
    /*margin-top: 1rem;*/
    height: 0.2rem;
    border-radius: 0.1rem;
    overflow: hidden;
    display: flex;
  }

  .progress-bar {
    flex: 1;
    height: 0.2rem;
    background-color: #e0e0e0;
    /*box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.2);*/
  }

  .progress-bar-fill {
    display: block;
    height: 0.2rem;
    background-color: lightseagreen;
    /*transition: width 500ms ease-in-out;*/
  }
</style>
