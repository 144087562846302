<script lang="ts">
  import type { IntervalInterface } from '../../lib/interfaces/Interval';

  export let menu: boolean = true;
  export let intervals: Array<IntervalInterface>;
</script>

<ul uk-tab="animation:uk-animation-fade" hidden={!menu}>
  {#each intervals || [] as interval}
    <li>
      <a href={'#'}>
        {interval.title}
      </a>
    </li>
  {/each}
</ul>

<slot name="content" />
