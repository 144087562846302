export const affectPresetToEvent = (category, schoolEvent) => {
  if (typeof category.eventPresets.attendanceSettings == 'undefined')
    category.eventPresets.attendanceSettings = [];
  category.eventPresets.attendanceSettings =
    category.eventPresets.attendanceSettings.map(setting => {
      setting.attendances = [];
      return setting;
    });
  Object.keys(category.eventPresets).forEach(key => {
    let avoidArr = [
      'startDate',
      'startTime',
      'endDate',
      'endTime',
      'id',
      '_id',
    ];
    if (avoidArr.indexOf(key) == -1 && key) {
      schoolEvent[key] = category.eventPresets[key];
    }
  });
  //schoolEvent.categoryLabel = category.title;
  return schoolEvent;
};
