<script>
  import { onMount } from 'svelte';
  import { DateTime } from 'luxon';
  import { _ } from 'svelte-i18n';
  import { io, ioFetch, ioGet } from '../../lib/realtime';

  export let id;
  export let onlyDate = false;
  let school = {
    roomCount: 0,
    createdAt: '2022-04-01T00:00:00',
  };
  onMount(async () => {
    school = await ioGet('getSchoolStats', {
      _id: id,
    });
  });
</script>

<ul class="uk-subnav uk-margin-remove-bottom">
  {#if !onlyDate}
    <li>
      {$_(
        school.roomCount > 1 ? 'top.RoomCount.prural' : 'top.RoomCount.single',
        {
          values: {
            number: school.roomCount,
          },
        },
      )}
    </li>
  {/if}
  <li>
    {$_('top.CreatedAt')}
    {DateTime.fromISO(school.createdAt).toFormat('yyyy/M/d')}
  </li>
</ul>
