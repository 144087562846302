import { ioGet } from '../realtime';
import { School } from '../store';
import dialog from './dialog';
import { get } from 'svelte/store';
import { _ } from 'svelte-i18n';
let school;

School.subscribe(obj => {
  school = obj;
});
export const saveInterval = async interval => {
  let res = await ioGet('updateSchoolInterval', {
    _id: school._id,
    data: interval,
  });
  dialog(get(_)('Saved'));
  return res;
};
export const saveIntervalCustomFields = async (category, interval) => {
  console.log('saving this data', interval);
  let res = await ioGet('updateSchoolIntervalCustomFields', {
    schoolID: school._id,
    intervalID: interval.id,
    category: category,
    data: interval.customFields[category],
  });
  dialog(get(_)('Saved'));
  return res;
};
