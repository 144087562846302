<script>
  import { onMount } from 'svelte';
  import { _ } from 'svelte-i18n';
  import { navigate } from 'svelte-routing';
  import { HOST } from '../../lib/config';
  import { ioGet } from '../../lib/realtime';
  import Button from '../UI/Button.svelte';

  export let roomID;
  export let asGuest = false;
  let room = null;
  let school = null;
  onMount(async () => {
    room = await ioGet('getRoom', {
      _id: roomID,
    });
    if (room) {
      school = await ioGet('getSchool', {
        _id: room.schoolID,
      });
    }
  });
</script>

{#if room && school}
  <div>
    <div>
      {room.title}
    </div>
    {#if asGuest}
      {#if room.invitations?.length > 0}
        <Button
          onclick={() => {
            location.href = `/${school.slug}/${room.slug}/scene?invitation=${room.invitations[0].id}`;
          }}
        >
          {$_('selectRoom.EnterRoom')}
        </Button>
      {:else}
        {$_('NoGuestAccessAllowed')}
      {/if}
    {:else}
      <Button
        onclick={() => navigate('/' + school.slug + '/' + room.slug + '/scene')}
      >
        {$_('selectRoom.EnterRoom')}
      </Button>
    {/if}
  </div>
{/if}
