import { DateTime } from 'luxon';
const AssetDefault = {
    url: '',
    title: '',
    fileType: '',
    filterCategories: [],
    linkedPrograms: [],
    filterRoles: [],
    filterByRole: false,
    updatedAt: DateTime.now().toISO(),
    createdAt: DateTime.now().toISO(),
    startDate: DateTime.now().toISO(),
};
export { AssetDefault };
