<script lang="ts">
  import Input from '../UI/Input.svelte';
  import { _ } from 'svelte-i18n';
  import Div from '../UI/Div.svelte';
  import MetaText from '../UI/MetaText.svelte';
  import TinyTextarea from './TinyTextarea.svelte';
  import Lead from '../UI/Lead.svelte';
  import AvatarPreview from './AvatarPreview.svelte';
  import Button from '../UI/Button.svelte';
  import Modal from '../UI/Modal.svelte';
  import PresetAvatars from '../Modals/presetAvatars.svelte';
  import CreateAvatar from './CreateAvatar.svelte';
  import FileUpload from './FileUpload.svelte';
  import CustomFieldDisplay from './CustomFieldComponents/CustomFieldDisplay.svelte';
  import CustomFieldEditOrDisplay from './CustomFieldComponents/CustomFieldEditOrDisplay.svelte';
  import {
    ImageEditCallback,
    ImageEditSquare,
    ImageEditURL,
    School,
    User,
  } from '../../lib/store';
  import { onMount } from 'svelte';
  import upload from '../../lib/helpers/upload';
  import { ioGet } from '../../lib/realtime';
  import { showModal } from '../../actions/modalController';
  import { UUID } from '../../lib/uuid';
  import CustomFieldInput from './CustomFieldComponents/CustomFieldInput.svelte';
  import { navigate } from 'svelte-routing';
  export let persona;
  export let school;
  export let role;
  let previewBackgroundColor = '#bbbbdd';
  let mountAvatar;
  let id = UUID();
  const changePreviewBackgroundColor = () => {
    if (mountAvatar) {
      mountAvatar.do();
    }
  };
  onMount(() => {
    if (mountAvatar) {
      mountAvatar.do();
    }
    console.log(
      school.customFields[role.category].filter(
        field => field.permissions[role.id].canEdit,
      ),
    );
  });
  let showAvatarCapture: boolean = false;
  const captureAvatarThumbnail = async () => {
    if (mountAvatar) {
      mountAvatar.render();
      document
        .getElementById('AvatarPreview' + id)
        .querySelector('canvas')
        .toBlob(
          function (blob) {
            var formData = new FormData(); //this will submit as a "multipart/form-data" request
            formData.append('myFile', blob); //"image_name" is what the server will call the blob
            upload(formData, async res => {
              persona.profileImageURL = res.data.url;
              let res2 = await ioGet('updatePersona', {
                schoolRoleID: persona.id,
                data: {
                  profileImageURL: res.data.url,
                },
              });
              UIkit.toggle('#captureAvatarPreview' + id).toggle();
              persona.profileImageURL = res.data.url;
            }); //upload the "formData", not the "blob"
          },
          'image/jpeg',
          0.8,
        );
    }
  };
  const setAvatarURL = (url: string) => {
    persona.avatarURL = url;
    setTimeout(function () {
      mountAvatar.do();
    }, 300);
  };
  const loadRPMAvatar = async url => {
    let path = await ioGet('loadExternalFile', {
      url: url,
    });
    setAvatarURL(path);
  };
</script>

<Div>
  <div uk-grid class="uk-grid-divider uk-grid-small uk-child-width-1-2@s">
    <div>
      <div uk-grid>
        <div>
          {#if persona.profileImageURL}
            <img
              src={persona.profileImageURL}
              class="uk-border-circle"
              style="width:80px;height:80px"
              alt=""
            />
          {/if}
          <FileUpload
            fileType="image"
            small
            onUploadComplete={res => {
              ImageEditURL.set(res.data.url);
              ImageEditSquare.set(true);
              ImageEditCallback.set(res => {
                persona.profileImageURL = res.data.url;
              });
              showModal('#imageEditor');
            }}>{$_('Upload')}</FileUpload
          >
        </div>
        <div>
          <Div>
            <Input
              label={$_('Nickname')}
              type="text"
              bind:value={persona.nickname}
              placeholder={$_('Nickname')}
            />
          </Div>
          <!-- <Div>
            <Input
              label={$_('Status')}
              required
              bind:value={persona.onlineStatus}
            />
          </Div> -->
        </div>
      </div>
      <!--
        Biographyもバーチャル教室以外で表示していないのでコメントアウトしておきます。
        <div class="uk-margin">
        <div class="uk-text-meta">{$_('Biography')}</div>
        <TinyTextarea bind:value={persona.bio} />
      </div> -->
      <!-- <div class="uk-margin">
        <div class="uk-text-meta">
          {$_('persona.Height')}
        </div>
        <div uk-grid>
          <div class="uk-width-expand">
            <input
              type="range"
              class="uk-range"
              min="1.2"
              max="2.4"
              step="0.01"
              bind:value={persona.height}
            />
          </div>
          <div>
            <span
              >{persona.height?.toString().padEnd(4, '0') || '1.6'} meters</span
            >
          </div>
        </div>
      </div> -->

      <!-- <Input required bind:value={persona.avatarURL} hidden />
      <div class="uk-margin">
        <Lead>{$_('Avatar')}</Lead>
        <div class="uk-position-relative" style="width:200px;">
          {#if persona.avatarURL}
            <AvatarPreview
              bgColor={previewBackgroundColor}
              showGrid={false}
              hideAvatarHands
              circle
              id={'AvatarPreview' + id}
              bind:mount={mountAvatar}
              bind:url={persona.avatarURL}
            />
          {/if}
          <div class="uk-position-bottom-right">
            <button
              class="uk-button uk-button-text"
              on:click={() => {
                showAvatarCapture = !showAvatarCapture;
              }}><i class="fa-duotone fa-camera fa-lg" /></button
            >
          </div>
        </div>
        {#if showAvatarCapture}
          <div id={'captureAvatarPreview' + id} style="width:200px">
            <div class="uk-text-meta">
              {$_('enterRoom.CaptureAvatarIcon')}
            </div>
            <div class="uk-margin uk-padding-small">
              <div class="uk-text-meta">
                {$_('enterRoom.SetBackgroundColor')}
              </div>

              <div class="uk-width-expand">
                <input
                  type="color"
                  on:change={changePreviewBackgroundColor}
                  bind:value={previewBackgroundColor}
                />
              </div>
              <div class="uk-margin">
                <Button fullwidth small onclick={captureAvatarThumbnail}
                  >{$_('enterRoom.Capture')}</Button
                >
              </div>
            </div>
          </div>
        {/if}
        <div>
          <Button style="text" onclick={() => showModal('#presetAvatars')}
            ><i class="fa-duotone fa-rectangle-history-circle-user" />
            {$_('enterRoom.ChooseFromExistingAvatars')}</Button
          >
        </div>
        <Modal
          id="presetAvatars"
          stack={true}
          title={$_('enterRoom.ChooseAvatar')}
          size="container"
        >
          <div slot="body">
            <PresetAvatars selectedURL={setAvatarURL} />
          </div>
        </Modal>
        <CreateAvatar {loadRPMAvatar}
          ><i class="fa-duotone fa-user-plus" />
          {$_('enterRoom.CreateNewAvatar')}</CreateAvatar
        >
        <div>
          <FileUpload
            fileType="gltf-model"
            style="text"
            onUploadComplete={res => {
              setAvatarURL(res.data.filePath);
            }}
            ><i class="fa-duotone fa-upload" />
            {$_('enterRoom.UploadYourGLBFile')}</FileUpload
          >
        </div>
      </div> -->
    </div>
    <div>
      {#each school.customFields[role.category].filter(field => field.permissions[role.id].canEdit) || [] as field}
        <div class="uk-margin">
          <MetaText>
            {field.title}
          </MetaText>
          <CustomFieldInput
            {field}
            bind:value={persona.customFieldValues[field.id]}
            parentFields={[]}
            parentValues={{}}
          />
        </div>
      {/each}
    </div>
  </div>
</Div>
