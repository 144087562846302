<script>
  import { onMount } from 'svelte';
  import SunCalc from 'suncalc';
  import { DateTime } from 'luxon';
  import { AdjustTime } from '../../lib/store';
  let skyColors = [
    {
      title: 'sunrise',
      dark: {
        r: 71,
        g: 164,
        b: 225,
      },
      light: {
        r: 254,
        g: 234,
        b: 183,
      },
    },
    {
      title: 'noon',
      dark: {
        r: 0,
        g: 45,
        b: 250,
      },
      light: {
        r: 90,
        g: 173,
        b: 243,
      },
    },
    {
      title: 'sunset',
      dark: {
        r: 61,
        g: 53,
        b: 112,
      },
      light: {
        r: 252,
        g: 164,
        b: 30,
      },
    },
    {
      title: 'midnight',
      dark: {
        r: 0,
        g: 0,
        b: 0,
      },
      light: {
        r: 0,
        g: 0,
        b: 0,
      },
    },
  ];
  AdjustTime.subscribe(time => {
    // updateSky()
  });
  const getColor = (begin, end, color, step) => {
    let lightDiff =
      skyColors.find(color => color.title == end).light[color] -
      skyColors.find(color => color.title == 'sunrise').light[color];
    let darkDiff =
      skyColors.find(color => color.title == end).dark[color] -
      skyColors.find(color => color.title == 'sunrise').dark[color];
    let light = Math.round(
      skyColors.find(color => color.title == begin).light[color] -
        lightDiff * step,
    );
    let dark = Math.round(
      skyColors.find(color => color.title == begin).dark[color] -
        darkDiff * step,
    );
    return {
      light: light,
      dark: dark,
    };
  };
  const getColors = (begin, end, step) => {
    let Rs = getColor(begin, end, 'r', step);
    let Gs = getColor(begin, end, 'g', step);
    let Bs = getColor(begin, end, 'b', step);
    let light = {
      r: Rs.light,
      g: Gs.light,
      b: Bs.light,
    };
    let dark = {
      r: Rs.dark,
      g: Gs.dark,
      b: Bs.dark,
    };
    return [light, dark];
  };
  let colors = [];
  let sunPos = {
    x: 0,
    y: 0,
    z: 0,
  };

  const updateSky = () => {
    var times = SunCalc.getTimes(new Date(), 35.6, 139.8); //tokyo
    let sunriseNum =
      DateTime.fromJSDate(times.sunrise).toFormat('HH') * 1 +
      parseInt(DateTime.fromJSDate(times.sunrise).toFormat('m')) / 60;
    let noonNum =
      DateTime.fromJSDate(times.solarNoon).toFormat('HH') * 1 +
      parseInt(DateTime.fromJSDate(times.sunrise).toFormat('m')) / 60;
    let sunsetNum =
      DateTime.fromJSDate(times.sunset).toFormat('HH') * 1 +
      parseInt(DateTime.fromJSDate(times.sunrise).toFormat('m')) / 60;
    let midnightNum =
      DateTime.fromJSDate(times.nadir).toFormat('HH') * 1 +
      parseInt(DateTime.fromJSDate(times.sunrise).toFormat('m')) / 60;
    let currentNum =
      DateTime.fromJSDate(new Date()).toFormat('HH') * 1 +
      parseInt(DateTime.fromJSDate(new Date()).toFormat('m')) / 60;
    //let currentNum = $AdjustTime
    //console.log('times', sunriseNum, noonNum, sunsetNum, midnightNum, currentNum)
    if (currentNum > sunriseNum && currentNum <= noonNum) {
      //am
      let span = noonNum - sunriseNum;
      let step = (currentNum - sunriseNum) / span;
      colors = getColors('sunrise', 'noon', step);
    } else if (currentNum > noonNum && currentNum <= sunsetNum) {
      //pm
      let span = sunsetNum - noonNum;
      let step = (currentNum - noonNum) / span;
      colors = getColors('noon', 'sunset', step);
    } else if (currentNum > sunsetNum && currentNum <= midnightNum) {
      //evening
      let span = midnightNum - sunsetNum;
      let step = (currentNum - sunsetNum) / span;
      colors = getColors('sunset', 'midnight', step);
    } else {
      //dawn
      let span = 24 + sunriseNum - midnightNum;
      let step = (24 + currentNum - midnightNum) / span;
      colors = getColors('midnight', 'sunrise', step);
    }
    //console.log(colors)

    //position
    let sunPosition = SunCalc.getPosition(new Date(), 35.6, 139.8);
    // console.log(sunPosition)
    let vector = new THREE.Vector3(0, 0, 1);
    vector.applyAxisAngle(new THREE.Vector3(-1, 0, 0), sunPosition.altitude);
    vector.applyAxisAngle(new THREE.Vector3(0, -1, 0), sunPosition.azimuth);
    sunPos = vector;
    // console.log(sunPos)
  };
  onMount(async () => {
    updateSky();
    setInterval(function () {
      updateSky();
    }, 60000);
  });
</script>

{#if colors.length}
  <a-simple-sun-sky
    sun-position="{sunPos.x} {sunPos.y} {sunPos.z}"
    light-color="rgba({colors[0].r},{colors[0].g},{colors[0].b},1)"
    dark-color="rgba({colors[1].r},{colors[1].g},{colors[1].b},1)"
    fog-color="rgba({Math.min(255, Math.floor(colors[0].r * 1.1))},{Math.min(
      255,
      Math.floor(colors[0].g * 1.1),
    )},{Math.min(255, Math.floor(colors[0].b * 1.1))},1)"
    radius="3000"
  />
{/if}
<a-entity light="type: ambient; color: #BBB" />
<a-entity
  light="type: directional; color: #FFF; intensity: 0.6"
  position="{sunPos.x} {sunPos.y} {sunPos.z}"
/>
