<script>
  import { DateTime } from 'luxon';
  import { onMount } from 'svelte';
  import { navigate } from 'svelte-routing';
  import { EventToCreate, School, TargetEvent } from '../lib/store';
  import { listEvents } from '../actions/eventController';
  import { ioFetch, ioGet } from '../lib/realtime';
  import { _ } from 'svelte-i18n';
  import fixEventDate from '../lib/helpers/fixEventDate';
  import prepareEventForCalendar from '../lib/helpers/prepareEventForCalendar';
  import CalendarController from '../actions/calendarController';

  onMount(async () => {
    console.log('calling events');
    let events = await listEvents();
    events = events.map(schoolEvent => prepareEventForCalendar(schoolEvent));
    console.log(events);
    CalendarController.ini(document.getElementById('eventCalendar'), events);
  });
</script>

<svelte:head>
  <title>{$_('Events')} | {$School.title} - AVOS</title>
</svelte:head>
<div id="eventCalendar" />
