<script lang="ts">
  import MyPaginationNav from './MyPaginationNav.svelte';

  import EmailValidator from 'email-validator';
  import Cookies from 'js-cookie';
  import Papa from 'papaparse';
  import { onMount } from 'svelte';
  import { _ } from 'svelte-i18n';
  import { paginate } from 'svelte-paginate';
  import { showModal } from '../../actions/modalController';
  import { Notification } from '../../actions/notification';
  import { HOST } from '../../lib/config';
  import areYouSure from '../../lib/helpers/areYouSure';
  import { defineIfUndefined } from '../../lib/helpers/defineIfUndefined';
  import dialog from '../../lib/helpers/dialog';
  import switchSort from '../../lib/helpers/switchSort';
  import { ioGet } from '../../lib/realtime';
  import { School, SchoolRole } from '../../lib/store';
  import { personasStore } from '../../state/stores/personasStore';
  import RegisterUserByCsv from '../../v2/components/AccountRegistrationByCsv.svelte';
  import AccountDetailModal from '../Modals/AccountDetailModal.svelte';
  import BulkRegistration from '../Modals/BulkRegistration.svelte';
  import EditRegistrations from '../Modals/EditRegistrations.svelte';
  import Button from '../UI/Button.svelte';
  import Checkbox from '../UI/Checkbox.svelte';
  import Input from '../UI/Input.svelte';
  import Modal from '../UI/Modal.svelte';
  import CustomFieldEditOrDisplay from './CustomFieldComponents/CustomFieldEditOrDisplay.svelte';
  import FilterInput from './FilterInput.svelte';
  import LinkToDocumentation from './LinkToDocumentation.svelte';
  import PersonaBlock from './PersonaBlock.svelte';

  export let role;
  let userRoles = [];
  let unfilteredUserRoles = [];
  personasStore.subscribe(array => {
    array = personasStore.list().filter(persona => persona.roleID == role.id);
    userRoles = array;
    unfilteredUserRoles = array;
  });

  const myPaginate = (items, pageSize, currentPage) => {
    if (!items || typeof items == 'undefined') {
      return [];
    } else {
      return paginate({ items, pageSize, currentPage });
    }
  };
  let pageSize = 20;
  let currentPage = 1;
  $: paginatedItems = myPaginate(userRoles, pageSize, currentPage);

  let updatePersonaBlock = false;
  let fields = [];
  onMount(async () => {
    fields = ($School.customFields[role.category] || []).filter(
      field => field.showOnList,
    );
    if (!fields || fields.length === 0) return;
    fields.forEach(field => {
      if (field.showOnBadge) {
        updatePersonaBlock = true;
      }
    });
  });

  const sendInvitationEmail = async userRole => {
    await ioGet('sendInvitationEmail', {
      userID: userRole._id,
      locale: Cookies.get('locale'),
      senderName: $SchoolRole.nickname,
      schoolTitle: $School.title,
    });
    Notification($_('accounts.InvitationSent'));
  };
  const deleteRole = async (roleID, userID) => {
    let res = personasStore.get(roleID);
    areYouSure(res.nickname, async () => {
      let res = await ioGet('revokeRole', {
        roleID: roleID,
        userID: userID,
      });
    });
  };
  const filteredUserRoles = array => {
    userRoles = array;
    //paginatedItems = myPaginate(userRoles, pageSize, currentPage);
  };
  let targetPersona;
  let targetPersonas;
  let invitingEmail;
  let invitingNickname;
  /* 開始の学期 **/
  let invitingStartingIntervalId: string;
  let bulkStartingIntervalId: string;
  let invitingCustomFieldValues;

  const invite = async (userDetails: {
    email: string;
    roleId: string;
    schoolId: string;
    nickname?: string;
    enrolmentIntervalId?: string;
    customFieldValues?: any;
  }) => {
    const {
      email,
      roleId,
      schoolId,
      nickname,
      enrolmentIntervalId,
      customFieldValues,
    } = userDetails;

    if (!EmailValidator.validate(email)) {
      //email not OK
      dialog($_('login.NotAValidEmail'));
      return;
    }

    const isStudentRole =
      $School.roles.find(schoolRole => schoolRole.id === roleId)?.category ===
      'subscriber';

    const res = await ioGet('addUserRole', {
      userEmail: email,
      roleId: roleId,
      schoolId: schoolId,
      nickname: nickname || $_('MyNickname'),
      enrolmentIntervalId: enrolmentIntervalId,
      isStudent: isStudentRole,
      customFieldValues: customFieldValues,
    });

    if (res.result == 'already has role') {
      dialog($_('accounts.AlreadyHasTheSameRole'));
      return;
    }
    location.reload();
  };

  const openUserRegistrationModal = async userRoleID => {
    let res = await ioGet('getPersona', {
      schoolRoleID: userRoleID,
    });
    res.customFieldValues = defineIfUndefined(res.customFieldValues, {});
    res.registrations = defineIfUndefined(res.registrations, []);
    targetPersona = res;
    showModal('#editRegistrations');
  };
  const openUserDetailModal = async userRoleID => {
    let res = await ioGet('getPersona', {
      schoolRoleID: userRoleID,
    });
    res.customFieldValues = defineIfUndefined(res.customFieldValues, {});
    res.registrations = defineIfUndefined(res.registrations, []);
    targetPersona = res;
    setTimeout(() => {
      showModal('#accountDetail');
    }, 100);
  };
  const processCSV = async (
    unicodeText: string,
    role: any,
    startingIntervalId: string,
  ): Promise<void> => {
    Papa.parse(unicodeText, {
      header: true,
      skipEmptyLines: true,
      complete: res2 => {
        const isStudentRole =
          $School.roles.find(schoolRole => schoolRole.id === role.id)
            ?.category === 'subscriber';

        let test = res2.data[0];
        if (typeof test.email == 'undefined') {
          dialog($_('accounts.EmailFieldNotSet'));
          return;
        }
        if (typeof test.nickname == 'undefined') {
          dialog($_('accounts.NicknameFieldNotSet'));
          return;
        }

        let promises = [];
        res2.data.forEach(row => {
          if (!row.email || !row.nickname) return;
          let customFieldValues = {};
          let keys = Object.keys(row);
          keys.forEach(key => {
            if (key == 'email' || key == 'nickname') {
              return;
            }
            let field = $School.customFields[role.category].find(
              field => field.title == key,
            );
            if (field) {
              customFieldValues[field.id] = row[key];
            }
          });

          const userDetails = {
            email: row.email,
            roleId: role.id,
            schoolId: $School._id,
            nickname: row.nickname,
            enrolmentIntervalId: startingIntervalId,
            customFieldValues: customFieldValues,
            isStudent: isStudentRole,
          };
          promises.push(invite(userDetails));
        });
        Promise.all(promises).then(res => {
          console.log(res);
        });
      },
    });
  };

  const save = async userRole => {
    let res = await ioGet('updatePersona', {
      schoolRoleID: userRole.id,
      data: userRole,
    });

    if (updatePersonaBlock) {
      //list();
    }
    Notification($_('Saved'));
  };
  const bulkRegistration = () => {
    let selected = userRoles.filter(role => role.selected);
    targetPersonas = selected;
    showModal('#bulkRegistration');
  };
  const sort = key => {
    userRoles = switchSort(userRoles, key);
  };
  const onChange = userRole => {
    //list();
  };
  const bulkDelete = async () => {
    areYouSure('Selected Users', async () => {
      let selected = userRoles.filter(role => role.selected);
      let promises = [];
      selected.forEach(role => {
        promises.push(
          ioGet('revokeRole', {
            roleID: role.id,
            userID: role._id,
          }),
        );
      });
      Promise.all(promises).then(res => {
        dialog($_('Saved'));
      });
    });
  };
</script>

<div>

  <div>
    <FilterInput
      returnArray={res => filteredUserRoles(res)}
      unfilteredArray={unfilteredUserRoles}
    />
  </div>
  <MyPaginationNav totalItems={userRoles.length} {pageSize} bind:currentPage />

  <table
    class="uk-table uk-table-divider uk-table-small uk-table-justify uk-table-middle"
  >
    <thead>
      <tr>
        <th>
          <Checkbox
            bind:checked={role.allChecked}
            onchange={() => {
              userRoles = userRoles.map(userRole => {
                if (userRole.roleID == role.id) {
                  userRole.selected = role.allChecked;
                }

                return userRole;
              });
            }}
          />
        </th>
        <th>
          <a
            href={'#'}
            on:click={() => {
              sort('nickname');
            }}
          >
            {$_('Nickname')}
          </a></th
        >
        <th>
          <a
            href={'#'}
            on:click={() => {
              sort('email');
            }}
          >
            {$_('Email')}
          </a>
        </th>

        <th>
          <a href={'#'} on:click={() => sort('status')}>
            {$_('Status')}
          </a>
        </th>
        {#each fields || [] as field}
          <th>
            <a
              href={'#'}
              on:click={() => {
                sort({ field: field.id });
              }}
            >
              {field.title}
            </a>
          </th>
        {/each}
        <th>
          <a href={'#'} on:click={bulkRegistration}>
            <i class="fa-duotone fa-check fa-fw fa-lg" />
            {$_('accounts.BulkEdit')}
          </a>
          <a href={'#'} on:click={bulkDelete}>
            <i class="fa-duotone fa-check fa-fw fa-lg" />
            {$_('accounts.BulkDelete')}
          </a>
        </th>
      </tr>
    </thead>
    <tbody>
      {#each paginatedItems as userRole}
        <tr>
          <td>
            <Checkbox bind:checked={userRole.selected} />
          </td>
          <td>
            {#key userRole.id}
              <PersonaBlock
                withRole={false}
                withCustomFields={false}
                withChat={false}
                bind:schoolRoleID={userRole.id}
              />
            {/key}
          </td>
          <td>
            {userRole.email || ''}
          </td>

          <td>
            {#if userRole.status == 'pending'}
              {$_('Pending')}
            {/if}
          </td>
          {#each fields || [] as field}
            <td>
              {#if userRole.customFieldValues}
                <CustomFieldEditOrDisplay
                  withLabel={false}
                  {field}
                  {fields}
                  bind:value={userRole.customFieldValues[field.id]}
                  bind:values={userRole.customFieldValues}
                />
              {/if}
            </td>
          {/each}
          <td style="min-width:40px;">
            <ul class="uk-subnav">
              <li>
                <a href={'#'} on:click={() => save(userRole)}>
                  <i class="fa-duotone fa-save fa-fw fa-lg" />
                  {$_('Save')}
                </a>
              </li>
              <li>
                <a href={'#'} on:click={() => openUserDetailModal(userRole.id)}>
                  <i class="fa-duotone fa-edit fa-fw fa-lg" />
                  {$_('Edit')}
                </a>
              </li>
              <li>
                <a
                  href={'#'}
                  on:click={() => openUserRegistrationModal(userRole.id)}
                >
                  <i class="fa-duotone fa-check fa-fw fa-lg" />
                  {$_('accounts.Registration')}
                </a>
              </li>
              {#if userRole.status == 'pending'}
                <li>
                  <a href={'#'} on:click={() => sendInvitationEmail(userRole)}>
                    <i class="fa-duotone fa-envelope fa-fw fa-lg" />
                    {$_('accounts.Invite')}
                  </a>
                </li>
              {/if}
              <li>
                <a
                  href={'#'}
                  on:click={() => {
                    deleteRole(userRole.id, userRole._id);
                  }}
                >
                  <i class="fa-duotone fa-trash fa-fw fa-lg" />
                  {$_('Delete')}
                </a>
              </li>
            </ul>
          </td>
        </tr>
      {/each}
    </tbody>
  </table>
  <MyPaginationNav totalItems={userRoles.length} {pageSize} bind:currentPage />

  <div class="uk-margin">
    <h3 class="uk-header">{$_('accounts.Create')}</h3>
    <Checkbox
      bind:checked={role.allowRegistrationsByUsers}
      onchange={async () => {
        let roles = $School.roles;
        roles = roles.map(r => {
          if (r.id == role.id) {
            r = role;
          }
          return r;
        });
        await ioGet('updateSchool', {
          _id: $School._id,
          data: {
            roles: roles,
          },
        });
        dialog($_('Saved'));
      }}
    >
      {$_('accounts.AllowRegistrationsByUsers')}
    </Checkbox>
    {#if role.allowRegistrationsByUsers}
      <Input
        copiable
        readonly
        value={HOST + '/register/' + $School.slug + '/' + role.id}
      />
    {/if}
  </div>
  {#if $School.roles.find(schoolRole => schoolRole.id == $SchoolRole.roleID)?.school.account.canCreate}
    <Input
      type="email"
      bind:value={invitingEmail}
      placeholder={$_('Email')}
      margin={'small'}
    />
    <Input
      type="text"
      bind:value={invitingNickname}
      placeholder={$_('Nickname')}
      margin={'small'}
    />
    {#if $School.roles.find(schoolRole => schoolRole.id === role.id)?.category === 'subscriber'}
      <label
        uk-tooltip={'title: ' + $_('tooltip.StartingInterval') + ';pos: left'}
        >{$_('schoolSetting.StartingInterval')}</label
      >
      <select bind:value={invitingStartingIntervalId} class="uk-input">
        {#each $School.intervals as interval}
          <option value={interval.id}>{interval.title}</option>
        {/each}
      </select>
    {/if}
    <Button
      margin={'small'}
      onclick={() => {
        invite({
          roleId: role.id,
          email: invitingEmail,
          schoolId: $School._id,
          nickname: invitingNickname,
          enrolmentIntervalId: invitingStartingIntervalId,
        });
      }}>{$_('accounts.Create')}</Button
    >
    <div class="uk-margin">
      <div uk-alert class="uk-background-secondary uk-light uk-border-rounded">
        <h3 class="uk-header">{$_('accounts.BulkCreate')}</h3>
        {#if $School.roles.find(schoolRole => schoolRole.id === role.id)?.category === 'subscriber'}
          <label
            uk-tooltip={'title: ' +
              $_('tooltip.StartingInterval') +
              ';pos: left'}>{$_('schoolSetting.StartingInterval')}</label
          >
          <select
            bind:value={bulkStartingIntervalId}
            class="uk-input uk-margin-small"
          >
            {#each $School.intervals as interval}
              <option value={interval.id}>{interval.title}</option>
            {/each}
          </select>
        {/if}
        <RegisterUserByCsv
          fileType="csv"
          onUploadComplete={csvText =>
            processCSV(csvText, role, bulkStartingIntervalId)}
        />
        <p>
          {$_('accounts.CSVInstruction')}
        </p>
      </div>
    </div>
  {/if}
</div>
<Modal id="editRegistrations" title={$_('EditRegistrations')}>
  <div slot="body">
    {#if targetPersona}
      <EditRegistrations
        registrations={targetPersona.registrations?.map(
          registration => registration.programID,
        ) || []}
        bind:targetPersona
      />
    {/if}
  </div>
</Modal>
<Modal id="bulkRegistration" title={$_('BulkRegistration')}>
  <div slot="body">
    {#if targetPersonas}
      <BulkRegistration {targetPersonas} />
    {/if}
  </div>
</Modal>
{#if targetPersona}
  <AccountDetailModal {role} bind:persona={targetPersona} {onChange} />
{/if}
