import { addMessages, getLocaleFromNavigator, init } from 'svelte-i18n';
import Cookies from 'js-cookie';
import en from './locales/en.json';
import jp from './locales/jp.json';

addMessages('en', en);
addMessages('ja', jp);
let locale =
  typeof Cookies.get('locale') == 'undefined'
    ? getLocaleFromNavigator()
    : Cookies.get('locale');
Cookies.set('locale', locale);
init({
  fallbackLocale: 'en',
  initialLocale: locale,
});
