<script>
  import { CometChat } from '@cometchat-pro/chat';
  import { onMount } from 'svelte';
  import { _ } from 'svelte-i18n';
  import { ChatUnreadCount, SchoolRole } from '../lib/store';
  import { COMETCHAT_APP_ID, COMETCHAT_AUTH_KEY, HOST } from '../lib/config';
  import { UUID } from '../lib/uuid';
  import { chatLogin, checkUnreadMessages } from '../lib/helpers/chatLogin';
  let loggedIn = false;
  import nl2br from 'nl2br';
  import { showModal } from '../actions/modalController';
  import { Notification } from '../actions/notification';
  import { ioGet } from '../lib/realtime';
  import { getChatAuthToken } from '../lib/helpers/getChatAuthToken';
  let authToken = null;
  onMount(async () => {
    console.log('chat mounted');
    SchoolRole.subscribe(async obj => {
      if (obj !== null && typeof obj != 'undefined' && obj.id != loggedIn) {
        const user = new CometChatWidget.CometChat.User(obj.id);
        console.log({ user });
        user.setName(obj.nickname);
        user.setAvatar(obj.profileImageURL || HOST + '/images/no_photo.jpg');
        if (loggedIn) {
          let response = await CometChatWidget.logout();
          console.log('logged out');
          loggedIn = false;
        }
        loggedIn = obj.id;
        if (
          typeof obj.cometChatAuthToken == 'undefined' ||
          !obj.cometChatAuthToken
        ) {
          obj.cometChatAuthToken = await getChatAuthToken(obj.id);
        }
        console.log({ authTOken: obj.cometChatAuthToken });
        if (!obj.cometChatAuthToken) {
          //no user in cometchat
        }
        chatLogin(
          user,
          async () => {
            CometChatWidget.launch({
              widgetID: '1f27d958-67f8-430b-a98e-d4a9b69381d6',
              target: '#cometchat',
              roundedCorners: 'false',
              height: '90vh',
              width: '100%',
            });
            checkUnreadMessages();
            CometChat.addUserListener(
              UUID(),
              new CometChat.UserListener({
                onUserOnline: onlineUser => {
                  console.log('On User Online:', {
                    onlineUser,
                  });
                },
                onUserOffline: offlineUser => {
                  console.log('On User Offline:', offlineUser.name);
                },
              }),
            );

            CometChat.addMessageListener(
              UUID(),
              new CometChat.MessageListener({
                onTextMessageReceived: textMessage => {
                  console.log(
                    'Text message received successfully',
                    textMessage,
                  );
                  console.log(document.getElementById('chatModal').classList);
                  if (
                    textMessage.sender.uid != $SchoolRole.id &&
                    !document
                      .getElementById('chatModal')
                      .classList.contains('uk-open')
                  ) {
                    let html = `
                <div class="uk-grid-small uk-width-expand" uk-grid>
                  <div>
                  <img src="${
                    textMessage.sender.avatar
                  }" style="width:40px;height:40px" class="uk-border-circle" />
                  </div>
                  <div class="uk-width-expand">
                  ${textMessage.sender.name}
                <div class=" uk-width-expand">${textMessage.text}</div>
                <div class="uk-text-right uk-width-expand">
                <a href="#" class="uk-button uk-button-text uk-light uk-button-small" onclick="UIkit.modal('#chatModal').show()">${$_(
                  'Open',
                )}</a>
                </div>
                </div>
                <div>`;
                    Notification(html);
                  }
                },
                onMediaMessageReceived: mediaMessage => {
                  console.log(
                    'Media message received successfully',
                    mediaMessage,
                  );
                  if (
                    mediaMessage.sender.uid != $SchoolRole.id &&
                    !document
                      .getElementById('chatModal')
                      .classList.contains('uk-open')
                  ) {
                    let html = `
                <div class="uk-grid-small uk-width-expand" uk-grid>
                  <div>
                  <img src="${
                    mediaMessage.sender.avatar
                  }" style="width:40px;height:40px" class="uk-border-circle" />
                  </div>
                  <div class="uk-width-expand">
                  ${mediaMessage.sender.name}
                <div class=" uk-width-expand">${$_(
                  'chat.SentMediaMessage',
                )}</div>
                <div class="uk-text-right uk-width-expand">
                <a href="#" class="uk-button uk-button-text uk-light uk-button-small" onclick="UIkit.modal('#chatModal').show()">${$_(
                  'Open',
                )}</a>
                </div>
                </div>
                <div>`;
                    Notification(html);
                  }
                },
                onCustomMessageReceived: customMessage => {
                  console.log(
                    'Custom message received successfully',
                    customMessage,
                  );
                  if (
                    customMessage.sender.uid != $SchoolRole.id &&
                    !document
                      .getElementById('chatModal')
                      .classList.contains('uk-open')
                  ) {
                    let html = `
                <div class="uk-grid-small uk-width-expand" uk-grid>
                  <div>
                  <img src="${
                    customMessage.sender.avatar
                  }" style="width:40px;height:40px" class="uk-border-circle" />
                  </div>
                  <div class="uk-width-expand">
                  ${customMessage.sender.name}
                <div class=" uk-width-expand">${$_(
                  'chat.SentMediaMessage',
                )}</div>
                <div class="uk-text-right uk-width-expand">
                <a href="#" class="uk-button uk-button-text uk-light uk-button-small" onclick="UIkit.modal('#chatModal').show()">${$_(
                  'Open',
                )}</a>
                </div>
                </div>
                <div>`;
                    Notification(html);
                  }
                },
              }),
            );
            CometChat.addCallListener(
              UUID(),
              new CometChat.CallListener({
                onIncomingCallReceived: call => {
                  console.log('Incoming call:', call);
                  if (call.callInitiator.uid != $SchoolRole.id) {
                    showModal('#chatModal');
                  }
                },
                onOutgoingCallAccepted: call => {
                  console.log('Outgoing call accepted:', call);
                },
                onOutgoingCallRejected: call => {
                  console.log('Outgoing call rejected:', call);
                },
                onIncomingCallCancelled: call => {
                  console.log('Incoming call cancelled:', call);
                  if (call.callItitiator.uid != $SchoolRole.id) {
                    Notification('Incoming call calcelled:');
                  }
                },
              }),
            );
          },
          obj.cometChatAuthToken,
        );
        //SchoolRole.set(obj);
      }
    });
  });
</script>

<div id="cometchat" />
