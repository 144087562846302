export const customFieldTypes = [
  {
    slug: 'number',
    title: 'Number',
    isPrimitive: true,
    isSimple: true,
  },
  {
    slug: 'text',
    title: 'Text',
    isPrimitive: true,
    isSimple: true,
  },
  {
    slug: 'multiLineText',
    title: 'MultiLineText',
    isPrimitive: true,
    isSimple: false,
  },
  {
    slug: 'richText',
    title: 'RichText',
    isPrimitive: true,
    isSimple: false,
  },
  {
    slug: 'select',
    title: 'Select',
    isPrimitive: true,
    isSimple: true,
  },
  {
    slug: 'selectMultiple',
    title: 'SelectMultiple',
    isPrimitive: true,
    isSimple: true,
  },
  {
    slug: 'checkbox',
    title: 'Checkbox',
    isPrimitive: true,
    isSimple: true,
  },
  {
    slug: 'date',
    title: 'Date',
    isPrimitive: true,
    isSimple: true,
  },
  {
    slug: 'time',
    title: 'Time',
    isPrimitive: true,
    isSimple: true,
  },
  {
    slug: 'datetime-local',
    title: 'DateTime',
    isPrimitive: true,
    isSimple: true,
  },
  {
    slug: 'endOfMonth',
    title: 'EndOfMonth',
    isPrimitive: true,
    isSimple: true,
  },
  {
    slug: 'color',
    title: 'Color',
    isPrimitive: true,
    isSimple: true,
  },
  {
    slug: 'image',
    title: 'Image',
    isPrimitive: true,
    isSimple: false,
  },
  {
    slug: 'file',
    title: 'FileUpload',
    isPrimitive: true,
    isSimple: false,
  },
  {
    slug: 'video',
    title: 'Video',
    isPrimitive: true,
    isSimple: false,
  },
  {
    slug: 'videoProgress',
    title: 'VideoProgress',
    isPrimitive: true,
    isSimple: false,
  },
  {
    slug: 'button',
    title: 'ActionButton',
    isPrimitive: false,
    isSimple: true,
  },
  {
    slug: 'autoCalculation',
    title: 'AutoCalculation',
    isPrimitive: false,
    isSimple: false,
  },
  {
    slug: 'conditionalValue',
    title: 'ConditionalValue',
    isPrimitive: false,
    isSimple: false,
  },
  {
    slug: 'conditionalTime',
    title: 'ConditionalTime',
    isPrimitive: false,
    isSimple: false,
  },
  {
    slug: 'conditionalColorRing',
    title: 'ConditionalColorRing',
    isPrimitive: false,
    isSimple: false,
  },
  {
    slug: 'reference',
    title: 'ReferenceToOtherField',
    isPrimitive: false,
    isSimple: false,
  },
  {
    slug: 'inherit',
    title: 'InheritFromParentGroup',
    isPrimitive: false,
    isSimple: false,
  },
  {
    slug: 'array',
    title: 'Array',
    isPrimitive: true,
    isSimple: false,
  },
  {
    slug: 'googleFormLink',
    title: 'GoogleFormLink',
    isPrimitive: true,
    isSimple: true,
  },
  {
    slug: 'googleFormResponseScore',
    title: 'GoogleFormResponseScore',
    isPrimitive: true,
    isSimple: true,
  },
];
