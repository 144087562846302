const assignAnimation = (
  targetEl,
  animationClipName,
  secondAnimation = null,
) => {
  if (targetEl) {
    targetEl.setAttribute('animation-mixer', 'clip', animationClipName);
    //targetEl.setAttribute('animation-mixer', 'startFrame', 0)
    targetEl.setAttribute('animation-mixer', 'crossFadeDuration', 0.5);
    if (secondAnimation) {
      targetEl.setAttribute('animation-mixer', 'loop', 'once');
    } else {
      targetEl.setAttribute('animation-mixer', 'loop', 'repeat');
    }
  }
};
export default assignAnimation;
