<script lang="ts">
  import { onMount } from 'svelte';
  import { _ } from 'svelte-i18n';
  import { showModal } from '../../actions/modalController';
  import { Notification } from '../../actions/notification';
  import { setLookRiggerHeight } from '../../lib/helpers/aframeHelpers/setLookRiggerHeight';
  import appendAvatarAsset from '../../lib/helpers/appendAvatarAsset';
  import { defineIfUndefined } from '../../lib/helpers/defineIfUndefined';
  import dialog from '../../lib/helpers/dialog';
  import upload from '../../lib/helpers/upload';
  import UUID from '../../lib/helpers/uuid';
  import type { Avatar } from '../../lib/interfaces/Avatar';
  import {
    PersonaDefault,
    PersonaInterface,
  } from '../../lib/interfaces/Persona';
  import { io, ioGet } from '../../lib/realtime';
  import {
    ImageEditCallback,
    ImageEditSquare,
    ImageEditURL,
    School,
    SchoolRole,
    User,
  } from '../../lib/store';
  import PresetAvatars from '../Modals/presetAvatars.svelte';
  import Button from '../UI/Button.svelte';
  import Input from '../UI/Input.svelte';
  import Lead from '../UI/Lead.svelte';
  import Modal from '../UI/Modal.svelte';
  import AvatarPreview from './AvatarPreview.svelte';
  import CreateAvatar from './CreateAvatar.svelte';
  import CustomFieldEditOrDisplay from './CustomFieldComponents/CustomFieldEditOrDisplay.svelte';
  import FileUpload from './FileUpload.svelte';
  import TinyTextarea from './TinyTextarea.svelte';
  import { RoleCategory } from '../../v2/models/types';

  School.subscribe(obj => {
    if (obj) {
      obj.customFields = defineIfUndefined(obj.customFields, {});
    }
  });
  let previewBackgroundColor = '#bbbbdd';

  let avatars: Array<Avatar> = [];
  let schoolRole: PersonaInterface | null = {
    ...PersonaDefault,
    schoolID: $School?._id,
    selected: false,
  };
  SchoolRole.subscribe(obj => {
    if (obj) {
      obj.customFieldValues = defineIfUndefined(obj.customFieldValues, {});
      schoolRole = obj;
    }
  });
  const onUploadComplete = res => {
    setAvatarURL(res.data.url);
  };
  const setAvatarURL = (url: string) => {
    schoolRole.avatarURL = url;
    SchoolRole.set(schoolRole);
    setTimeout(function () {
      mountAvatar.do();
    }, 300);
  };
  const loadRPMAvatar = async url => {
    let path = await ioGet('loadExternalFile', {
      url: url,
    });
    console.log('uploaded avatar', url);
    setAvatarURL(path);
  };

  const openPresetAvatars = () => {
    showModal('#preset-avatars');
  };
  const saveChange = async () => {
    if (!schoolRole.nickname || !schoolRole.avatarURL) {
      dialog($_('PleaseFillOutRequiredField'));
      return;
    }
    if (!schoolRole.height) {
      schoolRole.height = 1.6;
    }
    let res = await ioGet('updatePersona', {
      schoolRoleID: $SchoolRole.id,
      data: schoolRole,
    });
    if (!$SchoolRole.profileImageURL) {
      await captureAvatarThumbnail();
    }
    SchoolRole.set(schoolRole);
    Notification($_('Saved'));
    if (document.querySelector('a-scene')) {
      appendAvatarAsset(schoolRole.id, schoolRole.avatarURL);
      document.getElementById('myNickname').innerHTML = schoolRole.nickname;
      document.getElementById('myAvatar').removeAttribute('gltf-model');
      document.getElementById('myAvatarPreview').removeAttribute('gltf-model');
      document
        .getElementById('myAvatar')
        .setAttribute('gltf-model', '#avatarFile_' + schoolRole.id);
      document
        .getElementById('myAvatarPreview')
        .setAttribute('gltf-model', '#avatarFile_' + schoolRole.id);
      setLookRiggerHeight(schoolRole.height);

      io.emit('updateNicknameAndAvatar', {
        schoolRoleID: schoolRole.id,
      });
    }
    if (typeof $User.avatars == 'undefined') {
      avatars = [];
    } else {
      avatars = $User.avatars;
    }
    if (avatars.find((avatar: Avatar) => avatar.url == schoolRole.avatarURL)) {
      //already there
    } else {
      document
        .getElementById('enterAvatarPreview')
        .querySelector('canvas')
        .toBlob(
          function (blob) {
            var formData = new FormData(); //this will submit as a "multipart/form-data" request
            blob.name = 'something.jpg';
            formData.append('myFile', blob); //"image_name" is what the server will call the blob
            upload(formData, async res => {
              avatars.push({
                id: UUID(),
                url: schoolRole.avatarURL,
                thumbnail: res.data.url,
              });
              await ioGet('updateUser', {
                _id: $User._id,
                data: {
                  avatars: avatars,
                },
              });
              User.set({
                ...$User,
                ...{
                  avatars: avatars,
                },
              });
            }); //upload the "formData", not the "blob"
          },
          'image/jpeg',
          0.8,
        );
    }
  };
  let mountAvatar;

  const changePreviewBackgroundColor = () => {
    if (mountAvatar) {
      mountAvatar.do();
    }
  };
  onMount(() => {
    if (mountAvatar) {
      mountAvatar.do();
    }
  });
  let showAvatarCapture: boolean = false;
  const captureAvatarThumbnail = async () => {
    if (mountAvatar) {
      mountAvatar.render();
      document
        .getElementById('enterAvatarPreview')
        .querySelector('canvas')
        .toBlob(
          function (blob) {
            var formData = new FormData(); //this will submit as a "multipart/form-data" request
            formData.append('myFile', blob); //"image_name" is what the server will call the blob
            upload(formData, async res => {
              schoolRole.profileImageURL = res.data.url;
              let res2 = await ioGet('updatePersona', {
                schoolRoleID: $SchoolRole.id,
                data: {
                  profileImageURL: res.data.url,
                },
              });
              UIkit.toggle('#captureAvatarPreview').toggle();
              schoolRole.profileImageURL = res.data.url;
            }); //upload the "formData", not the "blob"
          },
          'image/jpeg',
          0.8,
        );
    }
  };
</script>

{#if $SchoolRole && $School}
  {@const role = $School.roles.find(role => role.id == $SchoolRole.roleID)}

  <div uk-grid class="uk-grid-divider uk-grid-small uk-child-width-1-2@s">
    <div>
      <div uk-grid>
        <div>
          {#if schoolRole?.profileImageURL}
            <img
              src={schoolRole?.profileImageURL}
              class="uk-border-circle"
              style="width:80px;height:80px"
              alt=""
            />
          {/if}
          <FileUpload
            fileType="image"
            small
            onUploadComplete={res => {
              ImageEditURL.set(res.data.url);
              ImageEditSquare.set(true);
              ImageEditCallback.set(res => {
                schoolRole.profileImageURL = res.data.url;
              });
              showModal('#imageEditor');
            }}
          >
            {$_('Upload')}
          </FileUpload>
        </div>
        {#if role.category !== RoleCategory.Student}
          <div>
            <Input
              label={$_('Nickname')}
              required
              bind:value={schoolRole.nickname}
            />
            <Input
              label={$_('Status')}
              required
              bind:value={schoolRole.onlineStatus}
            />
          </div>
        {/if}
      </div>
      <div class="uk-margin">
        <div class="uk-text-meta">{$_('Biography')}</div>
        <TinyTextarea bind:value={schoolRole.bio} />
      </div>
      <div class="uk-margin">
        <div class="uk-text-meta">
          {$_('persona.Height')}
        </div>
        <div uk-grid>
          <div class="uk-width-expand">
            <input
              type="range"
              class="uk-range"
              min="1.2"
              max="2.4"
              step="0.01"
              bind:value={schoolRole.height}
            />
          </div>
          <div>
            <span
              >{schoolRole.height?.toString().padEnd(4, '0') || '1.6'} meters</span
            >
          </div>
        </div>
      </div>

      <Input required bind:value={schoolRole.avatarURL} hidden />
      <div class="uk-margin">
        <Lead>{$_('Avatar')}</Lead>
        <div class="uk-position-relative" style="width:200px;">
          {#if schoolRole.avatarURL}
            <AvatarPreview
              bgColor={previewBackgroundColor}
              showGrid={false}
              hideAvatarHands
              circle
              id="enterAvatarPreview"
              bind:mount={mountAvatar}
              bind:url={schoolRole.avatarURL}
            />
          {/if}
          <div class="uk-position-bottom-right">
            <button
              class="uk-button uk-button-text"
              on:click={() => {
                showAvatarCapture = !showAvatarCapture;
              }}><i class="fa-duotone fa-camera fa-lg" /></button
            >
          </div>
        </div>
        {#if showAvatarCapture}
          <div id="captureAvatarPreview" style="width:200px">
            <div class="uk-text-meta">
              {$_('enterRoom.CaptureAvatarIcon')}
            </div>
            <div class="uk-margin uk-padding-small">
              <div class="uk-text-meta">
                {$_('enterRoom.SetBackgroundColor')}
              </div>

              <div class="uk-width-expand">
                <input
                  type="color"
                  on:change={changePreviewBackgroundColor}
                  bind:value={previewBackgroundColor}
                />
              </div>
              <div class="uk-margin">
                <Button fullwidth small onclick={captureAvatarThumbnail}
                  >{$_('enterRoom.Capture')}</Button
                >
              </div>
            </div>
          </div>
        {/if}
        <div>
          <Button style="text" onclick={openPresetAvatars}
            ><i class="fa-duotone fa-rectangle-history-circle-user" />
            {$_('enterRoom.ChooseFromExistingAvatars')}</Button
          >
        </div>
        <Modal
          id="preset-avatars"
          stack={true}
          title={$_('enterRoom.ChooseAvatar')}
          size="container"
        >
          <div slot="body">
            <PresetAvatars selectedURL={setAvatarURL} />
          </div>
        </Modal>
        <CreateAvatar {loadRPMAvatar}
          ><i class="fa-duotone fa-user-plus" />
          {$_('enterRoom.CreateNewAvatar')}</CreateAvatar
        >
        <div>
          <FileUpload fileType="gltf-model" style="text" {onUploadComplete}
            ><i class="fa-duotone fa-upload" />
            {$_('enterRoom.UploadYourGLBFile')}</FileUpload
          >
        </div>
      </div>
    </div>
    <div>
      {#each $School.customFields[$School.roles.find(r => $SchoolRole.roleID == r.id)?.category] || [] as field}
        <div class="uk-margin">
          <CustomFieldEditOrDisplay
            fields={$School.customFields[
              $School.roles.find(r => $SchoolRole.roleID == r.id).category
            ]}
            {field}
            bind:value={schoolRole.customFieldValues[field.id]}
            bind:values={schoolRole.customFieldValues}
          />
        </div>
      {/each}
    </div>
  </div>
  <div class="uk-margin">
    <Button fullwidth onclick={saveChange}>
      {$_('enterRoom.SaveChange')}
    </Button>
  </div>
{/if}
