<script lang="ts">
  import ExamBox from '../blocks/ExamBox.svelte';
  import { _ } from 'svelte-i18n';
  import {
    saveInterval,
    saveIntervalCustomFields,
  } from '../../lib/helpers/saveInterval';
  import { CustomFieldDefault } from '../../lib/interfaces/CustomField';
  import { ioGet } from '../../lib/realtime';
  import {
    SchoolRole,
    TargetGrade,
    TargetGroup,
    TargetInterval,
    TargetProgramCategory,
    School,
  } from '../../lib/store';
  import { UUID } from '../../lib/uuid';
  import CustomFieldEditOrDisplay from '../blocks/CustomFieldComponents/CustomFieldEditOrDisplay.svelte';
  import GroupBlock from '../blocks/GroupBlock.svelte';
  import PersonaBlock from '../blocks/PersonaBlock.svelte';
  import Button from '../UI/Button.svelte';
  import MetaText from '../UI/MetaText.svelte';
  import Modal from '../UI/Modal.svelte';
  import CustomFields from '../CustomFields.svelte';
  import { Notification } from '../../actions/notification';
  let grade = null;
  TargetGrade.subscribe(obj => {
    grade = obj;
  });
  let group = null;
  TargetGroup.subscribe(obj => {
    group = obj;
  });
  const save = async () => {
    let res = await ioGet('updateGrade', {
      _id: grade._id,
      data: grade,
    });
    Notification($_('Saved'));
  };
  let interval = null;
  TargetInterval.subscribe(obj => {
    interval = obj;
  });
  const addCustomField = () => {
    interval.customFields['grade'] = [
      ...interval.customFields['grade'],
      {
        ...CustomFieldDefault,
        id: UUID(),
      },
    ];
    saveIntervalCustomFields('grade', interval);
  };
  let parentValues = {};
  let programCategory;
  TargetProgramCategory.subscribe(obj => {
    programCategory = obj;
  });
</script>

<Modal id="gradeEdit" withFooter>
  <div slot="body" uk-margin>
    {#if grade}
      <div>
        <GroupBlock small groupID={$TargetGroup.id} />
      </div>
      <div>
        <PersonaBlock small schoolRoleID={grade.subscriberID} />
      </div>
      {#each grade.exams || [] as exam, i}
        <div>
          <ExamBox
            readonly={!$School.roles.find(role => role.id == $SchoolRole.roleID)
              .school.grade.canEdit}
            bind:grade
            bind:exam
            {i}
          />
        </div>
      {/each}
      {#if $School.roles.find(role => role.id == $SchoolRole.roleID).school.grade.canCreate}
        <div>
          <Button
            small
            style="link"
            onclick={() => {
              grade.exams = [
                ...(grade.exams || []),
                {
                  date: '',
                  score: '',
                },
              ];
            }}
          >
            <i class="fa-duotone fa-plus fa-fw" />
            {$_('Add')}
          </Button>
        </div>
      {/if}
      {#each $TargetInterval.customFields['grade'].filter(field => field.permissions[$SchoolRole.roleID]?.canRead) as field, i}
        <div class="uk-margin">
          <CustomFieldEditOrDisplay
            {field}
            fields={$TargetInterval.customFields['grade'].filter(
              field => field.permissions[$SchoolRole.roleID],
            )}
            bind:value={grade.customFieldValues[field.id]}
            values={grade.customFieldValues}
            parentFields={[
              ...programCategory.customFields,
              ...programCategory.childCustomFields,
            ]}
            parentValues={group.customFieldValues}
          />
        </div>
      {/each}
    {/if}
  </div>
  <div slot="footer">
    <Button onclick={save}>{$_('Save')}</Button>
  </div>
</Modal>
