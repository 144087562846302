<script lang="ts">
  import { onMount } from 'svelte';

  import { defineIfUndefined } from '../../../lib/helpers/defineIfUndefined';

  import type { CustomFieldInterface } from '../../../lib/interfaces/CustomField';
  import { customFieldTypes } from '../../../lib/presets/customFieldTypes';
  import { SchoolRole } from '../../../lib/store';

  import MetaText from '../../UI/MetaText.svelte';
  import RoleSelectOption from '../RoleSelectOption.svelte';
  import CustomFieldDisplay from './CustomFieldDisplay.svelte';
  import CustomFieldInput from './CustomFieldInput.svelte';
  export let videoWatcher: string = '';
  export let field: CustomFieldInterface;
  export let fields: Array<CustomFieldInterface>;
  export let value;
  export let values;
  export let withLabel: Boolean = true;
  export let parentValues = {};
  export let parentFields = [];
  export let fieldSchool = {
    roles: [],
  };
  onMount(() => {
    field.permissions = defineIfUndefined(field.permissions, {});
    if (field.type == 'selectMultiple') {
      value = defineIfUndefined(value, {
        values: [],
      });
    } else {
      value = defineIfUndefined(value, {
        value: '',
      });
      if (value == '') {
        value = {
          value: '',
        };
      }
    }
    // this is to block delete button
    if (
      field.type === 'button' &&
      field.click_once &&
      field.click_once == true
    ) {
      if (values[field.options.target].value == field.options.value.value) {
        fields.forEach(f => {
          if (f.type == 'file') {
            f.disabled = true;
          }
        });
      }
    }
  });
  $: valueIsUndefined = typeof value == 'undefined';
</script>

{#if field && field.permissions}
  {@const permission =
    field.permissions[$SchoolRole?.roleID] ||
    field.permissions[
      fieldSchool.roles.find(role => role.category == 'guest')?.id
    ] ||
    {}}
  <div hidden={!permission.canRead}>
    {#if withLabel}
      <MetaText>
        {field.title}
      </MetaText>
    {/if}
    {#key valueIsUndefined}
      {#if permission.canEdit && customFieldTypes.find(f => f.slug == field.type).isPrimitive}
        <CustomFieldInput {parentFields} {parentValues} {field} bind:value />
      {:else}
        <CustomFieldDisplay
          {videoWatcher}
          {parentValues}
          {parentFields}
          bind:values
          {fields}
          {field}
          {value}
        />
      {/if}
    {/key}
  </div>
{/if}
