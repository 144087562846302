import { io, ioFetch, ioGet } from '../lib/realtime';
import { RIG, Room } from '../lib/store.js';
import { roomElementsStore } from '../state/stores/roomElementsStore';

let room;
Room.subscribe(obj => {
  room = obj;
});
let rig;

RIG.subscribe(el => {
  rig = el;
});
const updateItem = el => {
  console.log('updating', el);
  if (!el) return;
  if (
    el.getAttribute('position').x == 0 &&
    el.getAttribute('position').y == 0 &&
    el.getAttribute('position').z == 0
  )
    return;
  io.emit('updateItem', {
    uid: el.id,
    position: el.getAttribute('position'),
    rotation: el.getAttribute('rotation'),
    scale: el.getAttribute('scale'),
    locked: el.getAttribute('locked'),
  });
};
const updateDashboardScale = (uid, scale, offset) => {
  io.emit('updateDashboardScale', {
    uid: uid,
    scale: scale,
    offset: offset,
  });
};
const deleteItem = el => {
  io.emit('deleteItem', {
    uid: el.id,
    roomID: room._id,
  });
  let item = roomElementsStore.list().find(item => item.uid == el.id);
  roomElementsStore.delete(item.id);
  //el.parentNode.removeChild(el)
};
const duplicateItem = el => {
  //let position = el.object3D.position.add(new THREE.Vector3(0.2,0,0.2))
  io.emit('duplicateItem', {
    uid: el.id,
  });
};

const createItem = async (
  storedAt,
  url,
  fileType,
  extra = null,
  position = null,
) => {
  if (!position) {
    position = document
      .getElementById('myCamera')
      .object3D.localToWorld(new THREE.Vector3(0, 0, -2));
  }
  let rotation = { x: 0, y: 0, z: 0 };
  if (fileType != 'gltf-model') {
    rotation.y = document
      .getElementById('look-rigger')
      .getAttribute('rotation').y;
  }

  let createRes = await ioGet('createItem', {
    url: url,
    roomID: room._id,
    position: position,
    rotation: rotation,
    storedAt: storedAt,
    kind: 'flexible',
    fileType: fileType,
    extra: extra,
    scale: extra?.initialScale || { x: 1, y: 1, z: 1 },
  });

  return createRes;
};
const updateStoredAt = (el, val) => {
  let position = document
    .getElementById('myCamera')
    .object3D.localToWorld(new THREE.Vector3(0, 0, -2));

  console.log('emitting update storedAt');
  io.emit('updateStoredAt', {
    uid: el.id,
    storedAt: val,
    position: position,
  });
};

export {
  updateItem,
  deleteItem,
  createItem,
  duplicateItem,
  updateStoredAt,
  updateDashboardScale,
};
