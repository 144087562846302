import { HOST } from '../config';

let backgroundImages = [
  {
    id: 1,
    url: HOST + '/models/pixta_40499328_L.jpg',
  },
  {
    id: 2,
    url: HOST + '/images/101.jpg',
  },
  {
    id: 3,
    url: HOST + '/images/aoikeBG.jpg',
  },
];
export default backgroundImages;
