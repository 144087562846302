import { CometChat } from '@cometchat-pro/chat';
import { COMETCHAT_APP_ID, COMETCHAT_AUTH_KEY } from '../config';
import { ChatUnreadCount } from '../store';
import Cookies from 'js-cookie';
export const chatLogin = (user, callback, authToken = false) => {
  console.log('authToken', authToken);
  let region = 'us';
  let appSetting = new CometChat.AppSettingsBuilder()
    .subscribePresenceForAllUsers()
    .setRegion(region)
    .autoEstablishSocketConnection(true)
    .build();
  CometChat.init(COMETCHAT_APP_ID, appSetting).then(
    () => {
      CometChatWidget.init({
        appID: COMETCHAT_APP_ID,
        appRegion: region,
        authKey: COMETCHAT_AUTH_KEY,
      }).then(response => {
        //You can now call login function.
        //CometChatWidget.localize(Cookies.get('locale') == 'ja' ? 'jp' : 'en');
        console.log('create or update user', { user });
        CometChatWidget.createOrUpdateUser(user).then(user => {
          console.log({ user });
          CometChat.login(authToken || user.uid).then(
            response => {
              callback();
            },
            error => {
              console.log('Initialization failed with error:', error);
              //Check the reason for error and take appropriate action.
            },
          );
          CometChatWidget.login({ uid: user.uid }).then(
            res => {
              if (!authToken) {
                callback();
              }
              console.log(res);
            },
            error => {
              console.log(error);
            },
          );
        });
      });
    },
    error => {
      console.log('Initialization failed with error:', error);
    },
  );
};
export const checkUnreadMessages = async () => {
  let unreadCount = await CometChat.getUnreadMessageCount();
  let count = 0;
  Object.keys(unreadCount.groups).forEach(key => {
    count = count + unreadCount.groups[key];
  });
  Object.keys(unreadCount.users).forEach(key => {
    count = count + unreadCount.users[key];
  });
  ChatUnreadCount.set(count);
};
export const removeGroup = async guid => {
  return CometChat.deleteGroup(guid).then(
    response => {
      console.log('Groups deleted successfully:', response);
      return response;
    },
    error => {
      console.log('Group delete failed with exception:', error);
      return error;
    },
  );
};
export const getChatUser = uid => {
  return CometChat.getUser(uid).then(
    user => {
      return [true, user.status == 'online'];
    },
    error => {
      console.log('getChatUser error', error);
      return [false, false];
    },
  );
};
