import { io } from '../lib/realtime';
import { gumballTargetType, mediaVolume } from '../lib/store';

const getSource = target => {
  console.log('get source', target);
  if (
    target.getAttribute('sourceType') == 'audio' ||
    target.getAttribute('sourceType') == 'friend'
  ) {
    var asset = document.getElementById(
      target.getAttribute('sourceId')?.replace('#', ''),
    );
  } else {
    var asset = document.getElementById(
      target.getAttribute('src')?.replace('#', ''),
    );
  }
  if (asset) {
    mediaVolume.set(asset.volume);
  } else {
    console.log('this guys audio is not set corectly');
  }
  return asset;
};
const toggleVideo = target => {
  let source = getSource(target);
  if (source.paused) {
    playVideo(target);
  } else {
    pauseVideo(target);
  }
};
const playVideo = target => {
  let source = getSource(target);
  console.log(source);
  source.onplay = evt => {
    broadcastVideo(source);
  };
  source.play();
};

const pauseVideo = target => {
  let source = getSource(target);
  source.onpause = evt => {
    broadcastVideo(source);
  };
  source.pause();
};
const muteVideo = target => {
  let source = getSource(target);
  source.volume = 0;
  mediaVolume.set(source.volume);
};
const turnVolumeUp = target => {
  let source = getSource(target);
  if (source.volume < 1) {
    source.volume += 0.1;
  }
  mediaVolume.set(source.volume);
};
const turnVolumeDown = target => {
  let source = getSource(target);
  if (source.volume > 0) {
    source.volume -= 0.1;
  }
  mediaVolume.set(source.volume);
};
const fastForwardVideo = target => {
  let source = getSource(target);
  source.currentTime = source.currentTime + 10;
  broadcastVideo(source);
};
const rewindVideo = target => {
  let source = getSource(target);
  source.currentTime = source.currentTime - 10;
  if (source.currentTime < 0) {
    source.currentTime = 0;
  }
  broadcastVideo(source);
};
const broadcastVideo = source => {
  if (source.paused) {
    io.emit('pauseVideo', {
      uid: source.id,
      currentTime: source.currentTime,
    });
  } else {
    io.emit('playVideo', {
      uid: source.id,
      currentTime: source.currentTime,
    });
  }
};
io.on('playVideo', data => {
  console.log('play video received');
  let source = document.getElementById(data.uid);
  source.currentTime = data.currentTime;
  source.play();
});
io.on('pauseVideo', data => {
  console.log('play video received');
  let source = document.getElementById(data.uid);
  source.currentTime = data.currentTime;
  source.pause();
});
const playFromBeginning = target => {
  let source = getSource(target);
  source.currentTime = 0;
  broadcastVideo(source);
};
export {
  playVideo,
  pauseVideo,
  muteVideo,
  turnVolumeDown,
  turnVolumeUp,
  fastForwardVideo,
  rewindVideo,
  playFromBeginning,
  toggleVideo,
  getSource,
};
