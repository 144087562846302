<script lang="ts">
  import { onMount } from 'svelte';
  import { ioGet } from '../../lib/realtime';
  import nl2br from 'nl2br';
  import { DateTime } from 'luxon';
  import PersonaBlock from './PersonaBlock.svelte';
  import MetaText from '../UI/MetaText.svelte';
  export let comment;
  let persona = null;
</script>

<article class="uk-comment">
  <header class="uk-comment-header">
    <PersonaBlock small schoolRoleID={comment.personaID} />
  </header>
  <div class="uk-comment-body">
    {@html nl2br(comment.body)}
  </div>
  <MetaText>
    {DateTime.fromISO(comment.createdAt).toLocaleString(
      DateTime.DATETIME_SHORT,
    )}
  </MetaText>
</article>
