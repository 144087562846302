<script lang="ts">
  export let icon: string = 'question';
  export let size: string = 'large';
  export let style: string = 'duotone';
</script>

<i
  class:fa-lg={size == 'large'}
  class:fa-2x={size == '2x'}
  class="fa-{style} fa-{icon} fa-fw"
/>
