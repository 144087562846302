<script lang="ts">
  import Icon from '../UI/Icon.svelte';
  export let volume: number = 0;
  export let size = '';
</script>

<div
  class="uk-position-relative"
  style:height={size == '2x' ? '30px' : '25px'}
  style:width={size == '2x' ? '30px' : '25px'}
>
  <div class="uk-position-bottom">
    <span>
      <Icon icon="microphone" {size} />
    </span>
  </div>
  <div
    class="uk-position-bottom"
    style="overflow-y:hidden"
    style:height={`${volume * 4}px`}
  >
    <span class="uk-position-bottom" style="color: lightgreen">
      <Icon icon="microphone" {size} />
    </span>
  </div>
</div>
