<script>
  import {
    muteVideo,
    turnVolumeDown,
    turnVolumeUp,
  } from '../../actions/mediaItemController';
  import Cookies from 'js-cookie';
  import resetFocus from '../../lib/helpers/resetFocus';
  import { io } from '../../lib/realtime';
  import { _ } from 'svelte-i18n';
  import { focusFriend, mediaVolume, RoomPermissions } from '../../lib/store';
  let currentAmpLevel = 1;
  let target;
  focusFriend.subscribe(el => {
    target = el;
    if (el) {
      currentAmpLevel = el.getAttribute('ampLevel') || 1;
    }
  });
  let roomPermissions = null;
  RoomPermissions.subscribe(obj => {
    roomPermissions = obj;
  });
  let volume = 1;
  mediaVolume.subscribe(val => {
    volume = val;
  });
  const kickOut = () => {
    io.emit('kickOut', target.getAttribute('socketID'));
  };
  const forceMute = () => {
    io.emit('forceMute', target.getAttribute('socketID'));
  };
  const amplifyVolumeDown = () => {
    currentAmpLevel = target.getAttribute('ampLevel') || 1;
    console.log(currentAmpLevel);
    if (currentAmpLevel > 0.01) {
      currentAmpLevel = currentAmpLevel / 2;
      target.setAttribute('ampLevel', currentAmpLevel);
    }
  };
  const amplifyVolumeUp = () => {
    currentAmpLevel = target.getAttribute('ampLevel') || 1;
    currentAmpLevel = currentAmpLevel * 2;
    target.setAttribute('ampLevel', currentAmpLevel);
  };
</script>

<a-entity id="friendMenu" look-at="#myCamera">
  <a-entity position="0 0.2 0">
    <a-circle
      position="-0.18 0 0"
      src="/images/icons/done.png"
      radius="0.05"
      class="rayTarget"
      on-click="action:resetFocus"
      width="150"
      material="flatShading:true"
    />
    {#if roomPermissions?.friends.canKickOut}
      <a-circle
        position="-0.06 0 0"
        src="/images/icons/kickOut{Cookies.get('locale') == 'ja'
          ? 'Ja'
          : 'En'}.png"
        radius="0.05"
        class="rayTarget"
        on-click="action:kickOut"
        width="150"
        material="flatShading:true"
      />
    {:else}
      <a-circle
        position="-0.06 0 0"
        src="/images/icons/kickOut{Cookies.get('locale') == 'ja'
          ? 'Ja'
          : 'En'}.png"
        radius="0.05"
        width="150"
        material="flatShading:true;opacity:0.5"
      />
    {/if}
    {#if roomPermissions?.friends.canMute}
      <a-circle
        position="0.06 0 0"
        src="/images/icons/forceMute{Cookies.get('locale') == 'ja'
          ? 'Ja'
          : 'En'}.png"
        radius="0.05"
        class="rayTarget"
        on-click="action:forceMute"
        width="150"
        material="flatShading:true"
      />
    {:else}
      <a-circle
        position="0.06 0 0"
        src="/images/icons/forceMute{Cookies.get('locale') == 'ja'
          ? 'Ja'
          : 'En'}.png"
        radius="0.05"
        width="150"
        material="flatShading:true;opacity:0.5"
      />
    {/if}
    {#if roomPermissions?.friends.canTransport}
      <a-circle
        position="0.18 0 0"
        src="/images/icons/forceTransport{Cookies.get('locale') == 'ja'
          ? 'Ja'
          : 'En'}.png"
        radius="0.05"
        class="rayTarget"
        on-click="action:forceTransport"
        width="150"
        material="flatShading:true"
      />
    {:else}
      <a-circle
        position="0.18 0 0"
        src="/images/icons/forceTransport{Cookies.get('locale') == 'ja'
          ? 'Ja'
          : 'En'}.png"
        radius="0.05"
        width="150"
        material="flatShading:true;opacity:0.5"
      />
    {/if}
    <!--a-circle position="0 0 0" src="/images/icons/volumeLow.png" radius="0.05" class="rayTarget" on-click="action:amplifyVolumeDown" width="150" material="flatShading:true" ></a-circle>
        <a-circle position="0.12 0 0" src="/images/icons/volumeHigh.png" radius="0.05" class="rayTarget" on-click="action:amplifyVolumeUp" width="150" material="flatShading:true" ></a-circle-->
    <a-circle
      position="0.30 0 0"
      src="/images/icons/info.png"
      radius="0.05"
      class="rayTarget"
      on-click="action:openInfo"
      width="150"
      material="flatShading:true"
    />
  </a-entity>
</a-entity>
