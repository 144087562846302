<script>
  import { capitalize } from '../../lib/helpers/capitalize';
  import { School, SchoolRole } from '../../lib/store';
  import RoleSelectOption from '../blocks/RoleSelectOption.svelte';
  export let dark = true;
  export let forTop = false;
</script>

<section
  class:uk-light={$School
    ? !$School[
        'fontIsDarkFor' +
          capitalize(
            $School?.roles.find(r => r.id == $SchoolRole?.roleID)?.category,
          )
      ]
    : true}
  style="min-height:100vh;"
  style:background-color={forTop
    ? 'rgba(32,32,32,0.9)'
    : $School[
        'backgroundColorFrame' +
          capitalize(
            $School?.roles.find(r => r.id == $SchoolRole?.roleID)?.category,
          )
      ] ||
      $School?.backgroundColorFrame ||
      '#202020'}
>
  <slot />
</section>

<style>
  .dark {
    background-color: #334;
  }
</style>
