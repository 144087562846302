<script>
  import Checkbox from '../UI/Checkbox.svelte';
  import Input from '../UI/Input.svelte';
  import ArrayNav from './ArrayNav.svelte';
  import AttendanceButtonGroup from './AttendanceButtonGroup.svelte';
  import { _ } from 'svelte-i18n';
  import { School, SchoolRole } from '../../lib/store';
  import Button from '../UI/Button.svelte';
  import { ioGet } from '../../lib/realtime';
  import PersonaBlock from './PersonaBlock.svelte';
  import RoleSelectOption from './RoleSelectOption.svelte';
  import CustomFields from '../CustomFields.svelte';
  import CustomFieldEditOrDisplay from './CustomFieldComponents/CustomFieldEditOrDisplay.svelte';
  import {
    eventCustomFields,
    eventCustomFieldValues,
  } from '../../lib/helpers/eventCustomFields';
  import Icon from '../UI/Icon.svelte';
  export let setting;
  export let i;
  export let schoolEvent;
  export let removeAttendanceSetting;
  let valueForUndecidedUsers;
  schoolEvent.attendanceSettings = schoolEvent.attendanceSettings.map(
    setting => {
      if (typeof setting.attendances == 'undefined') setting.attendances = [];
      setting.attendances = setting.attendances.map(attendance => {
        if (typeof attendance.customFieldValues == 'undefined')
          attendance.customFieldValues = {};
        return attendance;
      });
      return setting;
    },
  );
  const setAtetndanceofUndecided = () => {
    setting.attendances = setting.attendances.map(attendance => {
      if (attendance.status == 'ask') {
        attendance.status = valueForUndecidedUsers;
      }
      return attendance;
    });
  };
  const previewAttendances = async () => {
    schoolEvent.schoolID = $School._id;
    console.log('preview called');
    let res = await ioGet('previewAttendances', {
      schoolEvent: schoolEvent,
      attendanceSetting: setting,
      customFieldValues: {},
    });
    setting.attendances = [];
    console.log(res);
    setTimeout(function () {
      setting.attendances = res;
    }, 100);
  };
  const deleteAttendance = attendance => {
    setting.attendances = setting.attendances.filter(
      atte => atte.schoolRoleID != attendance.schoolRoleID,
    );
  };
</script>

<div>
  <div uk-grid class="uk-width-expand">
    <div class="uk-width-expand">
      {setting.roleTitle}
      {#if $School.roles.find(role => role.id == $SchoolRole.roleID).school.attendance.canCreate}
        <Button style="link" onclick={previewAttendances}
          ><i class="fa-duotone fa-arrows-rotate" /></Button
        >
      {/if}
    </div>
    {#if $School.roles.find(role => role.id == $SchoolRole.roleID).school.attendance.canDelete}
      <div>
        <ArrayNav
          arrayLength={schoolEvent.attendanceSettings?.length || 0}
          remove={() => removeAttendanceSetting(i)}
        />
      </div>
    {/if}
  </div>
  {#if !schoolEvent.isPublic}
    <div>
      <Checkbox
        label={$_('event.AutoAttendance')}
        bind:checked={setting.autoAttendance}
      />
    </div>
    {#if !setting.autoAttendance}
      <div>
        <AttendanceButtonGroup
          disabledAttend={!$School.roles.find(
            role => role.id == $SchoolRole.roleID,
          ).school.attendance.canEdit}
          disabledAsk={!$School.roles.find(
            role => role.id == $SchoolRole.roleID,
          ).school.attendance.canEdit}
          disabledUnattend={!$School.roles.find(
            role => role.id == $SchoolRole.roleID,
          ).school.attendance.canEdit}
          attend={true}
          unattend={true}
          ask={true}
          onchange={setAtetndanceofUndecided}
          bind:value={valueForUndecidedUsers}
        />
      </div>
    {/if}
  {/if}

  {#if setting.attendances}
    <ul class="uk-list">
      {#each setting.attendances as attendance}
        <li>
          <div uk-grid class="uk-grid-small uk-width-expand">
            <div class="uk-width-expand">
              <PersonaBlock schoolRoleID={attendance.schoolRoleID} />
            </div>
            {#if !setting.autoAttendance}
              <div class="uk-flex uk-flex-middle">
                <AttendanceButtonGroup
                  disabledAsk={!$School.roles.find(
                    role => role.id == $SchoolRole.roleID,
                  ).school.attendance.canEdit}
                  disabledAttend={!$School.roles.find(
                    role => role.id == $SchoolRole.roleID,
                  ).school.attendance.canEdit}
                  disabledUnattend={!$School.roles.find(
                    role => role.id == $SchoolRole.roleID,
                  ).school.attendance.canEdit}
                  disabledActual={!$School.roles.find(
                    role => role.id == $SchoolRole.roleID,
                  ).school.attendance.canEdit}
                  attend={true}
                  unattend={true}
                  ask={true}
                  bind:value={attendance.status}
                />
              </div>
            {/if}
            {#each $School.customFields.attendance.filter(f => f.showOnList) as field}
              <CustomFieldEditOrDisplay
                bind:value={attendance.customFieldValues[field.id]}
                values={attendance.customFieldValues}
                {field}
                fields={$School.customFields.attendance}
                parentFields={eventCustomFields($School)}
                parentValues={eventCustomFieldValues(schoolEvent)}
              />
            {/each}
            <div>
              <Button
                style="link"
                onclick={() => {
                  attendance.eventIsInvisibleForAttender = true;
                }}
                hidden={attendance.eventIsInvisibleForAttender}
              >
                <span uk-tooltip={$_('event.EventIsInvisibleForAttender')}>
                  <Icon icon="eye" size="large" />
                </span>
              </Button>
              <Button
                style="link"
                onclick={() => {
                  attendance.eventIsInvisibleForAttender = false;
                }}
                hidden={!attendance.eventIsInvisibleForAttender}
              >
                <span uk-tooltip={$_('event.EventIsInvisibleForAttender')}>
                  <Icon icon="eye-slash" size="large" />
                </span>
              </Button>
            </div>
          </div>
        </li>
      {/each}
    </ul>
  {/if}
</div>
