<script>
  import { ioFetch, ioGet, io } from '../lib/realtime';
  import { getLocaleFromNavigator, _ } from 'svelte-i18n';
  import EnterCard from './blocks/EnterCard.svelte';
  import Button from './UI/Button.svelte';
  import Cookies from 'js-cookie';
  import EmailValidator from 'email-validator';
  import Input from './UI/Input.svelte';
  import { onMount } from 'svelte';
  import { navigate } from 'svelte-routing';
  import { ComeBackURL } from '../lib/store';
  import dialog from '../lib/helpers/dialog';
  export let mode = 'reset-password';

  let comeBackURL;
  ComeBackURL.subscribe(url => {
    comeBackURL = url;
  });
  let email = '';
  const sendVerificationCode = async () => {
    if (!EmailValidator.validate(email)) {
      //email not OK
      dialog($_('login.NotAValidEmail'));
      return;
    }
    let res = await ioGet('sendVerificationCode', {
      email: email,
      locale: Cookies.get('locale') || getLocaleFromNavigator(),
    });
    dialog($_('login.CheckYourEmail'));
    mode = 'verify';
  };
  let code = '';
  const verifyCode = async () => {
    let res = await ioFetch('verifyCodeAndResetPassword', {
      email: email,
      code: code,
      locale: Cookies.get('locale') || getLocaleFromNavigator(),
    });
    console.log(res);
    if (res.result == 'ng') {
      dialog($_('login.WrongCode'));
      return;
    }
    //    Cookies.set('login', res.login)
    location.href = '/';
  };
</script>

<svelte:head>
  <title>{$_('login.ResetPassword')} - AVOS</title>
</svelte:head>
{#if mode == 'reset-password'}
  <EnterCard>
    <span slot="title">{$_('login.ResetPassword')}</span>
    <p slot="description">{$_('login.PleaseEnterYourEmail')}</p>
    <div slot="body">
      <Input type="email" bind:value={email} />
      <Button fullwidth onclick={sendVerificationCode}>{$_('Send')}</Button>
    </div>
  </EnterCard>
{:else}
  <EnterCard>
    <span slot="title">{$_('login.VerifyCode')}</span>
    <div slot="body">
      <Input type="text" bind:value={code} />
      <Button onclick={verifyCode} fullwidth>{$_('login.VerifyCode')}</Button>
      <Button
        style="text"
        onclick={() => {
          mode = 'reset-password';
          navigate('/reset-password');
        }}>{$_('login.SendCodeAgain')}</Button
      >
    </div>
  </EnterCard>
{/if}
