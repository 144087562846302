<script lang="ts">
  import { onMount } from 'svelte';
  import { _ } from 'svelte-i18n';
  import { ioGet } from '../../lib/realtime';
  import { SchoolRole } from '../../lib/store';
  import CustomFieldDisplay from './CustomFieldComponents/CustomFieldDisplay.svelte';
  // import { getChatUser } from '../../lib/helpers/chatLogin';
  import { personasStore } from '../../state/stores/personasStore';

  export let small: Boolean = false;
  export let schoolRoleID: string;
  export let withChat: Boolean = false;
  export let withRole: boolean = true;
  export let withCustomFields: boolean = false;
  export let withOnlineStatus: boolean = true;
  let personas = [];
  personasStore.subscribe(array => {
    personas = personasStore.list();
  });

  $: persona = personas.find(f => f.id == schoolRoleID);
  let school = null;
  let chatVisible: boolean = false;
  onMount(async () => {
    let response = await ioGet('getPersona', {
      schoolRoleID: schoolRoleID,
    });
    personasStore.add(response);

    school = await ioGet('getSchool', {
      _id: response.schoolID,
    });
    // let res = await getChatUser(response.id);
    // chatVisible = res[0];
    // await ioGet('upadatePersona', {
    //   data: {
    //     isOnline: res[1],
    //   },
    // });
  });
</script>

{#if persona}
  <div class="uk-grid-small" uk-grid style:min-height={small ? '40px' : '80px'}>
    <div>
      <img
        alt="Avatar"
        src={persona.profileImageURL || '/images/no_photo.jpg'}
        style:width={small ? '40px' : '80px'}
        style:height={small ? '40px' : '80px'}
        class="uk-border-circle uk-preserve-width uk-box-shadow-small"
      />
    </div>
    <div>
      <div class="uk-text-nowrap">
        {persona.nickname}
      </div>
      {#if withCustomFields}
        {@const fields =
          school?.customFields[
            school?.roles.find(role => role.id == persona.roleID)?.category
          ]?.filter(field => field.showOnBadge) || []}
        <ul
          class="uk-subnav uk-subnav-divider uk-margin-remove-top uk-margin-remove-bottom uk-text-small"
        >
          {#each fields as field}
            <li>
              {field.title}:
              <CustomFieldDisplay
                {field}
                {fields}
                value={persona.customFieldValues[field.id]}
                values={persona.customFieldValues}
              />
            </li>
          {/each}
        </ul>
      {/if}
      {#if withRole}
        <div class="uk-text-meta uk-text-small">
          {school?.roles.find(schoolRole => schoolRole.id == persona.roleID)
            ?.title}
          @
          {school?.title}
        </div>
      {/if}
      <div class="uk-text-small">
        {#if withOnlineStatus}
          {#if persona.isOnline}
            <span style="color:lightgreen">
              <i class="fa-solid fa-circle" />
            </span>
            {persona.onlineStatus || $_('Online')}
          {/if}
        {/if}
        {#if withChat && schoolRoleID != $SchoolRole.id}
          <span>
            <!-- <Button
              hidden={!chatVisible}
              style="text"
              onclick={() => {
                CometChatWidget.chatWithUser(persona.id);
                UIkit.modal('#chatModal').show();
              }}
            >
              <Icon icon="comments" size="large" />
            </Button> -->
          </span>
        {/if}
      </div>
    </div>
  </div>
{/if}
