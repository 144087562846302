import {
  normalAudio,
  videoController,
  stopScreenCapture,
  stopWebcam,
} from '../../actions/videoController';
import { ioGet } from '../realtime';
import { AudioInputs, captureScreenActive, webcamActive } from '../store';
import { _ } from 'svelte-i18n';
import { get } from 'svelte/store';
let ifWebcamActive = false;
let ifCaptureScreenActive = false;
webcamActive.subscribe(bool => {
  ifWebcamActive = bool;
});
captureScreenActive.subscribe(bool => {
  ifCaptureScreenActive = bool;
});
let audioInputs;
AudioInputs.subscribe(array => {
  audioInputs = array;
});
const changeAudioDevice = async deviceId => {
  //audioDeviceID = deviceId

  audioInputs = audioInputs.map(input => {
    input.inUse = input.deviceId == deviceId;
    return input;
  });
  UIkit.modal
    .confirm(get(_)('menu.ThisWillResetYourConnection'))
    .then(async function () {
      if (ifWebcamActive) stopWebcam();
      if (ifCaptureScreenActive) stopScreenCapture();
      videoController.disconnect();
      let resToken = await ioGet('getVideoToken');
      if (resToken.token) {
        await videoController.init(resToken.token, deviceId);
        normalAudio();
      }
    });
};
export default changeAudioDevice;
