import { HOST } from '../config';

const loadHands = (uid = null) => {
  let hands = document.createElement('a-entity');
  hands.setAttribute('gltf-model', 'url(/models/multi_action_04.glb)');
  hands.setAttribute('scale', '1 1 1');
  hands.setAttribute('position', '0 -1.6 0');
  hands.setAttribute('rotation', '0 180 0');
  hands.setAttribute('animation-mixer', 'clip:idle');
  if (uid) {
    //friends
    hands.id = 'myHands_' + uid;
  } else {
    hands.id = 'myHands';
  }
  hands.addEventListener('model-loaded', () => {
    const model = hands.object3D;
    model.traverse(node => {
      if (node.isMesh) {
        node.frustumCulled = false;
      }
    });
  });
  hands.addEventListener('animation-finished', function () {
    hands.removeAttribute('animation-mixer');
    hands.setAttribute('animation-mixer', 'clip', 'idle');
    hands.setAttribute('animation-mixer', 'loop', 'repeat');
  });
  hands.addEventListener('model-loaded', function (evt) {
    //console.log(hands.object3D.children[0].children[0].children[1].material)
    //hands.object3D.children[0].children[0].children[1].material.color = { r: 1.8, g: 1.2, b: 1.2 }
  });
  return hands;
};
export default loadHands;
