import { ringHeight, ringRadius } from '../lib/defaultValues.js';
import { gumballTarget, mousePos } from '../lib/store.js';

let thisPos;
mousePos.subscribe(pos => {
  thisPos = pos;
});
const unfocus = me => {
  let rig = document.getElementById('look-rigger');
  //console.log(evt.detail)
  me.mouseDown = false;
};

let rig = document.getElementById('look-rigger');
AFRAME.registerComponent('dashboard-handle', {
  schema: {
    enabled: { type: 'boolean', default: true },
  },
  init: function () {
    this.dashboard = document.getElementById('myDashboard');
  },
  tick: function () {
    this.el.object3D.position.y =
      this.dashboard.object3D.position.y - ringHeight;
    this.el.object3D.position.z =
      this.dashboard.object3D.position.z - ringRadius;
    let distance = this.el.object3D.position.distanceTo(
      document.getElementById('myCamera').object3D.position,
    );
    let scale = 1 * distance;
    this.el.setAttribute('scale', scale + ' ' + scale + ' ' + scale);
  },
});
