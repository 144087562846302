<script>
  import { ioFetch, io, ioGet } from '../lib/realtime';
  import EnterCard from './blocks/EnterCard.svelte';
  import Button from './UI/Button.svelte';
  import Cookies from 'js-cookie';
  import EmailValidator from 'email-validator';
  import Input from './UI/Input.svelte';
  import { onMount } from 'svelte';
  import { navigate } from 'svelte-routing';
  import { ComeBackURL, LoggedIn } from '../lib/store';
  import { _ } from 'svelte-i18n';
  import dialog from '../lib/helpers/dialog';
  import LanguageSwitch from './blocks/LanguageSwitch.svelte';
  import Div from './UI/Div.svelte';
  import LinkToDocumentation from './blocks/LinkToDocumentation.svelte';
  export let mode = 'login';
  let comeBackURL;
  ComeBackURL.subscribe(url => {
    comeBackURL = url;
  });
  LoggedIn.subscribe(bool => {
    if (bool) {
      navigate('/');
    }
  });
  let email = '';
  let password;
  const login = async () => {
    if (!EmailValidator.validate(email)) {
      //email not OK
      dialog($_('login.NotAValidEmail'));
      return;
    }
    console.log(email, password);
    let loginRes = await ioGet('login', {
      email: email,
      password: password,
    });
    console.log(loginRes);
    if (loginRes.status == 'success') {
      Cookies.set('login', loginRes.login);
      if (typeof Cookies.get('backURL') != 'undefined') {
        let url = Cookies.get('backURL');
        Cookies.remove('backURL');
        location.href = url;
      } else {
        location.href = '/';
      }
    } else {
      //not good
      dialog($_('login.Wrong'));
    }
  };
  let code = '';
  const verifyCode = async () => {
    let res = await ioFetch('verifyCode', {
      email: email,
      code: code,
      locale: Cookies.get('locale'),
    });
    console.log(res);
    if (res.result == 'ng') {
      dialog($_('login.WrongCode'));
      return;
    }
    Cookies.set('login', res.login);
  };
</script>

<svelte:head>
  <title>{$_('login.Login')} - AVOS</title>
</svelte:head>
{#if mode == 'login'}
  <div>
    <EnterCard>
      <span slot="title">
        {$_('login.Login')}
      </span>
      <div slot="body">
        <Div>
          <Input type="email" bind:value={email} label={$_('Email')} />
        </Div>
        <Div>
          <Input type="password" bind:value={password} label={$_('Password')} />
        </Div>
        <Div>
          <div>
            <Button fullwidth onclick={login}>{$_('login.Login')}</Button>
          </div>
        </Div>
        <div class="uk-margin">
          <Button style="text" small onclick={() => navigate('/register')}
            >{$_('login.Register')}</Button
          >
        </div>
        <div class="uk-margin">
          <Button
            style="text"
            small
            onclick={() => navigate('/forgot-password')}
            >{$_('login.ForgotPassword')}</Button
          >
        </div>
        <LanguageSwitch />
      </div>
    </EnterCard>
  </div>
{:else}
  <div>
    <EnterCard>
      <span slot="title">{$_('login.VerifyCode')}</span>
      <div slot="body">
        <Input type="text" bind:value={code} />
        <Button onclick={verifyCode} fullwidth>{$_('login.VerifyCode')}</Button>
        <Button
          style="text"
          onclick={() => {
            mode = 'login';
          }}>{$_('login.SendCodeAgain')}</Button
        >
      </div>
    </EnterCard>
  </div>
{/if}
