import ioS from 'socket.io-client';
import { ENDPOINT } from './config.js';
import dialog from './helpers/dialog.js';
import { UUID } from './uuid';
import { _ } from 'svelte-i18n';
import { get } from 'svelte/store';

//const socket = ioClient(ENDPOINT)

const socket = ioS(ENDPOINT, {
  withCredentials: true,
  extraHeaders: {
    'my-custom-header': 'abcd',
  },
  pingInterval: 25000, // default - 25000
  pingTimeout: 60000, // default - 60000
});
socket.on('connect', res => handleSuccess(res));
socket.on('connect_error', err => handleErrors(err));
socket.on('connect_failed', err => handleErrors(err));
socket.on('disconnect', err => handleErrors(err));
const handleErrors = error => {
  console.log({ error });
  if (location.href.indexOf('scene') > 0) {
    //dialog(get(_)("Disconnected. Please Reload."));
  }
  //alert(error+". Disconnected. Please reload")
};
const handleSuccess = res => {
  console.log('socket connection is successfull', new Date());
};

const ioGet = (message, data) => {
  let key = UUID();
  if (typeof data == 'undefined') data = {};
  data.secret_key = key;
  socket.emit(message, data);
  return new Promise(resolve => {
    socket.once(message + '_' + data.secret_key, res => {
      //console.log('resolve',res)
      resolve(res);
    });
  });
};

const ioFetch = (message, data) => {
  // console.log(message,data)
  socket.emit(message, data);
  return new Promise(resolve => {
    socket.on(message, res => {
      //console.log('resolve',res)
      resolve(res);
    });
  });
};
socket.on('schoolIntervalUpdated', data => {
  console.log('school Interval Updated', data);
});

const io = socket;
export { io, ioFetch, ioGet };
