import { ioGet } from '../realtime';
import { Room, School } from '../store';
import dialog from './dialog';
import setRoomPermissions from './setRoomPermissions';
import setSchoolRole from './setSchoolRole';
const setSchoolAndRoom = async (slug, roomSlug, asGuest = false) => {
  let schoolRes = await ioGet('getSchool', {
    slug: slug,
  });
  if (schoolRes) {
    School.set(schoolRes);
    setSchoolRole(asGuest);
  } else {
    dialog($_('WrongURL'));
  }
  let roomRes = await ioGet('getRoom', {
    slug: roomSlug,
  });
  if (roomRes) {
    Room.set(roomRes);
    setRoomPermissions(asGuest);
  } else {
    dialog($_('WrongURL'));
  }
  return [schoolRes, roomRes];
};
export default setSchoolAndRoom;
