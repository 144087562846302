export const decodeMath = val => {
  if (typeof val == 'undefined') return '';
  function do_decode(val) {
    if (!val || val.indexOf('[math]') == -1) return val;
    let pre = val.split('[math]')[0];
    let math = val.split('[math]')[1].split('[/math]')[0];
    let after = val.substr(val.indexOf('[/math]') + 7);
    //console.log(pre,math,after)
    math = htmldecode(math)
      .replace(/\<p\>/g, '\\[')
      .replace(/\<\/p\>/g, '\\]')
      .replace(/\[fn\]/g, '\\')
      .replace(/\[br\]/g, '\\\\');
    val = htmldecode(pre) + '\\[' + math + '\\]' + htmldecode(after);
    return val;
  }
  for (let i = 0; i < 1; i) {
    val = do_decode(val);
    if (val.indexOf('[math]') == -1) {
      i = 1;
    }
  }
  val = val.replace(/\\\[\\\]/g, '');
  //val = TeXToCHTML(val)
  return val;
};
function htmldecode(str) {
  return (str + '')
    .replace(/&amp;/g, '&')
    .replace(/&quot;/g, '"')
    .replace(/&#039;/g, "'")
    .replace(/&lt;/g, '<')
    .replace(/&gt;/g, '>');
}
export const previewMath = (s = 100) => {
  if (document.querySelectorAll('.mathPreview').length) {
    setTimeout(function () {
      if (document.querySelectorAll('.mathPreview').length) {
        MathJax.typeset(document.querySelectorAll('.mathPreview'));
      }
    }, s);
  }
};
