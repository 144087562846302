import { UUID } from '../uuid';
import { get } from 'svelte/store';
import { _ } from 'svelte-i18n';

let defaultRoles = [
  {
    id: UUID(),
    title: get(_)('preset.MasterAdmin'),
    category: 'admin',
    canChangePermissions: true,
    school: {
      canEdit: true,
      canDelete: true,
      account: {
        canRead: true,
        canCreate: true,
        canEdit: true,
        canDelete: true,
      },
      master: {
        canRead: true,
        canCreate: true,
        canEdit: true,
        canDelete: true,
      },
      event: {
        canRead: true,
        canCreate: true,
        canEdit: true,
        canDelete: true,
      },
      attendance: {
        canRead: true,
        canCreate: false,
        canEdit: false,
        canDelete: false,
      },
      article: {
        canRead: true,
        canCreate: true,
        canEdit: true,
        canDelete: true,
      },
      assignment: {
        canRead: true,
        canCreate: true,
        canEdit: true,
        canDelete: true,
      },
      grade: {
        canRead: true,
        canCreate: true,
        canEdit: true,
        canDelete: true,
      },
      email: {
        canRead: true,
        canCreate: true,
        canEdit: true,
        canDelete: true,
      },
      product: {
        canRead: true,
        canCreate: true,
        canEdit: true,
        canDelete: true,
      },
    },
    roomPermissionPresets: {
      canEnter: true,
      canTransport: true,
      canFly: true,
      rooms: {
        canCreate: true,
        canEdit: true,
        canDelete: true,
      },
      items: {
        canCreate: true,
        canEdit: true,
        canDelete: true,
        canMove: true,
        canMoveStorage: true,
      },
      friends: {
        canMute: true,
        canKickOut: true,
        canTransport: true,
      },
      canCreateCaptureScreen: true,
    },
    allowRegistrationsByUsers: false,
  },
  {
    id: UUID(),

    title: get(_)('preset.SupportingAdmin'),
    category: 'admin',
    canChangePermissions: true,
    school: {
      canEdit: false,
      canDelete: false,
      account: {
        canCreate: false,
        canDelete: false,
      },
      master: {
        canRead: true,
        canCreate: false,
        canEdit: false,
        canDelete: false,
      },
      event: {
        canRead: true,
        canCreate: true,
        canEdit: true,
        canDelete: true,
      },
      attendance: {
        canRead: true,
        canCreate: false,
        canEdit: false,
        canDelete: false,
      },
      article: {
        canRead: true,
        canCreate: true,
        canEdit: true,
        canDelete: true,
      },
      assignment: {
        canRead: true,
        canCreate: true,
        canEdit: true,
        canDelete: true,
      },
      grade: {
        canRead: true,
        canCreate: true,
        canEdit: true,
        canDelete: true,
      },
      email: {
        canRead: true,
        canCreate: true,
        canEdit: true,
        canDelete: true,
      },
      product: {
        canRead: true,
        canCreate: true,
        canEdit: true,
        canDelete: true,
      },
    },
    roomPermissionPresets: {
      canEnter: true,
      canTransport: true,
      canFly: true,
      rooms: {
        canCreate: true,
        canEdit: true,
        canDelete: true,
      },
      items: {
        canCreate: true,
        canEdit: true,
        canDelete: true,
        canMove: true,
        canMoveStorage: true,
      },
      friends: {
        canMute: true,
        canKickOut: true,
        canTransport: true,
      },
      canCreateCaptureScreen: true,
    },
    allowRegistrationsByUsers: false,
  },
  {
    id: UUID(),

    title: get(_)('preset.Teacher'),
    category: 'manager',
    canChangePermissions: false,
    school: {
      canEdit: true,
      canDelete: false,
      account: {
        canCreate: false,
        canDelete: false,
      },
      master: {
        canRead: true,
        canCreate: false,
        canEdit: false,
        canDelete: false,
      },
      event: {
        canRead: true,
        canCreate: true,
        canEdit: true,
        canDelete: true,
      },
      attendance: {
        canRead: true,
        canCreate: false,
        canEdit: false,
        canDelete: false,
      },
      article: {
        canRead: true,
        canCreate: true,
        canEdit: true,
        canDelete: true,
      },
      assignment: {
        canRead: true,
        canCreate: true,
        canEdit: true,
        canDelete: true,
      },
      grade: {
        canRead: true,
        canCreate: true,
        canEdit: true,
        canDelete: true,
      },
      email: {
        canRead: true,
        canCreate: true,
        canEdit: true,
        canDelete: true,
      },
      product: {
        canRead: true,
        canCreate: true,
        canEdit: true,
        canDelete: true,
      },
    },
    roomPermissionPresets: {
      canEnter: true,
      canTransport: true,
      canFly: true,
      rooms: {
        canCreate: true,
        canEdit: true,
        canDelete: true,
      },
      items: {
        canCreate: true,
        canEdit: true,
        canDelete: true,
        canMove: true,
        canMoveStorage: true,
      },
      friends: {
        canMute: true,
        canKickOut: true,
        canTransport: true,
      },
      canCreateCaptureScreen: true,
    },
    allowRegistrationsByUsers: false,
  },
  {
    id: UUID(),

    title: get(_)('preset.Student'),
    category: 'subscriber',

    canChangePermissions: false,
    school: {
      canEdit: true,
      canDelete: false,
      account: {
        canRead: false,
        canCreate: false,
        canDelete: false,
      },
      master: {
        canRead: false,
      },
      event: {
        canRead: true,
        canCreate: false,
        canEdit: false,
        canDelete: false,
      },
      attendance: {
        canRead: true,
        canCreate: false,
        canEdit: false,
        canDelete: false,
      },
      article: {
        canRead: true,
        canCreate: false,
        canEdit: false,
        canDelete: false,
      },
      assignment: {
        canRead: true,
        canCreate: false,
        canEdit: false,
        canDelete: false,
      },
      grade: {
        canRead: true,
        canCreate: false,
        canEdit: false,
        canDelete: false,
      },
      email: {
        canRead: false,
      },
      product: {
        canRead: true,
        canCreate: false,
        canEdit: false,
        canDelete: false,
      },
    },
    roomPermissionPresets: {
      canEnter: true,
      canTransport: true,
      canFly: true,
      rooms: {
        canCreate: false,
        canEdit: false,
        canDelete: false,
      },
      items: {
        canCreate: true,
        canEdit: true,
        canDelete: true,
        canMove: true,
        canMoveStorage: true,
      },
      friends: {
        canMute: true,
        canKickOut: true,
        canTransport: false,
      },
      canCreateCaptureScreen: true,
    },
    allowRegistrationsByUsers: false,
  },
  {
    id: UUID(),

    title: get(_)('preset.Guest'),
    category: 'guest',
    canChangePermissions: false,
    school: {
      canEdit: false,
      canDelete: false,
      account: {
        canRead: false,
      },
      master: {
        canRead: false,
      },
      event: {
        canRead: true,
        canCreate: false,
        canEdit: false,
        canDelete: false,
      },
      attendance: {
        canRead: true,
        canCreate: false,
        canEdit: false,
        canDelete: false,
      },
      article: {
        canRead: true,
        canCreate: false,
        canEdit: false,
        canDelete: false,
      },
      assignment: {
        canRead: false,
      },
      grade: {
        canRead: false,
      },
      email: {
        canRead: false,
      },
      product: {
        canRead: true,
      },
    },
    roomPermissionPresets: {
      canEnter: true,
      canTransport: true,
      canFly: true,
      rooms: {
        canCreate: false,
        canEdit: false,
        canDelete: false,
      },
      items: {
        canCreate: false,
        canEdit: false,
        canDelete: false,
        canMove: true,
        canMoveStorage: false,
      },
      friends: {
        canMute: false,
        canKickOut: false,
        canTransport: false,
      },
      canCreateCaptureScreen: true,
    },
    allowRegistrationsByUsers: false,
  },
];
let newRole = {
  title: '',
  category: 'manager',
  canChangePermissions: false,
  school: {
    canEdit: false,
    canDelete: false,
    account: {
      canCreate: false,
      canDelete: false,
    },
    master: {
      canCreate: false,
      canEdit: false,
      canDelete: false,
    },
    event: {
      canRead: true,
      canCreate: false,
      canEdit: false,
      canDelete: false,
    },
    attendance: {
      canRead: true,
      canCreate: false,
      canEdit: false,
      canDelete: false,
    },
    article: {
      canRead: true,
      canCreate: false,
      canEdit: false,
      canDelete: false,
    },
    assignment: {
      canRead: true,
      canCreate: false,
      canEdit: false,
      canDelete: false,
    },
    grade: {
      canRead: false,
      canCreate: true,
      canEdit: true,
      canDelete: true,
    },
    email: {
      canRead: false,
      canCreate: true,
      canEdit: true,
      canDelete: true,
    },
    product: {
      canRead: true,
      canCreate: true,
      canEdit: true,
      canDelete: true,
    },
  },
  roomPermissionPresets: {
    canEnter: true,
    canTransport: true,
    canFly: true,
    rooms: {
      canCreate: false,
      canEdit: false,
      canDelete: false,
    },
    items: {
      canCreate: true,
      canEdit: true,
      canDelete: true,
      canMove: true,
      canMoveStorage: true,
    },
    friends: {
      canMute: true,
      canKickOut: true,
      canTransport: true,
    },
    canCreateCaptureScreen: true,
  },
  allowRegistrationsByUsers: false,
};
export { defaultRoles, newRole };
