<script lang="ts">
  import 'aframe';
  import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
  import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
  import { RoomEnvironment } from 'three/examples/jsm/environments/RoomEnvironment.js';

  import { afterUpdate, onMount } from 'svelte';
  import { ENDPOINT, HOST } from '../../lib/config';
  import hideHands from '../../lib/helpers/hideHands';
  export let id;
  export let url;
  export let navMeshURL: string = '';
  export let showGrid = true;
  export let circle = false;
  export let width = 200;
  export let height = 200;
  export let scale = 1;
  export let hideAvatarHands = false;
  export let bgColor = '#bbbbbb';
  export let distance: number = 0.6;
  export const mount = {
    do: function () {
      if (!url) return;
      init();
      render();
    },
    render: function () {
      render();
    },
  };
  let camera, scene, renderer;

  function init() {
    if (!document.getElementById(id)) return;
    const container = document.createElement('div');
    if (circle) {
      container.style = 'border-radius:50%;overflow:hidden;';
    }
    document.getElementById(id).innerHTML = '';
    document.getElementById(id).appendChild(container);

    camera = new THREE.PerspectiveCamera(45, 1, 0.25, 5000);
    camera.position.set(distance / 2, distance, distance);

    scene = new THREE.Scene();

    if (url.indexOf(ENDPOINT) > -1) {
      var loader = new GLTFLoader().setPath(ENDPOINT);
      var loadUrl = url.replace(ENDPOINT, '');
    } else {
      var loader = new GLTFLoader().setPath();
      var loadUrl = url;
    }
    if (!loadUrl) return;
    loader.load(loadUrl, function (gltf) {
      let model = gltf.scene;
      model.scale.set(scale, scale, scale);
      if (hideAvatarHands) {
        hideHands(model);
      }
      scene.add(model);
      if (showGrid) {
        var grid = new THREE.GridHelper(10, 10);
        scene.add(grid);
      }
      render();
    });

    renderer = new THREE.WebGLRenderer({
      antialias: true,
      preserveDrawingBuffer: true,
    });
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setSize(width, height);
    renderer.toneMapping = THREE.ACESFilmicToneMapping;
    renderer.toneMappingExposure = 0.5;
    renderer.outputEncoding = THREE.sRGBEncoding;

    container.appendChild(renderer.domElement);

    const environment = new RoomEnvironment();
    const pmremGenerator = new THREE.PMREMGenerator(renderer);

    scene.background = new THREE.Color(bgColor);
    scene.environment = pmremGenerator.fromScene(environment).texture;

    const hemiLight = new THREE.HemisphereLight(0xffffff, 0xffffff, 2);
    hemiLight.color.setHSL(1, 1, 1);
    hemiLight.groundColor.setHSL(0.095, 1, 0.75);
    hemiLight.position.set(0, 50, 50);
    scene.add(hemiLight);

    const controls = new OrbitControls(camera, renderer.domElement);
    controls.addEventListener('change', render); // use if there is no animation loop
    controls.minDistance = 0.2;
    controls.maxDistance = 1000;
    controls.target.set(0, 0.6, 0);
    controls.update();
  }

  //

  function render() {
    renderer.render(scene, camera);
  }

  function getRenderer() {
    return renderer;
  }
  afterUpdate(() => {
    init();
    render();
  });
</script>

<div {id} style="width:{width}px;height:{height}px" />
