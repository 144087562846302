import appendToDashboard from '../lib/appendToDanshboard.js';
import { ENDPOINT, HOST } from '../lib/config.js';
import { ringHeight, thumbnailCloudURL } from '../lib/defaultValues.js';
import scaleDashboardItem from '../lib/helpers/scaleDashboardItem.js';
import thumbnailNGList from '../lib/presets/thumbnailNGList.js';
import { io, ioFetch } from '../lib/realtime.js';
import { ItemCounts, ItemsLoaded } from '../lib/store.js';
import {
  roomAssetsStore,
  roomElementsStore,
} from '../state/stores/roomElementsStore.js';

import { deleteItem } from './itemController';
import positionRingItems from './positionRingItems';
const appendAsset = itemAsset => {
  let existingAsset = document.getElementById(itemAsset.uid);
  if (!existingAsset) {
    if (
      itemAsset.fileType == 'image' ||
      itemAsset.fileType == '360image' ||
      itemAsset.fileType == 'file'
    ) {
      var asset = document.createElement('img');
      asset.addEventListener('load', function (res) {
        ItemsLoaded.update(num => num + 1);
      });
    } else if (itemAsset.fileType == 'video') {
      var asset = document.createElement('video');
      asset.setAttribute('autoplay', true);
      asset.autoplay = true;

      asset.addEventListener('loadeddata', function (res) {
        ItemsLoaded.update(num => num + 1);
      });
    } else if (itemAsset.fileType == '360video') {
      var asset = document.createElement('video');
      asset.setAttribute('autoplay', true);
      asset.setAttribute('loop', true);
      asset.autoplay = true;
      asset.loop = true;

      asset.addEventListener('loadeddata', function (res) {
        ItemsLoaded.update(num => num + 1);
      });
    } else if (itemAsset.fileType == 'audio') {
      var asset = document.createElement('audio');
      asset.setAttribute('autoplay', false);
      asset.autoplay = false;

      asset.addEventListener('loadeddata', function (res) {
        ItemsLoaded.update(num => num + 1);
      });
    } else {
      var asset = document.createElement('a-asset-item');

      asset.addEventListener('loaded', function (res) {
        ItemsLoaded.update(num => num + 1);
      });
    }
    ItemCounts.update(num => num + 1);
    asset.crossOrigin = 'Anonymous';
    document.querySelector('a-assets').appendChild(asset);

    asset.id = itemAsset.uid;
    asset.setAttribute('fileType', itemAsset.fileType);
    if (itemAsset.fileType == 'image') {
      asset.setAttribute('aspectRatio', itemAsset.aspectRatio);
      asset.setAttribute(
        'src',
        itemAsset.thumbnail || thumbnailCloudURL + itemAsset.url + '&size=500',
      );
    } else if (itemAsset.fileType == '360image') {
      asset.setAttribute(
        'src',
        thumbnailCloudURL + itemAsset.url + '&size=2000&mode=orig',
      );
    } else if (itemAsset.fileType == 'video') {
      asset.setAttribute('aspectRatio', itemAsset.aspectRatio);
      asset.setAttribute('src', itemAsset.url);
      asset.setAttribute(
        'poster',
        thumbnailCloudURL + itemAsset.url + '&size=500',
      );
    } else if (itemAsset.fileType == 'audio') {
      asset.setAttribute('aspectRatio', 1);
      asset.setAttribute('src', itemAsset.url);
    } else if (itemAsset.fileType == 'text') {
      asset.setAttribute('body', itemAsset.url);
    } else if (itemAsset.fileType != 'file') {
      asset.setAttribute('src', itemAsset.url);
    } else if (itemAsset.fileType == 'file') {
      if (itemAsset.thumbnail) {
        asset.setAttribute('src', itemAsset.thumbnail);
      } else {
        if (thumbnailNGList.indexOf(itemAsset.url.split('.').pop()) > -1) {
          asset.setAttribute('src', '/images/' + itemAsset.fileType + '.png');
        } else {
          asset.setAttribute(
            'src',
            thumbnailCloudURL + itemAsset.url + '&size=500',
          );
        }
      }
      asset.setAttribute('originalUrl', itemAsset.url);
      asset.setAttribute('aspectRatio', 1);
    } else {
      asset.setAttribute('src', '/' + itemAsset.url);
    }
    asset.setAttribute('original', itemAsset.url);
  } else {
    var asset = existingAsset;
  }
};
const appendItem = item => {
  let existingAsset = document.getElementById(item.assetID);
  if (
    !existingAsset &&
    item.uid.indexOf('screenOf') !== 0 &&
    item.extra?.fileType != 'text'
  ) {
    deleteItem({ id: item.uid });
    return;
  }
  let existingEl = document.getElementById(item.uid);
  if (!existingEl) {
    if (existingAsset?.getAttribute('fileType') == 'transportPoint') {
      var entity = document.createElement('a-entity');
      entity.setAttribute('sourceType', 'transportPoint');

      var aurora = document.createElement('a-gltf-model');
      aurora.setAttribute('animation-mixer', 'clip:*');
      aurora.setAttribute('sourceType', 'gltf-model');
      aurora.setAttribute('dashboardScale', item.dashboardScale || 1);
      aurora.setAttribute('dashboardOffset', item.dashboardOffset || 0);
      aurora.setAttribute('src', '#' + item.assetID);
      aurora.classList.add('rayTarget');

      if (item.extra?.initialScale?.x) {
        aurora.setAttribute(
          'initialScale',
          item.extra.initialScale.x +
            ' ' +
            item.extra.initialScale.y +
            ' ' +
            item.extra.initialScale.z,
        );
      } else {
        aurora.setAttribute('initialScale', '1 1 1');
      }
      entity.appendChild(aurora);
      let transportPointTitle = document.createElement('a-entity');
      transportPointTitle.classList.add('rayTarget');

      transportPointTitle.id = 'transportTitleNickname_' + item.uid;
      transportPointTitle.innerHTML =
        "<div id='myNickname_" +
        item.uid +
        "' style='font-size:30px;color:white;width:500px;text-align:center'>" +
        item.extra.title +
        '</div>';
      transportPointTitle.setAttribute('htmlembed', {
        ppu: 500,
      });
      transportPointTitle.setAttribute('look-at', '#myCamera');
      transportPointTitle.setAttribute('position', '0 1.8 0');
      transportPointTitle.setAttribute('rotation', '0 180 0');
      entity.appendChild(transportPointTitle);
    }
    if (existingAsset?.getAttribute('fileType') == 'gltf-model') {
      var entity = document.createElement('a-gltf-model');
      entity.setAttribute('animation-mixer', 'clip:*');
      entity.setAttribute('sourceType', 'gltf-model');
      entity.setAttribute('dashboardScale', item.dashboardScale || 1);
      entity.setAttribute('dashboardOffset', item.dashboardOffset || 0);
      if (item.extra?.initialScale?.x) {
        entity.setAttribute(
          'initialScale',
          item.extra.initialScale.x +
            ' ' +
            item.extra.initialScale.y +
            ' ' +
            item.extra.initialScale.z,
        );
      } else {
        entity.setAttribute('initialScale', '1 1 1');
      }
    }
    if (existingAsset?.getAttribute('fileType') == '360image') {
      var entity = document.createElement('a-sphere');
      entity.setAttribute('radius', 0.5);
      entity.setAttribute('material', 'side', 'back');
      entity.setAttribute('dashboardScale', item.dashboardScale || 1);
      entity.setAttribute('dashboardOffset', item.dashboardOffset || 0);
      entity.setAttribute('sourceType', '360image');
    }
    if (existingAsset?.getAttribute('fileType') == '360video') {
      var entity = document.createElement('a-videosphere');
      entity.setAttribute('radius', 0.5);
      entity.setAttribute('dashboardScale', item.dashboardScale || 1);
      entity.setAttribute('dashboardOffset', item.dashboardOffset || 0);
      entity.setAttribute('sourceType', '360video');
    }
    if (
      existingAsset?.getAttribute('fileType') == 'image' ||
      existingAsset?.getAttribute('fileType') == 'file'
    ) {
      var entity = document.createElement('a-image');
      entity.setAttribute('sourceType', 'image');

      entity.setAttribute('width', existingAsset.getAttribute('aspectRatio'));
      entity.setAttribute('height', 1);
    }
    if (existingAsset?.getAttribute('fileType') == 'video') {
      var entity = document.createElement('a-video');
      entity.setAttribute('width', existingAsset.getAttribute('aspectRatio'));
      entity.setAttribute('height', 1);
      entity.setAttribute('sourceType', 'video');
    }
    if (existingAsset?.getAttribute('fileType') == 'audio') {
      var entity = document.createElement('a-image');
      entity.setAttribute('src', '/images/soundCard.png');
      entity.setAttribute('width', 0.6);
      entity.setAttribute('height', 0.6);
      entity.setAttribute('sourceType', 'audio');
      entity.setAttribute('sourceId', '#' + item.assetID);
      entity.setAttribute('sound', 'src: #' + item.assetID);
    }
    if (item.extra?.fileType == 'text') {
      var entity = document.createElement('a-entity');
      entity.setAttribute('sourceType', 'html');
      entity.setAttribute('html-embed', {
        ppu: 500,
      });
      //entity.setAttribute('htmlembed-backplate',{color:item.extra.backgroundColor})
      //entity.setAttribute('material','{side:both}')
      entity.innerHTML =
        '<div style="padding:5px;width:500px"><div style="max-height:490px;overflow:hidden;box-shadow:2px 2px 5px rgba(0,0,0,0.3); border-radius:5px; padding:10px;font-size:20px;background-color:' +
        item.extra.backgroundColor +
        ';color:' +
        item.extra.textColor +
        '">' +
        item.extra.body +
        '</div></div>';
    }
    if (entity) {
      if (
        existingAsset?.getAttribute('fileType') != 'audio' &&
        item.extra?.fileType != 'text' &&
        item.extra?.fileType != 'transportPoint'
      ) {
        entity.setAttribute('src', '#' + item.assetID);
      }
      entity.id = item.uid;
      entity.classList.add('rayTarget');
      if (item.storedAt == 'room') {
        document.getElementById('scene').appendChild(entity);
      }
      if (item.storedAt == 'dashboard') {
        appendToDashboard(entity);
      }
    } else {
      try {
        entity.classList.add('rayTarget');
      } catch (e) {
        console.log(e);
      }
      return;
    }
  } else {
    var entity = existingEl;
  }
  entity.classList.add('rayTarget');
  if (item.linkURL) {
    entity.setAttribute('linkURL', item.linkURL);
  }
  if (item.storedAt == 'room') {
    entity.setAttribute('show-gumball', {
      enabled: true,
      sourceType: entity.getAttribute('sourceType'),
      originalURL: existingAsset?.getAttribute('original'),
    });
    if (item.scale) {
      entity.setAttribute('position', item.position);
    }
    if (item.scale) {
      entity.setAttribute('rotation', item.rotation);
    }
    if (item.scale) {
      entity.setAttribute('scale', item.scale);
    }
  } else {
    entity.setAttribute('show-dashboard-gumball', {
      enabled: true,
      sourceType: entity.getAttribute('sourceType'),
      originalURL: existingAsset?.getAttribute('original'),
    });
  }
};

const listItems = res => {
  let dashboardItems = [];
  res.roomAssets.forEach(item => {
    roomAssetsStore.add(item);
  });
  res.roomItems.forEach(item => {
    roomElementsStore.add(item);
  });
  res.roomAssets.forEach(asset => {
    appendAsset(asset);
  });
  res.roomItems.forEach(item => {
    appendItem(item);
    if (item.storedAt == 'dashboard') {
      dashboardItems.push(item);
    }
  });
  if (dashboardItems.length) {
    positionRingItems(dashboardItems);
  }
};
io.on('createItem', data => {
  console.log('createItem received', data);
  if (data.assetItem) {
    appendAsset(data.assetItem);
    roomAssetsStore.add(data.assetItem);
  }
  appendItem(data.roomItem);
  roomElementsStore.add(data.roomItem);
});
io.on('updateRoomItems', res => {
  res.roomAssets.forEach(asset => {
    appendAsset(asset);
  });
  res.roomItems.forEach(item => {
    appendItem(item);
  });
});

io.on('deleteItem', data => {
  let offsetterEl = document.getElementById('offsetter_' + data.uid);
  if (offsetterEl) {
    var el = offsetterEl;
  } else {
    var el = document.getElementById(data.uid);
  }
  if (el) el.parentNode.removeChild(el);
  positionRingItems();
});
io.on('updateItem', data => {
  console.log('updateItem received', data);
  appendItem(data);
});
io.on('updateStoredAt', data => {
  let currentEl = document.getElementById(data.uid);
  if (currentEl) {
    if (data.storedAt == 'room') {
      let offsetter = currentEl.parentNode.parentNode;
      offsetter.parentNode.removeChild(offsetter);
    } else {
      currentEl.parentNode.removeChild(currentEl);
    }
  }

  appendItem(data);
});

io.on('updateDashboardScale', data => {
  let currentEl = document.getElementById(data.uid);
  let ratio = data.dashboardScale / currentEl.getAttribute('dashboardScale');
  let diff = currentEl.getAttribute('dashboardOffset') - data.dashboardOffset;
  currentEl.setAttribute('dashboardScale', data.dashboardScale);
  currentEl.setAttribute('dashboardOffset', data.dashboardOffset);
  let newScale = currentEl.getAttribute('scale').x * ratio;
  currentEl.setAttribute('scale', newScale + ' ' + newScale + ' ' + newScale);
  currentEl.object3D.position.y = currentEl.getAttribute('position').y - diff;
});

export { listItems };
