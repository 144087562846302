import { resetGumball, resetDashboardGumball } from '../lib/resizeGumball.js';
import {
  User,
  gumballTarget,
  mousePos,
  transformLock,
  dashboardGumballTarget,
} from '../lib/store.js';
import { updateItem } from '../actions/itemController';
import { radian2degree } from '../lib/helpers/math';

let target = null;
let dashboardTarget = null;
let user;

gumballTarget.subscribe(el => {
  target = el;
});
dashboardGumballTarget.subscribe(el => {
  dashboardTarget = el;
});

let thisPos;
mousePos.subscribe(pos => {
  thisPos = pos;
});
let mouseDown = false;
let mouseDownPos = {};
let plusVector;
let plusVectorRadian;
let thisTransformLock = false;
transformLock.subscribe(val => {
  thisTransformLock = val;
});
User.subscribe(val => {
  user = val;
});

const unfocus = me => {
  mouseDown = false;
  transformLock.set(null);
  me.el.setAttribute('material', {
    opacity: 0.5,
  });
  if (me.target) {
    if (me.data.position == 'room') {
      resetGumball(me.target);
      me.gumball.setAttribute('position', me.target.getAttribute('position'));
    } else {
      resetDashboardGumball(me.target);
    }
  }
};

AFRAME.registerComponent('transform-controls', {
  schema: {
    prop: {
      type: 'string',
    },
    axis: {
      type: 'string',
    },
    position: {
      type: 'string',
    },
  },
  init: function () {
    this.rig = document.getElementById('look-rigger');

    this.plusVector = {};
    this.el.addEventListener('mouseenter', () => {
      if (!thisTransformLock) {
        this.el.setAttribute('material', {
          opacity: 1,
        });
      }
    });
    this.el.addEventListener('mouseleave', () => {
      //unfocus(this)
      this.el.setAttribute('material', {
        opacity: 0.5,
      });
    });
    this.el.addEventListener('mousedown', evt => {
      //let rig = document.getElementById(user)
      if (this.data.position == 'room') {
        this.gumball = document.getElementById('gumball');
        this.target = target;
      } else {
        this.gumball = document.getElementById('dashboardGumball');
        this.target = dashboardTarget;
      }

      let point = scene.components.raycaster.getIntersection(this.el)?.point;
      mouseDown = true;

      let vector1 = new THREE.Vector3(point.x, point.y, point.z);

      let vector2 = new THREE.Vector3(
        this.gumball.object3D.position.x,
        this.gumball.object3D.position.y,
        this.gumball.object3D.position.z,
      );
      mouseDownPos = {
        x: point.x,
        y: point.y,
        z: point.z,
        v: vector1.sub(vector2),
      };
      transformLock.set(this.el);
      return;
    });
    this.el.addEventListener('mouseup', evt => {
      //let rig = document.getElementById(user)
      //console.log(evt.detail)
      unfocus(this);
      updateItem(target);
    });
  },

  tick: function (evt) {
    //console.log(mouseDown)
    if (mouseDown && thisTransformLock == this.el) {
      //console.log('mouse data',this)
      //console.log(evt)

      let mouseNowPos =
        scene.components.raycaster.getIntersection(thisTransformLock)?.point;
      if (mouseNowPos && this.data.prop == 'position' && target) {
        let distance =
          mouseNowPos[this.data.axis] - mouseDownPos[this.data.axis];
        target.object3D.position[this.data.axis] =
          target.object3D.position[this.data.axis] + distance;
        this.gumball.object3D.position[this.data.axis] =
          this.gumball.object3D.position[this.data.axis] + distance;

        mouseDownPos = mouseNowPos;
        //updateItem(target);
      }
      if (mouseNowPos && this.data.prop == 'rotation') {
        let vector1 = new THREE.Vector3(
          mouseNowPos.x,
          mouseNowPos.y,
          mouseNowPos.z,
        );

        let vector2 = new THREE.Vector3(
          this.gumball.object3D.position.x,
          this.gumball.object3D.position.y,
          this.gumball.object3D.position.z,
        );
        mouseNowPos.v = vector1.sub(vector2);
        let Xc = 0;
        let Yc = 0;
        if (this.data.axis == 'x') {
          var Xb = mouseDownPos.v.z * -1;
          var Yb = mouseDownPos.v.y;
          var Xa = mouseNowPos.v.z * -1;
          var Ya = mouseNowPos.v.y;
        }
        if (this.data.axis == 'y') {
          var Xb = mouseDownPos.v.x;
          var Yb = mouseDownPos.v.z * -1;
          var Xa = mouseNowPos.v.x;
          var Ya = mouseNowPos.v.z * -1;
        }
        if (this.data.axis == 'z') {
          var Xb = mouseDownPos.v.x;
          var Yb = mouseDownPos.v.y;
          var Xa = mouseNowPos.v.x;
          var Ya = mouseNowPos.v.y;
        }

        var v1x = Xb - Xc;
        var v1y = Yb - Yc;
        var v2x = Xa - Xc;
        var v2y = Ya - Yc;

        var angle = Math.atan2(v1x, v1y) - Math.atan2(v2x, v2y);
        this.target.object3D.rotation[this.data.axis] =
          this.target.object3D.rotation[this.data.axis] + angle;

        //this.gumball.object3D.rotation[this.data.axis] = this.gumball.object3D.rotation[this.data.axis] + radian
        mouseDownPos = mouseNowPos;
        if (this.data.position == 'room') {
          //updateItem(target);
        }
      }
      if (mouseNowPos && this.data.prop == 'scale') {
        let distance1 = this.gumball.object3D.position.distanceTo(
          new THREE.Vector3(mouseDownPos.x, mouseDownPos.y, mouseDownPos.z),
        );
        let distance2 = this.gumball.object3D.position.distanceTo(
          new THREE.Vector3(mouseNowPos.x, mouseNowPos.y, mouseNowPos.z),
        );
        let ratio = distance2 / distance1;
        target.object3D.scale.x = target.object3D.scale.x * ratio;
        target.object3D.scale.y = target.object3D.scale.y * ratio;
        target.object3D.scale.z = target.object3D.scale.z * ratio;
        mouseDownPos = mouseNowPos;
        this.gumball.object3D.scale.x = this.gumball.object3D.scale.x * ratio;
        this.gumball.object3D.scale.y = this.gumball.object3D.scale.y * ratio;
        this.gumball.object3D.scale.z = this.gumball.object3D.scale.z * ratio;

        //updateItem(target);
      }
    }
  },
});
