<script lang="ts">
  import { HOST } from '../../lib/config';
  import Icon from '../UI/Icon.svelte';
  export let url: string = HOST;
  export let label: string = '';
  export let position: string = '';
</script>

{#if position == 'right'}
  <div class="uk-position-relative uk-width-expand">
    <div class="uk-position-top-right">
      <a href={url} target="_blank" class="uk-link-text">
        <Icon icon="question" />
        {label}
      </a>
    </div>
  </div>
  <!-- {:else if position == "top"}
 <div class="uk-position-relative uk-width-expand">
   <div class="uk-position-top">
     <a href={url} target="_blank">
       <Icon icon="question" />
       {label}
     </a>
   </div>
 </div> -->
{:else}
  <a href={url} target="_blank">
    <Icon icon="question" size="normal" />
    {label}
  </a>
{/if}
