import { _ } from 'svelte-i18n';
import { writable } from 'svelte/store';
import { AssignmentDefault } from '../../lib/interfaces/Assignments';
import { io } from '../../lib/realtime';

const assignmentsReducer = writable([]);

let assignments;
assignmentsReducer.subscribe(array => {
  assignments = array;
});

export const assignmentsStore = {
  groupID: '',
  assignmentCategoryID: '',
  subscribe: assignmentsReducer.subscribe,
  add: function (assignment) {
    const newAssignment = {
      ...AssignmentDefault,
      ...assignment,
    };
    if (!this.get(assignment._id)) {
      assignmentsReducer.update(assignments => {
        return [...assignments, newAssignment];
      });
    }
  },
  set: function (assignments) {
    assignmentsReducer.set(assignments);
  },
  get: _id => {
    return assignments.find(assignment => assignment._id == _id);
  },
  list: function (page = this.currentPage) {
    return assignments;
  },
  clear: () => {
    assignmentsReducer.set([]);
  },
};

io.on('assignmentDeleted', res => {
  assignmentsReducer.set(
    assignments.filter(assignment => assignment._id != res.data._id),
  );
});
io.on('assignmentAdded', res => {
  if (
    res.data.group == assignmentsStore.groupID &&
    res.data.assignmentCategoryID == assignmentsStore.assignmentCategoryID
  ) {
    //Notification(get(_)("AdditionDetected", { values: { category: get(_)("Assignments"), title: res.data.title } }));
    assignmentsStore.add(res.data);
  }
});
io.on('assignmentUpdated', res => {
  let updated = false;
  assignments = assignments.map(existingAssignment => {
    if (existingAssignment._id == res.data._id) {
      updated = true;
      //Notification(get(_)("UpdateDetected", { values: { category: get(_)("Assignments"), title: res.data.title } }));
      return res.data;
    } else {
      return existingAssignment;
    }
  });
  if (updated) {
    assignmentsReducer.set(assignments);
  }
});
