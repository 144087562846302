import sortByKey from './sortByKey';

let lastDirection = null;
const switchSort = (array, key) => {
  if (typeof key == 'string') {
    //no problem
  } else {
    //custom field.
    array = array.map(elem => {
      let value = elem.customFieldValues[key.field];
      elem[key.field] = value;
      return elem;
    });
    key = key.field;
  }

  if (!lastDirection) {
    lastDirection = 'desc';
  } else {
    lastDirection = lastDirection == 'asc' ? 'desc' : 'asc';
  }
  array = sortByKey(array, {
    key: key,
    direction: lastDirection,
  });
  return array;
};
export default switchSort;
