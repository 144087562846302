let cameraBack = false;
const switchCamera = () => {
  let selfAvatar = document.getElementById('myAvatar');
  let camera = document.getElementById('myCamera');
  cameraBack = !cameraBack;
  if (!cameraBack) {
    //1st camera active
    selfAvatar.setAttribute('visible', false);
    selfAvatar.object3D.position.z = 0.3;
    camera.object3D.position.z = 0;
  } else {
    selfAvatar.setAttribute('visible', true);
    selfAvatar.object3D.position.z = 0;
    camera.object3D.position.z = 1;
  }
};
export default switchCamera;
