<script>
  import { DateTime } from 'luxon';
  import { _ } from 'svelte-i18n';
  import { ioGet } from '../../lib/realtime';
  import { School, SchoolRole, TargetEvent } from '../../lib/store';
  import Lead from '../UI/Lead.svelte';

  import { decodeMath, previewMath } from '../../lib/helpers/decodeMath';
  import { defineIfUndefined } from '../../lib/helpers/defineIfUndefined';
  import {
    eventCustomFields,
    eventCustomFieldValues,
  } from '../../lib/helpers/eventCustomFields';
  import Button from '../UI/Button.svelte';
  import AttendanceButtonGroup from './AttendanceButtonGroup.svelte';
  import CategoryLabel from './CategoryLabel.svelte';
  import CustomFieldEditOrDisplay from './CustomFieldComponents/CustomFieldEditOrDisplay.svelte';
  import PersonaBlock from './PersonaBlock.svelte';
  import PublicFrom from './PublicFrom.svelte';
  import RoomButton from './RoomButton.svelte';
  import ViewersBlock from './ViewersBlock.svelte';
  let attendanceStatus = '';
  export let schoolEvent = null;
  let attendance;
  let eventSchool = null;
  TargetEvent.subscribe(async obj => {
    if (obj) {
      obj.customFieldValues = defineIfUndefined(obj.customFieldValues, {});
      if (typeof obj.filterCategories == 'undefined') obj.filterCategories = [];
      if (obj.useDeadlineDate) {
        obj.canAttend = obj.attendanceDeadline >= DateTime.now().toISODate();
        obj.actualAttendanceDeadline = obj.attendanceDeadline;
      } else if (obj.attendanceDeadlineDayCount) {
        obj.actualAttendanceDeadline = DateTime.fromISO(obj.startDate)
          .minus({
            days: obj.attendanceDeadlineDayCount,
          })
          .toISODate();
      } else {
        obj.actualAttendanceDeadline = obj.startDate;
      }
      console.log(obj.actualAttendanceDeadline);
      obj.canAttend =
        obj.actualAttendanceDeadline >= DateTime.now().toISODate();
      if (typeof obj.attendances == 'undefined') obj.attendances = [];
      obj.attendanceCount = 0;
      if (typeof obj.attendanceSettings == 'undefined')
        obj.attendanceSettings = [];
      obj.attendanceSettings.forEach(setting => {
        if (typeof setting.attendances == 'undefined') setting.attendances = [];
        setting.attendances.forEach(attendance => {
          let countArr = [
            'attending',
            'attended',
            'late',
            'leftEarly',
            'absent',
          ];
          if (countArr.indexOf(attendance.status) > -1) {
            obj.attendanceCount++;
          }
          if (
            attendance.status == 'attending' &&
            setting.useAttendanceCredit &&
            !attendance.attendanceCredit
          ) {
            attendance.attendanceCredit = setting.attendanceCredit;
          }
        });
      });
      console.log('canattend', obj.canAttend);
      schoolEvent = obj;
      if (
        obj.attendanceSettings.some(
          setting => setting.roleID == $SchoolRole.roleID,
        )
      ) {
        attendance = obj.attendanceSettings
          .find(setting => setting.roleID == $SchoolRole.roleID)
          .attendances.find(
            attendance => attendance.schoolRoleID == $SchoolRole.id,
          );
      }
      previewMath();
      eventSchool = await ioGet('getSchool', {
        _id: obj.schoolID,
      });
    }
  });
  const editEvent = () => {
    TargetEvent.set(schoolEvent);
    console.log(schoolEvent);
    UIkit.modal('#editEvent').show();
  };
  const deleteOccurrance = async () => {
    let date = $TargetEvent.clickedDate;
    console.log(date);
    date = DateTime.fromJSDate(date).toISO();
    console.log(date);
    let res = await ioGet('pushEventExdate', {
      _id: $TargetEvent._id,
      date: date,
    });
    console.log('canAttend', res.canAttend);
  };
  const attendanceChanged = async () => {
    console.log(attendance.status);
    let date = $TargetEvent.recurring
      ? DateTime.fromJSDate($TargetEvent.clickedDate).toISODate()
      : schoolEvent.startDate;
    schoolEvent.attendanceSettings.forEach(setting => {
      if (setting.roleID == $SchoolRole.roleID) {
        let match = false;
        setting.attendances.forEach(attendance => {
          if (attendance.schoolRoleID == $SchoolRole.id) {
            match = true;
            attendance.status = attendance.status;
          }
        });
        if (!match) {
          //make one!
          setting.attendances.push({
            schoolRoleID: $SchoolRole.id,
            status: attendance.status,
          });
        }
      }
    });
    await ioGet('updateEvent', {
      _id: $TargetEvent._id,
      data: {
        attendanceSettings: schoolEvent.attendanceSettings,
      },
    });
    TargetEvent.set(schoolEvent);
  };
  let attendanceStatusForAllAttending = 'attending';
  const changeAttendanceForAllAttending = () => {
    schoolEvent.attendanceSettings = schoolEvent.attendanceSettings.map(
      setting => {
        setting.attendance = setting.attendances.map(attendance => {
          if (attendance.status == 'attending') {
            attendance.status = attendanceStatusForAllAttending;
          }
          return attendance;
        });
        return setting;
      },
    );
  };
</script>

{#if schoolEvent && $School && $SchoolRole}
  <div uk-margin>
    <div class="uk-position-relative">
      {#if schoolEvent.categoryLabel && schoolEvent.categoryLabel != 'undefined'}
        <CategoryLabel entity={schoolEvent} />
      {/if}
      <div uk-grid class="uk-grid-small">
        {#if schoolEvent.iconImageURL}
          <div>
            <img
              alt="icon"
              src={schoolEvent.iconImageURL || '/images/no_photo.jpg'}
              style="height:80px;width:80px;"
              class="uk-border-rounded"
            />
          </div>
        {/if}
        <div>
          <Lead>
            {schoolEvent.title}
          </Lead>
          {#if schoolEvent.isPublic && schoolEvent.schoolID != $School._id}
            <PublicFrom schoolID={schoolEvent.schoolID} />
          {/if}
        </div>
      </div>
      <div class="uk-position-top-right">
        {#if $School.roles.find(role => role.id == $SchoolRole.roleID).school.event.canEdit && !(schoolEvent.isPublic && schoolEvent.schoolID != $School._id)}
          <Button onclick={editEvent}>{$_('Edit')}</Button>
        {/if}
      </div>
    </div>
    <hr />

    <div uk-grid>
      <div class="uk-position-relative uk-width-expand">
        <dl class="uk-description-list">
          <dt>
            <span class="uk-light">
              {$_('event.Date')}
            </span>
          </dt>
          <dd>
            {#if schoolEvent.allDay}
              {#if schoolEvent.multipleDays}
                <div>
                  {DateTime.fromISO(schoolEvent.startDate).toLocaleString()}
                  -
                  {DateTime.fromISO(schoolEvent.endDate).toLocaleString()}
                </div>
              {:else}
                <div>
                  {DateTime.fromISO(schoolEvent.startDate).toLocaleString()}
                </div>
              {/if}
            {:else if schoolEvent.multipleDays}
              <div>
                {DateTime.fromISO(schoolEvent.startDate).toLocaleString()}
                {DateTime.fromISO(schoolEvent.startAt).toLocaleString(
                  DateTime.TIME_SIMPLE,
                )}
                -
                {DateTime.fromISO(schoolEvent.endDate).toLocaleString()}
                {DateTime.fromISO(schoolEvent.endAt).toLocaleString(
                  DateTime.TIME_SIMPLE,
                )}
              </div>
            {:else}
              <div>
                {DateTime.fromISO(schoolEvent.startDate).toLocaleString()}
                {#if schoolEvent.useCustomTime || (schoolEvent.isPublic && schoolEvent.schoolID != $School._id)}
                  {DateTime.fromISO(schoolEvent.startAt).toLocaleString(
                    DateTime.TIME_SIMPLE,
                  )}
                  -
                  {DateTime.fromISO(schoolEvent.endAt).toLocaleString(
                    DateTime.TIME_SIMPLE,
                  )}
                {:else if schoolEvent.hourName && schoolEvent.hourName != 'undefined'}
                  {schoolEvent.hourName}
                {/if}
              </div>
            {/if}
          </dd>
        </dl>
      </div>
      {#if schoolEvent.location || schoolEvent.classroom}
        <div>
          {#if schoolEvent.location}
            <dl class="uk-description-list">
              <dt>
                <span class="uk-light">
                  {$_('event.Location')}
                </span>
              </dt>
              <dd>
                {schoolEvent.location}
              </dd>
            </dl>
          {/if}
          {#if schoolEvent.classroom}
            <dl class="uk-description-list">
              <dt>
                <span class="uk-light">
                  {$_('event.Classroom')}
                </span>
              </dt>
              <dd>
                {schoolEvent.classroom}
              </dd>
            </dl>
          {/if}
        </div>
      {/if}

      <!-- {#if schoolEvent.roomID && schoolEvent.roomID != 'undefined'}
        <div>
          <dl class="uk-description-list">
            <dt>
              <span class="uk-light">
                {$_('Room')}
              </span>
            </dt>
            <dd>
              <RoomButton
                roomID={schoolEvent.roomID}
                asGuest={schoolEvent.isPublic &&
                  schoolEvent.schoolID != $SchoolRole.schoolID}
              />
            </dd>
          </dl>
        </div>
      {/if} -->
      {#if schoolEvent.chatroom}
        <div>
          <Button
            onclick={() => {
              CometChatWidget.chatWithGroup(schoolEvent.chatroom);
              UIkit.modal('#chatModal').show();
            }}>{$_('chatrooms.Enter')}</Button
          >
        </div>
      {/if}
    </div>
    <hr />
    {#if schoolEvent.filterCategories.length > 0}
      <Lead>{$_('event.Viewers')}</Lead>
      <ViewersBlock entity={schoolEvent} />
      <hr />
    {/if}
    {#if schoolEvent.attendanceSettings?.some( setting => setting.attendances.some(attendance => attendance.schoolRoleID == $SchoolRole.id), )}
      <Lead>{$_('event.Attendances')}</Lead>
      <div>
        {#if !schoolEvent.attendanceSettings.find(setting => setting.roleID == $SchoolRole.roleID).autoAttendance}
          <div class="uk-buttou-group">
            <AttendanceButtonGroup
              disabledAttend={!schoolEvent.canAttend}
              attend={true}
              unattend={true}
              bind:value={attendance.status}
              onchange={attendanceChanged}
            />
          </div>
        {/if}
      </div>
    {/if}
    <div uk-grid>
      {#if schoolEvent.limitAttendance}
        <div>
          <dl class="uk-description-list">
            <dt>
              <span class="uk-light">
                {$_('event.AttendanceLimitNumber')}
              </span>
            </dt>
            <dd>
              {schoolEvent.attendanceLimitNumber}
              (
              {schoolEvent.attendanceLimitNumber - schoolEvent.attendanceCount} seats
              left)
            </dd>
          </dl>
        </div>
        <div>
          <dl class="uk-description-list">
            <dt>
              <span class="uk-light">
                {$_('event.AttendanceDeadline')}
              </span>
            </dt>
            <dd>
              {DateTime.fromISO(
                schoolEvent.actualAttendanceDeadline,
              ).toLocaleString()}
            </dd>
          </dl>
        </div>
      {/if}
    </div>
    <hr />
    <div class="mathPreview">
      {@html decodeMath(schoolEvent.description)}
    </div>
    <hr />
    {#if eventSchool}
      {#each eventSchool.customFields?.event || [] as field}
        <CustomFieldEditOrDisplay
          fieldSchool={eventSchool}
          {field}
          bind:value={schoolEvent.customFieldValues[field.id]}
          fields={eventSchool.customFields.event}
          values={schoolEvent.customFieldValues}
        />
      {/each}
    {/if}
    {#if $School.roles.find(role => role.id == $SchoolRole.roleID).school.attendance?.canRead && !(schoolEvent.isPublic && schoolEvent.schoolID != $School._id)}
      <hr />
      <div>
        <Lead>{$_('event.Attender')}</Lead>

        <ul class="uk-list">
          {#each schoolEvent.attendanceSettings || [] as setting}
            {#each setting.attendances || [] as attendance}
              <li class="uk-position-relative">
                <div uk-grid class="uk-grid-small">
                  <div class="uk-width-expand">
                    <PersonaBlock schoolRoleID={attendance.schoolRoleID} />
                  </div>
                  <div class="uk-flex uk-flex-middle">
                    <AttendanceButtonGroup
                      disabledAsk={!$School.roles.find(
                        role => role.id == $SchoolRole.roleID,
                      ).school.attendance.canEdit}
                      disabledAttend={!$School.roles.find(
                        role => role.id == $SchoolRole.roleID,
                      ).school.attendance.canEdit}
                      disabledUnattend={!$School.roles.find(
                        role => role.id == $SchoolRole.roleID,
                      ).school.attendance.canEdit}
                      disabledActual={!$School.roles.find(
                        role => role.id == $SchoolRole.roleID,
                      ).school.attendance.canEdit}
                      asking={!setting.autoAttendance}
                      bind:value={attendance.status}
                      attend={true}
                      unattend={true}
                      onchange={async () => {
                        await ioGet('updateEvent', {
                          _id: schoolEvent._id,
                          data: {
                            attendanceSettings: schoolEvent.attendanceSettings,
                          },
                        });
                        TargetEvent.set(schoolEvent);
                      }}
                    />
                  </div>
                  {#if attendance.status == 'attending'}
                    {#each $School.customFields.attendance.filter(f => !f.showOnList) as field}
                      {#if attendance.customFieldValues}
                        <CustomFieldEditOrDisplay
                          fieldSchool={eventSchool}
                          bind:value={attendance.customFieldValues[field.id]}
                          values={attendance.customFieldValues}
                          {field}
                          fields={$School.customFields.attendance}
                          parentFields={eventCustomFields($School)}
                          parentValues={eventCustomFieldValues(schoolEvent)}
                        />
                      {/if}
                    {/each}
                  {/if}
                </div>
              </li>
            {/each}
          {/each}
        </ul>
      </div>
    {/if}
  </div>
{/if}
