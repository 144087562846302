import getBoundingBox from './getBoundingBox';
import { ringHeight, ringRadius } from '../defaultValues.js';
let ringLength = ringRadius * 2 * 3.14;

const scaleDashboardItem = component => {
  let dashboardScale = component.getAttribute('dashboardScale');
  let res = getBoundingBox(component.object3D, 'width-height');
  let itemHeight = res.height / dashboardScale;
  let itemWidth = res.width / dashboardScale;
  var scale = ringHeight / itemHeight;
  itemWidth = itemWidth * scale;
  var itemWidthAngle = (itemWidth / ringLength) * 360;
  console.log(scale);
  component.setAttribute('scale', scale + ' ' + scale + ' ' + scale);
  component.setAttribute('itemWidthAngle', itemWidthAngle);
  component.object3D.position.y =
    res.center.y * -1 * scale +
    parseFloat(component.getAttribute('dashboardOffset'));
  component.setAttribute('rotation', '0 180 0');
};
export default scaleDashboardItem;
