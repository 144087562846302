let Avatars = [
  {
    id: 1,
    url: '/models/preset-avatars/3d640ef629f7cac719226728229102f2a976a0c7.glb',
    thumbnail: '/images/avatar_thumb_1.jpg',
  },
  {
    id: 2,
    url: '/models/preset-avatars/c8a6ef9b838ae1c3f15af78970fe6a8c147c1330.glb',
    thumbnail: '/images/avatar_thumb_2.jpg',
  },
  {
    id: 3,
    url: '/models/preset-avatars/00e6655f8f97ba6b6184ccfa870a6288b9864eba.glb',
    thumbnail: '/images/avatar_thumb_3.jpg',
  },
  {
    id: 31,
    url: '/models/preset-avatars/448ee41e25ec53e3d5d378345e7fb4c426b90075.glb',
    thumbnail: '/images/avatar_thumb_4.jpg',
  },
  {
    id: 32,
    url: '/models/preset-avatars/d835324b3e3bb7db1e88c13c41f866e651487a79.glb',
    thumbnail: '/images/avatar_thumb_5.jpg',
  },
  {
    id: 4,
    url: '/models/preset-avatars/e005aef34f427a4da58687456b7f810aa7bcf62b.glb',
    thumbnail: '/images/avatar_thumb_6.jpg',
  },
  {
    id: 5,
    url: '/models/preset-avatars/84a52e40b68dde308c4399c6a03c069ea4b84119.glb',
    thumbnail: '/images/avatar_thumb_7.jpg',
  },
  {
    id: 6,
    url: '/models/preset-avatars/f521e6a04d75946325e6a64f97c61143ce8a9105.glb',
    thumbnail: '/images/avatar_thumb_8.jpg',
  },
  {
    id: 7,
    url: '/models/preset-avatars/b3c158be8e39d28a8cc541052c7497cfa9d7bdbe.glb',
    thumbnail: '/images/avatar_thumb_9.jpg',
  },
  {
    id: 8,
    url: '/models/preset-avatars/803467595a3f61879be83e39d2bf566e7d0448c6.glb',
    thumbnail: '/images/avatar_thumb_10.jpg',
  },
  {
    id: 9,
    url: '/models/preset-avatars/f0bb9b0e7ad3d12c0d77fb2243e957abd43bb6b9.glb',
    thumbnail: '/images/avatar_thumb_11.jpg',
  },
  {
    id: 10,
    url: '/models/preset-avatars/1aad0f7759fb3b36bba8d5169c446edb783b7f45.glb',
    thumbnail: '/images/avatar_thumb_12.jpg',
  },
  {
    id: 11,
    url: '/models/preset-avatars/821ea079ee19dccede19e836c3124daef32922e5.glb',
    thumbnail: '/images/avatar_thumb_13.jpg',
  },
  {
    id: 12,
    url: '/models/preset-avatars/c2a1c1bc5556d8f8a9fe07fa7d344dbd581d6c1c.glb',
    thumbnail: '/images/avatar_thumb_14.jpg',
  },
  {
    id: 13,
    url: '/models/preset-avatars/02e74d941736c0447ed59dc720e4172d56a9e61e.glb',
    thumbnail: '/images/avatar_thumb_15.jpg',
  },
  {
    id: 14,
    url: '/models/preset-avatars/df158553c33310f113cfec6e1f8fb77063500517.glb',
    thumbnail: '/images/avatar_thumb_16.jpg',
  },
];
export default Avatars;
