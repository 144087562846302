<script lang="ts">
  import { onMount } from 'svelte';
  // import { removeGroup } from '../../lib/helpers/chatLogin';
  import { ioGet } from '../../lib/realtime';
  import { assignmentsStore } from '../../state/stores/assignmentsStore';
  import GroupBlock from './GroupBlock.svelte';
  import SubmissionRow from './SubmissionRow.svelte';
  import SummaryFieldHeading from './SummaryFieldHeading.svelte';
  export let assignmentCategory;
  export let interval;
  export let subscriber;
  export let saveCategory = () => {};
  onMount(async () => {
    assignmentsStore.clear();
    let groupIDs = [];
    interval.programCategories
      .filter(category => category.useToGroupAssignment)
      .forEach(programCategory => {
        programCategory.groups.forEach(group => {
          if (group.children.length) {
            group.children.forEach(child => {
              groupIDs.push(child.id);
            });
          } else {
            groupIDs.push(group.id);
          }
        });
      });
    let assignments = await ioGet('listAssignments', {
      groups: groupIDs,
      assignmentCategoryID: assignmentCategory.id,
    });
    assignmentsStore.groupIDs = groupIDs;
    assignmentsStore.assignmentCategoryID = assignmentCategory.id;
    assignmentsStore.set(assignments);
  });
</script>

<table
  class="uk-table uk-table-divider uk-table-small uk-table-justify uk-table-middle"
>
  <thead>
    <th />
    {#each assignmentCategory.submissionSummaryFields || [] as summaryField, i}
      <th class="uk-text-center">
        <SummaryFieldHeading
          bind:summaryField
          bind:summaryFields={assignmentCategory.submissionSummaryFields}
          sourceCustomFields={assignmentCategory.submissionCustomFields}
          save={saveCategory}
          {i}
        />
      </th>
    {/each}
    <th />
  </thead>
  <tbody>
    {#each interval.programCategories.filter(category => category.useToGroupAssignment) as programCategory}
      {#each programCategory.groups as group}
        {#if group.children.length}
          {@const groupChildren = group.children.filter(child =>
            subscriber.registrations.some(
              registration => registration.programID == child.id,
            ),
          )}
          {#if groupChildren.length}
            <tr>
              <th>
                <div class="">
                  {group.title}
                </div>
              </th>
            </tr>
            {#each groupChildren as child}
              <tr>
                <td>
                  <div class="uk-margin-small-left">
                    <GroupBlock
                      withCustomFields
                      customFields={programCategory.childCustomFields}
                      small
                      groupID={child.id}
                      withChat={programCategory.allowDirectChatInAssignment}
                    />
                  </div>
                </td>

                {#key assignmentCategory.submissionSummaryFields}
                  <SubmissionRow
                    {assignmentCategory}
                    {interval}
                    group={child}
                    {subscriber}
                  />
                {/key}
              </tr>
            {/each}
          {/if}
        {:else if subscriber.registrations.some(registration => registration.programID == group.id)}
          <tr>
            <td>
              <div class="">
                <GroupBlock
                  withCustomFields
                  customFields={programCategory.customFields}
                  small
                  groupID={group.id}
                />
              </div>
            </td>
            {#key assignmentCategory.submissionSummaryFields}
              <SubmissionRow
                {assignmentCategory}
                {interval}
                {group}
                {subscriber}
              />
            {/key}
          </tr>
        {/if}
      {/each}
    {/each}
  </tbody>
</table>
