<script>
  import Input from '../UI/Input.svelte';

  import { _ } from 'svelte-i18n';
  import { onMount } from 'svelte/internal';
  import arraySwap from '../../lib/helpers/arraySwap';
  import { School } from '../../lib/store';
  import Button from '../UI/Button.svelte';
  import Lead from '../UI/Lead.svelte';
  import ArrayNav from './ArrayNav.svelte';
  import CustomFieldEditOrDisplay from './CustomFieldComponents/CustomFieldEditOrDisplay.svelte';
  import EntityThumbnail from './EntityThumbnail.svelte';
  import TinyTextarea from './TinyTextarea.svelte';
  import ViewersCheckboxes from './ViewersCheckboxes.svelte';
  export let product;
  export let presetEditMode = false;
  onMount(() => {
    if (typeof product.customFieldValues == 'unefined') {
      product.customFieldValues = {};
    }
  });

  const addPrice = () => {
    product.prices = [
      ...product.prices,
      {
        price: 0,
        currency: 'jpy',
      },
    ];
  };

  const moveUp = i => {
    product.prices = arraySwap(product.prices, i, i - 1);
  };
  const moveDown = i => {
    product.prices = arraySwap(product.prices, i, i + 1);
  };
  const remove = i => {
    product.prices.splice(i, 1);
    product.prices = product.prices;
  };
</script>

{#if product}
  <div uk-margin class="uk-margin">
    <div uk-grid>
      {#if !presetEditMode}
        <div>
          <Input
            label={$_('product.ProductCategory')}
            bind:value={product.categoryLabel}
          />
        </div>
      {/if}

      <div>
        <Input
          label={$_('event.LabelColor')}
          type="color"
          bind:value={product.backgroundColor}
        />
      </div>
    </div>
    <hr />
    <div uk-grid>
      <div>
        <EntityThumbnail bind:entity={product} />
      </div>
      <div>
        <Input
          label={$_('product.ProductTitle')}
          type="text"
          bind:value={product.title}
        />
      </div>
    </div>
    <hr />
    {$_('product.Prices')}
    <ul class="uk-list">
      {#each product.prices as price, i}
        <li>
          <div uk-grid>
            <div>
              <Input
                type="number"
                label={$_('product.Price')}
                bind:value={price.price}
              />
            </div>
            <div>
              <select class="uk-select" bind:value={price.currency}>
                <option value="jpy">{$_('product.Yen')}</option>
              </select>
            </div>
          </div>
          <ArrayNav
            {i}
            {moveUp}
            {moveDown}
            {remove}
            length={product.prices.length}
          />
        </li>
      {/each}
    </ul>
    <Button small onclick={addPrice}>{$_('product.AddPrice')}</Button>
    <hr />
    <div uk-grid>
      {#if !presetEditMode}
        <div>
          <Lead>{$_('event.Schedule')}</Lead>
          <div>
            <Input
              label={$_('product.PublishDate')}
              type="date"
              bind:value={product.startDate}
            />
          </div>
        </div>
      {/if}
      <div>
        <Lead>{$_('event.Viewers')}</Lead>
        <ViewersCheckboxes bind:entity={product} componentCategory="Product" />
      </div>
    </div>

    <TinyTextarea bind:value={product.description} />
  </div>
  <hr />
  {#each $School.customFields?.product || [] as field}
    {#if product.customFieldValues}
      <CustomFieldEditOrDisplay
        {field}
        bind:value={product.customFieldValues[field.id]}
      />
    {/if}
  {/each}
{/if}
