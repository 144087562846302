<script lang="ts">
  import AssignmentsTable from './AssignmentsTable.svelte';
  import Lead from '../UI/Lead.svelte';
  import { _ } from 'svelte-i18n';
  import { School, TargetProgramCategory } from '../../lib/store';
  import GradeTable from './GradeTable.svelte';
  import { onMount, setContext } from 'svelte';
  import CustomFieldDisplay from './CustomFieldComponents/CustomFieldDisplay.svelte';
  import AssignmentTabs from './AssignmentTabs.svelte';
  import MetaText from '../UI/MetaText.svelte';
  import GroupBlock from './GroupBlock.svelte';
  import { ioGet } from '../../lib/realtime';
  import { navigate, Route, Router } from 'svelte-routing';
  import AttendanceCreditTable from './AttendanceCreditTable.svelte';

  import Button from '../UI/Button.svelte';
  import Input from '../UI/Input.svelte';
  import ViewersBlock from './ViewersBlock.svelte';
  import CategoryLabel from './CategoryLabel.svelte';
  import EventCategories from './EventCategories.svelte';
  import { UUID } from '../../lib/uuid';
  import areYouSure from '../../lib/helpers/areYouSure';
  import Dropdown from '../UI/Dropdown.svelte';
  import ArrayNav from './ArrayNav.svelte';
  import arraySwap from '../../lib/helpers/arraySwap';
  import EventList from './EventList.svelte';
  import EventViewCreateMenu from './EventViewCreateMenu.svelte';
  import ViewSettingDropdown from './ViewSettingDropdown.svelte';
  import type {
    IntervalInterface,
    ProgramCategoryInterface,
  } from '../../lib/interfaces/Interval';
  import { Notification } from '../../actions/notification';
  export let save: () => void;
  export let group;
  export let school;
  export let programCategory: ProgramCategoryInterface;
  export let interval: IntervalInterface;
  export let isChild: Boolean;
  $: TargetProgramCategory.set(programCategory);
  let styles = ['Subscriber', 'Event'];
  let focus = location.href
    .split(group.id + '/')
    .pop()
    .split('/')
    .shift()
    .replace('#', '');
  onMount(() => {
    if (location.href.split('/').pop().indexOf('grade') > -1) {
      focus = 'grade';
    } else if (
      location.href.split('/').pop().indexOf('attendance-credit') > -1
    ) {
      focus = 'attendance-credit';
    } else {
      school.assignmentCategories.forEach(category => {
        if (location.href.indexOf(category.id) > -1) {
          focus = category.id;
        }
      });
      if (typeof school.eventViews == 'undefined') school.eventViews = [];
      school.eventViews.forEach(view => {
        if (location.href.indexOf(view.id) > -1) {
          focus = view.id;
        }
      });
    }
    if (focus == '') {
      focus = school.assignmentCategories[0].id;
    }
    console.log('navigate', focus);
    navigate(focus);
  });
  const saveCategory = async () => {
    let res = await ioGet('updateSchool', {
      _id: school._id,
      data: {
        assignmentCategories: school.assignmentCategories,
      },
    });
    Notification($_('Saved'));
    School.set(school);
  };
  const saveView = async () => {
    let res = await ioGet('updateSchool', {
      _id: school._id,
      data: {
        eventViews: school.eventViews,
      },
    });
    Notification($_('Saved'));
    School.set(school);
  };
</script>

<div>
  {#key group.id}
    <GroupBlock
      withChat={programCategory.useChatroom}
      bind:groupID={group.id}
    />
  {/key}
  <div uk-grid class="uk-margin">
    {#if isChild}
      {#each programCategory.childCustomFields?.filter(category => category.showOnList) || [] as field}
        <div>
          <MetaText>
            {field.title}
          </MetaText>
          <CustomFieldDisplay
            {field}
            value={group.customFieldValues[field.id]}
          />
        </div>
      {/each}
    {:else}
      {#each programCategory.customFields.filter(category => category.showOnList) as field}
        <div>
          <MetaText>
            {field.title}
          </MetaText>
          <CustomFieldDisplay
            {field}
            value={group.customFieldValues[field.id]}
          />
        </div>
      {/each}
    {/if}
  </div>
  <ul class="uk-tab">
    {#if programCategory.useToGroupAssignment}
      {#each school.assignmentCategories as category}
        <li class:uk-active={focus == category.id}>
          <a
            href={'#'}
            on:click={() => {
              navigate(`${category.id}`);
              focus = category.id;
            }}
          >
            {category.title}
          </a>
        </li>
      {/each}
    {/if}
    {#if programCategory.useAttendanceCredit}
      {#each school.eventViews || [] as view}
        <li class:uk-active={focus == view.id}>
          <a
            href={'#'}
            on:click={() => {
              navigate(view.id);
              focus = view.id;
            }}
          >
            {view.title}
          </a>
        </li>
      {/each}
    {/if}
    {#if programCategory.useToGroupGrades}
      <li class:uk-active={focus == 'grade'}>
        <a
          href={'#'}
          on:click={() => {
            navigate(`grade`);
            focus = 'grade';
          }}
        >
          {$_('Grades')}
        </a>
      </li>
    {/if}
    {#if programCategory.useAttendanceCredit}
      <li>
        <EventViewCreateMenu
          {styles}
          bind:eventViews={school.eventViews}
          {school}
          {saveView}
        />
      </li>
    {/if}
  </ul>
  <div class="uk-margin">
    <Router>
      {#if programCategory.useToGroupAssignment}
        {#each school.assignmentCategories as category}
          <Route path={category.id}>
            <div class="uk-margin">
              <AssignmentTabs
                {interval}
                {saveCategory}
                bind:category
                bind:group
                {save}
              />
            </div>
          </Route>
        {/each}
      {/if}
      {#each school.eventViews || [] as view, i}
        <Route path={view.id + '/*'}>
          <div class="uk-text-right uk-width-expand">
            <ViewSettingDropdown
              {styles}
              bind:views={school.eventViews}
              bind:view
              categories={school.eventCategories}
              {i}
            />
          </div>
          {#if view.style == 'bySubscriber'}
            <AttendanceCreditTable bind:view {saveView} {interval} {group} />
          {/if}
          {#if view.style == 'byEvent'}
            <EventList bind:view {group} />
          {/if}
        </Route>
      {/each}
      {#if programCategory.useToGroupGrades}
        <Route path="grade/*">
          <GradeTable {programCategory} {interval} {group} />
        </Route>
      {/if}
    </Router>
  </div>
</div>
