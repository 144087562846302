<script lang="ts">
  import { _ } from 'svelte-i18n';
  import arraySwap from '../../../lib/helpers/arraySwap';
  import Button from '../../UI/Button.svelte';

  import Input from '../../UI/Input.svelte';
  import ArrayNav from '../ArrayNav.svelte';
  export let values = [];
  const moveUp = (i: number) => {
    values = arraySwap(values, i, i - 1);
  };
  const moveDown = (i: number) => {
    values = arraySwap(values, i, i + 1);
  };
  const remove = (i: number) => {
    values.splice(i, 1);
    values = values;
  };
  const addValue = () => {
    values = [...values, ''];
  };
</script>

<ul class="uk-list uk-list-collapse">
  {#each values || [] as value, i}
    <li>
      <Input type="text" bind:value />

      <ArrayNav {moveUp} {moveDown} {remove} {i} arrayLength={values.length} />
    </li>
  {/each}
</ul>
<Button small onclick={addValue}>{$_('Add')}</Button>
