import { hideModal } from '../../actions/modalController';
import { LoadingMessage } from '../store';
const Loading = {
  show: (message = '') => {
    UIkit.modal('#loading-spinner').show();
    LoadingMessage.set(message);
  },
  hide: () => {
    hideModal('#loading-spinner');
    document.getElementById('uploadAbortButton').hidden = true;
  },
  showAbortButton: callback => {
    document.getElementById('uploadAbortButton').removeAttribute('hidden');
    document
      .getElementById('uploadAbortButton')
      .addEventListener('click', callback, { once: true });
  },
};
export default Loading;
