import { ioGet } from '../realtime';
import { HOST } from '../config';
let authToken = '';
export const getChatAuthToken = async schoolRoleID => {
  if (!schoolRoleID) return false;
  console.log(schoolRoleID);
  let res = await ioGet('createAuthToken', {
    uid: schoolRoleID,
  });
  console.log(res);
  if (!res.data?.authToken) return false;
  authToken = res.data.authToken;
  if (authToken) {
    let res = await ioGet('updatePersona', {
      SchoolRoleID: schoolRoleID,
      data: {
        cometChatAuthToken: authToken,
      },
    });
  }
  return authToken;
};
