<script lang="ts">
  import { FileLoader } from 'three';
  import { School } from '../../lib/store';
  import CustomFieldDisplay from './CustomFieldComponents/CustomFieldDisplay.svelte';
  import {
    eventCustomFields,
    eventCustomFieldValues,
  } from '../../lib/helpers/eventCustomFields';
  import openEventDetail from '../../lib/helpers/openEventDetail';
  import { DateTime } from 'luxon';

  export let subscriber;
  export let schoolEvent;
  export let attendance;
  if (typeof schoolEvent.customFields == 'undefined') {
    schoolEvent.customFields = [];
  }
  let submission;
  let values = attendance.customFieldValues || {};
</script>

{#if schoolEvent && values}
  {@const field =
    $School.customFields.attendance.find(field => field.showOnList) || {}}
  <div
    on:click={() => {
      openEventDetail(schoolEvent._id);
    }}
    class="uk-text-center"
    style:cursor="pointer"
  >
    {#if attendance.status == 'attending' || schoolEvent.attendanceSettings.find(setting => setting.roleID == subscriber.roleID).autoAttendance}
      {schoolEvent.title}<br />
      {DateTime.fromISO(schoolEvent.startDate).toFormat('M/d')}
      <div>
        <CustomFieldDisplay
          parentFields={eventCustomFields($School)}
          parentValues={eventCustomFieldValues(schoolEvent)}
          fields={$School.customFields.attendance}
          {values}
          {field}
          bind:value={values[field.id]}
        />
      </div>
    {:else}
      {schoolEvent.title}<br />
      {DateTime.fromISO(schoolEvent.startDate).toFormat('M/d')}
    {/if}
  </div>
{/if}
