import { School } from '../lib/store';
import { ioGet } from '../lib/realtime';

let school;
School.subscribe(obj => {
  school = obj;
});

let events = [];
const listEvents = async () => {
  events = await ioGet('listEvents', {
    schoolID: school._id,
  });
  return events;
};
const getEvent = async () => {};

export { listEvents };
