import { writable } from 'svelte/store';

const videosReducer = writable([]);

const videosStore = {
  subscribe: videosReducer.subscribe,
  setVideos: videos => {
    videosReducer.set(videos);
  },

  addVideo: video => {
    videosReducer.update(videos => {
      return [...videos, video];
    });
  },

  deleteVideo: videoId => {
    videosReducer.update(videos => {
      return videos.filter(video => video.videoId !== videoId);
    });
  },
};

export default videosStore;
