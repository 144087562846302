<script>
  import { TargetPersona, School } from '../../lib/store';
  import PersonaBlock from '../blocks/PersonaBlock.svelte';
  import Modal from '../UI/Modal.svelte';
  import { _ } from 'svelte-i18n';
  import AScene from '../aframeBlocks/AScene.svelte';
  import Button from '../UI/Button.svelte';
  import { ioGet } from '../../lib/realtime';

  let persona = null;
  let school = null;
  TargetPersona.subscribe(async obj => {
    if (obj) {
      persona = null;
      persona = obj;
      school = await ioGet('getSchool', {
        _id: obj.schoolID,
      });
    }
  });
</script>

{#if persona}
  <Modal id="profile">
    <div slot="body">
      <div class="uk-grid-small" uk-grid>
        <div>
          <img
            alt="Avatar"
            src={persona.profileImageURL || '/images/no_photo.jpg'}
            style="
                    width:80px;
                    height:80px
                    "
            class="uk-border-circle"
          />
        </div>
        <div>
          {persona.nickname}
          <div class="uk-text-meta uk-text-small">
            {school?.roles.find(schoolRole => schoolRole.id == persona.roleID)
              ?.title}
            @
            {school?.title}
          </div>
          <div class="uk-text-small">
            <!-- {#if persona.isOnline}
              <span style="color:lightgreen">
                <i class="fa-solid fa-circle" />
              </span>
              {persona.onlineStatus || $_('Online')}
            {/if} -->
            <Button
              style="text"
              onclick={() => {
                CometChatWidget.chatWithUser(persona.id);
                UIkit.modal('#chatModal').show();
              }}
            >
              <i class="fa-duotone fa-comments" />
            </Button>
          </div>
        </div>
      </div>
      <!-- {#if persona.bio}
        <div>
          {$_('Biography')}
        </div>
        {@html persona.bio}
      {/if} -->
    </div>
  </Modal>
{/if}
