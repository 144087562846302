<script lang="ts">
  export let fields;
  export let value;
  export let onchange = () => {};
</script>

<select class="uk-select" bind:value on:change={onchange}>
  {#each fields || [] as field}
    <option value={field.id}>{field.title}</option>
  {/each}
</select>
