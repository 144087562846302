import { ioGet } from '../realtime';
import { School, Room } from '../store';
let school = null;
School.subscribe(obj => {
  school = obj;
});
let room = null;

Room.subscribe(obj => {
  room = obj;
});
const validateSlug = async (str = '') => {
  const regex = /[a-z0-9-]+/g;
  const found = str.match(regex);
  var res = {};
  if (!found) {
    res.message = 'SlugCanOnlyContain';
    return res;
  }
  if (found.length == 1) {
    if (found[0].length == str.length) {
      let resSchool = await ioGet('getSchool', {
        slug: str,
      });

      if (resSchool && school && resSchool._id == school._id) {
        resSchool = false;
      }
      let resRoom = await ioGet('getRoom', {
        slug: str,
      });
      if (resRoom && room && resRoom._id == room._id) {
        resRoom = false;
      }

      if (resSchool || resRoom) {
        //already exists
        res.message = 'ThisSlugIsAlreadyTaken';
      } else {
        res.status = 'success';
      }
    } else {
      res.message = 'SlugCanOnlyContain';
    }
  } else {
    res.message = 'SlugCanOnlyContain';
  }
  return res;
};
export default validateSlug;
