<script lang="ts">
  import Div from './UI/Div.svelte';

  import { _ } from 'svelte-i18n';
  import Input from './UI/Input.svelte';
  import Button from './UI/Button.svelte';
  import { ioGet } from '../lib/realtime';
  import { School } from '../lib/store';
  import { Notification } from '../actions/notification';
  import axios from 'axios';
  import { ENDPOINT } from '../lib/config';
  import Lead from './UI/Lead.svelte';
  import { UUID } from '../lib/uuid';
  import { children } from 'svelte/internal';
  import LinkToDocumentation from './blocks/LinkToDocumentation.svelte';
  const save = async () => {
    let res = await ioGet('updateSchool', {
      _id: $School._id,
      data: $School,
    });
    Notification($_('Saved'));
  };
  let intervalTitle;
  let intervalStartOn;
  let intervalEndOn;
</script>

<Div>
  <Input bind:value={$School.V1SchoolID} label={$_('migration.SchoolID')} />
  <Button small onclick={save}>{$_('Save')}</Button>
</Div>
<hr />
<Div>
  <Lead>{$_('HourNames')}</Lead>
  <Button
    onclick={async () => {
      let res = await axios.post(ENDPOINT + '/api/migration', {
        mode: 'getHourNames',
        clientID: $School.V1SchoolID,
      });
      console.log(res);
      let hourNames = res.data.map(row => {
        return {
          title: row.name,
          startAt: row.start,
          endAt: row.end,
        };
      });
      $School.hourNames = hourNames;
      save();
    }}>{$_('migration.TakeHourNames')}</Button
  >
</Div>
<hr />
<Div>
  <Lead>{$_('AssignmentCategories')}</Lead>
  <Button
    onclick={async () => {
      let res = await axios.post(ENDPOINT + '/api/migration', {
        mode: 'getAssignmentCategories',
        clientID: $School.V1SchoolID,
        schoolID: $School._id,
      });
      console.log(res);
      let assignmentCategories = [
        {
          id: UUID(),
          title: res.data.title1,
          customFields: [],
          submissionCustomFields: [],
          submissionSummaryFields: [],
        },
        {
          id: UUID(),
          title: res.data.title2,
          customFields: [],
          submissionCustomFields: [],
          submissionSummaryFields: [],
        },
      ];
      $School.assignmentCategories = assignmentCategories;
      save();
    }}>{$_('migration.TakeAssignmentCategories')}</Button
  >
</Div>
<hr />
<Div>
  <Lead>{$_('migration.ImportInterval')}</Lead>
  <Input label={$_('Title')} bind:value={intervalTitle} />
  <Input
    label={$_('schoolSetting.StartOn')}
    bind:value={intervalStartOn}
    type="date"
  />
  <Input
    label={$_('schoolSetting.EndOn')}
    bind:value={intervalEndOn}
    type="date"
  />
  <Button
    onclick={async () => {
      let res = await axios.post(ENDPOINT + '/api/migration', {
        mode: 'createInterval',
        clientID: $School.V1SchoolID,
        title: intervalTitle,
        startOn: intervalStartOn,
        endOn: intervalEndOn,
      });
      console.log(res);
      $School.intervals.push(res.data);
      save();
    }}>{$_('migration.CreateInterval')}</Button
  >
</Div>
<hr />
<Div>
  <Button
    onclick={async () => {
      let res = await axios.post(ENDPOINT + '/api/migration', {
        mode: 'createAssignments',
        clientID: $School.V1SchoolID,
        schoolID: $School._id,
      });
      console.log(res);
      $School.assignmentCategories[0].customFields =
        res.data.assignmentCategoryCustomFields1;
      $School.assignmentCategories[1].customFields =
        res.data.assignmentCategoryCustomFields2;
      $School.assignmentCategories[0].submissionCustomFields =
        res.data.submissionCustomFields1;
      $School.assignmentCategories[1].submissionCustomFields =
        res.data.submissionCustomFields2;

      //res.data.assignments1.forEach((assignment) => {});
      save();
    }}>{$_('migration.ImportAssignments')}</Button
  >
</Div>
<hr />
<Div>
  <Button
    onclick={async () => {
      let res = await axios.post(ENDPOINT + '/api/migration', {
        mode: 'createTeachers',
        clientID: $School.V1SchoolID,
        schoolID: $School._id,
      });
      console.log(res);
    }}>{$_('migration.ImportTeachers')}</Button
  >
</Div>
<hr />
<Div>
  <Button
    onclick={async () => {
      let res = await axios.post(ENDPOINT + '/api/migration', {
        mode: 'createStudents',
        clientID: $School.V1SchoolID,
        schoolID: $School._id,
      });
      console.log(res);
    }}>{$_('migration.ImportStudents')}</Button
  >
</Div>
