import { ShapeType } from 'three-to-cannon';
import { ENDPOINT, SERVER_URL } from '../config';
import { ioGet } from '../realtime';
import { LoadingMessage } from '../store';
import Loading from './loading';
import { VideoUploader } from '@api.video/video-uploader';
import dialog from './dialog';
import axios from 'axios';
import { UUID } from '../uuid';
let xhr;
const upload = (fd, callback, reject = () => {}) => {
  Loading.show();
  Loading.showAbortButton(() => {
    xhr.abort();
    Loading.hide();
  });

  xhr = new XMLHttpRequest();
  xhr.open('POST', ENDPOINT + '/upload', true);
  xhr.upload.addEventListener('progress', e => {
    console.log(e);
    let percentage = Math.round((100 * e.loaded) / e.total);
    LoadingMessage.set(percentage + '%');
  });

  xhr.addEventListener('load', event => {
    if (xhr.status === 403) {
      dialog('HTTP Error: ' + xhr.status);
      reject();
      return;
    }

    if (xhr.status < 200 || xhr.status >= 300) {
      dialog('HTTP Error: ' + xhr.status);
      reject();

      return;
    }
    console.log('upload finished', xhr.status);
    Loading.hide();
    console.log(event);
    const response = JSON.parse(xhr.responseText);
    console.log(response);
    callback(response);
  });
  xhr.send(fd);
};
export const abortUpload = () => {
  xhr.abort();
};
let uploader = null;
let uploadSessions = {};
export const uploadVIdeoFile = async (files, callback) => {
  let sessionID = UUID();
  uploadSessions[sessionID] = 'inProgress';
  Loading.show();
  Loading.showAbortButton(() => {
    uploadSessions[sessionID] = 'aborted';
    Loading.hide();
  });
  let token = await ioGet('getUploadToken');
  console.log(token);
  uploader = new VideoUploader({
    file: files[0],
    uploadToken: token.token,
    chunkSize: 1024 * 1024 * 10, // 10MB
    retries: 10,
  });
  uploader
    .upload()
    .then(async video => {
      if (uploadSessions[sessionID] == 'aborted') {
        delete uploadSessions[sessionID];
        return;
      }
      const response = await axios.put(SERVER_URL + 'api/videos', {
        videoId: video.videoId,
      });

      Loading.hide();
      console.log(response.data);
      callback(response.data);
    })
    .catch(error => console.log(error.status, error.message));
  uploader.onProgress(event => {
    if (uploadSessions[sessionID] == 'aborted') {
      return;
    }
    let percentage = Math.round((100 * event.uploadedBytes) / event.totalBytes);

    LoadingMessage.set(percentage + '%');
    //console.log(`total number of bytes uploaded for this upload: ${event.uploadedBytes}.`);
    //console.log(`total size of the file: ${event.totalBytes}.`);
  });
};
export default upload;
