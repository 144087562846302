<script lang="ts">
  import Button from '../UI/Button.svelte';
  import Section from '../UI/Section.svelte';
  import { _ } from 'svelte-i18n';
  import { onMount } from 'svelte';
  import { FooterVisible } from '../../lib/store';
</script>

{#if $FooterVisible}
  <section class="uk-section">
    <div class="uk-container uk-light">
      <div class=" uk-width-expand uk-flex uk-flex-center">
        <ul class="uk-subnav">
          <li>
            <Button
              style="link"
              onclick={() => {
                location.href = 'https://aoike-ep.com/openwater/kiyaku.pdf';
              }}
            >
              {$_('TermsOfUse')}
            </Button>
          </li>
          <li>
            <Button
              style="link"
              onclick={() => {
                location.href = 'https://aoike-ep.com/privacy.html';
              }}
            >
              {$_('PrivacyPolicy')}
            </Button>
          </li>
        </ul>
      </div>
      <div class="uk-text-center">
        <div
          class="uk-flex uk-flex-center"
          data-id="b25d1e0"
          data-element_type="widget"
          data-widget_type="image.default"
        >
          <div class="uk-width-small">
            <img
              src="https://aoike-ep.com/openwater/wp-content/uploads/2022/03/f_logo-1.png"
              alt=""
            />
          </div>
        </div>
        <div class="uk-margin">
          <p>
            運営会社：<a
              href="https://aoike-ep.com/"
              target="_blank"
              rel="noopener">AOIKE エンタープライズ株式会社</a
            >
          </p>
        </div>
        <div
          class="elementor-element elementor-element-31ca449a elementor-widget elementor-widget-heading"
          data-id="31ca449a"
          data-element_type="widget"
          data-widget_type="heading.default"
        >
          <div class="elementor-widget-container">
            <p class="elementor-heading-title elementor-size-default">
              © AOIKE ENTERPRISE All Right Reserved.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
{/if}
