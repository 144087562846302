<script lang="ts">
  import copy from 'copy-to-clipboard';

  import { afterUpdate } from 'svelte';
  import { _ } from 'svelte-i18n';
  import { Notification } from '../../actions/notification';
  import Button from './Button.svelte';

  export let value: any = '';
  export let required: boolean = false;
  export let label: string = '';
  export let hidden: boolean = false;
  export let type: string = 'text';
  export let onchange: (evt: Event) => void = () => {};
  export let medium: boolean = false;
  export let placeholder: string = '';
  export let readonly: boolean = null;
  export let copiable: boolean = false;
  export let id: string = '';
  export let small: boolean = false;
  export let min: number | null = null;
  export let max: number | null = null;
  export let step: number | null = null;
  export let withUnit: string = '';
  export let tooltip: string = '';
  export let margin: 'small' | 'medium' | 'large' | '' = '';
  export let tooltipPosition: string = '';

  let el;
  function typeAction(node) {
    if (el) {
      el.type = type;
    }
  }
  afterUpdate(() => {
    typeAction(el);
  });
  const copyToClipboard = () => {
    copy(value);
    console.log(value);
    Notification($_('Copied'));
  };
</script>

<div class="uk-flex uk-flex-middle">
  <div class="uk-width-expand">
    <label
      uk-tooltip={tooltipPosition && tooltip
        ? `title: ${tooltip}; pos: ${tooltipPosition}`
        : tooltip}
    >
      {#if !hidden}
        {label}
      {/if}
      {#if label}
        <br />
      {/if}
      <input
        {min}
        {max}
        {step}
        bind:this={el}
        {id}
        {placeholder}
        class:uk-form-small={small}
        class:uk-form-width-medium={medium}
        class="uk-input"
        {required}
        {hidden}
        {readonly}
        on:change={evt => {
          onchange(evt);
        }}
        bind:value
        class:uk-margin-small={margin === 'small'}
        class:uk-margin-medium={margin === 'medium'}
        class:uk-margin-large={margin === 'large'}
      />
    </label>
  </div>
  {#if copiable}
    <div class="uk-margin-small-left">
      <Button style="link" onclick={copyToClipboard}>
        <i class="fa-duotone fa-copy fa-lg" />
      </Button>
    </div>
  {/if}
  {#if withUnit}
    <div class="uk-margin-small-left">
      {withUnit}
    </div>
  {/if}
</div>
