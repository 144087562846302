<script>
  import Cookies from 'js-cookie';
  import { onMount } from 'svelte';
  import {
    gumballTarget,
    dashboardGumballTarget,
    gumballTargetType,
    RoomPermissions,
    ConstructionMode,
    SchoolRole,
    Room,
  } from '../../lib/store';

  import { _ } from 'svelte-i18n';
  import { roomElementsStore } from '../../state/stores/roomElementsStore';
  export let position = { x: 0, y: 0, x: 0 };
  $: roomPermissions =
    $Room.permissions.find(r => r.id == $SchoolRole.roleID)
      ?.roomPermissionPresets ||
    $Room.permissions.find(r => r.category == 'guest')?.roomPermissionPresets;
  let target = null;
  let canBeMovedToDashboard = true;
  let hasLinkURL = false;
  if (position == 'room') {
    gumballTarget.subscribe(el => {
      if (!el) return;
      target = el;
      let item = roomElementsStore.list().find(e => e.uid == el.id);
      if (item?.linkURL) {
        hasLinkURL = true;
      } else {
        hasLinkURL = false;
      }
    });
  } else {
    dashboardGumballTarget.subscribe(el => {
      if (!el) return;
      target = el;
      let item = roomElementsStore.list().find(e => e.uid == el.id);
      if (item?.linkURL) {
        hasLinkURL = true;
      } else {
        hasLinkURL = false;
      }
    });
  }
  let playbackVisible = true;
  let canCopy = true;
  let canDelete = true;
  let canDownload = true;
  let canChangeDashboardScale = false;
  let isPDF = false;
  let canHaveLinkURL = false;
  gumballTargetType.subscribe(val => {
    if (val == 'image') {
      canHaveLinkURL = true;
    }
    if (val == '360image' || val == '360video' || val == 'transportPoint') {
      canBeMovedToDashboard = false;
    } else {
      canBeMovedToDashboard = true;
    }

    if (val == 'audio' || val == 'video' || val == '360video') {
      playbackVisible = true;
    } else {
      playbackVisible = false;
    }
    if (val == 'gltf-model') {
      canChangeDashboardScale = true;
    } else {
      canChangeDashboardScale = false;
    }
    if (val == 'captureScreen') {
      canCopy = false;
      canDelete = false;
      canDownload = false;
      canBeMovedToDashboard = true;
      return;
    } else {
      canCopy = true;
      canDelete = true;
    }
    if (roomPermissions) {
      if (val == 'html' || !roomPermissions?.items?.canDownload) {
        canDownload = false;
      } else {
        canDownload = true;
      }
    } else {
      canDownload = false;
    }
  });
</script>

<a-entity position="0 0.3 0.4" id="gumballMenuHTML" look-at="#myCamera">
  <a-entity position="0 0 0">
    <a-circle
      position="0 0 0"
      radius="0.05"
      width="150"
      material="flatShading:true"
      class="rayTarget"
      on-click="action:resetFocus"
      src="/images/icons/done.png"
    />
  </a-entity>
  {#if $ConstructionMode}
    <a-entity position="0 -0.12 0">
      {#if canCopy && roomPermissions?.items?.canCreate}
        <a-circle
          position="-0.18 0 0"
          radius="0.05"
          width="150"
          material="flatShading:true"
          class="rayTarget"
          on-click="action:copy;param:{position}"
          src="/images/icons/copy{Cookies.get('locale') == 'ja'
            ? 'Ja'
            : 'En'}.png"
        />
      {:else}
        <a-circle
          position="-0.18 0 0"
          radius="0.05"
          width="150"
          material="flatShading:true;opacity:0.5"
          src="/images/icons/copy{Cookies.get('locale') == 'ja'
            ? 'Ja'
            : 'En'}.png"
        />
      {/if}
      {#if canDelete && roomPermissions?.items?.canDelete}
        <a-circle
          position="-0.06 0 0"
          radius="0.05"
          width="150"
          material="flatShading:true"
          class="rayTarget"
          on-click="action:remove;param:{position}"
          src="/images/icons/delete{Cookies.get('locale') == 'ja'
            ? 'Ja'
            : 'En'}.png"
        />
      {:else}
        <a-circle
          position="-0.06 0 0"
          radius="0.05"
          width="150"
          material="flatShading:true;opacity:0.5"
          src="/images/icons/delete{Cookies.get('locale') == 'ja'
            ? 'Ja'
            : 'En'}.png"
        />
      {/if}
      {#if position == 'room' && roomPermissions?.items.canMove}
        <a-circle
          position="0.06 0 0"
          radius="0.05"
          width="150"
          material="flatShading:true"
          class="rayTarget"
          on-click="action:reset;"
          src="/images/icons/reset{Cookies.get('locale') == 'ja'
            ? 'Ja'
            : 'En'}.png"
        />
        <a-circle
          position="0.18 0 0"
          radius="0.05"
          width="150"
          material="flatShading:true"
          class="rayTarget"
          on-click="action:fall;"
          src="/images/icons/fall{Cookies.get('locale') == 'ja'
            ? 'Ja'
            : 'En'}.png"
        />
      {:else}
        <a-circle
          position="0.06 0 0"
          radius="0.05"
          width="150"
          material="flatShading:true;opacity:0.5"
          src="/images/icons/reset{Cookies.get('locale') == 'ja'
            ? 'Ja'
            : 'En'}.png"
        />
        <a-circle
          position="0.18 0 0"
          radius="0.05"
          width="150"
          material="flatShading:true;opacity:0.5"
          src="/images/icons/fall{Cookies.get('locale') == 'ja'
            ? 'Ja'
            : 'En'}.png"
        />
      {/if}
      {#if canHaveLinkURL}
        <a-circle
          position="0 -0.12 0"
          radius="0.05"
          width="150"
          material="flatShading:true;"
          src="/images/icons/editLinkURL{Cookies.get('locale') == 'ja'
            ? 'Ja'
            : 'En'}.png"
          class="rayTarget"
          on-click="action:editLinkURL;param:{position}"
        />
      {/if}
    </a-entity>
  {:else}
    {#if playbackVisible}
      <a-entity position="0 -0.12 0">
        <a-circle
          position="-0.36 0 0"
          radius="0.05"
          width="150"
          material="flatShading:true"
          class="rayTarget"
          on-click="action:playFromBeginning;param:{position}"
          src="/images/icons/playFromBeginning.png"
        />
        <a-circle
          position="-0.24 0 0"
          radius="0.05"
          width="150"
          material="flatShading:true"
          class="rayTarget"
          on-click="action:rewindVideo;param:{position}"
          src="/images/icons/rewind.png"
        />
        <a-circle
          position="-0.12 0 0"
          radius="0.05"
          width="150"
          material="flatShading:true"
          class="rayTarget"
          on-click="action:toggleVideo;param:{position}"
          src="/images/icons/playPause.png"
        />
        <a-circle
          position="0 0 0"
          radius="0.05"
          width="150"
          material="flatShading:true"
          class="rayTarget"
          on-click="action:fastForwardVideo;param:{position}"
          src="/images/icons/fastForward.png"
        />
        <a-circle
          position="0.12 0 0"
          radius="0.05"
          width="150"
          material="flatShading:true"
          class="rayTarget"
          on-click="action:muteVideo;param:{position}"
          src="/images/icons/volumeNone.png"
        />
        <a-circle
          position="0.24 0 0"
          radius="0.05"
          width="150"
          material="flatShading:true"
          class="rayTarget"
          on-click="action:turnVolumeDown;param:{position}"
          src="/images/icons/volumeLow.png"
        />
        <a-circle
          position="0.36 0 0"
          radius="0.05"
          width="150"
          material="flatShading:true"
          class="rayTarget"
          on-click="action:turnVolumeUp;param:{position}"
          src="/images/icons/volumeHigh.png"
        />
      </a-entity>
    {/if}
    <a-entity position="0 {playbackVisible ? -0.24 : -0.12} 0">
      {#if position == 'room'}
        {#if roomPermissions?.items?.canMoveStorage && canBeMovedToDashboard}
          <a-circle
            position="{canDownload ? -0.06 : 0} 0 0"
            radius="0.05"
            width="150"
            material="flatShading:true"
            class="rayTarget"
            on-click="action:toDashboard;"
            src="/images/icons/toDashboard{Cookies.get('locale') == 'ja'
              ? 'Ja'
              : 'En'}.png"
          />
        {:else}
          <a-circle
            position="{canDownload ? -0.06 : 0} 0 0"
            radius="0.05"
            width="150"
            material="flatShading:true;opacity:0.5"
            src="/images/icons/toDashboard{Cookies.get('locale') == 'ja'
              ? 'Ja'
              : 'En'}.png"
          />
        {/if}
        {#if hasLinkURL}
          <a-circle
            position="0 -0.12 0"
            radius="0.05"
            width="150"
            material="flatShading:true"
            class="rayTarget"
            on-click="action:openLinkURL;param:{position}"
            src="/images/icons/openLinkURL{Cookies.get('locale') == 'ja'
              ? 'Ja'
              : 'En'}.png"
          />
        {/if}
      {:else if true == true}
        {#if roomPermissions?.items?.canMoveStorage}
          <a-circle
            position="{canDownload ? -0.06 : 0} 0 0"
            radius="0.05"
            width="150"
            material="flatShading:true"
            class="rayTarget"
            on-click="action:toRoom;"
            src="/images/icons/toRoom{Cookies.get('locale') == 'ja'
              ? 'Ja'
              : 'En'}.png"
          />
        {:else}
          <a-circle
            position="{canDownload ? -0.06 : 0} 0 0"
            radius="0.05"
            width="150"
            material="flatShading:true;opacity:0.5"
            src="/images/icons/toRoom{Cookies.get('locale') == 'ja'
              ? 'Ja'
              : 'En'}.png"
          />
        {/if}
      {/if}
      {#if canDownload}
        <a-circle
          position="0.06 0 0"
          radius="0.05"
          width="150"
          material="flatShading:true"
          class="rayTarget"
          on-click="action:download;param:{position}"
          src="/images/icons/download{Cookies.get('locale') == 'ja'
            ? 'Ja'
            : 'En'}.png"
        />
      {/if}
      {#if hasLinkURL}
        <a-circle
          position="0 -0.12 0"
          radius="0.05"
          width="150"
          material="flatShading:true"
          class="rayTarget"
          on-click="action:openLinkURL;param:{position}"
          src="/images/icons/openLinkURL{Cookies.get('locale') == 'ja'
            ? 'Ja'
            : 'En'}.png"
        />
      {/if}
    </a-entity>
  {/if}
  {#if canChangeDashboardScale && position != 'room' && roomPermissions?.items?.canMove}
    <a-entity position="0 -0.24 0">
      <a-circle
        position="-0.18 0 0"
        radius="0.05"
        width="150"
        material="flatShading:true"
        class="rayTarget"
        on-click="action:scaleDown;"
        src="/images/icons/shrink.png"
      />
      <a-circle
        position="-0.06 0 0"
        radius="0.05"
        width="150"
        material="flatShading:true"
        class="rayTarget"
        on-click="action:scaleUp;"
        src="/images/icons/enlarge.png"
      />
      <a-circle
        position="0.06 0 0"
        radius="0.05"
        width="150"
        material="flatShading:true"
        class="rayTarget"
        on-click="action:moveDown;"
        src="/images/icons/down.png"
      />
      <a-circle
        position="0.18 0 0"
        radius="0.05"
        width="150"
        material="flatShading:true"
        class="rayTarget"
        on-click="action:moveUp;"
        src="/images/icons/up.png"
      />
    </a-entity>
  {/if}
</a-entity>
<a-entity
  htmlEmbed="ppu:500"
  position="0 0.3 0.4"
  id="gumballMenuHTML"
  look-at="#myCamera"
>
  <div style="width:500px;margin:2px;" />
</a-entity>
