<script>
  import { User } from '../../lib/store';
  import Avatars from '../../lib/presets/avatars';

  import Button from '../UI/Button.svelte';
  import { _ } from 'svelte-i18n';
  import { hideModal } from '../../actions/modalController';

  export let selectedURL;
  let avatars = [];
  const chooseThis = id => {
    let one = [...Avatars, ...($User.avatars || [])].find(
      avatar => avatar.id == id,
    );

    selectedURL(one.url);
    hideModal('#preset-avatars');
  };
</script>

<ul uk-tab>
  <li>
    <a href={'#'}>
      {$_('PresetAvatars')}
    </a>
  </li>
  <li>
    <a href={'#'}>
      {$_('YourAvatars')}
    </a>
  </li>
</ul>
<ul class="uk-switcher">
  <li>
    <div uk-grid class="uk-grid-small">
      {#each Avatars as avatar}
        <div style="width:180px;">
          <img
            src={avatar.thumbnail}
            style="border-radius:50%;overflow:hidden"
            alt=""
          />
          <Button
            fullwidth
            onclick={() => {
              chooseThis(avatar.id);
            }}>{$_('enterRoom.ChooseThis')}</Button
          >
        </div>
      {/each}
    </div>
  </li>
  <li>
    <div uk-grid class="uk-grid-small">
      {#each $User?.avatars || [] as avatar}
        <div style="width:180px;">
          <img
            src={avatar.thumbnail}
            style="border-radius:50%;overflow:hidden"
            alt=""
          />
          <Button
            fullwidth
            onclick={() => {
              chooseThis(avatar.id);
            }}>{$_('enterRoom.ChooseThis')}</Button
          >
        </div>
      {/each}
    </div>
  </li>
</ul>
