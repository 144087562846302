import appendToDashboard from '../lib/appendToDanshboard.js';
import { SchoolRole } from '../lib/store';
import { updateItem } from './itemController.js';
import { io, ioGet } from '../lib/realtime';
let schoolRole;
SchoolRole.subscribe(obj => {
  schoolRole = obj;
});
export const getScreenData = async newVideoEl => {
  let res = await ioGet('getItemDetail', { uid: newVideoEl.id });
  console.log('got screenData', res);
  if (res) {
    if (res.storedAt == 'room') {
      newVideoEl.setAttribute('position', res.position);
      newVideoEl.setAttribute('rotation', res.rotation);
      newVideoEl.setAttribute('scale', res.scale);
    } else if (res.storedAt == 'dashboard') {
      moveScreenCaptureToDashboard(newVideoEl);
    }
  }
  return res;
};
const appendScreenCapture = async (track, identity) => {
  console.log(track);

  let assetId = 'screenOf_' + identity + track.name;
  if (document.getElementById(assetId)) {
    console.log(
      'screenOf asset alreaedy exists',
      document.getElementById(assetId),
    );
    var newVideo = document.getElementById(assetId);
  } else {
    var newVideo = track.attach();
    newVideo.id = assetId;
  }

  var elementId = 'screenOf_' + identity;
  if (document.getElementById(elementId + '_entity')) {
    console.log('screenOf element alreaedy exists');
    var newVideoEl = document.getElementById(elementId + '_entity');
  } else {
    var newVideoEl = document.createElement('a-video');
    newVideoEl.id = elementId + '_entity';
  }
  newVideo.setAttribute('name', elementId);
  newVideo.setAttribute('fileType', 'captureScreen');
  return new Promise(function (resolve) {
    track.once('started', async () => {
      console.log('capture screen started', elementId);
      document.querySelector('a-assets').appendChild(newVideo);
      document.getElementById('scene').appendChild(newVideoEl);
      let aspectRatio = track.dimensions.height / track.dimensions.width;
      console.log(track.dimensions);
      newVideoEl.setAttribute('width', 2);
      newVideoEl.setAttribute('height', 2 * aspectRatio);
      newVideoEl.setAttribute('src', '#' + assetId);
      newVideoEl.classList.add('rayTarget');

      newVideoEl.setAttribute('sourceType', 'captureScreen');
      let data = await getScreenData(newVideoEl);
      console.log({ data });
      if (data.storedAt == 'dashboard') {
        newVideoEl.setAttribute('show-dashboard-gumball', {
          enabled: true,
          sourceType: 'captureScreen',
        });
      } else {
        newVideoEl.setAttribute('show-gumball', {
          enabled: true,
          sourceType: 'captureScreen',
        });
      }
      //newVideoEl.setAttribute("update-repeatedly", { enabled: true });
      resolve(newVideoEl);
    });
  });
};
const moveScreenCaptureToDashboard = async el => {
  console.log('mocing screencapture to dashboard');
  if (document.getElementById('offsetter_' + el.id)) {
    return;
  }
  let newVideoEl = document.createElement('a-video');
  newVideoEl.id = el.id;
  newVideoEl.setAttribute('src', el.getAttribute('src'));
  newVideoEl.setAttribute(
    'width',
    el.getAttribute('width') / el.getAttribute('height'),
  );
  newVideoEl.setAttribute(
    'height',
    el.getAttribute('height') / el.getAttribute('height'),
  );
  newVideoEl.setAttribute('sourceType', 'captureScreen');
  newVideoEl.classList.add('rayTarget');
  io.emit('updateItem', {
    uid: el.id,
    storedAt: 'dashboard',
  });

  newVideoEl.setAttribute('show-dashboard-gumball', {
    enabled: true,
    sourceType: 'captureScreen',
  });

  appendToDashboard(newVideoEl);
  el.parentNode.removeChild(el);
};
const moveScreenCaptureToRoom = el => {
  let offsetterEl = document.getElementById('offsetter_' + el.id);
  let newVideoEl = document.createElement('a-video');

  newVideoEl.id = el.id;
  newVideoEl.setAttribute('src', el.getAttribute('src'));
  newVideoEl.setAttribute('width', el.getAttribute('width'));
  newVideoEl.classList.add('rayTarget');

  newVideoEl.setAttribute('height', el.getAttribute('height'));
  let position = document
    .getElementById('myCamera')
    .object3D.localToWorld(new THREE.Vector3(0, 0, -2));
  let rotation = document
    .getElementById('look-rigger')
    .getAttribute('rotation');
  newVideoEl.setAttribute('show-gumball', {
    enabled: true,
    sourceType: 'captureScreen',
  });
  newVideoEl.setAttribute('sourceType', 'captureScreen');
  offsetterEl.parentNode.removeChild(offsetterEl);
  document.getElementById('scene').appendChild(newVideoEl);
  if (el.id.indexOf(schoolRole.id) > -1) {
    //this is mine
    newVideoEl.setAttribute('position', position);
    newVideoEl.setAttribute('rotation', rotation);
    io.emit('updateItem', {
      uid: el.id,
      storedAt: 'room',
      position: position,
      rotation: rotation,
    });
  } else {
    setTimeout(function () {
      //updateItem(newVideoEl);
      getScreenData(newVideoEl);
    }, 3000);
  }
};
export {
  moveScreenCaptureToDashboard,
  moveScreenCaptureToRoom,
  appendScreenCapture,
};
