<script lang="ts">
  import { LoadingMessage } from '../../lib/store';
  import Button from '../UI/Button.svelte';
  import { _ } from 'svelte-i18n';
</script>

<div
  uk-modal="esc-close:false;bg-close:false;stack:true;"
  class="uk-open uk-modal-full loading-spinner uk-light"
  id="loading-spinner"
>
  <div class="uk-position-center uk-text-center">
    <div uk-spinner="ratio:3" />
    <div>
      <Button small id="uploadAbortButton" hidden={true}>{$_('Abort')}</Button>
    </div>
  </div>
  <div class="uk-position-center">
    {$LoadingMessage}
  </div>
</div>

<style>
  .loading-spinner {
    width: 100vw;
    height: 100vh;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
  }
</style>
