const appendAvatarAsset = (schoolRoleID, avatarURL) => {
  let id = 'avatarFile_' + schoolRoleID;
  if (document.getElementById(id)) {
    document
      .getElementById(id)
      .parentNode.removeChild(document.getElementById(id));
  }
  let gltfAsset = document.createElement('a-asset-item');
  gltfAsset.id = id;
  gltfAsset.setAttribute('src', avatarURL);
  document.getElementsByTagName('a-assets')[0].appendChild(gltfAsset);
};
export default appendAvatarAsset;
