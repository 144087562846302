<script>
  import { onMount } from 'svelte';
  import { _ } from 'svelte-i18n';
  import { navigate, Route, Router } from 'svelte-routing';
  import dialog from '../lib/helpers/dialog';
  import setSchoolRole from '../lib/helpers/setSchoolRole';
  import validateSlug from '../lib/helpers/validateSlug';
  import Environments from '../lib/presets/environments';
  import { ioGet } from '../lib/realtime';
  import { Room, School, SchoolRole, User } from '../lib/store';
  import { UUID } from '../lib/uuid';
  import RoomSettingBlock from './blocks/RoomSettingBlock.svelte';
  import Console from './Console.svelte';
  import Button from './UI/Button.svelte';
  import Lead from './UI/Lead.svelte';
  export let schoolID;
  let school = null;
  let user = null;
  let schoolRole = null;
  let rooms = [];
  let roomTitleInput = '';
  let description;
  let room = {
    environment: Environments[1],
    environmentID: Environments[1].id,
    backgroundType: 'sun',
    timeZone: '-0900',
  };
  School.subscribe(obj => {
    if (obj) {
      if (typeof obj.features == 'undefined') {
        obj.features = {};
        School.set(obj);
        return;
      }
      school = obj;
      room.permissions = school.roles;
      room.schoolID = school._id;
      room.slug = UUID().replace(/-/g, '');
    }
  });
  User.subscribe(obj => {
    user = obj;
  });
  SchoolRole.subscribe(obj => {
    schoolRole = obj;
  });
  const createRoom = async () => {
    let resSlug = await validateSlug(room.slug);
    if (resSlug.status != 'success') return;
    console.log(room);
    if (!room.title) {
      dialog($_('roomSetting.EmptyRoomTitle'));
      return;
    }
    let data = await ioGet('createRoom', {
      data: room,
    });

    if (data) {
      Room.set(data);
      setTimeout(() => {
        createWhiteBoardAndDocumentURLForRoom();
      }, 500);
      navigate('/' + school.slug + '/' + room.slug + '/enter-room');
    } else {
      alert('failed');
    }
  };
  onMount(async () => {
    console.log('checking school ID', schoolID);
    Room.set(null);
    let schoolRes = await ioGet('getSchool', {
      _id: schoolID,
    });
    if (schoolRes) {
      console.log(schoolRes);
      School.set(schoolRes);
      await setSchoolRole();
      let roomsRes = await ioGet('findRooms', {
        query: {
          schoolID: schoolRes._id,
        },
      });
      console.log(roomsRes);
      if (roomsRes.length) {
        rooms = roomsRes;
      }
    } else {
      alert('wrong URL3');
    }
  });
</script>

{#if school}
  <Router>
    <Route path="create-room">
      <div class="uk-container">
        <ul class="uk-breadcrumb">
          <li>
            <a href={'#'} on:click={() => navigate('/' + school.slug + '/#')}>
              {school?.title}
            </a>
          </li>
          <li>
            <a
              href={'#'}
              on:click={() => navigate('/' + school.slug + '/select-room')}
            >
              {$_('enterRoom.Rooms')}
            </a>
          </li>
        </ul>
        <Lead>{$_('CreateNewClassroom')}</Lead>

        <div>
          <RoomSettingBlock forNew={true} bind:room />

          <Button fullwidth onclick={createRoom}>{$_('Create')}</Button>
        </div>
      </div>
    </Route>
    <Route path="/*">
      <Console />
    </Route>
  </Router>
{/if}
