import { ioGet } from '../realtime';
import { School } from '../store';
import { UUID } from '../uuid';
import { _ } from 'svelte-i18n';
import { get } from 'svelte/store';
import dialog from './dialog';
let school = null;
School.subscribe(obj => {
  school = obj;
});
const createCategory = async (entity, categoryType) => {
  if (!entity.categoryLabel) {
    dialog(get(_)('article.PleaseGiveItAName'));
    return;
  }
  if (
    school[categoryType + 'Categories'].some(
      category =>
        category[categoryType + 'Presets'].categoryLabel ==
        entity.categoryLabel,
    )
  ) {
    dialog(get(_)('article.DuplicateCategoryTitle'));
    return;
  }
  let NewCategory = {
    id: UUID(),
    title: entity.categoryLabel,
    [categoryType + 'Presets']: entity,
  };
  if (typeof school[categoryType + 'Categories'] == 'undefined')
    school[categoryType + 'Categories'] = [];
  school[categoryType + 'Categories'].push(NewCategory);
  await ioGet('updateSchool', {
    _id: school._id,
    data: {
      [categoryType + 'Categories']: school[categoryType + 'Categories'],
    },
  });
  School.set(school);
  dialog(get(_)('Saved'));
};
export default createCategory;
