import { DateTime } from 'luxon';
import { customFieldTypes } from '../presets/customFieldTypes';
import copy from './copy';
import { defineIfUndefined } from './defineIfUndefined';
import { isInvalidDate } from './isInvalidDateTime';
export const calculate = (
  field,
  fields,
  value,
  values,
  parentFields = [],
  parentValues = {},
) => {
  if (!field) return [{ type: '' }, { value: '' }];
  switch (field.type) {
    case 'select':
      console.log('calculating select', value, field);
      value = defineIfUndefined(value, { value: '' });
      if (typeof value == 'string') {
        value = { value: value };
      }
      if (!value.value) {
        value.value = field.options.values[0];
      }
      console.log(value);
      break;
    case 'conditionalColorRing':
      let colorRing = {
        ringColor: calculate(
          fields.find(f => f.id == field.options.ringColorTarget),
          fields,
          values[field.options.ringColorTarget],
          values,
          parentFields,
          parentValues,
        )[1],
        showAboveTheRing: field.options.showAboveTheRing,
        aboveTheRing: calculate(
          fields.find(f => f.id == field.options.aboveTheRingTarget),
          fields,
          values[field.options.aboveTheRingTarget],
          values,
          parentFields,
          parentValues,
        ),
        insideTheRing: calculate(
          fields.find(f => f.id == field.options.insideTheRingTarget),
          fields,
          values[field.options.insideTheRingTarget],
          values,
          parentFields,
          parentValues,
        ),
        insideTheRingSize: field.options.insideTheRingSize,
        showBelowTheRing: field.options.showBelowTheRing,
        belowTheRing: calculate(
          fields.find(f => f.id == field.options.belowTheRingTarget),
          fields,
          values[field.options.belowTheRingTarget],
          values,
          parentFields,
          parentValues,
        ),
      };
      value = colorRing;
      field = field;
      break;
    case 'autoCalculation':
      var source = fields.find(f => f.id == field.options.target);
      var list = calculate(
        source,
        fields,
        copy(values[source.id]),
        values,
        parentFields,
        parentValues,
      );
      value = list[1];
      if (!value) {
        field = list[0];

        break;
      }
      if (
        list[0].type == 'date' ||
        list[0].type == 'time' ||
        list[0].type == 'endOfMonth' ||
        list[0].type == 'datetime-local'
      ) {
        let date = DateTime.fromISO(value.value);
        if (field.options.calculation == 'plus') {
          date = date.plus({
            [field.options.unit]: parseInt(field.options.value),
          });
        }
        if (field.options.calculation == 'minus') {
          date = date.minus({
            [field.options.unit]: parseInt(field.options.value),
          });
        }
        if (list[0].type == 'date' || source.type == 'endOfMonth') {
          value.value = date.toISODate();
        }
        if (list[0].type == 'time') {
          value.value = date.toISOTime();
        }
        if (list[0].type == 'datetime-local') {
          value.value = date.toISO();
        }
      }
      if (
        list[0].type == 'number' ||
        list[0].type == 'googleFormResponseScore'
      ) {
        if (field.options.calculation == 'plus') {
          value.value =
            parseFloat(value.value) + parseFloat(field.options.value);
        }
        if (field.options.calculation == 'minus') {
          value.value =
            parseFloat(value.value) - parseFloat(field.options.value);
        }
        if (field.options.calculation == 'multiply') {
          value.value =
            parseFloat(value.value) * parseFloat(field.options.value);
        }
      }
      field = list[0];

      break;
    case 'conditionalTime':
      let match = false;
      field.options.rows.forEach((row, i) => {
        if (match) return;
        if (i == field.options.rows.length - 1) {
          //last one is a match.
          match = row;
          return;
        }
        let now;
        let compareTo;
        let source = fields.find(f => f.id == row.compareTo);
        var list = calculate(
          source,
          fields,
          copy(values[source.id]),
          values,
          parentFields,
          parentValues,
        );
        source = list[0];
        let sourceValue = list[1];
        if (!source) return;
        sourceValue = defineIfUndefined(sourceValue, { value: '' });
        if (source.type == 'date' || source.type == 'endOfMonth') {
          now = DateTime.now().toISODate();
          compareTo = DateTime.fromISO(sourceValue.value).toISODate();
        } else if (source.type == 'time') {
          now = DateTime.now().toISOTime();
          compareTo = DateTime.fromISO(sourceValue.value).toISOTime();
        } else if (source.type == 'datetime-local') {
          now = DateTime.now().toISO();
          compareTo = DateTime.fromISO(sourceValue.value).toISO();
        }
        if (row.beforeOrAfter == 'isBefore') {
          match = now < compareTo;
        }
        if (row.beforeOrAfter == 'sameAs') {
          match = now == compareTo;
        }
        if (row.beforeOrAfter == 'isAfter') {
          match = now > compareTo;
        }
        if (match) {
          match = row;
          return;
          //then
        }
      });

      if (!match) return [{ type: '' }, { value: '' }];
      if (
        customFieldTypes.find(f => f.slug == field.options.type).isPrimitive
      ) {
        console.log('conditional time primitive');
        //end format
        field = {
          type: field.options.type,
        };
        value = match.value;
      } else {
        console.log(
          'conditional time not primitive',
          fields.find(f => f.id == match.target),
          { value: match.value },
          match,
        );

        var [f, v] = calculate(
          fields.find(f => f.id == match.target),
          fields,
          values[match.target],
          values,
          parentFields,
          parentValues,
        );
        field = f;
        value = v;
      }
      break;
    case 'conditionalValue':
      var source = fields.find(f => f.id == field.options.target);
      if (!source) return [{ type: '' }, { value: '' }];
      var row;
      switch (source.type) {
        case 'select':
          values[field.options.target] = defineIfUndefined(
            values[field.options.target],
            { value: '' },
          );
          let [targetField, targetValue] = calculate(
            fields.find(f => f.id == field.options.target),
            fields,
            values[field.options.target],
            values,
            parentFields,
            parentValues,
          );
          row = field.options.rows.find(row => {
            return row.equal == targetValue.value;
          });
          break;
        case 'checkbox':
          row = field.options.rows.find(row => {
            values[field.options.target] = defineIfUndefined(
              values[field.options.target],
              { value: false },
            );
            if (
              row.equal === true &&
              values[field.options.target].value === true
            ) {
              return true;
            }
            if (
              row.equal === false &&
              values[field.options.target].value === false
            ) {
              return true;
            }
            return false;
          });
          break;
        case 'number':
        case 'googleFormResponseScore':
          row = field.options.rows.find(row => {
            return (
              parseFloat(row.min) <=
                parseFloat(values[field.options.target]?.value || 0) &&
              parseFloat(values[field.options.target]?.value || 0) <
                parseFloat(row.max)
            );
          });
          break;
        case 'conditionalValue':
          row = field.oprions.rows(row => {
            let target = fields.find(f => f.id == row.target);
            if (target.type == 'number') {
            }
          });
          break;
      }
      if (!row) return [{ type: '' }, { value: '' }];

      if (
        customFieldTypes.find(f => f.slug == field.options.type).isPrimitive
      ) {
        //end format
        field = {
          type: field.options.type,
        };
        value = row.value;
      } else {
        var list = calculate(
          {
            type: field.options.type,
            options: {
              target: row.target,
            },
          },
          fields,
          copy(values[row.target]),
          values,
          parentFields,
          parentValues,
        );
        field = list[0];
        value = list[1];
      }
      break;
    case 'inherit':
      if (parentFields.length) {
        if (!parentFields.find(f => f.id == field.options.target))
          return [{ type: '' }, { value: '' }];
        if (
          customFieldTypes.find(
            f =>
              f.slug ==
              parentFields.find(f => f.id == field.options.target).type,
          ).isPrimitive
        ) {
          //end format
          value = parentValues[field.options.target];
          field = parentFields.find(f => f.id == field.options.target);
        } else {
          var list = calculate(
            parentFields.find(f => f.id == field.options.target),
            parentFields,
            copy(value),
            parentFields,
            parentValues,
          );
          field = list[0];
          value = list[1];
        }
      }
      break;
    case 'reference':
      console.log(
        'calculating reference',
        fields.find(f => f.id == field.options.target),
        copy(values[field.options.target]),
      );
      var list = calculate(
        fields.find(f => f.id == field.options.target),
        fields,
        copy(values[field.options.target]),
        values,
        parentFields,
        parentValues,
      );
      field = list[0];
      value = list[1];
      break;
  }
  return [field, value];
};

export const calculateSummaryFieldValue = (
  summaryField,
  sourceCustomFields,
  sourceParentCustomFields,
  sourceCustomFieldValues,
  results,
) => {
  let value = {
    value: 0,
  };
  if (summaryField.calculation == 'sum') {
    results.forEach((res, i) => {
      let [field, val] = calculate(
        sourceCustomFields.find(f => f.id == summaryField.target),
        sourceCustomFields,
        res.customFieldValues[summaryField.target],
        res.customFieldValues,
        sourceParentCustomFields,
        Array.isArray(sourceCustomFieldValues)
          ? sourceCustomFieldValues[i]
          : sourceCustomFieldValues,
      );
      field = defineIfUndefined(field, { type: '' });
      val = defineIfUndefined(val, { value: 0 });
      if (parseFloat(val.value) > 0) {
        value.value = value.value + parseFloat(val.value);
      }
    });
  }
  if (summaryField.calculation == 'count') {
    results.forEach(res => {
      value.value++;
    });
  }
  if (
    summaryField.calculation == 'countIf1' ||
    summaryField.calculation == 'countAndSubtract'
  ) {
    results.forEach(res => {
      let [field, val] = calculate(
        sourceCustomFields.find(f => f.id == summaryField.target),
        sourceCustomFields,
        res.customFieldValues[summaryField.target],
        res.customFieldValues,
        sourceParentCustomFields,
        sourceCustomFieldValues,
      );
      if (parseFloat(val.value)) {
        value.value++;
      }
    });

    if (summaryField.calculation == 'countAndSubtract') {
      let sub = 0;
      results.forEach(res => {
        let [field, val] = calculate(
          sourceCustomFields.find(f => f.id == summaryField.subtractTarget),
          sourceCustomFields,
          {},
          res.customFieldValues,
          sourceParentCustomFields,
          sourceCustomFieldValues,
        );
        if (parseFloat(val.value)) {
          sub++;
        }
      });
      sub = Math.floor(sub / summaryField.subtractEveryXOccasions);
      value.value = value.value - sub;
    }
  }
  return value;
};
