<script lang="ts">
  import FileBrowser from './blocks/FileBrowser.svelte';
  import { School } from '../lib/store';
  import { _ } from 'svelte-i18n';
  import LinkToDocumentation from './blocks/LinkToDocumentation.svelte';
</script>

<svelte:head>
  <title>{$_('Files')} | {$School?.title} - AVOS</title>
</svelte:head>
{#if $School}
  <FileBrowser />
{/if}
