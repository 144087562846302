import { ringRadius } from '../lib/defaultValues.js';
import { gumballTarget, mousePos, SchoolRole } from '../lib/store.js';
import { radian2degree } from '../lib/helpers/math';

let y = 0;
let thisPos;
mousePos.subscribe(pos => {
  thisPos = pos;
});
const unfocus = me => {
  let rig = document.getElementById('look-rigger');
  //console.log(evt.detail)
  me.mouseDown = false;
};
let schoolRole;
SchoolRole.subscribe(obj => {
  schoolRole = obj;
});
AFRAME.registerComponent('float-around', {
  schema: {
    enabled: { type: 'boolean', default: true },
    targetEl: { type: 'selector' },
  },
  init: function () {
    this.mouseDown = false;
    this.mouseDownPos = 0;
    this.el.addEventListener('mousedown', evt => {
      this.rig = document.getElementById(schoolRole.id);
      this.dashboard = document.getElementById('myDashboard');
      //console.log(evt)
      this.clickEl = evt.target;
      if (this.data.enabled) {
        let rig = document.getElementById('look-rigger');
        this.origRotation = this.el.object3D.rotation.y;
        this.origPosition = this.el.object3D.position.y;
        //console.log(evt.detail)

        let point = scene.components.raycaster.getIntersection(
          this.clickEl,
        )?.point;

        let vector1 = new THREE.Vector3(point.x, point.y, point.z);

        let vector2 = new THREE.Vector3(
          this.rig.object3D.position.x,
          this.rig.object3D.position.y,
          this.rig.object3D.position.z,
        );
        if (evt.target.getAttribute('sourceType') == 'html') {
          unfocus(this);
          return;
          //          point.y = evt.target.parent
        }
        this.mouseDownPosCanvas = { x: thisPos.x, y: thisPos.y };
        //console.log(scene.components.raycaster.getIntersection(),point)
        this.mouseDownPos = {
          x: point.x,
          y: point.y,
          z: point.z,
          v: vector1.sub(vector2),
        };
        this.mouseDown = true;
      }
    });

    this.el.addEventListener('mouseleave', evt => {
      unfocus(this);
    });
    this.el.addEventListener('mouseup', evt => {
      unfocus(this);
    });
  },

  tick: function () {
    if (this.mouseDown) {
      let mouseNowPos = scene.components.raycaster.getIntersection(
        this.clickEl,
      )?.point;
      let vector1 = new THREE.Vector3(
        mouseNowPos.x,
        mouseNowPos.y,
        mouseNowPos.z,
      );

      let vector2 = new THREE.Vector3(
        this.rig.object3D.position.x,
        this.rig.object3D.position.y,
        this.rig.object3D.position.z,
      );
      mouseNowPos.v = vector1.sub(vector2);

      let Xc = 0;
      let Yc = 0;
      var Xb = this.mouseDownPos.v.x;
      var Yb = this.mouseDownPos.v.z * -1;
      var Xa = mouseNowPos.v.x;
      var Ya = mouseNowPos.v.z * -1;
      var v1x = Xb - Xc;
      var v1y = Yb - Yc;
      var v2x = Xa - Xc;
      var v2y = Ya - Yc;
      var angle = Math.atan2(v1x, v1y) - Math.atan2(v2x, v2y);
      this.dashboard.object3D.rotation.y =
        this.dashboard.object3D.rotation.y + angle;
      //console.log(radian2degree(angle))

      let Ydistance = mouseNowPos.y - this.mouseDownPos.y;
      let Xdistance = mouseNowPos.x - this.mouseDownPos.x;
      let Zdistance = mouseNowPos.z - this.mouseDownPos.z;
      let XZdistance = Math.sqrt(Xdistance * Xdistance + Zdistance * Zdistance);
      let degreeDiff = (360 * XZdistance) / (ringRadius * 2 * 3.14);
      this.mouseNowPosCanvas = { x: thisPos.x, y: thisPos.y };
      if (this.mouseDownPosCanvas.x > this.mouseNowPosCanvas.x) {
        degreeDiff = degreeDiff * -1;
      }

      let newYRotation = this.origRotation - degreeDiff;
      //      this.el.setAttribute('rotation',this.el.getAttribute('rotation').x+" "+newYRotation+" "+this.el.getAttribute('rotation').z)
      //let Xdistance = this.mouseDownPos.x - thisPos.x
      //let Ydistance = this.mouseDownPos.y - thisPos.y
      //this.el.object3D.rotation.y = this.origRotation + Xdistance * 0.003
      this.el.object3D.position.y = this.el.object3D.position.y + Ydistance;
      this.mouseDownPos = mouseNowPos;
    }
  },
});
