<script>
  import Button from '../UI/Button.svelte';
  import Input from '../UI/Input.svelte';
  import { _ } from 'svelte-i18n';
  import Icon from '../UI/Icon.svelte';
  import Div from '../UI/Div.svelte';
  import ArrayNav from './ArrayNav.svelte';
  import arraySwap from '../../lib/helpers/arraySwap';
  import { School, SchoolRole } from '../../lib/store';
  let editMode = false;
  export let summaryField;
  export let save;
  export let summaryFields = [];
  export let sourceCustomFields = [];
  export let i;
  const remove = i => {
    summaryFields.splice(i, 1);
    summaryFields = summaryFields;
    save();
    editMode = false;
  };
  const moveUp = i => {
    summaryFields = arraySwap(summaryFields, i, i - 1);
  };
  const moveDown = i => {
    summaryFields = arraySwap(summaryFields, i, i + 1);
  };
</script>

<div hidden={!editMode} class="uk-width-medium">
  <Button
    style="text"
    onclick={() => {
      save();
      editMode = false;
    }}
  >
    <Icon icon="save" size="large" />
    {$_('Save')}
  </Button>

  <Div>
    <ArrayNav
      sideway
      arrayLength={summaryFields.length}
      {moveUp}
      {moveDown}
      {remove}
      {i}
    />
  </Div>
  <Input bind:value={summaryField.title} label={$_('Title')} />
  <select class="uk-select" bind:value={summaryField.target}>
    {#each sourceCustomFields as field}
      <option value={field.id}>{field.title}</option>
    {/each}
  </select>
  <select class="uk-select" bind:value={summaryField.calculation}>
    <option value="sum">{$_('customFields.Sum')}</option>
    <option value="count">{$_('customFields.Count')}</option>
    <option value="countIf1">{$_('customFields.CountIf1')}</option>
    <option value="countAndSubtract"
      >{$_('customFields.CountAndSubtractBy')}</option
    >
  </select>
  {#if summaryField.calculation == 'countAndSubtract'}
    <Input
      bind:value={summaryField.subtractEveryXOccasions}
      label={$_('customFields.EveryXOccasions')}
      type="number"
      min={0}
      max={100}
      step={1}
    />
    <select class="uk-select" bind:value={summaryField.subtractTarget}>
      {#each sourceCustomFields as field}
        <option value={field.id}>{field.title}</option>
      {/each}
    </select>
  {/if}
</div>
<div hidden={editMode} class="uk-text-nowrap">
  {summaryField.title || ''}
  {#if $School.roles.find(role => role.id == $SchoolRole.roleID).school.canEdit}
    <Button
      style="link"
      onclick={() => {
        editMode = true;
      }}
    >
      <Icon icon="edit" />
    </Button>
  {/if}
</div>
