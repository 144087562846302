<script>
  import { School } from '../../lib/store';

  import Checkbox from '../UI/Checkbox.svelte';
  import { _ } from 'svelte-i18n';
  import { onMount } from 'svelte';
  import Lead from '../UI/Lead.svelte';
  import Switch from '../UI/Switch.svelte';
  import features from '../../lib/interfaces/Features';
  export let entity;
  export let componentCategory = 'entity';
</script>

{#if entity}
  <div uk-margin>
    {#if (componentCategory == 'Event' && $School.features?.usePublicEvents) || (componentCategory == 'Article' && $School.features?.usePublicArticles)}
      <Lead>{$_('event.PublicViewers')}</Lead>
      <div>
        <div uk-grid>
          <div class="uk-width-expand">
            {$_('event.Public')}
          </div>
          <div>
            <Switch bind:value={entity.isPublic} />
          </div>
        </div>
      </div>
    {/if}
    {#if !entity.isPublic}
      <div>
        {$_('event.Roles')}
        <div>
          <Checkbox bind:checked={entity.filterByRole}
            >{$_('event.FilterByRole')}</Checkbox
          >
        </div>
        {#if entity.filterByRole}
          <div class="uk-flex">
            {#each $School.roles.filter(role => role.category != 'guest') as role}
              <div class="uk-margin-small-right">
                <label>
                  <input
                    type="checkbox"
                    class="uk-checkbox"
                    bind:group={entity.filterRoles}
                    value={role.id}
                    name="filterRoles"
                  />
                  {role.title}
                </label><br />
              </div>
            {/each}
          </div>
        {/if}
      </div>
      {@const interval =
        $School.intervals[
          $School.intervals.findIndex(interval => {
            if (!$School.features.useIntervals) return true;
            if (
              interval.startOn <= entity.startDate &&
              interval.endOn >= entity.startDate
            ) {
              return true;
            }
          })
        ]}
      {#if interval}
        {#if $School.features.useInterval}
          {interval.title}
        {/if}
        {#each interval.programCategories || [] as category, i}
          {#if category.useToGroupUser}
            <div uk-margin>
              <div>
                {category.title}
                <div>
                  <label>
                    <input
                      type="checkbox"
                      class="uk-checkbox"
                      bind:group={entity.filterCategories}
                      value={category.id}
                      name="filterCategories"
                    />{$_('event.FilterViewerWith', {
                      values: {
                        categoryName: category.title,
                      },
                    })}
                  </label>
                </div>
              </div>
              {#if entity.filterCategories?.indexOf(category.id) > -1}
                <div class="uk-margin-left">
                  {#each category.groups as group}
                    <div>
                      <label>
                        <input
                          type="checkbox"
                          class="uk-checkbox"
                          value={group.id}
                          bind:group={entity.linkedPrograms}
                          name="linkedProgram"
                          on:change={evt => {
                            console.log(evt);
                            let el = evt.target.parentNode.nextElementSibling;
                            console.log(el);
                            if (el) {
                              let checkboxes =
                                el.querySelectorAll('[type=checkbox]');
                              console.log(checkboxes);
                              checkboxes.forEach(el => {
                                if (evt.target.checked) {
                                  el.disabled = true;
                                  if (
                                    entity.linkedPrograms.indexOf(el.value) ==
                                    -1
                                  ) {
                                    entity.linkedPrograms.push(el.value);
                                  }
                                } else {
                                  el.disabled = false;
                                }
                              });
                              entity.linkedPrograms = entity.linkedPrograms;
                            }
                          }}
                        />
                        {group.title}
                      </label>
                      {#if group.children.length > 0}
                        <div class="uk-margin-left">
                          <ul class="uk-subnav">
                            {#each group.children as child}
                              <li>
                                <label>
                                  <input
                                    type="checkbox"
                                    class="uk-checkbox"
                                    value={child.id}
                                    bind:group={entity.linkedPrograms}
                                    name="linkedProgram"
                                  />
                                  {child.title}
                                </label>
                              </li>
                            {/each}
                          </ul>
                        </div>
                      {/if}
                    </div>
                  {/each}
                </div>
              {/if}
            </div>
          {/if}
        {/each}
      {/if}
    {/if}
  </div>
{/if}
