<script lang="ts">
  import { DateTime } from 'luxon';

  import { onMount } from 'svelte';
  import { _ } from 'svelte-i18n';
  import { ENDPOINT, HOST } from '../../../lib/config';
  import { thumbnailCloudURL } from '../../../lib/defaultValues';
  import areYouSure from '../../../lib/helpers/areYouSure';
  import arraySwap from '../../../lib/helpers/arraySwap';
  import { defineIfUndefined } from '../../../lib/helpers/defineIfUndefined';
  import dialog from '../../../lib/helpers/dialog';
  import { downloadFileAs } from '../../../lib/helpers/downloadFileAs';
  import { getFormScore } from '../../../lib/helpers/getFormScore';
  import Loading from '../../../lib/helpers/loading';
  import { School, SchoolRole, User } from '../../../lib/store';
  import { UUID } from '../../../lib/uuid';
  import { FileStorageClient } from '../../../v2/clients/fileStorageClient';
  import UserFilesUpload from '../../../v2/components/userFiles/UserFilesUpload.svelte';
  import Button from '../../UI/Button.svelte';
  import Checkbox from '../../UI/Checkbox.svelte';
  import ColorPicker from '../../UI/ColorPicker.svelte';
  import Div from '../../UI/Div.svelte';
  import Input from '../../UI/Input.svelte';
  import MetaText from '../../UI/MetaText.svelte';
  import ArrayNav from '../ArrayNav.svelte';
  import TinyTextarea from '../TinyTextarea.svelte';
  import VideoFileUpload from '../VideoFileUpload.svelte';
  import VideoPlayer from '../VideoPlayer.svelte';
  import CustomFieldEditOrDisplay from './CustomFieldEditOrDisplay.svelte';
  import CustomIconField from './CustomIconField.svelte';
  import VideoProgress from './VideoProgress.svelte';
  export let field;
  export let value: any;
  export let parentFields;
  export let parentValues;
  let valueReady = false;
  let videoPlayer;
  let id = UUID().replace(/-/g, '');
  let customButton = false;

  const fileStorageClient = new FileStorageClient();

  onMount(() => {
    value = defineIfUndefined(value, {
      value: '',
    });
    if (typeof value == 'string' || typeof value == 'number') {
      value = {
        value: value,
      };
    }
    value.values = defineIfUndefined(value.values, []);
    if (field.type == 'selectMultiple') {
      value = defineIfUndefined(value, {
        values: [],
      });
    } else {
      if (value == '') {
        value = {
          value: '',
        };
      }
    }
    if (field.type == 'array') {
      value.rows = defineIfUndefined(value.rows, []);
      value.rows = value.rows.map(row => {
        row.customFieldValues = defineIfUndefined(row.customFieldValues, {});
        for (let fieldRow of field.options.rows) {
          row.customFieldValues[fieldRow.id] = defineIfUndefined(
            row.customFieldValues[fieldRow.id],
            {},
          );
        }
        return row;
      });
    }
    valueReady = true;
    if (
      field.type == 'file' &&
      field.upload &&
      field.custom_button_url &&
      field.custom_button_url != ''
    )
      customButton = true;
  });
  let colorPickerVisible = false;

  let endsOfMonth = [];
  let startDate = DateTime.now()
    .minus({
      years: 1,
    })
    .endOf('month');
  for (let i = 0; i < 36; i++) {
    let date = startDate
      .plus({
        month: i,
      })
      .toISO();
    endsOfMonth.push(date);
  }
  const moveUp = (i: number) => {
    value.rows = arraySwap(value.rows, i, i - 1);
  };
  const moveDown = (i: number) => {
    value.rows = arraySwap(value.rows, i, i + 1);
  };
  const remove = (i: number) => {
    areYouSure(value.rows[i].title, () => {
      value.rows.splice(i, 1);
      value.rows = value.rows;
    });
  };

  const userFileUploadCallback = (
    response: Response,
    key: string,
    file: File,
  ): Promise<void> => {
    value.key = key;
    value.title = file.name;
    value.timestamp = DateTime.local().toISO();
    return;
  };

  const downloadFileLocally = async (key: string): Promise<void> => {
    await fileStorageClient.downloadSingleFileLocally(key);
  };

  const deleteFileFromBucket = async (key: string): Promise<void> => {
    await fileStorageClient.deleteFileByKey(key);
  };
</script>

{#if field && typeof value != 'undefined' && valueReady}
  {#if field.type == 'text' || field.type == 'date' || field.type == 'time' || field.type == 'datetime-local'}
    <Input type={field.type} bind:value={value.value} />
  {:else if field.type == 'number'}
    <Input
      type={field.type}
      bind:value={value.value}
      min={field.options?.min}
      max={field.options?.max}
      step={field.options?.step}
    />
  {:else if field.type == 'googleFormLink'}
    <Input
      type="text"
      bind:value={value.url}
      onchange={() => {
        Loading.show();
        let arr = value.url.split('/');
        let formID = arr[arr.length - 2];
        let getURI = ENDPOINT + '/api/google-forms?formId=' + formID;
        fetch(getURI)
          .then(response => response.json())
          .then(data => {
            Loading.hide();
            console.log(data);
            if (data.responderUri) {
              value.url = data.responderUri;
              value.formData = data;
            } else {
              dialog(data.data.error);
              //maybe wrong url
              value.formData = {};
              value.url = '';
              dialog($_('WrongFormURL'));
            }
          });
      }}
    />
    {#if value.formData?.items}
      <div class="uk-margin">
        <MetaText>{$_('googleForm.QuestionToUseAsStudentID')}</MetaText>
        {value.formData.items[0].title}
        <div class="uk-alert uk-background-secondary uk-border-rounded">
          {$_('googleForm.PleaseMakeSureThisFieldIsRequired')}
        </div>
      </div>
      {$_('googleForm.FieldThatShouldMatch')}
      <select class="uk-select" bind:value={value.customFieldForStudentID}>
        {#each $School?.customFields['subscriber'] || [] as subscriberCustomField}
          <option value={subscriberCustomField.id}
            >{subscriberCustomField.title}</option
          >
        {/each}
      </select>
    {/if}
    <Div>
      <div class="uk-alert uk-background-secondary uk-border-rounded">
        {@html $_('googleForm.PleaseMakeSureTo4')}
      </div>
    </Div>
  {:else if field.type == 'googleFormResponseScore'}
    <div>
      <Input type="number" bind:value={value.value} />
      <Button
        onclick={async () => {
          value.value = await getFormScore(field, parentFields, parentValues);
        }}
      >
        <i class="fa-brands fa-google" />
        {$_('googleForm.UpdateFormScore')}
      </Button>
    </div>
  {:else if field.type == 'googleSpreadsheetLink'}
    <Input type="text" bind:value={value.url} />
  {:else if field.type == 'color'}
    <div class="uk-position-relative">
      <div
        style:color={value.value}
        on:click={() => {
          colorPickerVisible = !colorPickerVisible;
        }}
      >
        <i class="fa-solid fa-circle fa-lg" />
      </div>
      {#if colorPickerVisible}
        <div
          class="uk-animation-fade uk-animation-fast"
          style="background-color:rgba(0,0,0,0.2); position:fixed;top:0px;left:0px;width:100vw;height:100vh"
          on:click={() => {
            colorPickerVisible = false;
          }}
        />
        <div
          class="uk-animation-fade  uk-animation-fast uk-position-top-left uk-margin-medium-top"
        >
          <ColorPicker bind:value={value.value} />
        </div>
      {/if}
    </div>
  {:else if field.type == 'select' && typeof value != 'string'}
    <select class="uk-select" bind:value={value.value}>
      {#each field.options?.values || [] as val}
        <option value={val}>{val}</option>
      {/each}
    </select>
  {:else if field.type == 'endOfMonth'}
    <select class="uk-select" bind:value={value.value}>
      {#each endsOfMonth as val}
        <option value={val}>{DateTime.fromISO(val).toLocaleString()}</option>
      {/each}
    </select>
  {:else if field.type == 'selectMultiple'}
    <ul class="uk-subnav">
      {#each field.options.values as val}
        <li>
          {#if value.values}
            <label>
              <input
                type="checkbox"
                class="uk-checkbox"
                bind:group={value.values}
                name={field.title}
                value={val}
              />
              {val}
            </label>
          {/if}
        </li>
      {/each}
    </ul>
  {:else if field.type == 'multiLineText'}
    <textarea class="uk-textarea" bind:value={value.value} />
  {:else if field.type == 'richText'}
    <TinyTextarea bind:value={value.value} />
  {:else if field.type == 'videoProgress'}
    <VideoProgress />
  {:else if field.type == 'image'}
    <CustomIconField
      original
      bind:value={value.url}
      setFilename={title => {
        console.log('returned title', title);
        value.title = title;
      }}
    />
    <Input bind:value={value.title} />
  {:else if field.type == 'checkbox'}
    <Checkbox bind:checked={value.value} />
  {:else if field.type == 'video'}
    {#if value.assets?.iframe}
      {#key value.videoId}
        <VideoPlayer
          thumbnailURL={value.assets.thumbnail}
          title={value.title}
          videoId={value.videoId}
          userId={$SchoolRole.id}
        />
      {/key}

      <div>
        <Input bind:value={value.title} />
        <Button
          onclick={() => {
            value = { title: '', assets: {} };
          }}>{$_('Delete')}</Button
        >
      </div>
    {:else}
      <VideoFileUpload
        style="default"
        fullwidth
        onUploadComplete={res => {
          console.log(res);

          value = res;
        }}>{$_('Upload')}</VideoFileUpload
      >
    {/if}
  {:else if field.type == 'file'}
    {#if value.url}
      <a
        href={'#'}
        on:click={() => {
          downloadFileAs;
          downloadFileAs(value);
        }}
      >
        {#if HOST.indexOf('localhost') == -1}
          <img src={`${thumbnailCloudURL}${value.url || value}`} alt="" />
        {:else}
          <img src={`${value.url || value}`} alt="" />
        {/if}
      </a>
      <div>
        <Input bind:value={value.title} />
        <div class="uk-flex uk-flex-between">
          <Button
            disabled={field.disabled || false}
            onclick={() => {
              value = {};
            }}>{$_('Delete')}</Button
          >
          {#if value.timestamp}
            {$_('customFields.UploadedAt')}:
            <div>
              {DateTime.fromISO(value.timestamp).toLocaleString(
                DateTime.DATETIME_SHORT,
              ) || ''}
            </div>
          {/if}
        </div>
      </div>
    {:else if value.key}
      <a
        href={'#'}
        on:click={() => {
          downloadFileLocally(value.key);
        }}
      >
        {value.title}
      </a>
      <div>
        <Input bind:value={value.title} />
        <div class="uk-flex uk-flex-between">
          <Button
            disabled={field.disabled || false}
            onclick={() => {
              deleteFileFromBucket(value.key);
              value = {};
            }}>{$_('Delete')}</Button
          >
          {#if value.timestamp}
            {$_('customFields.UploadedAt')}:
            <div>
              {DateTime.fromISO(value.timestamp).toLocaleString(
                DateTime.DATETIME_SHORT,
              ) || ''}
            </div>
          {/if}
        </div>
      </div>
    {:else}
      <UserFilesUpload
        schoolId={$School._id}
        userId={$User._id}
        fileUploadCallback={userFileUploadCallback}
        >{$_('Upload')}</UserFilesUpload
      >
    {/if}
  {:else if field.type == 'array'}
    <ul class="uk-list uk-list-small uk-margin-remove-top">
      {#each value?.rows || [] as row, i}
        <li>
          <div
            class="uk-padding-small uk-border-rounded uk-background-secondary uk-light"
          >
            <ArrayNav
              {moveUp}
              {moveDown}
              {remove}
              {i}
              arrayLength={value.rows.length}
            />
            {#each field.options.rows as rowField, i}
              {#if row.customFieldValues[rowField.id]}
                <CustomFieldEditOrDisplay
                  fields={field.options.rows}
                  field={rowField}
                  bind:value={row.customFieldValues[rowField.id]}
                  values={row.customFieldValues}
                />
              {/if}
            {/each}
          </div>
        </li>
      {/each}
    </ul>
    <div>
      <Button
        small
        onclick={() => {
          if (typeof value == 'string' || typeof value == 'undefined')
            value = {
              rows: [],
            };
          let customFieldValues = {};
          field.options.rows.forEach(row => {
            customFieldValues[row.id] = {};
          });
          if (typeof value.rows == 'undefined') value.rows = [];
          value.rows = [
            ...value.rows,
            {
              customFieldValues: customFieldValues,
            },
          ];
        }}>{$_('Add')}</Button
      >
    </div>
  {/if}
{/if}
