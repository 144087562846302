import axios from 'axios';
import { SERVER_URL } from '../../lib/config';
import formsStore from '../stores/formsStore';
import responsesStore from '../stores/responsesStore';

export const fetchForm = async formId => {
  try {
    const response = await axios.get(SERVER_URL + 'api/google-forms', {
      params: {
        formId,
      },
    });

    formsStore.addForm(response.data);
    return response.data;
  } catch (e) {
    console.log(e.message);
  }
};

export const fetchFormResponses = async formId => {
  const form = await fetchForm(formId);

  try {
    const response = await axios.get(SERVER_URL + 'api/responses-spreadsheet', {
      params: {
        spreadsheetId: form.linkedSheetId,
      },
    });

    responsesStore.addResponses(formId, response.data);
    return response.data;
  } catch (e) {
    console.log(e.message);
  }
};

/**
 * example of using results from spreadsheet id
 */

/*
  responsesStore.subscribe((value) => {
    console.log('responses ============>', value);
  });

  onMount(() => {
    fetchFormResponses('1BqaiYqDDqo-aPW370Lq0VXoLNGX2IdwNovrDutF0fww');
  });
*/
