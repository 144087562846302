export const PersonaDefault = {
    avatarURL: '/models/avatar.glb',
    bio: '',
    height: 1.6,
    nickname: 'My Nickname',
    position: { x: 0, y: 0, z: 0 },
    rotation: { x: 0, y: 0, z: 0 },
    profileImageURL: '/images/no_photo.jpg',
    registrations: [],
    status: '',
    onlineStatus: '',
    stripeCustomerID: '',
    roomID: '',
    customFieldValues: {},
    schoolID: '',
    selected: false,
};
