AFRAME.registerComponent('click-to-seat', {
  init: function () {
    let textEl = document.getElementById('changeSeatText');
    this.el.addEventListener('mouseenter', evt => {
      textEl.setAttribute('position', evt.detail.intersection.point);
      textEl.setAttribute('visible', true);
    });
    this.el.addEventListener('mouseleave', function (evt) {
      textEl.setAttribute('visible', false);
    });
    this.el.addEventListener('click', evt => {
      console.log(evt);
      let target_desk = evt.target;
      var transportPoint = {
        x: target_desk.getAttribute('position').x,
        y: 1.25,
        z: target_desk.getAttribute('position').z + 0.3,
      };
      sitDown();
      rig.setAttribute(
        'animation',
        'property:position;to:' +
          transportPoint.x +
          ' ' +
          transportPoint.y +
          ' ' +
          transportPoint.z,
      );
    });
  },
});
