<script lang="ts">
  import { DateTime } from 'luxon';

  import { onMount } from 'svelte';
  import { select_options } from 'svelte/internal';
  import { ringSize } from '../../lib/defaultValues';
  import RingText from './CustomFieldComponents/RingText.svelte';
  import VideoProgressRing from './VideoProgressRing.svelte';
  export let field;
  export let fields;
  export let values;
  export let value;
  let ring;
  let width = ringSize;
</script>

{#if value.belowTheRing && value.aboveTheRing && value.insideTheRing}
  <div style:width="{width}px">
    {#if value.showAboveTheRing == true}
      <div class="uk-text-center uk-text-nowrap">
        <RingText field={value.aboveTheRing[0]} value={value.aboveTheRing[1]} />
      </div>
    {/if}
    <div
      class="uk-position-relative"
      style:height="{width}px"
      style:width="{width}px"
      style:overflow="hidden"
    >
      {#if !field.options.usePieChart}
        <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
          <circle
            cx={width / 2}
            cy={width / 2}
            r={width / 2.1}
            stroke={value.ringColor.value}
            stroke-width="3"
            fill="none"
          />
        </svg>
      {/if}
      <div class="uk-position-center uk-text-nowrap">
        <span
          class="{value.insideTheRingSize == 'large' ? 'uk-text-large' : ''}
          {value.insideTheRingSize == 'small' ? 'uk-text-small' : ''}"
        >
          <RingText
            field={value.insideTheRing[0]}
            value={value.insideTheRing[1]}
          />
        </span>
      </div>
    </div>
    {#if value.showBelowTheRing == true}
      <div class="uk-text-center uk-text-small uk-text-nowrap">
        <RingText field={value.belowTheRing[0]} value={value.belowTheRing[1]} />
      </div>
    {/if}
  </div>
{/if}
