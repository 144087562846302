import { dashboardClose, dashboardOut } from '../lib/defaultValues';
import resetFocus from '../lib/helpers/resetFocus';
import positionRingItems from '../actions/positionRingItems';

import {
  playVideo,
  pauseVideo,
  toggleVideo,
  muteVideo,
  turnVolumeDown,
  turnVolumeUp,
  fastForwardVideo,
  rewindVideo,
  playFromBeginning,
} from '../actions/mediaItemController';
import {
  gumballTarget,
  dashboardGumballTarget,
  focusFriend,
  TargetPersona,
} from '../lib/store';
import { resetGumball, resetDashboardGumball } from '../lib/resizeGumball';
import {
  deleteItem,
  duplicateItem,
  updateItem,
  updateStoredAt,
  updateDashboardScale,
} from '../actions/itemController';
import { io, ioGet } from '../lib/realtime';
import { showModal } from '../actions/modalController';
import { Notification } from '../actions/notification';
import { _ } from 'svelte-i18n';
import { get } from 'svelte/store';
import dialog from '../lib/helpers/dialog';
import myPrompt from '../lib/helpers/myPrompt';
import TopNavSvelte from '../components/blocks/TopNav.svelte';
import { roomElementsStore } from '../state/stores/roomElementsStore';
let target = null;
gumballTarget.subscribe(el => {
  target = el;
});
let dashboardTarget;
dashboardGumballTarget.subscribe(el => {
  dashboardTarget = el;
});

let currentAmpLevel = 1;
let friendTarget;
focusFriend.subscribe(el => {
  friendTarget = el;
  if (el) {
    currentAmpLevel = el.getAttribute('ampLevel') || 1;
  }
});
const zoomDashboardIn = () => {
  let dashboard = document.getElementById('myDashboard');
  let y = dashboard.getAttribute('position').y;
  let z =
    (dashboardClose - dashboard.getAttribute('position').z) / 2 +
    dashboard.getAttribute('position').z;
  dashboard.setAttribute(
    'animation',
    'property:position;to:0 ' + y + ' ' + z + ';dur:500;loop:false;',
  );
};
const zoomDashboardOut = () => {
  let dashboard = document.getElementById('myDashboard');
  let y = dashboard.getAttribute('position').y;
  let z =
    (dashboardOut - dashboard.getAttribute('position').z) / 2 +
    dashboard.getAttribute('position').z;
  dashboard.setAttribute(
    'animation',
    'property:position;to:0 ' + y + ' ' + z + ';dur:500;loop:false;',
  );
};
const getVideoTarget = param => {
  var t;
  if (param == 'room') {
    t = target;
  } else {
    t = dashboardTarget;
  }
  return t;
};
const copy = t => {
  Notification(get(_)('Copied'));
  duplicateItem(t);
};
const remove = t => {
  if (
    t.getAttribute('sourceType') == 'audio' ||
    t.getAttribute('sourceType') == 'video'
  ) {
    pauseVideo(t);
  }
  resetFocus();
  deleteItem(t);
};
const fall = () => {
  var bbox = new THREE.Box3().setFromObject(target.object3D);
  let y = target.object3D.position.y - bbox.min.y;
  let targetPosition =
    target.object3D.position.x + ' ' + y + ' ' + target.object3D.position.z;
  target.setAttribute(
    'animation__fall',
    'property:position; dur:1000; to:' + targetPosition + '; loop:false',
  );
  target.addEventListener('animationcomplete', function () {
    updateItem(target);
    resetGumball(target);
  });
};

const reset = () => {
  target.setAttribute('rotation', '0 0 0');
  target.setAttribute('scale', target.getAttribute('initialScale'));
  updateItem(target);
  resetGumball(target);
};

const toDashboard = () => {
  if (target.id.indexOf('screenOf') === 0) {
    //this is a screen capture
    io.emit('moveScreenCaptureToDashboard', {
      uid: target.id,
    });
  } else {
    updateStoredAt(target, 'dashboard');
  }
};
const toRoom = () => {
  if (dashboardTarget.id.indexOf('screenOf') === 0) {
    //this is a screen capture
    io.emit('moveScreenCaptureToRoom', {
      uid: dashboardTarget.id,
    });
    //moveScreenCaptureToRoom(target)
  } else {
    updateStoredAt(dashboardTarget, 'room');
  }
  resetFocus();
  setTimeout(function () {
    positionRingItems();
  }, 1000);
};
const download = position => {
  if (position == 'room') {
    var originalURL = target.getAttribute('show-gumball').originalURL;
  } else {
    var originalURL = dashboardTarget.getAttribute(
      'show-dashboard-gumball',
    ).originalURL;
  }
  if (originalURL) {
    window.open(originalURL, '_blank');
  }
};
const scaleDown = () => {
  let currentScale = dashboardTarget.getAttribute('dashboardScale');
  let y = dashboardTarget.getAttribute('dashboardOffset');
  let newScale = currentScale * 0.8;
  updateDashboardScale(dashboardTarget.id, newScale, y);
};
const scaleUp = () => {
  let currentScale = dashboardTarget.getAttribute('dashboardScale');
  let y = dashboardTarget.getAttribute('dashboardOffset');
  let newScale = currentScale * 1.2;
  updateDashboardScale(dashboardTarget.id, newScale, y);
};
const moveUp = () => {
  let scale = dashboardTarget.getAttribute('dashboardScale');
  let currentY = dashboardTarget.getAttribute('dashboardOffset');
  let newY = parseFloat(currentY) + 0.03;
  updateDashboardScale(dashboardTarget.id, scale, newY);
};
const moveDown = () => {
  let scale = dashboardTarget.getAttribute('dashboardScale');
  let currentY = dashboardTarget.getAttribute('dashboardOffset');
  let newY = currentY - 0.03;
  updateDashboardScale(dashboardTarget.id, scale, newY);
};

const kickOut = () => {
  io.emit('kickOut', friendTarget.getAttribute('socketID'));
};
const forceMute = () => {
  io.emit('forceMute', friendTarget.getAttribute('socketID'));
};
const forceTransport = () => {
  document
    .getElementById('selectTransportPoint')
    .setAttribute('personaID', friendTarget.getAttribute('id'));
  showModal('#selectTransportPoint');
};
const amplifyVolumeDown = () => {
  currentAmpLevel = friendTarget.getAttribute('ampLevel') || 1;
  console.log(currentAmpLevel);
  if (currentAmpLevel > 0.0001) {
    currentAmpLevel = currentAmpLevel / 2;
    friendTarget.setAttribute('ampLevel', currentAmpLevel);
  }
};
const amplifyVolumeUp = () => {
  currentAmpLevel = friendTarget.getAttribute('ampLevel') || 1;
  currentAmpLevel = currentAmpLevel * 2;
  friendTarget.setAttribute('ampLevel', currentAmpLevel);
};
const openInfo = async () => {
  let friendID = friendTarget.id;
  let persona = await ioGet('getPersona', {
    schoolRoleID: friendID,
  });
  TargetPersona.set(persona);
  setTimeout(function () {
    showModal('#profile');
  }, 100);
};
const editLinkURL = async position => {
  let el = position == 'room' ? target : dashboardTarget;
  if (!el) return;
  let item = roomElementsStore.list().find(a => a.uid == el.id);
  myPrompt(
    'URL',
    async url => {
      if (!url) return;
      let res = ioGet('updateItemLink', {
        _id: item.id,

        data: {
          linkURL: url,
        },
      });
      console.log(res);
      Notification(get(_)('Saved'));
    },
    item.linkURL,
  );
};
const openLinkURL = position => {
  let el = position == 'room' ? target : dashboardTarget;
  let item = roomElementsStore.list().find(e => e.uid == el.id);
  window.open(item.linkURL);
};
AFRAME.registerComponent('on-click', {
  schema: {
    action: {
      type: 'string',
    },
    param: { type: 'string' },
  },
  init: function () {
    this.el.addEventListener('click', evt => {
      switch (this.data.action) {
        case 'zoomDashboardIn':
          zoomDashboardIn();
          break;
        case 'zoomDashboardOut':
          zoomDashboardOut();
          break;
        case 'resetFocus':
          resetFocus();
          break;
        case 'playFromBeginning':
          playFromBeginning(getVideoTarget(this.data.param));
          break;
        case 'rewindVideo':
          rewindVideo(getVideoTarget(this.data.param));
          break;
        case 'toggleVideo':
          toggleVideo(getVideoTarget(this.data.param));
          break;
        case 'fastForwardVideo':
          fastForwardVideo(getVideoTarget(this.data.param));
          break;
        case 'muteVideo':
          muteVideo(getVideoTarget(this.data.param));
          break;
        case 'turnVolumeDown':
          turnVolumeDown(getVideoTarget(this.data.param));
          break;
        case 'turnVolumeUp':
          turnVolumeUp(getVideoTarget(this.data.param));
          break;
        case 'copy':
          copy(getVideoTarget(this.data.param));
          break;
        case 'remove':
          remove(getVideoTarget(this.data.param));
          break;
        case 'reset':
          reset();
          break;
        case 'fall':
          fall();
          break;
        case 'toDashboard':
          toDashboard();
          break;
        case 'toRoom':
          toRoom();
          break;
        case 'download':
          download(this.data.param);
          break;
        case 'scaleUp':
          scaleUp();
          break;
        case 'scaleDown':
          scaleDown();
          break;
        case 'moveUp':
          moveUp();
          break;
        case 'moveDown':
          moveDown();
          break;
        case 'kickOut':
          kickOut();
          break;
        case 'forceMute':
          forceMute();
          break;
        case 'forceTransport':
          forceTransport();
          break;
        case 'amplifyVolumeDown':
          amplifyVolumeDown();
          break;
        case 'amplifyVolumeUp':
          amplifyVolumeUp();
          break;
        case 'openInfo':
          openInfo();
          break;
        case 'editLinkURL':
          editLinkURL(this.data.param);
          break;
        case 'openLinkURL':
          openLinkURL(this.data.param);
          break;
      }
    });
    this.el.addEventListener('mouseenter', () => {
      this.el.setAttribute('scale', '1.1 1.1 1.1');
    });
    this.el.addEventListener('mouseleave', () => {
      this.el.setAttribute('scale', '1 1 1');
    });
  },
});
