import { _ } from 'svelte-i18n';
import { writable } from 'svelte/store';
import { io } from '../../lib/realtime';

const submissionsReducer = writable([]);

let submissions;
submissionsReducer.subscribe(array => {
  submissions = array;
});

export const submissionsStore = {
  subscribe: submissionsReducer.subscribe,
  add: function (item) {
    const newItem = {
      videoProgress: {},
      ...item,
    };
    if (!this.get(item._id)) {
      submissionsReducer.update(items => {
        return [...items, newItem];
      });
    }
  },
  addMany: function (items) {
    items = items.filter(
      item => !submissions.some(submission => submission._id == item._id),
    );
    submissionsReducer.update(oldItems => {
      return [...oldItems, ...items];
    });
  },
  set: function (items) {
    submissionsReducer.set(items);
  },
  get: _id => {
    return submissions.find(item => item._id == _id);
  },
  list: function () {
    return submissions;
  },
  clear: () => {
    submissionsReducer.set([]);
  },
};

io.on('submissionUpdated', res => {
  let updated = false;
  //console.log("submission updated", res);
  submissions = submissions.map(existingSubmission => {
    if (existingSubmission._id == res.data._id) {
      updated = true;
      //Notification(get(_)("UpdateDetected", { values: { category: get(_)("Assignments"), title: res.data.title } }));
      return res.data;
    } else {
      return existingSubmission;
    }
  });
  if (updated) {
    submissionsStore.set(submissions);
  }
});
