import {
  myVolume,
  myCurrentFace,
  megaphoneActive,
  User,
  SchoolRole,
  IsMutable,
  micOnMute,
} from '../lib/store.js';
import { io } from '../lib/realtime.js';
import moveMouth from '../lib/helpers/moveMouth.js';
import assignAnimation from '../lib/helpers/assignAnimation';
import Cookies from 'js-cookie';
import copy from '../lib/helpers/copy.js';
let schoolRole;
SchoolRole.subscribe(obj => {
  schoolRole = copy(obj);
});
let currentFace;
myCurrentFace.subscribe(face => {
  currentFace = face;
});

let isMutable;

IsMutable.subscribe(value => {
  isMutable = value;
});
let onMute;
micOnMute.subscribe(bool => {
  onMute = bool;
});
let e = new Event('moving');
AFRAME.registerComponent('broadcast-me', {
  schema: {
    id: {},
    enabled: Boolean,
  },
  init: function () {
    let me = {
      id: schoolRole.id,
      avatarURL: schoolRole.avatarURL,
      nickname: schoolRole.nickname,
    };
    let oldPos = {};
    let rig = document.getElementById(schoolRole.id);
    let lookRigger = document.getElementById('look-rigger');
    let interval = setInterval(() => {
      if (!rig) return;
      if (!schoolRole) {
        clearInterval(interval);
        return;
      }
      me.position = rig.getAttribute('position');
      if (me.position.x == 0 && me.position.y == 0 && me.position.z == 0)
        return;
      me.rotation = lookRigger.getAttribute('rotation');
      me.volume = schoolRole.currentVolume;
      me.face = schoolRole.currentFace;
      me.height = schoolRole.height;
      me.faceShape = schoolRole.faceShape;
      me.faceRotation = schoolRole.faceRotation;
      me.useFacialCapture = schoolRole.useFacialCapture;
      me.isMutable = isMutable;
      me.onMute = onMute;

      let hands = document.getElementById('myHands');
      if (hands) {
        if (
          hands.getAttribute('animation-mixer').clip == 'walk' ||
          hands.getAttribute('animation-mixer').clip == 'idle' ||
          hands.getAttribute('animation-mixer').clip == '*'
        ) {
          if (me.position.x == oldPos.x && me.position.y == oldPos.y) {
            //idling
            assignAnimation(hands, 'idle');
            me.action = 'idle';
          } else {
            //moving
            rig.dispatchEvent(e);
            assignAnimation(hands, 'walk');
            me.action = 'walk';
          }
        } else {
          //in other action
          me.action = hands.getAttribute('animation-mixer').clip;
        }
        //console.log(me)
      }
      io.emit('broadcastMe', me);
      oldPos.x = me.position.x;
      oldPos.y = me.position.y;
    }, 80);
  },
});
