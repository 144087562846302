<script>
  import '@pqina/pintura/pintura.css';
  import { _ } from 'svelte-i18n';

  // Import the editor functionality
  import {
    // Import the default image reader and writer
    createDefaultImageReader,
    createDefaultImageWriter,

    // The method used to register the plugins
    setPlugins,

    // The plugins we want to use
    plugin_crop,
    plugin_finetune,
    plugin_annotate,
    plugin_filter,
    plugin_decorate,
    plugin_resize,
    plugin_sticker,
    // The user interface and plugin locale objects
    locale_en_gb,
    plugin_crop_locale_en_gb,
    plugin_finetune_locale_en_gb,
    plugin_annotate_locale_en_gb,
    plugin_filter_locale_en_gb,
    plugin_decorate_locale_en_gb,
    plugin_resize_locale_en_gb,
    plugin_sticker_locale_en_gb,

    // Because we use the annotate plugin we also need
    // to import the markup editor locale and the shape preprocessor
    markup_editor_locale_en_gb,
    createDefaultShapePreprocessor,

    // Import the default configuration for the markup editor and finetune plugins
    markup_editor_defaults,
    plugin_finetune_defaults,
    plugin_filter_defaults,
  } from '@pqina/pintura';

  import { PinturaEditor } from '@pqina/svelte-pintura';

  //This registers the plugins with Pintura Image Editor
  setPlugins(
    plugin_crop,
    plugin_annotate,
    plugin_filter,
    plugin_resize,
    plugin_sticker,
  );

  // Create our editor configuration
  const editorConfig = {
    // This will read the image data (required)
    imageReader: createDefaultImageReader(),

    // This will write the output image
    imageWriter: createDefaultImageWriter({
      imageWriter: {
        // We instruct the editor to post the file object to the server
        store: ENDPOINT + '/upload',
      },
    }),

    // The markup editor default options, tools, shape style controls
    ...markup_editor_defaults,

    // The finetune util controls
    ...plugin_finetune_defaults,
    ...plugin_filter_defaults,

    // This handles complex shapes like arrows / frames
    shapePreprocessor: createDefaultShapePreprocessor(),

    // This will set a square crop aspect ratio
    //imageCropAspectRatio: 1,

    // The icons and labels to use in the user interface (required)

    locale: {
      ...locale_en_gb,
      ...plugin_crop_locale_en_gb,
      ...plugin_filter_locale_en_gb,
      ...plugin_annotate_locale_en_gb,
      ...plugin_decorate_locale_en_gb,
      ...plugin_resize_locale_en_gb,
      ...plugin_sticker_locale_en_gb,
      ...markup_editor_locale_en_gb,
      cropLabel: $_('imageEdit.Crop'),
      cropLabelButtonRotateLeft: $_('imageEdit.cropLabelButtonRotateLeft'),
      cropLabelButtonFlipHorizontal: $_(
        'imageEdit.cropLabelButtonFlipHorizontal',
      ),
      annotateLabel: $_('imageEdit.annotateLabel'),
      filterLabel: $_('imageEdit.filterLabel'),
      labelSize: $_('imageEdit.Size'),
      labelSizeExtraLarge: $_('imageEdit.ExtraLarge'),
      labelSizeExtraSmall: $_('imageEdit.ExtraSmall'),
      labelSizeLarge: $_('imageEdit.Large'),
      labelSizeMedium: $_('imageEdit.Medium'),
      labelSizeMediumLarge: $_('imageEdit.MediumLarge'),
      labelSizeMediumSmall: $_('imageEdit.MediumSmall'),
      labelSizeSmall: $_('imageEdit.Small'),
      shapeTitleStrokeColor: $_('imageEdit.shapeTitleStrokeColor'),
      shapeTitleStrokeWidth: $_('imageEdit.shapeTitleStrokeWidth'),
      shapeLabelToolSharpie: $_('imageEdit.shapeLabelToolSharpie'),
      shapeLabelToolArrow: $_('imageEdit.shapeLabelToolArrow'),
      shapeLabelToolEllipse: $_('imageEdit.shapeLabelToolEllipse'),
      shapeLabelToolEraser: $_('imageEdit.shapeLabelToolEraser'),
      shapeLabelToolLine: $_('imageEdit.shapeLabelToolLine'),
      shapeLabelToolMove: $_('imageEdit.shapeLabelToolMove'),
      shapeLabelToolPreset: $_('imageEdit.shapeLabelToolPreset'),
      shapeLabelToolRectangle: $_('imageEdit.shapeLabelToolRectangle'),
      shapeLabelToolText: $_('imageEdit.shapeLabelToolText'),
      shapeTitleBackgroundColor: $_('imageEdit.shapeTitleBackgroundColor'),
      shapeTitleCornerRadius: $_('imageEdit.shapeTitleCornerRadius'),
      labelButtonExport: $_('imageEdit.labelButtonExport'),
      shapeLabelButtonSelectSticker: $_(
        'imageEdit.shapeLabelButtonSelectSticker',
      ),
      stickerLabel: $_('imageEdit.stickerLabel'),
      resizeLabel: $_('imageEdit.resizeLabel'),
      cropLabelTabRotation: $_('imageEdit.cropLabelTabRotation'),
      cropLabelTabZoom: $_('imageEdit.cropLabelTabZoom'),
      shapeLabelFontStyleBold: $_('imageEdit.shapeLabelFontStyleBold'),
      shapeLabelFontStyleItalic: $_('imageEdit.shapeLabelFontStyleItalic'),
      shapeLabelFontStyleItalicBold: $_(
        'imageEdit.shapeLabelFontStyleItalicBold',
      ),
      shapeLabelFontStyleNormal: $_('imageEdit.shapeLabelFontStyleNormal'),
      shapeTitleFontFamily: $_('imageEdit.shapeTitleFontFamily'),
      shapeTitleFontSize: $_('imageEdit.shapeTitleFontSize'),
      shapeTitleFontStyle: $_('imageEdit.shapeTitleFontStyle'),
      shapeTitleTextColor: $_('imageEdit.shapeTitleTextColor'),
      shapeTitleLineHeight: $_('imageEdit.shapeTitleLineHeight'),
      shapeTitleLineStart: $_('imageEdit.shapeTitleLineStart'),
      shapeTitleLineEnd: $_('imageEdit.shapeTitleLineEnd'),
    },
    animations: 'never',
  };

  import {
    ImageEditCallback,
    ImageEditURL,
    ImageEditSquare,
  } from '../../lib/store';
  import upload from '../../lib/helpers/upload';
  import { createItem } from '../../actions/itemController';
  import { ENDPOINT } from '../../lib/config';
  import { hideModal } from '../../actions/modalController';
  let imageEditURL = '';
  ImageEditURL.subscribe(url => {
    console.log('image url updated');
    imageEditURL = url;
  });

  const handleEditorProcess = event => {
    const imageWriterResult = event.detail;

    // logs: { src:…, dest:… , imageState:…, store:… }
    let fd = new FormData();
    fd.append('myFile', imageWriterResult.dest);
    upload(
      fd,
      res => {
        $ImageEditCallback(res);

        hideModal('#imageEditor');
      },
      () => {
        console.log('rejected');
      },
    );
  };
</script>

{#if imageEditURL != ''}
  <div style="height:600px">
    <PinturaEditor
      {...editorConfig}
      src={imageEditURL}
      imageCropAspectRatio={$ImageEditSquare ? 1 : null}
      on:process={handleEditorProcess}
    />
  </div>
{:else}
  no image URL
{/if}
