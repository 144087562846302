import resetFocus from '../lib/helpers/resetFocus';
import { emotePanelVisible } from '../lib/store';
import { hideAFRAME, showAFRAME } from '../lib/visibilityAFRAME';
let visible = false;

AFRAME.registerComponent('show-emote-selection', {
  init: function () {
    this.el.addEventListener('click', () => {
      let emotePanelEl = document.getElementById('myEmotePanel');
      visible = !visible;
      emotePanelVisible.set(visible);
      /*
      if(visible){
        resetFocus()
        showAFRAME(emotePanelEl)
      } else {
        resetFocus()
      }*/
    });
  },
});
