<script>
  import Pagination from './Pagination.svelte';

  import { DateTime } from 'luxon';
  import { onMount } from 'svelte';
  import { _ } from 'svelte-i18n';
  import { paginate } from 'svelte-paginate';
  import { navigate } from 'svelte-routing';
  import articleController from '../actions/articleController';
  import { showModal } from '../actions/modalController';
  import { Notification } from '../actions/notification';
  import { previewMath } from '../lib/helpers/decodeMath';
  import { defineIfUndefined } from '../lib/helpers/defineIfUndefined';
  import { ArticleDefault } from '../lib/interfaces/Articles';
  import { ioGet } from '../lib/realtime';
  import { Articles, School, SchoolRole, TargetArticle } from '../lib/store';
  import ArticleDetail from './blocks/ArticleDetail.svelte';
  import ArticlesList from './blocks/ArticlesList.svelte';
  import Button from './UI/Button.svelte';
  import Lead from './UI/Lead.svelte';
  import Modal from './UI/Modal.svelte';

  let articles = [];
  let publicArticles = [];
  let privateArticles = [];
  let articleID = location.href.split('articles/').pop().replace('#', '');
  let curPubPage = 1;
  let curNotPubPage = 1;
  let pageSize = 8;
  let activeTab = 'private';

  function deleteFutureArticles(articles) {
    const now = DateTime.now();
    return articles.filter(a => {
      return DateTime.fromISO(a.publishAt) < now;
    });
  }

  onMount(async () => {
    let res = await articleController.list();

    articles = res;
    if ($SchoolRole.category !== 'admin') {
      articles = deleteFutureArticles(articles);
    }
    articles = articles.map(article => {
      article.favorites = defineIfUndefined(article.favorites, []);
      article.comments = defineIfUndefined(article.comments, []);
      article.customFieldValues = defineIfUndefined(
        article.customFieldValues,
        {},
      );
      $School.customFields['article'].forEach(field => {
        article.customFieldValues[field.id] = defineIfUndefined(
          article.customFieldValues[field.id],
          {},
        );
      });
      if (article.isPublic) {
        publicArticles = [...publicArticles, article];
      } else {
        privateArticles = [...privateArticles, article];
      }
      return article;
    });

    console.log(articles);

    Articles.set(articles);
    if (articles.some(article => article._id == articleID)) {
      console.log('articleID', articleID);
      openArticleDetail(articles.find(article => article._id == articleID));
    }
    [currentPage, totalItems] = updateCurrentPage(activeTab);
  });
  const myPaginate = (items, pageSize, currentPage) => {
    if (!items || typeof items == 'undefined') {
      return [];
    } else {
      return paginate({ items, pageSize, currentPage });
    }
  };
  $: pagPublicArticles = myPaginate(publicArticles, pageSize, curPubPage);
  $: pagArticles = myPaginate(privateArticles, pageSize, curNotPubPage);
  const create = () => {
    if (!$TargetArticle || typeof $TargetArticle._id != 'undefined') {
      let newArticle = {
        ...ArticleDefault,
        startDate: DateTime.now().toISODate(),
      };
      TargetArticle.set(newArticle);
    }
    showModal('#createArticle');
  };
  const openArticleDetail = async article => {
    targetArticle = article;
    previewMath();
    navigate(article._id);

    showModal('#articleListDetail');
  };
  const edit = async id => {
    let res = await ioGet('getArticle', {
      _id: id,
    });
    TargetArticle.set(res);
    showModal('#editArticle');
  };

  const remove = id => {};
  let targetArticle;
  const updateArticle = async () => {
    console.log(targetArticle);
    targetArticle.publishAt = DateTime.fromISO(
      targetArticle.startDate + 'T' + targetArticle.publishTime + ':00',
    ).toISO();

    let res = await ioGet('updateArticle', {
      _id: targetArticle._id,
      data: targetArticle,
    });
    console.log(res);
    Notification($_('Saved'));
  };
  const saveArticle = async article => {
    let res = await ioGet('updateArticle', {
      _id: article._id,
      data: {
        favorites: article.favorites,
      },
    });
    console.log(res);
  };
  const tabSwitch = e => {
    activeTab = e;
  };
  const updateCurrentPage = activeTab => {
    if (activeTab == 'public') {
      return [curPubPage, publicArticles.length];
    } else {
      return [curNotPubPage, privateArticles.length];
    }
  };
  $: [currentPage, totalItems] = updateCurrentPage(activeTab);
</script>

<svelte:head>
  <title>{$_('Articles')} | {$School?.title} - AVOS</title>
</svelte:head>
<Lead>{$_('Articles')}</Lead>
{#if $School.roles.find(schoolRole => schoolRole.id == $SchoolRole.roleID)?.school.article.canCreate}
  <Button onclick={create}>{$_('Create')}</Button>
{/if}

<ul class="uk-tab" uk-switcher="animation: uk-animation-fade">
  <li>
    <a
      href={'#'}
      on:click={() => {
        tabSwitch('private');
      }}>{$_('Private')}</a
    >
  </li>
  <li>
    <a
      href={'#'}
      on:click={() => {
        tabSwitch('public');
      }}>{$_('Public')}</a
    >
  </li>
</ul>

<Pagination {pageSize} {totalItems} bind:currentPage />

<ArticlesList
  articles={activeTab == 'public' ? pagPublicArticles : pagArticles}
  {edit}
  {saveArticle}
  {openArticleDetail}
/>

<Modal
  withFooter
  id="articleListDetail"
  size="container"
  title={targetArticle?.title}
>
  <div slot="body">
    {#if targetArticle}
      {#key targetArticle.id}
        <ArticleDetail bind:article={targetArticle} />
      {/key}
    {/if}
  </div>
  <div slot="footer">
    <Button onclick={updateArticle}>{$_('Save')}</Button>
  </div>
</Modal>
