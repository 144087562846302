<script>
  import { navigate } from 'svelte-routing';

  export let focus = '';
  export let slug;
  export let breadcrumb;
  const nav = slug => {
    navigate(breadcrumb + slug + '/');
    focus = slug;
  };
</script>

<li class:uk-active={focus == slug}>
  <a href={'#'} on:click={nav(slug)}>
    <slot />
  </a>
</li>
