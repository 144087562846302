export const getCreateDownloadLink = (url) => {
    if (!document)
        return;
    const downloadLink = document.createElement("a");
    downloadLink.target = '_blank';
    downloadLink.href = url;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
    downloadLink.remove();
};
