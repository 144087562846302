import passwordValidator from 'password-validator';

// Create a schema
var schema = new passwordValidator();

// Add properties to it
schema
  .is()
  .min(8, 'login.PasswordMustBeAtLeast8Characters') // Minimum length 8
  .is()
  .max(100, 'login.PasswordShouldBeLessThan100Characters') // Maximum length 100
  .has()
  .uppercase(1, 'login.PasswordShouldHaveAtLeastOneUppercaseChatracter') // Must have uppercase letters
  .has()
  .lowercase(1, 'login.PasswordShouldHaveAtLeastOneLoweraseCharacter') // Must have lowercase letters
  .has()
  .digits(1, 'login.PasswordShouldHaveAtLeastOneNumber') // Must have at least 2 digits
  .has()
  .not()
  .spaces();

const validatePassword = password => {
  let res = schema.validate(password, { details: true });
  return res;
};
export default validatePassword;
