<script lang="ts">
  import arraySwap from '../../lib/helpers/arraySwap';

  import Button from '../UI/Button.svelte';
  import Modal from '../UI/Modal.svelte';
  import ArrayNav from './ArrayNav.svelte';
  import CustomFieldEditRow from './CustomFieldComponents/CustomFieldEditRow.svelte';
  import { _ } from 'svelte-i18n';
  import { UUID } from '../../lib/uuid';
  import areYouSure from '../../lib/helpers/areYouSure';
  import CustomFieldEditButtonModal from '../Modals/CustomFieldEditButtonModal.svelte';
  import CustomFieldThead from './CustomFieldComponents/CustomFieldThead.svelte';
  export let customFields;
  export let saveCustomField;
  const moveUp = (i: number) => {
    customFields = arraySwap(customFields, i, i - 1);
  };
  const moveDown = (i: number) => {
    customFields = arraySwap(customFields, i, i + 1);
  };
  const remove = (i: number) => {
    areYouSure(customFields[i].title, () => {
      customFields.splice(i, 1);
      customFields = customFields;
    });
  };
</script>

<tr>
  <th>{$_('Icon')}</th>
  <th>{$_('Title')}</th>
  <CustomFieldThead
    withAdd
    bind:fields={customFields}
    {moveUp}
    {moveDown}
    {remove}
    {saveCustomField}
  />
</tr>
