<script lang="ts">
  7;
  export let threshould: number = 75;
  export let videos;
  export let videoProgress;
  export let width: number = 100;
</script>

<div
  class="uk-position-relative"
  style:height="{width}px"
  style:width="{width}px"
>
  <div class="uk-position-top">
    <svg
      viewBox="0 0 63.6619772368 63.6619772368"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
    >
      <circle
        cx="31.8309886184"
        cy="31.8309886184"
        r="31.8309886184"
        fill="rgba(32,178,170,0.1)"
      />
    </svg>
  </div>
  {#if typeof videoProgress != 'undefined'}
    {#each videos.filter(video => videoProgress[video.videoId] > threshould) as video, i}
      <div class="uk-position-top">
        <svg
          viewBox="0 0 63.6619772368 63.6619772368"
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
        >
          <circle
            cx="31.8309886184"
            cy="31.8309886184"
            r="15.9154943092"
            fill="rgba(0,0,0,0)"
            stroke="rgba(32,178,170,0.5)"
            stroke-width="31.8309886184"
            stroke-dashoffset={25 - (i * 100) / videos.length}
            stroke-dasharray="{100 / videos.length},{100 - 100 / videos.length}"
          />
        </svg>
      </div>
    {/each}
  {/if}
</div>
