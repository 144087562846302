export const RegistrationStatus = {
    /** 1.「申請済み」 */
    AwaitingApproval: 'awaitingApproval',
    /** 2.「確定済み」 */
    AwaitingPayment: 'awaitingPayment',
    /** 3.「入金確認済み」 */
    PaymentVerified: 'paymentVerified',
    /** 4.「反映済み（授業開始）」 */
    CourseStarted: 'courseStarted',
};
