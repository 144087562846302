<script>
  import { _ } from 'svelte-i18n';
  import { decodeMath } from '../../lib/helpers/decodeMath';
  import { DialogMessage, previewValue } from '../../lib/store';
  import Chat from '../Chat.svelte';
  import ArticleModals from '../Modals/ArticleModals.svelte';
  import AssignmentEdit from '../Modals/AssignmentEdit.svelte';
  import ConfirmModal from '../Modals/ConfirmModal.svelte';
  import CustomFieldEdit from '../Modals/CustomFieldEdit.svelte';
  import EventModals from '../Modals/EventModals.svelte';
  import GradeEdit from '../Modals/GradeEdit.svelte';
  import ImageEditor from '../Modals/ImageEditor.svelte';
  import ProductModals from '../Modals/ProductModals.svelte';
  import Profile from '../Modals/Profile.svelte';
  import PromptModal from '../Modals/promptModal.svelte';
  import RoomSettingModal from '../Modals/RoomSettingModal.svelte';
  import SubmissionEdit from '../Modals/SubmissionEdit.svelte';
  import Modal from '../UI/Modal.svelte';
  import NickNameAndAvatarEdit from './NickNameAndAvatarEdit.svelte';
  import VersionHistory from './VersionHistory.svelte';
  import { checkUnreadMessages } from '../../lib/helpers/chatLogin';
  import { onMount } from 'svelte';
  onMount(() => {
    document.getElementById('chatModal').addEventListener('hide', () => {
      checkUnreadMessages();
    });
    document.getElementById('cheatSheetContent').innerHTML = `<tr>
                    <td>
                        <textarea class="uk-textarea">y=ax^2+bx+c</textarea>
                    </td>
                    <td>
                        \\[y=ax^2+bx+c\\]
                    </td>
                </tr>
                <tr>
                    <td>
                        <textarea class="uk-textarea">[fn]frac{x^3+1}{x+1}</textarea>
                    </td>
                    <td>
                        \\[\\frac{x^3+1}{x+1}\\]
                    </td>
                </tr>
                <tr>
                    <td>
                        <textarea class="uk-textarea">[fn]cfrac{ [fn]cfrac{3x+1}{x^2-1} }{ [br] x^3+1 [br] }</textarea>
                    </td>
                    <td>
                        \\[\\cfrac{ \\cfrac{3x+1}{x^2-1} }{ \\ \\ x^3+1 \\ \\ }\\]
                    </td>
                </tr>
                <tr>
                    <td>
                        <textarea class="uk-textarea">f(x)= [fn]left [fn]{[fn]begin{array}{l}1　(x＝1のとき) [br]0　(x≠1のとき)[fn]end{array}[fn]right.</textarea>
                    </td>
                    <td>
                        \\[f(x)= \\left \\{\\begin{array}{l}1　(x＝1のとき) \\\\0　(x≠1のとき)\\end{array}\\right.\\]
                    </td>
                </tr>
                <tr>
                    <td>
                        <textarea class="uk-textarea">[fn]dot{x}, [fn]ddot{x}</textarea>
                    </td>
                    <td>
                        \\[\\dot{x}, \\ddot{x}\\]
                    </td>
                </tr>
                <tr>
                    <td>
                        <textarea class="uk-textarea">a_{ij}</textarea>
                    </td>
                    <td>
                        \\[a_{ij}\\]
                    </td>
                </tr>
                <tr>
                    <td>
                        <textarea class="uk-textarea">{}_{n}C_{k}</textarea>
                    </td>
                    <td>
                        \\[{}_{n}C_{k}\\]
                    </td>
                </tr>
                <tr>
                    <td>
                        <textarea class="uk-textarea">{}^{i}_{j}T^{k}_{h}</textarea>
                    </td>
                    <td>
                        \\[{}^{i}_{j}T^{k}_{h}\\]
                    </td>
                </tr>
                <tr>
                    <td>
                        <textarea class="uk-textarea">[fn]sqrt{x}</textarea>
                    </td>
                    <td>
                        \\[\\sqrt{x}\\]
                    </td>
                </tr>
                <tr>
                    <td>
                        <textarea class="uk-textarea">S_n=[fn]sum_{k=1}^{n} (x_k)^2 </textarea>
                    </td>
                    <td>
                        \\[S_n=\\sum_{k=1}^{n} (x_k)^2 \\]
                    </td>
                </tr>
                <tr>
                    <td>
                        <textarea class="uk-textarea">[fn]lim_{h [fn]to 0} [fn]frac{[fn]sqrt{a+h}-[fn]sqrt{a}}{h} </textarea>
                    </td>
                    <td>
                        \\[\\lim_{h \\to 0} \\frac{\\sqrt{a+h}-\\sqrt{a}}{h} \\]
                    </td>
                </tr>
                <tr>
                    <td>
                        <textarea class="uk-textarea">[fn]frac{d^2y}{dx^2}=2x+3</textarea>
                    </td>
                    <td>
                        \\[\\frac{d^2y}{dx^2}=2x+3 \\]
                    </td>
                </tr>
                <tr>
                    <td>
                        <textarea class="uk-textarea">[fn]frac{[fn]partial^2 f}{[fn]partial x^2} =2x+y </textarea>
                    </td>
                    <td>
                        \\[\\frac{\\partial^2 f}{\\partial x^2} =2x+y \\]
                    </td>
                </tr>
                <tr>
                    <td>
                        <textarea class="uk-textarea">I=[fn]int_{1}^{3} x^2 dx</textarea>
                    </td>
                    <td>
                        \\[I=\\int_{1}^{3} x^2 dx \\]
                    </td>
                </tr>
                <tr>
                    <td>
                        <textarea class="uk-textarea">I=[fn]int_{0}^{ [fn]frac {1}{2}} [fn]frac{x}{[fn]sqrt{1-x^2}} dx</textarea>
                    </td>
                    <td>
                        \\[I=\\int_{0}^{ \\frac {1}{2}} \\frac{x}{\\sqrt{1-x^2}} dx \\]
                    </td>
                </tr>
                <tr>
                    <td>
                        <textarea class="uk-textarea">{}_n P _r</textarea>
                    </td>
                    <td>
                        \\[{}_n P _r \\]
                    </td>
                </tr>
                <tr>
                    <td>
                        <textarea class="uk-textarea">[fn]overrightarrow{AB}</textarea>
                    </td>
                    <td>
                        \\[\\overrightarrow{AB} \\]
                    </td>
                </tr>
                <tr>
                    <td>
                        <textarea class="uk-textarea">A=[fn]begin{pmatrix} 2 & 1 [br] 2 & 3 [fn]end{pmatrix}</textarea>
                    </td>
                    <td>
                        \\[A=\\begin{pmatrix} 2 & 1 \\\\ 2 & 3 \\end{pmatrix} \\]
                    </td>
                </tr>
                <tr>
                    <td>
                        <textarea class="uk-textarea">A=[fn]left[[fn]begin{array}{ccc}a_{11} & a_{12} & a_{13} [br]a_{21} & a_{22} & a_{23} [br]a_{31} & a_{32} & a_{33} [br][fn]end{array}[fn]right]</textarea>
                    </td>
                    <td>
                        \\[A=\\left[
                        \\begin{array}{ccc}
                        a_{11} & a_{12} & a_{13} \\\\
                        a_{21} & a_{22} & a_{23} \\\\
                        a_{31} & a_{32} & a_{33} \\\\
                        \\end{array}
                        \\right] \\]
                    </td>
                </tr>`;
    document.getElementById('mathCheatSheet').addEventListener('show', () => {
      MathJax.typeset(document.querySelectorAll('.mathPreview'));
    });
    document.getElementById('mathPreviewModal').addEventListener('show', () => {
      MathJax.typeset(document.querySelectorAll('.mathPreview'));
    });
  });
</script>

<Modal
  size="container"
  title={$_('menu.ChangeNicknameAndAvatar')}
  id="editNicknameAndAvatar"
>
  <div slot="body">
    <NickNameAndAvatarEdit />
  </div>
</Modal>
<div
  id="chatModal"
  class="uk-modal-container"
  uk-modal="container:false;stack:true"
>
  <div class="uk-modal-dialog">
    <Chat />
  </div>
</div>
<Modal stack id="dialogMessage">
  <div slot="body">
    {@html $DialogMessage}
  </div>
</Modal>

<div id="imageEditor" class="uk-modal-container" uk-modal="stack:true">
  <div class="uk-modal-dialog">
    <ImageEditor />
  </div>
</div>
<EventModals />
<ArticleModals />
<ProductModals />
<ConfirmModal />
<PromptModal />
<RoomSettingModal />
<CustomFieldEdit />
<AssignmentEdit />
<GradeEdit />
<SubmissionEdit />
<Profile />
<Modal id="versionHistory" title={$_('VersionHistory')}>
  <div slot="body">
    <VersionHistory />
  </div>
</Modal>
<Modal stack id="mathCheatSheet" size="container" title={$_('math.CheatSheet')}>
  <div slot="body">
    {$_('math.CheatSheet')}
    <p>
      {@html $_('math.AnythingBetween')}
    </p>
    <table class="uk-table uk-table-divider uk-table-small uk-table-justify">
      <thead>
        <tr class="uk-child-width-1-2">
          <th>{$_('math.Code')}</th>
          <th>{$_('math.Preview')}</th>
        </tr>
      </thead>
      <tbody id="cheatSheetContent" class="mathPreview" />
    </table>
  </div>
</Modal>
<Modal stack id="mathPreviewModal">
  <div slot="body" class="mathPreview">
    {@html decodeMath($previewValue)}
  </div>
</Modal>
