<script lang="ts">
  import AssignmentsTable from './AssignmentsTable.svelte';
  import SubmissionTable from './SubmissionTable.svelte';
  import { _ } from 'svelte-i18n';
  import { onMount } from 'svelte';
  import { School } from '../../lib/store';
  import { ioGet } from '../../lib/realtime';
  import { assignmentsStore } from '../../state/stores/assignmentsStore';
  import Loading from '../../lib/helpers/loading';
  import { defineIfUndefined } from '../../lib/helpers/defineIfUndefined';
  export let save;
  export let group;
  export let interval;
  export let saveCategory;
  export let category;
  let assignments = [];
  let school = null;
  School.subscribe(obj => {
    obj.customLanguages = defineIfUndefined(obj.customLanguages, {});
    school = obj;
  });
  onMount(async () => {
    assignmentsStore.clear();

    Loading.show();
    setTimeout(async function () {
      assignments = await ioGet('listAssignments', {
        groups: [group.id],
        assignmentCategoryID: category.id,
      });
      assignmentsStore.groupID = group.id;
      assignmentsStore.assignmentCategoryID = category.id;
      assignmentsStore.set(assignments);
      Loading.hide();
    }, 100);
  });
</script>

{#if category && school}
  <ul uk-tab>
    <li>
      <a href={'#'}>
        {$_(school.customLanguages['Submissions'] || 'Submissions')}
      </a>
    </li>
    <li>
      <a href={'#'}>
        {$_(school.customLanguages['Manage'] || 'Manage')}
      </a>
    </li>
  </ul>
  <ul class="uk-switcher">
    <li>
      <SubmissionTable {saveCategory} {category} {group} {interval} />
    </li>
    <li>
      <AssignmentsTable {group} {category} />
    </li>
  </ul>
{/if}
