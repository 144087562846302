<script>
  import { _ } from 'svelte-i18n';
  import { School } from '../../lib/store';
  export let schoolEvent = null;
</script>

<label>
  {$_('event.HourName')}
  <select
    class="uk-select"
    bind:value={schoolEvent.hourName}
    on:change={evt => {}}
  >
    <option value="">{$_('Undefined')}</option>
    {#each $School.hourNames || [] as hourName}
      <option value={hourName.title}>{hourName.title}</option>
    {/each}
  </select>
</label>
