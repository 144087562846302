export const ArticleDefault = {
    filterRoles: [],
    filterCategories: [],
    linkedPrograms: [],
    publishTime: '00:00',
    customFieldValues: {},
    title: '',
    description: '',
    schoolID: '',
    categoryLabel: '',
    publishAt: '',
    iconImageURL: '',
    roomID: '',
    isPublic: false,
    comments: [],
    favorites: [],
    backgroundColor: 'green',
};
