<script lang="ts">
  import { DateTime } from 'luxon';
  import { onMount } from 'svelte';
  import { defineIfUndefined } from '../../../lib/helpers/defineIfUndefined';

  export let value;
  export let field;
  onMount(() => {
    value = defineIfUndefined(value, {
      value: '',
    });
    if (value.value == '0000-00-00') {
      value.value = '';
    }
  });
</script>

{#if value && field}
  {#if (field?.type == 'date' || field?.type == 'endOfMonth') && value.value && value.value != '0000-00-00'}
    {DateTime.fromISO(value?.value).toFormat('M/d')}
  {:else}{value?.value || ''}{/if}
{:else}{value?.value || ''}{/if}
