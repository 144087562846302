<script lang="ts">
  import VerticalTab from './UI/VerticalTab.svelte';
  import { _ } from 'svelte-i18n';
  import { School } from '../lib/store';
  import Button from './UI/Button.svelte';
  import CustomFieldTable from './blocks/CustomFieldComponents/CustomFieldTable.svelte';
  import { ioGet } from '../lib/realtime';
  import IntervalsTab from './blocks/IntervalsTab.svelte';
  import { eventCustomFields } from '../lib/helpers/eventCustomFields';
  import copy from '../lib/helpers/copy';
  import myConfirm from '../lib/helpers/myConfirm';
  import { navigate, Route, Router } from 'svelte-routing';
  import { Notification } from '../actions/notification';
  import { defineIfUndefined } from '../lib/helpers/defineIfUndefined';
  let school = null;
  let gradeParentFields = [];
  let focus = location.href.split('/').pop().replace('#', '');
  let slugs = ['admin', 'manager', 'subscriber'];
  School.subscribe(obj => {
    gradeParentFields = [];
    if (obj) {
      if (
        typeof obj.customFields == 'undefined' ||
        Array.isArray(obj.customFields)
      ) {
        obj.customFields = {
          admin: [],
          manager: [],
          subscriber: [],
          article: [],
          event: [],
          product: [],
          attendanceCreditSummary: [],
          attendance: [],
        };
      } else {
        if (typeof obj.customFields.admin == 'undefined')
          obj.customFields.admin = [];
        if (typeof obj.customFields.manager == 'undefined')
          obj.customFields.manager = [];
        if (typeof obj.customFields.subscriber == 'undefined')
          obj.customFields.subscriber = [];
        if (typeof obj.customFields.article == 'undefined')
          obj.customFields.article = [];
        if (typeof obj.customFields.event == 'undefined')
          obj.customFields.event = [];
        if (typeof obj.customFields.product == 'undefined')
          obj.customFields.product = [];
        if (typeof obj.customFields.attendanceCreditSummary == 'undefined')
          obj.customFields.attendanceCreditSummary = [];
        if (typeof obj.customFields.attendance == 'undefined')
          obj.customFields.attendance = [];
      }
      if (typeof obj.assignmentCategories == 'undefined')
        obj.assignmentCategories = [];
      obj.assignmentCategories = obj.assignmentCategories.map(category => {
        if (typeof category.customFields == 'undefined') {
          category.customFields = [];
        }
        if (typeof category.submissionCustomFields == 'undefined') {
          category.submissionCustomFields = [];
        }
        return category;
      });
      obj.intervals = obj.intervals.map(interval => {
        interval.customFields = defineIfUndefined(interval.customFields, {
          grade: [],
        });
        interval.programCategories = interval.programCategories.map(
          category => {
            if (typeof category.customFields == 'undefined') {
              category.customFields = [];
            }
            if (typeof category.childCustomFields == 'undefined') {
              category.childCustomFields = [];
            }
            if (category.useToGroupGrades) {
              gradeParentFields = [
                ...gradeParentFields,
                {
                  title: '---' + category.title,
                },
                ...category.customFields,
                ...category.childCustomFields,
              ];
            }

            return category;
          },
        );
        return interval;
      });
      school = obj;
    }
  });
  const save = async () => {
    console.log(school.customFields);
    console.log(school.assignmentCategories);
    let res = await ioGet('updateSchool', {
      _id: $School._id,
      data: {
        customFields: school.customFields,
        assignmentCategories: school.assignmentCategories,
        intervals: school.intervals,
      },
    });
    School.set(school);
    Notification($_('Saved'));
  };
  const nav = slug => {
    navigate(`${slug}`);
    focus = slug;
  };
</script>

<VerticalTab>
  <ul slot="menu" class="uk-tab uk-tab-left">
    <li>
      {$_('customFields.Users')}
    </li>
    {#each slugs as slug}
      <li class:uk-active={focus == slug}>
        <a
          href={'#'}
          on:click={() => {
            nav(slug);
          }}
        >
          {$_('customFields.' + slug.replace(/^\w/, c => c.toUpperCase()))}
        </a>
      </li>
    {/each}
    {#if $School.features.useAssignments || $School.features.useGrades}
      <li class="uk-margin-top">
        {$_('customFields.Programs')}
      </li>
      {#if $School.features.useAssignments}
        {#each school.assignmentCategories as category}
          <li class:uk-active={focus == category.id}>
            <a
              href={'#'}
              on:click={() => {
                nav(category.id);
              }}
            >
              {category.title}
            </a>
          </li>
          <li
            class="uk-margin-left"
            class:uk-active={focus == category.id + '-submissions'}
          >
            <a
              href={'#'}
              on:click={() => {
                nav(category.id + '-submissions');
              }}
            >
              {$_('Submissions')}
            </a>
          </li>
        {/each}
      {/if}
      {#if $School.features.useGrades}
        <li class:uk-active={focus == 'grades'}>
          <a
            href={'#'}
            on:click={() => {
              nav('grades');
            }}
          >
            {$_('Grades')}
          </a>
        </li>
      {/if}
    {/if}
    <li class="uk-margin-top">
      {$_('customFields.Other')}
    </li>
    <li class:uk-active={focus == 'articles'}>
      <a
        href={'#'}
        on:click={() => {
          nav('articles');
        }}
      >
        {$_('Articles')}
      </a>
    </li>
    {#if $School.features.useEvents}
      <li class:uk-active={focus == 'events'}>
        <a
          href={'#'}
          on:click={() => {
            nav('events');
          }}
        >
          {$_('Events')}
        </a>
      </li>
    {/if}
    {#if $School.features.useAttendances}
      <li class="uk-margin-left" class:uk-active={focus == 'attendances'}>
        <a
          href={'#'}
          on:click={() => {
            nav('attendances');
          }}
        >
          {$_('Attendances')}
        </a>
      </li>
    {/if}
    {#if $School.features.useEC}
      <li class:uk-active={focus == 'products'}>
        <a
          href={'#'}
          on:click={() => {
            nav('products');
          }}
        >
          {$_('Products')}
        </a>
      </li>
    {/if}
  </ul>
  <div slot="content">
    <Router>
      {#each slugs as slug}
        <Route path={slug}>
          <CustomFieldTable
            {save}
            showShowOnBadge={true}
            bind:customFields={school.customFields[slug]}
            category={slug}
          />
        </Route>
      {/each}
      {#each school.assignmentCategories as category}
        <Route path={category.id}>
          <CustomFieldTable
            {save}
            bind:customFields={category.customFields}
            category="assignment"
          />
          <div class="uk-margin">
            {$_('customFields.CopyThisSettingToTheOtherProgramCategory')}
            <select class="uk-select" bind:value={category.otherCategoryID}>
              {#each school.assignmentCategories.filter(cat => cat.id != category.id) as otherCategory}
                <option value={otherCategory.id}>{otherCategory.title}</option>
              {/each}
            </select>
            <Button
              onclick={() => {
                myConfirm(
                  $_('ThisWillEraseTheExistingCustomFieldsOfTheOtherProgram'),
                  () => {
                    let thisCustomFields = category.customFields;
                    school.assignmentCategories.find(
                      cat => cat.id == category.otherCategoryID,
                    ).customFields = copy(thisCustomFields);
                    save();
                  },
                );
              }}>{$_('Copy')}</Button
            >
          </div>
        </Route>
        <Route path={`${category.id}-submissions`}>
          <CustomFieldTable
            {save}
            bind:customFields={category.submissionCustomFields}
            category="submission"
            parentFields={category.customFields}
          />
          <div class="uk-margin">
            {$_('customFields.CopyThisSettingToTheOtherProgramCategory')}
            <select class="uk-select" bind:value={category.otherCategoryID}>
              {#each school.assignmentCategories.filter(cat => cat.id != category.id) as otherCategory}
                <option value={otherCategory.id}>{otherCategory.title}</option>
              {/each}
            </select>
            <Button
              onclick={() => {
                myConfirm(
                  $_('ThisWillEraseTheExistingCustomFieldsOfTheOtherProgram'),
                  () => {
                    let thisCustomFields = category.submissionCustomFields;
                    school.assignmentCategories.find(
                      cat => cat.id == category.otherCategoryID,
                    ).submissionCustomFields = copy(thisCustomFields);
                    save();
                  },
                );
              }}>{$_('Copy')}</Button
            >
          </div>
        </Route>
      {/each}
      <Route path="grades">
        <div>
          <IntervalsTab
            intervals={$School.intervals}
            menu={$School.features.useIntervals}
          >
            <ul slot="content" class="uk-switcher">
              {#each $School.intervals as interval}
                <li>
                  <CustomFieldTable
                    {save}
                    bind:customFields={interval.customFields.grade}
                    category="grade"
                    parentFields={gradeParentFields}
                  />
                </li>
              {/each}
            </ul>
          </IntervalsTab>
        </div>
      </Route>
      <Route path="articles">
        <CustomFieldTable
          {save}
          withGuest={true}
          bind:customFields={school.customFields.article}
          category="article"
        />
      </Route>
      <Route path="events">
        <CustomFieldTable
          {save}
          withGuest={true}
          bind:customFields={school.customFields.event}
          category="event"
        />
      </Route>
      <Route path="attendances">
        <CustomFieldTable
          {save}
          bind:customFields={school.customFields.attendance}
          category="attendance"
          parentFields={eventCustomFields($School)}
        />
      </Route>
      <Route path="products">
        <CustomFieldTable
          {save}
          bind:customFields={school.customFields.product}
          category="product"
        />
      </Route>
    </Router>
    <Button onclick={save}>{$_('Save')}</Button>
  </div>
</VerticalTab>
