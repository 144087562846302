export const SubscriptionPlans = [
    {
        id: 1,
        title: 'Openwater',
    },
    {
        id: 2,
        title: 'Essential',
    },
    {
        id: 3,
        title: 'Basic',
    },
    {
        id: 4,
        title: 'Platinum',
    },
];
