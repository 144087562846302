<script>
  import { ioFetch, ioGet, io } from '../lib/realtime';
  import EnterCard from './blocks/EnterCard.svelte';
  import Button from './UI/Button.svelte';
  import Cookies from 'js-cookie';
  import EmailValidator from 'email-validator';
  import Input from './UI/Input.svelte';
  import { onMount } from 'svelte';
  import { navigate } from 'svelte-routing';
  import { ComeBackURL, LoggedIn } from '../lib/store';
  import { getLocaleFromNavigator, _ } from 'svelte-i18n';
  import dialog from '../lib/helpers/dialog';
  import LanguageSwitch from './blocks/LanguageSwitch.svelte';
  export let mode = 'register';

  let comeBackURL;
  ComeBackURL.subscribe(url => {
    comeBackURL = url;
  });

  LoggedIn.subscribe(bool => {
    if (bool) {
      navigate('/');
    }
  });

  let email = '';
  const sendVerificationCode = async () => {
    if (!EmailValidator.validate(email)) {
      //email not OK
      dialog($_('login.NotAValidEmail'));
      return;
    }
    getLocaleFromNavigator;
    let res = await ioGet('sendVerificationCode', {
      email: email,
      locale: Cookies.get('locale') || getLocaleFromNavigator(),
      checkExists: true,
    });
    console.log(res);
    if (res.result == 'already exists') {
      dialog($_('login.EmailAlreadyExists'));
      return;
    }
    dialog($_('login.CheckYourEmail'));
    mode = 'verify';
  };
  let code = '';
  const verifyCode = async () => {
    let res = await ioFetch('verifyCodeAndRegister', {
      email: email,
      code: code,
      locale: Cookies.get('locale') || getLocaleFromNavigator(),
    });
    console.log(res);
    if (res.result == 'ng') {
      dialog($_('WrongCode'));
      return;
    }
    Cookies.set('login', res.login);
    if (typeof Cookies.get('backURL') != 'undefined') {
      let url = Cookies.get('backURL');
      Cookies.remove('backURL');
      location.href = url;
    } else {
      location.href = '/';
    }
  };
</script>

<svelte:head>
  <title>{$_('login.Register')} - AVOS</title>
</svelte:head>
{#if mode == 'register'}
  <EnterCard>
    <span slot="title">{$_('login.Register')}</span>
    <p slot="description">{$_('login.PleaseEnterYourEmail')}</p>
    <div slot="body">
      <Input type="email" bind:value={email} />
      <Button fullwidth onclick={sendVerificationCode}>{$_('Send')}</Button>
      <div class="uk-margin">
        <Button
          style="text"
          small
          onclick={() => {
            navigate('/login');
          }}>{$_('login.GoToLogin')}</Button
        >
      </div>
      <LanguageSwitch />
    </div>
  </EnterCard>
{:else}
  <EnterCard>
    <span slot="title">{$_('login.VerifyCode')}</span>
    <div slot="body">
      <Input type="text" bind:value={code} />
      <Button onclick={verifyCode} fullwidth>{$_('login.VerifyCode')}</Button>
      <Button
        style="text"
        onclick={() => {
          navigate('/register');
        }}>{$_('login.SendCodeAgain')}</Button
      >
    </div>
  </EnterCard>
{/if}
