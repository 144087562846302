import { _ } from 'svelte-i18n';
import { get } from 'svelte/store';
import { hideModal, showModal } from '../../actions/modalController';
import Loading from './loading';
let input = document.getElementById('promptInput');
const myPrompt = (message, callback, initialValue = '') => {
  Loading.hide();
  document.getElementById('promptInput').value = initialValue;
  showModal('#promptModal');
  document.getElementById('promptMessage').innerHTML = message;
  const cancelAction = () => {
    callback('');
    document.getElementById('promptInput').value = '';
    hideModal('#promptModal');
    document.getElementById('promptOK').removeEventListener('click', okAction);
  };
  const okAction = () => {
    let value = document.getElementById('promptInput').value;
    callback(value);
    document.getElementById('promptInput').value = '';
    hideModal('#promptModal');
    document
      .getElementById('promptCancel')
      .removeEventListener('click', cancelAction);
  };
  document
    .getElementById('promptCancel')
    .addEventListener('click', cancelAction, { once: true });
  document
    .getElementById('promptOK')
    .addEventListener('click', okAction, { once: true });
};
export default myPrompt;
