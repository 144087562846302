import { get } from 'svelte/store';
import { _ } from 'svelte-i18n';
import { HOST } from '../config';
export const PresetModels = [
  {
    id: 1,
    title: get(_)('presetModels.DeskAndChair'),
    url: HOST + '/models/desk.glb',
  },
  {
    id: 2,
    title: get(_)('presetModels.MeetingTable'),
    url: '/models/meetingtable.glb',
  },
  {
    id: 3,
    title: get(_)('presetModels.DeskAndChaitAttached'),
    url: '/models/groupdesk.glb',
  },
  {
    id: 4,
    title: get(_)('presetModels.CafeTableAndChairs'),
    url: '/models/cafetable.glb',
  },
];
