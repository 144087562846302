<script lang="ts">
  import { hideModal, showModal } from '../../actions/modalController';
  import type { PersonaInterface } from '../../lib/interfaces/Persona';
  import { Room, SchoolRole } from '../../lib/store';
  import { personasStore } from '../../state/stores/personasStore';
  import Modal from '../UI/Modal.svelte';
  import Button from '../UI/Button.svelte';
  import Icon from '../UI/Icon.svelte';
  import PersonaBlock from './PersonaBlock.svelte';
  import { _ } from 'svelte-i18n';
  import Cookies from 'js-cookie';
  import { roomElementsStore } from '../../state/stores/roomElementsStore';
  import { io } from '../../lib/realtime';
  import MicIconWithLevel from './MicIconWithLevel.svelte';
  export let participantsListHidden: boolean = true;
  export let usersInRoom: Array<PersonaInterface> = [];
  let personaID;
  export let transportPoints = [];
</script>

<div
  id="participantsList"
  class="uk-margin dark-translucent-background uk-light  uk-border-rounded"
  style:padding="10px"
  hidden={participantsListHidden}
>
  <div class="uk-text-meta">
    {$_('selectRoom.CurrentlyInTheRoom')}
  </div>
  <ul
    class="uk-list uk-list-small uk-margin-large-right uk-margin-remove-right"
  >
    {#each usersInRoom as participant}
      <li class="uk-animation-slide-right-medium">
        <div class="uk-flex uk-flex-middle">
          <div class="uk-margin-small-right  uk-width-expand">
            <PersonaBlock small schoolRoleID={participant.id} />
          </div>
          <div class="uk-margin-small-right">
            {#if roomElementsStore.get(participant.id).isMutable && !roomElementsStore.get(participant.id).onMute}
              <MicIconWithLevel size="large" volume={participant.volume} />
            {:else if !roomElementsStore.get(participant.id).isMutable && !roomElementsStore.get(participant.id).onMute}
              <span style:color="yellow">
                <Icon icon="megaphone" />
              </span>
            {:else}
              <span style:color="red">
                <Icon icon="microphone-slash" />
              </span>
            {/if}
          </div>
          <div class="uk-margin-small-right">
            {#if $Room.permissions.find(r => r.id == $SchoolRole.roleID)?.roomPermissionPresets.friends.canTransport || $Room.permissions.find(r => r.category == 'guest')?.roomPermissionPresets.friends.canTransport}
              <span>
                <span
                  style:cursor="pointer"
                  on:click={() => {
                    personaID = participant.id;
                    document
                      .getElementById('selectTransportPoint')
                      .setAttribute('personaID', participant.id);
                    showModal('#selectTransportPoint');
                  }}
                >
                  <Icon icon="transporter-1" />
                </span>
              </span>
            {/if}
          </div>
          {#if $Room.permissions.find(r => r.id == $SchoolRole.roleID).roomPermissionPresets.friends.canMute && !roomElementsStore.get(participant.id).onMute}
            <div class="uk-margin-small-right">
              <a
                on:click={() => {
                  io.emit('forceMute', participant.socketID);
                }}
                href={'#'}
                style:border="yellow 1px"
                class="uk-border-circle"
              >
                <Icon icon="volume-xmark" />
              </a>
            </div>
          {/if}
          {#if $Room.permissions.find(r => r.id == $SchoolRole.roleID).roomPermissionPresets.friends.canKickOut}
            <div>
              <a
                on:click={() => {
                  io.emit('kickOut', participant.socketID);
                }}
                href={'#'}
                style:border="red 1px"
                class="uk-border-circle"
              >
                <Icon icon="ban" />
              </a>
            </div>
          {/if}
        </div>
      </li>
    {/each}
  </ul>
</div>
<Modal id="selectTransportPoint">
  <div slot="body">
    {#if transportPoints.length}
      <div class="uk-text-meta">
        {$_('room.TransportPoints')}
      </div>
      {#if personaID}
        <PersonaBlock small schoolRoleID={personaID} />
      {/if}
      <ul class="uk-list uk-list-small uk-margin-large-right">
        {#each transportPoints as transportPoint}
          <li class="uk-animation-slide-right-medium">
            <a
              on:click={() => {
                io.emit('forceTransport', {
                  socketID: roomElementsStore.get(
                    document
                      .getElementById('selectTransportPoint')
                      .getAttribute('personaID'),
                  ).socketID,
                  position: document
                    .getElementById(transportPoint.uid)
                    .getAttribute('position'),
                });
                hideModal('#selectTransportPoint');
              }}
              href={'#'}
            >
              {transportPoint.extra.title}
            </a>
          </li>
        {/each}
      </ul>
    {/if}
  </div>
</Modal>
