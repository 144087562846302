<script>
  import { ENDPOINT } from '../../lib/config';
  import Loading from '../../lib/helpers/loading';
  import upload, { uploadVIdeoFile } from '../../lib/helpers/upload';
  import Button from '../UI/Button.svelte';
  import { VideoUploader } from '@api.video/video-uploader';
  import { ioGet } from '../../lib/realtime';
  import { UUID } from '../../lib/uuid';
  export let style = 'default';
  export let onUploadComplete;
  export let fullwidth = false;
  export let small = false;
  export let id = UUID();

  const uploadFile = async (evt, callback) => {
    uploadVIdeoFile(evt.target.files, callback);
  };
</script>

<input
  {id}
  type="file"
  on:change={evt => {
    uploadFile(evt, onUploadComplete);
  }}
  hidden
  accept="video/*"
/>
<Button
  {id}
  {fullwidth}
  {small}
  {style}
  onclick={evt => {
    console.log(evt);
    document.getElementById(id).click();
    //    evt.target.previousElementSibling.click();
  }}><slot /></Button
>
