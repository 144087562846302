<script lang="ts">
  import { _ } from 'svelte-i18n';
  import { FileStorageClient } from '../../clients/fileStorageClient';
  export let schoolId: string | null;
  export let userId: string | null;
  export let fileUploadCallback: (
    response: Response,
    key: string,
    file: File,
  ) => Promise<void>;

  let files: FileList;
  let fileStorageClient = new FileStorageClient();

  $: if (files) {
    for (const file of files) {
      console.log(`${file.name}: ${file.size} bytes`);
    }
  }

  const uploadFile = async (
    event: MouseEvent & {
      currentTarget: EventTarget & HTMLButtonElement;
    },
  ): Promise<void> => {
    event.preventDefault();
    if (!files || files.length === 0) {
      alert($_('upload.NoFileSelected'));
      return;
    }
    if (!schoolId || !userId) return;
    const file = files[0];

    const { response, key } = await fileStorageClient.uploadUserFile(
      schoolId,
      userId,
      files,
    );
    await fileUploadCallback(response, key, file);
  };
</script>

<label class="upload uk-margin-small" for="upload">
  <input type="file" id="upload" bind:files class="uk-input" accept="*" />
</label>
<button
  on:click={event => uploadFile(event)}
  class="uk-button uk-button-default"
>
  <slot />
</button>

<style>
  label.upload {
    display: inline-block;
  }

  label.upload input {
    border: none;
    padding: 6px 12px;
    cursor: pointer;
  }
</style>
