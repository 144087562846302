<script lang="ts">
  import { beforeUpdate } from 'svelte';
  import { _ } from 'svelte-i18n';
  import { getContext } from 'svelte/internal';
  import { Notification } from '../../../actions/notification';
  import { CustomFieldDefault } from '../../../lib/interfaces/CustomField';
  import { customFieldTypes } from '../../../lib/presets/customFieldTypes';
  import { School } from '../../../lib/store';
  import Checkbox from '../../UI/Checkbox.svelte';
  import Input from '../../UI/Input.svelte';
  import ArrayNav from '../ArrayNav.svelte';
  import FileUpload from '../FileUpload.svelte';
  import CustomFieldOptions from './CustomFieldOptions.svelte';
  export let field;
  export let fields;
  export let moveUp;
  export let moveDown;
  export let remove;
  export let i;
  export let length;
  export let sideway: boolean = false;
  export let showShowOnBadge: boolean = false;
  export let withGuest: boolean = false;
  export let save: () => void;
  let parentFields = getContext('parentFields');
  let file = field.type === 'file' ? true : false;

  const onUploadComplete = res => {
    field.custom_button_url = res.data.url;
  };

  beforeUpdate(() => {
    if (typeof field.permissions == 'undefined') {
      field.permissions = {};
    }
    $School.roles.forEach(role => {
      if (typeof field.permissions[role.id] == 'undefined') {
        field.permissions[role.id] = {
          canRead: true,
          canEdit: false,
        };
      }
    });
  });
</script>

<div uk-grid class="uk-grid-small uk-width-expand">
  <div class="uk-width-expand@s">
    <Input bind:value={field.title} label={$_('customFields.FieldName')} />
    <div class="uk-text-meta">
      {$_('customFields.Type')}
    </div>
    <select
      class="uk-select"
      bind:value={field.type}
      on:change={async () => {
        field.options = CustomFieldDefault.options;
        if (field.type == 'array') {
          Notification($_('Reloading'));
          console.log(field);
          await save();
          location.reload();
        }
        if (field.type == 'file') {
          file = true;
        } else {
          file = false;
        }
      }}
    >
      {#each parentFields ? customFieldTypes.filter(f => f.slug != 'reference') : customFieldTypes.filter(f => f.slug != 'inherit' && f.slug != 'reference') as type}
        <option value={type.slug}>{$_('customFields.' + type.title)}</option>
      {/each}
    </select>

    {#if field.type == 'select' || field.type == 'selectMultiple' || field.type == 'reference' || field.type == 'inherit' || field.type == 'number' || field.type == 'button' || field.type == 'autoCalculation' || field.type == 'conditionalValue' || field.type == 'conditionalTime' || field.type == 'conditionalColorRing' || field.type == 'array' || field.type == 'googleFormResponseScore'}
      <div
        class="uk-background-secondary uk-light uk-border-rounded uk-padding-small"
      >
        <div class="uk-text-meta">
          {$_('customFields.Options')}
        </div>
        <CustomFieldOptions
          {save}
          type={field.type}
          bind:options={field.options}
          {field}
          {fields}
        />
      </div>
    {/if}
    <div class="uk-margin uk-flex">
      <div class="uk-margin-small-right">
        <Checkbox
          bind:checked={field.showOnList}
          label={$_('customFields.ShowOnList')}
        />
      </div>
      {#if showShowOnBadge}
        <div class="uk-margin-small-right">
          <!-- TODO: add correct classes for checkboxes -->
          <Checkbox
            bind:checked={field.showOnBadge}
            label={$_('customFields.ShowOnBadge')}
          />
        </div>
      {/if}
      {#if file}
        <div class="uk-margin-small-right">
          <Checkbox
            bind:checked={field.upload}
            label={$_('customFields.CustomButton')}
          />
        </div>
      {/if}
      {#if field.type === 'button'}
        <div class="uk-margin-small-right">
          <Checkbox bind:checked={field.click_once} label={'Click once'} />
        </div>
      {/if}
    </div>
    {#if field.upload}
      <div>
        <FileUpload {onUploadComplete}>{$_('Upload')}</FileUpload>
      </div>
    {/if}
    {#if field.custom_button_url && field.upload}
      <div>
        <img src={field.custom_button_url} style="max-height:60px" alt="" />
      </div>
    {/if}
  </div>
  <div>
    <ArrayNav {sideway} {moveUp} {moveDown} {remove} {i} arrayLength={length} />
    <div class="uk-text-meta">
      {$_('customFields.Permissions')}
    </div>
    <ul class="uk-list uk-list-small uk-list-divider">
      {#each $School.roles as role}
        {#if role.category != 'guest' || withGuest}
          <li>
            <div class="uk-flex">
              <div class="uk-margin-small-right">
                {role.title}
              </div>
              <div class="uk-margin-small-right">
                <Checkbox
                  bind:checked={field.permissions[role.id].canRead}
                  label={$_('permissions.CanRead')}
                />
              </div>
              {#if customFieldTypes.find(type => type.slug == field.type)?.isPrimitive}
                <div>
                  <Checkbox
                    bind:checked={field.permissions[role.id].canEdit}
                    label={$_('permissions.CanEdit')}
                  />
                </div>
              {/if}
            </div>
          </li>
        {/if}
      {/each}
    </ul>
  </div>
</div>
