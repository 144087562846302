<script lang="ts">
</script>

<a
  class="twitter-timeline"
  data-width="100%"
  data-height="800"
  data-theme="dark"
  href="https://twitter.com/avosofficial_jp?ref_src=twsrc%5Etfw"
>
  Tweets by avosofficial_jp
</a>
