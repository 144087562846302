<script>
  import { Interval } from 'luxon';
  import { onMount } from 'svelte';
  import { navigate, Route, Router } from 'svelte-routing';
  import { ioGet } from '../lib/realtime';
  import { School, SchoolRole } from '../lib/store';
  import IntervalsTab from './blocks/IntervalsTab.svelte';
  import NavList from './blocks/NavList.svelte';
  import SubscriberTable from './blocks/SubscriberTable.svelte';

  import VerticalTab from './UI/VerticalTab.svelte';
  let subscribers = [];

  let focus = location.href
    .split('subscribers/')
    .pop()
    .split('/')
    .shift()
    .replace('#', '');
  const nav = slug => {
    navigate('/' + $School.slug + '/subscribers/' + slug + '/');
    focus = slug;
  };
  onMount(() => {
    if (!focus) {
      nav($School.intervals[0].id);
    }
  });
</script>

<ul class="uk-tab" hidden={!$School.features.useIntervals}>
  {#each $School.intervals as interval}
    <NavList
      bind:focus
      slug={interval.id}
      breadcrumb={'/' + $School.slug + '/subscribers/'}
    >
      {interval.title}
    </NavList>
  {/each}
</ul>
<div>
  <Router>
    {#each $School.intervals as interval}
      <Route path={interval.id + '/*'}>
        {#if $SchoolRole}
          {#key interval.id}
            <SubscriberTable
              {interval}
              breadcrumb={'/' +
                $School.slug +
                '/subscribers/' +
                interval.id +
                '/'}
            />
          {/key}
        {/if}
      </Route>
    {/each}
  </Router>
</div>
