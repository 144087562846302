import { ioGet } from '../realtime';
import { Room } from '../store';
import { CometChat } from '@cometchat-pro/chat';

let room;
Room.subscribe(obj => {
  room = obj;
});
export const createWhiteBoardAndDocumentURLForRoom = () => {
  if (!room.whiteBoardURL || typeof room.whiteBoardURL == 'undefined') {
    CometChat.callExtension('whiteboard', 'POST', 'v1/create', {
      receiver: room._id,
      receiverType: 'group',
    })
      .then(async response => {
        // Response with board_url
        console.log(response);
        await ioGet('updateRoom', {
          _id: room._id,
          data: {
            whiteBoardURL: response.board_url,
          },
        });
        Room.update(room => {
          room.whiteBoardURL = response.board_url;
          return room;
        });
      })
      .catch(error => {
        // Some error occured
      });
  }
  if (!room.documentURL || typeof room.documentURL == 'undefined') {
    CometChat.callExtension('document', 'POST', 'v1/create', {
      receiver: room._id,
      receiverType: 'group',
    })
      .then(async response => {
        // Response with board_url
        console.log(response);
        await ioGet('updateRoom', {
          _id: room._id,
          data: {
            documentURL: response.document_url,
          },
        });
        Room.update(room => {
          room.documentURL = response.document_url;
          return room;
        });
      })
      .catch(error => {
        // Some error occured
      });
  }
};
