import { myVolumeRaw } from '../store';

let volume = 0;
myVolumeRaw.subscribe(val => {
  volume = val;
});

const getCurrentVoume = () => {
  return volume;
};
export default getCurrentVoume;
